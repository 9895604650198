import { e } from "mathjs";

const product = `${window.location.protocol}//manage-api.yanshizi.com`
const daily = `${window.location.protocol}//manage-api.kaoyanbox.net`
let logDebug = true;
let host = daily
// 环境判断

if (location) {
    if (location.host.includes('manage.yanshizi.com')) {
      host = product
        logDebug = false
    } else if (location.host.includes('manage.kaoyanbox.net')) {
        logDebug = true
        host = daily
        if (location.host.includes('test')) {
          host = daily
        }
    }
}


export {
    logDebug
}

export default host