<template>
  <!-- 组件TableTittle里的title本来应该是title（拼错了），但是已经写的太多了，索性就用这个变量名了，注意！ -->
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
//  /deep/ .el-tabs__nav-wrap::after{
//     height: 0px !important;
//   }



.tabulation {
  position: relative;
}
 .radio-group {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
  }

.pointer{
   color: #409eff;
   cursor: pointer;
}
/* .header{
  display: flex;
  align-content: space-between;
} */

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.dialog-footer {
  /* margin-top: 0.64rem;
  display: flex;
  justify-content: flex-end; */
  
}
/* .dialog-footer{
  display: flex;
  align-items: center;
} */
html,
body,
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, "microsoft yahei",
    arial, STHeiTi, sans-serif;
}

a {
  text-decoration: none;
}
</style>
