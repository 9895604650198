<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item>师资推荐</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button icon="el-icon-download"
                   @click="handleExport"
                   size="small">导出</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="订单编号">
          <el-input v-model.trim="query.order_no"
                    placeholder="请输入订单编号"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="发单人">
          <el-input v-model.trim="query.sender_keyword"
                    placeholder="请输入发单人名字/手机号/机构名"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select v-model="query.statues"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     multiple
                     clearable>
            <el-option v-for="item in filters.statues"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联师资订单状态">
          <el-select v-model="query.ref_allocation_order_statuses"
                     placeholder="请选择"
                     @change="fetchList(true,3)"
                     multiple
                     clearable>
            <el-option v-for="item in filters.relateStatues"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考信息">
          <el-input v-model.trim="query.apply_cams_keyword"
                    placeholder="请输入院校/学院/专业/科目关键字"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="匹配师"
                      v-if="!$buttonPermission('matchmaker:show')">
          <el-select v-model="allocation_manage_idses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="item in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 5 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form size="small"
                 :inline="true">
          <el-form-item label-width="32px">
            <el-radio-group v-model="tableType"
                            @change="fetchList(true)">
              <el-radio-button :label="1">订单大厅</el-radio-button>
              <el-radio-button :label="4">我的派单</el-radio-button>
              <el-radio-button :label="2">我的接单</el-radio-button>
              <el-badge :value="uncheck"
                        class="item"
                        :hidden="uncheck == 0">
                <el-radio-button :label="3">我的发单</el-radio-button>
              </el-badge>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="result"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                @selection-change="handleSelectionChange"
                border>
        <el-table-column type="selection"
                         width="50"
                         align="center">
        </el-table-column>
        <!--        <el-table-column
            prop="id"
            label="ID"
            align="center"
            fixed="left"
            width="80"
          >
          </el-table-column> -->
        <el-table-column fixed="left"
                         label="操作记录"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToRecord(scope.row)">操作</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToAbility(scope.row)">沟通</el-link>
          </template>
        </el-table-column>
        <!-- <el-table-column
          fixed="left"
          label="沟通记录"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToAbility(scope.row)"
            >查看</el-link>
          </template>
        </el-table-column> -->
        <el-table-column prop="order_no"
                         label="订单编号"
                         align="center"
                         width="150">
        </el-table-column>
        <el-table-column prop="spend_duration"
                         label="时效"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            <div class="spend-wrap">
              <img src="@/assets/images/ji_tag.png"
                   alt=""
                   srcset=""
                   v-if="scope.row.urgency_status == 1" />
              <div v-html="formateTime(scope.row)"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.status == 1">待接单</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 2">接单中</el-tag>
            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.status == 3">待支付</el-tag>
            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.status == 4">待确认</el-tag>
            <el-tag size="small"
                    type="success"
                    v-if="scope.row.status == 5">已完成</el-tag>
            <el-tag size="small"
                    type="info"
                    v-if="scope.row.status == 6">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="关联师资订单状态"
                         align="center"
                         width="140"
                         show-overflow-tooltip
                         v-if="tableType==3">
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.ref_allocation_order_status == 1">待分配</el-tag>
            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.ref_allocation_order_status == 2">匹配中</el-tag>
            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.ref_allocation_order_status == 3">待确认</el-tag>
            <el-tag size="small"
                    type="success"
                    v-if="scope.row.ref_allocation_order_status == 4">已完成</el-tag>
            <el-tag size="small"
                    type="info"
                    v-if="scope.row.ref_allocation_order_status == 5">返单中</el-tag>
            <el-tag size="small"
                    type="info"
                    v-if="scope.row.ref_allocation_order_status == 6">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发单人"
                         align="left"
                         min-width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              scope.row.sender_company_user_obj
                ? `${scope.row.sender_company_user_obj.company_ref_name}-${scope.row.sender_company_user_obj.alias}`
                : ""
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="订单信息"
                         align="left"
                         min-width="500"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.apply_year }}考研 |
              <span class="pointer"
                    @click="institutionOpen(scope.row)">{{
                scope.row.apply_college_name
              }}</span>
              | {{ scope.row.apply_academy_name }} | ({{
                scope.row.apply_major_code
              }}){{ scope.row.apply_major_name }} |
              {{ getSubject(scope.row) }}
              <!-- ({{scope.row.apply_subject_codes}}){{scope.row.apply_subject_names}}| -->
              {{ scope.row.lesson_hours }}小时</span>
          </template>
        </el-table-column>
        <el-table-column label="辅导要求"
                         align="left"
                         min-width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover placement="top"
                        width="260"
                        trigger="hover"
                        v-if="formateAsks(scope.row) > 0">
              <div class="popver-content">
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="
                    scope.row.demand_direction_codes &&
                    scope.row.demand_direction_names
                  ">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  考试方向仅限是{{ formateDirections(scope.row) }}
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_max_hour_amount">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  研究生时薪≤{{ scope.row.demand_max_hour_amount }}元/小时；
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_academy_major_status">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  研究生与学员同一个学院专业
                </div>
                <!-- <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_extra_info">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  {{ scope.row.demand_extra_info }}
                </div> -->
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.extend_info_demand_trial_listen_status">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  正式课程前，需要安排一节试听课
                </div>

                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.extend_info_demand_professional_experience_status">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  需要有对应的专业课辅导经验
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.extend_info_demand_before_first_rank">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  初试总分排名在前{{ scope.row.extend_info_demand_before_first_rank}}名
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.extend_info_demand_min_first_total_score">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  初试总成绩不低于{{ scope.row.extend_info_demand_min_first_total_score}}分
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.extend_info_demand_min_first_professional_score">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  专业课成绩不低于{{ scope.row.extend_info_demand_min_first_professional_score}}分
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_same_reference_book_status == 1">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  允许其他院校研究生根据参考书或者大纲辅导
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_extra_info !=null">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  其他要求: {{scope.row.demand_extra_info}}
                </div>
              </div>
              <span slot="reference">有{{ formateAsks(scope.row) }}项辅导要求</span>
            </el-popover>
            <span v-else> 暂无任何辅导要求 </span>
          </template>
        </el-table-column>

        <el-table-column label="订单金额"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover placement="top"
                        width="200"
                        trigger="hover">
              <div>
                <div class="count-item other">
                  <div>订单费用</div>
                  <div>￥{{ scope.row.total_in_amount }}</div>
                </div>
                <div class="line"></div>
                <div class="count-item">
                  <div>基础费用</div>
                  <div>
                    ￥{{
                      scope.row.in_base_amount ? scope.row.in_base_amount : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>平台费用</div>
                  <div>
                    ￥{{
                      scope.row.in_service_amount
                        ? scope.row.in_service_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>超时附加</div>
                  <div>
                    ￥{{
                      scope.row.in_timeout_amount
                        ? scope.row.in_timeout_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>院校附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_category_amount
                        ? scope.row.in_append_college_category_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>地区附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_province_amount
                        ? scope.row.in_append_college_province_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>招生附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_enrol_amount
                        ? scope.row.in_append_college_enrol_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>要求附加</div>
                  <div>
                    ￥{{
                      Number(scope.row.in_append_demand_academy_major_amount) +
                      Number(scope.row.in_append_demand_direction_amount) +
                      Number(scope.row.in_append_demand_hour_amount_amount)
                        ? (
                            Number(
                              scope.row.in_append_demand_academy_major_amount
                            ) +
                            Number(
                              scope.row.in_append_demand_direction_amount
                            ) +
                            Number(
                              scope.row.in_append_demand_hour_amount_amount
                            )
                          ).toFixed(2)
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>加急费用</div>
                  <div>
                    ￥{{
                      scope.row.in_urgency_amount
                        ? scope.row.in_urgency_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>复试附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_second_exam_subject_amount
                        ? scope.row.in_append_second_exam_subject_amount
                        : 0
                    }}
                  </div>
                </div>
                <!--   <div class="count-item">
                    <div>推荐分成</div>
                    <div>￥{{ scope.row.extra_out_referrer_bonus_amount?scope.row.extra_out_referrer_bonus_amount:0 }}</div>
                  </div> -->
              </div>
              <span slot="reference"
                    style="color: #ff0034">￥{{ scope.row.total_in_amount }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="已付费用"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              scope.row.total_deposit_amount
                ? `￥${scope.row.total_deposit_amount}`
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="接单人"
                         align="center"
                         width="150"
                         prop="ref_receiver_manage_user_obj.realname">
        </el-table-column>
        <el-table-column label="派单人"
                         align="left"
                         min-width="150"
                         show-overflow-tooltip
                         v-if="tableType==4">
          <template slot-scope="scope">
            <span>{{
              scope.row.ref_appointer_manage_user_obj !=null
                ? scope.row.ref_appointer_manage_user_obj.nickname
              : ""
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>

        <el-table-column label="更新时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         align="center"
                         width="220">
          <template slot-scope="scope">
            <div v-if="tableType == 1">
              <el-link type="primary"
                       :underline="false"
                       @click="reciver(scope.row)">接单</el-link>
              <el-link type="primary"
                       :underline="false"
                       @click="handle(scope.row)">处理</el-link>
              <el-link type="primary"
                       :underline="false"
                       @click="distribution(scope.row)"
                       v-if="$buttonPermission('taOrder:allocation')">派单</el-link>
            </div>
            <div v-if="tableType == 4">
              <el-link type="primary"
                       :underline="false"
                       @click="reciver(scope.row)">接单</el-link>
              <el-link type="primary"
                       :underline="false"
                       @click="handle(scope.row)">处理</el-link>
              <el-link type="primary"
                       :underline="false"
                       @click="distribution(scope.row)"
                       v-if="$buttonPermission('taOrder:allocation')">重新派</el-link>
            </div>

            <div v-if="tableType == 2">
              <el-link type="primary"
                       :underline="false"
                       @click="handle(scope.row)">处理</el-link>
              <el-link type="primary"
                       :underline="false"
                       @click="cancelOrderChose(scope.row, 1)"
                       v-if="scope.row.status != 3 && tableType == 2">取消订单</el-link>
              <el-link type="primary"
                       :underline="false"
                       @click="cancelOrder(scope.row)"
                       v-if="scope.row.status == 3">催支付</el-link>
            </div>
            <!-- [1,2,3]修改 -->
            <div v-if="tableType == 3">
              <el-link type="primary"
                       :underline="false"
                       @click="handleRefresh(scope.row)"
                       v-if="[1].includes(scope.row.status) && getShow(scope.row)">刷新置顶</el-link>
              <el-link type="primary"
                       :underline="false"
                       @click="handleUp(scope.row)"
                       v-if="[1, 2, 3].includes(scope.row.status)">修改</el-link>
              <el-link type="primary"
                       :underline="false"
                       @click="cancelOrderChose(scope.row, 2)"
                       v-if="[1, 2, 3].includes(scope.row.status)">取消订单</el-link>

              <el-link type="warning"
                       :underline="false"
                       @click="goPay(scope.row)"
                       v-if="[3].includes(scope.row.status)">去支付</el-link>

              <el-link type="primary"
                       :underline="false"
                       @click="unacked(scope.row)"
                       v-if="[4].includes(scope.row.status)">延时确认</el-link>
              <el-link type="primary"
                       :underline="false"
                       @click="handleReject(scope.row)"
                       v-if="[4].includes(scope.row.status)">驳回师资</el-link>
              <el-link type="primary"
                       :underline="false"
                       @click="certifiedTeacher(scope.row)"
                       v-if="[4].includes(scope.row.status)">确认师资</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="operation">
        <div>
          <!--  <el-button
            size="mini"
            type="danger"
            @click="handleToDeleteAll()"
          >删除</el-button> -->
        </div>
        <div class="block"
             style="margin-bottom: 0px">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <Distribution :type="type"
                  :row="row"
                  :id="distributionId"
                  @close="distributionDialog = false"
                  :isVisible="distributionDialog"
                  @submit="fetchList"></Distribution>
    <EditOrder :isVisible="editDialog"
               :id="editId"
               @submit="fetchList()"
               @close="editDialog = false"></EditOrder>
    <OrderRecord :isVisible="recordDialog"
                 :id="recordId"
                 :handle_receiver_id="handle_receiver_id"
                 @close="recordDialog = false"></OrderRecord>

    <AbilityRecord :teacherIdFlag="true"
                   :teacher_allocation_order_id="orderId"
                   :isVisible="orderDialog"
                   @close="orderDialog = false"
                   @submit="fetchList"
                   :type="'order_read'"></AbilityRecord>

    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>
    <RejectedTeacher ref="rejectedTeacher"
                     @submit="fetchList()" />
    <ShareUp ref="shareUp"
             @submit="fetchList()" />
    <el-dialog title="确认申请延时3天确认师资吗？"
               :visible.sync="unackedDialogVisible"
               width="30%">
      <!-- <div style="text-align:center">每笔订单只能延时两次哦</div> -->
      <el-form ref="ruleForm"
               :model="unackedForm"
               :rules="rules"
               label-width="80px">
        <el-form-item label="延时理由"
                      prop="delayValue">
          <el-input type="textarea"
                    :rows="2"
                    placeholder="输入延时理由(每笔订单只能延时两次哦)"
                    v-model="unackedForm.delayValue">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="unackedDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="unackedSubmit('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
            
<script>
import Distribution from '@/components/DialogComponents/TeacherResources/sendOrder.vue'
import AbilityRecord from '@/components/DialogComponents/TeacherResources/AbilityRecord.vue'
import TableTittle from '@/components/TableTittle/TableTittle.vue'

import EditOrder from '@/components/DialogComponents/ShareOrder/EditOrder.vue'
import OrderRecord from '@/components/DialogComponents/ShareOrder/OrderRecord.vue'

import InstitutionDetails from '@/components/DialogComponents/InstitutionDetails/index.vue'
import RejectedTeacher from '@/components/RejectedTeacher/RejectedTeacher.vue'
import ShareUp from '@/components/ShareUp/ShareUp.vue'

import { account } from '@/request/api'
import { crmUrl, getStorage } from '@/utils/helper.js'
import { mapMutations } from 'vuex'
export default {
  components: {
    TableTittle,
    EditOrder,
    OrderRecord,
    AbilityRecord,
    InstitutionDetails,
    RejectedTeacher,
    ShareUp,
    Distribution,
  },
  data() {
    return {
      row: {},
      type: '师资推荐',
      distributionId: null,
      manage_ids: '',
      distributionDialog: false,
      uncheck: 0,
      unackedDialogVisible: false,
      detail: {},
      dataItem: {},
      unackedForm: {},
      balance: 0,
      orderId: null,
      orderDialog: false,
      TableName: '订单列表',
      result: [],
      allocation_manage_idses: [getStorage('userInfo').id],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      chooseList: [],
      editId: null,
      editDialog: false,
      recordId: null,
      handle_receiver_id: null,
      recordDialog: false,
      filters: {
        statues: [
          {
            label: '待接单',
            value: 1,
          },
          {
            label: '进行中',
            value: 2,
          },
          {
            label: '待支付',
            value: 3,
          },
          {
            label: '待确认',
            value: 4,
          },
          {
            label: '已完成',
            value: 5,
          },
          {
            label: '已取消',
            value: 6,
          },
        ],
        relateStatues: [
          {
            label: '待分配',
            value: 1,
          },
          {
            label: '匹配中',
            value: 2,
          },
          {
            label: '待确认',
            value: 3,
          },
          {
            label: '已完成',
            value: 4,
          },
          {
            label: '返单中',
            value: 5,
          },
          {
            label: '已取消',
            value: 6,
          },
        ],
      },
      tableType: 1,
      oneDay: 86400000,
      threeDay: 259200000,
      rules: {
        delayValue: [
          { required: true, message: '请输入延时理由', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.parseQuery()
    this.fetchList()
    this.getBalance()

    this.$store.dispatch('getAllColleges')
    this.$store.dispatch('getStaffOptions')
    this.$store.dispatch('getpipeiOptions')
    // this.$store.dispatch('getAllColleges')
    // this.$store.dispatch("getCompanyList");
  },
  watch: {
    // 时间数据监听
  },
  computed: {},
  methods: {
    ...mapMutations(['setUncheckCount']),

    handleRefresh(row) {
      this.$confirm(
        '确定订单继续匹配并刷新置顶吗？每日仅限置顶一次！',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        let str = crmUrl()

        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrderHandle/order/refresh`,
          method: 'post',
          data: {
            find_order_id: row.id,
          },
        }).then((res) => {
          if (res.data.code == 0) {
            this.fetchList()
            this.$message.success('已成功刷新并置顶!')
          }
        })
      })
    },
    getShow(content) {
      let nowTime = new Date().getTime()
      // console.log("111",nowTime-content.refresh_time)
      if (
        content.create_time == content.refresh_time &&
        nowTime - content.refresh_time > this.threeDay
      ) {
        return true
      } else if (
        content.create_time != content.refresh_time &&
        nowTime - content.refresh_time > this.oneDay
      ) {
        return true
      } else {
        return false
      }
    },
    // 修改
    handleUp(row) {
      this.$refs.shareUp.open(row)
    },
    // 驳回
    handleReject(row) {
      this.$refs.rejectedTeacher.open(row.id)
    },
    distribution(row) {
      this.row = row
      // this.type = row.type
      this.distributionId = row.id
      this.manage_ids = this.allocation_manage_idses[0] || ''
      this.distributionDialog = true
    },

    // 确认师资
    certifiedTeacher(row) {
      this.$confirm('是否确认师资', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let str = crmUrl()
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrderHandle/orderTeacher/check`,
          method: 'post',
          data: {
            find_order_id: row.id,
            check_status: 1,
          },
        }).then((res) => {
          if (res.data.code == 0) {
            this.fetchList()
            this.$message.success('确认师资成功!')
          }
        })
      })
    },

    unacked(row) {
      this.$set(this.unackedForm, 'delayValue', '')
      this.dataItem = row
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder`,
        method: 'get',
        params: { id: this.dataItem.id },
      }).then((res) => {
        if (res.data.code == 0) {
          this.detail = res.data.data
        }
      })
      this.unackedDialogVisible = true
    },
    unackedSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.delayed()
        } else {
          return false
        }
      })
    },

    delayed() {
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder/sharePlatformConfigure`,
        method: 'get', //可以省略 不写默认是get
      }).then((res1) => {
        if (res1.data.code == 0) {
          var num = this.dataItem.auto_confirm_add_time_list
            ? this.dataItem.auto_confirm_add_time_list.split(',').length
            : 0

          var time =
            res1.data.data.configure_share_platform_list[1].value.split(',')[
              Number(num)
            ]
          // console.log("num-time",time)
          if (
            num + 1 >
            Number(res1.data.data.configure_share_platform_list[0].value)
          ) {
            this.$message.error('加时次数达到上限')
          } else {
            this.$axios({
              url: `${str}/api/v1/sharePlatform/findOrder/addOrderAutoConfirmTime`,
              method: 'post', //可以省略 不写默认是get
              data: {
                find_order_id: Number(this.dataItem.id),
                add_time_count: num + 1,
                add_time_time: Number(time),
              },
            }).then((res2) => {
              if (res2.data.code == 0) {
                this.$message.success('延时成功!')
                let list = this.detail.arr_receiver_company_user_all.filter(
                  (item) => item.receive_order_delete_status == 0
                )
                console.log('111', list)
                this.$axios({
                  url: `${str}/api/v1/sharePlatform/findOrder/record`,
                  method: 'post', //可以省略 不写默认是get
                  data: {
                    help_find_order_id: Number(this.dataItem.id),
                    sender_company_user_id:
                      this.dataItem.sender_company_user_id,
                    receiver_company_user_id:
                      list.length > 0 ? list[0].receive_order_count : null,
                    type: 13,
                    record: this.unackedForm.delayValue,
                  },
                }).then(() => {
                  this.unackedDialogVisible = false
                  this.fetchList()
                })
              }
            })
          }
        }
      })
    },

    handleExport() {
      this.$confirm('是否导出数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = Object.assign({}, this.query)

        if (params.statues) {
          params.statues = params.statues.join(',')
        }
        if (this.tableType == 1) {
          params.statues = '1'
          params.is_public_room = 1
        } else if (this.tableType == 2) {
          // params.statues = ''
          params.ref_receiver_manage_user_ids =
            this.allocation_manage_idses.toString()
          // if(this.allocation_manage_idses[0]){

          // }else{
          //   params.ref_receiver_manage_user_ids = getStorage('userInfo').id
          // }
        }

        let str = crmUrl()
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrder/export`,
          method: 'get', //可以省略 不写默认是get
          params: params,
        }).then((response) => {
          if (response.data.code == 0) {
            this.$message.success('导出成功')
            //创建a标签用于跳转至下载链接
            var str = response.data.data.url.replace(
              /http:/,
              window.location.protocol
            )
            this.$store.commit('downLoad', str)
          }
        })
      })
    },

    getSubject(row) {
      // scope.row.apply_subject_codes}}){{scope.row.apply_subject_names}
      if (row.apply_subject_codes && row.apply_subject_names) {
        let codeList = row.apply_subject_codes.split(',')
        return (
          row.apply_subject_names
            .split(',')
            .map((item, index) => `(${codeList[index]})${item}`)
            .join(',') + '|'
        )
      }
      return ''
    },

    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.apply_college_code)
    },

    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },

    receiptFetchList() {
      const params = {
        isPaging: 0,
        statues: '4,3',
      }
      params.ref_sender_manage_user_ids = this.allocation_manage_idses[0]
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder/list`,
        method: 'get', //可以省略 不写默认是get
        params: params,
      }).then((response) => {
        if (response.data.code == 0) {
          this.uncheck = response.data.data.count
          this.setUncheckCount(this.uncheck)
        }
      })
    },

    fetchList(isReset = false, label) {
      if (label && label == 3) {
        this.tableType = 3
      }
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      let params = Object.assign({}, this.query)

      if (params.statues) {
        params.statues = params.statues.join(',')
      }
      if (params.ref_allocation_order_statuses) {
        params.ref_allocation_order_statuses =
          params.ref_allocation_order_statuses.join(',')
      }
      if (this.tableType == 1) {
        params.statues = '1'
        params.is_public_room = 1
      } else if (this.tableType == 2) {
        params.ref_receiver_manage_user_ids =
          this.allocation_manage_idses.toString()
      } else if (this.tableType == 3) {
        params.ref_sender_manage_user_ids =
          this.allocation_manage_idses[0] || ''
      } else if (this.tableType == 4) {
        params.is_has_appointer_manage_user = 1
        params.statues = '1'
        params.ref_appointer_manage_user_ids =
          this.allocation_manage_idses[0] || ''
      }

      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder/list`,
        method: 'get', //可以省略 不写默认是get
        params: params,
      }).then((response) => {
        let res = response.data

        this.result = res.data.list || []
        this.pageTotal = res.data.count
      })
      setTimeout(() => {
        this.receiptFetchList()
      }, 500)

      this.syncQuery()
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.statues && this.query.statues.length) {
        this.query.statues = this.query.statues.split(',').map((v) => {
          return Number(v)
        })
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      if (params.statues) {
        params.statues = params.statues.filter((v) => v).join(',')
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },

    formateTime(row) {
      if (row.create_time == row.refresh_time) {
        let time = new Date().getTime() - Number(row.create_time)
        if (time < 60000) {
          return '刚刚发布'
        } else if (time < 3600000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60)
          )}分钟前发布`
        } else if (time < 86400000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60) / 60
          )}小时前发布`
        } else {
          return `${Math.floor(
            Math.floor(Math.floor(Math.round(time / 1000) / 60) / 60) / 24
          )}天前发布`
        }
      } else {
        let time = new Date().getTime() - Number(row.refresh_time)
        if (time < 60000) {
          return '刚刚更新'
        } else if (time < 3600000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60)
          )}分钟前更新`
        } else if (time < 86400000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60) / 60
          )}小时前更新`
        } else {
          return `${Math.floor(
            Math.floor(Math.floor(Math.round(time / 1000) / 60) / 60) / 24
          )}天前更新`
        }
      }
    },

    formateDuration(row) {
      let time = new Date().getTime() - row.create_time
      return this.formatDuring(time)
    },
    formatDuring(millisecond) {
      var days = parseInt(millisecond / (1000 * 60 * 60 * 24))
      var hours = parseInt(
        (millisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      var minutes = parseInt((millisecond % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = (millisecond % (1000 * 60)) / 1000
      if (days < 7) {
        return `<span>${days + '天' + hours + '小时'}</span>`
      } else if (days < 14) {
        return `<span>${days + '天' + hours + '小时'}</span>`
      } else {
        return `<span>${days + '天' + hours + '小时'}</span>`
      }
    },
    formateAsks(row) {
      let num = 0
      for (let k in row) {
        if (k == 'demand_direction_codes' && row[k]) {
          num += 1
        }
        if (k == 'demand_max_hour_amount' && row[k]) {
          num += 1
        }
        if (k == 'demand_academy_major_status' && row[k]) {
          num += 1
        }
        if (k == 'demand_extra_info' && row[k]) {
          num += 1
        }
        if (k == 'extend_info_demand_trial_listen_status' && row[k]) {
          num += 1
        }
        if (
          k == 'extend_info_demand_professional_experience_status' &&
          row[k]
        ) {
          num += 1
        }
        if (k == 'extend_info_demand_before_first_rank' && row[k]) {
          num += 1
        }
        if (k == 'extend_info_demand_min_first_total_score' && row[k]) {
          num += 1
        }
        if (k == 'extend_info_demand_min_first_professional_score' && row[k]) {
          num += 1
        }
        if (k == 'demand_same_reference_book_status' && row[k]) {
          num += 1
        }
      }
      return num
    },
    formateDirections(row) {
      let names = row.demand_direction_names.split(',')
      let codes = row.demand_direction_codes.split(',')

      return names
        .map((v, i) => {
          return `(${codes[i]})${v}`
        })
        .join(',')
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm('确定要删除这些信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let ids = this.chooseList.map((v) => {
          return v.id
        })
        let str = crmUrl()
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrder/list`,
          method: 'delete', //可以省略 不写默认是get
          data: { ids },
        }).then((response) => {
          this.fetchList()
        })
      })
    },

    goPay(row) {
      let price = (
        row.total_in_amount - (row.company_user_pay_coupon_amount || 0)
      ).toFixed(2)
      let hint = `当前余额 ${this.balance} ,将要支付 ${price} ,是否继续?`

      this.$confirm(hint, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (price > this.balance) {
          this.$message.error('余额不足')
          return
        }
        let str = crmUrl()
        this.$axios({
          url: `${str}/api/v1/payment/pay`,
          method: 'post', //可以省略 不写默认是get
          data: {
            ref_id: row.id + '',
            ref_type: '4',
            pay_type: '3',
          },
        }).then((response) => {
          this.$message.success('支付成功')
          this.getBalance()
          this.fetchList()
          // console.log("response:", response);
        })
      })
    },
    getBalance() {
      account({
        cid: 1000,
      }).then((res) => {
        if (res.data.code == 0) {
          this.balance = res.data.data.balance
        }
      })
    },
    cancelOrderChose(row, type) {
      this.$confirm('确定要取消承接该订单吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let str = crmUrl()
        if (type == 1) {
          this.$axios({
            url: `${str}/api/v1/sharePlatform/findOrderHandle/receiveOrder/cancel`,
            method: 'post', //可以省略 不写默认是get
            data: {
              find_order_id: row.id,
            },
          }).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('取消成功')
              this.fetchList()
            }
          })
        } else {
          this.$axios({
            url: `${str}/api/v1/sharePlatform/findOrderHandle/order/cancel`,
            method: 'post', //可以省略 不写默认是get
            data: {
              find_order_id: row.id,
            },
          }).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('取消成功')
              this.fetchList()
            }
          })
        }
      })
    },
    cancelOrder(row) {
      this.$confirm('是否要催支付', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let str = crmUrl()
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrderHandle/order/rushPay`,
          method: 'post', //可以省略 不写默认是get
          data: {
            find_order_id: row.id,
          },
        }).then((response) => {
          let res = response.data
          if (res.code == 0) {
            this.$message.success('催支付成功')
          }
        })
        // rushPay({
        //   find_order_id: row.id
        // }).then(res=>{

        // })
        // let str = crmUrl()
        // this.$axios({
        //   url: `${str}/api/v1/sharePlatform/findOrderHandle/receiveOrder/cancel`,
        //   method: 'post',//可以省略 不写默认是get
        //   data: {
        //     find_order_id: row.id
        //   },
        // }).then(response => {
        //   let res = response.data
        //   if (res.code == 0) {
        //     this.$message.success('接单成功')
        //     this.fetchList()
        //   }
        // })
      })
    },
    reciver(row) {
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrderHandle/receiveOrder/accept`,
        method: 'post', //可以省略 不写默认是get
        data: {
          find_order_id: row.id,
        },
      }).then((response) => {
        let res = response.data
        if (res.code == 0) {
          this.$message.success('接单成功')
          this.tableType = 2
          this.fetchList(true)
        }
      })
    },
    handleToEdit(row) {
      this.editId = row.id
      this.editDialog = true
    },
    handleToRecord(row) {
      this.recordDialog = true
      this.recordId = row.id
      this.handle_receiver_id = row.receiver_company_user_ids_real
        ? row.receiver_company_user_ids_real
        : null
    },

    handleToAbility(row) {
      this.orderId = row.id
      this.orderDialog = true
    },

    handle(row) {
      window.open(
        `/tr/share/handle/${row.id}?name=${
          row.sender_company_user_obj
            ? row.sender_company_user_obj.company_ref_name +
              row.sender_company_user_obj.alias
            : ''
        }`
      )
    },
  },
}
</script>
            
      <style lang="less" scoped>
.content {
  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }
  .el-link {
    font-size: 14px;
    margin: 0px 5px;
  }
  .img-list {
    margin-top: 12px;
    display: flex;
    align-items: center;
    img {
      width: 140px;
      height: 140px;
      margin-right: 12px;
      border-radius: 4px;
    }
  }
  .popver-content {
    .item {
      display: flex;
      align-items: flex-start;
    }
  }
}
.count-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
}
.other {
  font-weight: 600;
  font-size: 16px;
}
.line {
  opacity: 1;
  border: 1px solid #656565;
  margin: 12px 0px;
}
</style>
            