<template>
  <!-- title的英文拼成title了，注意变量名 -->
  <div class="table-title">
    <div class="line"></div>
    <!--  <el-divider direction="vertical"></el-divider> -->
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.table-title {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  .line {
    width: 4px;
    height: 18px;
    background: #409eff;
    border-radius: 4px 4px 4px 4px;
    margin-right: 4px;
  }
  .el-divider--vertical {
    background-color: #409eff;
    border-radius: 4px;
    border: 2px solid #409eff;
  }
  span {
    font-size: 16px;
    font-weight: 800;
    color: #252631;
    opacity: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>