<template>
  <div>
    <el-dialog top="5vh" title="新建订单价格" :visible.sync="dialogFormVisible" @close="handleToClose"
      :close-on-click-modal="false" width="720px">
      <el-form :model="dataForm" label-position="right" ref="formRules" :rules="rules" label-width="110px" size="small">
        <el-form-item label="机构简称：" prop="cid">
          <el-select filterable v-model="dataForm.cid" placeholder="请选择" style="width: 100%;">
            <el-option v-for="item in $store.state.CompanyList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型：" prop="order_type">
          <el-select filterable v-model="dataForm.order_type" style="width: 100%;" placeholder="请选择">
            <el-option label="外包" :value="1">
            </el-option>
            <el-option label="代找" :value="2">
            </el-option>
            <el-option label="代签" :value="3">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="初复试类型：" prop="exam_time_type">
          <el-select filterable v-model="dataForm.exam_time_type" style="width: 100%;" placeholder="请选择">
            <el-option label="不限" :value="0">
            </el-option>
            <el-option label="初试" :value="1">
            </el-option>
            <el-option label="复试" :value="2">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格类型：" prop="price_type">
          <div style="display: flex;">
            <el-select filterable v-model="dataForm.price_type" style="width: 100%;" placeholder="请选择"
              @change="price_typeChange">
              <el-option label="不限" :value="0">
              </el-option>
              <el-option label="院校" :value="1">
              </el-option>
              <el-option label="专业" :value="2">
              </el-option>
            </el-select>
            <el-button size="small" style="margin-left: 5px;" @click="priceType = true" v-if="!priceType">添加</el-button>
          </div>
        </el-form-item>
        <el-form-item label="价格子类型：" prop="sub_price_type" v-if="dataForm.price_type == 1">
          <el-select filterable v-model="dataForm.sub_price_type" style="width: 100%;" @change="sub_price_typeChange"
            placeholder="请选择">
            <el-option label="院校分类" :value="1">
            </el-option>
            <el-option label="院校地区" :value="2">
            </el-option>
            <el-option label="指定院校" :value="3">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格子类型：" prop="sub_price_type" v-if="dataForm.price_type == 2">
          <el-select filterable v-model="dataForm.sub_price_type" style="width: 100%;" placeholder="请选择">
            <el-option label="一级门类" :value="1">
            </el-option>
            <el-option label="二级学科" :value="2">
            </el-option>
            <el-option label="指定专业" :value="3">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="院校分类：" prop="target_ranges" v-if="dataForm.price_type == 1 && dataForm.sub_price_type == 1">
          <el-select filterable v-model="dataForm.target_ranges" style="width: 100%;" placeholder="请选择" multiple>
            <el-option label="985院校" :value="1">
            </el-option>
            <el-option label="211院校" :value="2">
            </el-option>
            <el-option label="一流大学" :value="3">
            </el-option>
            <el-option label="一流学科院校" :value="4">
            </el-option>
            <el-option label="自主划线院校" :value="5">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="院校地区：" prop="target_ranges" v-if="dataForm.price_type == 1 && dataForm.sub_price_type == 2">
          <el-select filterable v-model="dataForm.target_ranges" style="width: 100%;" placeholder="请选择" multiple>
            <el-option v-for="item in provinces" :key="item.id" :label="item.name" :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指定院校：" prop="target_ranges" v-if="dataForm.price_type == 1 && dataForm.sub_price_type == 3">
          <div class="user-wrap">
            <div class="top">
              <el-link type="primary" :underline="false" @click="handleInstitutional">选择院校</el-link>
            </div>
            <div v-if="chooseList.length > 0" style="display: flex;flex-wrap: wrap; width: 500px;">
              <div class="bottom" v-for="(item, index) in chooseList" :key="index"
                style="margin-right: 5px;margin-top: 5px;">
                <el-tag :disable-transitions="false" style="display: flex;">
                  <div>
                    {{ item.name }}
                    <i class="el-icon-remove-outline" style="cursor: pointer;" @click="delAcademy(item.id)"></i>
                  </div>
                  <!-- {{ chooseList.map(item => `${item.name}`).join(' / ') }} -->
                </el-tag>

              </div>
            </div>
            <!-- <div class="bottom" v-if="chooseList.length>0">
              <el-tag :disable-transitions="false" >
                {{ chooseList.map(item=>`${item.name}`).join(' / ') }}
              </el-tag>
            </div> -->
          </div>
          <!-- <el-select
            filterable
            v-model="dataForm.target_ranges"
            style="width: 100%;"
            placeholder="请选择"
            multiple
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="一级门类：" prop="target_ranges" v-if="dataForm.price_type == 2 && dataForm.sub_price_type == 1">
          <el-select filterable v-model="dataForm.target_ranges" style="width: 100%;" placeholder="请选择" multiple>
            <el-option v-for="(item, index) in majors_level1" :key="index" :label="item.name" :value="item.code">
              （{{ item.code }}）{{ item.name }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二级学科：" prop="target_ranges" v-if="dataForm.price_type == 2 && dataForm.sub_price_type == 2">
          <el-select filterable v-model="dataForm.target_ranges" style="width: 100%;" placeholder="请选择" multiple>
            <el-option v-for="(item, index) in majors_level2" :key="index" :label="item.name" :value="item.code">
              （{{ item.code }}）{{ item.name }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指定专业：" prop="target_ranges" v-if="dataForm.price_type == 2 && dataForm.sub_price_type == 3">
          <div class="choose-tag-wrap">
            <div class="link-wrap">
              专业选择
              <el-link type="primary" :underline="false" @click="majorChooseChange">选择专业</el-link>
            </div>
            <div class="tags-wrap">
              <el-tag :key="index" v-for="(item, index) in dataForm.target_ranges" closable :disable-transitions="false"
                @close="handleMajorRemove(item)">
                ({{ item.code }}){{ item.name }}
              </el-tag>
            </div>
          </div>
        </el-form-item>









        <div v-if="priceType">
          <el-form-item label="价格类型："  prop="extend_second_price_type">
            <div style="display: flex;">
              <el-select filterable v-model="dataForm.extend_second_price_type" style="flex: auto;" placeholder="请选择"
                @change="price_typeChangeTwo">
                <el-option label="不限" :value="0">
                </el-option>
                <el-option label="院校" :value="1">
                </el-option>
                <el-option label="专业" :value="2">
                </el-option>
              </el-select>
              <el-button size="small" style="margin-left: 5px;" @click="priceTypeDel">删除</el-button>
              <!-- <el-button size="small" style="margin-left: 5px;" @click="priceType = true">添加</el-button> -->
            </div>
          </el-form-item>

          <el-form-item label="价格子类型：" v-if="dataForm.extend_second_price_type == 1" prop="extend_second_sub_price_type">
            <el-select filterable v-model="dataForm.extend_second_sub_price_type" style="width: 100%;" placeholder="请选择"
              @change="sub_price_typeChangeTwo">
              <el-option label="院校分类" :value="1">
              </el-option>
              <el-option label="院校地区" :value="2">
              </el-option>
              <el-option label="指定院校" :value="3">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="价格子类型：" v-if="dataForm.extend_second_price_type == 2" prop="extend_second_sub_price_type">
            <el-select filterable v-model="dataForm.extend_second_sub_price_type" style="width: 100%;"
              @change="sub_price_typeChangeTwo" placeholder="请选择">
              <el-option label="一级门类" :value="1">
              </el-option>
              <el-option label="二级学科" :value="2">
              </el-option>
              <el-option label="指定专业" :value="3">
              </el-option>
            </el-select>
          </el-form-item>


          <el-form-item label="院校分类："
            v-if="dataForm.extend_second_price_type == 1 && dataForm.extend_second_sub_price_type == 1" prop="extend_second_target_ranges">
            <el-select filterable v-model="dataForm.extend_second_target_ranges" style="width: 100%;" placeholder="请选择"
              multiple>
              <el-option label="985院校" :value="1">
              </el-option>
              <el-option label="211院校" :value="2">
              </el-option>
              <el-option label="一流大学" :value="3">
              </el-option>
              <el-option label="一流学科院校" :value="4">
              </el-option>
              <el-option label="自主划线院校" :value="5">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="院校地区："
            v-if="dataForm.extend_second_price_type == 1 && dataForm.extend_second_sub_price_type == 2" prop="extend_second_target_ranges">
            <el-select filterable v-model="dataForm.extend_second_target_ranges" style="width: 100%;" placeholder="请选择"
              multiple>
              <el-option v-for="item in provinces" :key="item.id" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>



          <el-form-item label="指定院校：" prop="extend_second_target_ranges"
            v-if="dataForm.extend_second_price_type == 1 && dataForm.extend_second_sub_price_type == 3">
            <div class="user-wrap">
              <div class="top">
                <el-link type="primary" :underline="false" @click="handleInstitutionalTwo">选择院校</el-link>
              </div>
              <div v-if="secondList.length > 0" style="display: flex;flex-wrap: wrap; width: 500px;">
                <div class="bottom" v-for="(item, index) in secondList" :key="index"
                  style="margin-right: 5px;margin-top: 5px;">
                  <el-tag :disable-transitions="false" style="display: flex;">
                    <div>
                      {{ item.name }}
                      <i class="el-icon-remove-outline" style="cursor: pointer;" @click="delAcademyTwo(item.id)"></i>
                    </div>
                  </el-tag>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="一级门类：" prop="extend_second_target_ranges"
            v-if="dataForm.extend_second_price_type == 2 && dataForm.extend_second_sub_price_type == 1">
            <el-select filterable v-model="dataForm.extend_second_target_ranges" style="width: 100%;" placeholder="请选择"
              multiple>
              <el-option v-for="(item, index) in majors_level1" :key="index" :label="item.name" :value="item.code">
                （{{ item.code }}）{{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="二级学科：" prop="extend_second_target_ranges"
            v-if="dataForm.extend_second_price_type == 2 && dataForm.extend_second_sub_price_type == 2">
            <el-select @change='() => { $forceUpdate() }' filterable v-model="dataForm.extend_second_target_ranges"
              style="width: 100%;" placeholder="请选择" multiple>
              <el-option v-for="(item, index) in majors_level2" :key="index" :label="item.name" :value="item.code">
                （{{ item.code }}）{{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="指定专业：" prop="extend_second_target_ranges"
            v-if="dataForm.extend_second_price_type == 2 && dataForm.extend_second_sub_price_type == 3">
            <div class="choose-tag-wrap">
              <div class="link-wrap">
                专业选择
                <el-link type="primary" :underline="false" @click="majorChooseChangeTwo">选择专业</el-link>
              </div>
              <div class="tags-wrap">
                <el-tag :key="index" v-for="(item, index) in dataForm.extend_second_target_ranges" closable
                  :disable-transitions="false" @close="handleMajorRemoveTwo(item)">
                  ({{ item.code }}){{ item.name }}
                </el-tag>
              </div>
            </div>
          </el-form-item>
        </div>

















        <el-form-item label="价格：" prop="price">
          <el-input v-model.number="dataForm.price" placeholder="请输入价格" clearable
            oninput="value=value.replace(/[^0-9.]/g,'')" style="width: 200px;">
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="有效期：">
          <el-date-picker class="datetime" v-model="time" type="datetimerange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']" clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="优先值：" prop="sort">
          <el-input v-model.number="dataForm.sort" placeholder="请输入优先值" clearable
            oninput="value=value.replace(/[^0-9.]/g,'')" style="width: 150px;"></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button type="primary" @click="submit('formRules')">确定</el-button>
      </div>
    </el-dialog>
    <MajorChoose :visible="majorChooseDialog" :majors="this.dataForm.target_ranges" @close="majorChooseDialog = false"
      @submit="saveMajor"></MajorChoose>

    <InstitutionalManagement ref="institutionalManagement" @submit="chooseInstitution"></InstitutionalManagement>
  </div>
</template>
<script>
import {
  orderPriceEdit,
  orderPriceOne
} from "@/request/api";
import { adminUrl } from "@/utils/helper.js";
import MajorChoose from "@/components/DialogComponents/MajorChoose.vue";
import InstitutionalManagement from "@/components/DialogComponents/InstitutionalManagement/InstitutionalManagement.vue";
import { log } from 'mathjs';

export default {
  components: {
    MajorChoose,
    InstitutionalManagement
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      priceType: false,
      dialogFormVisible: this.isVisible,
      dataForm: {},
      rules: {
        cid: [{ required: true, message: "请选择机构", trigger: "blur" }],
        order_type: [{ required: true, message: "请选择订单类型", trigger: "blur" }],
        exam_time_type: [{ required: true, message: "请选择初复试类型", trigger: "blur" }],
        price_type: [{ required: true, message: "请选择价格类型", trigger: "blur" }],
        sub_price_type: [{ required: true, message: "请选择价格子类型", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        sort: [{ required: true, message: "请输入优先值", trigger: "blur" }],

        target_ranges: [{
          required: true,
          validator: (rule, value, callback) => {
            console.log(this.dataForm.target_ranges);
            if (!this.dataForm.target_ranges.length) {
              callback(new Error('请选择目标范围'))
            } else {
              callback()
            }
          },
        }],
        extend_second_price_type: [{ required: true, message: "请选择价格类型", trigger: "blur" }],
        extend_second_sub_price_type: [{ required: true, message: "请选择价格子类型", trigger: "blur" }],
        extend_second_target_ranges: [{
          required: true,
          validator: (rule, value, callback) => {
            // console.log(this.dataForm.target_ranges);
            if (!this.dataForm.extend_second_target_ranges.length) {
              callback(new Error('请选择目标范围'))
            } else {
              callback()
            }
          },
        }],


      },
      provinces: [],
      majors_level1: [],
      majors_level2: [],
      majorChooseDialog: false,
      time: '',
      chooseList: [],
      secondList: [],
      flagInstitutional: 1,
      majorChooseFlag: 1
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
        this.fetchProvinceList()
        this.fetchMajorList()
      }
    },
  },
  methods: {
    priceTypeDel() {
      this.priceType = false
      this.$set(this.dataForm, 'extend_second_price_type', null)
      this.$set(this.dataForm, 'extend_second_sub_price_type', null)
      this.$set(this.dataForm, 'extend_second_target_ranges', [])
    },
    // delAcademy(id) {
    //   this.chooseList = this.chooseList.filter(item => item.id != id)
    //   this.dataForm.target_ranges =  this.chooseList.map(item => item.code)
    // },
    // chooseInstitution(list) {
    //   this.chooseList = list
    //   this.dataForm.target_ranges = list.map(item => item.code)
    // },
    // handleInstitutional() {
    //   this.chooseList.forEach((item, index) => {
    //     this.$set(this.chooseList[index], 'flag', '')
    //   })
    //   this.$refs.institutionalManagement.open(this.chooseList)
    // },
    delAcademy(id) {
      this.chooseList = this.chooseList.filter(item => item.id != id)
      this.dataForm.target_ranges = this.chooseList.map(item => item.code)

    },

    delAcademyTwo(id) {
      this.secondList = this.secondList.filter(item => item.id != id)
      this.dataForm.extend_second_target_ranges = this.secondList.map(item => item.code)

    },

    chooseInstitution(list) {
      if (this.flagInstitutional == 1) {
        this.chooseList = list
        this.dataForm.target_ranges = list.map(item => item.code)
      } else {
        this.secondList = list
        this.dataForm.extend_second_target_ranges = list.map(item => item.code)
        console.log("bb", this.secondList, this.dataForm.extend_second_target_ranges)
      }

    },

    // chooseInstitutionTwo(list) {

    // },

    handleInstitutional() {
      this.flagInstitutional = 1
      this.chooseList.forEach((item, index) => {
        this.$set(this.chooseList[index], 'flag', '')
      })
      this.$refs.institutionalManagement.open(this.chooseList)
    },

    handleInstitutionalTwo() {
      this.flagInstitutional = 2
      this.secondList.forEach((item, index) => {
        this.$set(this.secondList[index], 'flag', '')
      })
      this.$refs.institutionalManagement.open(this.secondList)
    },



    majorChooseChange() {
      this.majorChooseFlag = 1
      // this.dataForm.target_ranges = []
      this.majorChooseDialog = true
    },
    majorChooseChangeTwo() {
      this.majorChooseFlag = 2
      // this.dataForm.extend_second_target_ranges = []
      this.majorChooseDialog = true
    },
    // 省份列表请求
    fetchProvinceList() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/province/list`,
        method: 'get',//可以省略 不写默认是get
      }).then(res => {
        this.provinces = res.data.data.list
      })
    },
    fetchMajorList() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/major/list`,
        method: 'get',//可以省略 不写默认是get
      }).then(response => {
        let res = response.data
        this.majors_level1 = res.data.list[0].children.concat(res.data.list[1].children)
        this.majors_level1.forEach(item => {
          this.majors_level2 = this.majors_level2.concat(item.children)
        });
      })
    },

    fetchDetail() {
      orderPriceOne({ id: this.id }).then((response) => {
        this.chooseList = response.data.data.college_list || []
        this.secondList = response.data.data.extend_second_college_list || []
        // console.log("cc", response)
        let res = response.data
        this.$set(this.dataForm, 'id', res.data.id)
        this.$set(this.dataForm, 'cid', res.data.cid)
        this.$set(this.dataForm, 'order_type', res.data.order_type)
        this.$set(this.dataForm, 'exam_time_type', res.data.exam_time_type)
        this.$set(this.dataForm, 'sub_price_type', res.data.sub_price_type)
        this.$set(this.dataForm, 'price_type', res.data.price_type)
        this.$set(this.dataForm, 'sort', res.data.sort)
        if(res.data.extend_second_price_type){
          this.priceType = true
        }else{
          this.priceType = false
        }
        this.$set(this.dataForm, 'extend_second_sub_price_type', JSON.parse(JSON.stringify(res.data.extend_second_sub_price_type)))
        this.$set(this.dataForm, 'extend_second_price_type', res.data.extend_second_price_type)
        if (res.data.extend_second_price_type == 1 && res.data.extend_second_sub_price_type == 1 && res.data.extend_second_college_category_list) {
          this.$set(this.dataForm, 'extend_second_target_ranges', res.data.extend_second_college_category_list.map(v => {
            return Number(v.id)
          }))
        } else if (res.data.extend_second_price_type == 1 && res.data.extend_second_sub_price_type == 2 && res.data.extend_second_college_province_list) {
          this.$set(this.dataForm, 'extend_second_target_ranges', res.data.extend_second_college_province_list.map(v => {
            return v.code
          }))
        } else if (res.data.extend_second_price_type == 1 && res.data.extend_second_sub_price_type == 3 && res.data.extend_second_college_list) {
          this.$set(this.dataForm, 'extend_second_target_ranges', res.data.extend_second_college_list.map(v => {
            return v.code
          }))
        } else if (res.data.extend_second_price_type == 2 && res.data.extend_second_sub_price_type == 1 && res.data.extend_second_major_door_list) {
          this.$set(this.dataForm, 'extend_second_target_ranges', res.data.extend_second_major_door_list.map(v => {
            return v.code

          }))
        } else if (res.data.extend_second_price_type == 2 && res.data.extend_second_sub_price_type == 2 && res.data.extend_second_major_displine_list) {
          this.$set(this.dataForm, 'extend_second_target_ranges', res.data.extend_second_major_displine_list.map(v => {
            return v.code

          }))
        } else if (res.data.extend_second_price_type == 2 && res.data.extend_second_sub_price_type == 3 && res.data.extend_second_major_list) {
          this.$set(this.dataForm, 'extend_second_target_ranges', res.data.extend_second_major_list.map(v => {
            return {
              code: v.code,
              name: v.major_name,
            }

          }))
        }


        this.$set(this.dataForm, 'price', res.data.price)
        this.time = res.data.start_time && res.data.end_time ? [res.data.start_time, res.data.end_time] : []
        if (res.data.price_type == 1 && res.data.sub_price_type == 1) {
          this.$set(this.dataForm, 'target_ranges', res.data.college_category_list.map(v => {
            return Number(v.id)
          }))
        } else if (res.data.price_type == 1 && res.data.sub_price_type == 2) {
          this.$set(this.dataForm, 'target_ranges', res.data.college_province_list.map(v => {
            return v.code
          }))
        } else if (res.data.price_type == 1 && res.data.sub_price_type == 3) {
          this.$set(this.dataForm, 'target_ranges', res.data.college_list.map(v => {
            return v.code
          }))
        } else if (res.data.price_type == 2 && res.data.sub_price_type == 1) {
          this.$set(this.dataForm, 'target_ranges', res.data.major_door_list.map(v => {
            return v.code

          }))
        } else if (res.data.price_type == 2 && res.data.sub_price_type == 2) {
          this.$set(this.dataForm, 'target_ranges', res.data.major_displine_list.map(v => {
            return v.code

          }))
        } else if (res.data.price_type == 2 && res.data.sub_price_type == 3) {
          this.$set(this.dataForm, 'target_ranges', res.data.major_list.map(v => {
            return {
              code: v.code,
              name: v.major_name,
            }

          }))
        }
        console.log(this.dataForm);
      })
    },

    handleToClose() {
      this.$emit("close");
      this.dataForm = {}
    },
    formateTarget_ranges() {
      if (!this.dataForm.target_ranges) {
        return ''
      }
      if (this.dataForm.price_type == 2 && this.dataForm.sub_price_type == 3) {
        return this.dataForm.target_ranges.map(v => {
          return v.code
        }).join(',')
      } else {
        return this.dataForm.target_ranges.join(',')
      }
    },
    formateTarget_rangesTwo() {
      // console.log('this.dataForm.extend_second_target_ranges',this.dataForm.extend_second_target_ranges);
      if(this.dataForm.extend_second_target_ranges&&this.dataForm.extend_second_target_ranges.length){
         if (this.dataForm.extend_second_price_type == 2 && this.dataForm.extend_second_sub_price_type == 3) {
        return this.dataForm.extend_second_target_ranges.map(v => {
          return v.code
        }).join(',')
      } else {
        return this.dataForm.extend_second_target_ranges.join(',')
      }
      }else{
        return ''
      }
     
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let data = {
            id: this.dataForm.id,
            cid: this.dataForm.cid,
            order_type: this.dataForm.order_type,
            exam_time_type: this.dataForm.exam_time_type,
            price_type: this.dataForm.price_type,
            sub_price_type: this.dataForm.sub_price_type ? this.dataForm.sub_price_type : 0,
            target_ranges: this.formateTarget_ranges(),
            price: this.dataForm.price,
            sort: this.dataForm.sort,
            start_time: this.time && this.time[0] ? this.time[0] : null,
            end_time: this.time && this.time[1] ? this.time[1] : null,

            extend_second_price_type: this.dataForm.extend_second_price_type,
            extend_second_sub_price_type: this.dataForm.extend_second_sub_price_type ? this.dataForm.extend_second_sub_price_type : 0,
            extend_second_target_ranges: this.formateTarget_rangesTwo()
          }
         
          orderPriceEdit(data).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveMajor({ majors }) {
      // console.log("majors", majors)
      // this.dataForm.target_ranges = majors;
      if (this.majorChooseFlag == 1) {
        this.dataForm.target_ranges = majors.filter(item => item.constructor == Object);
      } else {
        this.dataForm.extend_second_target_ranges = majors.filter(item => item.constructor == Object);
      }
    },
    handleMajorRemove(item) {
      this.dataForm.target_ranges = this.dataForm.target_ranges.filter((v) => v.code !== item.code);
    },

    handleMajorRemoveTwo(item) {
      this.dataForm.extend_second_target_ranges = this.dataForm.extend_second_target_ranges.filter((v) => v.code !== item.code);
    },

    price_typeChange() {
      this.$set(this.dataForm, 'sub_price_type', '')

      this.$set(this.dataForm, 'target_ranges', [])
      this.dataForm.target_ranges = []
      this.$forceUpdate()
    },

    price_typeChangeTwo() {
      this.$set(this.dataForm, 'extend_second_sub_price_type  ', '')

      this.$set(this.dataForm, 'extend_second_target_ranges', [])
      this.dataForm.extend_second_target_ranges = []
      this.$forceUpdate()
    },

    sub_price_typeChange() {
      console.log("11111")
      this.$set(this.dataForm, 'target_ranges', [])
      this.dataForm.target_ranges = []
      this.$forceUpdate()

    },

    sub_price_typeChangeTwo() {
      console.log("11111")
      this.$set(this.dataForm, 'extend_second_target_ranges ', [])
      this.dataForm.extend_second_target_ranges = []
      this.$forceUpdate()
    }
  },
};
</script>
<style lang="less" scoped>
.choose-tag-wrap {
  .link-wrap {
    display: flex;
    align-items: center;

    .el-link {
      margin-left: 16px;
    }
  }

  .tags-wrap {
    .el-tag {
      margin: 4px;
    }
  }
}
</style>