<template>
  <div class="content">
    <el-dialog
top="5vh"
      title="师资信息"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="501px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="100px"
        label-position="right"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item
          label="姓名"
          prop="name"
        >
          <el-input
            v-model="form.name"
            placeholder="请输入姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="性别"
          prop="gender"
        >
          <el-radio-group v-model="form.gender">
            <el-radio label="男">男</el-radio>
            <el-radio label="女">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="年级"
          prop="apply_year"
        >
          <el-select
            v-model="form.apply_year"
            placeholder="请选择"
          >

            <el-option
              label="2019"
              value="2019"
            ></el-option>
            <el-option
              label="2020"
              value="2020"
            ></el-option>
            <el-option
              label="2021"
              value="2021"
            ></el-option>
            <el-option
              label="2022"
              value="2022"
            ></el-option>
                 <el-option
              label="2023"
              value="2023"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input
            placeholder="请输入内容"
            v-model="form.phone"
            class="input-with-select"
          >
            <template slot="prepend">手机号</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label=""
          style="margin-top: -20px"
          prop="wechat"
        >
          <el-input
            placeholder="请输入内容"
            v-model="form.wechat"
            class="input-with-select"
          >
            <template slot="prepend">微信号</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label=""
          style="margin-top: -20px"
        >
          <el-input
            placeholder="请输入内容"
            v-model="form.qq"
            class="input-with-select"
          >
            <template slot="prepend">&nbsp;QQ号</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="方向"
          prop=""
          style="margin-top: -20px"
        >
          <el-input
            v-model="form.direction"
            placeholder="请输入方向"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="分数"
          prop=""
        >
          <el-input
            v-model="form.fraction"
            placeholder="请输入分数"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="备注"
          prop=""
        >
          <el-input
            type="textarea"
            v-model="form.remark"
            placeholder="请输入备注"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit()"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { crmOutrecorddetail } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      form: {
        phone: "",
        wechat: "",
        qq: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
        apply_year: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
      },
    };
  },
  mounted() { },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.init(val)
      /*     if (val.result == null) {
            this.form = {};
          } else {
            this.form = val.result;
          } */
    },
  },
  methods: {
    init(val) {
      if (val.old_id == null) {
        if (val.result == null) {
          this.form = {};
        } else {
          this.form = val.result;
        }
      } else {
        let forQuery = {
          id: val.old_id,
          newId: val.id,
        }
        // 请求每一条数据的详情
        crmOutrecorddetail(forQuery).then((response) => {
          if (response.data.data.message == "ok") {
            console.log(response.data.data.data);
            if (val.result != null) {
              this.form = val.result;
            } else if (response.data.data.data.teacherName) {
              this.form.name = response.data.data.data.teacherName
              this.form.phone = response.data.data.data.teacherMobile
              this.form.qq = response.data.data.data.teacherQq
              this.form.apply_year = response.data.data.data.teacherYear
              this.form.remark = response.data.data.data.teacherOthers
            } else {
              this.form = {};
            }
          }
        })
      }
    },
    emitClose() {
      this.$emit("close");
    },
    submit() {
      if (!this.form.name) {
        this.$message({
          message: "请填写姓名",
          type: "error",
          duration: 1000,
        });
        return false;
      }
      if (!this.form.apply_year) {
        this.$message({
          message: "请选择年级",
          type: "error",
          duration: 1000,
        });
        return false;
      }
      if (!this.form.phone && !this.form.wechat && !this.form.qq) {
        this.$message({
          message: "至少填写一种联系方式",
          type: "error",
          duration: 1000,
        });
        return false;
      }
      /*       if (!/^1[123456789]\d{9}$/.test(this.form.phone)) {
        this.$message({
          message: "请填写正确的手机号",
          type: "error",
          duration: 1000,
        });
        return false;
      } */
      this.$emit("submit", { form: JSON.stringify(this.form) });
    },
  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
.el-input {
  width: 350px;
}
</style>