<template>
  <div class="dialog-wrap">
    <el-dialog
    append-to-body
top="5vh"
      title="授课老师"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="1400px"
    >
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="mini"
      >
        <el-form-item label="老师关键字">
          <el-input
            v-model.trim="query.keyword"
            placeholder="ID/姓名/手机号/备注"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="院校">
          <el-input
            v-model.trim="query.college"
            placeholder="请输入院校"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="专业">
          <el-input
            v-model.trim="query.major"
            placeholder="请输入专业"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="班级">
          <el-input
            v-model.trim="query.grade_keyword"
            placeholder="请输入班级关键字"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="学生关键字">
          <el-input
            v-model.trim="query.student_keyword"
            placeholder="姓名/手机号"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <el-table
        class="result"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{  fontSize: '13px' }"
        border
      >
        <el-table-column
          prop="id"
          label="ID"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="realname"
          align="center"
          label="姓名"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          label="性别"
          align="center"
          width="50"
        >
          <template slot-scope="scope">
            {{scope.row.gender==1?'男':'女'}}
          </template>
        </el-table-column>
        <el-table-column
          label="确认状态"
          align="center"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              v-if="scope.row.confirm_status == 1"
              type="success"
            >已确认</el-tag>
            <el-tag
              size="small"
              v-if="scope.row.confirm_status == 0"
              type="warning"
            >未确认</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="考研年份"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            {{scope.row.recruit_year}}年
          </template>
        </el-table-column>
        <el-table-column
          label="录取信息"
          align="left"
          width="260"
          show-overflow-tooltip
        >

          <template slot-scope="scope">
            {{scope.row.recruit_college_name}}/{{scope.row.recruit_major_name}}
          </template>
        </el-table-column>
        <el-table-column
          label="课时费"
          align="center"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.fee_amount?scope.row.fee_amount+'元/小时':'暂无' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="综合评分"
          align="center"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.teacher_composite_score?scope.row.teacher_composite_score+'分':'暂无' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="manage_remark"
          label="备注"
          align="center"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="创建人"
          align="center"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="创建日期"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="修改日期"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              :disabled="content.findIndex(v=>{ return v.id==scope.row.id })!=-1"
              @click="chooseTeacher(scope.row)"
            >选择</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          small
          layout="total, prev, pager, next,sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="handleSubmit"
        >确 定</el-button>
      </div>
    </el-dialog>
    <TeacherInfo
      :content="TeacherInfoContent"
      @close="TeacherInfoDialog=false"
      :isVisible="TeacherInfoDialog"
      @submit="emitClose"
      :class_id="this.id"
    />
  </div>
</template>

<script>
import {
  teacherlist,
  gradeOne,
  gradeEdit,
} from "@/request/api";
import TeacherInfo from "@/components/DialogComponents/ClassService/TeacherInfo.vue";


export default {
  components: {
    TeacherInfo
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      dialogVisible: this.isVisible,
      query: {
        keyword: "",
        college: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 10,
      results: [],
      selections: [],
      countSelections: [],
      content: [],
      TeacherInfoContent: {},
      TeacherInfoDialog: false,
    };
  },

  watch: {
    isVisible(val) {
      this.dialogVisible = val;
      if (val) {
        this.query = {
          pageIndex: 1,
          pageSize: 10,
          isPaging: 1,
        }
        this.fetchList();
      }
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      let params = { ...this.query };
      teacherlist(params).then((response) => {
        let res = response.data
        this.results = res.data.list || [];
        this.pageTotal = res.data.count;
        this.fetchDetail()
      });
    },
    fetchDetail() {
      gradeOne({ id: this.id }).then((response) => {
        let res = response.data
        this.content = res.data.teachers ? res.data.teachers : []

        this.$nextTick(this.setRowSelection);
      });
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    // 设置选中行
    setRowSelection() {
      if (this.results.length) {
        this.results.forEach((row) => {
          if (this.content.find((item) => item.id === row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageIndex", 1);
      this.fetchList();
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields();
      this.fetchList();
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.fetchList();
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val;
      this.diffSelection();
    },
    handleSubmit() {
      this.emitClose()
      /*  let query = {
         id: this.id,
         arr_teacher: this.countSelections.map(v => {
           return {
             student_id: v.id
           }
         })
       }
       gradeEdit(query).then((res) => {
         if (res.data.code == 0) {
           this.emitClose()
         }
       }); */
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        keyword: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      };
      this.$emit('submit')
      this.$emit("close");
    },
    // 选中项差异化计算
    diffSelection() {
      let diffs = [];
      // 减
      this.countSelections.forEach((item, index) => {
        if (!this.selections.find((v) => v.id === item.id)) {
          if (this.results.find((v2) => v2.id === item.id)) {
            diffs.push(item);
          }
        }
      });
      if (diffs.length) {
        this.countSelections = this.countSelections.filter(
          (item) => !diffs.find((v) => v.id === item.id)
        );
      }
      // 加
      this.selections.forEach((item) => {
        if (!this.countSelections.find((v) => v.id === item.id)) {
          this.countSelections.push(item);
        }
      });
    },

    handleToChoose(row) {
      let data = [row]
      this.$emit("submit", { data: data });
      this.handleClose();
    },
    collegeFormate(row) {
      if (row.apply_college_name && row.apply_major_code && row.apply_major_name) {
        return `${row.apply_college_name},（${row.apply_major_code}）${row.apply_major_name}`
      } else {
        return "暂无定校"
      }
    },
    formateManage_Watcher(val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.nickname + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    chooseTeacher(row) {
      this.TeacherInfoContent = Object.assign({}, row)
      this.TeacherInfoDialog = true
    }
  },
};
</script>

<style lang="less" scoped>
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    margin-top: 10px;
  }
  .now {
    margin-left: 16px;
    color: red;
  }
}
.el-input {
  width: 150px;
}
</style>
