<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/tr/share' }">师资推荐</el-breadcrumb-item>
        <el-breadcrumb-item>订单处理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button type="primary"
                   @click="handleChect()"
                   size="small">沟通记录</el-button>
        <el-button type="primary"
                   @click="handleToShareRecord()"
                   size="small">操作记录</el-button>
        <el-button type="primary"
                   @click="reciver()"
                   v-if="detail.status == 1"
                   size="small">接单</el-button>
        <el-button type="primary"
                   @click="submitDialog = true"
                   size="small"
                   v-else-if="[2, 3, 4].includes(detail.status)">提交师资</el-button>
      </div>
    </div>
    <el-card style="margin-top: 28px;position: relative;">
      <div class="urgent"
           v-if="detail.in_urgency_amount && detail.in_urgency_amount > 0">
        <img src="@/assets/images/urgent.png"
             alt=""
             srcset="">
      </div>
      <div class="header">
        <TableTittle :title="pageTitle"></TableTittle>
      </div>
      <el-descriptions class="margin-top"
                       :column="2"
                       border>
        <el-descriptions-item label="报考信息"
                              labelClassName="my-label"
                              contentClassName="my-content">
          <div class="overflow-text">
            {{ detail.apply_college_name }} | {{ detail.apply_academy_name }} |
            ({{ detail.apply_major_code }}){{ detail.apply_major_name }}
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="辅导科目"
                              contentClassName="my-content"
                              label-class-name="my-label">
          <div class="overflow-text">
            <span>{{ formateSubjects(detail) }}</span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="辅导要求"
                              contentClassName="my-content"
                              label-class-name="my-label">
          <div class="overflow-text">
            <div class="contentBox">
              <div v-if="detail.demand_direction_codes && detail.demand_direction_names">
                考试方向仅限是{{ formateDirections(detail) }}；
              </div>
              <div v-if="detail.demand_max_hour_amount">
                研究生时薪≤{{ detail.demand_max_hour_amount }}元/小时；
              </div>
              <div v-if="detail.demand_academy_major_status">
                研究生与学员同一个学院专业；
              </div>
              <div v-if="detail.extend_info_obj.demand_trial_listen_status== 1">
                正式课程前，需要安排一节试听课；
              </div>
              <div v-if="detail.extend_info_obj.demand_professional_experience_status==1">
                需要有对应的专业课辅导经验；
              </div>
              <div v-if="detail.extend_info_obj.demand_before_first_rank !=null">
                初试总分排名在前{{ detail.extend_info_obj.demand_before_first_rank}}名；
              </div>
              <div v-if="detail.extend_info_obj.demand_min_first_total_score !=null">
                初试总成绩不低于{{ detail.extend_info_obj.demand_min_first_total_score}}分；
              </div>
              <div v-if="detail.extend_info_obj.demand_min_first_professional_score !=null">
                专业课成绩不低于{{ detail.extend_info_obj.demand_min_first_professional_score}}分；
              </div>
              <div v-if="detail.demand_same_reference_book_status == 1">
                允许其他院校研究生根据参考书或者大纲辅导；
              </div>
              <div v-if="detail.demand_extra_info">
                备注：{{ detail.demand_extra_info }}；
              </div>
            </div>
          </div>
        </el-descriptions-item>

        <el-descriptions-item label="排除老师"
                              contentClassName="my-content"
                              label-class-name="my-label">
          <div class="overflow-text"
               v-if="detail.exclude_year_teachers">
            <span v-for="(item, index) in detail.exclude_year_teachers.split(',')"
                  :key="index">
              <span v-if="index != 0">|</span> {{ item }}
            </span>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card style="margin-top: 28px;">
      <div class="card-header">
        <TableTittle title="老师列表"></TableTittle>
        <el-form size="small"
                 :inline="true"
                 :model="query">
          <el-popover placement="bottom-end"
                      width="800"
                      trigger="click"
                      ref="popoverSH">
            <div>
              <div style="border-bottom:#DCDFE6 1px solid;padding: 16px;margin-bottom: 16px;display: flex;align-items: center;justify-content: space-between;">
                <div style="font-size: 16px;font-weight: 600;color: #303133;">筛选条件</div>
                <div class="action-wrap">
                  <el-button type="primary"
                             @click="handleSearch"
                             size="small">搜索</el-button>
                  <el-button @click="handleFormReset('searchForm')"
                             size="small">重置</el-button>
                </div>
              </div>
              <el-form class="search-wrap"
                       ref="searchForm"
                       :inline="true"
                       :model="query"
                       size="small"
                       label-position="right"
                       label-width="100px">
                <el-form-item label="确认状态："
                              prop="confirm_statuses">
                  <el-select v-model="query.confirm_statuses"
                             placeholder="请选择确认状态"
                             style="width:250px;"
                             clearable>
                    <el-option label="已确认"
                               :value="1"></el-option>
                    <el-option label="未确认"
                               :value="0"></el-option>
                    <el-option label="未录取"
                               :value="-1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="签约状态："
                              prop="contract_statuses">
                  <el-select v-model="query.contract_statuses"
                             placeholder="请选择签约状态"
                             style="width:250px;"
                             clearable>
                    <el-option label="已签约"
                               :value="1"></el-option>
                    <el-option label="未签约"
                               :value="0"></el-option>
                    <el-option label="已过期"
                               :value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="录取年份："
                              prop="recruit_years">
                  <el-select v-model="query.recruit_years"
                             placeholder="请选择录取年份"
                             style="width:250px;"
                             clearable>
                    <el-option label="2019年"
                               :value="2019"></el-option>
                    <el-option label="2020年"
                               :value="2020"></el-option>
                    <el-option label="2021年"
                               :value="2021"></el-option>
                    <el-option label="2022年"
                               :value="2022"></el-option>
                    <el-option label="2023年"
                               :value="2023"></el-option>
                    <el-option label="2024年"
                               :value="2024"></el-option>
                    <el-option label="2025年"
                               :value="2025"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="学长/学姐：">
                  <el-input v-model.trim="query.keyword"
                            placeholder="请输入姓名/手机号"
                            clearable
                            style="width:250px;"></el-input>
                </el-form-item>
                <el-form-item label="院校："
                              prop="apply_college_name">
                  <!--  -->
                  <el-select style="width:250px;"
                             @clear="collegeClear"
                             @change="collegeChoose"
                             v-model.trim="query.college_code"
                             filterable
                             collapse-tags
                             placeholder="请选择"
                             clearable>
                    <el-option v-for="(item, index) in $store.state.collegeList"
                               :key="index"
                               :label="item.name"
                               :value="item.code">
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="院校：">
                  <el-input v-model.trim="query.college" placeholder="请输入院校名/代码" style="width:250px;"
                    clearable></el-input>
                </el-form-item> -->
                <el-form-item label="学院：">
                  <el-input v-model.trim="query.academy"
                            placeholder="请输入学院名/代码"
                            style="width:250px;"
                            clearable></el-input>
                </el-form-item>
                <el-form-item label="专业："
                              prop="apply_major_name">
                  <el-select filterable
                             placeholder="请选择"
                             v-model.trim="query.major_code"
                             clearable
                             style="width: 250px">
                    <el-option v-for="(item, index) in majorOptions"
                               :key="index"
                               :label="item.allnames"
                               :value="item.code">
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="专业：">
                  <el-input v-model.trim="query.majorCode" placeholder="请输入专业名/代码" style="width:250px;"
                    clearable></el-input>
                </el-form-item> -->
                <el-form-item label="科目：">
                  <el-input v-model.trim="query.test_subject_keyword"
                            placeholder="请输入初试科目"
                            style="width:250px;"
                            clearable></el-input>
                </el-form-item>
                <el-form-item label="复试：">
                  <el-input v-model.trim="query.second_test_subject_keyword"
                            placeholder="请输入复试科目"
                            style="width:250px;"
                            clearable></el-input>
                </el-form-item>
                <el-form-item label="辅导书籍：">
                  <el-input v-model.trim="query.reference_book_keyword"
                            placeholder="请输入辅导书籍"
                            style="width:250px;"
                            clearable></el-input>
                </el-form-item>
                <!-- <el-form-item label="初试科目：">
                  <el-input v-model.trim="query.test_subject_keyword" placeholder="请输入初试科目" style="width:250px;"
                    clearable></el-input>
                </el-form-item> -->

              </el-form>
            </div>
            <el-button slot="reference"
                       size="small"
                       v-if="!$buttonPermission('allfilters:show')">所有筛选</el-button>
          </el-popover>
        </el-form>

      </div>
      <el-table :data="result"
                :header-cell-style="{
        backgroundColor: '#F2F6FC',
      }"
                v-loading="loading"
                border>
        <el-table-column label="沟通记录"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToRecord(scope.row)">查看</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="ID"
                         align="center"
                         width="80">
        </el-table-column>
        <el-table-column prop="realname"
                         label="姓名"
                         align="center"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.realname }}{{ scope.row.hide_name_status ? `（${scope.row.nickname}）` : '' }}
          </template>
        </el-table-column>
        <el-table-column label="性别"
                         align="center"
                         width="50"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-show="scope.row.gender == 1">男</span>
            <span v-show="scope.row.gender == 2">女</span>
            <span v-show="scope.row.gender == 0">未知</span>
          </template>
        </el-table-column>
        <el-table-column label="年级"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            {{ scope.row.recruit_year }}年
          </template>
        </el-table-column>

        <el-table-column label="确认状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.confirm_status == 1"
                    type="success">已确认</el-tag>
            <el-tag size="small"
                    v-if="scope.row.confirm_status == 0">未确认</el-tag>
            <el-tag type="danger"
                    size="small"
                    v-if="scope.row.confirm_status == -1">未录取</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="签约状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.contract_status == 1"
                    type="success">已签约</el-tag>
            <el-tag size="small"
                    v-if="scope.row.contract_status == 0"
                    type="warning">未签约</el-tag>
            <el-tag size="small"
                    v-if="scope.row.contract_status == 2"
                    type="danger">已过期</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="录取信息"
                         align="left"
                         width="350"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.recruit_college_name }} | {{ scope.row.recruit_academy_name }} |
              ({{ scope.row.recruit_major_code }}){{ scope.row.recruit_major_name }} {{
                scope.row.recruit_direction_code && scope.row.recruit_direction_name ? ` |
              (${scope.row.recruit_direction_code})${scope.row.recruit_direction_name}` : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="初试科目"
                         align="left"
                         width="350"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ formateSubjectsOther(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="初试总分"
                         align="center "
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.initial_test_score }}分
          </template>
        </el-table-column>
        <el-table-column label="科目分数"
                         align="center"
                         width="260"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateScore(scope.row.test_subject_scores) }}
          </template>
        </el-table-column>
        <el-table-column label="参考书目"
                         align="center"
                         width="260"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateReference_book(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="复试科目"
                         align="center "
                         width="300"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.second_test_subject_names || '无' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="录取类型"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.recruit_type == 1">一志愿</span>
            <span v-if="scope.row.recruit_type == 2">调剂</span>
          </template>
        </el-table-column>

        <el-table-column label="平均课时费"
                         align="center "
                         width="110"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.teacher_average_lesson_hour_amount ? scope.row.sign_order_lesson_hour_amount : 0 }}元/小时
          </template>
        </el-table-column>
        <el-table-column label="签单课时费"
                         align="center "
                         width="110"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.sign_order_lesson_hour_amount ? scope.row.sign_order_lesson_hour_amount : 0 }}元/小时
          </template>
        </el-table-column>
        <el-table-column label="综合评分"
                         width="80"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              scope.row.teacher_average_student_apprise_score ?
              (scope.row.teacher_average_student_apprise_score).toFixed(1) + '分' : '暂无'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="微信添加"
                         width="200"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateWechatManager(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="首次签约人"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateFirstManager(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="签约有效期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{ scope.row.contract_end_time ? $moment(scope.row.contract_end_time).format("YYYY-MM-DD HH:mm") : "无" }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{ scope.row.update_time ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm") : "" }}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         fixed="right"
                         align="center"
                         width="180">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToDetail(scope.row)">详情</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleRecord(scope.row)">记录</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToConfirm(scope.row.id)">确认</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToSubmit(scope.row.id,scope.row.realname)">提交</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <div style="margin-right:16px;">共{{ otherCount }}条<span v-if="Number(otherCount) > 100">,涉及隐私仅显示前100条</span>
          </div>
          <el-pagination background
                         layout="prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <!-- <Record
      :teacher_id="teacher_id"
      :isVisible="recordDialog"
      @close="recordDialog=false"
    ></Record> -->

    <AbilityRecord :flag="flag"
                   :type="type"
                   :teacher_id="teacher_id"
                   :teacherIdFlag="teacherIdFlag"
                   :teacher_allocation_order_id="Number(this.$route.params.id)"
                   :isVisible="orderDialog"
                   @close="orderDialog = false"
                   @submit="fetchList">
    </AbilityRecord>
    <!-- 
    <AbilityRecord
      :teacherIdFlag="true"
      :teacher_allocation_order_id="orderId"
      :isVisible="orderDialog"
      @close="orderDialog=false"
      @submit="fetchList"
      :type="'order_read'"
    ></AbilityRecord> -->

    <SeniorDetail :isVisible="SeniorDetailDialog"
                  @close="SeniorDetailDialog = false"
                  :id="detailId" />
    <SubmitTeacher :isVisible="submitDialog"
                   :realname="realname"
                   :id="submitId"
                   :order_id="Number(this.$route.params.id)"
                   @close="submitDialog = false"
                   @submit="fetchDetail()"></SubmitTeacher>
    <OrderRecord :isVisible="shareRecordDialog"
                 :id="Number(this.$route.params.id)"
                 @close="shareRecordDialog = false">
    </OrderRecord>

    <AbilityLog :teacher_id="teacher_id"
                :isVisible="logDialog"
                :id="logId"
                :order_id="Number(this.$route.params.id)"
                @close="logDialog = false"
                @submit="fetchList"></AbilityLog>
  </div>
</template>
<script>
import AbilityLog from '@/components/DialogComponents/TeacherResources/AbilityLog.vue'
import { crmUrl, getStorage } from '@/utils/helper.js'
import TableTittle from '@/components/TableTittle/TableTittle.vue'
import AbilityRecord from '@/components/DialogComponents/TeacherResources/AbilityRecord.vue'
import SeniorDetail from '@/components/DialogComponents/TeacherResources/SeniorDetail.vue'
import SubmitTeacher from '@/components/DialogComponents/TeacherResources/SubmitTeacher.vue'
import OrderRecord from '@/components/DialogComponents/ShareOrder/OrderRecord.vue'

import {
  taOne,
  subjectlist,
  teacherlist,
  taRecordList,
  majorlist,
} from '@/request/api'

export default {
  components: {
    TableTittle,
    AbilityRecord,
    SeniorDetail,
    SubmitTeacher,
    OrderRecord,
    AbilityLog,
  },
  data() {
    return {
      realname: '',
      teacherIdFlag: false,
      flag: true,
      type: 'teacher_read',
      majorOptions: [],
      orderDialog: false,
      logDialog: false,
      logId: null,
      result: [],
      detail: {},
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        // handle_teacher_allocaton_type: 1,
        archive: '0',
        college_code: '',
        major_code: '',
        is_order_score: 1,
      },
      subjectOptions: [],
      pageTotal: 0,
      labelStyle: {
        width: '170px',
        border: 'solid',
      },
      loading: false,
      otherCount: 0,
      pageTitle: '',
      teacher_id: null,
      recordDialog: false,
      SeniorDetailDialog: false,
      detailId: null,
      submitId: null,
      submitDialog: false,
      shareRecordDialog: false,
    }
  },
  created() {
    this.fetchDetail()
    this.$store.dispatch('getAllColleges')
  },
  computed: {},
  methods: {
    handleChect() {
      this.teacherIdFlag = true
      ;(this.flag = false), (this.type = 'order_read')
      this.teacher_id = null
      this.orderDialog = true
    },

    collegeClear() {
      this.$set(this.query, 'major_code', '')
      this.majorOptions = []
    },
    collegeChoose(e) {
      this.collegeClear()
      if (!e) {
        return
      }
      // console.log("e", e)
      // let obj = {};
      // obj = this.$store.state.collegeList.find((item) => {
      //   return item.code === e; //筛选出匹配数据
      // });
      let query = {
        isPaging: 0,
        college_code: e,
      }
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          }
        })
      })
      // this.form.apply_college_code = obj.code;
      // this.form.apply_college_name = obj.name;
      // this.getMajorOptions();
    },

    fetchDetail() {
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder`,
        method: 'get', //可以省略 不写默认是get
        params: {
          id: this.$route.params.id,
        },
      }).then((response) => {
        let res = response.data
        if (res.code == 0) {
          this.detail = res.data
          console.log('this.detail', this.detail)
          this.pageTitle = `${
            this.$route.query.name ? this.$route.query.name + '-' : ''
          }${this.detail.apply_year}考研-${this.detail.lesson_hours}小时`
          console.log('detail', this.detail)
          this.$set(this.query, 'college_code', this.detail.apply_college_code)
          // this.$set(this.query, 'major', this.detail.apply_major_name)
          this.collegeChoose(this.query.college_code)
          this.$set(this.query, 'major_code', this.detail.apply_major_code)
          // console.log("999")
          this.$forceUpdate()
          this.fetchList(true)
        }
      })
    },
    fetchList(isReset = false) {
      this.loading = true
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query, {
        test_subjects: null,
      })
      teacherlist(params).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        if (res.data.count > 100) {
          this.otherCount = res.data.count
          this.pageTotal = 100
        } else {
          this.otherCount = res.data.count
          this.pageTotal = res.data.count || 0
        }
        this.loading = false
      })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    handleToClose() {
      this.dialog = false
    },
    formateSubjects(row) {
      let arr = row.apply_subject_names
        ? row.apply_subject_names.split(',')
        : []
      let code_arr = row.apply_subject_codes
        ? row.apply_subject_codes.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `(${code_arr[i]})${v}`
            })
            .join('+')
        : '无'
    },
    formateSubjectsOther(row) {
      let arr = row.test_subject_names ? row.test_subject_names.split(',') : []
      let code_arr = row.test_subject_codes
        ? row.test_subject_codes.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `(${code_arr[i]})${v}`
            })
            .join('+')
        : '无'
    },
    formateSecondSubjects(row) {
      let arr = row.second_test_subject_names
        ? row.second_test_subject_names.split(',')
        : []
      let code_arr = row.second_test_subject_codes
        ? row.second_test_subject_codes.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `(${code_arr[i]})${v}`
            })
            .join('+')
        : '无'
    },
    collegeChange(e) {
      if (e) {
        this.fetchSubjectList(e)
      }
    },
    handleSearch() {
      if (this.query.test_subjects) {
        this.query.test_subject_codes_search = this.query.test_subjects
          .map((v) => {
            return v.value
          })
          .join(',')
        this.query.test_subject_names_search = this.query.test_subjects
          .map((v) => {
            return v.label
          })
          .join(',')
      }
      this.fetchList(true)
      this.$refs.popoverSH.doClose()
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields()
      this.fetchList(true)
    },
    formateScore(score) {
      if (score) {
        let str = score.split(',')
        return str
          .map((v) => {
            return v + '分'
          })
          .join(' | ')
      } else {
        return '无'
      }
    },
    formateReference_book(row) {
      let arr = row.reference_book_names
        ? row.reference_book_names.split(',')
        : []
      let infos = row.reference_book_extra_infos
        ? row.reference_book_extra_infos.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `${v}，${infos[i]}`
            })
            .join(',')
        : '无'
    },
    formateWechatManager(row) {
      if (row.wechat_add_manage_ids) {
        let id = row.wechat_add_manage_ids
        if (this.$store.state.staffOptions.length > 0) {
          return this.$store.state.staffOptions.find((v) => {
            return v.id == id
          }).nickname
        }
      } else {
        return '无'
      }
    },
    formateFirstManager(row) {
      // console.log("ccc", this.$store.state.staffOptions)
      if (row.first_contract_manage_id) {
        let id = row.first_contract_manage_id
        if (this.$store.state.staffOptions.length > 0) {
          return this.$store.state.staffOptions.find((v) => {
            return v.id == id
          }).nickname
        } else {
          return '无'
        }
      } else {
        return '无'
      }
    },
    handleToRecord(row) {
      this.teacherIdFlag = false
      this.flag = true
      this.type = 'teacher_read'
      this.orderDialog = true
      this.teacher_id = row.id
    },
    handleToDetail(row) {
      this.detailId = row.id
      this.SeniorDetailDialog = true
    },

    handleToConfirm(id) {
      window.open(`/tr/order/confirm/${id}`)
    },
    handleToSubmit(id, realname) {
      // console.log(manage_user_id,'idddd')
      if (this.detail.ref_receiver_manage_user_id) {
        this.submitId = id
        this.realname = realname
        this.submitDialog = true
      } else {
        this.$message.error('请先接单之后再提交！')
      }
    },
    reciver() {
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrderHandle/receiveOrder/accept`,
        method: 'post', //可以省略 不写默认是get
        data: {
          find_order_id: this.$route.params.id,
        },
      }).then((response) => {
        let res = response.data
        if (res.code == 0) {
          this.$message.success('接单成功')
          this.fetchDetail()
        }
      })
    },
    formateDirections() {
      let names = this.detail.demand_direction_names.split(',')
      let codes = this.detail.demand_direction_codes.split(',')

      return names
        .map((v, i) => {
          return `(${codes[i]})${v}`
        })
        .join(',')
    },
    handleToShareRecord() {
      this.shareRecordDialog = true
    },

    handleRecord(row) {
      this.logId = row.id
      this.logDialog = true
      this.teacher_id = row.id
    },
  },
}
</script>
      
<style lang="less" scoped>
.contentBox {
  width: 80%;
}
.content {
  .popover-header {
  }

  .my-label {
    width: 90px;
  }

  .my-content {
    width: 50%;
  }

  .header {
    display: flex;
    align-items: center;

    .teacher-count {
      margin-left: 12px;
      margin-bottom: 16px;
      font-size: 12px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e34d59;
      border: #e34d59 2px solid;
      border-radius: 4px;
      padding: 2px 4px;
    }
  }

  /deep/.el-descriptions-item__label {
    white-space: nowrap;
  }

  .expand-wrap {
    .el-table__cell {
      background-color: #f9f9f9;
    }
  }

  .el-table__expanded-cell {
    padding: 0px !important;
  }

  .urgent {
    position: absolute;
    top: 16px;
    right: 16px;

    img {
      width: 86px;
      height: 68px;
    }
  }

  .divider {
    border-bottom: 1px dashed #dcdfe6;
    margin: 15px 0;
  }

  .action-wrap {
    display: flex;
    justify-content: flex-end;
  }

  .my-label {
    white-space: nowrap;
    width: 60px;
  }

  .overflow-text {
    background: #fff;
  }

  .timeline-wrap {
    height: 274px;
    overflow-y: auto;

    .timeline-item {
      height: 57px;
      background: rgba(225, 229, 237, 0.3);
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
    }
  }

  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}
</style>
      