<template>
  <div>
    <el-dialog top="5vh" title="查看或更新签约信息" :visible.sync="dialogFormVisible" @close="handleToClose"
      :close-on-click-modal="false" width="700px">
      <el-form :model="dataForm" label-position="right" ref="formRules" :rules="rules" label-width="110px" size="small">
        <el-form-item label="姓名 ：" prop="realname">
          <el-input v-model="dataForm.realname" placeholder="请输入姓名" style="width: 100%;"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model="dataForm.mobile" placeholder="请输入手机号" style="width:100%;"
            oninput="value=value.replace(/[^0-9.]/g,'')" disabled>
          </el-input>
        </el-form-item>
        <el-form-item label="微信号：" prop="name">
          <el-input v-model="dataForm.wechat" placeholder="请输入微信号" style="width:100%;">
          </el-input>
        </el-form-item>
        <el-form-item label="QQ号：" prop="name">
          <el-input v-model="dataForm.qq" placeholder="请输入QQ号" style="width:100%;">
          </el-input>
        </el-form-item>
        <el-form-item label="身份证号：" prop="id_card_no">
          <el-input v-model="dataForm.id_card_no" placeholder="请输入身份证号" style="width:100%;">
          </el-input>
        </el-form-item>
        <el-form-item label="签约课时费：" prop="fee_amount">
          <el-input v-model="dataForm.fee_amount" placeholder="请输入签约课时费" oninput="value=value.replace(/[^0-9.]/g,'')">
            <template slot="append">元/小时</template>
          </el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="contract_remark">
          <el-input v-model="dataForm.contract_remark" placeholder="请输入备注" style="width:100%;" type="textarea">
          </el-input>
        </el-form-item>
        <el-form-item label="成绩证明：" prop="recruit_score_img">
          <div style="display: flex;">
            <el-upload class="avatar-uploader" :action="imgUploadUrl" :show-file-list="false"
              :on-success="handleAvatarSuccess">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="签约状态：" prop="contract_status">
          <el-radio-group v-model="dataForm.contract_status">
            <el-radio :label="0">未签约</el-radio>
            <el-radio :label="1">已签约</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="签约日期：" prop="contract_start_time">
          <el-date-picker v-model="dataForm.contract_start_time" type="date" placeholder="选择日期" value-format="timestamp">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="签约时长：" prop="recruit_type">
          <div class="count-wrap">
            <div class="count-item" @click="chooseCount(1)" :class="{ 'choose': count == 1 }">1年</div>
            <div class="count-item" @click="chooseCount(2)" :class="{ 'choose': count == 2 }">2年</div>
            <div class="count-item" @click="chooseCount(3)" :class="{ 'choose': count == 3 }">3年</div>
          </div>

        </el-form-item>

      </el-form>
      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button type="primary" @click="submit('formRules')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  teacherEdit,
  teacherOne,
  taRecordCreate
} from "@/request/api";
import { imgUploadUrl } from "@/request/http";
import { log } from 'mathjs';

export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }, { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号' }],
        fee_amount: [{ required: true, message: "请输入签约课时费", trigger: "blur" }],
      },
      count: 1,
      imgUploadUrl: imgUploadUrl(),
      imageUrl: '',

    };

  },
  mounted() {
  },
  watch: {
    isVisible(val) {

      this.dialogFormVisible = val;

      if (val) {

        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      teacherOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data
        this.$set(this.dataForm, 'fee_amount', res.data.fee_amount ? res.data.fee_amount : null)
        this.$set(this.dataForm, 'contract_start_time', this.dataForm.contract_start_time ? this.dataForm.contract_start_time : new Date().getTime())
        if (this.dataForm.recruit_score_img) {
          this.imageUrl = this.dataForm.recruit_score_img
        }
        if (this.dataForm.contract_start_time && this.dataForm.contract_end_time) {
          let timestamp = Number(this.dataForm.contract_end_time) - Number(this.dataForm.contract_start_time)
          this.count = parseInt(timestamp / 31536000000)
        }
      })
    },

    handleToClose() {
      this.$emit("close");
      this.dataForm = {}
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let data = {
            id: this.dataForm.id,
            realname: this.dataForm.realname,
            mobile: this.dataForm.mobile,
            wechat: this.dataForm.wechat,
            id_card_no: this.dataForm.id_card_no,
            qq: this.dataForm.qq,
            contract_start_time: this.dataForm.contract_start_time,
            contract_end_time: this.formateEndTime(),
            contract_status: this.dataForm.contract_status,
            fee_amount: this.dataForm.fee_amount,
            recruit_score_img: this.imageUrl,
            contract_remark: this.dataForm.contract_remark,
          }
          teacherEdit(data).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('操作成功')

              if (this.dataForm.contract_status == 1) {
                taRecordCreate({
                  record: `已成功签约${this.dataForm.realname}老师`,
                  teacher_id:  this.dataForm.id,
                  type: 19,
                }).then(res => {
                  // console.log("ces",res)
                })
              }
              this.$emit('submit')
              this.handleToClose()
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    chooseCount(num) {
      this.count = num
    },
    formateEndTime() {
      let num = Number(this.dataForm.contract_start_time)
      return num + (31536000000 * this.count)
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = file.response.data.url;
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .el-input-group__append {
    background: #f5f5f5 !important;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    text-align: center;
  }

  .avatar {
    width: 140px;
    height: 140px;
  }

  .count-wrap {
    display: flex;
    align-items: center;

    .count-item {
      padding: 0px 18px;
      font-size: 14px;
      font-family: PingFangSC-Regular-, PingFangSC-Regular;
      color: rgba(0, 0, 0, 0.6);
      border: 1px solid #dcdcdc;
      margin-right: 12px;
      cursor: pointer;

      /deep/.el-input__inner {
        border: none;
        background: transparent;
      }
    }

    .choose {
      background-image: url("https://upload.kaoyanbox.net/c2d67a5112245e425cc58a547522aaa8.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>