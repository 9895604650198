<template>
    <div class="content">
        <el-dialog append-to-bod top="5vh" :title="title" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
            width="570px">
            <el-form :model="queryForm" label-width="auto" label-position="left" size="small" ref="formRules"
                :rules="rules">
                <el-form-item label="收款金额" prop="receipt_amount">
                    <el-input v-model="queryForm.receipt_amount" placeholder="请输入收款金额">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>

                <el-form-item label="收款方式" prop="receipt_type">
                    <el-select v-model="queryForm.receipt_type" placeholder="请选择" style="width: 100%;">
                        <el-option label="支付宝" :value="1"></el-option>
                        <el-option label="微信" :value="2"></el-option>
                        <el-option label="余额" :value="3"></el-option>
                        <el-option label="转账" :value="4"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="收款状态" prop="receipt_status">
                    <el-select v-model="queryForm.receipt_status" placeholder="请选择" style="width: 100%;">
                        <el-option label="未收款" :value="0"></el-option>
                        <el-option label="已收款" :value="1"></el-option>
                        <el-option label="已退款" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收款时间" prop="receipt_time">
                    <el-date-picker value-format="timestamp" v-model="queryForm.receipt_time" type="datetime"
                        format="yyyy-MM-dd HH:mm" style="width: 100%;" placeholder="请选择">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="收款截图" prop="receipt_img">
                    <el-upload :action="imgUploadUrl" list-type="picture-card" :file-list="queryForm.receipt_img" :limit="3"
                        :on-success="(response, file, fileList) => handleSuccess(response, file, fileList)"
                        :on-remove="(file, fileList) => handleRemove(file, fileList)">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="收款交易单号">
                    <el-input v-model="queryForm.receipt_trade_no" placeholder="请输入收款交易单号"></el-input>
                </el-form-item>
                <el-form-item label="收款备注">
                    <el-input v-model="queryForm.receipt_remark" placeholder="请输入收款备注" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit('formRules')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { orderReceiptRecordAdd, orderReceiptRecordUp } from "../../../request/api";
import { imgUploadUrl } from "@/request/http";
export default {
    data() {
        return {
            title: '新增收款记录',
            queryForm: {
                receipt_img: []
            },
            dialogFormVisible: false,
            rules: {
                receipt_amount: [{ required: true, message: "请输入收款金额", trigger: "blur" },
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入正整数(限两位小数)', trigger: 'blur' }],
                receipt_type: [{ required: true, message: "请选择收款方式", trigger: "blur" }],
                receipt_status: [{ required: true, message: "请选择收款状态", trigger: "blur" }],
                receipt_time: [{ required: true, message: "请选择收款时间", trigger: "blur" }],
            },
            imgUploadUrl: imgUploadUrl(),
            fileList: [],
        }
    },
    created() {

    },
    methods: {
        open(type, id, row) {
            

          

            if (row) {
                this.title = '修改收款记录'
                this.queryForm = {
                    "id": row.id,
                    "order_type": row.order_type,
                    "order_id": row.order_id,
                    "receipt_amount": row.receipt_amount,
                    "receipt_type": row.receipt_type,
                    "receipt_status": row.receipt_status,
                    "receipt_time": row.receipt_time,
                    "receipt_img":row.receipt_img? JSON.parse(row.receipt_img):[],
                    "receipt_trade_no": row.receipt_trade_no,
                    "receipt_remark": row.receipt_remark
                }
            } else {
                this.title = '新增收款记录'
                this.queryForm = {
                    receipt_img: []
                }
                this.queryForm.order_id = id
                this.queryForm.order_type = type
            }
            
            this.dialogFormVisible = true

        },

        submit(rules) {
            this.$refs[rules].validate((valid) => {
                if (valid) {
                    if (this.title == '新增收款记录') {
                        orderReceiptRecordAdd({
                            ...this.queryForm,
                            receipt_img: this.queryForm.receipt_img.length > 0 ? JSON.stringify(this.queryForm.receipt_img) : ''
                        }).then(res => {
                            // console.log("res",res)
                            if (res.data.code == 0) {
                                this.dialogFormVisible = false
                                this.$emit("submit")
                                this.$message({
                                    type: 'success',
                                    message: '创建成功!'
                                });
                            }

                        })
                    } else {
                        orderReceiptRecordUp({
                            ...this.queryForm,
                            receipt_img: this.queryForm.receipt_img.length > 0 ? JSON.stringify(this.queryForm.receipt_img) : ''
                        }).then(res => {
                            // console.log("res",res)
                            if (res.data.code == 0) {
                                this.dialogFormVisible = false
                                this.$emit("submit")
                                this.$message({
                                    type: 'success',
                                    message: '修改成功!'
                                });
                            }

                        })
                    }

                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },

        handleSuccess(response, file, fileList) {
            this.queryForm.receipt_img.push({
                name: response.data.name,
                url: response.data.url,
                uid: file.uid
            })
        },
        handleRemove(file, fileList) {
            this.queryForm.receipt_img = this.queryForm.receipt_img.filter(item => item.uid != file.uid)
        },

    }
}
</script>

<style lang="less" scoped></style>