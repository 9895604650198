<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>服务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/service/dispatchorder' }">师资派遣</el-breadcrumb-item>
        <el-breadcrumb-item>沟通记录</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="opendialogAdd()"
        >新建</el-button>
      </div>
    </div>
    <el-card class="operation-card">
      <el-form
        label-position="left"
        :inline="true"
        size="mini"
      >
        <el-form-item
          label="类型"
          prop="apply_year"
        >
          <el-select
            v-model="query.type"
            placeholder="请选择类型"
            clearable
            @change="getList(query)"
          >
            <el-option
              label="信息变更"
              :value="2"
            ></el-option>
            <el-option
              label="进度查询"
              :value="1"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="创建时间"
          prop="last_advance_time"
        >
          <el-date-picker
            v-model="create_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="dateChange"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card
      class="table-card"
      style="margin-top: 20px"
    >
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <!--         <div>
          <el-button type="primary" size="small" plain icon="el-icon-finished"
            >全部设为已读</el-button
          >
        </div> -->
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        height="440"
        border
      >
        <el-table-column
          prop="status"
          label="类型"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              :type="scope.row.type == 1 ? 'warning' : 'info'"
            >{{ scope.row.type == 1 ? "进度反馈" : "信息变更" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="描述"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.change == null"
              class="span-div"
            >
              {{ scope.row.content }}</span>
            <div
              class="span-div"
              v-else-if="
                scope.row.change.before == null && scope.row.change.after
              "
            >
              <span>创建了此订单</span>
            </div>
            <span
              v-else
              class="span-div"
            >
              <span v-show="
                  scope.row.change.before.apply_major_name ||
                  scope.row.change.after.apply_major_name
                ">将&nbsp;<span class="changename">报考专业&nbsp;<span class="span-other">由</span>&nbsp;</span><span class="changetext">&nbsp;"{{
                    scope.row.change.before.apply_major_name | fomateAll
                  }}"</span>&nbsp;改为&nbsp;<span class="changetext">"{{
                    scope.row.change.after.apply_major_name | fomateAll
                  }}"</span>；</span>
              <span v-show="
                  scope.row.change.before.apply_academy_name ||
                  scope.row.change.after.apply_academy_name
                ">将&nbsp;<span class="changename">报考学院&nbsp;<span class="span-other">由</span>&nbsp;</span><span class="changetext">&nbsp;"{{
                    scope.row.change.before.apply_academy_name | fomateAll
                  }}"</span>&nbsp;改为&nbsp;<span class="changetext">"{{
                    scope.row.change.after.apply_academy_name | fomateAll
                  }}"</span>；</span>
              <span v-show="
                  scope.row.change.before.apply_college_name ||
                  scope.row.change.after.apply_college_name
                ">将&nbsp;<span class="changename">报考院校&nbsp;<span class="span-other">由</span>&nbsp;</span><span class="changetext">&nbsp;"{{
                    scope.row.change.before.apply_college_name | fomateAll
                  }}"</span>&nbsp;改为&nbsp;<span class="changetext">"{{
                    scope.row.change.after.apply_college_name | fomateAll
                  }}"</span>；</span>
              <span v-show="
                  scope.row.change.before.order_amount ||
                  scope.row.change.after.order_amount
                ">将&nbsp;<span class="changename">订单价格&nbsp;<span class="span-other">由</span>&nbsp;</span><span class="changetext">&nbsp;"{{
                    scope.row.change.before.order_amount | fomateAll
                  }}元"</span>&nbsp;改为&nbsp;<span class="changetext">"{{
                    scope.row.change.after.order_amount | fomateAll
                  }}元"</span>；</span>
              <span v-show="
                  scope.row.change.before.remark ||
                  scope.row.change.after.remark
                ">将&nbsp;<span class="changename">备注&nbsp;<span class="span-other">由</span>&nbsp;</span><span class="changetext">&nbsp;"{{
                    scope.row.change.before.remark | fomateAll
                  }}"</span>&nbsp;改为&nbsp;<span class="changetext">"{{ scope.row.change.after.remark | fomateAll }}"</span>；</span>
              <span v-show="
                  scope.row.change.before.hours || scope.row.change.after.hours
                ">将&nbsp;<span class="changename">课时&nbsp;<span class="span-other">由</span>&nbsp;</span><span class="changetext">&nbsp;"{{ scope.row.change.before.hours | fomateAll }}"</span>&nbsp;改为&nbsp;<span class="changetext">"{{ scope.row.change.after.hours | fomateAll }}"</span>；</span>
              <span v-show="
                  scope.row.change.before.student_name ||
                  scope.row.change.after.student_name
                ">将&nbsp;<span class="changename">姓名&nbsp;<span class="span-other">由</span>&nbsp;</span><span class="changetext">&nbsp;"{{
                    scope.row.change.before.student_name | fomateAll
                  }}"</span>&nbsp;改为&nbsp;<span class="changetext">"{{ scope.row.change.after.student_name | fomateAll }}"</span>；</span>
              <span v-show="
                  scope.row.change.before.subjects ||
                  scope.row.change.after.subjects
                ">将&nbsp;<span class="changename">报考科目&nbsp;<span class="span-other">由</span>&nbsp;</span><span class="changetext">&nbsp;"{{
                    scope.row.change.before.subjects | formatesubjects
                  }}"</span>&nbsp;改为&nbsp;<span class="changetext">"{{
                    scope.row.change.after.subjects | formatesubjects
                  }}"</span>；</span>
              <span v-show="
                  scope.row.change.before.status ||
                  scope.row.change.after.status
                ">将&nbsp;<span class="changename">订单状态&nbsp;<span class="span-other">由</span>&nbsp;</span><span class="changetext">&nbsp;"{{
                    scope.row.change.before.status | formatestatus
                  }}"</span>&nbsp;改为&nbsp;<span class="changetext">"{{ scope.row.change.after.status | formatestatus }}"</span>；</span>
              <span v-show="
                  scope.row.change.before.payment_status ||
                  scope.row.change.after.payment_status
                ">将&nbsp;<span class="changename">支付状态&nbsp;<span class="span-other">由</span>&nbsp;</span><span class="changetext">&nbsp;"{{
                    scope.row.change.before.payment_status
                      | formatepayment_status
                  }}"</span>&nbsp;改为&nbsp;<span class="changetext">"{{
                    scope.row.change.after.payment_status
                      | formatepayment_status
                  }}"</span>；</span>
              <span v-show="
                  scope.row.change.before.watcher ||
                  scope.row.change.after.watcher
                ">将&nbsp;<span class="changename">匹配成功通知人&nbsp;<span class="span-other">由</span>&nbsp;</span><span class="changetext">&nbsp;"{{
                    scope.row.change.before.watcher | formatewatcher
                  }}"</span>&nbsp;改为&nbsp;<span class="changetext">"{{ scope.row.change.after.watcher | formatewatcher }}"</span>；</span>
              <span
                v-show="
                  scope.row.change.before.result ||
                  scope.row.change.after.result
                "
                class="result-div"
              >将&nbsp;<span class="changename">师资信息&nbsp;<span class="span-other">由</span>&nbsp;</span>
                <div
                  class="result-change-div"
                  v-if="scope.row.change.before.result"
                >
                  <span class="changetext">姓名：<span class="blackfont">{{
                      JSON.parse(scope.row.change.before.result).name
                    }}</span></span>
                  <span class="changetext">性别：<span class="blackfont">{{
                      JSON.parse(scope.row.change.before.result).gender
                    }}</span></span>
                  <span class="changetext">手机号：<span class="blackfont">{{
                      JSON.parse(scope.row.change.before.result).phone
                    }}</span></span>
                  <span class="changetext">微信号：<span class="blackfont">{{
                      JSON.parse(scope.row.change.before.result).wechat
                    }}</span></span>
                  <span class="changetext">QQ号：<span class="blackfont">{{
                      JSON.parse(scope.row.change.before.result).qq
                    }}</span></span>
                  <span class="changetext">年级：<span class="blackfont">{{
                      JSON.parse(scope.row.change.before.result).apply_year
                    }}</span></span>
                  <span class="changetext">方向：<span class="blackfont">{{
                      JSON.parse(scope.row.change.before.result).direction
                    }}</span></span>
                  <span class="changetext">分数：<span class="blackfont">{{
                      JSON.parse(scope.row.change.before.result).fraction
                    }}</span></span>
                  <span class="changetext">备注：<span class="blackfont">{{
                      JSON.parse(scope.row.change.before.result).remark
                    }}</span></span>
                </div>
                <div v-else><span class="changetext">无</span></div>
                &nbsp;改为&nbsp;
                <div
                  class="result-change-div"
                  v-if="scope.row.change.after.result"
                >
                  <span class="changetext">姓名：<span class="blackfont">{{
                      JSON.parse(scope.row.change.after.result).name
                    }}</span></span>
                  <span class="changetext">性别：<span class="blackfont">{{
                      JSON.parse(scope.row.change.after.result).gender
                    }}</span></span>
                  <span class="changetext">手机号：<span class="blackfont">{{
                      JSON.parse(scope.row.change.after.result).phone
                    }}</span></span>
                  <span class="changetext">微信号：<span class="blackfont">{{
                      JSON.parse(scope.row.change.after.result).wechat
                    }}</span></span>
                  <span class="changetext">QQ号：<span class="blackfont">{{
                      JSON.parse(scope.row.change.after.result).qq
                    }}</span></span>
                  <span class="changetext">年级：<span class="blackfont">{{
                      JSON.parse(scope.row.change.after.result).apply_year
                    }}</span></span>
                  <span class="changetext">方向：<span class="blackfont">{{
                      JSON.parse(scope.row.change.after.result).direction
                    }}</span></span>
                  <span class="changetext">分数：<span class="blackfont">{{
                      JSON.parse(scope.row.change.after.result).fraction
                    }}</span></span>
                  <span class="changetext">备注：<span class="blackfont">{{
                      JSON.parse(scope.row.change.after.result).remark
                    }}</span></span>
                </div>
                <div v-else><span class="changetext">无</span></div>
                ；
              </span>
            </span>
          </template>
        </el-table-column>
        <!--       <el-table-column prop="nickname" label="阅读反馈" width="100">
          <template>
            <el-link type="primary" :underline="false" disabled
              >对方未读</el-link
            >
          </template>
        </el-table-column> -->
        <el-table-column
          prop="creator"
          label="创建人"
          width="90"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.creator">
              {{scope.row.creator.alias}}
            </span>
            <span v-else-if="scope.row.manage_creator">
              {{scope.row.manage_creator.realname}}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          width="120px"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="修改时间"
          width="120px"
        >
          <template slot-scope="scope">
            {{ scope.row.update_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="操作"
          width="160"
          fixed="right"
        >
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link
                type="primary"
                :underline="false"
                @click="opendialogEdit(scope.row)"
              >编辑</el-link>
              <el-link
                type="danger"
                :underline="false"
                @click="deleteItem(scope.row)"
              >删除</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog
top="5vh"
      title="沟通记录"
      :visible.sync="dialogFormVisible"
      width="570px"
      @close="dialogFormVisibleClose"
    >
      <el-form
        :model="form"
        ref="dialogform"
        :rules="rules"
        label-position="right"
        label-width="60px"
      >
        <el-form-item
          label="类型"
          prop="type"
        >
          <el-radio-group v-model="form.type">
            <el-radio :label="1">进度反馈</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="描述"
          prop="content"
        >
          <el-input
            type="textarea"
            v-model="form.content"
            placeholder="请输入描述"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit(submitmethods)"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableTittle from "../../../../components/TableTittle/TableTittle.vue";
import {
  teacherdispatchorderadvancelist,
  createteacherdispatchorderadvance,
  editteacherdispatchorderadvance,
  deleteteacherdispatchorderadvance,
} from "../../../../request/api";
export default {
  components: {
    TableTittle,
  },
  data() {
    return {
      tableData: [],
      TableName: "记录列表",
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        order_id: "",
      },
      pageTotal: 0,
      orderInfo: {},
      dialogFormVisible: false,
      submitmethods: "add",
      form: {},
      rules: {
        type: [{ required: true, message: "请选择沟通类型", trigger: "blur" }],
        content: [{ required: true, message: "请输入描述", trigger: "blur" }],
      },
      create_time: "",
    };
  },
  filters: {
    fomateAll: function (val) {
      if (!val) {
        return "无";
      } else {
        return val;
      }
    },
    formatesubjects: function (val) {
      if (val == null || val == "") {
        return "无";
      }
      val = JSON.parse(val);
      var str = "";
      val.forEach((item) => {
        str += item.subject_name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formatestatus: function (val) {
      switch (val) {
        case 0:
          return "无";
        case 1:
          return "待推送";
        case 2:
          return "正在对接";
        case 3:
          return "正在匹配";
        case 4:
          return "匹配成功";
        case 5:
          return "订单完成";
        case 6:
          return "订单取消";
        default:
          break;
      }
    },
    formatepayment_status: function (val) {
      switch (val) {
        case 0:
          return "未支付";
        case 1:
          return "已支付";
        case 2:
          return "已退款";

        default:
          break;
      }
    },
    formatewatcher: function (val) {
      if (val == null || val == "") {
        return "无";
      }
      val = JSON.parse(val);
      var str = "";
      val.forEach((item) => {
        str += item.alias + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
  },
  mounted() {
    this.orderInfo = this.$route.query;
    this.query.order_id = this.orderInfo.id;
    this.getList(this.query);
  },
  methods: {
    getList(query) {
      teacherdispatchorderadvancelist(query).then((res) => {
        this.tableData = res.data.data.list || [];
        this.pageTotal = res.data.data.count;
      });
    },
    // 筛选组件传值
    screenTable({ screenForm }) {
      this.$set(this.query, "pageIndex", 1);
      this.query = {
        ...this.query,
        ...screenForm,
      };
      this.getList(this.query);
    },
    // 搜索框搜索
    inputSearch(e) {
      this.getList(this.query);
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    dateChange(e) {
      // 创建时间区间处理
      if (this.create_time) {
        this.query.start_create_time = this.create_time[0];
        this.query.end_create_time = this.create_time[1] + 86399000;
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
      }
      this.getList(this.query);
    },
    dialogFormVisibleClose() {
      this.form = {};
    },
    opendialogAdd() {
      this.submitmethods = "add";
      this.form = {
        type: 1,
      };
      this.dialogFormVisible = true;
    },
    opendialogEdit(item) {
      this.submitmethods = "edit";
      this.dialogFormVisible = true;
      this.form = JSON.parse(JSON.stringify(item));
    },
    submit(submitmethods) {
      this.$refs["dialogform"].validate((valid) => {
        if (valid) {
          let query = {
            order_id: this.query.order_id,
            content: this.form.content,
            type: 1,
            id: this.form.id,
          };
          switch (submitmethods) {
            case "add":
              createteacherdispatchorderadvance(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false;
                  this.getList(this.query);
                });
              });
              break;
            case "edit":
              editteacherdispatchorderadvance(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false;
                  this.getList(this.query);
                });
              });
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteItem(item) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            id: item.id,
          };
          deleteteacherdispatchorderadvance(query).then((res) => {
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.operation-card {
  width: 100%;
  margin-top: 20px;
  .demo-form-inline {
    height: 64px;
    border-bottom: 2px dashed #d2d5df;
  }
}
.operation-menu {
  display: flex;
  justify-content: space-between;
  .menu_left {
    margin-top: 25px;
  }
  .menu_right {
    margin-top: 25px;
  }
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.star {
  width: 18px;
  height: 18px;
}
.templateLink {
  .el-link {
    margin: 0px 5px;
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
}
.changename {
  font-weight: 900;
}
.changetext {
  color: #409eff;
}
.span-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.span-other {
  font-weight: 400;
}
.result-div {
  display: flex;
  align-items: center;
}
.result-change-div {
  height: 224px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  padding: 5px 10px;
  background-color: #f2f6fc;
}
.blackfont {
  color: #303133;
}
</style>