var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('el-dialog',{attrs:{"top":"5vh","title":"账单列表","visible":_vm.dialogFormVisible,"close-on-click-modal":false,"width":"654px"},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.emitClose}},[_c('el-table',{attrs:{"data":_vm.tableData,"header-cell-style":{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        },"cell-style":{ textAlign: 'center', fontSize: '13px' },"height":"460","border":""}},[_c('el-table-column',{attrs:{"prop":"order_no","label":"账单ID","width":"180","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"type","label":"账单类型","width":"116","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"create_time","label":"账单周期","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(scope.row.start_time,"YYYY.MM.DD"))+"-"+_vm._s(_vm._f("dateFormat")(scope.row.end_time,"YYYY.MM.DD"))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"total_amount","label":"总费用","width":"116","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.total_amount + "元"))])]}}])})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.emitClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.emitClose}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }