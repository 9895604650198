import { render, staticRenderFns } from "./DynamicCreate.vue?vue&type=template&id=89afc106&scoped=true"
import script from "./DynamicCreate.vue?vue&type=script&lang=js"
export * from "./DynamicCreate.vue?vue&type=script&lang=js"
import style0 from "./DynamicCreate.vue?vue&type=style&index=0&id=89afc106&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89afc106",
  null
  
)

export default component.exports