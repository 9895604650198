<template>
  <el-dialog
    title="详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="880px"
    class="dialog"
    append-to-body
  >
    <div class="analyse">
      <TableTittle title="基础信息"></TableTittle>
      <el-descriptions :column="1" border>
        <el-descriptions-item
          label="ID"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.id }}
        </el-descriptions-item>
        <el-descriptions-item
          label="状态"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          <el-tag :type="getStatus(detail.comp_teacher_status, 1)">{{
            getStatus(detail.comp_teacher_status, 2)
          }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item
          label="采纳数"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.pre_check_access_count }}
        </el-descriptions-item>
        <el-descriptions-item
          label="驳回数"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.pre_check_refuse_count }}
        </el-descriptions-item>
        <el-descriptions-item
          label="下线理由"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.pre_outline_reason }}
        </el-descriptions-item>
        <el-descriptions-item
          label="删除理由"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.pre_remove_reason }}
        </el-descriptions-item>
        <el-descriptions-item
          label="提交日期"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{
            detail.create_time
              ? $moment(detail.create_time).format("YYYY-MM-DD HH:mm")
              : "无"
          }}
        </el-descriptions-item>
        <el-descriptions-item
          label="更新日期"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{
            detail.update_time
              ? $moment(detail.update_time).format("YYYY-MM-DD HH:mm")
              : ""
          }}
        </el-descriptions-item>
      </el-descriptions>

      <TableTittle title="师资信息" style="margin-top: 24px"></TableTittle>
      <el-descriptions :column="1" border>
        <el-descriptions-item
          label="姓名"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.teacher_name }}
        </el-descriptions-item>
        <el-descriptions-item
          label="性别"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{
            detail.pre_teacher_gender == 0
              ? "未知"
              : detail.pre_teacher_gender == 1
              ? "男"
              : "女"
          }}
        </el-descriptions-item>
        <el-descriptions-item
          label="手机号"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.teacher_phone }}
        </el-descriptions-item>
        <el-descriptions-item
          label="微信号"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.teacher_wechat }}
        </el-descriptions-item>
        <el-descriptions-item
          label="QQ号"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.teacher_qq }}
        </el-descriptions-item>
        <el-descriptions-item
          label="年级"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.teacher_apply_year }}
        </el-descriptions-item>
        <el-descriptions-item
          label="录取院校"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.pre_apply_college_name }}
        </el-descriptions-item>
        <el-descriptions-item
          label="学院名称"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ detail.pre_apply_academy_name }}
        </el-descriptions-item>
        <el-descriptions-item
          label="专业名称"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ `(${detail.pre_apply_major_code})${detail.pre_apply_major_name}` }}
        </el-descriptions-item>
        <el-descriptions-item
          label="研究方向"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{
            `(${detail.pre_apply_direction_code})${detail.pre_apply_direction_name}`
          }}
        </el-descriptions-item>
        <el-descriptions-item
          label="初试科目"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ getSubject(1) }}
        </el-descriptions-item>
        <el-descriptions-item
          label="初试总分"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{
            detail.teacher_first_exam_score
              ? detail.teacher_first_exam_score + "分"
              : ""
          }}
        </el-descriptions-item>

        <el-descriptions-item
          label="复试科目"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ getSubject(2) }}
        </el-descriptions-item>
      </el-descriptions>

      <TableTittle title="机构信息" style="margin-top: 24px"></TableTittle>
      <el-descriptions :column="1" border>
        <el-descriptions-item
          label="机构简称"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ receiver_company_user_company_name }}
        </el-descriptions-item>

        <el-descriptions-item
          label="用户姓名"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ receiver_company_user_name }}
        </el-descriptions-item>
        <el-descriptions-item
          label="手机号"
          labelClassName="my-label"
          content-class-name="my-content"
        >
          {{ receiver_company_user_phone }}
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TableTittle from "@/components/TableTittle/TableTittle.vue";

import { crmUrl } from "@/utils/helper.js";
export default {
  components: {
    TableTittle,
  },
  data() {
    return {
      dialogVisible: false,
      detail: {},
      receiver_company_user_company_name:'',
      receiver_company_user_name:'',
      receiver_company_user_phone:''
    };
  },
  methods: {
    open(id,company_name,user_name,user_phone) {
      this.receiver_company_user_company_name = company_name
      this.receiver_company_user_name = user_name
      this.receiver_company_user_phone = user_phone
      this.getDetail(id);
      this.dialogVisible = true;
    },
    getDetail(id) {
      let str = crmUrl();
      this.$axios({
        url: `${str}/api/v1/sharePlatform/preTeacher`,
        method: "get",
        params: {
          id,
        },
        headers: {
          Authorization: localStorage.getItem("admin_token"),
        },
      }).then((res) => {
        // console.log("res:", res);
        if (res.data.code == 0) {
          this.detail = res.data.data;
        }
      });
    },
    getStatus(status, type) {
      switch (status) {
        case 1:
          if (type == 1) return "";
          return "已提交";
        case 2:
          if (type == 1) return "success";
          return "正常";
        case 3:
          if (type == 1) return "warning";
          return "待审核";
        case 4:
          if (type == 1) return "danger";
          return "已下线";
        case 5:
          if (type == 1) return "info";
          return "已删除";
      }
    },

    getSubject(type) {
      if (type == 1) {
        if (this.detail.pre_first_subject_codes) {
          let nameList = this.detail.pre_first_subject_names.split(",");
          let codeList = this.detail.pre_first_subject_codes.split(",");
          return nameList
            .map((item, index) => `(${codeList[index]})${item}`)
            .join(",");
        }
        return "无";
      } else {
        if (this.detail.pre_second_subject_codes) {
          let nameList = this.detail.pre_second_subject_names.split(",");
          let codeList = this.detail.pre_second_subject_codes.split(",");
          return nameList
            .map((item, index) => `(${codeList[index]})${item}`)
            .join(",");
        }
        return "无";
      }
    },
  },
};
</script>


<style lang="less" scoped>
/deep/ .el-descriptions__header {
  margin-bottom: 12px !important;
  margin-top: 24px;
}

/deep/ .el-descriptions-item__label.is-bordered-label {
  width: 124px !important;
  padding: 0 25px 0 23px;
  background: #ebeef5 !important;
  text-align: center !important;
}
/deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
  border: 1px solid #dcdfe6;
}
</style>