<template>
    <el-dialog top="5vh" :title="title" :visible.sync="dialogFormVisible" @close="dialogFormVisible = false"
        :close-on-click-modal="false" width="700px">

        <!-- <el-table :data="tableData" ref="multipleTable" v-loading="loading" :header-cell-style="{
            textAlign: 'center',
            color: '#909399',
            fontFamily: 'PingFang SC',
            fontSize: '14px',
            fontWeight: '400',
            backgroundColor: '#F2F6FC',
        }" :cell-style="{ fontSize: '13px', }" border style="width: 100%; margin-top: 20px">
            <el-table-column type="index" label="序号" width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                </template>
            </el-table-column>
            <el-table-column prop="content_text" label="任务记录">
                <template slot-scope="scope">
                    <div>{{ scope.row.content_text }}</div>
                    <el-image v-if="scope.row.content_img" style="width: 100px; height: 100px;margin-right: 5px;" v-for="(item, index) in scope.row.content_img.split(',')" :key="index"  :src="item" :preview-src-list="scope.row.content_img.split(',')">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="300" align="center">
                <template slot-scope="scope">
                    {{ scope.row.create_time ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "" }}
                </template>
            </el-table-column>
        </el-table>
        <div class="footer-wrap">
            <el-button size="small" @click="handleToLog()">新增记录</el-button>
            <el-pagination background layout="total, prev, pager, next, sizes, jumper"
                :current-page="Number(queryForm.pageIndex)" :page-sizes="[10, 20, 50, 100, 500]"
                :page-size="Number(queryForm.pageSize)" :total="Number(pageTotal)" @size-change="handleSizeChange"
                @current-change="handleCurrentChange"></el-pagination>
        </div>
        <el-dialog top="5vh" title="新增" :visible.sync="show" @close="show = false" :close-on-click-modal="false"
            append-to-body width="50%"> -->
        <!-- 任务记录 -->
        <el-form :model="dataForm" label-position="right" ref="formRules" :rules="rules" label-width="100px" size="small">
            <el-form-item :label="type=='关闭'? '关闭理由:':'任务记录：'" prop="content_text">
                <el-input @paste.native="handlePaste" v-model="dataForm.content_text" :rows="2" type="textarea"
                    placeholder="请输入" style="width: 100%;">
                </el-input>
            </el-form-item>
            <el-form-item label="图片：">
                <el-upload ref="upload" :action="imgUploadUrl" list-type="picture-card" :file-list="fileList" :limit="3"
                    :on-success="handleSuccess" :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <div class="footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="submit('formRules')">确定</el-button>
            </div>
        </el-form>

    </el-dialog>

    <!-- </el-dialog> -->
</template>

<script>
import { learnManageRecordList, doTask } from "@/request/api";

import { imgUploadUrl } from "@/request/http";
export default {

    data() {
        return {
            id: '',
            type:'',
            uid:'',
            title:'',
            dialogFormVisible: false,
            imgs: [],
            showViewer: false,
            show: false,
            loading: false,
            tableData: [],
            queryForm: {
                pageIndex: 1,
                pageSize: 10,
                isPaging: 1,
                is_oauth: 1
            },
            pageTotal: 0,
            dataForm: {
                content_img: '',
            },
            imgUploadUrl: imgUploadUrl(),
            fileList: [],
            list: [],
            rules: {
                content_text: [{ required: true, message: "任务记录不能为空", trigger: "blur" }],
            }
        }
    },
    created() {
        this.getDataList()
    },
    methods: {

        getDataList() {
            this.loading = true
            learnManageRecordList(this.queryForm).then(res => {
                if (res.data.code == 0) {
                    this.loading = false
                    this.pageTotal = res.data.data.count
                    this.tableData = res.data.data.list
                }
                // console.log("记录", res)
            })
        },
        open(id,type,uid) {
            if(type&&uid){
                this.title = "关闭"
                this.type = type
                this.uid = uid
            }else{
                this.title = "任务记录"
                this.type = ""
                this.uid = ""
            }
            this.id = id
            this.dataForm = {
                content_img: '',
            }
            this.fileList =[]
            this.dialogFormVisible = true
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.getDataList();
        },
        handleCurrentChange(val) {
            this.queryForm.pageIndex = val;
            this.getDataList();
        },
        handleToLog() {
            this.show = true
            this.dataForm = {
                content_img: '',
                content_text: ''
            }
            this.fileList = []
        },
        handleRemove(file, fileList) {
            // console.log("删除", file,fileList)
            this.fileList = fileList.filter(item=>item.id!=file.uid)
            this.dataForm.content_img =  this.fileList.map(item => item.url).toString()
           
        },
        handleSuccess(response, file, fileList) {
            this.fileList.push({
                url:response.data.url
            })
            this.dataForm.content_img = this.fileList.map(item => item.url).toString()
        },
        submit(rules) {
            this.$refs[rules].validate((valid) => {
                if (valid) {
                    doTask({
                        task_id: this.id,
                        force_uid:this.uid,
                        do_log_obj: {
                            ...this.dataForm,
                        }
                    }).then(res => {
                        if (res.data.code == 0) {
                            this.$message.success('操作成功')
                            this.getDataList();
                            this.dialogFormVisible = false
                            this.$emit("submit")
                        }
                    })
                }
            })
        },

        handlePaste(value) {
            // console.log(value,'KKK')
            if (this.fileList.length >= 3) {
                this.$message.error('最多可以传三张')
                return
            }
            let file = value.clipboardData.items[0]
            if (file.type.includes('image')) {
                let imgFile = file.getAsFile()
                const formData = new FormData()
                formData.append('file', imgFile)
                const axiosInstance = this.$axios.create({ withCredentials: false })
                axiosInstance({
                    method: 'post',
                    url: this.imgUploadUrl, // 上传地址，视情况更换
                    data: formData,
                })
                    .then(res => {
                        // 调用成功回调
                        // console.log("res", res)
                        this.fileList.push({
                            url: res.data.data.url,
                        })
                        console.log("fileList", this.fileList)
                        this.$refs.upload.submit();


                        // this.dataForm.domains[num].fileList.push({
                        //     url: res.data.data.url,
                        // })

                    })
                    .catch(function (err) {
                        console.log(err)
                        // 上传失败
                    })
            }
        },
    }
}
</script>

<style lang="less" scoped>
.footer-wrap {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    align-items: center;
}

.img-list {
    margin-top: 12px;
    display: flex;
    align-items: center;

    img {
        width: 140px;
        height: 140px;
        margin-right: 12px;
        border-radius: 4px;
    }
}
</style>