<template>
  <div class="content">
    <el-dialog top="5vh"
               :title="title"
               :visible.sync="dialogFormVisible"
               @close="dialogFormVisible = false"
               :close-on-click-modal="false"
               width="500px">
      <el-form :model="dataForm"
               label-position="left"
               ref="dataForm"
               :rules="rules"
               label-width="80px"
               size="small">
        <el-form-item label="充值金额"
                      prop="recharge_amount"
                      label-width="120px">
          <el-input placeholder="请输入"
                    v-model="dataForm.recharge_amount"
                    style="width: 180px;">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="赠送余额金额"
                      label-width="120px">
          <el-input placeholder="请输入"
                    v-model="dataForm.present_amount"
                    style="width: 180px;">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="赠送现金金额"
                      prop="give_cash_amount"
                      label-width="120px">
          <el-input placeholder="请输入"
                    v-model="dataForm.give_cash_amount "
                    style="width: 180px;">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="在线状态"
                      prop="status">
          <el-switch v-model="dataForm.status"
                     :active-value="1"
                     :inactive-value="0">
          </el-switch>
        </el-form-item>
        <div class="footer">
          <el-button @click="dialogFormVisible = false"
                     size="small">取消</el-button>
          <el-button type="primary"
                     @click="submit"
                     plain
                     size="small">提交</el-button>
        </div>
      </el-form>

    </el-dialog>
  </div>
</template>


<script>
import { crmUrl } from '@/utils/helper.js'
export default {
  data() {
    return {
      title: '',
      dialogFormVisible: false,
      dataForm: {
        recharge_amount: null,
        present_amount: null,
        give_cash_amount: null,
        status: 1,
      },
      rules: {
        recharge_amount: [
          { required: true, message: '充值金额不能为空', trigger: 'blur' },
          { pattern: /^[1-9](\d*)$/, message: '请输入正整数', trigger: 'blur' },
        ],
      },
    }
  },
  created() {},
  methods: {
    open(row) {
      this.formClear()
      if (row) {
        this.title = '编辑充值活动'
        this.dataForm = JSON.parse(JSON.stringify(row))
      } else {
        this.title = '新建充值活动'
      }
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    },
    formClear() {
      this.dataForm = {
        recharge_amount: null,
        present_amount: null,
        give_cash_amount: null,
        status: 1,
      }
    },
    submit() {
      console.log('this.dataform', this.dataForm)
      if (
        !/^(0?|-?[1-9]\d*)$/.test(this.dataForm.present_amount) &&
        this.dataForm.present_amount != null
      ) {
        this.$message({
          type: 'error',
          message: '赠送余额金额请输入正整数',
        })
        return
      }
      if (
        !/^(0?|-?[1-9]\d*)$/.test(this.dataForm.give_cash_amount) &&
        this.dataForm.give_cash_amount != null
      ) {
        this.$message({
          type: 'error',
          message: '赠送现金金额请输入正整数',
        })
        return
      }

      if (
        this.dataForm.present_amount == null &&
        this.dataForm.give_cash_amount == null
      ) {
        this.$message({
          type: 'error',
          message: '赠送余额金额,赠送现金金额至少选择一个!',
        })
        return
      }

      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          let str = crmUrl()
          if (this.title == '新建充值活动') {
            this.$axios({
              url: `${str}/api/v1/sharePlatform/rechargePresentActivity`,
              method: 'post', //可以省略 不写默认是get
              data: this.dataForm,
              headers: {
                // 设置请求头
                Authorization: localStorage.getItem('admin_token'),
              },
            }).then((res) => {
              if (res.data.code == 0) {
                this.dialogFormVisible = false
                this.$emit('submit')
                this.$message({
                  type: 'success',
                  message: '创建成功!',
                })
              }
            })
          } else {
            this.$axios({
              url: `${str}/api/v1/sharePlatform/rechargePresentActivity`,
              method: 'put', //可以省略 不写默认是get
              data: this.dataForm,
              headers: {
                // 设置请求头
                Authorization: localStorage.getItem('admin_token'),
              },
            }).then((res) => {
              if (res.data.code == 0) {
                this.dialogFormVisible = false
                this.$emit('submit')
                this.$message({
                  type: 'success',
                  message: '修改成功!',
                })
              }
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped></style>