<template>
  <div class="dialog-wrap">
    <el-dialog
      top="5vh"
      title="学员列表"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="980px"
    >

      <el-table
        class="result"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column
          label="报名日期"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="招生机构"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.company?`${scope.row.company.company_name}/${scope.row.company.contact_name}`:""}}
          </template>
        </el-table-column>
        <el-table-column
          label="课程状态"
          width="120"
        >
          <template slot-scope="scope">
            <el-tag
              size="mini"
              v-if="scope.row.student_sync_status == 1"
              type="warning"
            >待对接</el-tag>
            <el-tag
              size="mini"
              v-if="scope.row.student_sync_status == 2"
              type="warning"
            >待分配</el-tag>
            <el-tag
              size="mini"
              v-if="scope.row.student_sync_status == 3"
            >匹配中</el-tag>
            <el-tag
              size="mini"
              v-if="scope.row.student_sync_status == 4"
            >上课中</el-tag>
            <el-tag
              size="mini"
              v-if="scope.row.student_sync_status == 5"
              type="info"
            >已终止</el-tag>
            <el-tag
              size="mini"
              v-if="scope.row.student_sync_status == 6"
              type="danger"
            >已结束</el-tag>
            <!--   <el-tag
              size="small"
              v-show="scope.row.course_status == 1"
              type="warning"
            >待上课</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.course_status == 2"
            >上课中</el-tag>
            <el-tag
              size="small"
              type="info"
              v-show="scope.row.course_status == 3"
            >上课结束</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="apply_year"
          label="考研年份"
          width="120"
        >
          <template slot-scope="scope">
            {{scope.row.apply_year?`${scope.row.apply_year}年`:""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="realname"
          label="学员姓名"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="课程进度"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="scope.row.course_progress?parseFloat((Number(scope.row.course_progress)*100).toFixed(2)):0"
            ></el-progress>
          </template>
        </el-table-column>
        <el-table-column
          label="报考信息"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{collegeFormate(scope.row)}}
          </template>
        </el-table-column>
        <!--     <el-table-column
          label="辅导时长"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.service_hours}}小时
          </template>
        </el-table-column> -->
        <el-table-column
          width="120"
          show-overflow-tooltip
        >
          <template slot="header">
            <el-popover ref="popover">
              <span
                class="el-dropdown-link"
                slot="reference"
              >
                辅导时长<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <div
                class="popover-item"
                @click="handleCommand(1)"
              >小时</div>
              <div
                class="popover-item"
                @click="handleCommand(2)"
              >课时</div>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <div v-if="unitConversion==1">{{scope.row.service_hours}}小时</div>
            <div v-if="unitConversion==2">{{(scope.row.service_hours/scope.row.company.lesson_hour_hour_rate).toFixed(2)}}课时</div>
          </template>
        </el-table-column>
        <!--        <el-table-column
          width="120"
          align="center"
          show-overflow-tooltip
        >
          <template slot="header">
            <el-popover ref="popover">
              <span
                class="el-dropdown-link"
                slot="reference"
              >
                已安排课时<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <div
                class="popover-item"
                @click="handleCommandOther(1)"
              >小时</div>
              <div
                class="popover-item"
                @click="handleCommandOther(2)"
              >课时</div>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <div v-if="unitConversion2==1">{{scope.row.sum_student_lesson_hour_arranged||0}}小时</div>
            <div v-if="unitConversion2==2">{{Number((scope.row.sum_student_lesson_hour_arranged/scope.row.company.lesson_hour_hour_rate).toFixed(2))}}课时</div>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="remark"
          label="课程备注"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="学管师"
          width="130"
        >
          <template slot-scope="scope">
            {{scope.row.learn_manager?scope.row.learn_manager.nickname:""}}
          </template>
        </el-table-column>
        <el-table-column
          label="对接人"
          width="130"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <!--  -->
            <span>{{scope.row.docking_man_nickname||'无'}}</span>
            <!-- <span>{{ formateManage_Watcher(scope.row.manage_watcher)}}</span> -->
          </template>
        </el-table-column>
        <!--         <el-table-column
          label="创建日期"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column> -->
        <el-table-column
          label="修改日期"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          small
          layout="total, prev, pager, next,sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="handleToSubmit"
        >确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  studentlist,
} from "@/request/api";



export default {
  components: {

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      dialogVisible: this.isVisible,
      query: {
        keyword: "",
        college: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 10,
      results: [],
      unitConversion: 1
    };
  },

  watch: {
    isVisible(val) {
      this.dialogVisible = val;
      if (val) {
        this.query = {
          pageIndex: 1,
          pageSize: 10,
          isPaging: 1,
        }
        this.fetchList();
      }
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      let params = { ...this.query, lesson_log_id: this.id };
      studentlist(params).then((response) => {
        let res = response.data
        this.results = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields();
      this.fetchList();
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.fetchList();
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val;
      this.diffSelection();
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        keyword: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      };
      this.$emit("close");
    },

    collegeFormate(row) {
      if (row.apply_college_name && row.apply_major_code && row.apply_major_name) {
        return `${row.apply_college_name},（${row.apply_major_code}）${row.apply_major_name}`
      } else {
        return "暂无定校"
      }
    },
    formateManage_Watcher(val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.nickname + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    handleToChoose() {
      this.ChooseTeacherId = this.id
      this.chooseDialog = true
    },
    handleToSubmit() {
      this.$emit('submit')
      this.handleClose()
    },
    handleToEditLessonHourAmount(row) {
      this.editId = row.id
      this.editDialog = true
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    },
    handleCommandOther(e) {
      this.unitConversion2 = e
      this.$refs['popover'].doClose()
    }

  },
};
</script>

<style lang="less" scoped>
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    margin-top: 10px;
  }
  .now {
    margin-left: 16px;
    color: red;
  }
  .header {
    margin-bottom: 16px;
  }
}
</style>
