<template>
  <div>
    <el-dialog
top="5vh"
      title="计划详情"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="1010px"
    >
      <div class="dialog-content">
        <div class="content">
          <div
            class="modular"
            style="overflow-y: scroll;"
          >
            <div
              class="timeline"
              v-for="(item,index) in planList.filter(v=>{
                return v.length
            })"
              :key="index"
            >
              <div class="title">{{$moment(item[0].plan_start_time).format("MM月")}}</div>
              <el-timeline>
                <el-timeline-item
                  v-for="(citem,cindex) in item"
                  :key="cindex"
                  :id="citem.id"
                >
                  <!--   橙色  -->
                  <div
                    class="point"
                    slot="dot"
                    style="background-color:#E6A23C;"
                    v-if="[0,20,21,41].includes(citem.plan_status)"
                  ></div>
                  <!--   蓝色  -->
                  <div
                    slot="dot"
                    class="point"
                    style="background-color:#409EFF;"
                    v-if="[1,40].includes(citem.plan_status)"
                  ></div>
                  <!--   红色  -->
                  <div
                    slot="dot"
                    class="point"
                    style="background-color:#F56C6C;"
                    v-if="[22,42,5].includes(citem.plan_status)"
                  ></div>
                  <!--   绿色  -->
                  <div
                    slot="dot"
                    class="point"
                    style="background-color:#67C23A;"
                    v-if="[43,45].includes(citem.plan_status)"
                  ></div>
                  <div
                    class="card"
                    :class="{'active':citem.id==activeId}"
                    @click="changeId(citem)"
                  >
                    <div style="margin-bottom:4px;">{{$moment(citem.plan_start_time).format("MM月DD日 HH:mm")}}</div>
                    <div>{{citem.course_theme}}</div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
          <div
            class="modular"
            v-if="Object.keys(detail).length"
            style="height:100%;"
          >
            <div class="descriptions">
              <div class="item">
                <div class="value other">{{$moment(detail.plan_start_time).format("YYYY-MM-DD HH:mm")}}</div>
              </div>
              <div class="item">
                <div class="label">计划状态</div>
                <div class="value">
                  <el-tag
                    v-if="detail.plan_status==0"
                    size="mini"
                    type="warning"
                  >待审核</el-tag>
                  <el-tag
                    v-if="detail.plan_status==1"
                    size="mini"
                  >待上课</el-tag>
                  <el-tag
                    v-if="detail.plan_status==20"
                    size="mini"
                    type="warning"
                  >待上课</el-tag>
                  <el-tag
                    v-if="detail.plan_status==21"
                    size="mini"
                    type="warning"
                  >待提交</el-tag>
                  <el-tag
                    v-if="detail.plan_status==22"
                    size="mini"
                    type="danger"
                  >已超期</el-tag>
                  <el-tag
                    v-if="detail.plan_status==40"
                    size="mini"
                  >待确认</el-tag>
                  <el-tag
                    v-if="detail.plan_status==41"
                    size="mini"
                    type="warning"
                  >待回访</el-tag>
                  <el-tag
                    v-if="detail.plan_status==42"
                    size="mini"
                    type="danger"
                  >已拒绝</el-tag>
                  <el-tag
                    v-if="detail.plan_status==43"
                    size="mini"
                    type="success"
                  >已完成</el-tag>
                  <el-tag
                    v-if="detail.plan_status==45"
                    size="mini"
                    type="success"
                  >已完结</el-tag>
                  <el-tag
                    v-if="detail.plan_status==46"
                    size="mini"
                    type="info"
                  >已暂停</el-tag>
                  <el-tag
                    v-if="detail.plan_status==5"
                    size="mini"
                    type="danger"
                  >已拒绝</el-tag>
                  <el-tag
                    v-if="detail.plan_status==6"
                    size="mini"
                    type="info"
                  >已取消</el-tag>
                  <el-tag
                    v-if="detail.plan_status==7"
                    size="mini"
                    type="info"
                  >空状态</el-tag>
                </div>
              </div>
              <div class="item">
                <div class="label">课程主题</div>
                <div class="value">{{detail.course_theme}}</div>
              </div>
              <div class="item">
                <div class="label">课程内容</div>
                <div class="value">{{detail.course_content}}</div>
              </div>
              <div class="item">
                <div class="label">计划时长</div>
                <div class="value">{{detail.plan_lesson_hour}}小时</div>
              </div>

              <div class="item">
                <div class="label">所在班级</div>
                <div class="value">{{detail.grade?detail.grade.name:'暂无'}}</div>
              </div>
              <div class="item">
                <div class="label">相关学员</div>
                <div class="value">{{detail.students?detail.students.map(v=>{return v.realname}).join('，'):'暂无'}}</div>
              </div>
              <div class="item">
                <div class="label">授课老师</div>
                <div class="value">{{detail.teacher?detail.teacher.realname:'暂无'}}</div>
              </div>
              <div class="item">
                <div class="label">课程阶段</div>
                <div class="value"> {{formateCourse_stage(detail.course_stage)}}</div>
              </div>
              <div class="item">
                <div class="label">学管师</div>
                <div class="value">{{detail.learn_manager?detail.learn_manager.nickname:''}}</div>
              </div>
              <div class="item">
                <div class="label">创建时间</div>
                <div class="value">{{$moment(detail.create_time).format("YYYY-MM-DD HH:mm")}}</div>
              </div>
              <div class="item">
                <div class="label">备注</div>
                <div class="value">{{detail.remark?detail.remark:'暂无'}}</div>
              </div>
            </div>
          </div>

        </div>
        <div class="footer">
          <template v-if="detail.plan_status==0">
            <el-button
              type="danger"
              plain
              size="small"
              @click="handleToRefuse"
            >拒绝</el-button>
            <el-button
              type="primary"
              plain
              size="small"
              @click="handleToAccept"
            >通过</el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleToAcceptNext"
            >通过并继续</el-button>
          </template>
          <template v-else>
            <el-button
              type="primary"
              size="small"
              @click="next()"
            >下一个</el-button>
          </template>
        </div>
      </div>
    </el-dialog>
    <RefuseDialog
      :isVisible="refuseDialog"
      @close="refuseDialog=false"
      @submit="refuseSubmit"
    />
  </div>
</template>

<script>



import {
  coursePlanlist,
  coursePlanOne,
  coursePlanCheck
} from "@/request/api";
import {
} from "@/utils/helper";
import RefuseDialog from "@/components/DialogComponents/RefuseDialog.vue";

export default {
  components: {
    RefuseDialog
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    plan_id: {
      type: Number,
      default: null,
    },
    grade_id: {
      type: Number,
      default: null,
    },
    student_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      planList: [],
      activeId: 0,
      detail: {},
      originList: [],
      refuseDialog: false,
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.planList = []
        this.originList = []
        this.init()
        this.activeId = this.plan_id
        this.fetchDetail(this.activeId)

      }
    },
    activeId(val) {
      if (val) {
        document.getElementById(val).scrollIntoView()
      }
    }
  },
  methods: {
    init() {
      for (let i = 0; i < 12; i++) {
        this.planList.push([])
      }
      this.fetchList()
    },
    fetchDetail(id) {
      coursePlanOne({
        id
      }).then((response) => {
        let res = response.data
        this.detail = res.data
      });
    },
    fetchList() {
      const startOfMonth = this.$moment().startOf('year').format('x');
      const endOfMonth = this.$moment().endOf('year').format('x');
      coursePlanlist({
        isPaging: 0,
        grade_id: this.grade_id || '',
        student_id: this.student_id || '',
        /*  start_plan_start_time: startOfMonth,
         end_plan_start_time: endOfMonth, */
        is_all_type: 1,
      }).then((response) => {
        let res = response.data
        this.originList = JSON.parse(JSON.stringify(res.data.list))
        this.originList.sort(this.compare('plan_start_time'));


        this.planList.forEach((item, index) => {
          this.originList.forEach((citem, cindex) => {
            if ((index + 1) == this.$moment(citem.plan_start_time).format(
              'MM')) {
              this.planList[index].push(citem)
            }
          })
        })
      });
    },
    compare(attr) {
      return function (a, b) {
        var val1 = a[attr];
        var val2 = b[attr];
        return val1 - val2;
      }
    },
    emitClose() {
      this.$emit("close");
    },
    changeId(citem) {
      if (citem.id == this.activeId) {
        return
      }
      this.activeId = citem.id
      this.fetchDetail(citem.id)
    },
    formateCourse_stage(val) {
      let stages = {
        '0': '基础阶段',
        '1': '强化阶段',
        '2': '冲刺阶段',
      }
      return stages[val]
    },
    refuseSubmit({ text }) {
      let query = {
        id: this.detail.id,
        is_access: 0,
        refuse_reason: text,
      }
      coursePlanCheck(query).then((res) => {
        this.planList = []
        this.init()
        this.fetchDetail(this.activeId)
      });
    },
    handleToRefuse() {
      this.refuseDialog = true
    },
    handleToAccept() {
      this.$confirm("确定要通过此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let query = {
          id: this.detail.id,
          is_access: 1,
        }
        coursePlanCheck(query).then((res) => {
          this.planList = []
          this.init()
          this.fetchDetail(this.activeId)

          /*  if (isNext) {
             this.next()
           } else {
           } */
        });
      })
    },
    handleToAcceptNext() {
      this.$confirm("确定要通过此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let query = {
          id: this.detail.id,
          is_access: 1,
        }
        coursePlanCheck(query).then((res) => {
          this.planList = []
          this.init()
          this.next()
          this.fetchDetail(this.activeId)

        });
      })
    },
    next() {
      let ind = this.originList.findIndex(v => {
        return this.activeId == v.id
      })
      if (ind + 1 == this.originList.length) {
        return this.$message.error('已经是最后一个了')
      } else {
        this.activeId = this.originList[ind + 1].id
        this.fetchDetail(this.activeId)
      }
    }
  },
};
</script>

<style lang="less" scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.content {
  display: flex;
  align-items: center;
  height: 600px;
  .modular {
    height: 100%;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .descriptions {
      width: 504px;
      height: 100%;
      background: #ecf2fe;
      border-radius: 4px;
      padding: 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .other {
        font-size: 18px !important;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 600;
        color: #303133;
      }

      .item {
        & + .item {
          margin-top: 16px;
        }
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: 20px;

        .label {
          width: 60px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #909399;
          line-height: 16px;
          margin-right: 16px;
        }
        .value {
          flex: 1;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #303133;
          line-height: 16px;
        }
      }
    }

    .timeline {
      /deep/.el-timeline-item {
        padding-bottom: 8px;
      }
      .title {
        font-size: 24px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #303133;
        margin-bottom: 16px;
      }
      .point {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #333;
        margin-top: 16px;
      }
      .card {
        width: 352px;
        background: #f5f5f5;
        border-radius: 4px;
        padding: 8px;
        cursor: pointer;
        div {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #303133;
        }
      }
      .active {
        background-color: #ebf5ff;
        color: #409eff;
        div {
          color: #409eff;
        }
      }
    }
  }
  .modular::-webkit-scrollbar {
    /*滚动条宽*/
    width: 4px;
  }

  .modular::-webkit-scrollbar-thumb {
    /*正在滑动的小块*/
    border-radius: 10px;
  }

  .modular::-webkit-scrollbar-track {
    /*滚动内部轨道*/
    border-radius: 10px;
  }
}
.footer {
  margin-top: 16px;
}
</style>