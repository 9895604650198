<template>
  <div class="content">
    <el-dialog
top="5vh"
      :title="content.name + '   ' + content.code"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="734px"
      @close="emitClose"
    >
      <div style="margin-bottom: 24px">
        <el-button
          type="primary"
          size="small"
          @click="createVisible = !createVisible"
          >新建</el-button
        >
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        height="460"
        border
      >
        <el-table-column
          prop="value"
          label="字典值"
          width="180"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag size="small" v-show="scope.row.value">{{
              scope.row.value
            }}</el-tag>
          </template></el-table-column
        >
        <el-table-column
          prop="create_time"
          label="创建日期"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column prop="date" label="操作" width="200">
          <template slot-scope="scope">
            <div class="templateLink">
              <!--              <el-link type="primary" :underline="false">上移</el-link>
              <el-link type="primary" :underline="false">下移</el-link> -->
              <el-link
                type="danger"
                :underline="false"
                @click="deleteItem(scope.row)"
                >删除</el-link
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary" @click="emitClose">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
top="5vh" title="字典值创建" :visible.sync="createVisible" width="370px">
      <el-form
        :model="form"
        label-position="right"
        label-width="80px"
        size="small"
        ref="dialogform"
        :rules="rules"
      >
        <el-form-item label="字典值" prop="value">
          <el-input
            v-model="form.value"
            autocomplete="off"
            style="width: 220px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="createVisible = false">取 消</el-button>
        <el-button type="primary" @click="createValue">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  dictionary,
  createdictionaryvalue,
  deletedictionaryvalue,
} from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      tableData: [],
      form: {},
      createVisible: false,
      rules: {
        value: [{ required: true, message: "请输入字典值", trigger: "blur" }],
      },
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      let query = {
        code: val.code,
      };
      dictionary(query).then((res) => {
        this.tableData = res.data.data.values || [];
      });
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    createValue() {
      this.$refs["dialogform"].validate((valid) => {
        if (valid) {
          let query = {
            code: this.content.code,
            value: this.form.value,
          };
          createdictionaryvalue(query).then((res) => {
            if (res.data.code == 0) {
              let query = {
                code: this.content.code,
              };
              dictionary(query).then((res) => {
                this.tableData = res.data.data.values || [];
                this.form = {};
                this.createVisible = false;
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteItem(item) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            code: item.code,
            value: item.value,
          };
          deletedictionaryvalue(query).then((res) => {
            if (res.data.code == 0) {
              this.$notify.success({
                title: "提示",
                message: "删除成功",
              });
              dictionary({ code: item.code }).then((res) => {
                this.tableData = res.data.data.values || [];
              });
            }
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.templateLink {
  width: 100%;
  .el-link {
    margin: 0px 5px;
  }
}
</style>