<template>
  <div>
    <el-table :data="result"
              :header-cell-style="{
        textAlign: 'center',
        color: '#909399',
        fontFamily: 'PingFang SC',
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: '#F2F6FC',
      }"
              :cell-style="{ textAlign: 'center', fontSize: '13px' }"
              border>
      <el-table-column prop="teacher_realnames"
                       label="老师姓名"
                       width="120"
                       show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="total_final_student_level_other_amount"
                       label="报考信息"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{scope.row.apply_college_info}} / {{scope.row.apply_academy_info}} / {{scope.row.apply_subject_info}}
        </template>
      </el-table-column>
      <el-table-column prop="allocation_success_time"
                       label="匹配完成时间"
                       show-overflow-tooltip>
      </el-table-column>

      <!-- <el-table-column prop="name"
                       label="操作"
                       width="80">
        <template slot-scope="scope">
          <div class="templateLink">
            <el-link type="primary"
                     :underline="false"
                     @click="handleUp(scope.row)">修改</el-link>
          </div>
        </template>
      </el-table-column> -->
    </el-table>

    <el-dialog append-to-body
               title="修改"
               :visible.sync="dialogVisible"
               width="30%">
      <el-form :model="form"
               :rules="rules"
               ref="ruleForm"
               label-width="100px"
               class="demo-ruleForm">
        <el-form-item label="其他费用"
                      prop="cost">
          <el-input v-model="form.cost"
                    placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submit('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderFeeDetail } from '@/request/api'
export default {
  props: {
    result: {
      type: Array,
      default: () => [],
    },
    contractDatail: {
      default: () => {},
    },
    content: {
      default: () => [],
    },
    total_other_amount: {
      default: 0,
    },
    contractForm: {
      default: {},
    },
    cid: {
      default: '',
    },
  },
  filters: {
    formateSub: function (val) {
      if (val == null || val == 1) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += `(${item.subject_code})${item.subject_name}` + '，'
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
    formateWatcher: function (val) {
      if (!val) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += item.alias + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },

    formateManage_Watcher(val) {
      if (!val) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += item.nickname + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
  },
  data() {
    return {
      EditMatchingOrderContent: {},
      arr_student_level_data: [],
      dialogVisible: false,
      form: {
        cost: '',
      },
      rules: {
        cost: [
          { required: true, message: '请输入其他费用', trigger: 'blur' },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: '输入最多两位小数的数字',
          },
        ],
      },
    }
  },
  methods: {
    getManageList(list) {
      if (list && list.length > 0) {
        return list.map((item) => item.nickname).join(' / ')
      } else {
        return '-'
      }
    },
    getPrice(list) {
      if (list && list.length) {
        if (list.length == 1) {
          return list[0].ref_exist_student_level_other_amount + '元'
        } else {
          return list
            .map(
              (item) =>
                `${item.student_realname}：${
                  item.ref_exist_student_level_other_amount || 0
                }元`
            )
            .join(',')
        }
      } else {
        return '-'
      }
    },
    handleUp(row) {
      console.log('row:', row)
      this.form.cost = row.total_final_student_level_other_amount
      this.form.data_id = row.data_id
      this.dialogVisible = true
    },
    submit(formName) {
      console.log('resuly', this.result)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.result.forEach((item) => {
            if (item.data_id == this.form.data_id) {
              item.total_final_student_level_other_amount = this.form.cost
            }
          })
          this.arr_student_level_data = this.result.map((item) => {
            return {
              data_id: item.data_id,
              data_other_amount: Number(
                item.total_final_student_level_other_amount
              ),
            }
          })
          // let arr_student_level_data = [
          //   {
          //     data_id: this.form.data_id,
          //     data_other_amount: Number(this.form.cost),
          //   },
          // ];
          this.content.forEach((item, index) => {
            if (item.id == this.contractDatail.id) {
              this.$set(
                this.content[index],
                'final_real_company_hour_amount',
                Number(this.contractForm.final_real_company_hour_amount)
              )
              this.$set(
                this.content[index],
                'final_real_company_service_amount',
                Number(this.contractForm.final_real_company_service_amount)
              )
            }
          })

          let arr_lesson_log = []
          this.content.forEach((item) => {
            arr_lesson_log.push({
              log_id: item.id,
              final_real_lesson_hour: item.final_real_lesson_hour,
              final_real_company_hour_amount:
                item.final_real_company_hour_amount || 0,
              final_real_company_service_amount:
                item.final_real_company_service_amount || 0,
            })
          })
          getOrderFeeDetail({
            cid: this.cid,
            total_other_amount: this.total_other_amount || 0,
            arr_lesson_log,
            arr_student_level_data: this.arr_student_level_data,
          }).then((res) => {
            // console.log('res:修改', res)
            if (res.data.code == 0) {
              this.dialogVisible = false
              // this.$message.success('修改成功!');
              this.$emit(
                'submit',
                res.data.data.company_order_fee_obj.total_amount
                // this.result.map(item=>{
                //     return {
                //         data_id:item.data_id,
                //         data_other_amount:item.total_final_student_level_other_amount
                //     }
                // })
              )
            }
          })
          //   alert("submit!");
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style >
</style>