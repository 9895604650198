<template>
  <div>
    <el-dialog top="5vh"
               title="订单生成账单"
               :visible.sync="dialogFormVisible"
               @close="emitClose"
               :close-on-click-modal="false"
               width="1080px">
      <el-form :model="dataForm"
               label-position="left"
               ref="formRules"
               :rules="rules"
               label-width="130px"
               size="small">
        <el-form-item label="机构名称">
          <div class="info">
            {{ company_name }}
            <!-- {{ teacher_info.realname }}/{{ teacher_info.mobile }} -->
          </div>
        </el-form-item>
        <el-form-item label="账单名称：">
          <div class="form-item">
            <el-input v-model="dataForm.order_name"
                      placeholder="请输入账单名称"></el-input>
            <div class="amount-item">
              <div class="item-label">当前金额</div>
              <div class="item-value">{{ totalAmount }}元</div>
            </div>
            <div class="amount-item">
              <div class="item-label">大写金额</div>
              <div class="item-value">
                {{ formateTotalAmount(totalAmount) }}
              </div>
            </div>
            <div class="amount-item">
              <div class="item-label">总计时长</div>
              <div class="item-value">{{ totalHour }}小时</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="附加费名称："
                      prop="total_other_amount_name">
          <el-input style="width: 300px"
                    v-model="dataForm.total_other_amount_name"
                    placeholder="请输入附加费名称"></el-input>
        </el-form-item>
        <el-form-item label="附加费金额："
                      prop="total_other_amount">
          <el-input type="number"
                    style="width: 300px"
                    @input="upPrice"
                    v-model="dataForm.total_other_amount"
                    placeholder="请输入附加费金额"></el-input>
        </el-form-item>

        <el-form-item label="备注：">
          <el-input type="textarea"
                    v-model="dataForm.remark"
                    placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <div class="table-modular">
          <div class="header">已选订单</div>
          <el-table :data="result"
                    :header-cell-style="{
              textAlign: 'center',
              color: '#909399',
              fontFamily: 'PingFang SC',
              fontSize: '14px',
              fontWeight: '400',
              backgroundColor: '#F2F6FC',
            }"
                    :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                    border>
            <el-table-column prop="order_no"
                             label="订单号"
                             width="120"
                             show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="company.name"
                             label="机构简称"
                             width="120"
                             show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="创建时间"
                             width="120">
              <template slot-scope="scope">
                <span>{{
                    $moment(scope.row.create_time).format("YYYY-MM-DD")
                  }}</span>
              </template>
            </el-table-column>

            <el-table-column align="center"
                             prop="status"
                             label="订单状态"
                             width="80"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag size="small"
                        type="warning"
                        v-show="scope.row.match_order_sync_status == 1">待服务</el-tag>
                <el-tag size="small"
                        type="warning"
                        v-show="scope.row.match_order_sync_status == 2">待对接</el-tag>
                <el-tag size="small"
                        v-show="scope.row.match_order_sync_status == 3">匹配中</el-tag>
                <el-tag size="small"
                        type="success"
                        v-show="scope.row.match_order_sync_status == 4">已完成</el-tag>
                <el-tag size="small"
                        type="danger"
                        v-show="scope.row.match_order_sync_status == 5">已取消</el-tag>
              </template>
            </el-table-column>

            <el-table-column align="center"
                             prop="status"
                             label="支付状态"
                             width="80"
                             type="success"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <div @click="$buttonPermission('aggregatePayment:flag')&&aggregatePayment(scope.row)">
                  <el-tag size="small"
                          type="danger"
                          v-show="scope.row.payment_status == 0">未支付</el-tag>
                  <el-tag size="small"
                          type="success"
                          v-show="scope.row.payment_status == 1">已支付</el-tag>
                  <el-tag size="small"
                          type="success"
                          v-show="scope.row.payment_status == 2">已退款</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center"
                             prop="status"
                             label="账单状态"
                             width="80"
                             type="success"
                             show-overflow-tooltip>
              <template slot-scope="scope">

                <el-tag size="small"
                        type="danger"
                        v-show="scope.row.company_order_process_status  == 0">未绑定</el-tag>
                <el-tag size="small"
                        type="success"
                        v-show="scope.row.company_order_process_status  == 1">账单中</el-tag>
                <el-tag size="small"
                        type="success"
                        v-show="scope.row.company_order_process_status  == 2">已通过</el-tag>

              </template>
            </el-table-column>

            <el-table-column prop="nickname"
                             label="报考信息"
                             width="400"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.apply_year ? scope.row.apply_year + "年/" : "" }}
                <span class="pointer"
                      @click="institutionOpen(scope.row)"
                      v-if="
                      scope.row.apply_college_name &&
                      scope.row.apply_college_code
                    ">
                  {{ `${scope.row.apply_college_name}` }}/</span>
                <span v-if="
                      scope.row.apply_academy_name &&
                      scope.row.apply_academy_code
                    ">
                  {{
                      `(${scope.row.apply_academy_code})${scope.row.apply_academy_name}`
                    }}/</span>
                <span v-if="
                      scope.row.apply_major_name && scope.row.apply_major_code
                    ">
                  {{
                      `(${scope.row.apply_major_code})${scope.row.apply_major_name}`
                    }}/</span>

                <span>
                  {{
                      scope.row.apply_direction_code
                        ? `(${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
                        : "不限"
                    }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="subjects"
                             label="辅导科目"
                             width="160"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.subjects | formateSub }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center"
                             prop="lesson_hours"
                             label="报名课时"
                             width="100"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.lesson_hours + "小时" }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center"
                             label="匹配老师"
                             width="130"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{
                    getManageList(scope.row.bind_allocation_order_manage_list)
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center"
                             label="对接老师"
                             width="130"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{
                    scope.row.manage_watcher | formateManage_Watcher
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="更新时间"
                             align="center"
                             width="150">
              <template slot-scope="scope">
                {{
                    scope.row.update_time
                      ? $moment(scope.row.update_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : ""
                  }}
              </template>
            </el-table-column>
            <el-table-column prop="name"
                             label="操作"
                             width="80"
                             fixed="right">
              <template slot-scope="scope">
                <div class="templateLink">
                  <el-link type="primary"
                           :underline="false"
                           @click="openEditMatchingOrderDialog(scope.row)"
                           :disabled="!$buttonPermission('ordermatching:edit')">变更</el-link>
                  <el-link type="success"
                           :underline="false"
                           @click="changeStatus(scope.row)"
                           v-if="scope.row.match_order_sync_status == 5">恢复</el-link>
                  <el-link v-else
                           type="danger"
                           :disabled="
                        [4, 5].includes(scope.row.match_order_sync_status)
                      "
                           @click="filterArr(scope.row)">终止</el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="btn-wrap">
          <el-button size="mini" @click="handleToEditList"
            >修改课时费</el-button
          >
        </div> -->
        </div>

      </div>

      <!-- <div v-else>
        <AddSeniorStudent @submit="costUp"
                          :total_other_amount="dataForm.total_other_amount"
                          :cid="content[0].cid"
                          :contractDatail="contractDatail"
                          :content="result"
                          :result="studentData"
                          :contractForm="contractForm" />
      </div> -->

      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确定</el-button>
        <!-- <el-button type="primary" @click="submit('formRules')">确定</el-button> -->
      </div>
    </el-dialog>
    <!-- <EditMatchingOrder :isVisible="EditMatchingOrderDialog"
                       @close="EditMatchingOrderDialog = false"
                       @submit="EditMatchingOrderSubmit"
                       :content="EditMatchingOrderContent"></EditMatchingOrder>
    <EditLogHourAmount :content="editContent"
                       :isVisible="editDialog"
                       @close="editDialog = false"
                       @submit="EditLogHourAmountSubmit" />
    <EditLogHourAmountList :list="editlist"
                           :isVisible="editlistDialog"
                           @close="editlistDialog = false"
                           @submit="EditLogHourAmountListSubmit" />
    <InstitutionDetails ref="institutionDetails"></InstitutionDetails> -->

    <el-dialog append-to-body
               top="5vh"
               title="修改签约费"
               :visible.sync="dialogContract"
               @close="dialogContract = false"
               :close-on-click-modal="false"
               width="440px">
      <el-form :model="contractForm"
               label-position="right"
               ref="contractFormRules"
               :rules="rules"
               label-width="130px"
               size="small">
        <el-form-item label="当前机构签约费：">
          <span class="item-value">
            {{
              contractDatail.final_real_company_hour_amount
                ? contractDatail.final_real_company_hour_amount
                : contractDatail.ref_hour_income_company_amount
                ? contractDatail.ref_hour_income_company_amount
                : "0"
            }}元/小时
          </span>
        </el-form-item>
        <el-form-item label="机构签约费："
                      prop="final_real_company_hour_amount">
          <el-input placeholder="请输入机构签约费"
                    v-model="contractForm.final_real_company_hour_amount"
                    class="input-with-select">
            <span slot="append">元/小时</span>
          </el-input>
        </el-form-item>

        <el-form-item label="当前机构附加费：">
          <span class="item-value">
            {{
              contractDatail.final_real_company_service_amount
                ? contractDatail.final_real_company_service_amount
                : 0
            }}元
          </span>
        </el-form-item>
        <el-form-item label="机构附加费："
                      prop="final_real_company_service_amount">
          <el-input placeholder="请输入机构附加费："
                    v-model="contractForm.final_real_company_service_amount"
                    class="input-with-select">
            <span slot="append">元/小时</span>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="dialogContract = false">取消</el-button>
        <el-button type="primary"
                   @click="contractSubmit('contractFormRules')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  teacherOne,
  lessonLoglist,
  feeDetailByMatchOrder,
  orderFee,
  byMatchOrder,
  dispatchLessonLogUp,
  dataViewByMatchOrder,
  editeacherorder,
} from '@/request/api'
import { adminUrl } from '@/utils/helper.js'
import { digitUppercase } from '@/utils/helper'
import EditMatchingOrder from '../../../components/DialogComponents/EditMatchingOrder/EditMatchingOrder.vue'
import EditLogHourAmount from '@/components/DialogComponents/ClassService/EditLogHourAmount.vue'
import EditLogHourAmountList from '@/components/DialogComponents/ClassService/EditLogHourAmountList.vue'
import AddSeniorStudent from '@/components/DialogComponents/TeacherResources/AddSeniorStudent.vue'
import InstitutionDetails from '@/components/DialogComponents/InstitutionDetails/index.vue'
export default {
  components: {
    AddSeniorStudent,
    EditLogHourAmount,
    EditLogHourAmountList,
    EditMatchingOrder,
    InstitutionDetails,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    formateSub: function (val) {
      if (val == null || val == 1) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += `(${item.subject_code})${item.subject_name}` + '，'
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
    formateWatcher: function (val) {
      if (!val) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += item.alias + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },

    formateManage_Watcher(val) {
      if (!val) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += item.nickname + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
  },
  data() {
    return {
      EditMatchingOrderDialog: false, // 编辑订单弹窗
      EditMatchingOrderContent: {},
      arr_student_level_data: [],
      arr_lesson_log: [],
      ref_hour_income_company_amount: [],

      studentData: [],
      company_name: '',
      dialogFormVisible: this.isVisible,
      dataForm: {},
      teacher_info: {},
      result: [],
      chooseList: [],
      totalAmount: 0,
      totalHour: 0,
      editDialog: false,
      editContent: {},
      editlist: [],
      editlistDialog: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      result_other: [],
      unitConversion: 1,
      firstDayOfMonth: null,
      nextMonthFirstDay: null,
      dialogContract: false,
      contractDatail: {},
      contractForm: {
        final_real_company_hour_amount: 0,
        final_real_company_service_amount: 0,
      },
      rules: {
        total_other_amount_name: [],
        total_other_amount: [
          {
            pattern: /^(?=.*\d)\d*(?:\.\d{1,2})?$/,
            message: '请输入正整数(限两位小数)',
            trigger: 'blur',
          },
        ],
        final_real_company_hour_amount: [
          { required: true, message: '请输入机构签约费', trigger: 'blur' },
          {
            pattern: /^(?=.*\d)\d*(?:\.\d{1,2})?$/,
            message: '请输入正整数(限两位小数)',
            trigger: 'blur',
          },
        ],

        final_real_company_service_amount: [
          { required: true, message: '请输入机构签约费', trigger: 'blur' },
          {
            pattern: /^(?=.*\d)\d*(?:\.\d{1,2})?$/,
            message: '请输入正整数(限两位小数)',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      console.log(val)
      if (val) {
        this.result = JSON.parse(JSON.stringify(this.content))
        this.fetList()
        this.fetTeacherDetail()
      }
    },
    result(val) {},
  },
  methods: {
    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.apply_college_code)
    },
    formateOrder() {
      // 把subjects参数转换为处理并转换字符串，必须要number格式
      if (this.EditMatchingOrderContent.subjects) {
        let arr = []
        this.EditMatchingOrderContent.subjects.forEach((item) => {
          arr.push(item.subject_code)
        })
        this.EditMatchingOrderContent.subjects = arr
      }
      /*      if (this.EditMatchingOrderContent.watcher.length) {
             this.EditMatchingOrderContent.watcher = this.EditMatchingOrderContent.watcher[0].id;
           } */
    },
    // 打开编辑订单弹窗
    openEditMatchingOrderDialog(item) {
      this.EditMatchingOrderContent = JSON.parse(JSON.stringify(item))
      this.formateOrder()
      this.EditMatchingOrderDialog = !this.EditMatchingOrderDialog
    },
    // 修改组件提交
    EditMatchingOrderSubmit({ form }) {
      editeacherorder(form).then((res) => {
        if (res.data.code == 0) {
          this.$notify.success({
            title: '提示',
            message: '修改成功',
          })
          this.fetList()
          this.EditMatchingOrderDialog = false
          this.fetList()
        }
      })
    },
    getManageList(list) {
      if (list && list.length > 0) {
        return list.map((item) => item.nickname).join(' / ')
      } else {
        return '-'
      }
    },
    upPrice() {
      this.result.forEach((item, index) => {
        // console.log("cc",item.id)
        if (item.id == this.contractDatail.id) {
          this.$set(
            this.result[index],
            'final_real_company_hour_amount',
            Number(this.contractForm.final_real_company_hour_amount)
          )
          this.$set(
            this.result[index],
            'final_real_company_service_amount',
            Number(this.contractForm.final_real_company_service_amount)
          )
        }
      })
      this.arr_lesson_log = []
      this.company_name = this.content[0].company.company_name
      this.result.forEach((item) => {
        this.arr_lesson_log.push({
          id: item.id,
          ref_hour_income_company_amount: item.ref_hour_income_company_amount,
        })
      })

      this.getDetail()
    },
    getDetail() {
      console.log('进去获得订单明细')
      console.log('附加非金额', this.dataForm.total_other_amount)
      feeDetailByMatchOrder({
        total_other_amount: this.dataForm.total_other_amount,
        cid: this.content[0].cid,
        arr_match_order: this.arr_lesson_log,
      }).then((res) => {
        console.log('res', res)
        this.totalAmount = res.data.data.company_order_fee_obj.total_amount
      })
    },
    costUp(total_amount) {
      this.totalAmount = total_amount
    },

    // 获取学生列表
    // getStudent() {
    //   let ids = this.result.map((item) => item.id)
    //   dataViewByMatchOrder({
    //     arr_direct_match_order_id: ids,
    //   }).then((res) => {
    //     this.studentData = res.data.data.arr_data_view
    //   })
    // },

    contractSubmit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.result.forEach((item, index) => {
            // console.log("cc",item.id)
            if (item.id == this.contractDatail.id) {
              this.$set(
                this.result[index],
                'final_real_company_hour_amount',
                Number(this.contractForm.final_real_company_hour_amount)
              )
              this.$set(
                this.result[index],
                'final_real_company_service_amount',
                Number(this.contractForm.final_real_company_service_amount)
              )
            }
          })

          this.fetTeacherDetail()
          this.getDetail()
          this.dialogContract = false
          console.log('submit!!')
        } else {
          console.log('error submit!!')
        }
      })
    },
    emitClose() {
      this.result_other = []
      this.result = []
      this.dataForm = {}
      this.$emit('close')
      this.arr_lesson_log = []
    },
    fetTeacherDetail() {
      console.log('result', this.result)
      console.log('一开始获得订单明细')
      this.arr_lesson_log = []
      this.company_name = this.content[0].company.company_name
      this.result.forEach((item) => {
        this.arr_lesson_log.push({
          id: item.id,
          ref_hour_income_company_amount: item.ref_hour_income_company_amount,
        })
      })

      feeDetailByMatchOrder({
        total_other_amount: this.dataForm.total_other_amount,
        cid: this.content[0].cid,
        arr_match_order: this.arr_lesson_log,
      }).then((response) => {
        let res = response.data
        this.teacher_info = res.data
        console.log(' this.teacher_info', this.teacher_info)
        let mouth = this.$moment(
          this.getObjectWithMinValue(this.result).create_time
        ).month()

        this.$set(
          this.dataForm,
          'order_name',
          `${this.company_name}机构的${mouth + 1}月派遣账单`
        )
        console.log('res.data1111', res.data)
        if (res.data != undefined) {
          this.totalAmount = res.data.company_order_fee_obj.total_amount
          this.totalHour = res.data.company_order_fee_obj.service_hours
        }

        // this.computedAmount();
      })

      // getOrderFeeDetail({
      //   cid: this.content[0].company_id,
      //   arr_lesson_log: arr_lesson_log,
      // }).then((response) => {
      //   console.log("response", response);
      //   let res = response.data;
      //   this.teacher_info = res.data;
      // });
    },
    getObjectWithMinValue(objects, key) {
      objects.sort(function (a, b) {
        return a.create_time - b.create_time
      })
      return objects[0]
    },
    fetList() {
      const params = Object.assign({}, this.query, {
        lesson_statuses: 3,
        company_lesson_statuses: 1,
        is_statistic_lesson_company: 1,
        force_delete_types: 2,
      })
      lessonLoglist(params).then((response) => {
        let res = response.data
        this.pageTotal = res.data.count
        this.filterList(res.data.list)
      })
    },
    filterList(list) {
      let arr = list.filter((v) => {
        return (
          this.result.findIndex((t) => {
            return t.id == v.id
          }) == -1
        )
      })
      this.result_other = arr
    },
    submit(rules) {
      const maxValue = this.result.reduce((max, obj) => {
        return obj.create_time > max ? obj.create_time : max
      }, 0)

      const minValue = this.result.reduce((min, obj) => {
        return obj.create_time < min ? obj.create_time : min
      }, Infinity)
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.arr_lesson_log = []
          this.result.forEach((item) => {
            this.arr_lesson_log.push({
              id: item.id,
              ref_hour_income_company_amount:
                item.ref_hour_income_company_amount,
            })
          })
          let params = {
            order_name: this.dataForm.order_name,
            total_amount: this.totalAmount,
            total_other_amount: this.dataForm.total_other_amount || 0,
            total_other_amount_name: this.dataForm.total_other_amount_name,
            cid: this.content[0].cid,
            start_time: minValue,
            end_time: maxValue,
            service_hours:
              this.teacher_info.company_order_fee_obj.service_hours,
            arr_match_order: this.arr_lesson_log,
          }
          byMatchOrder(params).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('创建成功')
              this.$emit('submit')
              this.emitClose()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    computedAmount() {
      let a = this.result.reduce((acc, cur) => {
        return (
          acc +
          Number(cur.final_real_lesson_hour_amount * cur.final_real_lesson_hour)
        )
      }, 0)
      let b = this.result.reduce((acc, cur) => {
        return acc + Number(cur.final_real_lesson_hour)
      }, 0)
      this.totalAmount = a.toFixed(2)
      this.totalHour = b.toFixed(2)
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    formateTotalAmount(e) {
      return digitUppercase(e)
    },
    handleToEdit(row) {
      if (row.final_real_company_hour_amount) {
        this.contractForm.final_real_company_hour_amount =
          row.final_real_company_hour_amount
      } else if (row.ref_hour_income_company_amount) {
        this.contractForm.final_real_company_hour_amount =
          row.ref_hour_income_company_amount
      } else {
        this.contractForm.final_real_company_hour_amount = 0
      }
      this.contractForm.final_real_company_service_amount =
        row.final_real_company_service_amount || 0

      this.contractDatail = row
      this.dialogContract = true
    },
    EditLogHourAmountSubmit(data) {
      let ind = this.result.findIndex((v) => {
        return v.id == data.id
      })
      this.$set(
        this.result[ind],
        'final_real_lesson_hour_amount',
        data.final_real_lesson_hour_amount
      )
      this.fetTeacherDetail()
    },
    EditLogHourAmountListSubmit(data) {
      data.ids.forEach((item, index) => {
        this.$set(
          this.result.find((v) => {
            return v.id == item
          }),
          'final_real_lesson_hour_amount',
          data.final_real_lesson_hour_amount
        )
      })
      this.fetTeacherDetail()
    },
    handleToEditList() {
      this.editlist = this.chooseList
      this.editlistDialog = true
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getList()
    },
    handleToChoose(row) {
      let obj = Object.assign({}, row)
      this.result.push(obj)

      this.filterList(this.result_other)
      this.computedAmount()
      this.fetTeacherDetail()
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    },
  },
}
</script>

<style lang="less" scoped>
.info {
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #303133;
}
.form-item {
  display: flex;
  .el-input {
    width: 300px;
  }
  .amount-item {
    display: flex;
    align-items: center;
    margin-left: 16px;
    .item-label {
      font-size: 14px;
      font-weight: 400;
      color: #909399;
    }
    .item-value {
      font-size: 14px;
      margin-left: 16px;
      font-weight: 600;
      color: #e6a23c;
    }
  }
}
.table-modular {
  margin-top: 24px;
  .header {
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    margin-bottom: 16px;
  }
}
.btn-wrap {
  margin-top: 16px;
}
.line {
  border: dashed 1px #dcdfe6;
  margin: 32px 0px;
}
.page-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0px;
}
</style>