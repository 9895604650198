<template>
  <div class="content">
    <el-dialog
top="5vh"
      title="修改登录密码"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="350px"
      @close="emitClose"
      :modal-append-to-body="false"
    >
      <el-form label-position="right">
        <el-form-item label="">
          <el-input
            placeholder="请输入手机号"
            v-model="phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            placeholder="请输入验证码"
            v-model="yzcode"
          >
            <el-button
              slot="append"
              :disabled="codedisabled"
              @click="sendsms"
            >{{ yzcodetext }}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            placeholder="请输入新的登录密码"
            v-model="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <!-- <el-button @click="emitClose">取 消</el-button> -->
        <el-button
          type="primary"
          @click="submit()"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { smscode, resetpassword } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      codeTime: 0,
      codedisabled: false,
      yzcodetext: "获取验证码",
      phone: "",
      yzcode: "",
      password: "",
    };
  },
  mounted() { },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    sendsms() {
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
        this.$notify.error({
          title: "请输入正确的手机号",
          showClose: false,
          duration: 2000,
        });
        return false;
      } else {
        this.codeTime = 60;
        this.codedisabled = true;
        let query = {
          phone: this.phone,
        };
        smscode(query).then((res) => {
          this.timer();
        });
      }
    },
    timer() {
      if (this.codeTime > 0) {
        this.codeTime--;
        this.yzcodetext = this.codeTime + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.codeTime = 0;
        this.yzcodetext = "获取验证码";
        this.codedisabled = false;
      }
    },
    emitClose() {
      this.$emit("close");
    },
    submit(rules) {
      if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.phone)) {
        this.$notify.error({
          title: "请输入正确的手机号",
          showClose: false,
          duration: 2000,
        });
        return false;
      }
      if (!this.yzcode) {
        this.$notify.error({
          title: "请输入验证码",
          showClose: false,
          duration: 2000,
        });
        return false;
      }
      let query = {
        phone: this.phone,
        code: this.yzcode,
        password: this.password,
      };
      resetpassword(query).then((res) => {
        if (res.data.code == 0) {
          this.$notify.success({
            title: "提示",
            message: "修改成功，请重新登陆",
          });
          this.$router.replace({ path: "/login" });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
.el-input {
  width: 318px;
}
</style>