<template>
  <div>
    <el-dialog top="5vh"
               title="进度"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               @open="open"
               :close-on-click-modal="false"
               width="700px">
      <div>
        <p>{{content.realname}}同学，请核对以下当前的课时进度哦～</p>
        <p class="top">上月（{{content.lesson_info_pre_month}}月） <br>

          已确认完成课时 ：{{content.lesson_info_pre_done_lesson_hour !=0 ?content.lesson_info_pre_done_lesson_hour+'小时': '无' }}
        </p>
        <p class="top">本月（{{content.lesson_info_current_month}}月） <br>

          已确认完成课时 ：{{content.lesson_info_current_done_lesson_hour !=0 ? content.lesson_info_current_done_lesson_hour+ '小时' : '无'}}
        </p>
        <p class="top"> 报名总课时：{{content.lesson_info_total_lesson_hour !=0 ? content.lesson_info_total_lesson_hour+'小时': '无' }} <br>
          当前总已确认完成课时：{{content.lesson_info_total_done_lesson_hour !=0 ?content.lesson_info_total_done_lesson_hour+'小时' :'无' }}<br>
          当前已上待确认课时：{{content.lesson_info_total_toconfirm_lesson_hour  !=0 ?content.lesson_info_total_toconfirm_lesson_hour +'小时'  :'无' }}<br>
          总剩余课时：{{content.lesson_info_total_last_lesson_hour  !=0 ? content.lesson_info_total_last_lesson_hour +'小时':'无' }}</p>
        <p class="top">确认后若无误请回复“没问题”，有问题可以直接跟我反馈。</p>
      </div>
      <div class="footer">
        <el-button type="primary"
                   class="type"
                   :data-clipboard-text="clipboardText"
                   @click="copy">一键复制</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import Clipboard from 'clipboard'
export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    scheduleRow: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      hour: '小时',
      no: '无',
      kong: '',
      clipboardText: '',
      dialogFormVisible: false,
      content: {},
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
    },
    scheduleRow: {
      handler(newVal, oldVal) {
        this.content = newVal
      },
      deep: true, // 启用深度监听
    },
  },

  methods: {
    copy() {
      this.clipboardText = `${
        this.content.realname
      }同学，请核对以下当前的课时进度哦～

上月（${this.content.lesson_info_pre_month}月）
已确认完成课时 ：${
        this.content.lesson_info_pre_done_lesson_hour != 0
          ? this.content.lesson_info_pre_done_lesson_hour
          : '无'
      }${this.content.lesson_info_pre_done_lesson_hour != 0 ? '小时' : ''}

本月（${this.content.lesson_info_current_month}月）
已确认完成课时 ：${
        this.content.lesson_info_current_done_lesson_hour != 0
          ? this.content.lesson_info_current_done_lesson_hour
          : '无'
      }${this.content.lesson_info_current_done_lesson_hour != 0 ? '小时' : ''}

报名总课时：${
        this.content.lesson_info_total_lesson_hour != 0
          ? this.content.lesson_info_total_lesson_hour
          : '无'
      }${this.content.lesson_info_total_lesson_hour != 0 ? '小时' : ''}
当前总已确认完成课时：${
        this.content.lesson_info_total_done_lesson_hour != 0
          ? this.content.lesson_info_total_done_lesson_hour
          : '无'
      }${this.content.lesson_info_total_done_lesson_hour != 0 ? '小时' : ''}
当前已上待确认课时：${
        this.content.lesson_info_total_toconfirm_lesson_hour != 0
          ? this.content.lesson_info_total_toconfirm_lesson_hour
          : '无'
      }${
        this.content.lesson_info_total_toconfirm_lesson_hour != 0 ? '小时' : ''
      }
总剩余课时：${
        this.content.lesson_info_total_last_lesson_hour != 0
          ? this.content.lesson_info_total_last_lesson_hour
          : '无'
      }${this.content.lesson_info_total_last_lesson_hour != 0 ? '小时' : ''}

确认后若无误请回复“没问题”，有问题可以直接跟我反馈。
      `
      var clipboard = new Clipboard('.type')

      clipboard.on('success', (e) => {
        this.$message({
          message: '复制成功！',
          type: 'success',
        })
        this.handleToClose()
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({
          message: '该浏览器不支持自动复制',
          type: 'error',
        })
        // 释放内存
        clipboard.destroy()
      })
    },
    open() {
      // console.log('content', this.content)
    },
    handleToClose() {
      this.$emit('colse')
    },
  },
}
</script>
  <style lang="less" scoped>
/deep/.el-input-group__append {
  background: #eeeeee !important;
}
.wrap {
  /deep/.el-upload {
    width: 100%;
  }
  /deep/.el-upload-dragger {
    width: 100%;
  }
}
.top {
  margin-top: 15px;
  line-height: 25px;
}
</style>