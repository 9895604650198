<template>
  <div>
    <el-dialog top="5vh"
               title="生成机构账单"
               :visible.sync="dialogFormVisible"
               @close="emitClose"
               :close-on-click-modal="false"
               width="1080px">
      <el-form :model="dataForm"
               label-position="left"
               ref="formRules"
               :rules="rules"
               label-width="130px"
               size="small">
        <el-form-item label="机构名称">
          <div class="info">
            {{ company_name }}
            <!-- {{ teacher_info.realname }}/{{ teacher_info.mobile }} -->
          </div>
        </el-form-item>
        <el-form-item label="账单名称：">
          <div class="form-item">
            <el-input v-model="dataForm.order_name"
                      placeholder="请输入账单名称"></el-input>
            <div class="amount-item">
              <div class="item-label">当前金额</div>
              <div class="item-value">{{ totalAmount }}元</div>
            </div>
            <div class="amount-item">
              <div class="item-label">大写金额</div>
              <div class="item-value">
                {{ formateTotalAmount(totalAmount) }}
              </div>
            </div>
            <div class="amount-item">
              <div class="item-label">总计时长</div>
              <div class="item-value">{{ totalHour }}小时</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="附加费名称："
                      prop="total_other_amount_name">
          <el-input style="width: 300px"
                    v-model="dataForm.total_other_amount_name"
                    placeholder="请输入附加费名称"></el-input>
        </el-form-item>
        <el-form-item label="附加费金额："
                      prop="total_other_amount">
          <el-input type="number"
                    style="width: 300px"
                    @input="upPrice"
                    v-model="dataForm.total_other_amount"
                    placeholder="请输入附加费金额"></el-input>
        </el-form-item>

        <el-form-item label="备注：">
          <el-input type="textarea"
                    v-model="dataForm.remark"
                    placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <div v-if="step == 1">
        <div class="table-modular">
          <div class="header">已选课时</div>
          <el-table :data="result"
                    :header-cell-style="{
              textAlign: 'center',
              color: '#909399',
              fontFamily: 'PingFang SC',
              fontSize: '14px',
              fontWeight: '400',
              backgroundColor: '#F2F6FC',
            }"
                    :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                    border>
            <!-- @selection-change="handleSelectionChange"
          <el-table-column type="selection" width="50" fixed="left">
          </el-table-column> -->
            <!--   <el-table-column
            label="上课时间"
            width="150"
          >
            <template slot-scope="scope">
              {{scope.row.plan_start_time?$moment(scope.row.plan_start_time).format("YYYY-MM-DD HH:mm"):""}}
            </template>
          </el-table-column> -->
            <el-table-column prop="company_name"
                             label="机构名称"
                             width="150"
                             show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="dispatch_log_student_name"
                             label="学生姓名"
                             width="120"
                             show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="create_time"
                             label="登记日期"
                             width="120">
              <template slot-scope="scope">
                {{
                  scope.row.create_time
                    ? $moment(scope.row.create_time).format("YYYY-MM-DD")
                    : "-"
                }}
              </template>
            </el-table-column>

            <el-table-column prop="dispatch_log_course_theme"
                             label="课程主题"
                             width="200"
                             show-overflow-tooltip>
            </el-table-column>

            <el-table-column label="课程时长"
                             width="150"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.final_real_lesson_hour }}小时
              </template>
            </el-table-column>
            <el-table-column label="课时费"
                             width="150"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.final_real_lesson_hour_amount }}元/小时
              </template>
            </el-table-column>
            <el-table-column label="机构签约费"
                             width="150"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <div v-if="
                    scope.row.final_real_company_hour_amount ||
                    scope.row.ref_hour_income_company_amount
                  ">
                  {{
                    scope.row.final_real_company_hour_amount
                      ? scope.row.final_real_company_hour_amount
                      : scope.row.ref_hour_income_company_amount
                  }}元/小时
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column label="机构附加费"
                             align="center"
                             width="150"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                {{
                  scope.row.final_real_company_service_amount
                    ? scope.row.final_real_company_service_amount + "元"
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column label="课时状态"
                             width="120"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.lesson_status == 0"
                        size="mini"
                        type="warning">待确认</el-tag>
                <el-tag v-if="scope.row.lesson_status == 1"
                        size="mini">待回访</el-tag>
                <el-tag v-if="scope.row.lesson_status == 2"
                        size="mini"
                        type="danger">已拒绝</el-tag>
                <el-tag v-if="scope.row.lesson_status == 3"
                        size="mini"
                        type="success">已完成</el-tag>
                <!--     <el-tag
                v-if="scope.row.lesson_status==4"
                size="mini"
                type="info"
              >已取消</el-tag> -->
                <el-tag v-if="scope.row.lesson_status == 5"
                        size="mini"
                        type="info">已完结</el-tag>
                <el-tag v-if="scope.row.lesson_status == 6"
                        size="mini"
                        type="info">已暂停</el-tag>
              </template>
            </el-table-column>

            <el-table-column prop="name"
                             label="操作"
                             width="80"
                             fixed="right">
              <template slot-scope="scope">
                <div class="templateLink">
                  <el-link type="primary"
                           :underline="false"
                           @click="handleToEdit(scope.row)">修改</el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="btn-wrap">
          <el-button size="mini" @click="handleToEditList"
            >修改课时费</el-button
          >
        </div> -->
        </div>

        <div class="line"></div>

        <div class="table-modular">
          <div class="tableHeader">
            <div class="header">对应该机构其他已完成且待生成课酬单的课时记录</div>
            <div class="allCheck"
                 @click="allCheck">一键全选
            </div>
          </div>
          <el-table :data="result_other"
                    :header-cell-style="{
              textAlign: 'center',
              color: '#909399',
              fontFamily: 'PingFang SC',
              fontSize: '14px',
              fontWeight: '400',
              backgroundColor: '#F2F6FC',
            }"
                    :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                    border>
            <el-table-column prop="company_name"
                             label="机构名称"
                             width="150"
                             show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="dispatch_log_student_name"
                             label="学生姓名"
                             width="120"
                             show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="create_time"
                             label="登记日期"
                             width="120">
              <template slot-scope="scope">
                {{
                  scope.row.create_time
                    ? $moment(scope.row.create_time).format("YYYY-MM-DD")
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="dispatch_log_course_theme"
                             label="课程主题"
                             width="200"
                             show-overflow-tooltip>
            </el-table-column>

            <el-table-column label="机构课酬状态"
                             width="120"
                             align="center"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.company_lesson_status == 0"
                        size="mini"
                        type="warning">不可用</el-tag>
                <el-tag v-if="scope.row.company_lesson_status == 1"
                        size="mini">待生成</el-tag>
                <el-tag v-if="scope.row.company_lesson_status == 2"
                        size="mini"
                        type="success">已生成</el-tag>
              </template>
            </el-table-column>

            <el-table-column label="课程时长"
                             width="150"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.final_real_lesson_hour }}小时
              </template>
            </el-table-column>
            <el-table-column label="课时费"
                             width="150"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.final_real_lesson_hour_amount }}元/小时
              </template>
            </el-table-column>
            <el-table-column label="机构签约费"
                             width="150"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <div v-if="
                    scope.row.final_real_company_hour_amount ||
                    scope.row.ref_hour_income_company_amount
                  ">
                  {{
                    scope.row.final_real_company_hour_amount
                      ? scope.row.final_real_company_hour_amount
                      : scope.row.ref_hour_income_company_amount
                  }}元/小时
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column label="机构附加费"
                             width="150"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.final_real_company_service_amount || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="课时状态"
                             width="120"
                             show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tag v-if="scope.row.lesson_status == 0"
                        size="mini"
                        type="warning">待确认</el-tag>
                <el-tag v-if="scope.row.lesson_status == 1"
                        size="mini">待回访</el-tag>
                <el-tag v-if="scope.row.lesson_status == 2"
                        size="mini"
                        type="danger">已拒绝</el-tag>
                <el-tag v-if="scope.row.lesson_status == 3"
                        size="mini"
                        type="success">已完成</el-tag>
                <!--     <el-tag
                v-if="scope.row.lesson_status==4"
                size="mini"
                type="info"
              >已取消</el-tag> -->
                <el-tag v-if="scope.row.lesson_status == 5"
                        size="mini"
                        type="info">已完结</el-tag>
                <el-tag v-if="scope.row.lesson_status == 6"
                        size="mini"
                        type="info">已暂停</el-tag>
              </template>
            </el-table-column>

            <el-table-column prop="name"
                             label="操作"
                             width="80"
                             fixed="right">
              <template slot-scope="scope">
                <div class="templateLink">
                  <el-link type="primary"
                           :underline="false"
                           @click="handleToChoose(scope.row)">选择</el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="page-footer">
            <el-pagination background
                           layout="total, prev, pager, next, sizes, jumper"
                           :current-page="Number(query.pageIndex)"
                           :page-sizes="[10, 20, 50, 100, 500]"
                           :page-size="Number(query.pageSize)"
                           :total="Number(pageTotal)"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"></el-pagination>
          </div>
        </div>
      </div>

      <div v-else>
        <AddSeniorStudent @submit="costUp"
                          :total_other_amount="dataForm.total_other_amount"
                          :cid="content[0].company_id"
                          :contractDatail="contractDatail"
                          :content="result"
                          :result="studentData"
                          :contractForm="contractForm" />
      </div>

      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button type="primary"
                   @click="stepUp()"
                   v-if="step == 1">下一步</el-button>
        <el-button type="primary"
                   @click="step = 1"
                   v-if="step == 2">上一步</el-button>
        <el-button type="primary"
                   @click="submit('formRules')"
                   v-if="step == 2">确定</el-button>
        <!-- <el-button type="primary" @click="submit('formRules')">确定</el-button> -->
      </div>
    </el-dialog>
    <EditLogHourAmount :content="editContent"
                       :isVisible="editDialog"
                       @close="editDialog = false"
                       @submit="EditLogHourAmountSubmit" />
    <EditLogHourAmountList :list="editlist"
                           :isVisible="editlistDialog"
                           @close="editlistDialog = false"
                           @submit="EditLogHourAmountListSubmit" />

    <el-dialog append-to-body
               top="5vh"
               title="修改签约费"
               :visible.sync="dialogContract"
               @close="dialogContract = false"
               :close-on-click-modal="false"
               width="440px">
      <el-form :model="contractForm"
               label-position="right"
               ref="contractFormRules"
               :rules="rules"
               label-width="130px"
               size="small">
        <el-form-item label="当前机构签约费：">
          <span class="item-value">
            {{
              contractDatail.final_real_company_hour_amount
                ? contractDatail.final_real_company_hour_amount
                : contractDatail.ref_hour_income_company_amount
                ? contractDatail.ref_hour_income_company_amount
                : "0"
            }}元/小时
          </span>
        </el-form-item>
        <el-form-item label="机构签约费："
                      prop="final_real_company_hour_amount">
          <el-input placeholder="请输入机构签约费"
                    v-model="contractForm.final_real_company_hour_amount"
                    class="input-with-select">
            <span slot="append">元/小时</span>
          </el-input>
        </el-form-item>

        <el-form-item label="当前机构附加费：">
          <span class="item-value">
            {{
              contractDatail.final_real_company_service_amount
                ? contractDatail.final_real_company_service_amount
                : 0
            }}元
          </span>
        </el-form-item>
        <el-form-item label="机构附加费："
                      prop="final_real_company_service_amount">
          <el-input placeholder="请输入机构附加费："
                    v-model="contractForm.final_real_company_service_amount"
                    class="input-with-select">
            <span slot="append">元/小时</span>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="dialogContract = false">取消</el-button>
        <el-button type="primary"
                   @click="contractSubmit('contractFormRules')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  teacherOne,
  lesson,
  getOrderFeeDetail,
  orderFee,
  dispatchLessonLogUp,
  getOrderStudentDataView,
} from '@/request/api'
import { adminUrl } from '@/utils/helper.js'
import { digitUppercase } from '@/utils/helper'
import EditLogHourAmount from '@/components/DialogComponents/ClassService/EditLogHourAmount.vue'
import EditLogHourAmountList from '@/components/DialogComponents/ClassService/EditLogHourAmountList.vue'
import AddSeniorStudent from '@/components/DialogComponents/ClassService/AddSeniorStudent.vue'
export default {
  components: { EditLogHourAmount, EditLogHourAmountList, AddSeniorStudent },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inner_arr_exclude_id: [],
      total: 0,
      arr_student_level_data: [],
      arr_lesson_log: [],
      step: 1,
      studentData: [],
      company_name: '',
      dialogFormVisible: this.isVisible,
      dataForm: {},
      teacher_info: {},
      result: [],
      chooseList: [],
      totalAmount: 0,
      totalHour: 0,
      editDialog: false,
      editContent: {},
      editlist: [],
      editlistDialog: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      result_other: [],
      unitConversion: 1,
      firstDayOfMonth: null,
      nextMonthFirstDay: null,
      dialogContract: false,
      contractDatail: {},
      contractForm: {
        final_real_company_hour_amount: 0,
        final_real_company_service_amount: 0,
      },
      rules: {
        total_other_amount_name: [],
        total_other_amount: [
          {
            pattern: /^(?=.*\d)\d*(?:\.\d{1,2})?$/,
            message: '请输入正整数(限两位小数)',
            trigger: 'blur',
          },
        ],
        final_real_company_hour_amount: [
          { required: true, message: '请输入机构签约费', trigger: 'blur' },
          {
            pattern: /^(?=.*\d)\d*(?:\.\d{1,2})?$/,
            message: '请输入正整数(限两位小数)',
            trigger: 'blur',
          },
        ],

        final_real_company_service_amount: [
          { required: true, message: '请输入机构签约费', trigger: 'blur' },
          {
            pattern: /^(?=.*\d)\d*(?:\.\d{1,2})?$/,
            message: '请输入正整数(限两位小数)',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      console.log(val)
      if (val) {
        this.result = JSON.parse(JSON.stringify(this.content))
        this.fetList()
        this.fetTeacherDetail()
      }
    },
    result(val) {},
  },
  methods: {
    allCheck() {
      this.result_other.map((item) => {
        this.result.push(item)
      })
      // this.result_other.map((item) => {
      //   this.inner_arr_exclude_id.push(item.id)
      // })
      this.fetList()
      this.computedAmount()
      this.fetTeacherDetail()
    },
    upPrice() {
      this.result.forEach((item, index) => {
        // console.log("cc",item.id)
        if (item.id == this.contractDatail.id) {
          this.$set(
            this.result[index],
            'final_real_company_hour_amount',
            Number(this.contractForm.final_real_company_hour_amount)
          )
          this.$set(
            this.result[index],
            'final_real_company_service_amount',
            Number(this.contractForm.final_real_company_service_amount)
          )
        }
      })
      this.arr_lesson_log = []
      this.result.forEach((item) => {
        this.arr_lesson_log.push({
          log_id: item.id,
          final_real_lesson_hour: item.final_real_lesson_hour,
          final_real_company_hour_amount:
            item.final_real_company_hour_amount || 0,
          final_real_company_service_amount:
            item.final_real_company_service_amount || 0,
        })
      })

      this.arr_student_level_data = this.studentData.map((item) => {
        return {
          data_id: item.data_id,
          data_other_amount: item.total_final_student_level_other_amount,
        }
      })
      this.getDetail()
      // console.log("pp", this.dataForm.total_other_amount);
    },
    getDetail() {
      getOrderFeeDetail({
        cid: this.content[0].company_id,
        total_other_amount: this.dataForm.total_other_amount,
        arr_lesson_log: this.arr_lesson_log,
        arr_student_level_data: this.arr_student_level_data,
      }).then((res) => {
        console.log('res', res)
        this.totalAmount = res.data.data.company_order_fee_obj.total_amount
      })
    },
    costUp(total_amount) {
      this.totalAmount = total_amount
    },
    stepUp() {
      this.step = 2
      this.getStudent()
    },

    // 获取学生列表
    getStudent() {
      let ids = this.result.map((item) => item.id)
      getOrderStudentDataView({
        arr_direct_lesson_log_id: ids,
      }).then((res) => {
        this.studentData = res.data.data.arr_student_data_view
        // console.log('res:', res)
      })
    },

    contractSubmit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.result.forEach((item, index) => {
            // console.log("cc",item.id)
            if (item.id == this.contractDatail.id) {
              this.$set(
                this.result[index],
                'final_real_company_hour_amount',
                Number(this.contractForm.final_real_company_hour_amount)
              )
              this.$set(
                this.result[index],
                'final_real_company_service_amount',
                Number(this.contractForm.final_real_company_service_amount)
              )
            }
          })

          this.fetTeacherDetail()
          // setTimeout(() => {
          //   this.getDetail()
          // }, 500)

          this.dialogContract = false
          console.log('submit!!')
        } else {
          console.log('error submit!!')
        }
      })
    },
    emitClose() {
      this.result_other = []
      this.inner_arr_exclude_id = []
      this.result = []
      this.dataForm = {}
      this.$emit('close')
      this.arr_lesson_log = []
    },
    fetTeacherDetail() {
      this.arr_lesson_log = []
      this.company_name = this.content[0].company_name
      this.result.forEach((item) => {
        this.arr_lesson_log.push({
          log_id: item.id,
          final_real_lesson_hour: item.final_real_lesson_hour,
          final_real_company_hour_amount:
            item.final_real_company_hour_amount || 0,
          final_real_company_service_amount:
            item.final_real_company_service_amount || 0,
        })
      })

      let arr_student_level_data = this.studentData.map((item) => {
        return {
          data_id: item.data_id,
          data_other_amount: item.total_final_student_level_other_amount,
        }
      })
      getOrderFeeDetail({
        total_other_amount: this.dataForm.total_other_amount,
        cid: this.content[0].company_id,
        arr_lesson_log: this.arr_lesson_log,
        arr_student_level_data,
      }).then((response) => {
        let res = response.data
        this.teacher_info = res.data
        let mouth = this.$moment(
          this.getObjectWithMinValue(this.result).create_time
        ).month()
        // console.log(mouth, "ooo", this.getObjectWithMinValue(this.result));
        this.$set(
          this.dataForm,
          'order_name',
          `${this.company_name}机构的${mouth + 1}月派遣账单`
        )
        //  console.log("res.data1111",res.data)
        if (res.data != undefined) {
          this.totalAmount = res.data.company_order_fee_obj.total_amount
          this.totalHour = res.data.company_order_fee_obj.service_hours
        }

        // this.computedAmount();
      })

      // getOrderFeeDetail({
      //   cid: this.content[0].company_id,
      //   arr_lesson_log: arr_lesson_log,
      // }).then((response) => {
      //   console.log("response", response);
      //   let res = response.data;
      //   this.teacher_info = res.data;

      // });
    },
    getObjectWithMinValue(objects, key) {
      objects.sort(function (a, b) {
        return a.create_time - b.create_time
      })
      return objects[0]
    },
    fetList() {
      this.inner_arr_exclude_id = []
      this.result.map((item) => {
        this.inner_arr_exclude_id.push(item.id)
      })
      let company_id = this.content[0].company_id
      const params = Object.assign({}, this.query, {
        // teacher_id: this.result[0].teacher_id,
        lesson_statuses: '',
        company_ids: company_id,
        // grade_id: this.result[0].grade_id,
        company_lesson_statuses: 1,
        is_statistic_lesson_company: 1,
        force_delete_types: 2,
        inner_arr_exclude_id: this.inner_arr_exclude_id,
      })
      lesson(params).then((response) => {
        let res = response.data
        this.result_other = res.data.list
        this.pageTotal = res.data.count
      })
    },
    // filterList(list) {
    //   let arr = list.filter((v) => {
    //     return (
    //       this.result.findIndex((t) => {
    //         return t.id == v.id
    //       }) == -1
    //     )
    //   })
    //   this.result_other = arr
    // },
    submit(rules) {
      const maxValue = this.result.reduce((max, obj) => {
        return obj.create_time > max ? obj.create_time : max
      }, 0)

      const minValue = this.result.reduce((min, obj) => {
        return obj.create_time < min ? obj.create_time : min
      }, Infinity)

      // console.log("最大值：", maxValue);
      // console.log("最小值：", minValue);
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let arr_student_level_data = this.studentData.map((item) => {
            return {
              data_id: item.data_id,
              data_other_amount: item.total_final_student_level_other_amount,
            }
          })
          let params = {
            order_name: this.dataForm.order_name,
            total_amount: this.totalAmount,
            // total_amount: this.teacher_info.company_order_fee_obj.total_amount,
            cid: this.content[0].company_id,
            start_time: minValue,
            end_time: maxValue,
            service_hours:
              this.teacher_info.company_order_fee_obj.service_hours,
            arr_lesson_log: this.result.map((v) => {
              return {
                log_id: v.id,
                final_real_lesson_hour: v.final_real_lesson_hour,
                final_real_company_hour_amount:
                  v.final_real_company_hour_amount || 0,
                final_real_company_service_amount:
                  v.final_real_company_service_amount || 0,
              }
            }),
            total_other_amount_name: this.dataForm.total_other_amount_name,
            total_other_amount: this.dataForm.total_other_amount || 0,
            arr_student_level_data,
          }
          orderFee(params).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('创建成功')
              this.$emit('submit')
              this.emitClose()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    computedAmount() {
      let a = this.result.reduce((acc, cur) => {
        return (
          acc +
          Number(cur.final_real_lesson_hour_amount * cur.final_real_lesson_hour)
        )
      }, 0)
      let b = this.result.reduce((acc, cur) => {
        return acc + Number(cur.final_real_lesson_hour)
      }, 0)
      this.totalAmount = a.toFixed(2)
      this.totalHour = b.toFixed(2)
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    formateTotalAmount(e) {
      return digitUppercase(e)
    },
    handleToEdit(row) {
      console.log('7777')
      if (row.final_real_company_hour_amount) {
        this.contractForm.final_real_company_hour_amount =
          row.final_real_company_hour_amount
      } else if (row.ref_hour_income_company_amount) {
        this.contractForm.final_real_company_hour_amount =
          row.ref_hour_income_company_amount
      } else {
        this.contractForm.final_real_company_hour_amount = 0
      }
      this.contractForm.final_real_company_service_amount =
        row.final_real_company_service_amount || 0

      this.contractDatail = row
      this.dialogContract = true

      // this.editContent = Object.assign({}, row);
      // this.editDialog = true;
    },
    EditLogHourAmountSubmit(data) {
      let ind = this.result.findIndex((v) => {
        return v.id == data.id
      })
      this.$set(
        this.result[ind],
        'final_real_lesson_hour_amount',
        data.final_real_lesson_hour_amount
      )
      this.fetTeacherDetail()
    },
    EditLogHourAmountListSubmit(data) {
      data.ids.forEach((item, index) => {
        this.$set(
          this.result.find((v) => {
            return v.id == item
          }),
          'final_real_lesson_hour_amount',
          data.final_real_lesson_hour_amount
        )
      })
      this.fetTeacherDetail()
    },
    handleToEditList() {
      this.editlist = this.chooseList
      this.editlistDialog = true
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetList()
    },
    handleToChoose(row) {
      let obj = Object.assign({}, row)
      this.result.push(obj)
      this.fetList()
      // this.filterList(this.result_other)
      this.computedAmount()

      this.fetTeacherDetail()
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    },
  },
}
</script>

<style lang="less" scoped>
.info {
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #303133;
}
.form-item {
  display: flex;
  .el-input {
    width: 300px;
  }
  .amount-item {
    display: flex;
    align-items: center;
    margin-left: 16px;
    .item-label {
      font-size: 14px;
      font-weight: 400;
      color: #909399;
    }
    .item-value {
      font-size: 14px;
      margin-left: 16px;
      font-weight: 600;
      color: #e6a23c;
    }
  }
}
.table-modular {
  margin-top: 24px;
  .tableHeader {
    display: flex;
    justify-content: space-between;
    .header {
      font-size: 14px;
      font-weight: 400;
      color: #303133;
      margin-bottom: 16px;
    }
    .allCheck {
      cursor: pointer;
      color: #409eff;
    }
  }
}
.btn-wrap {
  margin-top: 16px;
}
.line {
  border: dashed 1px #dcdfe6;
  margin: 32px 0px;
}
.page-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0px;
}
</style>