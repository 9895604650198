<template>
  <div class="content">
    <el-dialog
      top="5vh"
      title="变更"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="600px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="auto"
        label-position="left"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item label="机构简称：" prop="cid">
          <el-select
            filterable
            v-model="form.cid"
            placeholder="请选择"
            style="width: 410px"
            clearable
            @change="cidChange"
          >
            <el-option
              v-for="item in $store.state.CompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学生姓名：" prop="student_name">
          <el-input
            v-model="form.student_name"
            placeholder="请输入学生姓名"
            clearable
            style="width: 410px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="apply_year" label="考研年份：">
          <el-select
            v-model="form.apply_year"
            placeholder="请选择考研年份"
            clearable
            style="width: 410px"
          >
            <el-option label="2024考研" value="2024"></el-option>
            <el-option label="2025考研" value="2025"></el-option>
            <el-option label="2026考研" value="2026"></el-option>
            <el-option label="2027考研" value="2027"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考院校：" prop="apply_college_name">
          <el-select
            v-model="form.apply_college_code"
            filterable
            placeholder="请选择"
            style="width: 410px"
            clearable
            @change="collegeChoose"
          >
            <el-option
              v-for="item in $store.state.collegeList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考专业：" prop="major_code">
          <div class="sign">
            <el-select
              filterable
              placeholder="请选择"
              v-model="form.major_code"
              @change="majorChoose"
              clearable
              class="select"
            >
              <el-option
                v-for="(item, index) in majorOptions"
                :key="index"
                :label="item.allnames"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <div class="sign-icon">
              {{
                majorType == 1 ? "学硕型" : majorType == 2 ? "专硕型" : "暂无"
              }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="报考学院：" prop="apply_academy_name">
          <el-select
            filterable
            placeholder="请选择"
            v-model="form.apply_academy_name"
            @change="academyChoose"
            clearable
            style="width: 410px"
          >
            <el-option
              v-for="(item, index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="item.academy_code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试方向：" prop="apply_direction">
          <el-select
            v-model="form.apply_direction"
            placeholder="请选择考试方向"
            clearable
            style="width: 410px"
          >
            <el-option
              v-for="(item, index) in directionsOptions"
              :key="index"
              :label="
                item.direction == '不限'
                  ? item.direction
                  : `（${item.direction_code}）${item.direction}`
              "
              :value="{ value: item.direction_code, label: item.direction }"
            >
              <span>{{
                item.direction == "不限"
                  ? item.direction
                  : `（${item.direction_code}）${item.direction}`
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="辅导科目：" prop="subjectsarr">
          <el-select
            :multiple-limit="4"
            @change="$forceUpdate()"
            v-model="form.subjectsarr"
            multiple
            placeholder="请选择"
            clearable
            style="width: 410px"
          >
            <el-option
              v-for="(item, index) in subjectsOptions"
              :key="index"
              :label="item.subject_name"
              :value="item.subject_code"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报名课时：" prop="hours">
          <el-input
            v-model="form.hours"
            placeholder="请输入课时"
            clearable
            style="width: 410px"
            ><template slot="append">小时</template></el-input
          >
        </el-form-item>
        <el-form-item label="匹配备注：" prop="remark">
          <el-input
            style="width: 410px"
            type="textarea"
            v-model="form.remark"
            placeholder="请输入备注"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <div class="line"></div>
        <el-form-item
          label="匹配成功通知人："
          prop="manage_watcher"
        >
          <el-select
            @change="$forceUpdate()"
            v-model="form.watcher"
            placeholder="请选择"
            clearable
            style="width: 410px"
            multiple
          >
            <el-option
              v-for="(item,index) in staffOptions"
              :key="index"
              :label="item.alias"
              :value="item.id"
            >
              <span> {{ `${item.alias}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary" @click="submit('formRules')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  majorlist,
  subjectlist,
  academylist,
  companyuserlist,
} from "../../../request/api";
import { adminUrl } from "@/utils/helper";

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      majorType: 0,
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      form: this.content,
      labelOptions: [],
      sourceOptions: [],
      staffOptions: [],
      // 院校选择项
      collegeOptions: [],
      academyOptions: [],
      majorOptions: [],
      subjectsOptions: [],
      productsOptions: [],
      rules: {
        apply_college_name: [
          { required: true, message: "请选择意向院校", trigger: "blur" },
        ],
        apply_academy_name: [
          { required: true, message: "请选择意向学院", trigger: "blur" },
        ],
        major_code: [
          { required: true, message: "请选择意向专业", trigger: "blur" },
        ],
        watcher: [{ required: true, message: "请选择负责人", trigger: "blur" }],
        subjects: [
          { required: true, message: "请选择辅导科目", trigger: "blur" },
        ],
        order_amount: [
          { required: true, message: "请输入辅导课时" },
          { type: "number", message: "辅导课时必须为数字值" },
        ],
        hours: [
          { required: true, message: "请输入课时" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
      },
      directionsOptions: [],
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail();
      }
    },
  },
  methods: {
    fetchDetail() {
      this.form = this.content;
      console.log("this.form:", this.form);

      this.getStaffByCid(this.form.cid);
      if (this.form.apply_college_name && this.form.apply_college_code) {
        this.getMajorOptions();
      }
      if (this.form.apply_major_name && this.form.apply_major_code) {
        this.$set(
          this.form,
          "major_code",
          `（${this.form.apply_major_code}）${this.form.apply_major_name}`
        );
        this.getMajorInfo(
          this.form.apply_college_code,
          this.form.apply_major_code,
          true
        );
      }
      if (this.form.apply_academy_name && this.form.apply_academy_code) {
        this.getSubjectOptions(
          this.form.apply_college_code,
          this.form.apply_academy_code,
          this.form.apply_major_code
        );
      }
      if (this.form.apply_direction_name) {
        this.$set(this.form, "apply_direction", {
          label: this.form.apply_direction_name,
          value: this.form.apply_direction_code,
        });
      }
      // console.log('ooo',this.form.apply_direction)
      if (this.form.subjects) {
        this.$set(
          this.form,
          "subjectsarr",
          this.form.subjects.map((v) => {
            return v;
          })
        );
      }
    },
    emitClose() {
      this.form = {};
      this.$emit("close");
    },
    // 因为传科目时要上传对象所以在这里做一些处理
    formateSubjects() {
      let arr = [];
      this.form.subjectsarr.forEach((item) => {
        let obj = {};
        obj = this.subjectsOptions.find((v) => {
          return v.subject_code == item; //筛选出匹配数据
        });
        if (obj) {
          arr.push(obj);
        }
      });
      this.form.subjects = arr;
    },
    submit(rules) {
      // console.log(this.form,'pppp')
      // return
      this.formateSubjects();
      if (this.form.watcher) {
        this.form.watcher = this.form.watcher.join(",");
      }
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.$emit("submit", {
            form: Object.assign({}, this.form, {
              apply_direction_code: this.form.apply_direction
                ? this.form.apply_direction.value
                : "",
              apply_direction_name: this.form.apply_direction
                ? this.form.apply_direction.label
                : "",
            }),
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getSubjectOptions(college_code, academy_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        academy_code: academy_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程",
        };
        this.subjectsOptions = res.data.data.list || [];
        this.subjectsOptions.push(obj);
      });
    },
    // 选择院校options搜索出name（后台数据需要name和code两个参数）
    collegeChoose(e) {
      this.form.apply_major_name = "";
      this.form.apply_major_code = "";
      this.majorOptions = [];
      this.form.apply_academy_name = "";
      this.form.apply_academy_code = "";
      this.academyOptions = [];

      this.form.apply_direction = {};
      this.directionsOptions = [];
      this.form.subjectsarr = "";
      this.subjectsOptions = [];

      if (!e) return;
      let obj = {};
      obj = this.$store.state.collegeList.find((item) => {
        return item.code === e; //筛选出匹配数据
      });
      this.form.apply_college_code = obj.code;
      this.form.apply_college_name = obj.name;
      this.getMajorOptions();
    },
    getMajorOptions() {
      let query = {
        isPaging: 0,
        college_code: this.form.apply_college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data;
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          };
        });
         let typeObj = this.majorOptions.find(item=>item.code == this.form.apply_major_code)
      	this.majorType =typeObj? typeObj.type :0
      });
    },
    cidChange(e) {
      this.form.watcher = "";
      this.getStaffByCid(e);
    },
    getStaffByCid(cid) {
      let query = {
        isPaging: 0,
        cid: cid,
      };
      companyuserlist(query).then((res) => {
        this.staffOptions = res.data.data.list || [];
      });
    },
    onInput(e) {
      // 通过正则过滤小数点后两位
      this.form.hours = this.form.hours.match(/^\d*(\.?\d{0,2})/g)[0];
      this.$message({
        message: "课时最多只能输入两位小数",
        type: "warning",
      });
    },
    // 选择学院，获取专业代码与专业名称
    majorChoose(e) {
      this.form.apply_academy_name = "";
      this.form.apply_academy_code = "";
      this.academyOptions = [];
      this.form.apply_direction = {};
      this.directionsOptions = [];
      this.form.subjectsarr = "";
      this.subjectsOptions = [];
      if (!e) return;
      let obj = {};
      obj = this.majorOptions.find((item) => {
        return item.code === e; //筛选出匹配数据
      });
      this.majorType = obj.type;
      this.form.apply_major_code = obj.code;
      this.form.apply_major_name = obj.name;
      this.getMajorInfo(
        this.form.apply_college_code,
        this.form.apply_major_code
      );
    },
    getMajorInfo(college_code, major_code, type) {
      let str = adminUrl();
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: "get", //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data;
        this.academyOptions = res.data.academys;

        if (this.academyOptions.length == 1) {
          this.form.apply_academy_code = this.academyOptions[0].academy_code;
          this.form.apply_academy_name = this.academyOptions[0].academy_name;
          this.academyList(this.form.apply_academy_code, type);
        }

        this.directionsOptions =
          this.academyOptions.find((v) => {
            return this.form.apply_academy_code == v.academy_code;
          })?.directions || [];

        if (
          !this.directionsOptions.length ||
          this.directionsOptions[0].direction != "不限"
        ) {
          this.directionsOptions.unshift({
            direction: "不限",
            direction_code: "",
          });
        }
      });
    },
    // 获取学院代码与学院名称
    academyChoose(e) {
      this.form.apply_direction = {};
      this.directionsOptions = [];
      this.form.subjectsarr = "";
      this.subjectsOptions = [];
      this.academyList(e);
    },
    academyList(e, type) {
      if (!e) return;
      let obj = this.academyOptions.find((item) => {
        return item.academy_code === e; //筛选出匹配数据
      });
      this.form.apply_academy_code = obj.academy_code;
      this.form.apply_academy_name = obj.academy_name;
      this.directionsOptions = obj.directions || [];
      if (
        !this.directionsOptions.length ||
        this.directionsOptions[0].direction != "不限"
      ) {
        this.directionsOptions.unshift({
          direction: "不限",
          direction_code: "",
        });
      }
      if (!type) {
        this.$set(this.form, "apply_direction", {
          label: this.directionsOptions[0].direction,
          value: this.directionsOptions[0].direction_code,
        });
      }

      this.getSubjectOptions(
        this.form.apply_college_code,
        this.form.apply_academy_code,
        this.form.apply_major_code
      );
    },
  },
};
</script>

<style lang="less" scoped>
.sign {
  display: flex;
  .select {
    flex: 1;
    /deep/.el-input__inner {
      border-radius: 4px 0 0 4px !important;
    }
  }
  .sign-icon {
    box-sizing: border-box;
    width: 70px;
    height: 32px;
    line-height: 32px;
    background-color: #f5f7fa;
    color: #909399;
    border: 1px solid #dcdfe6;
    border-left: none;
    text-align: center;
    border-radius: 0px 4px 4px 0px;
  }
}

.input-with-select {
  margin-bottom: 16px;
}
.line {
  border: dashed #dcdfe6 1px;
  margin: 36px auto;
}
</style>