<template>
  <div class="dialog-wrap">
    <el-dialog
      top="5vh"
      title="加入班级"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="1500px"
    >
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="mini"
      >
        <el-form-item label="班级名称">
          <el-input
            v-model.trim="query.name_keyword"
            placeholder="请输入班级名称"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="班级类型">
          <el-select
            v-model="query.types"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.types"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级状态">
          <el-select
            v-model="query.statuses"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学员">
          <el-input
            v-model.trim="query.student_keyword"
            placeholder="姓名/手机号"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="老师">
          <el-input
            v-model.trim="query.teacher_keyword"
            placeholder="姓名/手机号"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="院校">
          <el-input
            v-model.trim="query.apply_college_keyword"
            placeholder="请输入院校"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="专业">
          <el-input
            v-model.trim="query.apply_major_keyword"
            placeholder="请输入专业"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="学管师">
          <el-select
            v-model="query.learn_managers"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in $store.state.staffOptions.filter(v=>{
                return v.depts.findIndex(t=>{
                  return t.id==4 && v.status == 1
                })!=-1
              })"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <el-table
        class="result"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ fontSize: '13px' }"
        border
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        > </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          show-overflow-tooltip
        >

        </el-table-column>
        <el-table-column
          prop="name"
          label="班级名称"
          width="200"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="left">
              {{scope.row.name}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          align="center"
          label="班级类型"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{formateType(scope.row.type)}}
          </template>
        </el-table-column>
        <el-table-column
          label="班级状态"
          align="center"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status==0"
              size="mini"
              type="warning"
            >正在对接</el-tag>
            <el-tag
              v-if="scope.row.status==1"
              size="mini"
              type="success"
            >正在上课</el-tag>
            <el-tag
              v-if="scope.row.status==2"
              size="mini"
              type="info"
            >课程终止</el-tag>
            <el-tag
              v-if="scope.row.status==3"
              size="mini"
              type="danger"
            >课程结束</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="学员列表"
          width="220"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{formateMember(scope.row.student_realnames)}}
          </template>
        </el-table-column>
        <el-table-column
          label="授课老师"
          width="160"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{formateMember(scope.row.teacher_realnames)}}
          </template>
        </el-table-column>
        <el-table-column
          label="课程进度"
          width="120"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="scope.row.course_progress?parseFloat((Number(scope.row.course_progress)*100).toFixed(2)):0"
            ></el-progress>
          </template>
        </el-table-column>
        <el-table-column
          label="报考信息"
          width="500"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="left">
              {{scope.row.apply_college_name}} | ({{scope.row.apply_major_code}}){{scope.row.apply_major_name}} | {{scope.row.apply_academy_name}}
              |
              <span
                v-for="(item,index) in scope.row.apply_subjects||[]"
                :key="index
            "
              >
                ({{item.subject_code}}){{item.subject_name}}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          align="center"
          show-overflow-tooltip
          label="辅导时长"
        >
          <template slot-scope="scope">
            {{scope.row.cocah_lesson_hour}}小时
          </template>
        </el-table-column>

        <el-table-column
          label="学管师"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            {{scope.row.learn_manager?scope.row.learn_manager.nickname:""}}
          </template>
        </el-table-column>
        <el-table-column
          label="创建日期"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="修改日期"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          small
          layout="total, prev, pager, next,sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submitBefore"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { gradelist, gradelistEdit, commonHandleLogCreate } from "@/request/api";

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
    single: {
      type: Boolean,
      default: false,
    },
    student_id: {
      type: Array,
      default: () => [],
    },
    service_hour: {
      type: Number,
      default: 0,
    },
    student_realname: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      btnLoading:false,
      dialogVisible: this.isVisible,
      query: {
        keyword: "",
        college: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 10,
      results: [],
      selections: [],
      countSelections: [],
      filters: {
        types: [
          { label: "一对一", value: 0 },
          { label: "小班课", value: 1 },
          { label: "大班课", value: 2 },
          { label: "试听课", value: 3 },
        ],
        status: [
          { label: "正在对接", value: 0 },
          { label: "正在上课", value: 1 },
          { label: "课程终止", value: 2 },
          { label: "课程结束", value: 3 },
        ]
      },
      create_time: "",
      setNum: 0,
      final_class: [],
    };
  },
  filters: {

  },
  watch: {
    isVisible(val) {
      this.dialogVisible = val;

      if (val) {
        this.query = {
          pageIndex: 1,
          pageSize: 10,
          isPaging: 1,
        }
        this.fetchList();
      }
    },
    content(val) {
      this.countSelections = val;
    },
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
        this.fetchList(true)
      }
    },
  },
  methods: {

    // 院校列表请求
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      let params = { ...this.query };
      if (params.types) {
        params.types = params.types.join(',')
      }
      if (params.statuses) {
        params.statuses = params.statuses.join(',')
      }
      if (params.learn_managers) {
        params.learn_managers = params.learn_managers.join(',')
      }
      gradelist(params).then((response) => {
        let res = response.data
        this.results = res.data.list || [];
        this.pageTotal = res.data.count;

        /*  this.$nextTick(this.setRowSelection); */
      });
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    // 设置选中行
    setRowSelection() {
      if (this.results.length) {
        this.results.forEach((row) => {
          if (this.content.find((item) => item.id === row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageIndex", 1);
      this.fetchList();
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields();
      this.fetchList();
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.fetchList();
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val;
      this.diffSelection();
    },
    submitBefore() {
      
      if (this.setNum + 1 > this.countSelections.length) {
        this.setNum = 0
        this.handleSubmit(this.final_class)
        this.final_class = []
        return
      } else {
         this.final_class.push(this.countSelections[this.setNum])
            this.setNum += 1
            this.submitBefore()
        // if (this.service_hour > this.countSelections[this.setNum].cocah_lesson_hour) {
        //   this.$confirm(`当前加入的班级课时为${this.countSelections[this.setNum].cocah_lesson_hour}小时，与该学员的总课时${this.service_hour}小时不匹配，是否确认加入？`, "提示", {
        //     confirmButtonText: "确定",
        //     cancelButtonText: "取消",
        //     type: "warning",
        //   }).then(() => {
        //     this.final_class.push(this.countSelections[this.setNum])
        //     this.setNum += 1
        //     this.submitBefore()
        //   }).catch(() => {
        //     this.setNum += 1
        //     this.submitBefore()
        //   })
        // } else if (this.service_hour < this.countSelections[this.setNum].cocah_lesson_hour) {
        //   this.$confirm(`当前加入的班级课时为${this.countSelections[this.setNum].cocah_lesson_hour}小时，大于该学员的总课时${this.countSelections[this.setNum].cocah_lesson_hour}小时，是否确认加入？`, "提示", {
        //     confirmButtonText: "确定",
        //     cancelButtonText: "取消",
        //     type: "warning",
        //   }).then(() => {
        //     this.final_class.push(this.countSelections[this.setNum])
        //     this.setNum += 1
        //     this.submitBefore()
        //   }).catch(() => {
        //     this.setNum += 1
        //     this.submitBefore()
        //   })
        // } else {
        //   this.final_class.push(this.countSelections[this.setNum])
        //   this.setNum += 1
        //   this.submitBefore()
        // }
      }
    },
    handleSubmit(arr) {
      if (arr.length) {
        let query = {
          rows: arr.map(v => {
            return {
              id: v.id,
              arr_student: v.student_ids ? Array.from(new Set(v.student_ids.split(',').concat(this.student_id))).map(t => {
                return {
                  student_id: t + "",
                  student_grade_status: 1
                }
              }) : this.student_id.map(t => {
                return {
                  student_id: t + "",
                  student_grade_status: 1

                }
              })
            }
          })
        }
        gradelistEdit(query).then(async (res) => {
          if (res.data.code == 0) {
            for (let i = 0; i < arr.length; i++) {
              let pro = await this.createLog(arr[i].id, i, arr.length)
            }
            /*   this.$message.success('分配成功')
              this.handleClose();
              this.$emit('submit') */
          }
        });
      }

    },
    handleClose() {
      // 检索条件重置
      this.query = {
        keyword: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      };
      this.$emit("close");
    },
    // 选中项差异化计算
    diffSelection() {
      let diffs = [];
      // 减
      this.countSelections.forEach((item, index) => {
        if (!this.selections.find((v) => v.id === item.id)) {
          if (this.results.find((v2) => v2.id === item.id)) {
            diffs.push(item);
          }
        }
      });
      if (diffs.length) {
        this.countSelections = this.countSelections.filter(
          (item) => !diffs.find((v) => v.id === item.id)
        );
      }
      // 加
      this.selections.forEach((item) => {
        if (!this.countSelections.find((v) => v.id === item.id)) {
          this.countSelections.push(item);
        }
      });
    },
    handleToChoose(row) {
      let data = [row]
      this.$emit("submit", { data: data });
      this.handleClose();
    },
    formateType(type) {
      let types = {
        '0': '一对一',
        '1': '小班课',
        '2': '大班课',
        '3': '试听课',
      }
      return types[type]
    },
    formateMember(val) {
      if (val) {
        let val_arr = val.split(',')
        if (val_arr.length > 3) {
          return val.split(',').slice(0, 3).join('，') + '等'
        } else {
          return val.split(',').slice(0, 3).join('，')
        }
      } else {
        return '无'
      }
    },
    createLog(id, index, length) {
      let query = {
        ref_id: id,
        handle_type: 0,
        handle_child_type: 2,
        handle_content: `将${this.student_realname}学员加入班级`,
      }
      return new Promise((resolve, reject) => {
        commonHandleLogCreate(query).then((res) => {
          if (res.data.code == 0) {
            resolve(0)
            if (index + 1 == length) {
              this.handleClose()
              this.$emit('submit')
            }
          } else {
            reject(1)
          }
        });
      })

    },
  },
};
</script>

<style lang="less" scoped>
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    margin-top: 10px;
  }
  .now {
    margin-left: 16px;
    color: red;
  }
}
</style>
