<template>
  <div>
    <el-dialog
      top="5vh"
      title="新建参考书目"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="700px"
    >
      <el-form
        :model="dataForm"
        label-position="left"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="名称："
          prop="label"
        >
          <el-input
            v-model="dataForm.label"
            placeholder="请输入名称"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="补充内容："
          prop="value"
        >
          <el-input
            v-model="dataForm.value"
            placeholder="请输入名称"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
    <script>

export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        label: [{ required: true, message: "请输入名称", trigger: "blur" }],
        value: [{ required: true, message: "请输入补充内容", trigger: "blur" }],

      },
    };

  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    handleToClose() {
      this.$emit("close");
      this.dataForm = {}
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.$emit('submit', {
            data: {
              label: this.dataForm.label,
              value: this.dataForm.value
            }
          })
          this.handleToClose()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
        </script>
    <style lang="less" scoped>
.content {
}
</style>