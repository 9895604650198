<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>共享</el-breadcrumb-item>
        <el-breadcrumb-item>留言</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button type="primary"
                   size="small"
                   @click="openDialogFormAdd('add')"
                   :disabled="!$buttonPermission('employeemanagement:create')">新建</el-button>
      </div>
    </div>
    <el-card class="operation-card">
      <el-form :inline="true"
               class="demo-form-inline">
        <el-form-item label="搜索">
          <el-input v-model="query.title_keyword"
                    size="small"
                    placeholder="标题"
                    @input="inputSearch"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="类别"
                      prop="type">
          <el-select @change="inputSearch"
                     v-model="query.force_type"
                     placeholder="请选择类别"
                     style="width: 100%">
            <el-option v-for="(item, index) in typeList"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="table-card"
             style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form size="small"
                 :inline="true">
          <el-form-item label-width="32px">
            <el-radio-group v-model="order_status"
                            @change="fetchList(true)">
              <el-radio-button :label="1">已上架</el-radio-button>
              <el-radio-button :label="0">已下架</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                border
                @selection-change="handleSelectionChange">
        >
        <el-table-column type="selection"
                         width="55"> </el-table-column>
        <el-table-column type="index"
                         label="序号"
                         width="60">
        </el-table-column>
        <el-table-column prop="title"
                         align="left"
                         label="标题"
                         width="500"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="left">
              {{ scope.row.title }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="create_time" label="类别">
          <template slot-scope="scope">
            {{ scope.row.type==1?'我要接单': scope.row.type==2?'我要发单':'错误类型'}}
          </template>
        </el-table-column> -->
        <el-table-column prop="create_time"
                         label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 1 ? '' : 'info'">{{
              scope.row.status == 1 ? "上架" : "下架"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sort"
                         label="排序值"></el-table-column>
        <el-table-column prop="create_time"
                         label="创建日期">
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time"
                         label="更新日期">
          <template slot-scope="scope">
            {{ scope.row.update_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         width="150">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToEdit(scope.row)"
                     :disabled="!$buttonPermission('employeemanagement:edit')">编辑</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link type="danger
              "
                     :underline="false"
                     @click="deleteItem(scope.row)"
                     :disabled="!$buttonPermission('employeemanagement:delete')">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button size="small"
                   type="danger"
                   @click="chooseAllDelete"
                   :disabled="delType">批量删除</el-button>
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>

    <DynamicCreate :isVisible="createDialog"
                   @close="createDialog = false"
                   @submit="fetchList" />
    <DynamicEdit :id="editId"
                 :isVisible="editDialog"
                 @close="editDialog = false"
                 @submit="fetchList" />
  </div>
</template>
  
<script>
import { crmUrl } from '@/utils/helper.js'
import DynamicCreate from '@/components/leaveWordComponents/DynamicCreate.vue'
import DynamicEdit from '@/components/leaveWordComponents/DynamicEdit.vue'

import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import ScreenHelp from '../../../components/ScreenComponents/ScreenHelp/ScreenHelp.vue'

export default {
  components: {
    TableTittle,
    DynamicCreate,
    DynamicEdit,
    ScreenHelp,
  },
  data() {
    return {
      order_status: 1,
      delType: true,
      editDialog: false,
      editId: null,
      createDialog: false,
      formInline: {},
      dialogForm: { status: '1' },
      tableData: [],
      ScreenStaffShow: false,
      TableName: '记录列表',
      submitmethods: '',
      deleteList: [],
      typeList: [
        {
          label: '全部',
          value: '3,4',
        },
        {
          label: '发单人留言',
          value: 3,
        },
        {
          label: '接单人留言',
          value: 4,
        },
      ],
      query: {
        pageIndex: 1,
        pageSize: 10,
        force_type: '3,4',
        title_keyword: '',
      },
      pageTotal: 0,
      fileList: [],
    }
  },

  mounted() {
    this.getList(this.query)
  },
  methods: {
    handleToEdit(row) {
      this.editDialog = true
      this.editId = row.id
    },
    fetchList() {
      this.$set(this.query, 'pageIndex', 1)
      this.getList(this.query)
    },
    // 表格选中取值
    handleSelectionChange(val) {
      if (val[0]) {
        this.delType = false
      } else {
        this.delType = true
      }
      // console.log("var",val)
      this.deleteList = val
    },
    getList(query) {
      let str = crmUrl()
      query.statuses = this.order_status
      this.$axios({
        url: `${str}/api/v1/sharePlatform/helpCenterArticle/list`,
        method: 'get', //可以省略 不写默认是get
        params: query,
      }).then((res) => {
        this.tableData = res.data.data.list
        this.pageTotal = res.data.data.count
      })
    },
    openDialogFormAdd(submitmethods) {
      this.createDialog = true
    },

    // 筛选组件传值
    screenTable({ screenForm }) {
      // console.log('screenForm', screenForm)
      this.$set(this.query, 'pageIndex', 1)
      this.query = {
        ...this.query,
        ...screenForm,
      }
      this.getList(this.query)
    },
    // 搜索框搜索
    inputSearch(e) {
      this.$set(this.query, 'pageIndex', 1)
      this.getList(this.query)
    },
    deleteItem(item) {
      this.$confirm('确定要删除此条信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            id: item.id,
          }
          let str = crmUrl()
          this.$axios({
            url: `${str}/api/v1/sharePlatform/helpCenterArticle`,
            method: 'delete', //可以省略 不写默认是get
            data: query,
          }).then((res) => {
            this.$notify.success({
              title: '提示',
              message: '删除成功',
            })
            this.$nextTick(() => {
              this.getList(this.query)
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消删除',
          })
        })
    },
    // 选中数据后做处理删除
    chooseAllDelete() {
      this.$confirm('确定要删除选中的数据吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            ids: [],
          }
          this.deleteList.forEach((item) => {
            query.ids.push(item.id)
          })
          let str = crmUrl()
          this.$axios({
            url: `${str}/api/v1/sharePlatform/helpCenterArticle/list`,
            method: 'delete', //可以省略 不写默认是get
            data: query,
          }).then((res) => {
            this.$notify.success({
              title: '提示',
              message: '删除成功',
            })
            this.$nextTick(() => {
              this.getList(this.query)
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消删除',
          })
        })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getList(this.query)
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getList(this.query)
    },
  },
}
</script>
  
<style lang="less" scoped>
.left {
  text-align: left;
}
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}

.operation-card {
  width: 100%;
  margin-top: 20px;

  .demo-form-inline {
    height: 64px;
    border-bottom: 2px dashed #d2d5df;
  }
}

.operation-menu {
  display: flex;
  justify-content: space-between;

  .menu_left {
    margin-top: 25px;
  }

  .menu_right {
    margin-top: 25px;
  }
}

.block {
  margin-bottom: 14px;
  float: right;
}
</style>