<template>
  <div class="content">
    <el-dialog
      top="5vh"
      title="新建"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="570px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="auto"
        label-position="left"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item label="机构简称：" prop="cid">
          <el-select
            filterable
            @change="companyChange"
            v-model="form.cid"
            placeholder="请选择"
            style="width: 429px"
          >
            <el-option
              v-for="(item, index) in $store.state.CompanyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考研年份：" prop="apply_year">
          <el-select
            v-model="form.apply_year"
            placeholder="请选择"
            style="width: 320px"
          >
            <el-option label="2024考研" value="2024"></el-option>
            <el-option label="2025考研" value="2025"></el-option>
            <el-option label="2026考研" value="2026"></el-option>
            <el-option label="2027考研" value="2027"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学生姓名：" prop="student_realname">
          <el-input
            v-model="form.student_realname"
            autocomplete="off"
            clearable
            placeholder="请输入姓名昵称"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input
            placeholder="请输入内容"
            v-model="form.student_phone"
            class="input-with-select"
          >
            <template slot="prepend">手机号</template>
          </el-input>
          <el-input
            placeholder="请输入内容"
            v-model="form.student_wechat"
            class="input-with-select"
          >
            <template slot="prepend">微信号</template>
          </el-input>
          <el-input
            placeholder="请输入内容"
            v-model="form.student_qq"
            class="input-with-select"
          >
            <template slot="prepend">&nbsp;QQ号</template>
          </el-input>
        </el-form-item>
        <!--        <el-form-item label="报名课程：">
          <el-input
            placeholder="请输入内容"
            v-model="form.productsarr"
            style="width: 429px"
          >
          </el-input>
        </el-form-item> -->

        <el-form-item label="报考院校：" prop="apply_college_name">
          <el-select
            v-model="form.apply_college_name"
            filterable
            collapse-tags
            placeholder="请选择"
            clearable
            @change="collegeChoose"
            style="width: 429px"
          >
            <el-option
              v-for="(item, index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考专业：" prop="apply_major_code">
          <div class="sign">
            <el-select
              filterable
              placeholder="请选择"
              v-model="form.apply_major_code"
              @change="majorChoose"
              clearable
              class="select"
            >
              <el-option
                v-for="(item, index) in majorOptions"
                :key="index"
                :label="`（${item.code}）${item.allnames}`"
                :value="item.code"
              >
                <span>{{ `（${item.code}）${item.allnames}` }}</span>
              </el-option>
            </el-select>
            <div class="sign-icon">
              {{
                majorType == 1 ? "学硕型" : majorType == 2 ? "专硕型" : "暂无"
              }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="报考学院：" prop="apply_academy_name">
          <el-select
            filterable
            placeholder="请选择"
            v-model="form.apply_academy_name"
            @change="academyChoose"
            clearable
            style="width: 429px"
          >
            <el-option
              v-for="(item, index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="item.academy_code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试方向：" prop="apply_direction">
          <el-select
            v-model="form.apply_direction"
            placeholder="请选择考试方向"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in directionsOptions"
              :key="index"
              :label="
                item.direction == '不限'
                  ? item.direction
                  : `（${item.direction_code}）${item.direction}`
              "
              :value="{ value: item.direction_code, label: item.direction }"
            >
              <span>{{
                item.direction == "不限"
                  ? item.direction
                  : `（${item.direction_code}）${item.direction}`
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="辅导科目：" prop="subjectsarr">
          <el-select
            :multiple-limit="4"
            @change="$forceUpdate()"
            v-model="form.subjectsarr"
            multiple
            placeholder="请选择"
            clearable
            style="width: 429px"
          >
            <el-option
              v-for="item in subjectsOptions"
              :key="item.id"
              :label="item.subject_name"
              :value="item.subject_code"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报名课时：" prop="service_hours">
          <el-input v-model="form.service_hours" autocomplete="off" @input="classSwitching(1)">
            <template slot="append">小时</template></el-input
          >
          <div style="margin-top: 5px" v-if="form.cid">
            1课时={{ rate }}小时 共
            <el-input v-model="rateHour" style="width:80px;margin-top:5px" @input="classSwitching(2)"></el-input>
            课时
          </div>
        </el-form-item>

        <el-form-item label="复习基础：" prop="coach_need">
          <el-input
            v-model="form.coach_need"
            placeholder="请输入复习基础"
          ></el-input>
        </el-form-item>

        <el-form-item label="参考书目：" prop="refer_book">
          <el-input
            v-model="form.refer_book"
            placeholder="请输入参考书目"
          ></el-input>
        </el-form-item>

        <el-form-item label="学管老师：" prop="learn_manager">
          <el-select
            @change="$forceUpdate()"
            v-model="form.learn_manager"
            placeholder="请选择"
            clearable
            style="width: 410px"
            filterable
          >
            <el-option
              v-for="(item, index) in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 4 && v.status == 1;
                  }) != -1
                );
              })"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            >
              <span> {{ `${item.nickname}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="教务老师：" prop="watcher">
          <el-select
            v-model="form.watcher"
            placeholder="请选择"
            clearable
            style="width: 410px"
            multiple
            filterable
          >
            <el-option
              v-for="(item, index) in affairsTeacherList"
              :key="index"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="对接老师：" prop="manage_watcher">
          <el-select
            @change="$forceUpdate()"
            v-model="form.manage_watcher"
            placeholder="请选择"
            clearable
            style="width: 410px"
            multiple
            filterable
          >
            <el-option
              v-for="(item, index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            >
              <span> {{ `${item.nickname}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="服务备注：" prop="applremarky_year">
          <el-input
            type="textarea"
            clearable
            v-model="form.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="continueSubmit('formRules')"
          >确定并继续</el-button
        >
        <el-button
          :loading="btnLoading"
          type="primary"
          @click="submit('formRules')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  collegeList,
  academylist,
  majorlist,
  subjectlist,
  companyuserlist,
  companyOne,
} from "../../../request/api";

import { adminUrl } from "@/utils/helper";

export default {
  props: {
    btnFlag: {
      default: false,
    },

    addForm: {
      default: {},
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      majorType: 0,
      btnLoading: false,
      dialogFormVisible: this.isVisible,
      labelWidth: "150px",
      form: {
        service_hours: 0,
      },
      academyOptions: [],
      majorOptions: [],
      subjectsOptions: [],
      rules: {
        apply_year: [
          { required: true, message: "请选择考研年份", trigger: "blur" },
        ],
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        productsarr: [
          { required: true, message: "请选择报名课程", trigger: "blur" },
        ],
        service_hours: [
          { required: true, message: "请输入辅导课时" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
        apply_college_name: [
          { required: true, message: "请选择院校", trigger: "blur" },
        ],
        apply_academy_name: [
          { required: true, message: "请选择学院", trigger: "blur" },
        ],
        apply_major_code: [
          { required: true, message: "请选择专业", trigger: "blur" },
        ],
        subjectsarr: [
          { required: true, message: "请选择辅导科目", trigger: "blur" },
        ],
        cid: [{ required: true, message: "请选择机构", trigger: "blur" }],
      },
      directionsOptions: [],
      affairsTeacherList: [],
      rate: 0.75,
      rateHour: 0,
    };
  },
  comments: {
    
    // newValue.service_hours
  },
  watch: {
    isVisible(val) {
      this.rateHour = 0
      this.dialogFormVisible = val;
      if (val && this.addForm.apply_year) {
        // console.log("addForm",this.addForm)
        this.form = this.addForm;
      }
      this.form.manage_watcher = [
        JSON.parse(localStorage.getItem("userInfo")).id,
      ];
      // console.log('this.form.manage_watcher:', this.form.manage_watcher)

      if (this.btnFlag) {
        this.btnLoading = false;
      }
    },
    // "form.service_hours": {
    //   handler(newValue, oldValue) {
    //     this.rateHour = (Number(newValue)/this.rate).toFixed(2) || ''
    //   }
    // },
    // rateHour(newValue){
    //   console.log("newValue",newValue)
    //   this.form.service_hours = (Number(newValue) * this.rate).toFixed(2) || ''
    // }

  },
  mounted() {
    // console.log('mounted:', JSON.parse(localStorage.getItem("userInfo")).id)
    // 获取全部院校列表
    // this.getAffairsTeacher()
  },
  methods: {
    classSwitching(type){
      if(type == 1){
        this.rateHour = (Number(this.form.service_hours) / this.rate).toFixed(2)
      }else{
        this.form.service_hours = (Number(this.rateHour) * this.rate).toFixed(2) 
      }
    },
    companyChange(e) {
      this.getAffairsTeacher(e);
      this.getCompanyOne(e);

    },
    getCompanyOne(id) {
      companyOne({ id }).then((res) => {
        if (res.data.code == 0) {
          this.rate = res.data.data.lesson_hour_hour_rate || 0.75;
          if(this.form.service_hours||this.rateHour){
             this.rateHour = (Number(this.form.service_hours) / this.rate).toFixed(2)
          }
        }
      });
    },

    getAffairsTeacher(cid) {
      let query = {
        isPaging: 0,
        cid: cid ? cid : localStorage.getItem("cid"),
      };
      companyuserlist(query).then((res) => {
        this.affairsTeacherList = res.data.data.list || [];
        // console.log('this.affairsTeacherList:', this.affairsTeacherList)
      });
    },
    // 选择院校options搜索出name（后台数据需要name和code两个参数）
    collegeChoose(e) {
      let obj = {};
      obj = this.$store.state.collegeList.find((item) => {
        return item.code === e; //筛选出匹配数据
      });
      this.form.apply_college_code = obj.code;
      this.form.apply_college_name = obj.name;
      this.getMajorOptions();
    },
    getSubjectOptions(college_code, academy_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        academy_code: academy_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程",
        };
        this.subjectsOptions = res.data.data.list || [];

        this.subjectsOptions.push(obj);
      });
    },
    // 获取学院代码与学院名称
    academyChoose(e) {
      if (!e) return;
      let obj = this.academyOptions.find((item) => {
        return item.academy_code === e; //筛选出匹配数据
      });
      this.form.apply_academy_code = obj.academy_code;
      this.form.apply_academy_name = obj.academy_name;
      this.directionsOptions = obj.directions;
      // console.log('this.directionsOptions:', this.directionsOptions)
      // this.$set(this.form, 'apply_direction', {
      //   label: this.directionsOptions[0].direction,
      //   value: this.directionsOptions[0].direction_code,
      // })
      this.$set(this.form, "apply_direction", {
        label: "不限",
        value: "",
      });
    },
    // 选择学院，获取专业代码与专业名称
    majorChoose(e) {
      // console.log('e:', e)

      let obj = {};
      obj = this.majorOptions.find((item) => {
        return item.code === e; //筛选出 匹 配数据
      });
      this.majorType = obj.type;
      // console.log('obj', obj)
      // this.form.apply_major_code = obj.code;
      this.form.apply_major_name = obj.allnames;
      this.getMajorInfo(
        this.form.apply_college_code,
        this.form.apply_major_code
      );
      this.getSubjectOptions(
        this.form.apply_college_code,
        this.form.apply_academy_code,
        this.form.apply_major_code
      );
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl();
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: "get", //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data;
        this.academyOptions = res.data.academys;

        if (this.academyOptions.length == 1) {
          this.form.apply_academy_code = this.academyOptions[0].academy_code;
          this.form.apply_academy_name = this.academyOptions[0].academy_name;
          this.academyChoose(this.form.apply_academy_code);
        }

        this.directionsOptions =
          this.academyOptions.find((v) => {
            return this.form.apply_academy_code == v.academy_code;
          }).directions || [];
        this.directionsOptions.unshift({
          direction_code: "",
          direction: "不限",
        });
        console.log("this.directionsOptions.unshift:", this.directionsOptions);
      });
    },
    getAcademyOptions() {
      let query = {
        isPaging: 0,
        college_code: this.form.apply_college_code,
      };
      academylist(query).then((res) => {
        this.academyOptions = res.data.data.list || [];
      });
    },
    getMajorOptions() {
      let query = {
        isPaging: 0,
        college_code: this.form.apply_college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data;
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: v.name,
          };
        });

        console.log("majorOptions:", this.majorOptions);
      });
    },
    emitClose() {
      this.form = {
        service_hours: 0,
      };
      this.$emit("close");
    },
    continueSubmit(rules) {
      // console.log("1111",this.form.manage_watcher)
      // return
      if (
        this.form.student_phone &&
        !/^1[123456789]\d{9}$/.test(this.form.student_phone)
      ) {
        this.$message({
          message: "请填写正确的手机号",
          type: "error",
          duration: 1000,
        });
        return false;
      }
      this.formateSubjects();
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.btnLoading = true;

          if (this.form.watcher) {
            this.form.watcher = this.form.watcher.join(",");
          }
          if (this.form.manage_watcher) {
            this.form.manage_watcher = this.form.manage_watcher.join(",");
          }
          this.$emit("continueSubmit", {
            form: Object.assign({}, this.form, {
              apply_direction_code: this.form.apply_direction
                ? this.form.apply_direction.value
                : "",
              apply_direction_name: this.form.apply_direction
                ? this.form.apply_direction.label
                : "",
            }),
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    submit(rules) {
      if (
        this.form.student_phone &&
        !/^1[123456789]\d{9}$/.test(this.form.student_phone)
      ) {
        this.$message({
          message: "请填写正确的手机号",
          type: "error",
          duration: 1000,
        });
        return false;
      }
      if (this.form.subjectsarr && this.form.subjectsarr.length) {
        this.formateSubjects();
      }

      this.$refs[rules].validate((valid) => {
        if (valid) {
          if (this.form.manage_watcher) {
            this.form.manage_watcher = this.form.manage_watcher.join(",");
          }

          if (this.form.watcher) {
            this.form.watcher = this.form.watcher.join(",");
          }

          this.btnLoading = true;
          this.$emit("submit", {
            form: Object.assign({}, this.form, {
              apply_direction_code: this.form.apply_direction
                ? this.form.apply_direction.value
                : "",
              apply_direction_name: this.form.apply_direction
                ? this.form.apply_direction.label
                : "",
            }),
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 因为传科目时要上传对象所以在这里做一些处理
    formateSubjects() {
      let arr = [];
      this.form.subjectsarr.forEach((item) => {
        let obj = {};
        obj = this.subjectsOptions.find((v) => {
          return v.subject_code === item; //筛选出匹配数据
        });
        if (obj) {
          arr.push({
            subject_code: obj.subject_code,
            subject_name: obj.subject_name,
          });
        }
      });
      this.form.subjects = arr;
    },
  },
};
</script>

<style lang="less" scoped>
.sign {
  display: flex;
  .select {
    flex: 1;
    /deep/.el-input__inner {
      border-radius: 4px 0 0 4px !important;
    }
  }
  .sign-icon {
    box-sizing: border-box;
    width: 70px;
    height: 32px;
    line-height: 32px;
    background-color: #f5f7fa;
    color: #909399;
    border: 1px solid #dcdfe6;
    border-left: none;
    text-align: center;
    border-radius: 0px 4px 4px 0px;
  }
}
.input-with-select {
  margin-bottom: 16px;
}
</style>