<template>
  <div class="content">
    <el-dialog top="5vh" append-to-body :visible.sync="dialogFormVisible" @close="handleToClose" width="980px">
      <div class="wrap" v-if="Object.keys(content).length">
        <TableTittle title="学员信息"></TableTittle>
        <el-descriptions direction="horizontal" :column="1" border :labelStyle="mylabel" style="margin-bottom: 16px;">
          <el-descriptions-item label="姓名">{{
            content.realname
          }}</el-descriptions-item>
          <!-- <el-descriptions-item label="性别">
            <el-tag size="mini">
              xx

            </el-tag>
          </el-descriptions-item> -->
          <el-descriptions-item label="手机号">
            <div class="flex">
              <div> {{ content.phone }}</div>
              <el-button v-if="content.phone" size="small" :data-clipboard-text="clipboardText" class="phone"
                @click="copy(content.phone, 'phone')">复制</el-button>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="微信号">
            <div class="flex">
              <div> {{ content.wechat}}</div>
              <el-button v-if="content.wechat" size="small" :data-clipboard-text="clipboardText" class="wechat"
                @click="copy(content.wechat, 'wechat')">复制</el-button>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="QQ号">
            <div class="flex">
              <div> {{  content.qq}}</div>
              <el-button v-if="content.qq" size="small" :data-clipboard-text="clipboardText" class="qq"
                @click="copy(content.qq, 'qq')">复制</el-button>
            </div>
          </el-descriptions-item>


          <el-descriptions-item
            label="招生机构">{{ content.company.name }}</el-descriptions-item>

          <el-descriptions-item label="对接人">
             {{getDockingMan(content.docking_man) }}
          </el-descriptions-item>



        </el-descriptions>
        <TableTittle :title="'报考信息'"></TableTittle>
        <el-descriptions direction="horizontal" :column="1" border :labelStyle="mylabel">
          <el-descriptions-item label="考研年份">
             {{ content.apply_year }}
          </el-descriptions-item>
          <el-descriptions-item label="报考院校">
            {{ content.apply_college_name }}
            </el-descriptions-item>
            <el-descriptions-item label="报考学院">
              {{ content.apply_academy_name }}
            </el-descriptions-item>
            <el-descriptions-item label="报考专业">
              {{ content.apply_major_name }}
            </el-descriptions-item>
            <el-descriptions-item label="辅导科目">
              {{getSubjects( content.subjects) }}
            </el-descriptions-item>
            <el-descriptions-item label="辅导时长">
              {{ content.service_hours }}小时
            </el-descriptions-item>
            <el-descriptions-item label="备注">
              {{ content.service_remark }}
            </el-descriptions-item>
        </el-descriptions>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleToClose">取 消</el-button>
        <el-button type="primary" @click="handleToClose">确 定
        </el-button>
      </div>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :url-list="tableImg" :on-close="closeViewer" />
  </div>
</template>
  
<script>
import { studentOne } from "@/request/api";
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import Clipboard from 'clipboard';
import { forEach } from "mathjs";

export default {
  components: {
    TableTittle,
    ElImageViewer
  },
  props: {

    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      clipboardText: '',
      dialogFormVisible: this.isVisible,
      content: {},
      mylabel: {
        width: "120px",
      },
      showViewer: false,
      tableImg: [],

    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
        this.$store.dispatch('getStaffOptions')
      }
    },
  },
  created() {

  },
  computed: {

  },
  methods: {
    copy(text, type) {
      this.clipboardText = text

      var clipboard = new Clipboard(`.${type}`)

      clipboard.on('success', e => {
        this.$message({
          message: '复制成功！',
          type: 'success'
        });
        // 释放内存  
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制  
        this.$message({
          message: '该浏览器不支持自动复制',
          type: 'error'
        });
        // 释放内存  
        clipboard.destroy()
      })
    },

    fetchDetail() {
      studentOne({ id: this.id }).then((response) => {
        let res = response.data
        this.content = res.data
      });
    },
    getDockingMan(ids){
      if(ids){
        let idList = []
        ids.split(',').forEach(item=>{
          this.$store.state.staffOptions.forEach(its=>{
            if(item == its.id){
              idList.push(its.realname)
            }
          })
        })
        return idList.join('/')
      }
    },

    getSubjects(list){
      return list.map(item=>`(${item.subject_code})${item.subject_name}`).join("+")
    },


    renderGender(type) {
      const genders = {
        0: "未知",
        1: "学长",
        2: "学姐",
      };
      return genders[type] || "";
    },
    handleToClose() {
      this.$emit("close");
    },
    

  }
};
</script>
  
<style lang="less" scoped>
.content {
  .img {
    width: 140px;
    height: 140px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}
</style>
  