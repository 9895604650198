<template>
  <div class="content">
    <div class="card-main">
      <div class="card-main-item">
        <span>账户名称：{{ JSON.parse(info.value).name }}</span>
      </div>
      <div class="card-main-item">
        <span>银行名称：{{ JSON.parse(info.value).bank_name }}</span>
      </div>
      <div class="card-main-item">
        <span>银行卡卡号：{{ JSON.parse(info.value).bank_num }}</span>
      </div>
      <div class="card-main-item">
        <span>开户行：{{ JSON.parse(info.value).bank_address }}</span>
      </div>
    </div>
    <el-button
      class="button-new-tag"
      size="small"
      @click="openDialog"
      type="primary"
      >修改账户信息</el-button
    >
    <el-dialog
top="5vh" title="添加账单类型" :visible.sync="DialogShow" width="570px">
      <el-form
        ref="dialogform"
        :rules="rules"
        :model="form"
        label-position="right"
        label-width="120px"
      >
        <el-form-item label="账户名称" prop="name">
          <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="请输入账户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行名称" prop="bank_name">
          <el-input
            v-model="form.bank_name"
            autocomplete="off"
            placeholder="请输入银行名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行卡卡号" prop="bank_num">
          <el-input
            v-model="form.bank_num"
            autocomplete="off"
            placeholder="请输入银行卡卡号"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户行" prop="bank_address">
          <el-input
            v-model="form.bank_address"
            autocomplete="off"
            placeholder="请输入开户行"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="DialogShow = false">取 消</el-button>
        <el-button type="primary" @click="handleInputConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { querycrmconfigure, editcrmconfigure } from "../../../request/api";
export default {
  data() {
    return {
      list: {},
      DialogShow: false,
      form: {},
      info: {},
      rules: {
        name: [{ required: true, message: "请输入账户名称", trigger: "blur" }],
        bank_name: [
          { required: true, message: "请输入银行名称", trigger: "blur" },
        ],
        bank_num: [
          { required: true, message: "请输入银行卡卡号", trigger: "blur" },
        ],
        bank_address: [
          { required: true, message: "请输入开户行", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取学院状态字典
    getList() {
      let query = {
        code: "AccountInfo",
      };
      querycrmconfigure(query).then((res) => {
        this.info = res.data.data;
      });
    },
    openDialog() {
      this.DialogShow = true;
      this.form = JSON.parse(this.info.value);
    },
    handleInputConfirm() {
      this.$refs["dialogform"].validate((valid) => {
        if (valid) {
          let query = {
            id: this.info.id,
            code: this.info.code,
            value: JSON.stringify(this.form),
          };
          editcrmconfigure(query).then((res) => {
            this.$nextTick(() => {
              this.DialogShow = false;
              this.getList();
              this.form = {};
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 24px;
  height: 70px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-right: 24px;
  margin-top: 24px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  float: right;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.card-main {
  display: flex;
  span {
    margin: 0 24px;
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 16px;
    color: #303133;
    opacity: 1;
  }
  .card-main-item {
    display: flex;
  }
}
</style>