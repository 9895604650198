<template>
  <div class="content">
    <el-dialog top="5vh"
               :title="title"
               :visible.sync="dialogFormVisible"
               @close="dialogFormVisible = false"
               :close-on-click-modal="false"
               width="500px">
      <el-table :data="result"
                width="150rpx"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
         
                fontSize: '14px'
                ,
                }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column fixed="left"
                         label="用户名"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            {{scope.row.company_user_obj .alias}}
          </template>
        </el-table-column>
        <el-table-column prop="order_no"
                         label="手机号"
                         align="center"
                         width="170">
          <template slot-scope="scope">
            {{scope.row.company_user_obj .phone}}
          </template>
        </el-table-column>
        <el-table-column prop="order_no"
                         label="查看时间"
                         align="center"
                         width="200">
          <template slot-scope="scope">
            {{
              scope.row.company_user_obj
                ? $moment(scope.row.read_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { orderReadCompanyUser } from '@/request/api'
import { crmUrl } from '@/utils/helper.js'
export default {
  data() {
    return {
      dialogFormVisible: false,
      title: '详情',
      result: [],
      mylabel: {
        width: '120px',
      },
    }
  },
  created() {},
  methods: {
    open(id) {
      console.log('ids', id)
      this.dialogFormVisible = true
      this.detail = {}
      console.log('00000')
      this.getDetail(id)
    },
    openUrl(url) {
      if (url.indexOf('.pdf') != -1) {
        window.open(url)
      } else {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
      }
    },
    getDetail(id) {
      let str = crmUrl()
      let params = {
        find_order_id: id,
      }
      this.$axios({
        url: `${str}/api/v1/sharePlatform/tool/orderReadCompanyUser/list`,
        method: 'get', //可以省略 不写默认是get
        params: params,
      }).then((res) => {
        this.result = res.data.data.list
        console.log('res`', res.data.data.list)
      })
    },
  },
}
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
</style>