<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>余额管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px">
      <el-form label-position="right" :model="screenForm" :inline="true" size="small">
        <el-form-item label="充值日期">
          <el-date-picker v-model="charge_time" type="daterange" align="right" unlink-panels range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" @change="dateChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="机构简称" prop="cid">
          <el-select v-model="query.cid" placeholder="请选择" filterable clearable @change="getList(query)">
            <el-option v-for="item in $store.state.CompanyList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否缴纳保证金" prop="apply_year">
          <el-select v-model="query.deposit_transfer" placeholder="请选择" @change="getList(query)" clearable>
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户余额小于" prop="apply_year">
          <el-input v-model="query.balance" placeholder="请输入数字" style="width: 230px" @input="getList(query)"
            clearable></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <TableTittle :title="TableName"></TableTittle>
      <el-table :data="tableData" :header-cell-style="{
        textAlign: 'center',
        color: '#909399',
        fontFamily: 'PingFang SC',
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: '#F2F6FC',
      }" :cell-style="{ textAlign: 'center', fontSize: '13px' }" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="50" fixed="left">
        </el-table-column>
        <el-table-column prop="id" label="机构ID" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="brand_name" label="机构简称" show-overflow-tooltip width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.company">
              {{ scope.row.company.brand_name }}
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>

        <el-table-column prop="brand_name" label="机构联系人" show-overflow-tooltip width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.company">
              {{ scope.row.company.contact_name + '/' + scope.row.company.contact_phone }}
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>

        <el-table-column prop="deposit" label="保证金">
          <template slot-scope="scope">
            <span v-if="scope.row.deposit">{{ scope.row.deposit + "元" }}
            </span>
            <span v-else>0元</span>
          </template>
        </el-table-column>
        <el-table-column prop="balance" label="账户余额">
          <template slot-scope="scope">
            <span v-if="scope.row.balance">{{ scope.row.balance + "元" }}
            </span>
            <span v-else>0元</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="本月充值">
          <template slot-scope="scope">
            <span>{{ scope.row.month_recharge_amount + "元" }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="年度充值">
          <template slot-scope="scope">
            <span>{{ scope.row.year_recharge_amount + "元" }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="最近充值日期" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.last_recharge_time">{{
              scope.row.last_recharge_time | dateFormat("YYYY-MM-DD")
            }}</span>
            <span v-else> 无</span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="操作" fixed="right" width="160">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="openRechargeRecordDialog(scope.row, '支付记录')"
              :disabled="!$buttonPermission('balancemanagement:check')">支付记录</el-link>
            <el-link type="primary" :underline="false" @click="openRechargeRecordDialog(scope.row, '余额流水')"
              :disabled="!$buttonPermission('balancemanagement:check')">余额流水</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination background layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)" :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)" :total="Number(pageTotal)" @size-change="handleSizeChange"
            @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <RechargeRecord :btnType="btnType" :content="RechargeRecordContent" :isVisible="RechargeRecordDialog"
      @close="RechargeRecordDialog = false">
    </RechargeRecord>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import RechargeRecord from "../../../components/DialogComponents/RechargeRecord/RechargeRecord.vue";
import { accountlist } from "../../../request/api";
export default {
  components: {
    TableTittle,
    RechargeRecord,
  },
  data() {
    return {
      btnType: '',
      TableName: "余额管理",
      tableData: [],
      screenForm: {},
      deleteList: [],
      RechargeRecordContent: {},
      RechargeRecordDialog: false,
      charge_time: "",
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: "",
        start_recharge_time: "",
        end_recharge_time: "",
      },
    };
  },
  mounted() {
    this.getList(this.query);
    this.$store.dispatch("getCompanyList");
  },
  methods: {
    dateChange(e) {
      // 充值时间区间处理
      if (this.charge_time) {
        this.query.start_recharge_time = this.charge_time[0];
        this.query.end_recharge_time = this.charge_time[1] + 86399000;
      } else {
        this.query.start_recharge_time = "";
        this.query.end_recharge_time = "";
      }
      this.$nextTick(() => {
        this.getList(this.query);
      });
    },
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val;
    },
    getList(query) {
      accountlist(query).then((res) => {
        this.tableData = res.data.data.list || [];
        this.pageTotal = res.data.data.count;
      });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    // 下载文件
    downLoad(item) {
      this.$store.commit("downLoad", item.detail_file_url);
    },
    openRechargeRecordDialog(item, type) {
      this.btnType = type
      this.RechargeRecordContent = JSON.parse(JSON.stringify(item));
      this.RechargeRecordDialog = !this.RechargeRecordDialog;
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}

.block {
  float: right;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.demo-form-inline {
  border-bottom: 2px dashed #d2d5df;
}
</style>