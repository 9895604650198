<template>
  <div class="content">
    <el-dialog
top="5vh"
      title="权限分配"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
    <!--  check-strictly -->
      <el-tree
       
        :data="Treedata"
        :props="defaultProps"
        show-checkbox
        @check="handleCheckChange"
        default-expand-all
        node-key="id"
        ref="tree"
        highlight-current
      ></el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary" @click="submit">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      info: {},
      Treedata: this.$store.state.UserRuleList,
      defaultProps: {
        children: "children",
        label: "name",
      },
      selectionsObj: {
        // 要修改的角色id
        id: "",
        // 选中数据做处理之后
        rules: "",
      },
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.info = val;
      if (this.type == "user") {
        this.Treedata = this.$store.state.UserRuleList;
      } else {
        this.Treedata = this.$store.state.crmRuleList;
      }
      this.$nextTick(() => {
        // 回显
        if (this.info.rules) {
          const rules = this.computeSelections(
            this.Treedata,
            this.info.rules.split(",").map((v) => Number(v))
          );
          this.$refs.tree.setCheckedKeys(rules);
          this.selectionsObj = {
            id: this.info.id,
            rules: this.info.rules,
          };
        } else {
          this.$refs.tree.setCheckedKeys([]);
        }
      });
    },
  },
  methods: {
    //  handleCheckChange(data, node) {
    //   const _this = this
    //   // 获取当前节点是否被选中
    //   const isChecked = this.$refs.tree.getNode(data).checked
    //   // 如果当前节点被选中，则遍历上级节点和下级子节点并选中，如果当前节点取消选中，则遍历下级节点并取消选中
    //   if(isChecked) {
    //     // 判断是否有上级节点，如果有那么遍历设置上级节点选中
    //     data.parentPermissionId && setParentChecked(data.parentPermissionId)
    //     // 判断该节点是否有下级节点，如果有那么遍历设置下级节点为选中
    //     // data.children && setChildreChecked(data.children, true)
    //   }else{
    //     // 如果节点取消选中，则取消该节点下的子节点选中
    //     data.tenantPermissionList && setChildreChecked(data.tenantPermissionList, false)
    //   }
    //   function setParentChecked(val) {
    //     // 获取该id的父级node
    //     const parentNode = _this.$refs.tree.getNode(val)
    //     // 如果该id的父级node存在父级id则继续遍历
    //     parentNode && parentNode.data && parentNode.data.parentPermissionId && setParentChecked(parentNode.data.parentPermissionId)

    //     //  设置该id的节点为选中状态
    //     _this.$refs.tree.setChecked(val, true)
    //   }
    //   function setChildreChecked(node, isChecked) {
    //     node.forEach(item => {
    //       item.tenantPermissionList && setChildreChecked(item.tenantPermissionList, isChecked)
    //       _this.$refs.elTree.setChecked(item.id, isChecked)
    //     })
    //   }

    //   //  this.selectionsObj.rules = String(
    //   //   node.checkedKeys.concat(node.halfCheckedKeys)
    //   // );
    // },
    emitClose() {
      this.$emit("close");
    },
    // 层级依赖计算
    computeSelections(rules, selections) {
      const results = [];
      rules.forEach((item) => {
        if (item.children && item.children.length) {
          results.push(...this.computeSelections(item.children, selections));
        } else {
          if (selections.includes(item.id)) {
            results.push(item.id);
          }
        }
      });
      return results;
    },
    handleCheckChange(item, obj) {
      this.selectionsObj.rules = String(
        obj.checkedKeys.concat(obj.halfCheckedKeys)
      );
    },
    submit() {
      this.selectionsObj.id = this.info.id;
      console.log(this.selectionsObj);
      this.$emit("submit", { obj: this.selectionsObj });
    },
  },
};
</script>

<style lang="less" scoped>
</style>