<template>
  <div>
    <el-dialog
    append-to-body 
      top="5vh"
      title="班级管理"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="left"
        ref="formRules"
        :rules="rules"
        size="small"
      >
        <el-form-item prop="status">
          <el-radio-group v-model="dataForm.status">
            <el-radio :label="1">正在上课</el-radio>
            <el-radio :label="2">课程终止</el-radio>
            <el-radio :label="3">课程结束</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  academylist,
  majorlist,
  gradeEdit,
  gradeOne,
  subjectlist,
  commonHandleLogCreate,
  gradeSyncEdit
} from "@/request/api";
import { formateGradeInfo } from "@/utils/helper";

export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
        cocah_lesson_hour: 1
      },
      rules: {

        status: [{ required: true, message: "请选择班级状态", trigger: "blur" }],
      },
      majorOptions: [],// 专业数组
      academyOptions: [],// 学院数组
      subjectOptions: [],//科目数组
      companySelect: 1,
      origin_data: {}
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      gradeOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data

        this.origin_data = Object.assign({}, this.dataForm)

        this.$forceUpdate()
      });
    },
    emitClose() {
      this.$emit("close");
    },

    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            status: this.dataForm.status,
          }
          gradeEdit(query).then((res) => {
            if (res.data.code == 0) {
              this.createLog()
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    formateStatus(status) {
      let statuses = {
        '0': '正在对接',
        '1': '正在上课',
        '2': '课程终止',
        '3': '课程结束',
      }
      return statuses[status]
    },
    createLog() {
      let arr = []
      for (let k in this.origin_data) {
        if (this.origin_data[k] != this.dataForm[k]) {
          arr.push({
            val: k,
            old: this.origin_data[k],
            new: this.dataForm[k],
          })
        }
      }
      let final = arr.map(v => {
        if (v.val == 'status') {
          return `将班级状态由“${this.formateStatus(v.old)}”变更为“${this.formateStatus(v.new)}”`
        }
      })
      let query = {
        ref_id: this.dataForm.id,
        handle_type: 0,
        handle_child_type: 1,
        handle_content: final.join('<br/>'),
      }
      commonHandleLogCreate(query).then((res) => {
        if (res.data.code == 0) {
          this.sync()
        }
      });
    },
    sync() {
      gradeOne({ id: this.id }).then((response) => {
        let res = response.data
        if (res.data.old_id) {
          gradeSyncEdit(formateGradeInfo(res.data, 'edit')).then((res) => {
            if (res.data.data.message == "ok") {
              this.emitClose()
              this.$emit('submit')
            } else {
              this.$message.error(res.data.data.message)
            }
          });
        } else {
          this.emitClose()
          this.$emit('submit')
        }

      })

    },
  },
};
</script>

<style lang="less" scoped>
</style>