<template>
  <div>
    <el-dialog
      top="5vh"
      title="班级管理"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item label="班级类型：" prop="type">
          <el-select
            v-model="dataForm.type"
            placeholder="请选择班级类型"
            clearable
            @change="typeChange"
          >
            <el-option label="一对一" :value="0"></el-option>
            <el-option label="小班课" :value="1"></el-option>
            <el-option label="大班课" :value="2"></el-option>
            <el-option label="试听课" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考研年份：" prop="apply_year">
          <el-select
            v-model="dataForm.apply_year"
            placeholder="请选择考研年份"
            clearable
          >
            <el-option label="2022" value="2022"></el-option>
            <el-option label="2023" value="2023"></el-option>
            <el-option label="2024" value="2024"></el-option>
            <el-option label="2025" value="2025"></el-option>
            <el-option label="2026" value="2026"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考信息：" prop="apply_college">
          <el-select
            v-model="dataForm.apply_college"
            placeholder="请选择院校"
            filterable
            clearable
            @change="collegeChange"
          >
            <el-option
              v-for="(item, index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name }"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="apply_major">
          <el-select
            v-model="dataForm.apply_major"
            placeholder="请选择专业"
            @change="majorChange"
            clearable
          >
            <el-option
              v-for="(item, index) in majorOptions"
              :key="index"
              :label="item.allnames"
              :value="{ value: item.code, label: item.name }"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="apply_major">
          <el-select
            v-model="dataForm.apply_academy"
            placeholder="请选择学院"
            clearable
            @change="academyChange"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="{
                value: item.academy_code,
                label: item.academy_name,
                directions: item.directions,
              }"
            >
              <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="dataForm.apply_direction"
            placeholder="请选择考试方向"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in directionsOptions"
              :key="index"
              :label="item.direction"
              :value="{ value: item.direction_code, label: item.direction }"
            >
              <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="apply_subjects">
          <el-select
            style="width: 100%"
            v-model="dataForm.apply_subjects"
            placeholder="请选择辅导科目"
            clearable
            multiple
            @change="$forceUpdate()"
          >
            <el-option
              v-for="(item, index) in subjectOptions"
              :key="index"
              :label="item.subject_name"
              :value="{ value: item.subject_code, label: item.subject_name }"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级名称：" prop="name">
          <el-input
            v-model="dataForm.name"
            placeholder="请输入班级名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="辅导课时：" prop="cocah_lesson_hour">
          <el-input
            placeholder="请输入辅导课时"
            v-model="dataForm.cocah_lesson_hour"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            @blur="hoursBlur"
          >
            <span slot="append">小时</span>
          </el-input>
        </el-form-item>
        <!--         <el-form-item
          label=""
          v-if="tipsText"
        >
          <div class="tips">
            {{tipsText}}
          </div>
        </el-form-item> -->
        <el-form-item label="复习基础：" prop="coach_need">
          <el-input
            type="textarea"
            v-model="dataForm.coach_need"
            placeholder="请输入复习基础"
          ></el-input>
        </el-form-item>
        <el-form-item label="参考书目" prop="refer_book">
          <el-input
            type="textarea"
            v-model="dataForm.refer_book"
            placeholder="请输入参考书目"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input
            type="textarea"
            v-model="dataForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="学管师：" prop="learn_manager">
          <el-select
            v-model="dataForm.learn_manager"
            placeholder="请选择学管师"
            clearable
          >
            <el-option
              v-for="(item, index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button type="primary" @click="submit('formRules', true)"
          >新建并匹配</el-button
        >
        <el-button type="primary" @click="submit('formRules', false)"
          >确定</el-button
        >
      </div>

      <Matching
        :id="insertId"
        :isVisible="matchDialog"
        @getList="fetchList()"
        @close="matchDialog = false"
      />
      <!--  @submit="fetchList()" -->
    </el-dialog>
  </div>
</template>

<script>
import Matching from "@/components/DialogComponents/TeacherResources/Matching.vue";
import {
  academylist,
  majorlist,
  gradeCreate,
  gradeOne,
  subjectlist,
  studentOne,
  gradeEdit,
  commonHandleLogCreate,
} from "@/request/api";
import { getStorage } from "@/utils/helper";
import { adminUrl } from "@/utils/helper";

export default {
  components: {
    Matching,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Number,
      default: 1,
    },
    student_id: {
      type: Number,
      default: null,
    },
    service_hour: {
      type: Number,
      default: 0,
    },
    student_realname: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      insertId: null,
      matchDialog: false,
      dialogFormVisible: this.isVisible,
      dataForm: {
        cocah_lesson_hour: 1,
      },
      rules: {
        name: [{ required: true, message: "请输入班级名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择班级类型", trigger: "blur" }],
        cocah_lesson_hour: [
          { required: true, message: "请选择辅导课时", trigger: "blur" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
        learn_manager: [
          { required: true, message: "请选择学管师", trigger: "blur" },
        ],
        apply_year: [
          { required: true, message: "请选择考研年份", trigger: "blur" },
        ],

        apply_college: [
          { required: true, message: "请选择院校", trigger: "blur" },
        ],
        apply_major: [
          { required: true, message: "请选择专业", trigger: "blur" },
        ],
        apply_academy: [
          { required: true, message: "请选择学院", trigger: "blur" },
        ],
        apply_subjects: [
          { required: true, message: "请选择科目", trigger: "blur" },
        ],
      },
      majorOptions: [], // 专业数组
      academyOptions: [], // 学院数组
      subjectOptions: [], //科目数组
      companySelect: 1,
      tipsText: "",
      directionsOptions: [],
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.$store.dispatch("getAllColleges");
        this.insertId = null;
        this.fetchStudentDetail();
        this.companySelect = 2;
        this.hoursBlur();
      }
    },
  },
  methods: {
    fetchList(){
      this.$emit('getList')
    },
    fetchStudentDetail() {
      studentOne({ id: this.student_id }).then((response) => {
        let res = response.data;
        this.dataForm = res.data;
        console.log("this.dataForm:", this.dataForm);

        if (res.data.apply_college_name && res.data.apply_college_code) {
          this.$set(this.dataForm, "apply_college", {
            label: this.dataForm.apply_college_name,
            value: this.dataForm.apply_college_code,
          });
          this.getMajor(this.dataForm.apply_college_code);
        }
        if (res.data.apply_major_name && res.data.apply_major_code) {
          this.$set(this.dataForm, "apply_major", {
            label: this.dataForm.apply_major_name,
            value: this.dataForm.apply_major_code,
          });
          this.getMajorInfo(
            this.dataForm.apply_college_code,
            this.dataForm.apply_major_code
          );
        }
        if (res.data.apply_academy_name && res.data.apply_academy_code) {
          this.$set(this.dataForm, "apply_academy", {
            label: this.dataForm.apply_academy_name,
            value: this.dataForm.apply_academy_code,
          });
          this.getSubjects();
          this.$set(this.dataForm, "cocah_lesson_hour", res.data.service_hours);
          if (res.data.learn_manager) {
            this.$set(
              this.dataForm,
              "learn_manager",
              res.data.learn_manager.id
            );
          } else {
            this.$set(
              this.dataForm,
              "learn_manager",
              getStorage("userInfo").id
            );
          }
          let num = Math.floor(Math.random() * 100);
          this.$set(
            this.dataForm,
            "name",
            `${
              this.dataForm.apply_year
                ? this.dataForm.apply_year.substring(2)
                : ""
            }${this.dataForm.apply_college.label}${
              this.dataForm.apply_major.label
            }${num}班`
          );
          this.$forceUpdate();
        }
        if (res.data.subjects && res.data.subjects.length) {
          this.$set(
            this.dataForm,
            "apply_subjects",
            res.data.subjects.map((v) => {
              return {
                value: v.subject_code,
                label: v.subject_name,
              };
            })
          );
        }
        if (res.data.apply_direction_code && res.data.apply_direction_name) {
          this.$set(this.dataForm, "apply_direction", {
            label: res.data.apply_direction_name,
            value: res.data.apply_direction_code,
          });
        }

        this.$set(this.dataForm, "remark", res.data.remark);
      });
    },
    typeChange(e) {
      // console.log("dataForm.apply_subjects",this.dataForm.apply_subjects)
      let subjectsList = {};
      if (this.dataForm.apply_subjects && this.dataForm.apply_subjects.length) {
        subjectsList = this.dataForm.apply_subjects.find(
          (item) => item.value == "999"
        );
      }
      if (e == 0) {
        if (subjectsList && subjectsList.value) {
          this.$set(
            this.dataForm,
            "name",
            `${this.dataForm.realname}的1v1复试课`
          );
        } else {
          this.$set(this.dataForm, "name", `${this.dataForm.realname}的1v1课`);
        }
      } else if (e == 3) {
        this.$set(this.dataForm, "name", `${this.dataForm.realname}的试听课`);
      }
    },
    emitClose() {
      this.dataForm = {};
      this.$emit("close");
    },
    collegeChange(e) {
      this.dataForm.apply_major = {};
      this.majorOptions = [];
      this.dataForm.apply_academy = {};
      this.academyOptions = [];
      this.dataForm.apply_subjects = [];
      this.subjectOptions = [];
      if (e) {
        this.getMajor(e.value);
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data;
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          };
        });
      });
    },
    majorChange(e) {
      this.dataForm.apply_academy = {};
      this.academyOptions = [];
      this.dataForm.apply_subjects = [];
      this.subjectOptions = [];
      if (e) {
        this.getMajorInfo(this.dataForm.apply_college.value, e.value);
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl();
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: "get", //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data;
        this.academyOptions = res.data.academys;
        this.directionsOptions = this.academyOptions.find((v) => {
          return this.dataForm.apply_academy.value == v.academy_code;
        }).directions;
      });
    },
    getAcedemy(college_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        major_code: major_code,
      };
      academylist(query).then((response) => {
        let res = response.data;
        this.academyOptions = res.data.list || [];
      });
    },
    academyChange(e) {
      this.dataForm.apply_subjects = [];
      this.subjectOptions = [];
      this.$forceUpdate();
      let num = Math.floor(Math.random() * 100);

      if (e) {
        this.directionsOptions = e.directions;

        this.$set(
          this.dataForm,
          "name",
          `${
            this.dataForm.apply_year
              ? this.dataForm.apply_year.substring(2)
              : ""
          }${this.dataForm.apply_college.label}${
            this.dataForm.apply_major.label
          }${num}班`
        );
        this.getSubjects();
      }
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.dataForm.apply_college.value,
        academy_code: this.dataForm.apply_academy.value,
        major_code: this.dataForm.apply_major.value,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程",
        };
        this.subjectOptions = res.data.data.list || [];
        this.subjectOptions.push(obj);
      });
    },
    submit(rules, flag) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            cid: localStorage.getItem("cid"),
            apply_college_code: this.dataForm.apply_college
              ? this.dataForm.apply_college.value
              : "",
            apply_college_name: this.dataForm.apply_college
              ? this.dataForm.apply_college.label
              : "",
            apply_major_code: this.dataForm.apply_major
              ? this.dataForm.apply_major.value
              : "",
            apply_major_name: this.dataForm.apply_major
              ? this.dataForm.apply_major.label
              : "",
            apply_academy_code: this.dataForm.apply_academy
              ? this.dataForm.apply_academy.value
              : "",
            apply_academy_name: this.dataForm.apply_academy
              ? this.dataForm.apply_academy.label
              : "",
            apply_direction_code: this.dataForm.apply_direction
              ? this.dataForm.apply_direction.value
              : "",
            apply_direction_name: this.dataForm.apply_direction
              ? this.dataForm.apply_direction.label
              : "",
            apply_subjects: this.dataForm.apply_subjects.map((v) => {
              return {
                subject_code: v.value,
                subject_name: v.label,
              };
            }),
            apply_year: this.dataForm.apply_year,

            name: this.dataForm.name,
            type: this.dataForm.type,
            cocah_lesson_hour:
              this.companySelect == 2
                ? this.dataForm.cocah_lesson_hour
                : (Number(this.dataForm.cocah_lesson_hour) * 0.75).toFixed(2),
            learn_manager: this.dataForm.learn_manager,
            coach_need: this.dataForm.coach_need,
            refer_book: this.dataForm.refer_book,
            remark: this.dataForm.remark,
          };
         
          gradeCreate(query).then((response) => {
            let res = response.data;
            if (res.code == 0) {
              this.insertId = res.data.insertId;
              this.editClass(res.data.insertId, flag);

              // if (this.service_hour > this.dataForm.cocah_lesson_hour) {
              //   this.$confirm(
              //     `当前加入的班级课时为${this.dataForm.cocah_lesson_hour}小时，与该学员的总课时${this.service_hour}小时不匹配，是否确认加入？`,
              //     "提示",
              //     {
              //       confirmButtonText: "确定",
              //       cancelButtonText: "取消",
              //       type: "warning",
              //     }
              //   )
              //     .then(() => {
              //       this.editClass(res.data.insertId);
              //     })
              //     .catch(() => {
              //       this.emitClose();
              //       this.$emit("submit");
              //     });
              // } else if (this.service_hour < this.dataForm.cocah_lesson_hour) {
              //   this.$confirm(
              //     `当前加入的班级课时为${this.dataForm.cocah_lesson_hour}小时，大于该学员的总课时${this.service_hour}小时，是否确认加入？`,
              //     "提示",
              //     {
              //       confirmButtonText: "确定",
              //       cancelButtonText: "取消",
              //       type: "warning",
              //     }
              //   )
              //     .then(() => {
              //       this.editClass(res.data.insertId);
              //     })
              //     .catch(() => {
              //       this.emitClose();
              //       this.$emit("submit");
              //     });
              // } else {
              //   this.editClass(res.data.insertId);
              // }
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    editClass(id, flag) {
      setTimeout(()=>{
 gradeEdit({
        id: id,
        arr_student: [
          {
            student_id: this.student_id,
          },
        ],
      }).then((response1) => {
        let res1 = response1.data;
        if (res1.code == 0) {
          if (flag) {
            this.createLog(id);
          } else {
            this.dialogFormVisible = false;
            this.$emit("submit");
          }
        }
      });
      },600)
    },
    createLog(id) {
      let query = {
        ref_id: id,
        handle_type: 0,
        handle_child_type: 2,
        handle_content: `将${this.student_realname}学员加入班级`,
      };
      commonHandleLogCreate(query).then((res) => {
        if (res.data.code == 0) {
          this.emitClose();
          if (this.insertId) {
            this.matchDialog = true;
          } else {
            this.$emit("submit");
          }
        }
      });
    },
    hoursBlur() {
      if (!/^(\d+|\d+\.\d{1,2})$/.test(this.dataForm.cocah_lesson_hour)) {
        this.tipsText = "输入最多两位小数的数字";
      } else {
        if (this.companySelect == 2) {
          this.tipsText = `${this.dataForm.cocah_lesson_hour}小时等于${(
            this.dataForm.cocah_lesson_hour * 1.33
          ).toFixed(2)}课时`;
        } else if (this.companySelect == 1) {
          this.tipsText = `${this.dataForm.cocah_lesson_hour}课时等于${(
            this.dataForm.cocah_lesson_hour * 0.75
          ).toFixed(2)}小时`;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
}
</style>