<template>
  <div class="content">
    <el-dialog top="5vh" :title="btnType == '余额流水'?'余额流水':'支付记录'" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="1300px"
      @close="emitClose">
      <el-table v-if="btnType == '余额流水'" :data="tableData" :header-cell-style="{
        textAlign: 'center',
        color: '#909399',
        fontFamily: 'PingFang SC',
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: '#F2F6FC',
      }" :cell-style="{ textAlign: 'center', fontSize: '13px' }" height="460" border>
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="amount" label="变动金额">
          <template slot-scope="scope">
            <span>{{ scope.row.amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="way" label="变动类型">
          <template slot-scope="scope">
            <el-tag v-show="scope.row.type == 1">
              <span >收入</span>
            </el-tag>
            <el-tag type="danger" v-show="scope.row.type == 2">
              <span >支出</span>
            </el-tag>
            <el-tag type="success" v-show="scope.row.type == 3">
              <span >提现</span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag type="danger" v-show="scope.row.status == 0">
              <span >已冻结</span>
            </el-tag>
            <el-tag  v-show="scope.row.status == 1">
              <span >已成功</span>
            </el-tag>
            <el-tag type="danger" v-show="scope.row.status == 2">
              <span >已驳回</span>
            </el-tag>
            <el-tag type="success" v-show="scope.row.status == 3">
              <span >已提现</span>
            </el-tag>
            <el-tag  v-show="scope.row.status == 4">
              <span >提现中</span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="balance" label="当前余额">
          <template slot-scope="scope">
            <span>{{ scope.row.balance + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deposit" label="当前保证金" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.deposit + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip width="240">
          <template slot-scope="scope">
            <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="140">
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="140">
          <template slot-scope="scope">
            {{ scope.row.update_time | dateFormat("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
      </el-table>


      <el-table v-else :data="tableData" :header-cell-style="{
        textAlign: 'center',
        color: '#909399',
        fontFamily: 'PingFang SC',
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: '#F2F6FC',
      }" :cell-style="{ textAlign: 'center', fontSize: '13px' }" height="700" border>
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="product_name" label="订单名称" width="280">
        </el-table-column>
        <el-table-column prop="amount" label="订单金额">
          <template slot-scope="scope">
            <span>{{ scope.row.amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_amount" label="支付金额">
          <template slot-scope="scope">
            <span>{{scope.row.pay_amount? scope.row.pay_amount + "元" :'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="支付状态">
          <template slot-scope="scope">
            <el-tag v-show="scope.row.pay_status == 0">
              <span>未支付</span>
            </el-tag>
            <el-tag type="success" v-show="scope.row.pay_status == 1">
              <span>已支付</span>
            </el-tag>
            <el-tag type="danger" v-show="scope.row.pay_status == 2">
              <span>已退款</span>
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="pay_type" label="支付方式" show-overflow-tooltip width="80">
          <template slot-scope="scope">
            <span>{{ getPayType(scope.row.pay_type) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_trade_no" label="平台交易单号" width="240">
          <template slot-scope="scope">
            <span>{{scope.row.pay_trade_no?scope.row.pay_trade_no:'-'}}</span>
          </template>
        </el-table-column>


        <el-table-column prop="refund_amount" label="退费金额" >
          <template slot-scope="scope">
            <span>{{ scope.row.refund_amount? scope.row.refund_amount+ "元" :'-'}}</span>
          </template>
        </el-table-column>
       
        <el-table-column prop="refund_status" label="退费状态">
          <template slot-scope="scope">
            <el-tag v-show="scope.row.refund_status == 0">
              <span>未申请</span>
            </el-tag>
            <el-tag type="success" v-show="scope.row.refund_status == 1">
              <span>已申请</span>
            </el-tag>
            <el-tag type="danger" v-show="scope.row.refund_status == 2">
              <span>已退款</span>
            </el-tag>
          </template>
        </el-table-column>
        

        
        <!-- <el-table-column prop="balance" label="当前余额">
          <template slot-scope="scope">
            <span>{{ scope.row.balance + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deposit" label="当前保证金" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.deposit + "元" }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="remark" label="备注" show-overflow-tooltip width="240">
          <template slot-scope="scope">
            <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_time" label="支付时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.pay_time?$moment(scope.row.pay_time).format("YYYY-MM-DD HH:mm"):'-'}}
          </template>
        </el-table-column>
        <el-table-column prop="refund_time" label="退款时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.refund_time?$moment(scope.row.refund_time).format("YYYY-MM-DD HH:mm"):'-' }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary" @click="emitClose">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { rechargeorderlist, accountLog } from "../../../request/api";
import { crmUrl } from "@/utils/helper.js";
export default {
  props: {
    btnType: {
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      tableData: [],
    };
  },
  mounted() { },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      let query = {
        cid: val.company.id,
        pageIndex: 1,
        pageSize: 999
      };
      this.tableData = []
      let str = crmUrl()
      // console.log("，，",this.btnType)
      if (this.btnType == '支付记录') {
        this.$axios({
          // url: `${str}/api/v1/company/account/log`,
          url: `${str}/api/v1/company/account/orderSender/paymentLog`,
          method: 'get',//可以省略 不写默认是get
          params: query,
        }).then(res => {
          this.tableData = res.data.data.list || [];
          // console.log('re',res)
        })
      } else if (this.btnType == '余额流水') {

        this.$axios({
          // url: `${str}/api/v1/company/account/log`,
          url: `${str}/api/v1/company/account/log`,
          method: 'get',//可以省略 不写默认是get
          params: query,
        }).then(res => {
          this.tableData = res.data.data.list || [];
          // console.log('re',res)
        })
      }

      // accountLog(query).then(res=>{
      //   console.log('re',res)
      // })
      // accountLog(query).then((res) => {
      //   console.log('re',res)
      //   // this.tableData = res.data.data.list || [];
      // });
    },
  },
  methods: {
    getPayType(type) {
      switch (type) {
        case 0:
          return '无';
        case 1:
          return '支付宝'
        case 2:
          return '微信'
        case 3:
          return '余额'
        case 4:
          return '转账'
      }
    },
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped></style>