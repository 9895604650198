<template>
    <div class="content">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>资料管理</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="button-wrap">
                <el-button type="primary" size="small" @click="handleAdd">新建</el-button>
            </div>
        </div>

        <el-card style="margin-top: 20px">
            <el-form class="search-wrap" ref="searchForm" :inline="true" :model="queryForm" size="small"
                label-position="left">
                <el-form-item label="名称">
                    <el-input @input="getDataList(true)" style="width: 170px;" v-model.trim="queryForm.name_keyword"
                        placeholder="请输入资料名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="年份">
                    <el-select @change="getDataList(true)" v-model="queryForm.years" placeholder="请选择年份"
                        style="width: 150px;" clearable>
                        <el-option v-for="(item, index) in yearList" :key="index" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="院校名称">
                    <el-input @input="getDataList(true)" style="width: 170px;" v-model.trim="queryForm.college_keyword"
                        placeholder="请输入院校名称/代码" clearable></el-input>
                </el-form-item>
                <el-form-item label="专业名称">
                    <el-input @input="getDataList(true)" style="width: 170px;" v-model.trim="queryForm.major_keyword"
                        placeholder="请输入专业名称/代码" clearable></el-input>
                </el-form-item>
                <el-form-item label="学院名称">
                    <el-input @input="getDataList(true)" style="width: 170px;" v-model.trim="queryForm.academy_keyword"
                        placeholder="请输入学院名称/代码" clearable></el-input>
                </el-form-item>
                <el-form-item label="科目">
                    <el-input @input="getDataList(true)" style="width: 170px;" v-model.trim="queryForm.subject_keyword"
                        placeholder="请输入科目名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="文件类型">
                    <el-select @change="getDataList(true)" v-model="queryForm.types" placeholder="选择文件类型"
                        style="width: 150px;" clearable>
                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card style="margin-top: 20px">
            <TableTittle :title="TableName"></TableTittle>
            <el-table :data="tableData" ref="multipleTable" v-loading="loading" @selection-change="handleSelectionChange"
                :header-cell-style="{
                    textAlign: 'center',
                    color: '#909399',
                    fontFamily: 'PingFang SC',
                    fontSize: '14px',
                    fontWeight: '400',
                    backgroundColor: '#F2F6FC',
                }" :cell-style="{ fontSize: '13px',}" border
                style="width: 100%; margin-top: 20px">
                <el-table-column type="selection" width="50" align="center"> </el-table-column>
                <el-table-column  label="序号" width="80">
                    <template slot-scope="scope">
                        {{ scope.$index+1}}
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="名称" width="400" show-overflow-tooltip align="left">
                </el-table-column>
                <el-table-column prop="description" label="简介" width="300" show-overflow-tooltip align="left">
                </el-table-column>
                <el-table-column prop="type" label="类型" width="80" align="center">
                    <template slot-scope="scope">
                        {{ getTypeName(scope.row.type) }}
                    </template>
                </el-table-column>
                <el-table-column prop="year" label="年份" width="80" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.year }}年
                    </template>
                </el-table-column>
                <el-table-column prop="college_name" label="院校" width="180" align="center">
                    <template slot-scope="scope">
                       <span class="pointer" @click="institutionOpen(scope.row)">{{scope.row.college_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="academy_name" label="学院" width="180" align="center">
                </el-table-column>
                <el-table-column prop="major_name" label="专业" width="180" align="center">
                    <template slot-scope="scope">
                        ( {{ scope.row.major_code }}){{ scope.row.major_name}}
                    </template>
                </el-table-column>
                <el-table-column prop="subject_names" label="科目" width="240" align="center">
                    <template slot-scope="scope">
                        {{ getSubject(scope.row.subject_names,scope.row.subject_codes) }}
                    </template>
                </el-table-column>
                
               
               
                <el-table-column prop="manage_creator.nickname" label="创建人" width="120" align="center">
                </el-table-column>
                <el-table-column label="创建时间" width="150" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.create_time ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "" }}
                    </template>
                </el-table-column>
                <el-table-column label="更新时间" width="150 " align="center">
                    <template slot-scope="scope">
                        {{ scope.row.update_time ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm") : "" }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="160" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="handleDetail(scope.row)">查看</el-link>
                        <el-link type="primary" :underline="false" @click="handleUp(scope.row)"  :disabled='!scope.row.eidtable'>编辑</el-link>
                        <el-link type="danger" :underline="false" @click="handleDel(scope.row.id)"
                            :disabled='!scope.row.eidtable'>删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top: 16px">
                <el-button size="mini" type="danger" @click="handleToDeleteAll()"
                    :disabled="chooseList.length <= 0">批量删除</el-button>
                <div class="block">
                    <el-pagination background layout="total, prev, pager, next, sizes, jumper"
                        :current-page="Number(queryForm.pageIndex)" :page-sizes="[10, 20, 50, 100, 500]"
                        :page-size="Number(queryForm.pageSize)" :total="Number(pageTotal)" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"></el-pagination>
                </div>
            </div>
        </el-card>

        <DataManagementAdd ref="dataManagementAdd" @submit="getDataList(true)"></DataManagementAdd>
        <el-dialog top="5vh" :visible.sync="dialogFormVisible" @close="dialogFormVisible = false"
            :close-on-click-modal="false" width="620px">
            <el-form label-position="right" ref="dataForm" label-width="100px" size="small">
                <el-form-item label="链接" prop="link_url">
                    <div class="box">{{ detail.link_url }}</div>
                </el-form-item>
                <el-form-item label="密码" prop="link_password">
                    <div class="box">{{detail.eidtable? detail.link_password:'******' }}</div>
                </el-form-item>
            </el-form>

            <div class="footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false" plain>确定</el-button>
            </div>
        </el-dialog>
        <InstitutionDetails ref="institutionDetails"></InstitutionDetails>
    </div>
</template>

<script>
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import DataManagementAdd from "@/components/ShareOrderCompeonents/dataManagement/DataManagementAdd.vue";
import InstitutionDetails from "@/components/DialogComponents/InstitutionDetails/index.vue"
import { commonCollegeMaterial, commonCollegeMaterialDelList, commonCollegeMaterialDel } from "@/request/api";
export default {
    components: {
        TableTittle,
        DataManagementAdd,
        InstitutionDetails
    },
    data() {
        return {
            loading: false,
            TableName: '资料列表',
            detail: {},
            queryForm: {
                pageIndex: 1,
                pageSize: 10,
            },
            yearList: [
                {
                    label: '2023',
                    value: '2023'
                },
                {
                    label: '2022',
                    value: '2022'
                },
                {
                    label: '2021',
                    value: '2021'
                },
                {
                    label: '2020',
                    value: '2020'
                },
                {
                    label: '2019',
                    value: '2019'
                }
            ],
            typeList: [
                {
                    label: '资料包',
                    value: '1'
                },
                {
                    label: '真题',
                    value: '2'
                },
                {
                    label: '笔记',
                    value: '3'
                },
                {
                    label: '讲义',
                    value: '4'
                },
                {
                    label: '小讲',
                    value: '5'
                },
                {
                    label: '课程',
                    value: '6'
                },
            ],
            chooseList: [],
            pageTotal: 0,
            tableData: [],
            dialogFormVisible: false
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        institutionOpen(row){
      this.$refs.institutionDetails.open(row.college_code)
    },
        handleDetail(row) {
            this.detail = row
            this.dialogFormVisible = true

        },
        handleAdd() {
            this.$refs.dataManagementAdd.open()
        },
        handleUp(id) {
            this.$refs.dataManagementAdd.open(id)
        },
        getDataList(reset) {
            if (reset) {
                this.queryForm.pageIndex = 1
            }
            commonCollegeMaterial(this.queryForm).then(res => {
                if (res.data.code == 0) {
                    this.pageTotal = res.data.data.count
                    this.tableData = res.data.data.list
                }
                // console.log('res', res)
            })
        },
        handleToDeleteAll() {
            let flag = this.chooseList.some(item=>!item.eidtable)
            // console.log(flag)
            if(flag){
                this.$message({
                    type: 'error',
                    message: '无权限!'
                });
                return
            }
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                commonCollegeMaterialDelList({
                    ids: this.chooseList.map(item => item.id)
                }).then(res => {
                    if (res.data.code == 0) {
                        this.getDataList()
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }

                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
            // console.log(this.chooseList)
        },
        handleDel(id) {
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                commonCollegeMaterialDel({
                    id: id
                }).then(res => {
                    if (res.data.code == 0) {
                        this.getDataList()
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }

                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        getTypeName(type) {
            switch (type) {
                case 1:
                    return '资料包'
                case 2:
                    return '真题'
                case 3:
                    return '笔记'
                case 4:
                    return '讲义'
                case 5:
                    return '小讲'
                case 6:
                    return '课程'
            }
        },

        getSubject(names,codes){
            
            if(names&&codes){
                // console.log('ccc',names,codes)
                let nameList = names.split(',')
                let codeList = codes.split(',')
                let codeName = ''
                nameList.forEach((item,index)=>{
                    // console.log("item",item)
                    codeName+=( `(${codeList[index]})` +item + ',')
                })
                return codeName.substring(0,codeName.length-1)
            }
           

        },
        handleSelectionChange(val) {
            this.chooseList = val
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.getDataList();
        },
        handleCurrentChange(val) {
            this.queryForm.pageIndex = val;
            this.getDataList();
        },

    }
}
</script>

<style lang="less" scoped>
.box {
    width: 100%;
    height: 36px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #DCDFE6;
    padding-left: 10px;
}
</style>