<template>
  <div class="content">
    <el-dialog
      top="5vh"
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="dialogFormVisible = false"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="dataForm"
        :rules="rules"
        label-width="120px"
        size="small"
      >
        <el-form-item label="机构课酬状态" prop="company_lesson_status">
          <el-select
            v-model="dataForm.company_lesson_status"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="submit()" plain> 确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tempUpdateCompanyLessonStatus } from "@/request/api";
export default {
  data() {
    return {
      title: "更新机构课酬状态",
      dialogFormVisible: false,
      ids: [],
      dataForm: {},
      options: [
        {
          label: "待生成",
          value: 1,
        },
        {
          label: "已生成",
          value: 2,
        },
      ],
      rules: {
        company_lesson_status: [
          { required: true, message: "机构课酬状态不能为空", trigger: "blur" },
        ],
      },
    };
  },

  created() {},
  methods: {
    open(data) {
      this.dataForm.arr_lesson_log_id  = data;
    //   console.log("data", data);
      this.dialogFormVisible = true;
    },

    submit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          tempUpdateCompanyLessonStatus(this.dataForm).then((res) => {
            if(res.data.code == 0){
                this.dialogFormVisible = false
                this.$message.success("修改成功！");
                this.$emit('submit')
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>