<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item>我的签单</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">

      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="匹配师">
          <el-select v-model="query.allocation_manage_id"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option v-for="item in $store.state.staffOptions.filter(v=>{
                return v.depts.findIndex(t=>{
                  return t.id==5 && v.status == 1
                })!=-1
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input v-model.trim="query.allocation_order_keyword"
                    placeholder="请输入编号/辅导对象"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="老师">
          <el-input v-model.trim="query.teacher_keyword"
                    placeholder="请输入名称/手机号"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="确认状态："
                      prop="confirm_statuses">
          <el-select v-model="query.confirm_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)">
            <el-option label="已确认"
                       :value="1"></el-option>
            <el-option label="未确认"
                       :value="0"></el-option>
            <el-option label="已驳回"
                       :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="确认理由">
          <el-input v-model.trim="query.confirm_reason_keyword"
                    placeholder="请输入确认理由"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="签单时间">
          <el-date-picker class="datetime"
                          v-model="create_time"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="fetchList(true)"
                          clearable
                          :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="类别：">
          <el-select v-model="query.allocation_order_types"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option label="外包"
                       :value="1"></el-option>
            <el-option label="代找"
                       :value="2"></el-option>
            <el-option label="代签"
                       :value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table :data="result"
                :header-cell-style="{
                    backgroundColor: '#F2F6FC',
                color: '#909399',
                fontSize: '14px',
                  }"
                :cell-style="{fontSize: '13px' }"
                border>
        <el-table-column prop="id"
                         label="ID"
                         align="center"
                         width="80">
        </el-table-column>
        <el-table-column label="签单人"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{ scope.row.allocation_manage_user?scope.row.allocation_manage_user.nickname:'无' }}
          </template>
        </el-table-column>
        <el-table-column prop="teacher_realname"
                         label="签单老师"
                         align="center"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.teacher_realname }}/ {{ scope.row.teacher_mobile }}
          </template>
        </el-table-column>
        <el-table-column label="确认状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.confirm_status==0"
                    type="warning">待确认</el-tag>
            <el-tag size="small"
                    v-if="scope.row.confirm_status==1"
                    type="success">已确认</el-tag>
            <el-tag type="danger"
                    size="small"
                    v-if="scope.row.confirm_status==2">已驳回</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="confirm_reason"
                         label="确认理由"
                         align="left"
                         width="300">
          <template slot-scope="scope">
            {{ scope.row.confirm_reason?scope.row.confirm_reason:'暂无' }}
          </template>
        </el-table-column>
        <el-table-column label="类别"
                         align="center"
                         width="80">
          <template slot-scope="scope">
            {{ formateType(scope.row) }}
          </template>
        </el-table-column>

        <el-table-column label="机构简称"
                         align="center"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.allocation_order_company?scope.row.allocation_order_company.name:'无' }}
          </template>
        </el-table-column>
        <el-table-column prop="allocation_order_order_no"
                         label="订单编号"
                         align="center"
                         width="120">
        </el-table-column>
        <el-table-column label="订单状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.allocation_order_status == 1">待分配</el-tag>
            <el-tag size="small"
                    v-if="scope.row.allocation_order_status == 2">匹配中</el-tag>
            <el-tag size="small"
                    v-if="scope.row.allocation_order_status == 3"
                    type="warning">待确认</el-tag>
            <el-tag size="small"
                    v-if="scope.row.allocation_order_status == 4"
                    type="success">已完成</el-tag>
            <el-tag type="danger"
                    size="small"
                    v-if="scope.row.allocation_order_status == 5">返单中</el-tag>
            <el-tag type="info"
                    size="small"
                    v-if="scope.row.allocation_order_status == 6">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="辅导对象"
                         align="left"
                         width="180"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateName(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="报考信息"
                         align="left"
                         width="350"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span><span class="pointer"
                    @click="institutionOpen(scope.row)">{{scope.row.allocation_order_apply_college_name}}</span> | {{scope.row.allocation_order_apply_academy_name}} |
              ({{scope.row.allocation_order_apply_major_code}}){{scope.row.allocation_order_apply_major_name}}
              {{ scope.row.allocation_order_apply_direction_code&&scope.row.allocation_order_apply_direction_name?` | (${scope.row.allocation_order_apply_direction_code})${scope.row.allocation_order_apply_direction_name}`:'' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="辅导科目"
                         align="left"
                         width="250"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ formateSubjects(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="辅导课时"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.allocation_order_lesson_hours}}小时
          </template>
        </el-table-column>
        <el-table-column prop="allocation_order_allocation_demand"
                         label="要求"
                         align="left"
                         width="350"
                         show-overflow-tooltip>
        </el-table-column>

        <el-table-column label="学管师"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{ formateIds(scope.row.allocation_order_learn_manage_id)}}
          </template>
        </el-table-column>
        <el-table-column label="签单时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):"无"}}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         fixed="right"
                         align="center"
                         width="200">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToSeniorDetail(scope.row)">老师详情</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleOrder(scope.row)">订单处理</el-link>
          </template>
        </el-table-column>

      </el-table>
      <div class="operation">
        <div class="block"
             style="margin-bottom:0px;">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <SeniorDetail :isVisible="SeniorDetailDialog"
                  @close="SeniorDetailDialog=false"
                  :id="detailId" />
    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>
  </div>
</template>
          
        <script>
import TableTittle from '@/components/TableTittle/TableTittle.vue'
import SeniorDetail from '@/components/DialogComponents/TeacherResources/SeniorDetail.vue'

import InstitutionDetails from '@/components/DialogComponents/InstitutionDetails/index.vue'

import { taSignOrderList } from '@/request/api'

export default {
  components: {
    TableTittle,
    SeniorDetail,
    InstitutionDetails,
  },
  data() {
    return {
      TableName: '签单列表',
      result: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        is_oauth: 1,
      },
      pageTotal: 0,
      create_time: '',
      SeniorDetailDialog: false,
      detailId: null,
    }
  },
  created() {
    this.parseQuery()
    this.fetchList()
    this.$store.dispatch('getStaffOptions')
  },
  watch: {
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf()
        this.query.end_create_time = this.$moment(value[1]).valueOf()
      } else {
        this.query.start_create_time = ''
        this.query.end_create_time = ''
        this.fetchList(true)
      }
    },
  },
  computed: {},
  methods: {
    institutionOpen(row) {
      this.$refs.institutionDetails.open(
        row.allocation_order_apply_college_code
      )
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)

      taSignOrderList(params).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        this.pageTotal = res.data.count
      })
      this.syncQuery()
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.start_create_time && this.query.end_create_time) {
        this.create_time = [
          this.$moment(Number(this.query.start_create_time)),
          this.$moment(Number(this.query.end_create_time)),
        ]
      }
    },

    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )

      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    formateSubjects(row) {
      let arr = row.allocation_order_apply_subject_names
        ? row.allocation_order_apply_subject_names.split(',')
        : []
      let code_arr = row.allocation_order_apply_subject_codes
        ? row.allocation_order_apply_subject_codes.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `(${code_arr[i]})${v}`
            })
            .join('+')
        : '无'
    },
    formateType(row) {
      let types = {
        1: '外包',
        2: '代找',
        3: '代签',
      }
      return types[row.allocation_order_type]
    },
    formateName(row) {
      if (row.allocation_order_type == 1) {
        return row.allocation_order_grade
          ? row.allocation_order_grade.name
          : '无'
      } else if (row.allocation_order_type == 3) {
        return row.allocation_order_dispatch_order
          ? row.allocation_order_dispatch_order.student_name
          : '无'
      } else if (row.allocation_order_type == 2) {
        return row.allocation_order_match_order
          ? `代找ID ${row.allocation_order_match_order.order_no}`
          : '无'
      }
    },
    formateIds(id) {
      if (id && this.$store.state.staffOptions.length) {
        return this.$store.state.staffOptions.find((v) => {
          return v.id == id
        }).nickname
      } else {
        return '无'
      }
    },
    handleOrder(row) {
      if (row.allocation_order_status == 1) {
        this.$message.error('请分配匹配师')
        return
      }
      if (row.allocation_order_type == 1) {
        window.open(
          `/tr/order/handle/${row.teacher_allocation_order_id}?name=${
            row.allocation_order_grade ? row.allocation_order_grade.name : ''
          }`
        )
      } else if (row.allocation_order_type == 3) {
        this.$router.push()
        window.open(
          `/tr/order/handle/${row.teacher_allocation_order_id}?name=${
            row.allocation_order_dispatch_order
              ? row.allocation_order_dispatch_order.student_name
              : ''
          }`
        )
      } else {
        window.open(`/tr/order/handle/${row.teacher_allocation_order_id}`)
      }
    },
    handleToSeniorDetail(row) {
      this.detailId = row.teacher_id
      this.SeniorDetailDialog = true
    },
  },
}
</script>
          
    <style lang="less" scoped>
.content {
  .operation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }
  .el-link {
    font-size: 14px;
    margin: 0px 5px;
  }
}
</style>
          