<template>
  <div class="contont">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资共享</el-breadcrumb-item>
        <el-breadcrumb-item>预留老师</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap"></div>
    </div>

    <el-card style="margin-top: 20px">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="老师信息">
          <el-input
            v-model.trim="query.pre_teacher_keyword"
            placeholder="输入老师姓名/手机号"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="状态">
          <el-select
            v-model="query.comp_teacher_statuses"
            placeholder="请选择状态"
            @change="fetchList(true)"
            clearable
          >
            <el-option
              v-for="(item, index) in statusesList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="年份">
          <el-select
            v-model="query.apply_years"
            placeholder="请选择年份"
            @change="fetchList(true)"
            clearable
          >
            <el-option
              v-for="(item, index) in yearsList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="院校">
          <el-input
            v-model.trim="query.college_keyword"
            placeholder="输入院校名称"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="初试科目">
          <el-input
            v-model.trim="query.subject_keyword"
            placeholder="输入初试科目"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="提交人">
          <el-input
            v-model.trim="query.receiver_company_user_keyword"
            placeholder="输入姓名/手机号"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="提交日期">
          <el-date-picker
            v-model="create_time"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm"
            :default-time="['00:00:00', '23:59:59']"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="fetchList(true)"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form size="small" :inline="true">
          <el-form-item label-width="32px">
            <el-radio-group
              v-model="query.comp_teacher_statuses"
              @input="fetchList(true)"
            >
              <el-radio-button :label="''">全部</el-radio-button>
              <el-radio-button :label="'2'">正常</el-radio-button>
              <el-badge :value="authstrNum" :max="99" :hidden="authstrNum == 0">
                <el-radio-button :label="'3'">待审核</el-radio-button>
              </el-badge>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <el-table
        :data="dataList"
        :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
        :cell-style="{ fontSize: '13px' }"
        @selection-change="handleSelectionChange"
        border
      >
        <!-- fixed="left"  -->
        <el-table-column type="selection" width="50" align="center">
        </el-table-column>
        <el-table-column prop="id" label="ID" align="center" width="80">
        </el-table-column>

        <el-table-column label="提交日期" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="teacher_name"
          label="姓名"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column label="状态" align="center" width="80">
          <template slot-scope="scope">
            <el-tag :type="getStatus(scope.row.comp_teacher_status, 1)">{{
              getStatus(scope.row.comp_teacher_status, 2)
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          label="录取信息"
          align="center"
          width="500"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{
              `${scope.row.teacher_apply_year}年 | ${
                scope.row.pre_apply_college_name
              } | ${scope.row.pre_apply_academy_name? scope.row.pre_apply_academy_name+' | ': ''}
             (${scope.row.pre_apply_major_code})${
                scope.row.pre_apply_major_name
              } | (${scope.row.pre_apply_direction_code})${
                scope.row.pre_apply_direction_name
              }
             | ${scope.row.teacher_first_exam_score?scope.row.teacher_first_exam_score+'分 |':'暂无初试总分'}${getSubject(
                scope.row
              )}
             `
            }}
          </template>
        </el-table-column>
        <el-table-column label="提交人" align="center" width="240">
          <template slot-scope="scope">
            {{ scope.row.receiver_company_user_company_name }} /
            {{ scope.row.receiver_company_user_name }} /
            {{ scope.row.receiver_company_user_phone }}
          </template>
        </el-table-column>

        <el-table-column
          prop="pre_check_access_count"
          label="采纳数"
          align="center"
          width="80"
        >
        </el-table-column>

        <el-table-column
          prop="pre_check_refuse_count"
          label="驳回数"
          align="center"
          width="80"
        >
        </el-table-column>

        <el-table-column
          prop="pre_college_major_sort"
          label="排序值"
          align="center"
          width="80"
        >
        </el-table-column>

        <el-table-column label="更新时间" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" align="center" width="160">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleDetail(scope.row)"
              >详情</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              @click="handleAudit(scope.row)"
              >审核</el-link
            >
            <el-link
              type="danger"
              :underline="false"
              @click="handleDel(scope.row.id)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="operation">
        <div class="del-btn">
          <div class="btn-text">
            已选<span>{{ selectList.length }}</span
            >条
          </div>
          <el-button
            size="mini"
            type="danger"
            :disabled="!selectList.length"
            @click="handleToDeleteAll()"
            >删除</el-button
          >
        </div>
        <div class="block" style="margin-bottom: 0px">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>

    <ReserveTeachersDetails ref="reserveTeachersDetails" />
    <ReserveTeachersAudit
      ref="reserveTeachersAudit"
      @offLineSubmit="fetchList(true)"
    />
  </div>
</template>

<script>
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import ReserveTeachersDetails from "../../../components/reserveTeachers/ReserveTeachersDetails.vue";
import ReserveTeachersAudit from "../../../components/reserveTeachers/ReserveTeachersAudit.vue";

import { crmUrl } from "@/utils/helper.js";

export default {
  components: {
    TableTittle,
    ReserveTeachersDetails,
    ReserveTeachersAudit,
  },
  data() {
    return {
      TableName: "老师列表",
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        comp_teacher_statuses: "",
        is_force_all_comp_status :"1" 
      },
      authstrNum: 0,
      dataList: [],
      pageTotal: 0,
      selectList: [],
      ids: [],
      create_time: [],
      statusesList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "已提交",
          value: "1",
        },
        {
          label: "正常",
          value: "2",
        },
        {
          label: "待审核",
          value: "3",
        },
        {
          label: "已下线",
          value: "4",
        },
        {
          label: "已删除",
          value: "5",
        },
      ],
      yearsList: [
        {
          label: "2023年",
          value: "2023",
        },
        {
          label: "2022年",
          value: "2022",
        },
        {
          label: "2021年",
          value: "2021",
        },
        {
          label: "2020年",
          value: "2020",
        },
      ],
    };
  },
  created() {
    this.fetchList();
    this.getAuthstrNum();
  },
  methods: {
    fetchList(flag) {
      if (flag) {
        this.query.pageIndex = 1;
      }
      let data = {};
      if (this.create_time && this.create_time.length) {
        data.start_create_time = this.create_time[0];
        data.end_create_time = this.create_time[1];
      }
      let str = crmUrl();
      this.$axios({
        url: `${str}/api/v1/sharePlatform/preTeacher/list`,
        method: "get", //可以省略 不写默认是get
        params: {
          ...this.query,
          ...data,
        },
        headers: {
          // 设置请求头
          Authorization: localStorage.getItem("admin_token"),
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.pageTotal = res.data.data.count;
          this.dataList = res.data.data.list;
          // console.log("res:", this.dataList);
        }
      });
    },
    getAuthstrNum() {
      let str = crmUrl();
      this.$axios({
        url: `${str}/api/v1/sharePlatform/preTeacher/list`,
        method: "get", //可以省略 不写默认是get
        params: {
          isPaging: 0,
          comp_teacher_statuses: "3",
        },
        headers: {
          // 设置请求头
          Authorization: localStorage.getItem("admin_token"),
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.authstrNum = res.data.data.count 
          // console.log("res----:", res);
        }
      });
    },

    handleDetail(row) {
      this.$refs.reserveTeachersDetails.open(
        row.id,
        row.receiver_company_user_company_name,
        row.receiver_company_user_name,
        row.receiver_company_user_phone
      );
    },

    handleAudit(row) {
      this.$refs.reserveTeachersAudit.open(
        row.id,
        row.receiver_company_user_company_name,
        row.receiver_company_user_name,
        row.receiver_company_user_phone
      );
    },
    handleDel(id) {
      this.ids = [id];
      this.handleToDeleteAll();
    },
    handleToDeleteAll() {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let str = crmUrl();
          this.$axios({
            url: `${str}/api/v1/sharePlatform/preTeacher/list`,
            method: "delete", //可以省略 不写默认是get
            data: {
              ids: this.ids,
            },
            headers: {
              Authorization: localStorage.getItem("admin_token"),
            },
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.fetchList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });

      //
    },

    getSubject(row) {
      if (row.pre_first_subject_codes) {
        let nameList = row.pre_first_subject_names.split(",");
        let codeList = row.pre_first_subject_codes.split(",");
        return nameList
          .map((item, index) => `(${codeList[index]})${item}`)
          .join(",");
      }
      return "暂无科目";
    },
    handleSelectionChange(e) {
      this.selectList = e;
      this.ids = this.selectList.map((item) => item.id);
    },
    getStatus(status, type) {
      switch (status) {
        case 1:
          if (type == 1) return "";
          return "已提交";
        case 2:
          if (type == 1) return "success";
          return "正常";
        case 3:
          if (type == 1) return "warning";
          return "待审核";
        case 4:
          if (type == 1) return "danger";
          return "已下线";
        case 5:
          if (type == 1) return "info";
          return "已删除";
      }
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.fetchList();
    },
  },
};
</script>

<style lang="less" scoped>
// .card-header{
//   display: flex;
//   justify-content: space-between;
// }
.operation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  .del-btn {
    display: flex;
    align-items: center;

    .btn-text {
      height: 20px;
      line-height: 20px;
      margin-right: 22px;
      padding-right: 16px;
      border-right: 1px solid #dcdfe6;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #606266;
      span {
        font-weight: 600;
        font-size: 18px;
        color: #303133;
        margin: 0 4px;
      }
    }
  }
}
</style>
