<template>
  <el-dialog title="提示"
             :visible.sync="dialogVisible"
             width="500px">
    <el-form :model="dataForm"
             ref="dataForm"
             :rules="rules"
             label-width="100px"
             size="small">
      <el-form-item label="考研年份"
                    prop="apply_year">
        <el-select v-model="dataForm.apply_year"
                   placeholder="请选择"
                   style="width: 100%"
                   @change="yearChange">
          <el-option v-for="item in yearList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="报考院校"
                    prop="apply_college">
        <el-select v-model="dataForm.apply_college"
                   filterable
                   collapse-tags
                   placeholder="请选择"
                   style="width: 100%"
                   value-key="code"
                   @change="collegeChange">
          <el-option v-for="item in $store.state.collegeList"
                     :key="item.code"
                     :label="item.name"
                     :value="{ name: item.name, code: item.code }">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="报考专业"
                    prop="major">
        <el-select filterable
                   placeholder="请选择"
                   v-model="dataForm.major"
                   @change="majorChoose"
                   value-key="code"
                   style="width: 100%">
          <el-option v-for="(item, index) in majorOptions"
                     :key="index"
                     :label="`（${item.code}）${item.allnames}`"
                     :value="{ name: item.allnames, code: item.code }">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="报考学院"
                    prop="apply_academy">
        <el-select filterable
                   placeholder="请选择"
                   v-model="dataForm.apply_academy"
                   @change="academyChoose"
                   value-key="code"
                   style="width: 100%">
          <el-option v-for="(item, index) in academyOptions"
                     :key="index"
                     :label="item.academy_name"
                     :value="{ name: item.academy_name, code: item.academy_code }">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="辅导科目"
                    prop="subjects">
        <el-select v-model="dataForm.subjects"
                   multiple
                   placeholder="请选择"
                   style="width: 100%"
                   value-key="code"
                   :multiple-limit="4">
          <el-option v-for="(item, index) in subjectsOptions"
                     :key="item.id"
                     :label="item.subject_name"
                     :value="{ name: item.subject_name, code: item.subject_code }">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="辅导小时"
                    prop="lesson_hours">
        <el-input v-model="dataForm.lesson_hours"
                  placeholder="请输入辅导小时">
          <template slot="append">小时</template></el-input>
      </el-form-item>

      <el-form-item label="研究生时薪<="
                    prop="demand_max_hour_amount">
        <el-input v-model="dataForm.demand_max_hour_amount"
                  placeholder="请输入研究生时薪">
          <template slot="append">元/小时</template></el-input>
      </el-form-item>

      <el-form-item label="考试方向"
                    prop="apply_direction">
        <el-select value-key="code"
                   v-model="dataForm.apply_direction"
                   placeholder="请选择考试方向"
                   @change="$forceUpdate()"
                   style="width: 100%">
          <el-option v-for="(item, index) in directionsOptions"
                     :key="index"
                     :label="`（${item.direction_code}）${item.direction}`"
                     :value="{ code: item.direction_code, name: item.direction }">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="辅导要求">
        <el-checkbox-group @change="major_status"
                           v-model="dataForm.demand_academy_major_status">
          <el-checkbox :label="0">限与学员同一个学院专业</el-checkbox>
        </el-checkbox-group>

        <el-checkbox v-model="dataForm.demand_same_reference_book_status"
                     :checked="!!dataForm.demand_same_reference_book_status"
                     @change="book_status">允许其他院校研究生根据参考书或者大纲辅导</el-checkbox>
        <!-- <el-radio-group v-model="dataForm.radio">
          <el-radio :label="1">限与学员同一个学院专业</el-radio>
          <el-radio :label="2" style="margin-top: 6px"
            >允许其他院校研究生根据参考书或者大纲辅导</el-radio
          >
        </el-radio-group> -->
      </el-form-item>
      <el-form-item label="其他要求"
                    prop="demand_extra_info">
        <el-input v-model="dataForm.demand_extra_info"
                  placeholder="请输入其他要求"
                  type="textarea"
                  :rows="2" />
      </el-form-item>
    </el-form>

    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="submit()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { crmUrl, adminUrl } from '@/utils/helper.js'
import { majorlist, subjectlist } from '@/request/api.js'
export default {
  data() {
    return {
      dialogVisible: false,
      orderId: '',
      dataForm: {
        apply_college: null,
        subjects: [],
      },
      majorOptions: [],
      academyOptions: [],
      subjectsOptions: [],
      directionsOptions: [],
      yearList: [
        {
          label: '2025年考研',
          value: '2025',
        },
        {
          label: '2024年考研',
          value: '2024',
        },
      ],
      rules: {
        apply_year: [
          { required: true, message: '考研年份不能为空', trigger: 'blur' },
        ],
        apply_college: [
          { required: true, message: '报考院校不能为空', trigger: 'blur' },
        ],
        major: [
          { required: true, message: '报考专业不能为空', trigger: 'blur' },
        ],
        apply_academy: [
          { required: true, message: '报考学院不能为空', trigger: 'blur' },
        ],
        subjects: [
          { required: true, message: '辅导科目不能为空', trigger: 'blur' },
        ],
        lesson_hours: [
          { required: true, message: '辅导小时不能为空', trigger: 'blur' },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: '输入最多两位小数的数字',
          },
        ],
        demand_max_hour_amount: [
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: '输入最多两位小数的数字',
          },
        ],
      },
    }
  },
  created() {
    this.$store.dispatch('getAllColleges')
  },
  methods: {
    open(row) {
      console.log('rowwww', row)
      this.dataForm = {}
      let subjectList = []
      if (row.apply_subject_codes && row.apply_subject_names) {
        let nameList = row.apply_subject_names.split(',')
        let codeList = row.apply_subject_codes.split(',')
        nameList.map((item, index) => {
          subjectList.push({
            name: item,
            code: codeList[index],
          })
        })
      }

      this.dataForm = {
        id: row.id,
        apply_year: row.apply_year,
        apply_college: {
          name: row.apply_college_name,
          code: row.apply_college_code,
        },
        major: {
          name: row.apply_major_name,
          code: row.apply_major_code,
        },
        apply_academy: {
          name: row.apply_academy_name,
          code: row.apply_academy_code,
        },
        subjects: subjectList,
        apply_direction: {
          name: row.demand_direction_names,
          code: row.demand_direction_codes,
        },
        lesson_hours: row.lesson_hours,
        demand_max_hour_amount: row.demand_max_hour_amount,
        demand_academy_major_status: row.demand_academy_major_status
          ? true
          : false,
        demand_same_reference_book_status: row.demand_same_reference_book_status
          ? true
          : false,
        demand_extra_info: row.demand_extra_info,
        extend_reamrk: row.reamrk || '',
        ref_allocation_order_id: row.ref_allocation_order_id || '',
      }

      this.getMajorOptions(row.apply_college_code)
      this.getMajorInfo(
        row.apply_college_code,
        row.apply_major_code,
        row.apply_academy_code
      )

      this.getSubjectOptions(
        row.apply_college_code,
        row.apply_academy_code,
        row.apply_major_code
      )
      this.dialogVisible = true
    },

    major_status(e) {
      if (e) {
        this.dataForm.demand_same_reference_book_status = false
      }
    },
    book_status(e) {
      if (e) {
        this.dataForm.demand_academy_major_status = false
      }
    },
    yearChange(e) {
      this.dataForm.subjects = null
      let flag =
        this.subjectsOptions[0] && this.subjectsOptions[0].subject_code == '999'
      if (e == '2024' && !flag) {
        this.subjectsOptions.unshift({
          id: 999,
          subject_code: '999',
          subject_name: '复试课程',
        })
      } else if (flag) {
        this.subjectsOptions.shift()
      }
    },
    submit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          let time = new Date().getTime() + 7 * 24 * 60 * 60 * 1000
          let str = crmUrl()
          console.log('time', time)
          if (this.dataForm.id) {
            this.$axios({
              url: `${str}/api/v1/sharePlatform/findOrder`,
              method: 'put', //可以省略 不写默认是get
              data: {
                id: this.dataForm.id,
                apply_year: this.dataForm.apply_year,
                apply_college_code: this.dataForm.apply_college.code,
                apply_college_name: this.dataForm.apply_college.name,
                apply_major_code: this.dataForm.major.code,
                apply_major_name: this.dataForm.major.name,
                apply_academy_code: this.dataForm.apply_academy.code,
                apply_academy_name: this.dataForm.apply_academy.name,
                apply_subject_codes: this.dataForm.subjects
                  .map((v) => {
                    return v.code
                  })
                  .join(','),
                apply_subject_names: this.dataForm.subjects
                  .map((v) => {
                    return v.name
                  })
                  .join(','),
                lesson_hours: this.dataForm.lesson_hours,

                demand_direction_codes: this.dataForm.apply_direction
                  ? this.dataForm.apply_direction.code
                  : null,
                demand_direction_names: this.dataForm.apply_direction
                  ? this.dataForm.apply_direction.name
                  : null,

                demand_max_hour_amount:
                  this.dataForm.demand_max_hour_amount || null,
                demand_academy_major_status: this.dataForm
                  .demand_academy_major_status
                  ? 1
                  : 0,
                demand_extra_info: this.dataForm.demand_extra_info || null,
                demand_same_reference_book_status: this.dataForm
                  .demand_same_reference_book_status
                  ? 1
                  : 0,
              },
            }).then((response) => {
              if (response.data.code == 0) {
                this.$message.success('修改成功!')
                this.dialogVisible = false
                this.$emit('submit')
              }
              // console.log("response:", response);
            })
          } else {
            this.$axios({
              url: `${str}/api/v1/sharePlatform/findOrder`,
              method: 'post', //可以省略 不写默认是get
              data: {
                auto_cancel_time: time,
                apply_year: this.dataForm.apply_year,
                apply_college_code: this.dataForm.apply_college.code,
                apply_college_name: this.dataForm.apply_college.name,
                apply_major_code: this.dataForm.major.code,
                apply_major_name: this.dataForm.major.name,
                apply_academy_code: this.dataForm.apply_academy.code,
                apply_academy_name: this.dataForm.apply_academy.name,
                apply_subject_codes: this.dataForm.subjects
                  .map((v) => {
                    return v.code
                  })
                  .join(','),
                apply_subject_names: this.dataForm.subjects
                  .map((v) => {
                    return v.name
                  })
                  .join(','),
                lesson_hours: this.dataForm.lesson_hours,
                demand_max_hour_amount:
                  this.dataForm.demand_max_hour_amount || null,
                demand_direction_codes: this.dataForm.apply_direction
                  ? this.dataForm.apply_direction.code
                  : null,

                demand_direction_names: this.dataForm.apply_direction
                  ? this.dataForm.apply_direction.name
                  : null,
                demand_extra_info: this.dataForm.demand_extra_info || null,

                demand_same_reference_book_status: this.dataForm
                  .demand_same_reference_book_status
                  ? 1
                  : 0,
                demand_academy_major_status: this.dataForm
                  .demand_academy_major_status
                  ? 1
                  : 0,
                extend_reamrk: this.dataForm.reamrk,
                ref_allocation_order_id: this.dataForm.ref_allocation_order_id,
              },
            }).then((res) => {
              if (res.data.code == 0) {
                this.$message.success('同步成功!')
                this.dialogVisible = false
                this.$emit('submit')
              }
            })
          }
        }
      })
    },
    collegeChange(e) {
      this.dataForm.major = null
      this.majorOptions = []
      this.dataForm.apply_academy = null
      this.academyOptions = []
      this.dataForm.subjects = null
      this.subjectsOptions = []
      this.dataForm.apply_direction = null
      this.directionsOptions = []
      this.getMajorOptions(e.code)
    },

    getMajorOptions(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      }
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `${v.name}`,
          }
        })
      })
    },

    majorChoose(e) {
      this.dataForm.apply_academy = null
      this.academyOptions = []
      this.dataForm.subjects = null
      this.subjectsOptions = []
      this.dataForm.apply_direction = null
      this.directionsOptions = []
      this.getMajorInfo(this.dataForm.apply_college.code, e.code)
    },

    getMajorInfo(college_code, major_code, academy_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get', //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data
        this.academyOptions = res.data.academys
        if (academy_code) {
          this.directionsOptions = this.academyOptions.find(
            (item) => item.academy_code == academy_code
          ).directions
        }
      })
    },

    academyChoose(e) {
      this.dataForm.subjects = null
      this.subjectsOptions = []
      this.dataForm.apply_direction = null
      this.directionsOptions = []
      this.getSubjectOptions(
        this.dataForm.apply_college.code,
        e.code,
        this.dataForm.major.code
      )
      this.directionsOptions = this.academyOptions.find(
        (item) => item.academy_code == e.code
      ).directions
    },

    getSubjectOptions(college_code, academy_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        academy_code: academy_code,
        major_code: major_code,
      }
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: '999',
          subject_name: '复试课程',
        }
        this.subjectsOptions = res.data.data.list || []
        console.log(' this.subjectsOptions', this.subjectsOptions)
        if (this.dataForm.apply_year == '2024') {
          this.subjectsOptions.unshift(obj)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
</style>