<template>
  <div>
    <el-dialog
    append-to-body
top="5vh"
      title="修改课时费"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="440px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item label="当前课时费：">
          <span class="item-value">
            {{now_amount}}元/小时
          </span>
        </el-form-item>
        <el-form-item
          label="课时费："
          prop="lesson_hour_amount"
        >
          <el-input
            placeholder="请输入课时费"
            v-model="dataForm.lesson_hour_amount"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          >
            <span slot="append">元/小时</span>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose()">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  teacherEdit,
  teacherOne,
  commonHandleLogCreate

} from "@/request/api";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
    class_id: {
    
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        lesson_hour_amount: [{ required: true, message: "请输入课时费", trigger: "blur" },
        { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }],
      },
      now_amount: 0,
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },

  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    fetchDetail() {
      teacherOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data
        if (res.data.grades) {
          this.now_amount = res.data.grades.find(v => { return v.id == this.class_id }).teacher_lesson_hour_amount || 0
        }
      });
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let arr = this.dataForm.grades.filter(v => { return v.id != this.class_id })
          arr.push({
            id: this.class_id,
            teacher_lesson_hour_amount: this.dataForm.lesson_hour_amount,
            teacher_grade_status: this.dataForm.grades.find(t => {
              return t.id == this.class_id
            }).teacher_grade_status
          })

          let query = {
            id: this.dataForm.id,
            arr_grade: arr.map(v => {
              return {
                grade_id: v.id,
                teacher_lesson_hour_amount: v.teacher_lesson_hour_amount,
                teacher_grade_status: v.teacher_grade_status,
              }
            })
          }
          teacherEdit(query).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.createLog()
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    createLog() {
      if (this.now_amount == this.dataForm.lesson_hour_amount) {
        this.$emit('submit')
        this.emitClose()
        return
      }
      let query = {
        ref_id: this.class_id,
        handle_type: 0,
        handle_child_type: 6,
        handle_content: `将${this.dataForm.realname}老师的课时费由“${this.now_amount}元/小时”变更为“${this.dataForm.lesson_hour_amount}元/小时”`,
      }
      commonHandleLogCreate(query).then((res) => {
        if (res.data.code == 0) {
          this.$emit('submit')
          this.emitClose()
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
</style>