<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>服务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/service/serviceorder' }">学员管理</el-breadcrumb-item>
        <el-breadcrumb-item>跟进记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card class="operation-card">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
      >
        <el-form-item label="搜索">
          <el-input
            v-model="formInline.user"
            placeholder="搜索 "
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <ScreenRecord></ScreenRecord> -->
    </el-card>
    <el-card style="margin-top: 10px">
      <div class="card-header">
        <TableTittle :title="tableType==1?'跟进记录':'操作记录'"></TableTittle>
        <el-radio-group
          v-model="tableType"
          size="medium"
          @change="getListChange"
        >
          <el-radio-button :label="1">跟进记录</el-radio-button>
          <el-radio-button :label="2">操作记录</el-radio-button>
        </el-radio-group>
      </div>
      <!-- 老系统跟进记录 开始 -->
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        v-if="tableType == 1"
      >
        <el-table-column
          prop="teachDate"
          label="上课日期"
        >
        </el-table-column>
        <el-table-column
          prop="subjects"
          label="科目"
        >
        </el-table-column>
        <el-table-column
          prop="hours"
          label="课时"
        >
          <template slot-scope="scope">
            <span>{{scope.row.hours}}小时</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="内容"
        >
        </el-table-column>
        <el-table-column
          prop="content"
          label="学生评价"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.feedback">{{Object.keys(scope.row.feedback).length?scope.row.feedback.text:'无'}}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createdAt"
          label="创建日期"
        >
        </el-table-column>
      </el-table>
      <!-- 老系统跟进记录表格 结束 -->
      <!-- 跟进内容表格 开始 -->
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        v-if="tableType == 2"
      >
        <el-table-column
          prop="status"
          label="学员状态"
          width="172"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.status == 0">无</span>
            <span v-show="scope.row.status == 1">待定校</span>
            <span v-show="scope.row.status == 2">待推送</span>
            <span v-show="scope.row.status == 3">正在对接</span>
            <span v-show="scope.row.status == 4">正在匹配</span>
            <span v-show="scope.row.status == 5">匹配成功</span>
            <span v-show="scope.row.status == 6">匹配失败</span>
            <span v-show="scope.row.status == 7">开始上课</span>
            <span v-show="scope.row.status == 8">正在上课</span>
            <span v-show="scope.row.status == 9">课程结束</span>
            <span v-show="scope.row.status == 10">课程终止</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="跟进内容"
          show-overflow-tooltip
          align="left"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.change == null">
              {{ scope.row.content }}</span>
            <div v-else-if="
                scope.row.change.before == null && scope.row.change.after
              ">
              <span>创建了此学员</span>
            </div>
            <span v-else>
              <span v-show="
                  scope.row.change.before.nickname ||
                  scope.row.change.after.nickname
                ">将&nbsp;<span class="changename">昵称</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.nickname | fomateAll
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.nickname | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.student_realname ||
                  scope.row.change.after.student_realname
                ">将&nbsp;<span class="changename">姓名</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.student_realname | fomateAll
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.student_realname | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.student_phone ||
                  scope.row.change.after.student_phone
                ">将&nbsp;<span class="changename">手机号</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.student_phone | fomateAll
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.student_phone | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.student_wechat ||
                  scope.row.change.after.student_wechat
                ">将&nbsp;<span class="changename">微信号</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.student_wechat | fomateAll
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.student_wechat | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.student_qq ||
                  scope.row.change.after.student_qq
                ">将&nbsp;<span class="changename">QQ号</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.student_qq | fomateAll
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.student_qq | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.tags || scope.row.change.after.tags
                ">将&nbsp;<span class="changename">学员标签</span><span class="changetext">&nbsp;{{ scope.row.change.before.tags | fomateAll }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.tags | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.status ||
                  scope.row.change.after.status
                ">将&nbsp;<span class="changename">学员状态</span><span class="changetext">&nbsp;{{ scope.row.change.before.status | fomateAll }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.status | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.apply_year ||
                  scope.row.change.after.apply_year
                ">将&nbsp;<span class="changename">考研年份</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.apply_year | fomateAll
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.apply_year | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.apply_major_name ||
                  scope.row.change.after.apply_major_name
                ">将&nbsp;<span class="changename">意向专业</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.apply_major_name | fomateAll
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.apply_major_name | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.apply_academy_name ||
                  scope.row.change.after.apply_academy_name
                ">将&nbsp;<span class="changename">意向学院</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.apply_academy_name | fomateAll
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.apply_academy_name | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.apply_college_name ||
                  scope.row.change.after.apply_college_name
                ">将&nbsp;<span class="changename">意向院校</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.apply_college_name | fomateAll
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.apply_college_name | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.products ||
                  scope.row.change.after.products
                ">将&nbsp;<span class="changename">意向课程</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.products | formateproducts
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.products | formateproducts
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.subjects ||
                  scope.row.change.after.subjects
                ">将&nbsp;<span class="changename">意向科目</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.subjects | formatesubjects
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.subjects | formatesubjects
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.source ||
                  scope.row.change.after.source
                ">将&nbsp;<span class="changename">学员来源</span><span class="changetext">&nbsp;{{ scope.row.change.before.source | fomateAll }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.source | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.remark ||
                  scope.row.change.after.remark
                ">将&nbsp;<span class="changename">备注</span><span class="changetext">&nbsp;{{ scope.row.change.before.remark | fomateAll }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.remark | fomateAll
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.watcher ||
                  scope.row.change.after.watcher
                ">将&nbsp;<span class="changename">负责人</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.watcher | formatewatcher
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.watcher | formatewatcher
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.manage_watcher ||
                  scope.row.change.after.manage_watcher
                ">将&nbsp;<span class="changename">总端负责人</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.manage_watcher | formateManage_watcher
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.manage_watcher | formateManage_watcher
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.audit_status ||
                  scope.row.change.after.audit_status
                ">将&nbsp;<span class="changename">审核状态</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.audit_status | formateaudit_status
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.audit_status | formateaudit_status
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.service_status ||
                  scope.row.change.after.service_status
                ">将&nbsp;<span class="changename">审核状态</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.service_status
                      | formateservice_status
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.service_status | formateservice_status
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.service_hours ||
                  scope.row.change.after.service_hours
                ">将&nbsp;<span class="changename">课时</span><span class="changetext">&nbsp;{{
                    scope.row.change.before.service_hours +'小时'
                  }}</span>&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.service_hours +'小时'
                }}</span>；</span>
              <span v-show="
                  scope.row.change.before.old_id ||
                  scope.row.change.after.old_id
                ">订单正在对接</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="creator"
          label="跟进人"
          width="158"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.creator">{{ scope.row.creator.alias }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="跟进日期"
          width="258"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}</span>
          </template>
        </el-table-column>
        <!--        <el-table-column label="操作" width="194" fixed="right">
          <template>
            <el-link type="danger" :underline="false">删除</el-link>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 跟进内容表格 结束 -->
      <!-- 内容修改表格 结束 -->
      <div
        class="block"
        v-if="tableType==2"
      >
        <el-pagination
          background
          layout="total, prev, pager, next, sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>
    <el-dialog
top="5vh"
      title="跟进内容"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-descriptions
        column="1"
        :labelStyle="labelStyle"
      >
        <el-descriptions-item label="跟进内容">{{
          content.content
        }}</el-descriptions-item>
      </el-descriptions>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TableTittle from "../../../../components/TableTittle/TableTittle.vue";
import { companyserviceadvancelist, crmOutrecorddetail } from "../../../../request/api";
export default {
  components: {
    TableTittle,
  },
  data() {
    return {
      studentInfo: {},
      formInline: {},
      tableData: [],
      dialogVisible: false,
      content: {},
      labelStyle: {
        width: "100px",
      },
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        order_id: "",
      },
      tableType: 1,
    };
  },
  mounted() {
    this.studentInfo = this.$route.query;
    this.query.order_id = this.studentInfo.id;
    this.getList();
  },
  filters: {
    formateproducts: function (val) {
      if (val == null || val == "") {
        return "无";
      }
      val = JSON.parse(val);
      var str = "";
      val.forEach((item) => {
        str += item.product_name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formatewatcher: function (val) {
      if (val == null || val == "") {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.alias + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formateManage_watcher: function (val) {
      if (val == null || val == "") {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.nickname + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formatesubjects: function (val) {
      if (val == null || val == "") {
        return "无";
      }
      val = JSON.parse(val);
      var str = "";
      val.forEach((item) => {
        str += item.subject_name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formateservice_status: function (val) {
      switch (val) {
        case 0:
          return "无";
        case 1:
          return "待定校";
        case 2:
          return "待推送";
        case 3:
          return "正在对接";
        case 4:
          return "正在匹配";
        case 5:
          return "匹配成功";
        case 6:
          return "匹配失败";
        case 7:
          return "开始上课";
        case 8:
          return "正在上课";
        case 9:
          return "课程结束";
        case 10:
          return "课程终止";
        default:
          break;
      }
    },
    formateaudit_status: function (val) {
      switch (val) {
        case 0:
          return "待审核";
        case 1:
          return "审核通过";
        case 2:
          return "审核不通过";
        default:
          break;
      }
    },
    fomateAll: function (val) {
      if (!val) {
        return "无";
      } else {
        return val;
      }
    },
  },
  methods: {
    getListChange() {
      this.tableData = []
      this.getList()
    },
    //页面列表刷新
    getList() {
      // eslint 会对这种情况做校验，现在必须 使用花括号将代码块确定具体的作用域
      switch (this.tableType) {
        case 1:
          {
            this.getOldCRMList()
          }
          break;
        case 2:
          {
            let query = this.query;
            companyserviceadvancelist(query).then((res) => {
              this.tableData = res.data.data.list || [];
              this.pageTotal = res.data.data.count;
            });
          }
          break;
        default:
          break;
      }
    },
    getOldCRMList() {
      if (!this.$route.query.old_id) {
        return false
      }
      let query = {
        id: this.$route.query.old_id,
        newId: this.$route.query.id,
      }
      crmOutrecorddetail(query).then((res) => {
        this.tableData = res.data.data.data.records || [];
      });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    radioChange(e) {
      console.log(e);
    },
    checkStudentChange(item) {
      this.dialogVisible = true;
      this.content = item;
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 35px;
}
.operation-card {
  width: 100%;
  margin-top: 10px;
}
.star {
  width: 18px;
  height: 18px;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin: 16px 0px;
}
.card-header {
  display: flex;
  justify-content: space-between;
}
.changename {
  font-weight: 900;
}
.changetext {
  color: #409eff;
}
</style>