<template>
  <div>
    <el-dialog
top="5vh"
      title="账单状态"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="当前状态："
          prop="name"
        >
        <div class="status"  v-show="content.status == 0">
           <el-tag
              size="small"
            >待确定</el-tag>
            <el-checkbox v-model="isCheck">待支付</el-checkbox>
        </div>
         <div class="status"  v-show="content.status == 1">
           <el-tag
              size="small"
            >待支付</el-tag>
            <el-checkbox v-model="isCheck">已支付</el-checkbox>
        </div>
        <div class="status"  v-show="content.status == 2">
           <el-tag
              size="small"
            >已支付</el-tag>
            <el-checkbox v-model="isCheck">已完成</el-checkbox>
        </div>
          <!-- <div class="status">
            <el-tag
              size="small"
              v-show="content.status == 0"
            >待确定</el-tag>
            <el-tag
              size="small"
              v-show="content.status == 1"
            >待支付</el-tag>
            <el-tag
              size="small"
              v-show="content.status == 2"
            >已支付</el-tag>
            <el-tag
              size="small"
              v-show="content.status == 3"
            >已完成</el-tag>
            <el-tag
              size="small"
              v-show="content.status == 4"
            >已取消</el-tag>
            <el-checkbox v-model="isCheck">设为已支付</el-checkbox>
          </div> -->
        </el-form-item>
        <el-form-item
          label="备注："
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="dataForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  editorderlist
} from "@/request/api";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],

      },
      isCheck: false,
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    emitClose() {
      this.dataForm = {}
      this.isCheck = false
      this.$emit("close");
    },

    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if(this.content.status==0){
          this.isCheck=1
        }else if(this.content.status==1){
           this.isCheck=2
        }else if(this.content.status==2){
           this.isCheck=3
        }
        if (valid) {
          let query = {
            id: this.content.id,
            status: this.isCheck,
            remark: this.dataForm.remark
          }
          editorderlist(query).then((res) => {
            if (res.data.code == 0) {
              this.emitClose()
              this.$emit('submit')
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
</script>

<style lang="less" scoped>
.status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>