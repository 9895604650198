<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>服务</el-breadcrumb-item>
        <el-breadcrumb-item>师资匹配</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button type="primary"
                   size="small"
                   @click="openAutoDialog()"
                   :disabled="!$buttonPermission('ordermatching:create')">新建并匹配</el-button>
        <!-- <el-button type="primary" size="small" @click="openAddMatchingOrderDialog()"
          :disabled="!$buttonPermission('ordermatching:create')">新建</el-button> -->
        <el-button icon="el-icon-download"
                   @click="handleExport"
                   size="small">导出</el-button>
      </div>
    </div>
    <el-card class="operation-card">
      <el-form :inline="true"
               size="small">
        <!-- <el-form-item label="搜索">
          <el-input
            v-model="query.keyword"
            placeholder="姓名/院校/学院/专业/备注"
            @input="inputSearch"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item label="订单号">
          <el-input v-model="query.order_no"
                    placeholder="输入订单号"
                    @input="inputSearch"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="机构简称"
                      prop="cid">
          <el-select v-model="query.cid"
                     placeholder="请选择"
                     filterable
                     @change="fetchList(true)"
                     clearable>
            <el-option v-for="item in $store.state.CompanyList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="float: right">
          <el-radio-group v-model="query.archive"
                          @change="fetchList(true)">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="1">已归档</el-radio-button>
            <el-radio-button label="0">未归档</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <deviceLine style="margin-bottom: 16px"></deviceLine>

        <el-form-item label="考研年份">
          <el-select v-model="query.apply_year"
                     placeholder="请选择考研年份"
                     @change="fetchList(true)"
                     clearable>
            <el-option label="2023考研"
                       value="2023"></el-option>
            <el-option label="2024考研"
                       value="2024"></el-option>
            <el-option label="2025考研"
                       value="2025"></el-option>
            <el-option label="2026考研"
                       value="2026"></el-option>
            <el-option label="2027考研"
                       value="2027"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="订单状态">
          <el-select v-model="query.match_order_sync_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="item in filters.match_order_sync_statuses"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select v-model="query.payment_status"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option label="未支付"
                       value="0"></el-option>
            <el-option label="已支付"
                       value="1"></el-option>
            <el-option label="已退款"
                       value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账单状态">
          <el-select v-model="query.company_order_process_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option label="未绑定"
                       value="0"></el-option>
            <el-option label="账单中"
                       value="1"></el-option>
            <el-option label="已通过"
                       value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报考信息">
          <el-input v-model="query.college_info_keyword"
                    placeholder="输入报考院校/学院/专业/方向"
                    @input="inputSearch"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="辅导科目">
          <el-input v-model="query.subject_keyword"
                    placeholder="辅导科目"
                    @input="inputSearch"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="对接老师"
                      prop="manage_watcher">
          <el-select @change="inputSearch"
                     v-model="query.manage_watcher_id"
                     placeholder="请选择"
                     clearable
                     filterable>
            <el-option v-for="(item, index) in $store.state.staffOptions"
                       :key="index"
                       :label="item.nickname"
                       :value="item.id">
              <span> {{ `${item.nickname}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="匹配老师"
                      prop="allocation_manage_id">
          <el-select @change="inputSearch"
                     v-model="query.allocation_manage_id"
                     placeholder="请选择"
                     clearable
                     filterable>
            <el-option v-for="(item, index) in $store.state.staffOptions"
                       :key="index"
                       :label="item.nickname"
                       :value="item.id">
              <span> {{ `${item.nickname}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <!-- start/end_payment_time -->
        <el-form-item label="创建时间"
                      prop="create_time">
          <el-date-picker :default-time="['00:00:00', '23:59:59']"
                          format="yyyy-MM-dd HH:mm"
                          v-model="create_time"
                          type="datetimerange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="timestamp"
                          @change="fetchList(true)">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="支付时间"
                      prop="last_advance_time">
          <el-date-picker :default-time="['00:00:00', '23:59:59']"
                          format="yyyy-MM-dd HH:mm"
                          v-model="pay_time"
                          type="datetimerange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="timestamp"
                          @change="fetchList(true)">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="table-card"
             style="margin-top: 20px">
      <div class="tabulation">
        <div class="radio-group">
          <el-radio-group v-model="query.is_has_allocation_confirm"
                          @change="fetchList(true)"
                          size="small">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="0">仅关注</el-radio-button>
            <el-badge :value="confirmedCount"
                      :hidden="confirmedCount == 0">
              <el-radio-button label="1">待确认</el-radio-button>
            </el-badge>
          </el-radio-group>
        </div>
        <el-tabs v-model="activeName"
                 @tab-click="handleClick">
          <el-tab-pane label="订单列表"
                       name="first">
            <el-table :data="result"
                      :header-cell-style="{
                textAlign: 'center',
                color: '#909399',
                fontFamily: 'PingFang SC',
                fontSize: '14px',
                fontWeight: '400',
                backgroundColor: '#F2F6FC',
              }"
                      @selection-change="handleSelectionChange"
                      border>
              <el-table-column type="selection"
                               width="50"
                               align="center">
              </el-table-column>
              <el-table-column prop="date"
                               label="关注"
                               width="50"
                               fixed="left"
                               align="center">
                <template slot-scope="scope">
                  <img alt=""
                       srcset=""
                       class="star"
                       src="../../../assets/images/star-fill.png"
                       @click="followDelete(scope.row)"
                       v-show="scope.row.follow" />
                  <img src="../../../assets/images/star.png"
                       alt=""
                       srcset=""
                       class="star"
                       v-show="!scope.row.follow"
                       @click="followCreate(scope.row)" />
                </template>
              </el-table-column>
              <el-table-column prop="order_no"
                               label="订单号"
                               width="120"
                               align="center">
              </el-table-column>
              <el-table-column label="创建时间"
                               min-width="120"
                               align="center">
                <template slot-scope="scope">
                  <span>{{
                    $moment(scope.row.create_time).format("YYYY-MM-DD")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center"
                               prop="apply_year"
                               label="机构简称"
                               show-overflow-tooltip
                               width="150px">
                <template slot-scope="scope">
                  <span>{{ scope.row.company.name }}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="name"
                label="订单价格"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.order_amount + "元" }}</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="name"
                label="支付金额"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.payment_amount
                      ? scope.row.payment_amount + "元"
                      : "-"
                  }}</span>
                </template>
              </el-table-column> -->
              <el-table-column align="center"
                               prop="status"
                               label="订单状态"
                               width="80"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-tag size="small"
                          type="warning"
                          v-show="scope.row.match_order_sync_status == 1">待服务</el-tag>
                  <el-tag size="small"
                          type="warning"
                          v-show="scope.row.match_order_sync_status == 2">待对接</el-tag>
                  <el-tag size="small"
                          v-show="scope.row.match_order_sync_status == 3">匹配中</el-tag>
                  <el-tag size="small"
                          type="success"
                          v-show="scope.row.match_order_sync_status == 4">已完成</el-tag>
                  <el-tag size="small"
                          type="danger"
                          v-show="scope.row.match_order_sync_status == 5">已取消</el-tag>
                  <!--  <el-tag
              size="small"
              v-show="scope.row.status == 0"
            >无</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 1"
            >待推送</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 2"
            >正在对接</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 3"
            >正在匹配</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 4"
            >匹配成功</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 5"
            >订单完成</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 6"
            >订单取消</el-tag> -->
                </template>
              </el-table-column>
              <el-table-column align="center"
                               prop="status"
                               label="支付状态"
                               width="80"
                               type="success"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <div @click="$buttonPermission('aggregatePayment:flag')&&aggregatePayment(scope.row)">
                    <el-tag size="small"
                            type="danger"
                            v-show="scope.row.payment_status == 0">未支付</el-tag>
                    <el-tag size="small"
                            type="success"
                            v-show="scope.row.payment_status == 1">已支付</el-tag>
                    <el-tag size="small"
                            type="success"
                            v-show="scope.row.payment_status == 2">已退款</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center"
                               prop="status"
                               label="账单状态"
                               width="80"
                               type="success"
                               show-overflow-tooltip>
                <template slot-scope="scope">

                  <el-tag size="small"
                          type="danger"
                          v-show="scope.row.company_order_process_status  == 0">未绑定</el-tag>
                  <el-tag size="small"
                          type="success"
                          v-show="scope.row.company_order_process_status  == 1">账单中</el-tag>
                  <el-tag size="small"
                          type="success"
                          v-show="scope.row.company_order_process_status  == 2">已通过</el-tag>

                </template>
              </el-table-column>

              <el-table-column align="center"
                               label="推荐老师"
                               width="120"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="badge">
                    <el-link v-if="
                        scope.row.arr_teacher_info &&
                        scope.row.bind_allocation_order_id
                      "
                             type="primary"
                             :underline="false"
                             @click="openConfirmList(scope.row)">
                      {{
                        scope.row.arr_teacher_info
                          .map((item) => item.teacher_realname)
                          .join(",")
                      }}
                    </el-link>

                    <el-badge v-else-if="scope.row.bind_allocation_order_id"
                              :value="
                        scope.row.allocation_order_teacher_unconfirm_count
                      "
                              :hidden="
                        !scope.row.allocation_order_teacher_unconfirm_count
                      ">
                      <el-link type="success"
                               :underline="false"
                               @click="openConfirmList(scope.row)">确认列表</el-link>
                    </el-badge>

                    <el-link v-else-if="
                        (scope.row.match_order_sync_status == 1 ||
                          scope.row.match_order_sync_status == 2) &&
                        !scope.row.bind_allocation_order_id
                      "
                             type="warning"
                             :underline="false"
                             @click="handleToMatching(scope.row)">提交匹配</el-link>
                    <el-link v-else
                             type="info"
                             :underline="false">提交匹配</el-link>
                    <!-- <el-link
                      v-else
                      type="success"
                      :underline="false"
                      @click="openConfirmList(scope.row)"
                      >确认列表</el-link
                    > -->
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="nickname"
                               label="报考信息"
                               width="400"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.apply_year ? scope.row.apply_year + "年/" : "" }}
                  <span class="pointer"
                        @click="institutionOpen(scope.row)"
                        v-if="
                      scope.row.apply_college_name &&
                      scope.row.apply_college_code
                    ">
                    {{ `${scope.row.apply_college_name}` }}/</span>
                  <span v-if="
                      scope.row.apply_academy_name &&
                      scope.row.apply_academy_code
                    ">
                    {{
                      `(${scope.row.apply_academy_code})${scope.row.apply_academy_name}`
                    }}/</span>
                  <span v-if="
                      scope.row.apply_major_name && scope.row.apply_major_code
                    ">
                    {{
                      `(${scope.row.apply_major_code})${scope.row.apply_major_name}`
                    }}/</span>

                  <span>
                    {{
                      scope.row.apply_direction_code
                        ? `(${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
                        : "不限"
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="subjects"
                               label="辅导科目"
                               width="160"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.subjects | formateSub }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center"
                               prop="lesson_hours"
                               label="报名课时"
                               width="100"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.lesson_hours + "小时" }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="remark"
                               label="匹配备注"
                               width="280"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
                  <span v-else>无</span>
                </template>
              </el-table-column>

              <el-table-column align="center"
                               label="匹配老师"
                               width="130"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{
                    getManageList(scope.row.bind_allocation_order_manage_list)
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center"
                               label="对接老师"
                               width="130"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{
                    scope.row.manage_watcher | formateManage_Watcher
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="更新时间"
                               align="center"
                               width="150">
                <template slot-scope="scope">
                  {{
                    scope.row.update_time
                      ? $moment(scope.row.update_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column align="center"
                               prop="name"
                               label="操作"
                               width="120"
                               fixed="right">
                <template slot-scope="scope">
                  <div class="templateLink">
                    <el-link type="primary"
                             :underline="false"
                             @click="openEditMatchingOrderDialog(scope.row)"
                             :disabled="!$buttonPermission('ordermatching:edit')">变更</el-link>
                    <el-link type="success"
                             :underline="false"
                             @click="changeStatus(scope.row)"
                             v-if="scope.row.match_order_sync_status == 5">恢复</el-link>
                    <el-link v-else
                             type="danger"
                             :disabled="
                        [4, 5].includes(scope.row.match_order_sync_status)
                      "
                             @click="filterArr(scope.row)">终止</el-link>

                    <!-- <el-button
          size="mini"
          type="danger"
         
          :disabled="!$buttonPermission('ordermatching:batch')"
          >终止</el-button
        > -->
                    <!--    <el-link
                type="info"
                :underline="false"
                v-if="scope.row.old_id"
              >已同步</el-link>
              <el-link
                type="primary"
                :underline="false"
                @click="exportData(scope.row)"
                v-else
              >同步</el-link> -->
                    <!-- <el-link
                      type="primary"
                      :underline="false"
                      @click="openEditMatchingOrderDialog(scope.row)"
                      :disabled="!$buttonPermission('ordermatching:edit')"
                      v-if="scope.row.match_order_sync_status != 5"
                      >编辑</el-link
                    >
                    <el-link
                      type="success"
                      :underline="false"
                      @click="changeStatus(scope.row)"
                      v-else
                      >恢复</el-link
                    > -->
                    <!-- <el-link
                      type="primary"
                      :underline="false"
                      @click="
                        $buttonPermission('aggregatePayment:flag') &&
                          aggregatePayment(scope.row)
                      "
                      >收款</el-link
                    >-->

                  </div>
                </template>
              </el-table-column>

              <!-- <el-table-column prop="nickname" label="师资信息" width="130">
                <template slot-scope="scope">
                  <div class="link-template">
                    <div
                      class="red-bradge"
                      v-if="
                        scope.row.manage_unread_status == 1 &&
                        scope.row.status != 1
                      "
                    >
                      <span>new</span>
                    </div>
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="openCheckResultDialog(scope.row)"
                      v-if="scope.row.result == null"
                      :disabled="
                        scope.row.match_order_sync_status == 5 ||
                        !$buttonPermission('ordermatching:fill')
                      "
                      >填写</el-link
                    >
                    <el-link
                      type="success"
                      :underline="false"
                      :disabled="!$buttonPermission('ordermatching:fill')"
                      v-else
                      @click="openCheckResultDialog(scope.row)"
                      >填写</el-link
                    >
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="goRecord(scope.row)"
                      :disabled="
                        scope.row.match_order_sync_status == 5 ||
                        !$buttonPermission('ordermatching:record:view')
                      "
                      >跟进</el-link
                    >
                  </div>
                </template>
              </el-table-column> -->
              <!-- <el-table-column prop="create_time" label="创建时间" width="120">
                <template slot-scope="scope">
                  {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
                </template>
              </el-table-column> -->
              <!--   <el-table-column
          label="负责人"
          width="130"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.watcher | formateWatcher }}</span>
          </template>
        </el-table-column> -->

              <!-- <el-table-column
                prop="apply_college_name"
                label="报考院校"
                width="150"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    class="pointer"
                    @click="institutionOpen(scope.row)"
                    v-if="
                      scope.row.apply_college_name &&
                      scope.row.apply_college_code
                    "
                  >
                    {{ `${scope.row.apply_college_name}` }}</span
                  >
                  <span v-else> 无</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="报考学院"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.apply_academy_name &&
                      scope.row.apply_academy_code
                    "
                  >
                    {{
                      `(${scope.row.apply_academy_code})${scope.row.apply_academy_name}`
                    }}</span
                  >
                  <span v-else> 无</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="name"
                label="报考专业"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.apply_major_name && scope.row.apply_major_code
                    "
                  >
                    {{
                      `(${scope.row.apply_major_code})${scope.row.apply_major_name}`
                    }}</span
                  >
                  <span v-else> 无</span>
                </template>
              </el-table-column>
              <el-table-column
                label="考试方向"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.apply_direction_code &&
                      scope.row.apply_direction_name
                    "
                  >
                    {{
                      `(${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
                    }}</span
                  >
                  <span v-else>无</span>
                </template>
              </el-table-column> -->
              <!--               
              <el-table-column label="对接人" width="130" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{
                    scope.row.manage_watcher | formateManage_Watcher
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="creator" label="创建人" width="200">
                <template slot-scope="scope">
                  <span v-if="scope.row.creator">
                    {{ scope.row.creator.alias }}(Crm端)
                  </span>
                  <span v-else-if="scope.row.manage_creator">
                    {{ scope.row.manage_creator.realname }}(Manage端)
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="creator" label="支付方式" width="90">
                <template slot-scope="scope">
                  <span
                    >{{
                      scope.row.payment_type
                        ? formatePayType(scope.row.payment_type)
                        : "/"
                    }}
                  </span>
                </template>
              </el-table-column> -->
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="数据列表"
                       name="second">
            <el-table :data="result"
                      :header-cell-style="{
                textAlign: 'center',
                color: '#909399',
                fontFamily: 'PingFang SC',
                fontSize: '14px',
                fontWeight: '400',
                backgroundColor: '#F2F6FC',
              }"
                      @selection-change="handleSelectionChange"
                      border>
              <el-table-column type="selection"
                               width="50"
                               align="center">
              </el-table-column>

              <el-table-column prop="date"
                               label="关注"
                               width="50"
                               fixed="left"
                               align="center">
                <template slot-scope="scope">
                  <img alt=""
                       srcset=""
                       class="star"
                       src="../../../assets/images/star-fill.png"
                       @click="followDelete(scope.row)"
                       v-show="scope.row.follow" />
                  <img src="../../../assets/images/star.png"
                       alt=""
                       srcset=""
                       class="star"
                       v-show="!scope.row.follow"
                       @click="followCreate(scope.row)" />
                </template>
              </el-table-column>
              <el-table-column prop="order_no"
                               label="订单号"
                               width="150"
                               align="center">
              </el-table-column>
              <el-table-column label="创建时间"
                               min-width="160"
                               align="center">
                <template slot-scope="scope">
                  <span>{{
                    $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center"
                               prop="apply_year"
                               label="机构简称"
                               show-overflow-tooltip
                               width="150px">
                <template slot-scope="scope">
                  <span>{{ scope.row.company.name }}</span>
                </template>
              </el-table-column>

              <el-table-column align="center"
                               prop="status"
                               label="订单状态"
                               width="80"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-tag size="small"
                          type="warning"
                          v-show="scope.row.match_order_sync_status == 1">待服务</el-tag>
                  <el-tag size="small"
                          type="warning"
                          v-show="scope.row.match_order_sync_status == 2">待对接</el-tag>
                  <el-tag size="small"
                          v-show="scope.row.match_order_sync_status == 3">匹配中</el-tag>
                  <el-tag size="small"
                          type="success"
                          v-show="scope.row.match_order_sync_status == 4">已完成</el-tag>
                  <el-tag size="small"
                          type="danger"
                          v-show="scope.row.match_order_sync_status == 5">已取消</el-tag>
                </template>
              </el-table-column>
              <el-table-column align="center"
                               prop="status"
                               label="支付状态"
                               width="80"
                               type="success"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <div>
                    <el-tag size="small"
                            type="danger"
                            v-show="scope.row.payment_status == 0">未支付</el-tag>
                    <el-tag size="small"
                            type="success"
                            v-show="scope.row.payment_status == 1">已支付</el-tag>
                    <el-tag size="small"
                            type="success"
                            v-show="scope.row.payment_status == 2">已退款</el-tag>
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="center"
                               label="订单价格"
                               width="160"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <div v-if="scope.row.upFlag">
                    <el-input style="width: 100px"
                              min="0"
                              v-model="scope.row.ref_hour_income_company_amount"
                              placeholder="请输入"
                              type="number"></el-input>
                    <i style="margin-left: 5px"
                       class="el-icon-finished"
                       @click="upSignContract(scope.row)"></i>
                  </div>
                  <div v-else>
                    {{ scope.row.ref_hour_income_company_amount || 0 }}元
                    <i class="el-icon-edit-outline"
                       @click="upSign(scope.row)"></i>
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="center"
                               label="已收费用"
                               width="120"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{
                    scope.row.ref_exist_company_order_amount + "元"
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column align="center"
                               label="其他费用"
                               width="160"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <div v-if="scope.row.restFlag">
                    <el-input style="width: 100px"
                              min="0"
                              v-model="scope.row.ref_match_other_company_amount"
                              placeholder="请输入"
                              type="number"></el-input>
                    <i style="margin-left: 5px"
                       class="el-icon-finished"
                       @click="upRestMoney(scope.row)"></i>
                  </div>
                  <div v-else>
                    {{ scope.row.ref_match_other_company_amount || 0 }}元
                    <i class="el-icon-edit-outline"
                       @click="upRest(scope.row)"></i>
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="center"
                               label="当前利润"
                               width="120"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.ref_pure_earn_amount + "元" }}</span>
                </template>
              </el-table-column>

              <el-table-column label="最近收款时间"
                               width="150"
                               align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.ref_last_company_order_time
                      ? $moment(scope.row.ref_last_company_order_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "-"
                  }}
                </template>
              </el-table-column>

              <el-table-column prop="name"
                               label="操作"
                               width="160"
                               fixed="right"
                               align="center">
                <template slot-scope="scope">
                  <div class="templateLink">
                    <el-link type="primary"
                             :underline="false"
                             @click="orderReceiptRecord(scope.row.id)">收款记录</el-link>
                    <el-link type="primary"
                             :underline="false"
                             @click="openRefundOrderDialog(scope.row)"
                             v-if="
                        scope.row.payment_status == 1 &&
                        $buttonPermission('ordermatching:refund')
                      ">退款</el-link>
                    <el-link type="info"
                             :underline="false"
                             v-else>退款</el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form :inline="true" label-width="70px" size="small">
          <el-form-item label="只看关注">
            <el-switch v-model="query.follow" :active-value="1" inactive-value=" " @change="fetchList(true)">
            </el-switch>
          </el-form-item>
          <el-form-item>
            <el-badge :value="uncheck" :hidden="uncheck == 0">
              <el-checkbox v-model="query.is_has_allocation_confirm" label="待确认师资" border :true-label="1" :false-label="0"
                @change="fetchList(true)"></el-checkbox>
            </el-badge>
          </el-form-item>
        </el-form>
      </div> -->

      <div style="margin-top: 20px">
        <el-button size="mini"
                   type="primary"
                   @click="chooseAllEdit(1, '您确定要归档吗')"
                   v-show="query.archive == 0 && query.archive != ''"
                   :disabled="!$buttonPermission('ordermatching:batch')">归档</el-button>
        <el-button size="mini"
                   type="primary"
                   @click="chooseAllEdit(0, '您确定要取消归档吗')"
                   v-show="query.archive == 1"
                   :disabled="!$buttonPermission('ordermatching:batch')">取消归档</el-button>
        <el-button size="mini"
                   type="danger"
                   @click="chooseAllDelete"
                   :disabled="!$buttonPermission('ordermatching:delete')">删除</el-button>
        <el-button size="mini"
                   type="danger"
                   @click="filterArrList"
                   :disabled="!$buttonPermission('ordermatching:batch')">终止</el-button>
        <el-button size="mini"
                   @click="handleToCompany">生成机构账单</el-button>
        <!--
        <el-button
          size="small"
          type="primary"
          @click="openBatchEditWatcherDialog"
          v-show="$buttonPermission('ordermatching:batchWatcher')"
          >分配</el-button
        > -->
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <AddMatchingOrder :addForm="addForm"
                      :isVisible="AddMatchingOrderDialog"
                      @close="AddMatchingOrderDialog = false"
                      @submit="AddMatchingOrderSubmit"
                      @continueSubmit="continueAddStudent"></AddMatchingOrder>
    <EditMatchingOrder :isVisible="EditMatchingOrderDialog"
                       @close="EditMatchingOrderDialog = false"
                       @submit="EditMatchingOrderSubmit"
                       :content="EditMatchingOrderContent"></EditMatchingOrder>
    <CheckResult :content="CheckResultContent"
                 :isVisible="CheckResultDialog"
                 @close="CheckResultDialog = false"
                 @submit="CheckResultSubmit">
    </CheckResult>
    <RefundOrder :content="RefundOrderContent"
                 :isVisible="RefundOrderDialog"
                 @close="RefundOrderDialog = false"
                 @getlist="fetchList()">
    </RefundOrder>
    <BatchEditWatcher :isVisible="BatchEditWatcherDialog"
                      @close="BatchEditWatcherDialog = false"
                      @submit="BatchEditWatcherSubmit"></BatchEditWatcher>
    <MatchOrderMatching :id="matchId"
                        :isVisible="matchDialog"
                        @close="matchDialog = false"
                        @submit="fetchList()" />
    <!-- grade_id有可能是师资匹配id也有可能是师资派遣id -->
    <!-- <OrderConfirmList
      :teachers="teachers"
      :isVisible="confirmDialog"
      :grade_id="confirmId"
      :order_id="confirmOrder_id"
      @close="confirmDialog = false"
      @submit="fetchList()"
    /> -->

    <ServerOrderConfirmList :teachers="teachers"
                            :isVisible="confirmDialog"
                            :grade_id="confirmId"
                            :order_id="confirmOrder_id"
                            name="匹配"
                            @close="confirmDialog = false"
                            @submit="fetchList()" />

    <Record :teacher_allocation_order_id="orderId"
            :isVisible="recordDialog"
            @close="recordDialog = false"
            @submit="fetchList()"
            :type="'order_read'"></Record>
    <AddAutoMatchingOrder :isVisible="autoDialog"
                          @close="autoDialog = false"
                          @submit="fetchList()">
    </AddAutoMatchingOrder>

    <AggregatePayment ref="aggregatePayment"
                      @submit="fetchList()"></AggregatePayment>

    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>

    <MatchingCancle :isVisible="cancelDialog"
                    @close="cancelDialog = false"
                    :id="order_id"
                    @submit="termination" />
    <AddSeniorCompany :isVisible="createCompanyDialog"
                      @close="createCompanyDialog = false"
                      @submit="fetchList()"
                      :content="AddSeniorCompany" />
  </div>
</template>

<script>
import AddSeniorCompany from '@/components/DialogComponents/TeacherResources/AddSeniorCompany.vue'
import deviceLine from '../../../components/Line/Line.vue'
import InstitutionDetails from '@/components/DialogComponents/InstitutionDetails/index.vue'
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import AddMatchingOrder from '../../../components/DialogComponents/AddMatchingOrder/AddMatchingOrder.vue'
import EditMatchingOrder from '../../../components/DialogComponents/EditMatchingOrder/EditMatchingOrder.vue'
import CheckResult from '../../../components/DialogComponents/CheckResult/CheckResult.vue'
import RefundOrder from '../../../components/DialogComponents/RefundOrder/RefundOrder.vue'
import BatchEditWatcher from '../../../components/DialogComponents/BatchEditWatcher/BatchEditWatcher.vue'
import OrderConfirmList from '@/components/DialogComponents/TeacherResources/OrderConfirmList.vue'

import MatchOrderMatching from '@/components/DialogComponents/TeacherResources/MatchOrderMatching.vue'
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'
import AddAutoMatchingOrder from '@/components/DialogComponents/TeacherResources/AddAutoMatchingOrder.vue'

import AggregatePayment from '@/components/DialogComponents/AggregatePayment/AggregatePayment.vue'

import ServerOrderConfirmList from '@/components/DialogComponents/TeacherResources/ServerOrderConfirmList.vue'

import MatchingCancle from '@/components/DialogComponents/TeacherResources/MatchingCancle.vue'

import {
  createteacherorder,
  teacherorderlist,
  editeacherorder,
  createuserfollow,
  deleteuserfollow,
  editteacherorderlist,
  deleteteacherorderlist,
  editteacherorder,
  matchorderresult,
  crmOuthelpFindcreate,
  crmOuthelpFindedit,
  helpFinddetail,
  orderExport,
} from '../../../request/api'
import { formateObjDaiZhao } from '../../../utils/helper'
export default {
  components: {
    AddSeniorCompany,
    TableTittle,
    AddMatchingOrder,
    EditMatchingOrder,
    CheckResult,
    RefundOrder,
    BatchEditWatcher,
    MatchOrderMatching,
    OrderConfirmList,
    Record,
    deviceLine,
    AddAutoMatchingOrder,
    AggregatePayment,
    InstitutionDetails,
    ServerOrderConfirmList,
    MatchingCancle,
  },
  data() {
    return {
      order_id: null,
      cancelDialog: false,
      addForm: {},
      AddSeniorCompany: [],
      result: [],
      results: [],
      TableName: '订单列表',
      activeName: 'first',
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem('cid'),
        archive: '0',
        is_has_allocation_confirm: '',
      },
      pageTotal: 0,
      confirmedCount: 0,
      filtrate: '1',
      createCompanyDialog: false, //生成机构账单
      EditMatchingOrderDialog: false, // 编辑订单弹窗
      EditMatchingOrderContent: {},
      AddMatchingOrderDialog: false, // 添加订单弹窗
      CheckResultDialog: false, // 师资信息弹窗
      RefundOrderContent: {},
      RefundOrderDialog: false, // 退款弹窗
      chooseList: [],
      lock: true,
      CheckResultContent: {},
      // 批量修改watcher弹窗
      BatchEditWatcherDialog: false,
      matchId: null,
      matchDialog: false,
      confirmId: null,
      confirmOrder_id: null,
      confirmDialog: false,
      teachers: [],
      orderId: null,
      uncheck: 0,
      recordDialog: false,
      create_time: '',
      pay_time: '',
      autoDialog: false,
      filters: {
        match_order_sync_statuses: [
          { value: 1, label: '待服务' },
          { value: 2, label: '待对接' },
          { value: 3, label: '匹配中' },
          { value: 4, label: '已完成' },
          { value: 5, label: '已取消' },
        ],
      },
      terminationId: null,
    }
  },
  filters: {
    formateSub: function (val) {
      if (val == null || val == 1) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += `(${item.subject_code})${item.subject_name}` + '，'
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
    formateWatcher: function (val) {
      if (!val) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += item.alias + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },

    formateManage_Watcher(val) {
      if (!val) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += item.nickname + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
  },
  mounted() {
    this.parseQuery()
    // 获取列表

    this.getchList()
    this.fetchListconfirmed()
    this.$store.dispatch('getCompanyList', { data_range_tags: '4' })
    this.$store.dispatch('getAllColleges')
    this.$store.dispatch('getStaffOptions')
  },
  watch: {
    pay_time(value) {
      if (value) {
        this.query.start_payment_time = this.$moment(value[0]).valueOf()
        this.query.end_payment_time = this.$moment(value[1]).valueOf()
      } else {
        this.query.start_payment_time = ''
        this.query.end_payment_time = ''
        this.fetchList(true)
      }
    },
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf()
        this.query.end_create_time = this.$moment(value[1]).valueOf()
      } else {
        this.query.start_create_time = ''
        this.query.end_create_time = ''
        this.fetchList(true)
      }
    },
  },
  methods: {
    // 选择机构
    handleToCompany() {
      if (!this.chooseList.length) {
        return this.$message.error('请选择数据')
      }
      let arr = this.chooseList.filter((v, i) => {
        return (
          v.match_order_sync_status != 4 ||
          v.payment_status != 0 ||
          v.company_order_process_status != 0 ||
          v.cid != this.chooseList[0].cid
        )
      })
      console.log('arr', arr)

      if (arr.length) {
        this.$message.error(
          '选择机构必须为同一家机构并且订单状态为已完成且支付状态为未支付订单账单状态为未绑定'
        )
      } else {
        this.createCompanyDialog = true
        console.log('chooseList', this.chooseList)
        this.AddSeniorCompany = this.chooseList
      }
    },
    ChooseTeacher(row) {
      this.$refs.classGrade.open({
        id: row.id,
        confirmOrder_id: row.allocation_order_obj
          ? row.allocation_order_obj.id
          : null,
      })
    },

    upSignContract(row) {
      editeacherorder({
        id: row.id,
        match_order_fix_company_amount: Number(
          row.ref_hour_income_company_amount
        ),
      }).then((res) => {
        this.fetchList()
      })
    },
    upRest(row) {
      this.result.find((item, index) => {
        if (row.id == item.id) {
          this.$set(this.result[index], 'restFlag', true)
        }
      })
    },
    upRestMoney(row) {
      editeacherorder({
        id: row.id,
        ref_match_other_company_amount: Number(
          row.ref_match_other_company_amount
        ),
      }).then((res) => {
        this.fetchList()
        // console.log('res:', res)
      })
    },
    upSign(row) {
      this.result.find((item, index) => {
        if (row.id == item.id) {
          this.$set(this.result[index], 'upFlag', true)
        }
      })
    },

    getManageList(list) {
      if (list && list.length > 0) {
        return list.map((item) => item.nickname).join(' / ')
      } else {
        return '-'
      }
    },
    // filtrateChange(e) {
    //   this.query.is_has_allocation_confirm = 0;
    //   this.query.follow = 0;
    //   if (e == 2) {
    //     this.query.follow = 1;
    //   } else if (e == 3) {
    //     this.query.is_has_allocation_confirm = 1;
    //   }
    //   this.fetchList(true);
    // },
    handleClick(tab, event) {
      if (tab.name == 'first') {
        this.query.is_statistic_data_table = ''
        this.fetchList(true)
      } else {
        this.query.is_statistic_data_table = 1
        this.fetchList(true)
      }
    },

    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.apply_college_code)
    },
    // 导出
    handleExport() {
      this.$confirm('是否导出数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = Object.assign({}, this.query)
        if (params.match_order_sync_statuses) {
          params.match_order_sync_statuses =
            params.match_order_sync_statuses.join(',')
        }
        orderExport(params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success('导出成功')
            //创建a标签用于跳转至下载链接
            // console.log("src",src)
            var str = res.data.data.url.replace(
              /http:/,
              window.location.protocol
            )
            this.$store.commit('downLoad', str)
          }
        })
      })
    },

    // 新增综合支付
    aggregatePayment(row) {
      this.$refs.aggregatePayment.open(row)
    },
    fetchCheckCount() {
      teacherorderlist({
        is_has_allocation_confirm: 1,
        isPaging: 0,
      }).then((response) => {
        let res = response.data
        this.uncheck = res.data.count
      })
    },

    fetchListconfirmed() {
      let query = {
        isPaging: 1,
        archive: '0',
        is_has_allocation_confirm: 1,
      }
      teacherorderlist(query).then((response) => {
        this.confirmedCount = response.data.data.count
        // console.log('response',response);
      })
    },

    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)
      if (params.match_order_sync_statuses) {
        params.match_order_sync_statuses =
          params.match_order_sync_statuses.join(',')
      }
      if (this.create_time && this.create_time.length) {
        params.start_create_time = this.create_time[0]
        params.ent_create_time = this.create_time[1]
      }
      teacherorderlist(params).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        this.pageTotal = res.data.count
        this.fetchCheckCount()
        // 对比两个数组id  一样的ref_hour_income_company_amount给传过去
        this.result.map((item) => {
          this.results.map((k) => {
            if (item.id == k.id) {
              item.ref_hour_income_company_amount =
                k.ref_hour_income_company_amount
            }
          })
        })
        /*    this.result.forEach((item, index) => {
             if (item.old_id == null) {
               return false
             }
             let forQuery = {
               id: item.old_id,
               newId: item.id,
             }
             // 请求每一条数据的详情
             helpFinddetail(forQuery).then((response_other) => {
               if (response_other.data.data.message == "ok") {
                 console.log('ok');
               }
             })
           }) */
      })
      /*   .then(() => {
          setTimeout(() => {
            this.getListTwice(params)
          }, 100);
        }) */
      this.syncQuery()
    },

    getchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      this.query.is_statistic_data_table = 1
      const params = Object.assign({}, this.query)
      if (params.match_order_sync_statuses) {
        params.match_order_sync_statuses =
          params.match_order_sync_statuses.join(',')
      }
      if (this.create_time && this.create_time.length) {
        params.start_create_time = this.create_time[0]
        params.ent_create_time = this.create_time[1]
      }
      teacherorderlist(params).then((response) => {
        let res = response.data
        this.results = res.data.list || []
        console.log('chuancan', this.results)
        this.fetchList()
        // this.fetchCheckCount()

        /*    this.result.forEach((item, index) => {
             if (item.old_id == null) {
               return false
             }
             let forQuery = {
               id: item.old_id,
               newId: item.id,
             }
             // 请求每一条数据的详情
             helpFinddetail(forQuery).then((response_other) => {
               if (response_other.data.data.message == "ok") {
                 console.log('ok');
               }
             })
           }) */
      })
      /*   .then(() => {
          setTimeout(() => {
            this.getListTwice(params)
          }, 100);
        }) */
      // this.syncQuery()
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.follow) {
        this.query.follow = Number(this.query.follow)
      }
      if (this.query.is_has_allocation_confirm) {
        this.query.is_has_allocation_confirm = Number(
          this.query.is_has_allocation_confirm
        )
      }
      if (this.query.cid) {
        this.query.cid = Number(this.query.cid)
      }
      if (
        this.query.match_order_sync_statuses &&
        this.query.match_order_sync_statuses.length
      ) {
        this.query.match_order_sync_statuses =
          this.query.match_order_sync_statuses.split(',').map((v) => {
            return Number(v)
          })
      }
      if (this.query.start_create_time && this.query.end_create_time) {
        this.create_time = [
          this.$moment(Number(this.query.start_create_time)),
          this.$moment(Number(this.query.end_create_time)),
        ]
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      if (params.match_order_sync_statuses) {
        params.match_order_sync_statuses = params.match_order_sync_statuses
          .filter((v) => v)
          .join(',')
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },

    getListTwice(query) {
      teacherorderlist(query).then((res) => {
        this.result = res.data.data.list || []

        this.pageTotal = res.data.data.count
      })
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseList = val
      console.log('val', val)
    },
    // 筛选组件传值
    screenTable({ screenForm }) {
      this.$set(this.query, 'pageIndex', 1)
      this.query = {
        ...this.query,
        ...screenForm,
      }
      this.fetchList()
    },
    // 搜索框搜索
    inputSearch(e) {
      this.fetchList()
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    // 跳转沟通记录
    goRecord(item) {
      this.$router.push({
        name: `communicationrecord`,
        query: item,
      })
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 3,
          follow_id: item.id,
        }
        createuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 500)
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 3,
          follow_id: item.id,
        }
        deleteuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 300)
      }
    },
    formateOrder() {
      // 把subjects参数转换为处理并转换字符串，必须要number格式
      if (this.EditMatchingOrderContent.subjects) {
        let arr = []
        this.EditMatchingOrderContent.subjects.forEach((item) => {
          arr.push(item.subject_code)
        })
        this.EditMatchingOrderContent.subjects = arr
      }
      /*      if (this.EditMatchingOrderContent.watcher.length) {
             this.EditMatchingOrderContent.watcher = this.EditMatchingOrderContent.watcher[0].id;
           } */
    },
    // 打开添加订单弹窗
    openAddMatchingOrderDialog() {
      this.AddMatchingOrderDialog = !this.AddMatchingOrderDialog
      this.addForm = {}
    },

    openAutoDialog() {
      this.autoDialog = true
    },

    // 打开编辑订单弹窗
    openEditMatchingOrderDialog(item) {
      this.EditMatchingOrderContent = JSON.parse(JSON.stringify(item))
      this.formateOrder()
      this.EditMatchingOrderDialog = !this.EditMatchingOrderDialog
    },
    openCheckResultDialog(item) {
      this.CheckResultContent = JSON.parse(JSON.stringify(item))
      this.CheckResultDialog = !this.CheckResultDialog
    },

    // 添加订单组件提交
    continueAddStudent({ form }) {
      // console.log("form",form)
      let query = form
      this.addForm = {
        cid: query.cid,
        watcher: [],
      }
      this.addForm.watcher = []
      if (query.watcher) {
        query.watcher.split(',').map((item) => {
          this.addForm.watcher.push(Number(item))
        })
      }
      createteacherorder(form).then((res) => {
        if (res.data.code == 0) {
          this.fetchList()
          this.AddMatchingOrderDialog = false
          setTimeout(() => {
            this.AddMatchingOrderDialog = true
          }, 1000)
        }
      })
    },

    // 添加订单组件提交
    AddMatchingOrderSubmit({ form }) {
      createteacherorder(form).then((res) => {
        if (res.data.code == 0) {
          this.fetchList()
          this.AddMatchingOrderDialog = false
        }
      })
    },
    // 修改订单组件提交
    EditMatchingOrderSubmit({ form }) {
      editeacherorder(form).then((res) => {
        if (res.data.code == 0) {
          this.$notify.success({
            title: '提示',
            message: '修改成功',
          })
          this.fetchList()
          this.EditMatchingOrderDialog = false
          this.fetchList()

          /*         if (form.old_id == null) {
                    this.fetchList();
                    return false
                  }
                  // 总端修改成功之后还要再调一次老系统的接口同步数据
                  crmOuthelpFindedit(formateObjDaiZhao(form, 'edit')).then(response => {
                    if (response.data.data.message == "ok") {
                      this.fetchList();
                    } else {
                      this.$notify.error({
                        title: "提示",
                        message: response.data.data.message,
                      });
                    }
                  }) */
        }
      })
    },
    chooseAllEdit(archive_status, text) {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: '提示',
          message: '还没有选择要修改的数据',
        })
        return false
      }

      let flag = this.chooseList.every((item) =>
        [4, 5].includes(item.match_order_sync_status)
      )
      if (!flag) {
        this.$notify.error({
          title: '提示',
          message: '仅限状态为已完成、已取消的订单可以归档',
        })
        return false
      }

      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            rows: [],
          }
          this.chooseList.forEach((item) => {
            let obj = {
              id: item.id,
              archive: archive_status,
            }
            query.rows.push(obj)
          })
          editteacherorderlist(query).then((res) => {
            this.$nextTick(() => {
              this.fetchList()
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消操作',
          })
        })
    },
    // 选中数据后做处理删除
    chooseAllDelete() {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: '提示',
          message: '还没有选择要删除的数据',
        })
        return false
      }
      let flag = this.chooseList.every(
        (item) => item.match_order_sync_status == 2
      )
      if (!flag) {
        this.$notify.error({
          title: '提示',
          message: '仅待对接状态的订单可以删除',
        })
        return false
      }

      this.$confirm('确定要删除选中的数据吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            ids: [],
          }
          this.chooseList.forEach((item) => {
            query.ids.push(item.id)
          })
          deleteteacherorderlist(query).then((res) => {
            this.$nextTick(() => {
              this.fetchList()
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消删除',
          })
        })
    },
    // 修改订单状态
    editTeacherOrder(item, text, status, canceltext) {
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            id: item.id,
            status: status,
          }
          console.log(item)
          editteacherorder(query).then((res) => {
            this.$nextTick(() => {
              this.dialogVisible = false
              this.fetchList()
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: canceltext,
          })
        })
    },
    CheckResultSubmit({ form }) {
      let query = {
        id: this.CheckResultContent.id,
        result: form,
      }
      matchorderresult(query).then((res) => {
        this.$nextTick(() => {
          this.CheckResultDialog = false
          this.fetchList()
        })
      })
    },
    // 打开退款弹窗
    openRefundOrderDialog(item) {
      this.RefundOrderContent = JSON.parse(JSON.stringify(item))
      this.RefundOrderDialog = !this.RefundOrderDialog
    },
    // 对多选数组做判断处理
    termination() {
      let query = {
        rows: [
          {
            id: this.terminationId,
            status: 6,
          },
        ],
      }
      editteacherorderlist(query).then((res) => {
        if (res.data.code == 0) {
          this.$notify.success({
            title: '提示',
            message: '操作成功',
          })
          this.fetchList()
        }
      })
    },

    filterArrList() {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: '提示',
          message: '还没有选择要修改的数据',
        })
        return false
      }
      let list = this.chooseList.map((item) => {
        return {
          id: item.id,
          status: 6,
        }
      })
      this.$confirm('您确定要终止吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            rows: list,
          }
          editteacherorderlist(query).then((res) => {
            if (res.data.code == 0) {
              this.$notify.success({
                title: '提示',
                message: '操作成功',
              })
              this.fetchList()
            }
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消操作',
          })
        })
    },
    filterArr(row) {
      this.terminationId = row.id

      if (row.match_order_sync_status == 3) {
        this.$confirm('您确定要取消匹配并终止吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.order_id = Number(row.bind_allocation_order_id)
          this.cancelDialog = true
        })
        return false
      }
      this.$confirm('您确定要终止吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            rows: [
              {
                id: row.id,
                status: 6,
              },
            ],
          }
          editteacherorderlist(query).then((res) => {
            if (res.data.code == 0) {
              this.$notify.success({
                title: '提示',
                message: '操作成功',
              })
              this.fetchList()
            }
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消操作',
          })
        })
    },
    openBatchEditWatcherDialog() {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: '提示',
          message: '还没有选择要修改的数据',
        })
        return false
      }
      this.BatchEditWatcherDialog = true
    },
    BatchEditWatcherSubmit(obj) {
      let query = {
        rows: this.chooseList.map((v) => {
          return {
            id: v.id,
            manage_watcher: obj.watcher,
          }
        }),
      }
      editteacherorderlist(query).then((res) => {
        this.$nextTick(() => {
          this.fetchList()
          this.$notify.success({
            title: '提示',
            message: '修改成功',
          })
          this.BatchEditWatcherDialog = false
        })
      })
    },
    exportData(item) {
      crmOuthelpFindcreate(formateObjDaiZhao(item, 'create')).then((res) => {
        if (res.data.data.message == 'ok') {
          editeacherorder({
            id: res.data.data.data.newId,
            old_id: res.data.data.data.id,
          }).then((resp) => {
            this.fetchList()
          })
        } else {
          this.$notify.error({
            title: '提示',
            message: res.data.data.message,
          })
        }
      })
    },
    formatePayType(type) {
      const obj = {
        0: '无',
        1: '支付宝',
        2: '微信',
        3: '余额',
        4: '转账',
      }
      return obj[type]
    },
    handleToMatching(row) {
      this.matchId = row.id
      this.matchDialog = true
    },
    openConfirmList(row) {
      // this.$refs.classGrade.open({
      //   id: row.id,
      //   confirmOrder_id: row.allocation_order_obj
      //     ? row.allocation_order_obj.id
      //     : null,
      //   type: "确认列表",
      // });
      this.teachers =
        row.allocation_order_teacher_refs &&
        row.allocation_order_teacher_refs.length
          ? row.allocation_order_teacher_refs
          : []
      this.confirmId = row.id
      this.confirmOrder_id = row.allocation_order_obj.id
      this.confirmDialog = true
    },
    handleToOrderRecord(row) {
      this.orderId = row.allocation_order_obj.id
      this.recordGradeId = row.id
      this.recordDialog = true
    },
    changeStatus(row) {
      this.$confirm('您确定要恢复订单吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            id: row.id,
            status: 50,
          }
          editeacherorder(query).then((res) => {
            this.fetchList()
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消操作',
          })
        })
    },
  },
}
</script>

<style lang="less" scoped>
.tabulation {
  /deep/ .el-tabs__nav-wrap::after {
    height: 0px !important;
  }
  /deep/.el-badge__content.is-fixed {
    right: 15px !important;
  }
}

.badge {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}

.operation-card {
  width: 100%;
  margin-top: 20px;

  .demo-form-inline {
    height: 64px;
    border-bottom: 2px dashed #d2d5df;
  }
}

.operation-menu {
  display: flex;
  justify-content: space-between;

  .menu_left {
    margin-top: 25px;
  }

  .menu_right {
    margin-top: 25px;
  }
}

.block {
  float: right;
  display: flex;
  justify-content: center;
}

.star {
  width: 18px;
  height: 18px;
}

.templateLink,
.link-template {
  .el-link {
    margin: 0px 5px;
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  margin-top: 10px;
  margin-right: 40px;
}

.link-template {
  position: relative;

  .red-bradge {
    position: absolute;
    width: 25px;
    height: 13px;
    background: #f56c6c;
    border-radius: 6px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -4px;
    top: 0;

    span {
      font-size: 10px;
      color: #ffffff;
    }
  }
}
</style>