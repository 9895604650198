<template>
  <div>
    <el-dialog
      top="5vh"
      title="学员管理"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <!-- <el-form-item
          label="学员姓名："
          prop="realname"
        >
          <el-input
            v-model="dataForm.realname"
            placeholder="请输入学员姓名"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item
          label="课程状态："
          prop="course_status"
        >
          <el-select
            v-model="dataForm.course_status"
            placeholder="请选择课程状态"
            clearable
          >
            <el-option
              label="待上课"
              :value="1"
            ></el-option>
            <el-option
              label="上课中"
              :value="2"
            ></el-option>
            <el-option
              label="上课结束"
              :value="3"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item
          label="考研年份："
          prop="apply_year"
        >
          <el-select
            v-model="dataForm.apply_year"
            placeholder="请选择考研年份"
            clearable
          >
            <el-option
              label="2022年"
              :value="2022"
            ></el-option>
            <el-option
              label="2023年"
              :value="2023"
            ></el-option>
            <el-option
              label="2024年"
              :value="2024"
            ></el-option>
            <el-option
              label="2025年"
              :value="2025"
            ></el-option>
            <el-option
              label="2026年"
              :value="2026"
            ></el-option>
            <el-option
              label="2027年"
              :value="2027"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考信息："
          prop="apply_college"
        >
          <el-select
            v-model="dataForm.apply_college"
            placeholder="请选择院校"
            filterable
            clearable
            @change="collegeChange"
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="apply_major"
        >
          <el-select
            v-model="dataForm.apply_major"
            placeholder="请选择专业"
            @change="majorChange"
            clearable
          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.allnames"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="apply_major"
        >
          <el-select
            v-model="dataForm.apply_academy"
            placeholder="请选择学院"
            clearable
            @change="academyChange"
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="{ value: item.academy_code, label: item.academy_name,directions:item.directions}"
            >
              <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="dataForm.apply_direction"
            placeholder="请选择考试方向"
            clearable
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in directionsOptions"
              :key="index"
              :label="item.direction"
              :value="{ value: item.direction_code, label: item.direction}"
            >
              <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="subjects"
        >
          <el-select
            style="width:100%;"
            v-model="dataForm.subjects"
            placeholder="请选择科目"
            clearable
            multiple
            @change="$forceUpdate()"
          >
            <el-option
              v-for="(item,index) in subjectOptions"
              :key="index"
              :label="item.subject_name"
              :value="{ value: item.subject_code, label: item.subject_name}"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>

            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="辅导时长："
          prop="service_hours"
        >
          <el-input
            placeholder="请输入辅导时长"
            v-model="dataForm.service_hours"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          >
          </el-input>
        </el-form-item> -->
        <el-form-item label="课程备注：">
          <el-input
            type="textarea"
            v-model="dataForm.remark"
            placeholder="请输入课程备注"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="学管师："
          prop="learn_manager"
        >
          <el-select
            v-model="dataForm.learn_manager"
            placeholder="请选择学管师"
            clearable
          >
            <el-option
              v-for="(item,index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="对接人："
          prop="docking_man"
        >
          <el-select
            v-model="dataForm.docking_man"
            placeholder="请选择对接人"
            clearable
            multiple
          >
            <el-option
              v-for="(item,index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>

      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  academylist,
  majorlist,
  editstudent,
  studentOne,
  subjectlist,

} from "@/request/api";
import { adminUrl } from "@/utils/helper";

export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        realname: [{ required: true, message: "请输入学员名称", trigger: "blur" }],
        learn_manager: [{ required: true, message: "请选择学管师", trigger: "blur" }],
        course_status: [{ required: true, message: "请选择课程状态", trigger: "blur" }],
        apply_year: [{ required: true, message: "请选择考研年份", trigger: "blur" }],
        apply_college: [{ required: true, message: "请选择院校", trigger: "blur" }],
        apply_major: [{ required: true, message: "请选择专业", trigger: "blur" }],
        apply_academy: [{ required: true, message: "请选择学院", trigger: "blur" }],
        docking_man: [{ required: true, message: "请选择对接人", trigger: "blur" }],
        subjects: [{ required: true, message: "请选择科目", trigger: "blur" }],

        service_hours: [
          { required: true, message: "请输入辅导课时", trigger: "blur" },
          { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }
        ],
      },
      majorOptions: [],// 专业数组
      academyOptions: [],// 学院数组
      subjectOptions: [],//科目数组

      companySelect: 2,

      tipsText: "",
      directionsOptions: [],
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.companySelect = 2
        this.fetchDetail()
        this.$store.dispatch('getAllColleges')
        this.$store.dispatch('getStaffOptions')
      }
    },
  },
  methods: {
    fetchDetail() {
      studentOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = {}
        // this.$set(this.dataForm, 'realname', res.data.realname)
        this.$set(this.dataForm, 'course_status', res.data.course_status)
        // this.$set(this.dataForm, 'apply_year', res.data.apply_year)
        // this.$set(this.dataForm, 'service_hours', res.data.service_hours)
        // this.hoursBlur()
        this.$set(this.dataForm, 'remark', res.data.remark)
        // if (res.data.grades) {
        //   this.$set(this.dataForm, 'arr_grade', res.data.grades.map(v => {
        //     return {
        //       grade_id: v.id,
        //       student_grade_status: v.student_grade_status,
        //     }
        //   }))
        // }

        // if (res.data.learn_manager) {
        //   this.$set(this.dataForm, 'learn_manager', res.data.learn_manager.id)
        // }

        // if (res.data.docking_man) {
        //   this.$set(this.dataForm, 'docking_man', res.data.docking_man.split(',').map(v => {
        //     return Number(v)
        //   }))
        // }
        // if (res.data.apply_college_name && res.data.apply_college_code) {
        //   this.$set(this.dataForm, 'apply_college', {
        //     label: res.data.apply_college_name,
        //     value: res.data.apply_college_code,
        //   })
        //   this.getMajor(res.data.apply_college_code)

        // }
        // if (res.data.apply_major_name && res.data.apply_major_code) {
        //   this.$set(this.dataForm, 'apply_major', {
        //     label: res.data.apply_major_name,
        //     value: res.data.apply_major_code,
        //   })
        //   this.getMajorInfo(res.data.apply_college_code, res.data.apply_major_code)

        // }
        // if (res.data.apply_academy_name && res.data.apply_academy_code) {
        //   this.$set(this.dataForm, 'apply_academy', {
        //     label: res.data.apply_academy_name,
        //     value: res.data.apply_academy_code,
        //   })
        //   this.getSubjects()
        // }
        // if (res.data.subjects) {
        //   this.$set(this.dataForm, 'subjects', res.data.subjects.map(v => {
        //     return {
        //       value: v.subject_code,
        //       label: v.subject_name,
        //     }
        //   }))
        // }
        // if (res.data.apply_direction_code && res.data.apply_direction_name) {
        //   // console.log('asd');
        //   this.$set(this.dataForm, 'apply_direction', {
        //     label: res.data.apply_direction_name,
        //     value: res.data.apply_direction_code,
        //   })
        // }
        // this.$forceUpdate()

      });
    },
    emitClose() {
      this.$refs['formRules'].clearValidate();
      this.$emit("close");
    },
    collegeChange(e) {
      this.dataForm.apply_major = {}
      this.majorOptions = []
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.subjects = []
      this.subjectOptions = []
      if (e) {
        this.getMajor(e.value);
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get',//可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then(response => {
        let res = response.data
        this.academyOptions = res.data.academys
        this.directionsOptions = this.academyOptions.find(v => {
          return this.dataForm.apply_academy.value == v.academy_code
        }).directions

      })
    },
    majorChange(e) {
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.subjects = []
      this.subjectOptions = []
      if (e) {
        this.getMajorInfo(this.dataForm.apply_college.value, e.value);
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map(v => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`
          }
        })
      });
    },
    getAcedemy(college_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        major_code: major_code,
      };
      academylist(query).then((response) => {
        let res = response.data
        this.academyOptions = res.data.list || [];
      });
    },
    academyChange(e) {
      this.dataForm.subjects = []
      this.subjectOptions = []
      this.$forceUpdate()
      if (e) {
        this.getSubjects();
      }
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.dataForm.apply_college.value,
        academy_code: this.dataForm.apply_academy.value,
        major_code: this.dataForm.apply_major.value,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程"
        }
        this.subjectOptions = res.data.data.list || [];
        this.subjectOptions.push(obj)
      });
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.id,
            // cid: localStorage.getItem("cid"),
            // apply_college_code: this.dataForm.apply_college ? this.dataForm.apply_college.value : "",
            // apply_college_name: this.dataForm.apply_college ? this.dataForm.apply_college.label : "",
            // apply_major_code: this.dataForm.apply_major ? this.dataForm.apply_major.value : "",
            // apply_major_name: this.dataForm.apply_major ? this.dataForm.apply_major.label : "",
            // apply_academy_code: this.dataForm.apply_academy ? this.dataForm.apply_academy.value : "",
            // apply_academy_name: this.dataForm.apply_academy ? this.dataForm.apply_academy.label : "",
            // apply_direction_code: this.dataForm.apply_direction ? this.dataForm.apply_direction.value : "",
            // apply_direction_name: this.dataForm.apply_direction ? this.dataForm.apply_direction.label : "",
            // realname: this.dataForm.realname,
            // course_status: this.dataForm.course_status,
            // apply_year: this.dataForm.apply_year,
            // service_hours: this.dataForm.service_hours,
            remark: this.dataForm.remark,
            // docking_man: this.dataForm.docking_man ? this.dataForm.docking_man.join(',') : '',
            // learn_manager: this.dataForm.learn_manager,
            // arr_grade: this.dataForm.arr_grade,
            // subjects: this.dataForm.subjects && this.dataForm.subjects.length ? this.dataForm.subjects.map(v => {
            //   return {
            //     subject_code: v.value,
            //     subject_name: v.label,
            //   }
            // }) : [],
          }
          editstudent(query).then((res) => {
            if (res.data.code == 0) {
              this.emitClose()
              this.$emit('submit')
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    hoursBlur() {
      if (!/^(\d+|\d+\.\d{1,2})$/.test(this.dataForm.service_hours)) {
        this.tipsText = "输入最多两位小数的数字"
      } else {
        if (this.companySelect == 2) {
          this.tipsText = `${this.dataForm.service_hours}小时等于${(this.dataForm.service_hours * 1.33).toFixed(2)}课时`
        } else if (this.companySelect == 1) {
          this.tipsText = `${this.dataForm.service_hours}课时等于${(this.dataForm.service_hours * 0.75).toFixed(2)}小时`
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
.tips {
  font-size: 14px;
  color: #909399;
  line-height: 16px;
}
</style>