<template>
  <div>
    <el-dialog
top="5vh"
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="700px"
    >
      <div>
        <el-descriptions
          :column="1"
          :labelStyle="labelStyle"
        >
          <el-descriptions-item label="姓名">
            {{content.name_1st}}
          </el-descriptions-item>
          <el-descriptions-item label="提交时间">{{$moment(content.time).format("YYYY-MM-DD HH:mm")}}</el-descriptions-item>
          <el-descriptions-item label="课堂反馈">
            <div
              v-if="content.type=='老师评价'"
              class="content-text"
            >
              对<span>{{content.name_2nd}}</span>的评价：{{content.content}}
            </div>
            <div
              v-else-if="content.type=='课程评价'"
              class="content-text"
            >
              对<span>{{content.name_2nd}}</span>老师的评价：{{content.content}}
            </div>
            <div
              v-else-if="content.type=='老师评分'"
              class="content-text"
            >
              对<span>{{content.name_2nd}}</span>老师的评分：{{content.score}}
            </div>
            <div v-else-if="content.type=='确认记录'">
              <template v-if="content.handle.handle_type==3">
                {{`我撤回了自己于“${$moment(parseTime(content,'update_time')).format("YYYY-MM-DD HH:mm")}”提交的关于“确认的实际上课时间为“${$moment(parseTime(content,'confirm_time')).format("YYYY-MM-DD HH:mm")}”的上课记录，稍后将重新确认。`}}
              </template>
              <template v-else-if="content.handle.handle_type==0">
                <div v-if="content.handle.target_student_id">
                  To<span class="weight">{{content.handle.student_realname}}</span>学员：我代你进行了课程确认，{{content.handle.handle_content}}
                </div>
                <div v-else-if="content.handle.target_teacher_id">
                  To<span class="weight">{{content.handle.teacher_realname}}</span>老师：我代你进行了课程确认，{{content.handle.handle_content}}
                </div>
              </template>
              <template v-else-if="content.handle.handle_type==1">
                <div v-if="content.handle.target_student_id">
                  To<span class="weight">{{content.handle.student_realname}}</span>学员：我对你的确认进行了修改，{{content.handle.handle_content}}
                </div>
                <div v-else-if="content.handle.target_teacher_id">
                  To<span class="weight">{{content.handle.teacher_realname}}</span>老师：我对你的确认进行了修改，{{content.handle.handle_content}}
                </div>
              </template>
              <template v-else-if="content.handle.handle_type==2">
                <div v-if="content.handle.target_student_id">
                  To<span class="weight">{{content.handle.student_realname}}</span>学员：我撤回了你于{{$moment(parseTime(content,'update_time')).format("YYYY-MM-DD HH:mm")}}提交的关于“确认实际上课时间{{$moment(parseTime(content,'confirm_time')).format("YYYY-MM-DD HH:mm")}}”的上课确认记录，请根据实际课程情况重新进行确认！
                </div>
                <div v-else-if="content.handle.target_teacher_id">
                  To<span class="weight">{{content.handle.teacher_realname}}</span>老师：我撤回了你于{{$moment(parseTime(content,'update_time')).format("YYYY-MM-DD HH:mm")}}提交的关于“确认实际上课时间{{$moment(parseTime(content,'confirm_time')).format("YYYY-MM-DD HH:mm")}}”的上课确认记录，请根据实际课程情况重新进行确认！
                </div>
              </template>
              <template v-else-if="content.handle.handle_type==4">
                课时拒绝理由： {{content.handle.handle_content}}
              </template>
            </div>
            <div
              v-else
              class="content-text"
            >
              {{content.content}}
            </div>
            <el-row
              :gutter="10"
              v-if="content.picture_urls&&content.picture_urls.length&&content.picture_urls.length<=2"
              style="margin:16px 0px;"
            >
              <el-col
                :span="8"
                v-for="(item,index) in content.picture_urls"
                :key="index"
              >
                <img
                  :src="item.url"
                  alt=""
                  srcset=""
                  style="width:213px;height:213px;"
                  @click="openViewer(content.picture_urls,index)"
                >
              </el-col>
            </el-row>
            <el-row
              v-if="content.picture_urls&&content.picture_urls.length&&content.picture_urls.length>=3"
              style="margin:16px 0px;"
            >
              <el-col
                :span="7"
                v-for="(item,index) in content.picture_urls"
                :key="index"
              >
                <img
                  :src="item.url"
                  alt=""
                  srcset=""
                  style="width:148px;height:148px;margin-bottom:16px;"
                  @click="openViewer(content.picture_urls,index)"
                >
              </el-col>
            </el-row>
          </el-descriptions-item>
          <el-descriptions-item label="查看范围">{{content.area}}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="footer">
        <el-button @click="emitClose()">取消</el-button>
        <el-button
          type="primary"
          @click="emitClose()"
        >确定</el-button>
      </div>
    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      :url-list="imgs"
      :zIndex="999999999"
      :on-close="closeViewer"
      :initial-index="initialIndex"
    />
  </div>
</template>

<script>


import {
  confirmLesson,
  lessonLogOne
} from "@/request/api";
import { } from "@/utils/helper.js";
import { } from "@/utils/helper";
import { imgUploadUrl, fileUploadUrl } from "@/request/http";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";




export default {
  components: {
    ElImageViewer
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },

  },
  data() {
    return {
      initialIndex:0,
      dialogFormVisible: this.isVisible,
      imgUploadUrl: imgUploadUrl(),
      labelStyle: {
        whiteSpace: 'nowrap',
      },
      title: '确认详情',
      imgs: [],
      showViewer: false,

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.title = this.content.label + '的评价与反馈记录详情'
      }
    },

  },
  methods: {
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url,index) {
      this.imgs = url.map(v => {
        return v.url
      })
      this.initialIndex = index
      this.showViewer = true;
    },
    emitClose() {
      this.$emit("close");
    },
    parseTime(row, type) {
      let obj = JSON.parse(row.handle.before)
      if (type == 'update_time') {
        return obj.update_time
      } else if (type == 'confirm_time') {
        if (obj.target_student_id) {
          return obj.student_real_lesson_start_time
        } else if (obj.target_teacher_id) {
          return obj.teacher_real_lesson_start_time
        } else {
          return obj.final_real_lesson_start_time
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  margin-bottom: 16px;
}
.weight {
  margin-bottom: 8px;
  font-weight: 800;
  color: #333;
}
.img-content {
  margin-top: 16px;
  img {
    border: solid;
    width: 213px;
    height: 213px;
  }
}
</style>