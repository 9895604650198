<template>
  <div>
    <el-dialog
top="5vh"
      title="您提交的内容有如下错误："
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="1140px"
    >
      <div class="table">
        <el-table
          :data="result"
          border
        >
          <el-table-column
            label="行数"
            prop="row_no"
            width="120"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="课程主题"
            prop="course_theme"
            width="260"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="错误类型"
            width="100"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                style="color:#F56C6C;"
                v-if="scope.row.error_type==0"
              >参数错误</div>
              <div
                style="color:#F56C6C;"
                v-if="scope.row.error_type==1"
              >数据不存在</div>
              <div
                style="color:#F56C6C;"
                v-if="scope.row.error_type==2"
              >数据重复</div>
              <div
                style="color:#F56C6C;"
                v-if="scope.row.error_type==3"
              >数据冲突</div>
              <div
                style="color:#F56C6C;"
                v-if="scope.row.error_type==4"
              >系统运行时错误</div>
              <div
                style="color:#F56C6C;"
                v-if="scope.row.error_type==5"
              >余额不足</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="error_reason"
            label="错误原因"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <!--         <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div> -->
      </div>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {
  studentlist
} from "@/request/api";
import {
} from "@/utils/helper";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      result: [],
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.result = this.list
      }
    },
  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.fetchList();
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      /*       studentlist(params).then((response) => {
              let res = response.data
              this.tableData = res.data.list || [];
              this.pageTotal = res.data.count;
            }); */
    },
    emitClose() {
      this.result = []
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.table {
}
.block {
  margin-top: 16px;
}
.footer {
  width: 100%;
}
</style>