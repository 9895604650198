<template>
  <el-dialog
top="5vh"
    title=""
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="470px"
  >
    <el-form>
      <el-form-item label="学管师">
        <el-select
          v-model="form.watcher"
          filterable
          placeholder="请选择"
          style="width: 400px"
          @change='$forceUpdate()'
        >
          <el-option
            v-for="(item,index) in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 4 && v.status == 1;
                  }) != -1
                );
              })"
            :key="index"
            :label="item.nickname"
            :value="item.id"
          >
          <span> {{ `${item.nickname}-${item.phone}` }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="emitClose">取 消</el-button>
      <el-button
        type="primary"
        @click="submit"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    learn_manager_id:{
      default: null,
    }
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      form: {},
    };
  },
  mounted() {
    this.$store.dispatch('getStaffOptions')
  },
  watch: {
    isVisible(val) {
      this.form = {}
      this.form.watcher = this.learn_manager_id
      this.dialogFormVisible = val;
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit", { watcher: this.form.watcher });
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  width: 128px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #303133;
  opacity: 1;
}
.count-content {
  height: 55px;
  text-align: center;
  margin-bottom: 36px;
  span {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 55px;
    color: #e6a23c;
    opacity: 1;
  }
}
.tax-rate {
  margin-top: 24px;
  span {
    margin-left: 16px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 18px;
    color: #c0c4cc;
    opacity: 1;
  }
}
</style>