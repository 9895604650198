<template>
  <div>
    <el-dialog top="5vh"
               title="新建课时"
               :visible.sync="dialogFormVisible"
               @close="emitClose"
               :close-on-click-modal="false"
               width="650px">
      <el-form :model="dataForm"
               label-position="left"
               ref="formRules"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="选择班级"
                      prop="flex_grade_id">
          <!-- clearable -->
          <el-select style="width: 100%;"
                     v-model="dataForm.flex_grade_id"
                     filterable
                     placeholder="请搜索或选择班级"
                     @change="classChange"
                     @focus="getGradelist"
                     reserve-keyword
                     remote
                     :remote-method="remoteMethod">
            <el-option v-for="(item, index) in options"
                       :key="index"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="授课老师">
          <div class="user-wrap">
            <div class="top">
              <el-link type="primary"
                       :underline="false"
                       :disabled="!grade_id"
                       @click="chooseDialog = true">选择老师</el-link>
            </div>
            <div class="bottom">
              <el-tag :disable-transitions="false"
                      v-if="teacherChoose.realname">
                {{ teacherChoose.realname }} | {{ teacherChoose.recruit_college_name }} | {{
                                    teacherChoose.test_subject_names }}
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="上课时长"
                      prop="flex_plan_lesson_hour">
          <el-time-picker v-model="hour"
                          style="width: 100%;"
                          :clearable="false"
                          :picker-options="{
                        selectableRange: '00:00:00 - 23:59:59'
                    }"
                          value-format="HH:mm:ss"
                          format="HH:mm:ss"
                          @change="timeChange"
                          placeholder="选择上课时间">
          </el-time-picker>
          <!-- <el-time-select style="width: 100%;" @change="timeChange" placeholder="选择上课时间" v-model="hour"
                        :picker-options="{
                            start: '00:00',
                            step: '00:10',
                            end: '24:00'
                        }">
                    </el-time-select> -->
        </el-form-item>
        <el-form-item label="选择计划"
                      prop="flex_course_plan_id">
          <el-select :disabled="!teacherChoose.realname"
                     v-model="dataForm.flex_course_plan_id"
                     placeholder="请选择计划"
                     @change="planChange"
                     style="width: 100%;">
            <el-option v-for="(item, index) in planList"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
          <div class="hint"
               v-if="planObj.id && ((totalClassHours + plan_lesson_hour) < dataForm.flex_plan_lesson_hour)">
            *总课程时长仅剩{{ (totalClassHours + plan_lesson_hour).toFixed(2) }}小时，将按照<span style="color: #E34D59 ;">{{
                            (totalClassHours + plan_lesson_hour).toFixed(2) }}</span>小时进行确认
          </div>

          <div class="hint"
               v-else-if="planObj.id && planObj.plan_lesson_hour > dataForm.flex_plan_lesson_hour">
            *计划剩余时长{{ (planObj.plan_lesson_hour - dataForm.flex_plan_lesson_hour).toFixed(2) }}h，将自动加入到下个计划时长中
          </div>
          <div class="hint"
               v-else-if="planObj.id && planObj.plan_lesson_hour < dataForm.flex_plan_lesson_hour">
            *超出计划时长{{ (dataForm.flex_plan_lesson_hour - planObj.plan_lesson_hour).toFixed(2) }}h，将自动从下个计划时长中扣除
          </div>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button size="small"
                   @click="emitClose()">取消</el-button>
        <el-button type="primary"
                   size="small"
                   @click="cancel('formRules')">确定</el-button>
      </div>
    </el-dialog>
    <MatchingTeacher :isVisible="chooseDialog"
                     @close="chooseDialog = false"
                     @submit="chooseTeacher"
                     :grade_id="grade_id" />
  </div>
</template>

<script>
import { gradelist, coursePlanlist, flexLessonLog } from '@/request/api'
import MatchingTeacher from '@/components/DialogComponents/ClassService/MatchingTeacher.vue'
import { getStorage } from '@/utils/helper'
export default {
  components: {
    MatchingTeacher,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: [],
      classIndex: 1,
      classTotal: 0,
      plan_lesson_hour: 0, //计划累加课时
      totalClassHours: 0, //
      hour: '00:00:00',
      teacherChoose: {},
      grade_id: null,
      chooseDialog: false,
      dialogFormVisible: this.isVisible,
      dataForm: {
        flex_course_plan_id: '',
        flex_user_type: 'learn_manager',
        flex_grade_id: '',
        flex_plan_lesson_hour: '',
        learn_manager: getStorage('userInfo').id,
      },
      classList: [],
      planList: [],
      planListAll: [],
      planObj: {},
      rules: {
        flex_grade_id: [
          { required: true, message: '请选择班级', trigger: 'blur' },
        ],
        flex_plan_lesson_hour: [
          { required: true, message: '请选择上课时长', trigger: 'blur' },
        ],
        flex_course_plan_id: [
          {
            required: true,
            message: '请选择计划(请先选择授课老师)',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        this.getGradelist()
      }
    },
  },
  methods: {
    // loadmore() {
    //   console.log('滚动到底部')
    //   if (this.classList.length < this.classTotal) {
    //     this.classIndex++
    //     this.getGradelist()
    //   } else {
    //     console.log('取消')
    //   }
    // },
    remoteMethod(query) {
      console.log('query', this.query)
      if (query !== null) {
        gradelist({
          isPaging: 0,
          name_keyword: query,
          is_grade_plan_data: 1,
        }).then((response) => {
          let res = response.data
          this.options = res.data.list
        })
      } else {
        this.options = []
      }
    },
    // 获取班级
    getGradelist() {
      this.options = []
      gradelist({
        pageIndex: 1,
        pageSize: 100,
        isPaging: 1,
        is_grade_plan_data: 1,
      }).then((res) => {
        this.options = this.options.concat(res.data.data.list)
        // this.classTotal = res.data.data.count
      })
    },
    // 获取计划
    getCoursePlanlist(id) {
      coursePlanlist({
        teacher_id: id,
        is_log_ref: 0,
        plan_statuses: '20,21,22', //20,21,22 ：未完成
        grade_id: this.grade_id, //班级id
      }).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.list.length > 0) {
            this.plan_lesson_hour = 0
            res.data.data.list.forEach((item) => {
              this.plan_lesson_hour += item.plan_lesson_hour
            })
          }

          this.planListAll = res.data.data.list
          this.planList = []
          res.data.data.list.map((item) => {
            this.planList.push({
              label:
                this.$moment(item.plan_start_time).format('YYYY-MM-DD HH:mm') +
                ' | ' +
                item.course_content +
                ' | ' +
                '时长' +
                item.plan_lesson_hour +
                '小时',
              value: item.id,
            })
          })
        }

        //
        // console.log("计划", res)
      })
    },
    // 选择老师
    chooseTeacher(e) {
      this.dataForm.flex_course_plan_id = ''
      this.planObj = {}
      this.teacherChoose = e.data
      this.getCoursePlanlist(e.data.id)
    },
    // 选择
    timeChange(e) {
      console.log('e', e)
      let time = e.split(':')
      // this.hour = Number(time[0]) + '小时' + Number(time[1]) + '分钟'
      this.dataForm.flex_plan_lesson_hour =
        Number(time[0]) + Number((Number(time[1]) / 60).toFixed(2))
      // console.log(this.flex_plan_lesson_hour,"e")
    },
    // 选择班级
    classChange(e) {
      this.grade_id = e
      this.dataForm.flex_course_plan_id = ''
      this.planObj = {}
      this.teacherChoose = {}
      let obj = this.classList.filter((item) => item.id == e)[0]
      this.totalClassHours = obj.grade_plan_data.plan_last_lesson_hour
      // console.log("E",e)
    },
    // 选择计划
    planChange(e) {
      this.planObj = this.planListAll.filter((item) => item.id == e)[0]
      // console.log(e,'e')
    },
    emitClose() {
      this.classIndex = 1
      this.$emit('close')
      this.classList = []
    },
    cancel(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          console.log('res', this.dataForm)
          flexLessonLog(this.dataForm).then((res) => {
            if (res.data.code == 0) {
              this.$message.success('新建成功')
              this.$emit('close')
              this.$emit('submit')
            }
            // //
            // console.log("res",res)
          })
        }
      })
    },
  },
}
</script>


<style lang="less" scoped>
.hint {
  font-size: 3rpx;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: #ed7b2f;
  line-height: 6rpx;
}

.user-wrap {
  .top {
    display: flex;
    align-items: center;
  }

  .bottom {
  }
}
</style>
<!-- isVisible: {
    type: Boolean,
    default: false,
  }, -->