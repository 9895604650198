<template>
  <div
    v-drag
    class="content"
    v-if="dialogFormVisible"
  >
    <div class="title">对比列表</div>
    <div class="list-wrap">
      <div
        class="item"
        v-for="(item,index) in list"
        :key="index"
      >
        {{ index+1 }}.{{item.name}}
      </div>
    </div>
    <div class="btn-wrap">
      <el-button
        @click="handleToClose()"
        size="mini"
      >清空列表</el-button>
      <el-button
        type="primary"
        @click="handleToContrastDetail()"
        size="mini"
        :disabled="list.length!=2"
      >开始对比</el-button>
    </div>
    <ContrastDetail
      :list="list"
      :isVisible="detailDialog"
      @close="detailDialog=false"
    ></ContrastDetail>
  </div>
</template>
  <script>
import {

} from "@/request/api";

import {

} from "@/utils/helper";

import ContrastDetail from '@/components/DialogComponents/TeacherResources/ContrastDetail.vue'



export default {
  components: {
    ContrastDetail
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      list: [],
      detailDialog: false,
    };
  },
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let odiv = el;   //获取当前元素
        el.onmousedown = (e) => {
          //算出鼠标相对元素的位置
          let disX = e.clientX - odiv.offsetLeft;
          let disY = e.clientY - odiv.offsetTop;
          let left = '';
          let top = '';
          document.onmousemove = (e) => {

            //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            left = e.clientX - disX;
            top = e.clientY - disY;
            var docWidth = document.body.clientWidth//网页可见宽
            var docHeight = document.body.clientHeight//网页可见高
            if (top > docHeight - 210) {//超下边界(键盘DIV长宽分别为 295px、210px)
              top = docHeight - 210
            }
            if (top < 0) {//超上边界
              top = 0
            }
            if (left > docWidth - 295) {//超右边界
              left = docWidth - 295
            }
            if (left < 0) {//超左边界
              left = 0
            }
            //绑定元素位置到positionX和positionY上面
            //移动当前元素
            odiv.style.left = left + 'px';
            odiv.style.top = top + 'px';
          };
          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      }
    }
  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    data(val) {
      if (val && this.list.length < 2) {
        this.list.push(val)
      }
    }
  },
  methods: {
    handleToClose() {
      this.list = []
      this.$emit("close");
    },
    handleToContrastDetail() {
      this.detailDialog = true
    }
  },
};
      </script>
  <style lang="less" scoped>
.content {
  min-height: 180px;
  position: fixed;
  top: 200px;
  left: 50%;
  width: 300px;
  background: #feffff;
  box-shadow: 0px 8px 12px 1px rgba(0, 0, 0, 0.08);
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  border: 1px solid #dcdcdc;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .title {
    text-align: center;
  }
  .list-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 18px 0px;
    .item {
      padding: 14px 18px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 12px;
      overflow: hidden;
    }
  }
}
</style>