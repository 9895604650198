<template>
  <el-dialog
top="5vh"
    title="选择学长"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="970px"
    @close="emitClose"
  >
    <div class="filters">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="mini"
      >
        <el-form-item
          label="学长"
          prop="keyword"
        >
          <el-input
            v-model="query.keyword"
            placeholder="输入姓名/手机号"
            @input="fetSeniorList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="院校"
          prop="college"
        >
          <el-input
            v-model="query.college"
            placeholder="输入院校名称/代码"
            @input="fetSeniorList(query,true)"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item
          label="专业"
          prop="major"
        >
          <el-input
            v-model="query.major"
            placeholder="输入专业名称/代码"
            @input="fetSeniorList(query,true)"
            clearable
          ></el-input>
        </el-form-item>

      </el-form>
    </div>
    <el-table
      :data="results"
      style="width: 100%"
      tooltip-effect="dark"
      size="mini"
      border
    >
      <el-table-column
        prop="id"
        label="用户ID"
        width="80"
        align="center"
        fixed="left"
      >
      </el-table-column>
      <el-table-column
        prop="realname"
        label="姓名"
        min-width="100"
        align="center"
        fixed="left"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.realname }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="mobile"
        label="手机号"
        min-width="120"
        align="center"
        fixed="left"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.mobile }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="院校"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.recruit_college_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="专业"
        min-width="200"
        align="center"
      >
        <template slot-scope="scope">
          <span>({{ scope.row.recruit_major_code || "-" }}){{
                  scope.row.recruit_major_name || "-"
                }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="学院"
        min-width="200"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.recruit_academy_name }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="课时费"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.fee_amount }}元/小时</span>
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag
            size="small"
            :type="scope.row.status == 1 ? 'success' : 'danger'"
          >{{ scope.row.status == 1 ? "正常" : "禁用" }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="描述"
        width="220"
        align="center"
        prop="description"
      >
        <template slot-scope="scope">
          <span>{{scope.row.description?scope.row.description:'无'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="invite_info"
        label="邀请人"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="创建时间"
        min-width="160"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{
                $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
              }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="选择"
        min-width="160"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="choose(scope.row)"
          >选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <div class="block">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </el-dialog>

</template>

<script>

import { adminUrl } from "@/utils/helper.js";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      results: [],
      filter: {
        status: [
          { label: "全部", value: "" },
          { label: "正常", value: "1" },
          { label: "禁用", value: "0" },
        ],
      },
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        status: 1
      }
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  mounted() {
    this.fetSeniorList(this.query)
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    fetSeniorList(query, isReset = false) {
      if (isReset) {
        this.query.pageIndex = 1
      }
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/senior/list`,
        method: 'get',//可以省略 不写默认是get
        params: query
      }).then(res => {
        this.results = res.data.data.list
        this.pageTotal = res.data.data.count;
      })
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetSeniorList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.fetSeniorList(this.query);
    },
    choose(row) {
      this.$emit('submit', row)
      this.emitClose()
    },

  },
};
</script>

<style lang="less" scoped>
.filters {
  margin: 16px 0px;
}
</style>