<template>
  <div>
    <el-dialog
top="5vh"
      title="任务管理"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="110px"
        size="small"
      >
        <el-form-item
          label="任务要求："
          prop="demand"
        >
          <el-input
            type="textarea"
            v-model="dataForm.demand"
            placeholder="请输入任务要求"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="任务类型："
          prop="type"
        >
          <el-select
            v-model="dataForm.type"
            placeholder="请选择"
            style="width:260px;"
            clearable
          >
            <el-option
              label="辅导推荐"
              :value="1"
            ></el-option>
            <el-option
              label="资料制作"
              :value="2"
            ></el-option>
            <el-option
              label="讲座答疑"
              :value="3"
            ></el-option>
            <el-option
              label="课程分销"
              :value="4"
            ></el-option>
            <el-option
              label="其他综合"
              :value="5"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="要求人数："
          prop="work_user_count"
        >
          <el-input-number v-model="dataForm.work_user_count"></el-input-number>
        </el-form-item>
        <el-form-item
          label="截止时间："
          prop="end_time"
        >
          <el-date-picker
            v-model="dataForm.end_time"
            type="datetime"
            style="width:260px;"
            placeholder="请选择日期时间"
            clearable
            value-format="timestamp"
            default-time="23:59:59"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="佣金："
          prop="referrer_amount"
        >
          <el-input
            placeholder="请输入佣金"
            v-model="dataForm.referrer_amount"
            type="number"
            style="width:300px;"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="任务联系人："
          prop="contact_user"
        >
          <div class="input-wrap">
            <el-input
              v-model="dataForm.contact_user_name"
              placeholder="请输入任务联系人"
            ></el-input>
            <el-input
              v-model="dataForm.contact_user_wechat"
              placeholder="请输入任务联系人微信号"
            ></el-input>
          </div>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>



import {
  taskCreate
} from "@/request/api";

import {
  getStorage
} from "@/utils/helper";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        demand: [{ required: true, message: "请输入任务要求", trigger: "blur" }],
        type: [{ required: true, message: "请选择任务类型", trigger: "blur" }],
        work_user_count: [{ required: true, message: "请输入任务要求人数", trigger: "change" }],
        end_time: [{ required: true, message: "请选择截止时间", trigger: "blur" }],
        referrer_amount: [{ required: true, message: "请输入佣金", trigger: "blur" }],
        contact_user: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.dataForm.contact_user_name || !this.dataForm.contact_user_wechat) {
              callback(new Error('请输入联系人信息'))
            } else {
              callback()
            }
          },
        }],
      },

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        console.log('pppp');
      }
    },
  },
  methods: {

    emitClose() {
      this.$refs['formRules'].clearValidate();
      this.$emit("close");
    },


    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.dataForm)
          taskCreate(params).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.emitClose()
              this.$emit('submit')
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
</script>

<style lang="less" scoped>
.input-wrap {
  display: flex;
  .el-input {
    margin-right: 16px;
  }
}
</style>