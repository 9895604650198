<template>
  <div class="content">
    <div class="demo-drawer__content">
      <el-form
        :label-position="labelPosition"
        :model="form"
        :inline="true"
        size="mini"
        ref="ruleForm"
      >
        <el-form-item label="师资委派状态" prop="teacher_matching_status">
          <el-select
            v-model="form.teacher_dispatch_status"
            placeholder="请选择师资委派状态"
            @change="submit"
            clearable
          >
            <el-option label="开启" value="1"></el-option>
            <el-option label="关闭" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="师资匹配状态" prop="teacher_matching_status">
          <el-select
            v-model="form.teacher_matching_status"
            placeholder="请选择师资匹配状态"
            @change="submit"
            clearable
          >
            <el-option label="开启" value="1"></el-option>
            <el-option label="关闭" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="师资匹配价格小于" prop="source">
          <el-input
            v-model="form.teacher_matching_price"
            placeholder="请输入价格"
            style="width: 230px"
            @input="submit"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="合同到期时间" prop="region">
          <el-select
            v-model="coop_end_time"
            placeholder="请选择合同到期时间"
            @change="submit"
            clearable
          >
            <el-option
              v-for="item in timeArr"
              :key="item.timestamp"
              :label="item.name"
              :value="item.timestamp"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      dialogFormVisible: this.isVisible,
      labelPosition: "left",
      coop_end_time: "",
      timeArr: [
        {
          name: "一个月",
          timestamp: Date.now() + 30 * 24 * 60 * 60 * 1000,
        },
        {
          name: "两个月",
          timestamp: Date.now() + 60 * 24 * 60 * 60 * 1000,
        },
        {
          name: "三个月",
          timestamp: Date.now() + 90 * 24 * 60 * 60 * 1000,
        },
        {
          name: "六个月",
          timestamp: Date.now() + 180 * 24 * 60 * 60 * 1000,
        },
        {
          name: "一年",
          timestamp: Date.now() + 360 * 24 * 60 * 60 * 1000,
        },
        {
          name: "两年",
          timestamp: Date.now() + 720 * 24 * 60 * 60 * 1000,
        },
        {
          name: "三年",
          timestamp: Date.now() + 1440 * 24 * 60 * 60 * 1000,
        },
      ],
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit() {
      // 判断，因为为跟进时间传end_advance_time不传start_advance_time
      if (this.coop_end_time) {
        this.form.coop_end_time = this.coop_end_time;
      } else {
        this.form.coop_end_time = "";
      }
      this.$emit("submit", { screenForm: this.form });
    },
  },
};
</script>

<style lang="less" scoped>
.demo-drawer__content {
  padding-top: 24px;
  height: 100%;
}
.tag-wrap {
  width: 178px;
  display: flex;
  flex-wrap: wrap;
}
.subject-item {
  margin-right: 24px;
  margin-bottom: 5px;
}
.demo-drawer__footer {
  margin-bottom: 24px;
  float: right;
}
.el-select {
  width: 184px;
}
</style>