<template>
  <div class="content">
    <el-dialog
      top="5vh"
      title="新建机构"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="680px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="145px"
        label-position="right"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item
          label="机构简称"
          prop="name"
        >
          <el-input
            v-model="form.name"
            clearable
            style="width: 100%"
            placeholder="请输入机构简称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="品牌名称"
          prop="brand_name"
        >
          <el-input
            v-model="form.brand_name"
            clearable
            style="width: 100%"
            placeholder="请输入品牌名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司名称"
          prop="company_name"
        >
          <el-input
            v-model="form.company_name"
            clearable
            style="width: 100%"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司所在地"
          prop="company_area"
        >
          <el-input
            v-model="form.company_area"
            clearable
            style="width: 100%"
            placeholder="请输入公司所在地"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系人"
          prop="contact_name"
        >
          <el-input
            placeholder="请输入姓名"
            v-model="form.contact_name"
            class="input-with-select"
            clearable
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label=""
          prop="contact_phone"
          style="margin-top: -20px"
        >
          <el-input
            placeholder="请输入手机号"
            v-model="form.contact_phone"
            class="input-with-select"
            clearable
            style="width: 100%"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label=""
          style="margin-top: -20px"
        >
          <el-select
            v-model="form.contact_title"
            placeholder="请选择职务"
            style="width: 100%"
          >
            <el-option
              v-for="item in $store.state.MechanismRoleList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="公司地址"
          prop="company_address"
        >
          <el-cascader
            :props="defaultProps"
            :options="region"
            v-model="regionmodel"
            style="width: 100%"
          ></el-cascader>
          <el-input
            v-model="form.company_address"
            clearable
            style="width: 100%; margin-top: 16px"
            placeholder="详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="缴纳保证金"
          prop="deposit_status"
        >
          <el-select
            v-model="form.deposit_status"
            placeholder="请选择是否缴纳保证金"
            clearable
            style="width: 100%"
          >
            <el-option
              label="需要"
              value="1"
            ></el-option>
            <el-option
              label="不需要"
              value="0"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="保证金金额"
          prop="name"
          v-if="form.deposit_status == 1"
        >
          <el-input
            v-model="form.deposit"
            placeholder="请输入保证金金额"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item
          label="是否开启一口价"
          prop="share_static_amount_status"
        >
          <el-select
            v-model="form.share_static_amount_status"
            placeholder="请选择是否开启共享平台一口价"
            clearable
            style="width: 100%"
          >
            <el-option
              label="是"
              :value="1"
            ></el-option>
            <el-option
              label="否"
              :value="0"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="一口价价格"
          prop="share_static_amount"
          v-if="form.share_static_amount_status == 1"
        >
          <el-input
            v-model="form.share_static_amount"
            placeholder="请输入一口价价格"
            clearable
          ></el-input>
        </el-form-item>



        <el-form-item
          label="是否开启师资匹配"
          prop="teacher_matching_status"
        >
          <el-select
            v-model="form.teacher_matching_status"
            placeholder="请选择是否开启师资匹配"
            clearable
            style="width: 100%"
          >
            <el-option
              label="是"
              value="1"
            ></el-option>
            <el-option
              label="否"
              value="0"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="师资匹配单价"
          prop="name"
          v-if="form.teacher_matching_status == 1"
        >
          <el-input
            v-model="form.teacher_matching_price"
            placeholder="请输入师资匹配单价"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否开启师资派遣"
          prop="teacher_dispatch_status"
        >
          <el-select
            v-model="form.teacher_dispatch_status"
            placeholder="请选择是否开启师资派遣"
            clearable
            style="width: 100%"
          >
            <el-option
              label="是"
              value="1"
            ></el-option>
            <el-option
              label="否"
              value="0"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="是否共享手机号"
          prop="is_share_mobile"
        >
          <el-select
            v-model="form.is_share_mobile"
            placeholder="请选择是否共享手机号"
            clearable
            style="width: 100%"
          >
            <el-option
              label="是"
              :value="1"
            ></el-option>
            <el-option
              label="否"
              :value="0"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构组">
          <el-select
            v-model="form.company_group"
            placeholder="请选择机构组"
            style="width: 100%"
            multiple
          >
            <el-option
              v-for="(item,index) in $store.state.groupList"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="课时小时转换比率"
          prop="lesson_hour_hour_rate"
        >
          <el-input
            placeholder="请输入转换比"
            v-model="form.lesson_hour_hour_rate"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="合作期间"
          prop="coop_time"
        >
          <el-date-picker
            v-model="form.coop_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="上传协议"
          prop="agreement"
          ref="agreementUpload"
        >
          <el-upload
            class="upload-demo"
            action="https://manage-api.yanshizi.com/api/v1/upload/file"
            :limit="1"
            :on-success="handleSuccessFile"
            :on-exceed="handleExceed"
            ref="upload"
            :file-list="filelist"
          >
            <el-button
              size="small"
              type="primary"
            >点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="负责人"
          prop="manage_watcher"
        >
          <el-select
            @change="$forceUpdate()"
            v-model="form.manage_watcher"
            placeholder="请选择"
            style="width: 410px"
            multiple
          >
            <el-option
              v-for="(item,index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            >
              <span> {{ `${item.nickname}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="默认对接人"
          prop="manage_docking_man"
        >
          <el-select
            @change="$forceUpdate()"
            v-model="form.manage_docking_man"
            placeholder="请选择"
            style="width: 410px"
            multiple
          >
            <el-option
              v-for="(item,index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            >
              <span> {{ `${item.nickname}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.remark"
            type="textarea"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      form: {},
      //省市区数据
      region: regionData,
      regionmodel: [],
      filelist: [],
      address: "",
      // cascader绑定属性
      defaultProps: {
        label: "label",
        value: "label",
        children: "children",
      },
      // 角色options
      userroleOption: [],
      rules: {
        lesson_hour_hour_rate: [{ required: true, message: "请输入转换比率", trigger: "blur" },
        { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }],
        name: [{ required: true, message: "请输入机构简称", trigger: "blur" }],
        brand_name: [
          { required: true, message: "请输入品牌名称", trigger: "blur" },
        ],
        contact_name: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        contact_phone: [
          { required: true, message: "请输入联系人手机号", trigger: "blur" },
        ],
        company_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        company_area: [
          { required: true, message: "请输入公司所在地", trigger: "blur" },
        ],
        company_address: [
          { required: true, message: "请输入公司地址", trigger: "blur" },
        ],
        agreement: [{ required: true, message: "请上传协议", trigger: "blur" }],
        deposit_status: [
          {
            required: true,
            message: "请选择是否需要缴纳保证金",
            trigger: "blur",
          },
        ],
        coop_time: [
          {
            required: true,
            message: "请选择合作时间",
            trigger: "blur",
          },
        ],
        is_share_mobile: [{ required: true, message: "请上传协议", trigger: "blur" }],
      },
    };
  },
  mounted() { },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.$set(this.form, 'lesson_hour_hour_rate', 0.75)
      }
    },
  },
  methods: {
    emitClose() {
      this.form = {};
      this.regionmodel = [];
      this.$refs["upload"].clearFiles();
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let arr = this.regionmodel;
          let str = arr[0] + arr[1] + arr[2];
          this.form.company_address = str + this.form.company_address;
          // 合作时间区间处理
          if (this.form.coop_time) {
            this.form.coop_start_time = this.form.coop_time[0];
            this.form.coop_end_time = this.form.coop_time[1] + 86399000;
          } else {
            this.form.coop_start_time = "";
            this.form.coop_end_time = "";
          }
          if (this.form.manage_watcher) {
            this.form.manage_watcher = this.form.manage_watcher.join(",");
          }
          if (this.form.company_group) {
            this.form.company_group = this.form.company_group.join(",");
          }
          if (this.form.manage_docking_man) {
            this.form.manage_docking_man = this.form.manage_docking_man.join(",");
          }
          this.$emit("submit", { form: this.form });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 文件上传成功处理
    handleSuccessFile(response, file, fileList) {
      this.form.agreement = fileList[0].response.data.url;
      this.$refs.agreementUpload.clearValidate(); // 关闭校验
    },
    // 文件超出一个
    handleExceed(files, fileList) {
      this.$notify({
        title: "提示",
        message: "只能上传一个文件",
        type: "warning",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
.el-input {
  width: 318px;
}
</style>