<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>员工</el-breadcrumb-item>
        <el-breadcrumb-item>角色管理</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button
        type="primary"
        @click="opendialogAdd"
        size="small"
        :disabled="!$buttonPermission('rolemanagement:create')"
      >新建</el-button>
    </div>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column
          prop="id"
          label="ID"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="名称"
        ></el-table-column>
        <el-table-column
          prop="datarange"
          label="数据范围"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.datarange == 1">本人</span>
            <span v-show="scope.row.datarange == 2">本部门</span>
            <span v-show="scope.row.datarange == 3">本部门以及下属部门</span>
            <span v-show="scope.row.datarange == 4">全部</span>
            <span v-show="scope.row.datarange == 5">产品经理</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="120"
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              :type="scope.row.status == 1 ? 'success' : 'danger'"
            >{{ scope.row.status == 1 ? "正常" : "下线" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="操作"
          width="210"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="opendialogEdit(scope.row)"
              :disabled="!$buttonPermission('rolemanagement:edit')"
            >编辑</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="primary"
              :underline="false"
              @click="openAuthTreeDialog(scope.row)"
              :disabled="!$buttonPermission('rolemanagement:distribution')"
            >分配权限</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="danger"
              :underline="false"
              :disabled="!$buttonPermission('rolemanagement:delete')"
              @click="deleteItem(scope.row)"
            >
              删除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>
    <el-dialog
top="5vh"
      title="角色管理"
      :visible.sync="dialogFormVisible"
      width="570px"
    >
      <el-form
        :model="form"
        label-position="right"
        label-width="80px"
        size="small"
        ref="dialogform"
        :rules="rules"
      >
        <el-form-item
          label="名称"
          prop="name"
        >
          <el-input
            v-model="form.name"
            autocomplete="off"
            style="width: 455px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="数据范围"
          prop="datarange"
        >
          <el-select
            v-model="form.datarange"
            placeholder="请选择"
            style="width: 455px"
          >
            <el-option
              label="本人"
              :value="1"
            ></el-option>
            <el-option
              label="本部门"
              :value="2"
            ></el-option>
            <el-option
              label="本部门以及下属部门"
              :value="3"
            ></el-option>
            <el-option
              label="全部"
              :value="4"
            ></el-option>
            <el-option
              label="产品经理"
              :value="5"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="备注"
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="form.remark"
            autocomplete="off"
            style="width: 455px"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit(submitmethods)"
        >确 定</el-button>
      </div>
    </el-dialog>
    <PermissionAssignment
      :isVisible="AuthTreeDialog"
      :content="AuthTreeContent"
      @close="AuthTreeDialog = false"
      @submit="AuthTreeSubmit"
      type="user"
    >
    </PermissionAssignment>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import PermissionAssignment from "../../../components/DialogComponents/PermissionAssignment/PermissionAssignment.vue";
import {
  createuserrole,
  userrolelist,
  edituserrole,
  deleteuserrole,
} from "../../../request/api";
export default {
  components: {
    TableTittle,
    PermissionAssignment,
  },
  data() {
    return {
      TableName: "角色列表",
      currentPage: 1,
      form: {
        status: "1",
      },
      dialogFormVisible: false,
      AuthTreeDialog: false,
      AuthTreeContent: {},
      submitmethods: "add",
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      pageTotal: 0,
      tableData: [],
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getList(this.query);
    this.$store.dispatch("getUserRuleList");
    this.$store.dispatch("getUserDeptList");
  },
  methods: {
   



    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    opendialogAdd() {
      this.submitmethods = "add";
      this.form = { status: 1 };
      this.dialogFormVisible = true;
    },
    opendialogEdit(item) {
      this.submitmethods = "edit";
      this.form = JSON.parse(JSON.stringify(item));
      this.$nextTick(() => {
        this.dialogFormVisible = true;
      });
    },
    getList(query) {
      userrolelist(query).then((res) => {
        this.tableData = res.data.data.list || [];
        this.pageTotal = res.data.data.count;
      });
    },
    submit(method) {
      this.$refs["dialogform"].validate((valid) => {
        if (valid) {
          let query = this.form;
          switch (method) {
            case "add":
              createuserrole(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false;
                  this.getList(this.query);
                });
              });
              break;
            case "edit":
              edituserrole(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false;
                  this.form = {};
                  this.getList(this.query);
                });
              });
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteItem(item) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            id: item.id,
          };
          deleteuserrole(query).then((res) => {
            this.$notify.success({
              title: "提示",
              message: "删除成功",
            });
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    openAuthTreeDialog(item) {
      this.AuthTreeContent = JSON.parse(JSON.stringify(item));
      this.AuthTreeDialog = !this.AuthTreeDialog;
    },
    AuthTreeSubmit({ obj }) {
      edituserrole(obj).then((res) => {
        this.$nextTick(() => {
          this.AuthTreeDialog = false;
          this.getList(this.query);
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.card-header {
  display: flex;
  justify-content: space-between;
  .el-button {
    height: 35px;
    line-height: 10px;
  }
}
.block {
  margin-top: 20px;
  margin-bottom: 14px;
  float: right;
}
</style>