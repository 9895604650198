import { render, staticRenderFns } from "./AddServiceStudent.vue?vue&type=template&id=01c9a3e8&scoped=true"
import script from "./AddServiceStudent.vue?vue&type=script&lang=js"
export * from "./AddServiceStudent.vue?vue&type=script&lang=js"
import style0 from "./AddServiceStudent.vue?vue&type=style&index=0&id=01c9a3e8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01c9a3e8",
  null
  
)

export default component.exports