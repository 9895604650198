<template>
    <div class="content">
        <el-dialog top="5vh" title="固定支付" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="570px">
            <el-form :model="queryForm" label-width="auto" label-position="left" size="small" ref="formRules"
                :rules="rules">
                <el-form-item label="支付金额" prop="fix_payment_amount">
                    <el-input v-model="queryForm.fix_payment_amount" placeholder="请输入支付金额">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>

                <el-form-item label="支付方式" prop="fix_payment_type">
                    <el-select v-model="queryForm.fix_payment_type" placeholder="请选择" style="width: 100%;">
                        <el-option label="支付宝" :value="1"></el-option>
                        <el-option label="微信" :value="2"></el-option>
                        <el-option label="余额" :value="3"></el-option>
                        <el-option label="转账" :value="4"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="支付状态" prop="fix_payment_status">
                    <el-select v-model="queryForm.fix_payment_status" placeholder="请选择" style="width: 100%;">
                        <el-option label="未支付" :value="0"></el-option>
                        <el-option label="已支付" :value="1"></el-option>
                        <el-option label="已退款" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="支付时间" prop="fix_payment_time">
                    <el-date-picker value-format="timestamp" v-model="queryForm.fix_payment_time" type="datetime"
                        format="yyyy-MM-dd HH:mm" style="width: 100%;" placeholder="请选择">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="支付截图" prop="fix_payment_img">
                    <el-upload :action="imgUploadUrl" list-type="picture-card" :file-list="queryForm.fix_payment_img" :limit="3"
                        :on-success="(response, file, fileList) => handleSuccess(response, file, fileList)"
                        :on-remove="(file, fileList) => handleRemove(file, fileList)">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="支付单号">
                    <el-input v-model="queryForm.fix_payment_trade_no" placeholder="请输入支付单号"></el-input>
                </el-form-item>
                <el-form-item label="支付备注">
                    <el-input v-model="queryForm.fix_payment_remark" placeholder="请输入支付备注" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit('formRules')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { editeacherorder } from "../../../request/api";
import { imgUploadUrl } from "@/request/http";
export default {
    data() {
        return {
            queryForm: {},
            dialogFormVisible: false,
            rules: {
                fix_payment_amount: [{ required: true, message: "请输入支付金额", trigger: "blur" },
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请输入正数(限两位小数)', trigger: 'blur' }],
                fix_payment_type: [{ required: true, message: "请选择支付方式", trigger: "blur" }],
                fix_payment_status: [{ required: true, message: "请选择支付状态", trigger: "blur" }],
                fix_payment_time: [{ required: true, message: "请选择支付时间", trigger: "blur" }],
            },
            imgUploadUrl: imgUploadUrl(),
            fileList: [],
        }
    },
    created() {

    },
    methods: {
        open(row) {
            console.log("rowT", row)
            this.queryForm = {
                "id": row.id,
                "fix_payment_amount": row.fix_payment_amount,
                "fix_payment_type": row.fix_payment_type,
                "fix_payment_status": row.fix_payment_status,
                "fix_payment_time": row.fix_payment_time,
                "fix_payment_img": row.fix_payment_img ? JSON.parse(row.fix_payment_img): [],
                "fix_payment_trade_no": row.fix_payment_trade_no,
                "fix_payment_remark": row.fix_payment_remark,
                "fix_payment_enable": 1
            }
            this.dialogFormVisible = true
        },

        submit(rules) {
            this.$refs[rules].validate((valid) => {
                if (valid) {
                    editeacherorder({
                        ...this.queryForm,
                        fix_payment_img:this.queryForm.fix_payment_img.length>0? JSON.stringify(this.queryForm.fix_payment_img):''
                    }).then(res => {
                        // console.log("res",res)
                        if (res.data.code == 0) {
                            this.dialogFormVisible = false
                            this.$emit("submit")
                            this.$message({
                                type: 'success',
                                message: '修改成功!'
                            });
                        }

                    })
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },

        handleSuccess(response, file, fileList) {
            this.queryForm.fix_payment_img.push({
                    name:response.data.name,
                    url:response.data.url,
                    uid:file.uid
                })
        },
        handleRemove(file, fileList) {
            // console.log("file",this.queryForm.fix_payment_img,file)
            this.queryForm.fix_payment_img =  this.queryForm.fix_payment_img.filter(item => item.uid != file.uid)
        },

    }
}
</script>

<style lang="less" scoped></style>