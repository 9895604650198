<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">任务管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button
          type="primary"
          size="small"
          @click="createDialog=true"
          v-if="!this.$route.query.teacher_id"
        >新建</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="任务要求关键字">
          <el-input
            v-model.trim="query.demand_keyword"
            placeholder="请输入任务要求关键字"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="任务状态">
          <el-select
            v-model="query.statuses"
            placeholder="请选择"
            @change="getList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.statuses"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务类型">
          <el-select
            v-model="query.types"
            placeholder="请选择"
            @change="getList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.types"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人关键字">
          <el-input
            v-model.trim="query.contact_user_keyword"
            placeholder="请输入联系人关键字"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="对接人">
          <el-select
            v-model="query.creators"
            placeholder="请选择"
            @change="getList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布日期">
          <el-date-picker
            class="datetime"
            v-model="create_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList(true)"
            clearable
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker
            class="datetime"
            v-model="end_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList(true)"
            clearable
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column
          type="selection"
          width="50"
          fixed="left"
        ></el-table-column>
        <el-table-column
          fixed="left"
          label="编号"
          width="120"
          prop="order_no"
        >
        </el-table-column>
        <el-table-column
          label="任务状态"
          width="100"
        >
          <template slot-scope="scope">
            <div class="tag-wrap">
              <el-tag
                v-if="scope.row.status==0"
                size="mini"
              >等待审核</el-tag>
              <el-tag
                v-if="scope.row.status==1"
                size="mini"
                type="warning"
              >正在进行</el-tag>

              <el-badge
                is-dot
                class="item"
                v-if="scope.row.status==2"
              >
                <el-tag
                  size="mini"
                  type="danger"
                  @click="openreason(scope.row)"
                >审核驳回</el-tag>
              </el-badge>
              <el-tag
                v-if="scope.row.status==3"
                size="mini"
                type="info"
              >任务结束</el-tag>
              <el-tag
                v-if="scope.row.status==4"
                size="mini"
                type="info"
              >任务取消</el-tag>
            </div>

          </template>
        </el-table-column>
        <el-table-column
          label="发布日期"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="任务类型"
          width="100"
        >
          <template slot-scope="scope">
            {{formateType(scope.row.type)}}
          </template>
        </el-table-column>
        <el-table-column
          label="任务要求"
          min-width="300"
          show-overflow-tooltip
          prop="demand"
        >
          <template slot-scope="scope">
            <div class="left">
              {{scope.row.demand}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="要求人数"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.work_user_count}}人
          </template>
        </el-table-column>
        <el-table-column
          label="截止日期"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.end_time?$moment(scope.row.end_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="佣金"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span style="color:#f56c6c;">
              {{scope.row.referrer_amount}}元
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="任务联系人"
          width="230"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.contact_user_name}} / {{scope.row.contact_user_wechat}}
          </template>
        </el-table-column>
        <el-table-column
          label="创建人"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="scope.row.creator">{{scope.row.creator.nickname}}</div>
            <div v-else-if="scope.row.admin_creator">{{scope.row.admin_creator.nickname}}</div>
            <div v-else>暂无</div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="230"
          v-if="$buttonPermission('taskRoom')"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.status==0"
              class="row"
            >
              <el-link
                type="primary"
                :underline="false"
                @click="handleToAccept(1,scope.row.id)"
              >通过</el-link>
              <el-link
                type="danger"
                :underline="false"
                @click="openRefuseDialog(scope.row.id)"
              >拒绝</el-link>
            </div>
            <div
              class="row"
              v-else
            >
              <el-link
                type="primary"
                :underline="false"
                @click="handleToEdit(scope.row)"
                :disabled="scope.row.status!=0"
              >编辑</el-link>
              <!--       <el-badge
                class="item"
                :value="scope.row.count_commit_log_tocheck?scope.row.count_commit_log_tocheck:0"
                :max="99"
                :hidden="!scope.row.count_commit_log_tocheck"
              >
                <el-link
                  type="primary"
                  :underline="false"
                  @click="handleToDetail(scope.row.id)"
                >查看详情</el-link>
              </el-badge> -->
              <div class="badge-content">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="handleToDetail(scope.row.id)"
                >查看详情</el-link>
                <div
                  class="badge"
                  v-if="scope.row.count_commit_log_tocheck"
                >{{scope.row.count_commit_log_tocheck?scope.row.count_commit_log_tocheck:0}}</div>
              </div>
              <el-link
                type="danger"
                :underline="false"
                :disabled="scope.row.status!=1"
                @click="handleToChangeStatus(scope.row.id)"
              >任务终止</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="230"
          v-if="!$buttonPermission('taskRoom')"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToEdit(scope.row)"
            >编辑</el-link>
            <el-link
              type="primary"
              :underline="false"
              disabled
            >查看详情</el-link>

            <el-link
              type="danger"
              :underline="false"
              disabled
            >任务终止</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button
          size="mini"
          type="danger"
          @click="handleToDeleteAll()"
        >批量删除</el-button>
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddTask
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="getList()"
    ></AddTask>
    <EditTask
      :isVisible="editDialog"
      :id="editId"
      @close="editDialog=false"
      @submit="getList()"
    ></EditTask>
    <RefuseDialog
      :isVisible="refuseVisible"
      @close="refuseVisible=false"
      @submit="RefuseDialogSubmit"
    ></RefuseDialog>
    <el-dialog
top="5vh"
      title="拒绝理由"
      :visible.sync="reasonDialog"
      width="700px"
      :close-on-click-modal="false"
      @close="reasonDialog=false"
    >
      {{reason}}
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="reasonDialog=false">取 消</el-button>
        <el-button
          type="primary"
          @click="reasonDialog=false"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import AddTask from "@/components/DialogComponents/ClassService/AddTask.vue";
import EditTask from "@/components/DialogComponents/ClassService/EditTask.vue";
import RefuseDialog from "@/components/DialogComponents/RefuseDialog.vue";



import {
  tasklist,
  tasklistDelete,
  taskcheck,
  taskEdit
} from "@/request/api";
export default {
  components: {
    TableTittle,
    AddTask,
    EditTask,
    RefuseDialog
  },
  data() {
    return {
      TableName: '任务列表',
      tableData: [],
      createDialog: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      chooseList: [],
      editDialog: false,
      editId: null,
      refuseVisible: false,
      create_time: "",
      end_time: "",
      reason: '',
      reasonDialog: false,
      filters: {
        statuses: [
          { label: "等待审核", value: 0 },
          { label: "正在进行", value: 1 },
          { label: "审核驳回", value: 2 },
          { label: "任务结束", value: 3 },
          { label: "任务取消", value: 4 },
        ],
        types: [
          { label: "辅导推荐", value: 1 },
          { label: "资料制作", value: 2 },
          { label: "讲座答疑", value: 3 },
          { label: "课程分销", value: 4 },
          { label: "其他综合", value: 5 },
        ]
      }
    };
  },
  created() {
    this.parseQuery();
    this.getList();

    this.$store.dispatch('getStaffOptions')

  },
  watch: {
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
        this.getList(true)
      }
    },
    // 时间数据监听
    end_time(value) {
      if (value) {
        this.query.start_end_time = this.$moment(value[0]).valueOf();
        this.query.end_end_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_end_time = "";
        this.query.end_end_time = "";
        this.getList(true)
      }
    },
  },
  computed: {

  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList();
    },
    getList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      if (params.statuses) {
        params.statuses = params.statuses.join(',')
      }
      if (params.types) {
        params.types = params.types.join(',')
      }
      if (params.creators) {
        params.creators = params.creators.join(',')
      }
      tasklist(params).then((response) => {
        let res = response.data
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
      if (this.query.statuses && this.query.statuses.length) {
        this.query.statuses = this.query.statuses.split(",").map(v => { return Number(v) });
      }
      if (this.query.types && this.query.types.length) {
        this.query.types = this.query.types.split(",").map(v => { return Number(v) });
      }
      if (this.query.creators && this.query.creators.length) {
        this.query.creators = this.query.creators.split(",").map(v => { return Number(v) });
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      if (params.statuses) {
        params.statuses = params.statuses.filter((v) => v).join(",");
      }
      if (params.statuses) {
        params.statuses = params.statuses.filter((v) => v).join(",");
      }
      if (params.creators) {
        params.creators = params.creators.filter((v) => v).join(",");
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    formateType(val) {
      let types = {
        '1': '辅导推荐',
        '2': '资料制作',
        '3': '讲座答疑',
        '4': '课程分销',
        '5': '其他综合',
      }
      return types[val]
    },
    handleToEdit(row) {
      this.editId = row.id
      this.editDialog = true
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm("确定要删除这些信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map(v => { return v.id })
        tasklistDelete({ ids }).then((res) => {
          this.getList(this.query);
        });
      })
    },
    handleToAccept(is_accept, id) {
      this.$confirm("确定要通过吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        taskcheck({
          id: id,
          is_access: is_accept
        }).then((res) => {
          this.getList(this.query);
        });
      })
    },
    RefuseDialogSubmit({ text }) {
      taskcheck({
        id: this.editId,
        is_access: 0,
        refuse_reason: text
      }).then((res) => {
        this.getList(this.query);
      });
    },
    openRefuseDialog(id) {
      this.refuseVisible = true
      this.editId = id
    },
    openreason(row) {
      this.reason = row.refuse_reason
      this.reasonDialog = true
    },
    handleToDetail(id) {
      this.$router.push(`/classservice/task/${id}`)
    },
    handleToChangeStatus(id) {
      this.$confirm("确定要取消任务吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        taskEdit({
          id: id,
          status: 4
        }).then((res) => {
          this.getList(this.query);
        });
      })
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  .tag-wrap {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .row {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.badge-content {
  position: relative;
  .badge {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #f56c6c;
    color: #fff;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -5px;
    top: -2px;
  }
}
.left {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
