<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>机构</el-breadcrumb-item>
        <el-breadcrumb-item>账号管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button type="primary"
                   size="small"
                   @click="openDialogFormAdd('add')"
                   :disabled="!$buttonPermission('accountmanagement:create')">新建</el-button>
      </div>
    </div>
    <el-card class="operation-card">
      <el-form :inline="true"
               class="demo-form-inline"
               size="small">
        <el-form-item label="搜索">
          <el-input v-model="query.keyword"
                    placeholder="机构简称/姓名/手机号"
                    @input="getList(query)"
                    clearable></el-input>
        </el-form-item>
      </el-form>
      <ScreenStaff :userroleOption="userroleOption"
                   @submit="scrrenTable"></ScreenStaff>
    </el-card>
    <el-card class="table-card"
             style="margin-top: 20px">
      <TableTittle :title="TableName"></TableTittle>
      <el-table :data="tableData"
                :header-cell-style="{
        textAlign: 'center',
        color: '#909399',
        fontFamily: 'PingFang SC',
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: '#F2F6FC',
      }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                border
                @selection-change="handleSelectionChange">
        >
        <el-table-column type="selection"
                         width="55"
                         show-overflow-tooltip
                         fixed>
        </el-table-column>
        <el-table-column prop="alias"
                         label="机构名称"
                         show-overflow-tooltip
                         width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.company.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="alias"
                         label="所在地"
                         show-overflow-tooltip
                         width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.company.company_area }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone"
                         label="账号（手机号）"
                         show-overflow-tooltip
                         width="120">
        </el-table-column>
        <el-table-column prop="alias"
                         label="姓名"
                         show-overflow-tooltip
                         width="120"></el-table-column>
        <!--         <el-table-column prop="depts" label="部门" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.depts | formate }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="roles"
                         label="角色"
                         show-overflow-tooltip
                         width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.roles | formateroles }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark"
                         label="备注"
                         show-overflow-tooltip
                         width="150">
          <template slot-scope="scope">
            <div class="inp">
              <el-input style="border:none"
                        v-model="scope.row.remark"
                        @input="inpRemark($event, scope.row)"></el-input>
            </div>

          </template>
        </el-table-column>

        <el-table-column prop="status"
                         label="状态"
                         width="80">
          <template slot-scope="scope">
            <el-tag size="small"
                    :type="scope.row.status == 1 ? 'success' : 'danger'">{{ scope.row.status == 1 ? "正常" :
              "下线" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="user_status"
                         label="是否封禁"
                         width="120">
          <template slot-scope="scope">
            <el-tag size="small"
                    :type="scope.row.user_status == 1 ? 'success' : 'danger'">{{ scope.row.user_status == 1 ? "正常" :
              "禁用" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="是否邀请"
                         width="80">
          <template slot-scope="scope">
            <el-tag size="small"
                    :type="scope.row.share_referrer_useful_status == 1 ? 'success' : 'danger'">{{
              scope.row.share_referrer_useful_status == 1 ? "开启" : "关闭" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="自动接单"
                         width="80">
          <template slot-scope="scope">
            <el-tag size="small"
                    :type="scope.row.share_referrer_auto_receiver_status == 1 ? 'success' : 'danger'">{{
              scope.row.share_referrer_auto_receiver_status == 1 ? "开启" : "关闭" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="邀请人"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.referrer_company_user_obj ?
              `${scope.row.referrer_company_user_obj.company_ref_name}-${scope.row.referrer_company_user_obj.alias}` : '-'
            }}
          </template>
        </el-table-column>

        <el-table-column prop="create_time"
                         label="更新日期"
                         show-overflow-tooltip
                         width="120">
          <template slot-scope="scope">
            {{ scope.row.update_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time"
                         label="创建日期"
                         show-overflow-tooltip
                         width="120">
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         width="150">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="openDialogFormEdit('edit', scope.row)"
                     :disabled="!$buttonPermission('accountmanagement:edit')">编辑</el-link>
            <el-link type="danger"
                     :underline="false"
                     @click="deleteItem(scope.row)"
                     :disabled="!$buttonPermission('accountmanagement:delete')">删除</el-link>
            <el-link type="warning"
                     :underline="false"
                     @click="openextendDialog(scope.row)"
                     :disabled="!$buttonPermission('accountmanagement:extend')">继承</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button size="small"
                   type="danger"
                   @click="chooseAllDelete">批量删除</el-button>
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog top="5vh"
               title="账号管理"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               width="570px">
      <el-form :model="dialogForm"
               label-width="auto"
               label-position="left"
               ref="dialogForm"
               :rules="rules"
               size="small">
        <el-form-item label="机构简称"
                      prop="cid">
          <el-select v-model="dialogForm.cid"
                     placeholder="请选择"
                     style="width: 366px"
                     filterable>
            <el-option v-for="item in $store.state.CompanyList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名"
                      prop="alias">
          <el-input v-model="dialogForm.alias"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号（即登陆账号）"
                      prop="phone">
          <el-input v-model="dialogForm.phone"
                    autocomplete="off"></el-input>
        </el-form-item>
        <!--         <el-form-item label="机构账号" prop="alias">
          <el-input v-model="dialogForm.alias" autocomplete="off"></el-input>
        </el-form-item> -->
        <!--         <el-form-item label="部门" prop="depts">
          <el-select
            v-model="dialogForm.depts"
            multiple
            collapse-tags
            placeholder="请选择"
            style="width: 366px"
          >
            <el-option
              v-for="item in userdepOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="角色"
                      prop="roles">
          <el-select v-model="dialogForm.roles"
                     multiple
                     collapse-tags
                     placeholder="请选择"
                     style="width: 366px">
            <el-option v-for="item in userroleOption"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- v-if="" -->
        <el-form-item label="取消单限额"
                      prop="max_unpayed_cancel_order_count"
                      v-if="title=='编辑'">
          <el-input v-model="dialogForm.max_unpayed_cancel_order_count"></el-input>
        </el-form-item>
        <el-form-item label="是否邀请">
          <el-switch v-model="dialogForm.share_referrer_useful_status"
                     :active-value="1"
                     :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="自动接单">
          <el-switch v-model="dialogForm.share_referrer_auto_receiver_status"
                     :active-value="1"
                     :inactive-value="0"></el-switch>
        </el-form-item>

        <el-form-item label="状态">
          <el-switch v-model="dialogForm.status"
                     :active-value="1"
                     :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="封禁账号">
          <el-switch v-model="dialogForm.user_status "
                     :active-value="0"
                     :inactive-value="1"></el-switch>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea"
                    v-model="dialogForm.remark"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submit(submitmethods)">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog top="5vh"
               title="选择继承该账号信息的员工"
               :visible.sync="extendDialog"
               :close-on-click-modal="false"
               width="470px">
      <el-form :model="extendForm"
               label-width="auto"
               label-position="left"
               ref="extendForm"
               :rules="extendRules">
        <el-form-item label="用户"
                      prop="uid_set">
          <el-select v-model="extendForm.uid_set"
                     placeholder="请选择"
                     filterable
                     style="width: 352px">
            <el-option v-for="(item, index) in staffOptions"
                       :key="index"
                       :label="item.alias"
                       :value="item.uid">
              {{ `${item.company.name}--${item.alias}` }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="extendDialog = false">取 消</el-button>
        <el-button type="primary"
                   @click="extendSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import ScreenStaff from '../../../components/ScreenComponents/ScreenStaff/ScreenStaff.vue'
import {
  crmdeptlist,
  crmrolelist,
  createcompanyuser,
  companyuserlist,
  deletecompanyuser,
  editcompanyuser,
  deletecompanyuserlist,
} from '../../../request/api'
export default {
  components: {
    TableTittle,
    ScreenStaff,
  },
  data() {
    return {
      title: '新增',
      formInline: {},
      dialogForm: { status: 1 },
      tableData: [],
      dialogFormVisible: false,
      ScreenStaffShow: false,
      TableName: '账号管理',
      submitmethods: '',
      // 部门options
      userdepOption: [],
      // 角色options
      userroleOption: [],
      rules: {
        alias: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: '请输入正确的手机号',
          },
        ],
        depts: [{ required: true, message: '请选择部门', trigger: 'blur' }],
        roles: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        cid: [{ required: true, message: '请选择机构', trigger: 'blur' }],
        max_unpayed_cancel_order_count: [
          { pattern: /^([0]|[1-9][0-9]*)$/, message: '请输入正整数' },
        ],
      },
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem('cid'),
      },
      pageTotal: 0,
      extendDialog: false,
      extendForm: {},
      extendRules: {
        uid_set: [{ required: true, message: '请选择用户', trigger: 'blur' }],
      },
      staffOptions: [],
    }
  },
  filters: {
    formate: function (val) {
      var str = ''
      val.forEach((item) => {
        str += item.name + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return str
      }
    },
    formateroles: function (val) {
      var str = ''
      val.forEach((item) => {
        str += item.name + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return str
      }
    },
  },
  mounted() {
    // 获取部门列表和角色列表做select
    this.getOptions()
    // 获取列表
    this.getList(this.query)
    this.$store.dispatch('getCompanyList')
    this.$store.dispatch('getUserRoleList')
    this.$store.dispatch('getCompanyUser')
  },
  methods: {
    inpRemark(e, item) {
      this.dialogForm = JSON.parse(JSON.stringify(item)) // 利用JSON深拷贝
      if (this.dialogForm.depts) {
        this.dialogForm.depts = this.dialogForm.depts.map((v) => Number(v.id))
      }
      if (this.dialogForm.roles) {
        this.dialogForm.roles = this.dialogForm.roles.map((v) => Number(v.id))
      }
      if (this.dialogForm.depts) {
        delete this.dialogForm.depts
      }
      this.dialogForm.roles = this.dialogForm.roles.join(',')
      let query = this.dialogForm
      editcompanyuser(query).then((res) => {
        // this.$nextTick(() => {
        //   // this.dialogFormVisible = false;
        //   this.getList(this.query);
        // });
      })
      // console.log("e",e)
    },
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val
    },
    getList(query) {
      companyuserlist(query).then((res) => {
        this.tableData = res.data.data.list || []
        this.pageTotal = res.data.data.count
      })
    },
    openDialogFormAdd(submitmethods) {
      this.title = '新增'
      this.submitmethods = submitmethods
      this.dialogForm = { status: 1 }
      this.dialogFormVisible = true
    },
    openDialogFormEdit(submitmethods, item) {
      this.title = '编辑'
      // Select多选做回显
      this.dialogForm = JSON.parse(JSON.stringify(item)) // 利用JSON深拷贝
      if (this.dialogForm.depts) {
        this.dialogForm.depts = this.dialogForm.depts.map((v) => Number(v.id))
      }
      if (this.dialogForm.roles) {
        this.dialogForm.roles = this.dialogForm.roles.map((v) => Number(v.id))
      }
      // Select多选做回显
      this.submitmethods = submitmethods
      this.dialogFormVisible = true
    },
    getOptions() {
      let query = {
        isPaging: 0,
      }
      crmdeptlist(query).then((res) => {
        this.userdepOption = res.data.data.list || []
        // console.log('this.userdepOption:', this.userdepOption)
      })
      crmrolelist(query).then((res) => {
        this.userroleOption = []
        this.userroleOption = res.data.data.list || []
        // console.log('this.userroleOption:', this.userroleOption)
      })
    },
    submit(submitmethods) {
      this.$refs['dialogForm'].validate((valid) => {
        if (valid) {
          /*  this.dialogForm.depts = this.dialogForm.depts.join(","); */
          if (this.dialogForm.depts) {
            delete this.dialogForm.depts
          }
          this.dialogForm.roles = this.dialogForm.roles.join(',')
          let query = this.dialogForm
          switch (submitmethods) {
            case 'add':
              createcompanyuser(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false
                  this.getList(this.query)
                })
              })
              break
            case 'edit':
              editcompanyuser(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false
                  this.getList(this.query)
                })
              })
              break
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deleteItem(item) {
      this.$confirm('确定要删除此条信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            id: item.id,
          }
          deletecompanyuser(query).then((res) => {
            this.$notify.success({
              title: '提示',
              message: '删除成功',
            })
            this.$nextTick(() => {
              this.getList(this.query)
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消删除',
          })
        })
    },
    // 选中数据后做处理删除
    chooseAllDelete() {
      this.$confirm('确定要删除选中的数据吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            ids: [],
          }
          this.deleteList.forEach((item) => {
            query.ids.push(item.id)
          })
          deletecompanyuserlist(query).then((res) => {
            this.$notify.success({
              title: '提示',
              message: '删除成功',
            })
            this.$nextTick(() => {
              this.getList(this.query)
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消删除',
          })
        })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getList(this.query)
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getList(this.query)
    },
    //筛选组件
    scrrenTable({ screenForm }) {
      this.$set(this.query, 'pageIndex', 1)
      this.query = {
        ...this.query,
        ...screenForm,
      }
      this.getList(this.query)
    },
    openextendDialog(row) {
      this.extendForm = JSON.parse(JSON.stringify(row))
      this.staffOptions = this.$store.state.companyUserList.filter((v) => {
        return v.id !== row.id
      })
      this.extendDialog = true
    },
    extendSubmit() {
      this.$refs['extendForm'].validate((valid) => {
        if (valid) {
          this.$confirm('确定要修改此条信息吗', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              let query = {
                id: this.extendForm.id,
                uid_set: this.extendForm.uid_set,
              }
              editcompanyuser(query).then((res) => {
                this.$notify.success({
                  title: '提示',
                  message: '修改成功',
                })
                this.$nextTick(() => {
                  this.extendDialog = false
                  this.$refs['extendForm'].resetFields()
                  this.getList(this.query)
                })
              })
            })
            .catch(() => {
              this.$notify.error({
                title: '提示',
                message: '已取消修改',
              })
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.inp {
  /deep/.el-input__inner {
    border: none !important;
    background-color: none !important;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}

.operation-card {
  width: 100%;
  margin-top: 20px;

  .demo-form-inline {
    height: 64px;
    border-bottom: 2px dashed #d2d5df;
  }
}

.operation-menu {
  display: flex;
  justify-content: space-between;

  .menu_left {
    margin-top: 25px;
  }

  .menu_right {
    margin-top: 25px;
  }
}

.block {
  margin-bottom: 14px;
  float: right;
}

.el-link {
  margin: 0px 5px;
}
</style>