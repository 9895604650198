<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item>老师管理</el-breadcrumb-item>
        <el-breadcrumb-item>数据统计</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px">

      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="省份">
          <el-select style="width: 200px"
                     @change="getDataList(true)"
                     v-model.trim="query.college_province_code"
                     filterable
                     collapse-tags
                     placeholder="请选择省份"
                     clearable>
            <el-option v-for="(item, index) in provinces"
                       :key="index"
                       :label="item.alias"
                       :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考院校：">
          <!--   filterable -->
          <el-select filterable
                     v-model="query.college_code"
                     placeholder="请选择院校"
                     style="width: 200px"
                     clearable
                     @change="collegeChoose">
            <el-option v-for="item in $store.state.collegeList"
                       :key="item.code"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业"
                      prop="apply_major">
          <el-select filterable
                     style="width: 200px"
                     v-model="query.major_code"
                     placeholder="请选择专业"
                     @change="majorChange"
                     clearable>
            <el-option v-for="(item, index) in majorOptions"
                       :key="index"
                       :label="item.allnames"
                       :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学院"
                      prop="apply_major">
          <el-select filterable
                     v-model="query.academy_code"
                     placeholder="请选择学院"
                     @change="getDataList(true)"
                     clearable
                     style="width: 200px">
            <el-option v-for="(item, index) in academyOptions"
                       :key="index"
                       :label="item.academy_name"
                       :value="item.academy_code">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="科目"
                      prop="apply_subjects">
          <el-input style="width: 200px"
                    v-model.trim="query.subjects_keyword"
                    placeholder="请输入科目"
                    @input="getDataList(true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="排序">
          <el-select value-key="id"
                     v-model="sortObj"
                     placeholder="请选择排序方式"
                     @change="getDataList(true)"
                     clearable
                     style="width: 200px">
            <el-option v-for="(item, index) in sortList"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="匹配师"
                      v-if="!$buttonPermission('matchmaker:show')">
          <el-select v-model="allocation_manage_idses"
                     placeholder="请选择"
                     @change="getDataList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="item in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 5 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- sortList -->
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <div class="card-header">
          <el-form size="small"
                   :inline="true">
            <el-form-item label-width="32px">
              <el-radio-group v-model="tableType"
                              @change="getDataList(true)">
                <el-radio-button :label="1">全部</el-radio-button>
                <el-radio-button :label="2">我的派单</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-table :data="dataList"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column label="序号"
                         width="50"
                         align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="college_province_name"
                         label="省份"
                         align="center"
                         width="80">
        </el-table-column>
        <el-table-column prop="college_name"
                         :show-overflow-tooltip="true"
                         label="院校"
                         align="left"
                         width="120">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true"
                         prop="academy_name"
                         label="学院"
                         align="left"
                         width="160">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true"
                         prop="major_name"
                         label="专业"
                         align="left"
                         width="180">
          <template slot-scope="scope">
            {{ `(${scope.row.major_code})${scope.row.major_name}` }}
          </template>
        </el-table-column>
        <el-table-column label="科目"
                         align="center"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ getSubjects(scope.row.subjects) }}
          </template>
        </el-table-column>
        <el-table-column prop="type_plans"
                         label="拟招生"
                         align="center"
                         width="200"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="hot_order_count"
                         label="热度"
                         align="center"
                         width="80">
        </el-table-column>
        <el-table-column prop="id"
                         label="总师资"
                         align="center"
                         width="80">
          <template slot-scope="scope">
            {{
              `${scope.row.college_major_teacher_obj.total_year_total_teacher_contract_count}/${scope.row.college_major_teacher_obj.total_year_total_teacher_confirm_count}`
            }}
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         :label="year1"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            {{
              `${scope.row.college_major_teacher_obj.before_year_1_total_teacher_contract_count}/${scope.row.college_major_teacher_obj.before_year_1_total_teacher_confirm_count}`
            }}
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         :label="year2"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            {{
              `${scope.row.college_major_teacher_obj.before_year_2_total_teacher_contract_count}/${scope.row.college_major_teacher_obj.before_year_2_total_teacher_confirm_count}`
            }}
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         :label="year3"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            {{
              `${scope.row.college_major_teacher_obj.before_year_3_total_teacher_contract_count}/${scope.row.college_major_teacher_obj.before_year_3_total_teacher_confirm_count}`
            }}
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         :label="year4"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            {{
              `${scope.row.college_major_teacher_obj.before_year_4_total_teacher_contract_count}/${scope.row.college_major_teacher_obj.before_year_4_total_teacher_confirm_count}`
            }}
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="操作"
                         width="100"
                         align="center"
                         fixed="right">
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link type="primary"
                       :underline="false"
                       @click="handleSchedule(scope.row)">查看</el-link>
              <el-link type="primary"
                       :underline="false"
                       @click="distribution(scope.row)"
                       v-if="$buttonPermission('taOrder:allocation')">派单</el-link>

            </div>
          </template></el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <Distribution :type="type"
                  :row="row"
                  :id="distributionId"
                  @close="distributionDialog = false"
                  :isVisible="distributionDialog"
                  @submit="getDataList"></Distribution>
  </div>
</template>

<script>
import { collegeMajorStatistic, majorlist, subjectlist } from '@/request/api'
import { adminUrl, getStorage } from '@/utils/helper.js'
import TableTittle from '@/components/TableTittle/TableTittle.vue'
import Distribution from '@/components/DialogComponents/TeacherResources/sendOrder.vue'
export default {
  components: {
    TableTittle,
    Distribution,
  },
  data() {
    return {
      tableType: 2,
      allocation_manage_idses: [getStorage('userInfo').id],
      type: '数据统计',
      distributionId: null,
      row: {},
      distributionDialog: false,
      year1: null,
      year2: null,
      year3: null,
      year4: null,
      TableName: '数据列表',
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      dataList: [],
      pageTotal: 0,
      provinces: [],
      majorOptions: [],
      academyOptions: [],
      subjectOptions: [],
      sortObj: '',
      sortList: [
        {
          label: '热度自高往低',
          value: {
            id: '0',
            arr: ['is_order_hot_count_desc', 1],
          },
        },
        {
          label: '热度自低往高',
          value: {
            id: '1',
            arr: ['is_order_hot_count_desc', 0],
          },
        },
        {
          label: '拟招生自高往低',
          value: {
            id: '2',
            arr: ['is_order_plan_count_desc', 1],
          },
        },
        {
          label: '拟招生自低往高',
          value: {
            id: '3',
            arr: ['is_order_plan_count_desc', 0],
          },
        },
        {
          label: '签约量自高往低',
          value: {
            id: '4',
            arr: ['is_order_contract_count_desc', 1],
          },
        },
        {
          label: '签约量自低往高',
          value: {
            id: '5',
            arr: ['is_order_contract_count_desc', 1],
          },
        },
      ],
    }
  },
  created() {
    this.$store.dispatch('getAllColleges')
    this.$store.dispatch('getStaffOptions')
    this.getDataList()
    this.fetchProvinceList()
  },
  methods: {
    distribution(row) {
      this.row = row
      this.distributionDialog = true
      this.distributionId = row.id
    },
    handleSchedule(row) {
      console.log('row', row)
      let url = `/tr/teachers?pageIndex=1&pageSize=10&isPaging=1&archive=0&first_contract_time=&pre_teacher_sync_status=&college_code=${row.college_code}&major=${row.major_code}`
      window.open(url)
    },
    getDataList(flag) {
      if (this.flag) {
        this.query.pageIndex = 1
      }
      if (this.tableType == 1) {
        this.query.teacher_allocation_manage_ids = ''
      } else {
        this.query.teacher_allocation_manage_ids =
          this.allocation_manage_idses.toString()
      }
      if (this.sortObj) {
        this.query[this.sortObj.arr[0]] = this.sortObj.arr[1]
      }
      // this.query.teacher_allocation_manage_ids =
      //   this.allocation_manage_idses.toString()
      collegeMajorStatistic(this.query).then((res) => {
        if (res.data.code == 0) {
          this.pageTotal = res.data.data.count
          this.dataList = res.data.data.list
          this.year1 =
            Number(this.dataList[0].college_major_teacher_obj.now_year) -
            1 +
            '师资'
          this.year2 =
            Number(this.dataList[0].college_major_teacher_obj.now_year) -
            2 +
            '师资'
          this.year3 =
            Number(this.dataList[0].college_major_teacher_obj.now_year) -
            3 +
            '师资'
          this.year4 =
            Number(this.dataList[0].college_major_teacher_obj.now_year) -
            4 +
            '师资'
        }
        // console.log(res);
      })
    },

    // 选择院校
    collegeChoose(e) {
      this.query.major_code = ''
      this.majorOptions = []
      this.query.academy_code = ''
      this.academyOptions = []
      this.getDataList(true)
      if (e) {
        this.getMajor(e)
      }
    },

    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      }
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          }
        })
      })
    },
    // 选择专业
    majorChange(e) {
      // console.log("pp",e)
      this.query.academy_code = ''
      this.academyOptions = []
      this.getDataList(true)
      if (e) {
        this.getMajorInfo(this.query.college_code, e)
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get', //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data
        this.academyOptions = res.data.academys
      })
    },

    // // 选择科目
    // academyChange(e){
    //      this.getSubjects();
    // },
    //  getSubjects() {
    //   let query = {
    //     isPaging: 0,
    //     college_code: this.query.college_code,
    //     academy_code: this.query.academy_code,
    //     major_code: this.query.major_code,
    //   };
    //   subjectlist(query).then((res) => {
    //     let obj = {
    //       id: 999,
    //       subject_code: "999",
    //       subject_name: "复试课程"
    //     }
    //     this.subjectOptions = res.data.data.list || [];
    //     this.subjectOptions.push(obj)
    //   });
    // },

    // 省份列表请求
    fetchProvinceList() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/province/list`,
        method: 'get', //可以省略 不写默认是get
      }).then((res) => {
        this.provinces = res.data.data.list
        // console.log(this.provinces);
      })
    },
    getSubjects(list) {
      if (list) {
        return JSON.parse(list).join('|')
      }
      return '-'
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getDataList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getDataList()
    },
  },
}
</script>


<style lang="less" scoped>
</style>