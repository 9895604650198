<template>
  <div class="dialog-wrap">
    <el-dialog
      top="5vh"
      title="所在班级"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="1200px"
    >
      <!--      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="mini"
      >
        <el-form-item label="班级名称">
          <el-input
            v-model.trim="query.name_keyword"
            placeholder="请输入班级名称"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="班级类型">
          <el-select
            v-model="query.types"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.types"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级状态">
          <el-select
            v-model="query.statuses"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学员">
          <el-input
            v-model.trim="query.student_keyword"
            placeholder="姓名/手机号"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="老师">
          <el-input
            v-model.trim="query.teacher_keyword"
            placeholder="姓名/手机号"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="院校">
          <el-input
            v-model.trim="query.apply_college_keyword"
            placeholder="请输入院校"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="专业">
          <el-input
            v-model.trim="query.apply_major_keyword"
            placeholder="请输入专业"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="学管师">
          <el-select
            v-model="query.learn_managers"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报名时间">
          <el-date-picker
            class="datetime"
            v-model="create_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="fetchList(true)"
            clearable
          >
          </el-date-picker>
        </el-form-item>
      </el-form> -->
      <el-table
        class="result"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column label="序号" width="80" type="index">
        </el-table-column>
        <el-table-column
          prop="name"
          label="班级名称"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="type"
          label="班级类型"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ formateType(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column label="班级状态" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag
                    v-if="scope.row.grade_sync_status == 1"
                    size="mini"
                    type="warning"
                    >待匹配</el-tag
                  >
                  <el-tag v-if="scope.row.grade_sync_status == 2" size="mini"
                    >匹配中</el-tag
                  >
                  <el-tag
                    v-if="scope.row.grade_sync_status == 3"
                    size="mini"
                    type="warning"
                    >待确认</el-tag
                  >
                  <el-tag
                    v-if="scope.row.grade_sync_status == 4"
                    size="mini"
                    type="warning"
                    >待上课</el-tag
                  >
                  <el-tag v-if="scope.row.grade_sync_status == 5" size="mini"
                    >上课中</el-tag
                  >
                  <el-tag
                    v-if="scope.row.grade_sync_status == 6"
                    size="mini"
                    type="danger"
                    >已过半</el-tag
                  >
                  <el-tag
                    v-if="scope.row.grade_sync_status == 7"
                    size="mini"
                    type="info"
                    >已终止</el-tag
                  >
                  <el-tag
                    v-if="scope.row.grade_sync_status == 8"
                    size="mini"
                    type="danger"
                    >已结束</el-tag
                  >
            <!-- <el-tag v-if="scope.row.status == 0" size="mini" type="warning"
              >正在对接</el-tag
            >
            <el-tag v-if="scope.row.status == 1" size="mini" type="success"
              >正在上课</el-tag
            >
            <el-tag v-if="scope.row.status == 2" size="mini" type="info"
              >课程终止</el-tag
            >
            <el-tag v-if="scope.row.status == 3" size="mini" type="danger"
              >课程结束</el-tag
            > -->
          </template>
        </el-table-column>
        <el-table-column
          prop="teacher_realnames"
          label="辅导老师"
          width="260"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              class="badge"
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              <div
                v-if="
                  scope.row.grade_sync_status != 1 &&
                  scope.row.grade_sync_status != 2 &&
                  scope.row.grade_sync_status != 3
                "
              >
                <span v-if="scope.row.teacher_realnames">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="ChooseTeacher(scope.row)"
                  >
                    {{ getTeachers(scope.row.teachers) }}
                  </el-link>
                </span>

                <span v-else-if="scope.row.teacher_realnames_end">
                  <el-link
                    type="info"
                    :underline="false"
                    @click="ChooseTeacher(scope.row)"
                  >
                    {{ getTeachers(scope.row.teachers_end) }}
                  </el-link>
                </span>
                <span v-else> - </span>
              </div>

              <el-link
                v-if="
                  scope.row.grade_sync_status == 1 &&
                  !scope.row.allocation_order_id
                "
                type="warning"
                :underline="false"
                @click="handleToMatching(scope.row)"
                >提交匹配</el-link
              >

              <el-badge
                v-if="
                  scope.row.grade_sync_status == 2 ||
                  scope.row.grade_sync_status == 3 ||
                  (scope.row.grade_sync_status == 1 &&
                    scope.row.allocation_order_id)
                "
                :value="scope.row.allocation_order_teacher_unconfirm_count"
                class="item"
                :hidden="!scope.row.allocation_order_teacher_unconfirm_count"
              >
                <el-link
                  type="success"
                  :underline="false"
                  @click="openConfirmList(scope.row)"
                  >确认列表</el-link
                >
              </el-badge>
            </div>
          </template>
          <!-- <template slot-scope="scope">
            <div
              v-if="scope.row.teacher_realnames"
              style="color: #409eff; cursor: pointer"
              @click="handleToDetail(scope.row.teacher_ids)"
            >
              {{ scope.row.teacher_realnames }}
            </div>
            <div
              v-else-if="scope.row.teacher_realnames_end"
              style="color: #909399; cursor: pointer"
              @click="handleToDetail(scope.row.teacher_ids_end)"
            >
              {{ scope.row.teacher_realnames_end }}
            </div>
            <div v-else>-</div>
          </template> -->
        </el-table-column>
        <el-table-column label="课程进度" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="
                scope.row.course_progress
                  ? parseFloat(
                      (Number(scope.row.course_progress) * 100).toFixed(2)
                    )
                  : 0
              "
            ></el-progress>
          </template>
        </el-table-column>

        <el-table-column label="班级总课时" width="150">
          <template slot-scope="scope">
            {{ scope.row.cocah_lesson_hour.toFixed(2) || 0 }}小时
          </template>
        </el-table-column>
        <el-table-column label="班级剩余课时" width="150">
          <template slot-scope="scope">
            {{
              (
                scope.row.cocah_lesson_hour - scope.row.sum_grade_lesson_hour
              ).toFixed(2)
            }}小时
          </template>
        </el-table-column>

        <!-- <el-table-column label="课程状态" width="120">
          <template slot-scope="scope">
            <el-tag size="mini" v-show="scope.row.student_grade_status == 1"
              >正常</el-tag
            >
            <el-tag
              size="mini"
              type="info"
              v-show="scope.row.student_grade_status == 0"
              >终止</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="报考信息" width="460" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.apply_college_name }}/（{{
              scope.row.apply_major_code
            }}）{{ scope.row.apply_major_name }}/{{
              scope.row.apply_academy_name
            }}
            /
            <span
              v-for="(item, index) in scope.row.apply_subjects || []"
              :key="index"
            >
              ({{ item.subject_code }}){{ item.subject_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="辅导课时" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.cocah_lesson_hour }}小时
          </template>
        </el-table-column>
        <el-table-column label="学管师" width="180">
          <template slot-scope="scope">
            {{
              scope.row.learn_manager ? scope.row.learn_manager.nickname : ""
            }}
          </template>
        </el-table-column> -->
        <el-table-column label="创建时间" width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="修改时间" width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <!--   <el-table-column
          fixed="right"
          label="操作"
          width="150"
        >
          <template slot-scope="scope">
            <el-link
              type="danger"
              :underline="false"
              @click="handleTorRemove(scope.row)"
            >移除班级</el-link>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="footer">
        <el-pagination
          background
          small
          layout="total, prev, pager, next,sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </div>
      <SeniorDetail
        :isVisible="SeniorDetailDialog"
        @close="SeniorDetailDialog = false"
        :id="detailId"
      />
      <ClassTeacherListIndex
        :id="ClassTeacherListId"
        :isVisible="ClassTeacherListDialog"
        @close="ClassTeacherListDialog = false"
      />

      <ClassGrade ref="classGrade" @submit="fetchList()"></ClassGrade>
      <Matching
      :id="matchId"
      :isVisible="matchDialog"
      @close="matchDialog = false"
      @submit="fetchList()"
    />
    </el-dialog>
  </div>
</template>

<script>
import { gradelist, gradelistEdit } from "@/request/api";
// import { manageUrl } from "@/utils/helper";
import ClassTeacherListIndex from "./ClassTeacherListIndex.vue";
import SeniorDetail from "./SeniorDetail.vue";
import ClassGrade from "../DialogComponents/ClassGrade/ClassGrade.vue";
import Matching from "@/components/DialogComponents/TeacherResources/Matching.vue";
export default {
  components: {
    SeniorDetail,
    ClassTeacherListIndex,
    ClassGrade,
    Matching,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
    single: {
      type: Boolean,
      default: false,
    },
    student_id: {
      type: Number,
      default: () => null,
    },
    student_realname: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      matchId:"",
      matchDialog:false,
      dialogVisible: this.isVisible,
      query: {
        keyword: "",
        college: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 10,
      results: [],
      selections: [],
      countSelections: [],
      filters: {
        types: [
          { label: "一对一", value: 0 },
          { label: "小班课", value: 1 },
          { label: "大班课", value: 2 },
          { label: "试听课", value: 3 },
        ],
        status: [
          { label: "正在对接", value: 0 },
          { label: "正在上课", value: 1 },
          { label: "课程终止", value: 2 },
          { label: "课程结束", value: 3 },
        ],
      },
      create_time: "",
      SeniorDetailDialog: false,
      detailId: null,
      ClassTeacherListDialog: false,
      ClassTeacherListId: null,
    };
  },
  filters: {},
  watch: {
    isVisible(val) {
      this.dialogVisible = val;

      if (val) {
        this.query = {
          pageIndex: 1,
          pageSize: 10,
          isPaging: 1,
        };
        this.fetchList();
      }
    },
    content(val) {
      this.countSelections = val;
    },
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
        this.fetchList(true);
      }
    },
  },
  methods: {
    ChooseTeacher(row) {
      this.$refs.classGrade.open({
        id: row.id,
        confirmOrder_id: row.allocation_order_obj
          ? row.allocation_order_obj.id
          : null,
      });
    },
     handleToMatching(row) {
      if (row.student_ids) {
        this.matchId = row.id;
        this.matchDialog = true;
      } else {
        this.$message.error("请选择上课学员");
      }
    },
     openConfirmList(row) {
      this.$refs.classGrade.open({
        id: row.id,
        confirmOrder_id: row.allocation_order_obj
          ? row.allocation_order_obj.id
          : null,
        type: "确认列表",
      });
    },



    getTeachers(list) {
      // console.log('lits',list);
      if (list && list.length > 0) {
        return list
          .map((item) =>
            item.teacher_hide_name_status
              ? `${item.teacher_realame}(${item.teacher_nickname})`
              : item.teacher_realame
          )
          .join(",");
      }
      return "-";
    },
    handleToDetail(id) {
      if (id.split(",").length == 1) {
        this.detailId = id;
        this.SeniorDetailDialog = true;
      } else {
        this.ClassTeacherListId = id;
        this.ClassTeacherListDialog = true;
      }
    },
    getTeacherName(row) {
      if (row.teacher_realnames) {
        let realnamesList = [];
        let nicknamesList = row.teacher_nicknames.split(",");
        row.teacher_realnames.split(",").forEach((item, index) => {
          realnamesList.push(`${item}(${nicknamesList[index]})`);
        });
        return realnamesList.join(",");
      } else {
        return "-";
      }
    },
    // 院校列表请求
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      let params = { ...this.query, student_id: this.student_id };
      if (params.types) {
        params.types = params.types.join(",");
      }
      if (params.statuses) {
        params.statuses = params.statuses.join(",");
      }
      if (params.learn_managers) {
        params.learn_managers = params.learn_managers.join(",");
      }

      // /grade/grade/list
      gradelist(params).then((response) => {
        let res = response.data;
        this.results = res.data.list || [];
        this.pageTotal = res.data.count;

        this.$nextTick(this.setRowSelection);
      });

      // let str = manageUrl();
      // this.$axios({
      //   url: `${str}/api/v1/grade/grade/list`,
      //   method: "get", //可以省略 不写默认是get
      //   params: params,
      // }).then((res) => {
      //   // console.log('res:-', res)
      //   // console.log('res:', res)
      //   // let res = response.data;
      //   this.results = res.data.list || [];
      //   this.pageTotal = res.data.count;
      //   this.$nextTick(this.setRowSelection);
      // });
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    // 设置选中行
    setRowSelection() {
      if (this.results.length) {
        this.results.forEach((row) => {
          if (this.content.find((item) => item.id === row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageIndex", 1);
      this.fetchList();
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields();
      this.fetchList();
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.fetchList();
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val;
      this.diffSelection();
    },
    handleSubmit() {},
    handleClose() {
      // 检索条件重置
      this.query = {
        keyword: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      };
      this.$emit("close");
    },
    // 选中项差异化计算
    diffSelection() {
      let diffs = [];
      // 减
      this.countSelections.forEach((item, index) => {
        if (!this.selections.find((v) => v.id === item.id)) {
          if (this.results.find((v2) => v2.id === item.id)) {
            diffs.push(item);
          }
        }
      });
      if (diffs.length) {
        this.countSelections = this.countSelections.filter(
          (item) => !diffs.find((v) => v.id === item.id)
        );
      }
      // 加
      this.selections.forEach((item) => {
        if (!this.countSelections.find((v) => v.id === item.id)) {
          this.countSelections.push(item);
        }
      });
    },
    handleToChoose(row) {
      let data = [row];
      this.$emit("submit", { data: data });
      this.handleClose();
    },
    formateType(type) {
      let types = {
        0: "一对一",
        1: "小班课",
        2: "大班课",
        3: "试听课",
      };
      return types[type];
    },
    //移除班级
    // handleTorRemove(row) {
    //   this.$confirm("确定要将该学员从班级移除吗", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(() => {
    //     console.log(row);
    //     /*    let query = {
    //          id: row.id,
    //          arr_grade: [],
    //        }
    //        editstudent(query).then((res) => {
    //          this.getList();
    //        }); */
    //   })
    // },
  },
};
</script>

<style lang="less" scoped>
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    margin-top: 10px;
  }
  .now {
    margin-left: 16px;
    color: red;
  }
}
</style>
