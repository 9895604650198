<template>
  <div class="content">
    <el-form
      class="search-wrap"
      ref="searchForm"
      :inline="true"
      :model="query"
      size="small"
      label-position="left"
    >
      <el-form-item label="订单编号">
        <el-input
          v-model.trim="query.order_no"
          placeholder="请输入订单编号"
          @input="fetchList(true)"
          clearable
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="操作人">
          <el-input v-model.trim="query.handle_user_keyword" placeholder="请输入操作人姓名/手机号/所在机构" @input="fetchList(true)"
            clearable></el-input>
        </el-form-item> -->
      <el-form-item label="类别">
        <el-select
          v-model="query.types"
          placeholder="请选择"
          @change="fetchList"
          multiple
          clearable
        >
          <el-option
            v-for="item in filters.types"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-table
      :data="result"
      :header-cell-style="{
        backgroundColor: '#F2F6FC',
        color: '#909399',
        fontSize: '14px',
      }"
      :cell-style="{ fontSize: '13px' }"
      border
    >
      <el-table-column prop="id" label="ID" align="center" width="80">
      </el-table-column>
      <el-table-column
        prop="order_no"
        label="订单编号"
        align="center"
        width="150"
      >
        <template slot-scope="scope">
          <div style="color:#409EFF;cursor: pointer;"  @click="handleToRecord(scope.row)">
            {{ scope.row.order_no }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="类别"
        align="center"
        width="120"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.type == 1">订单创建</el-tag>
          <el-tag size="small" v-if="scope.row.type == 2">订单修改</el-tag>
          <el-tag size="small" type="info" v-if="scope.row.type == 3"
            >订单取消</el-tag
          >
          <el-tag size="small" v-if="scope.row.type == 4">有人接单</el-tag>
          <el-tag size="small" type="info" v-if="scope.row.type == 5"
            >取消接单</el-tag
          >
          <el-tag size="small" type="info" v-if="scope.row.type == 6"
            >自动取消</el-tag
          >
          <el-tag type="success" size="small" v-if="scope.row.type == 7"
            >提交老师</el-tag
          >
          <el-tag type="success" size="small" v-if="scope.row.type == 8"
            >订单支付</el-tag
          >
          <el-tag type="success" size="small" v-if="scope.row.type == 9"
            >确认老师</el-tag
          >
          <el-tag type="success" size="small" v-if="scope.row.type == 10"
            >自动确认</el-tag
          >
          <el-tag type="danger" size="small" v-if="scope.row.type == 11"
            >驳回老师</el-tag
          >
          <el-tag type="success" size="small" v-if="scope.row.type == 12"
            >订单完成</el-tag
          >
          <el-tag type="warning" size="small" v-if="scope.row.type == 13"
            >延时确认</el-tag
          >
          <el-tag type="warning" size="small" v-if="scope.row.type == 14"
            >客服消息</el-tag
          >
          <el-tag type="warning" size="small" v-if="scope.row.type == 15"
            >系统提示</el-tag
          >
          <el-tag type="warning" size="small" v-if="scope.row.type == 16"
            >订单刷新</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="record"
        label="记录内容"
        align="left"
        min-width="320"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div style="white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;">{{ scope.row.record }}</div>
          <div class="img-list" v-if="scope.row.record_img_urls">
            <img
              v-for="(item, index) in scope.row.record_img_urls.split(',')"
              :key="index"
              :src="item"
              alt=""
              srcset=""
              @click="openViewer(scope.row.record_img_urls.split(','), index)"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作人"
        align="left"
        min-width="200"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span v-if="scope.row.handle_user_type == 3">系统</span>
          <span v-else-if="scope.row.handle_user_type == 1">{{
            scope.row.sender_company_user_obj
              ? `${scope.row.sender_company_user_obj.company_ref_name}/${scope.row.sender_company_user_obj.alias}/${scope.row.sender_company_user_obj.phone}`
              : ""
          }}</span>
          <span v-else-if="scope.row.handle_user_type == 2">{{
            scope.row.receiver_company_user_obj
              ? `${scope.row.receiver_company_user_obj.company_ref_name}/${scope.row.receiver_company_user_obj.alias}/${scope.row.receiver_company_user_obj.phone}`
              : ""
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" width="150">
        <template slot-scope="scope">
          {{
            scope.row.create_time
              ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
              : "无"
          }}
        </template>
      </el-table-column>
    </el-table>
    <div class="operation">
      <div></div>
      <div class="block" style="margin-bottom: 0px">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <el-image-viewer
      v-if="showViewer"
      :url-list="imgs"
      :zIndex="999999999"
      :on-close="closeViewer"
      :initial-index="initialIndex"
    />
    <OrderRecord
      :isVisible="recordDialog"
      :id="recordId"
      :handle_receiver_id="handle_receiver_id"
      @close="recordDialog = false"
    ></OrderRecord>
  </div>
</template>
            
<script>
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

import OrderRecord from "@/components/DialogComponents/ShareOrder/OrderRecord.vue";

import {} from "@/request/api";
import { crmUrl } from "@/utils/helper.js";
/* 已完成 进行中 已取消 */
export default {
  props: {
    teacherId: {
      default: "",
    },
  },
  components: {
    TableTittle,
    ElImageViewer,
    OrderRecord,
  },
  data() {
    return {
      initialIndex: 0,
      showViewer: false,
      imgs: [],
      TableName: "记录列表",
      result: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      filters: {
        types: [
          {
            label: "订单创建",
            value: 1,
          },
          {
            label: "订单修改",
            value: 2,
          },
          {
            label: "订单取消",
            value: 3,
          },
          {
            label: "有人接单",
            value: 4,
          },
          {
            label: "取消接单",
            value: 5,
          },
          {
            label: "自动取消接单",
            value: 6,
          },
          {
            label: "提交老师",
            value: 7,
          },
          {
            label: "订单支付",
            value: 8,
          },
          {
            label: "确认老师",
            value: 9,
          },
          {
            label: "自动确认老师",
            value: 10,
          },
          {
            label: "驳回老师",
            value: 11,
          },
          {
            label: "订单完成",
            value: 12,
          },
        ],
      },
      recordDialog: false,
      recordId: null,
      handle_receiver_id: null,
    };
  },
  created() {
    // this.parseQuery();
    /*    this.$store.dispatch("getStaffOptions"); */
  },
  watch: {
    teacherId: {
      handler(news) {
        console.log("news", news);
        if(news){
            this.fetchList();
        }
        
      },
      immediate:true
    },
  },
  computed: {},
  methods: {
    handleToRecord(row) {
      this.recordDialog = true;
      // this.recordId = row.help_find_order_id;
      this.recordId = row.help_find_order_id;
      this.handle_receiver_id = row.handle_user_obj
        .receiver_company_user_ids_real
        ? row.handle_user_obj.receiver_company_user_ids_real
        : null;
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url, index) {
      this.imgs = url;
      this.initialIndex = index;
      // console.log("cc", url)
      this.showViewer = true;
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.fetchList();
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      if (params.types) {
        params.types = params.types.join(",");
      }
      params.comp_receiver_commit_teacher_id = this.teacherId;
      let str = crmUrl();
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder/record/list`,
        method: "get", //可以省略 不写默认是get
        params: params,
      }).then((response) => {
      	console.log('response:', response)
        let res = response.data;
        this.result = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
      if (this.query.types && this.query.types.length) {
        this.query.types = this.query.types.split(",").map((v) => {
          return Number(v);
        });
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      if (params.types) {
        params.types = params.types.filter((v) => v).join(",");
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
  },
};
</script>
            
<style lang="less" scoped>
.img-list {
  margin-top: 12px;
  display: flex;
  align-items: center;

  img {
    width: 140px;
    height: 140px;
    margin-right: 12px;
    border-radius: 4px;
  }
}

.content {
  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }

  .el-link {
    font-size: 14px;
    margin: 0px 5px;
  }
}
</style>
            