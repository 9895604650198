<template>
  <div class="content">
    <el-dialog
      top="5vh"
      title="详情"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="501px"
    >
      <el-form
        :model="form"
        label-width="100px"
        label-position="right"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item label="机构名称" prop="cid">
          <el-select
            v-model="form.cid"
            placeholder="请选择"
            style="width: 350px"
            disabled
            filterable
          >
            <el-option
              v-for="item in $store.state.CompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="上传协议" prop="detail_file_url" ref="fileUpload">
          <el-upload
            class="upload-demo"
            action="https://manage-api.yanshizi.com/api/v1/upload/file"
            :limit="1"
            :on-success="handleSuccessFile"
            :on-exceed="handleExceed"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item> -->
        <el-form-item label="账单类型" prop="type">
          <el-select
            disabled
            v-model="form.type"
            placeholder="请选择"
            style="width: 270px"
          >
            <el-option
              v-for="item in $store.state.BillsTypeOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账单周期" prop="billTime">
          <el-date-picker
            disabled
            v-model="form.billTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结算人数" prop="service_students">
          <el-input
            :readonly="true"
            v-model.number="form.service_students"
            placeholder="请输入结算人数"
            clearable
            ><template slot="append">人</template></el-input
          >
        </el-form-item>
        <el-form-item label="服务费用" prop="service_amount">
          <el-input
            :readonly="true"
            v-model="form.service_amount"
            placeholder="请输入服务费用"
            clearable
            ><template slot="append">元</template></el-input
          >
        </el-form-item>
        <el-form-item label="结算课时" prop="service_amount">
          <el-input
            :readonly="true"
            v-model="form.service_hours"
            placeholder="请输入结算课时"
            clearable
            ><template slot="append">小时</template></el-input
          >
        </el-form-item>
        <el-form-item label="课程费用" prop="product_amount">
          <el-input
            :readonly="true"
            v-model="form.product_amount"
            placeholder="请输入课程费用"
            clearable
            ><template slot="append">元</template></el-input
          >
        </el-form-item>
        <el-form-item label="其他费用">
          <el-input
            :readonly="true"
            v-model="form.other_amount"
            placeholder="请输入结算课时"
            clearable
            ><template slot="append">元</template></el-input
          >
        </el-form-item>
        <el-form-item label="总费用" prop="total_amount">
          <el-input
            :readonly="true"
            v-model="form.total_amount"
            placeholder="请输入总费用"
            clearable
            ><template slot="append">元</template></el-input
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer btnList">
        <div>
          <el-button
            type="danger"
            v-if="form.manage_audit_status == 1"
            @click="handleCheckRefuse()"
            >驳 回</el-button
          >
        </div>
        <div>
          <el-button @click="emitClose">取 消</el-button>
          <el-button type="primary" @click="submit('formRules')"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog title="驳回" :visible.sync="dialogVisible" width="30%">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入驳回理由"
        v-model="refuse_reason"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkRefuseSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { checkRefuse } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      order_id: "",
      dialogVisible: false,
      refuse_reason: "",
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      form: this.content,
      billTime: "",
      rules: {
        cid: [{ required: true, message: "请选择机构", trigger: "blur" }],
        detail_file_url: [
          { required: true, message: "请上传账单明细", trigger: "blur" },
        ],
        service_amount: [{ required: true, message: "请输入服务费用" }],
        total_amount: [{ required: true, message: "请输入总费用" }],
        service_hours: [
          { required: true, message: "请输入结算课时" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
        type: [{ required: true, message: "请选择账单类型", trigger: "blur" }],
        billTime: [
          { required: true, message: "请选择账单周期", trigger: "blur" },
        ],
        product_amount: [{ required: true, message: "请输入课程费用" }],
        service_students: [
          { required: true, message: "请输入结算人数" },
          { type: "number", message: "结算人数必须为数字值" },
        ],
      },
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.form = val;
    },
  },
  methods: {
    handleCheckRefuse() {
      this.order_id = this.form.id;
      this.dialogVisible = true;
    },

    checkRefuseSubmit() {
      checkRefuse({
        order_id: this.order_id,
        refuse_reason: this.refuse_reason,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$emit("submitList");
          this.dialogVisible = false;
          this.$message({
            type: "success",
            message: "驳回成功!",
          });
        }
      });
    },

    emitClose() {
      this.$emit("close");
    },
    submit(rules) {
      this.dialogFormVisible = false;
      // this.$refs[rules].validate((valid) => {
      //   if (valid) {
      //     // 时间区间处理
      //     if (this.form.billTime) {
      //       this.form.start_time = this.form.billTime[0];
      //       this.form.end_time = this.form.billTime[1];
      //     } else {
      //       this.form.start_time = "";
      //       this.form.end_time = "";
      //     }
      //     this.$emit("submit", { form: this.form });
      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
    // 文件上传成功处理
    handleSuccessFile(response, file, fileList) {
      this.form.detail_file_url = fileList[0].response.data.url;
      this.$refs.fileUpload.clearValidate(); // 关闭校验
    },
    // 文件超出一个
    handleExceed(files, fileList) {
      this.$notify({
        title: "提示",
        message: "只能上传一个文件",
        type: "warning",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.btnList {
  display: flex;
  justify-content: space-between;
}
.input-with-select {
  margin-bottom: 16px;
}
.el-input {
  width: 350px;
}
</style>