<template>
    <div class="content">
        <el-dialog top="5vh" append-to-body  :title="title" :visible.sync="dialogFormVisible" @close="dialogFormVisible = false"
            :close-on-click-modal="false" width="1280px">
            <div class="header">
                <div class="title">计划组列表</div>
                <div class="dialog-footer">
                    <el-button type="primary" @click="handleCopy" size="small" :data-clipboard-text="clipboardText"
                        class="copy">邀请制定</el-button>
                </div>

            </div>
            <el-table :data="tableData" :header-cell-style="{
                textAlign: 'center',
                color: '#909399',
                fontFamily: 'PingFang SC',
                fontSize: '14px',
                fontWeight: '400',
                backgroundColor: '#F2F6FC',
            }" :cell-style="{ fontSize: '13px' }" border>
                <el-table-column fixed label="操作" width="200" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-link @click="preview(scope.row)" type="primary" :underline="false">计划预览</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="ID" width="100" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="teacher_realname" label="关联老师" width="200" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="grade_name" label="关联班级" width="300" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="statistic_count_plan_access " label="已通过" width="100" align="center"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.statistic_count_plan_access ? scope.row.statistic_count_plan_access : 0 }}
                    </template>
                </el-table-column>
                <el-table-column prop="statistic_count_plan_uncheck " label="待审核" width="100" align="center"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.statistic_count_plan_uncheck ? scope.row.statistic_count_plan_uncheck : 0 }}
                    </template>
                </el-table-column>
                <el-table-column prop="statistic_count_plan_finish " label="已完成" width="100" align="center"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.statistic_count_plan_finish ? scope.row.statistic_count_plan_finish : 0 }}
                    </template>
                </el-table-column>
                <el-table-column prop="grade_obj.learn_manager_obj.nickname" label="学管师" width="160" align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="创建时间" align="center" show-overflow-tooltip width="180">
                    <template slot-scope="scope">
                        {{ scope.row.create_time ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "" }}
                    </template>
                </el-table-column>
                <el-table-column label="更新时间" align="center" show-overflow-tooltip width="180">
                    <template slot-scope="scope">
                        {{ scope.row.update_time ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm") : "" }}
                    </template>
                </el-table-column>

                <el-table-column label="操作" width="100" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="handleAudit(scope.row)">审核</el-link>
                        <el-link @click="delProjectTeam(scope.row.id)" type="danger" :underline="false">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <PlanDetail ref="planDetail"></PlanDetail>
    </div>
</template>

<script>
import {
    coursePlanGroupList,
    delCoursePlanGroup
} from "@/request/api";
import Clipboard from 'clipboard';
import PlanDetail from "@/components/PlanComponents/PlanDetail/index";
export default {
    components: {
        PlanDetail
    },
    data() {
        return {
            clipboardText: '',
            grade_id: '',
            title: '计划组',
            dialogFormVisible: false,
            tableData: []
        }
    },
    created() {
        
    },
    methods: {
        open(id) {
            this.grade_id = id
            this.getCoursePlanGroupList()
            this.dialogFormVisible = true
            
        },
        handleCopy() {
            // console.log("lo",location)
            if (location) {
                if (location.host.includes('manage.yanshizi.com')) {
                    this.clipboardText = `https://lesson.kaoyanbox.com/#/pagesA/curriculumPlanning/index?grade_id=${this.grade_id}`
                } else {
                    this.clipboardText = `http://test-senior.kaoyanbox.net/#/pagesA/curriculumPlanning/index?grade_id=${this.grade_id}`
                }
            }
            var clipboard = new Clipboard('.copy')
            // console.log(clipboard)
            clipboard.on('success', e => {
                this.$message({
                    message: '复制成功！',
                    type: 'success'
                });
                // 释放内存  
                clipboard.destroy()
            })
            clipboard.on('error', e => {
                // 不支持复制  
                this.$message({
                    message: '该浏览器不支持自动复制',
                    type: 'error'
                });
                // 释放内存  
                clipboard.destroy()
            })
        },
        getCoursePlanGroupList(flag) {
            coursePlanGroupList({
                grade_id: this.grade_id,
                isPaging: 0,
            }).then(res => {
                if (res.data.code == 0) {
                    this.tableData = res.data.data.list
                    // this.pageTotal = res.data.data.count
                }
                // console.log(res, '计划组列表')
            })
        },
        // 预览
        preview(row) {
            this.$refs.planDetail.open({
                id: row.grade_obj.id,
                teacher_id: row.teacher_id
            })
        },

        // 审核
        handleAudit(row){
            // this.$router.push({ path: '/planReview', query: { grade_id: row.grade_obj.id,teacher_id:row.teacher_id, } })
            window.open(`/planReview?grade_id=${row.grade_obj.id}&teacher_id=${row.teacher_id}`)
        },
        // 删除计划组
        delProjectTeam(id) {
            this.$confirm('是否删除计划组?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delCoursePlanGroup({
                    id: id
                }).then(res => {
                    if (res.data.code == 0) {
                        this.getCoursePlanGroupList()
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }

                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
    }
}
</script>

<style lang="less" scoped>
.header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        font-size: 20px;
        font-weight: 600;
        color: #333;
    }
}
</style>