<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/classservice/task' }">任务管理</el-breadcrumb-item>
        <el-breadcrumb-item>提交记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column
          type="selection"
          width="50"
          fixed="left"
        ></el-table-column>
        <el-table-column
          fixed="left"
          label="ID"
          width="80"
          prop="id"
        >
        </el-table-column>
        <el-table-column
          label="提交时间"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="提交人"
          width="230"
          show-overflow-tooltip
        >
          <template
            slot-scope="scope"
            v-if="scope.row.commit_user"
          >
            {{scope.row.commit_user.realname}} / {{scope.row.commit_user.mobile}}
          </template>
        </el-table-column>
        <el-table-column
          label="任务状态"
          width="100"
        >
          <template slot-scope="scope">
            <div class="tag-wrap">
              <el-tag
                v-if="scope.row.status==0"
                type="warning"
                size="mini"
              >待审核</el-tag>
              <el-tag
                v-if="scope.row.status==1"
                size="mini"
                type="success"
              >已通过</el-tag>

              <el-badge
                is-dot
                class="item"
                v-if="scope.row.status==2"
              >
                <el-tag
                  size="mini"
                  type="danger"
                  @click="openreason(scope.row)"
                >已驳回</el-tag>
              </el-badge>
              <el-tag
                v-if="scope.row.status==3"
                size="mini"
                type="info"
              >已终止</el-tag>
            </div>

          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="记录内容"
          min-width="230"
          show-overflow-tooltip
          align="left"
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100"
          v-if="$buttonPermission('taskRoom')"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToAccept(1,scope.row.id)"
              :disabled="scope.row.status!=0"
            >通过</el-link>
            <el-link
              type="danger"
              :underline="false"
              @click="openRefuseDialog(scope.row.id)"
              :disabled="scope.row.status!=0"
            >拒绝</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button
          size="mini"
          @click="handleToCheck()"
        >批量通过</el-button>
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddTask
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="getList()"
    ></AddTask>
    <EditTask
      :isVisible="editDialog"
      :id="editId"
      @close="editDialog=false"
      @submit="getList()"
    ></EditTask>
    <RefuseDialog
      :isVisible="refuseVisible"
      @close="refuseVisible=false"
      @submit="RefuseDialogSubmit"
    ></RefuseDialog>
    <el-dialog
      top="5vh"
      title="拒绝理由"
      :visible.sync="reasonDialog"
      width="700px"
      :close-on-click-modal="false"
      @close="reasonDialog=false"
    >
      {{reason}}
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="reasonDialog=false">取 消</el-button>
        <el-button
          type="primary"
          @click="reasonDialog=false"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import AddTask from "@/components/DialogComponents/ClassService/AddTask.vue";
import EditTask from "@/components/DialogComponents/ClassService/EditTask.vue";
import RefuseDialog from "@/components/DialogComponents/RefuseDialog.vue";



import {
  taskloglist,
  commitLogcheck,
  taskEdit,
  commitLogchecklistCheck
} from "@/request/api";
export default {
  components: {
    TableTittle,
    AddTask,
    EditTask,
    RefuseDialog
  },
  data() {
    return {
      TableName: '记录列表',
      tableData: [],
      createDialog: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        task_id: this.$route.params.id
      },
      pageTotal: 0,
      chooseList: [],
      editDialog: false,
      editId: null,
      refuseVisible: false,
      create_time: "",
      reason: '',
      reasonDialog: false,
    };
  },
  created() {
    this.parseQuery();
    this.getList();
  },
  watch: {
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
        this.getList(true)
      }
    },
  },
  computed: {

  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList();
    },
    getList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);

      taskloglist(params).then((response) => {
        let res = response.data
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );

      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    formateType(val) {
      let types = {
        '1': '辅导推荐',
        '2': '资料制作',
        '3': '讲座答疑',
        '4': '课程分销',
        '5': '其他综合',
      }
      return types[val]
    },
    handleToEdit(row) {
      this.editId = row.id
      this.editDialog = true
    },
    handleToCheck() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要审核的数据')
        return
      }
      this.$confirm("确定要将这些记录通过吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map(v => { return v.id })
        commitLogchecklistCheck({
          ids,
          is_access: 1
        }).then((res) => {
          this.getList(this.query);
        });
      })
    },
    handleToAccept(is_accept, id) {
      this.$confirm("确定要通过吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        commitLogcheck({
          id: id,
          is_access: is_accept
        }).then((response) => {
          let res = response.data
          if (res.code == 0) {
            this.getList(this.query);
          } else if (res.code == 100) {
            this.$confirm(res.error, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              commitLogcheck({
                id: id,
                is_access: is_accept,
                is_force_check: 1,
              }).then((response1) => {
                let res1 = response1.data
                if (res1.code == 0) {
                  this.getList(this.query);
                }
              });
            })
          }
        });
      })
    },
    RefuseDialogSubmit({ text }) {
      commitLogcheck({
        id: this.editId,
        is_access: 0,
        refuse_reason: text
      }).then((response) => {
        let res = response.data
        if (res.code == 0) {
          this.getList(this.query);
        } else if (res.code == 100) {
          this.$confirm(res.error, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            commitLogcheck({
              id: this.editId,
              is_access: 0,
              refuse_reason: text,
              is_force_check: 1,
            }).then((response1) => {
              let res1 = response1.data
              if (res1.code == 0) {
                this.getList(this.query);
              }
            });
          })
        }
      });
    },
    openRefuseDialog(id) {
      this.refuseVisible = true
      this.editId = id
    },
    openreason(row) {
      this.reason = row.refuse_reason
      this.reasonDialog = true
    },
    handleToDetail(id) {
      this.$router.push(`/classservice/task/${id}`)
    },
    handleToChangeStatus(id) {
      this.$confirm("确定要取消任务吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        taskEdit({
          id: id,
          status: 4
        }).then((res) => {
          this.getList(this.query);
        });
      })
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  .tag-wrap {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
