<template>
  <div>
    <el-dialog
      top="5vh"
      title="老师管理"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="姓名："
          prop="realname"
        >
          <el-input
            v-model="dataForm.realname"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="性别："
          prop="gender"
        >
          <el-select
            v-model="dataForm.gender"
            placeholder="请选择性别"
            clearable
          >
            <el-option
              label="男"
              :value="1"
            ></el-option>
            <el-option
              label="女"
              :value="0"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="考研年份："
          prop="recruit_year"
        >
          <el-select
            v-model="dataForm.recruit_year"
            placeholder="请选择考研年份"
            clearable
          >
            <el-option
              label="2022年"
              :value="2022"
            ></el-option>
            <el-option
              label="2023年"
              :value="2023"
            ></el-option>
            <el-option
              label="2024年"
              :value="2024"
            ></el-option>
            <el-option
              label="2025年"
              :value="2025"
            ></el-option>
            <el-option
              label="2026年"
              :value="2026"
            ></el-option>
            <el-option
              label="2027年"
              :value="2027"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="录取信息："
          prop="recruit_college"
        >
          <el-select
            v-model="dataForm.recruit_college"
            placeholder="请选择院校"
            filterable
            clearable
            @change="collegeChange"
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="recruit_major"
        >
          <el-select
            v-model="dataForm.recruit_major"
            placeholder="请选择专业"
            clearable
          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.allnames"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            type="textarea"
            v-model="dataForm.manage_remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  majorlist,
  teacherOne,
  teacherEdit
} from "@/request/api";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
        recruit_college: [{ required: true, message: "请选择录取院校", trigger: "blur" }],
        recruit_major: [{ required: true, message: "请选择录取专业", trigger: "blur" }],
      },
      majorOptions: [],// 专业数组
    };
  },
  mounted() {
    this.$store.dispatch('getAllColleges')
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      teacherOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data
        if (this.dataForm.recruit_college_name && this.dataForm.recruit_college_code) {
          this.$set(this.dataForm, 'recruit_college', {
            label: this.dataForm.recruit_college_name,
            value: this.dataForm.recruit_college_code,
          })
          this.getMajor(this.dataForm.recruit_college_code)
        }
        if (this.dataForm.recruit_major_name && this.dataForm.recruit_major_code) {
          this.$set(this.dataForm, 'recruit_major', {
            label: this.dataForm.recruit_major_name,
            value: this.dataForm.recruit_major_code,
          })
        }
      });
    },
    emitClose() {
      this.$emit("close");
    },
    collegeChange(e) {
      this.dataForm.recruit_major = {}
      this.majorOptions = []
      this.academyOptions = []
      if (e) {
        this.getMajor(e.value);
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map(v => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`
          }
        })
      });
    },

    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            cid: localStorage.getItem("cid"),
            recruit_college_code: this.dataForm.recruit_college ? this.dataForm.recruit_college.value : "",
            recruit_college_name: this.dataForm.recruit_college ? this.dataForm.recruit_college.label : "",
            recruit_major_code: this.dataForm.recruit_major ? this.dataForm.recruit_major.value : "",
            recruit_major_name: this.dataForm.recruit_major ? this.dataForm.recruit_major.label : "",
            realname: this.dataForm.realname,
            gender: this.dataForm.gender,
            recruit_year: this.dataForm.recruit_year,
            manage_remark: this.dataForm.manage_remark,
          }
          teacherEdit(query).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('修改成功')
              this.emitClose()
              this.$emit('submit')
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>