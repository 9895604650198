var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"top":"5vh","title":"自动分单","visible":_vm.dialogFormVisible,"close-on-click-modal":false,"width":"600px"},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.handleToClose}},[_c('el-form',{ref:"formRules",attrs:{"model":_vm.dataForm,"label-position":"right","rules":_vm.rules,"size":"small","label-width":"130px"}},[_c('el-form-item',{attrs:{"label":"开关：","prop":"status"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.dataForm.status),callback:function ($$v) {_vm.$set(_vm.dataForm, "status", $$v)},expression:"dataForm.status"}})],1),_c('el-form-item',{attrs:{"label":"相同优先：","prop":"AllocationSameOrderFirstStatus"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.dataForm.AllocationSameOrderFirstStatus),callback:function ($$v) {_vm.$set(_vm.dataForm, "AllocationSameOrderFirstStatus", $$v)},expression:"dataForm.AllocationSameOrderFirstStatus"}})],1),_c('el-form-item',{attrs:{"label":"分单类型："}},[_c('el-select',{attrs:{"placeholder":"请选择","clearable":""},model:{value:(_vm.dataForm.order_type),callback:function ($$v) {_vm.$set(_vm.dataForm, "order_type", $$v)},expression:"dataForm.order_type"}},[_c('el-option',{attrs:{"label":"综合分单","value":1}}),_c('el-option',{attrs:{"label":"指定分单","value":2}})],1)],1),(_vm.dataForm.order_type==1)?[_c('el-form-item',{attrs:{"label":"匹配师：","prop":"ids"}},[_c('el-select',{staticStyle:{"width":"360px"},attrs:{"placeholder":"请选择","clearable":"","multiple":""},model:{value:(_vm.dataForm.ids),callback:function ($$v) {_vm.$set(_vm.dataForm, "ids", $$v)},expression:"dataForm.ids"}},_vm._l((_vm.$store.state.staffOptions.filter(v=>{
              return v.depts.findIndex(t=>{
                return t.id 
              })!=-1
            })),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nickname,"value":item.id}})}),1)],1)]:_vm._e(),(_vm.dataForm.order_type==2)?[_c('el-form-item',{attrs:{"label":"外包订单匹配师：","prop":"outsourse_ids"}},[_c('el-select',{staticStyle:{"width":"360px"},attrs:{"placeholder":"请选择","clearable":"","multiple":""},model:{value:(_vm.dataForm.outsourse_ids),callback:function ($$v) {_vm.$set(_vm.dataForm, "outsourse_ids", $$v)},expression:"dataForm.outsourse_ids"}},_vm._l((_vm.$store.state.staffOptions.filter(v=>{
              return v.depts.findIndex(t=>{
                return t.id && v.status == 1
              })!=-1
            })),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nickname,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"代找订单匹配师：","prop":"findreplace_ids"}},[_c('el-select',{staticStyle:{"width":"360px"},attrs:{"placeholder":"请选择","clearable":"","multiple":""},model:{value:(_vm.dataForm.findreplace_ids),callback:function ($$v) {_vm.$set(_vm.dataForm, "findreplace_ids", $$v)},expression:"dataForm.findreplace_ids"}},_vm._l((_vm.$store.state.staffOptions.filter(v=>{
              return v.depts.findIndex(t=>{
                return t.id && v.status == 1
              })!=-1
            })),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nickname,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"代签订单匹配师：","prop":"signreplace_ids"}},[_c('el-select',{staticStyle:{"width":"360px"},attrs:{"placeholder":"请选择","clearable":"","multiple":""},model:{value:(_vm.dataForm.signreplace_ids),callback:function ($$v) {_vm.$set(_vm.dataForm, "signreplace_ids", $$v)},expression:"dataForm.signreplace_ids"}},_vm._l((_vm.$store.state.staffOptions.filter(v=>{
              return v.depts.findIndex(t=>{
                return t.id && v.status == 1
              })!=-1
            })),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nickname,"value":item.id}})}),1)],1)]:_vm._e()],2),_c('div',{staticClass:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.handleToClose()}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit('formRules')}}},[_vm._v("确定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }