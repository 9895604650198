<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item>计划管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button type="primary"
                   size="small"
                   @click="handleAdd">新建</el-button>
      </div>
    </div>

    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="queryForm"
               size="small"
               label-position="left">
        <el-form-item label="关联班级">
          <el-select :loading="selectLoading"
                     v-model="queryForm.grade_id"
                     filterable
                     placeholder="请选择"
                     @change="getCoursePlanGroupList(true)"
                     clearable>
            <el-option v-for="(item, index) in grades"
                       :key="index"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="提交老师">
          <el-input v-model.trim="queryForm.teacher_keyword"
                    placeholder="请输入老师关键字"
                    @input="getCoursePlanGroupList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="学管师">
          <el-select v-model="queryForm.learn_manager_id"
                     placeholder="请选择"
                     @change="getCoursePlanGroupList(true)"
                     clearable>
            <el-option v-for="item in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 4 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="是否存在待审核计划">
          <el-select v-model="queryForm.is_exist_uncheck_plan"   placeholder="请选择" @change="getCoursePlanGroupList()" clearable>
            <el-option v-for="(item, index) in auditTypeList" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form size="small"
                 :inline="true">
          <el-form-item label-width="32px">
            <el-radio-group v-model="queryForm.is_exist_uncheck_plan"
                            @change="getCoursePlanGroupList(true)">
              <el-radio-button label="">全部</el-radio-button>
              <el-badge :value="auditNum"
                        class="item"
                        :hidden="auditNum == 0">
                <el-radio-button :label="1">待审核</el-radio-button>
              </el-badge>

              <el-radio-button :label="0">已审核</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column fixed
                         label="操作"
                         width="200"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- <el-link @click="handleCopy(scope.row)" type="primary" :data-clipboard-text="clipboardText" class="copy" :underline="false">邀请制定</el-link> -->
            <el-link @click="preview(scope.row)"
                     type="primary"
                     :underline="false">计划预览</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="ID"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="teacher_realname"
                         label="关联老师"
                         width="200"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="grade_name"
                         label="关联班级"
                         width="300"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="statistic_count_plan_access "
                         label="已通过"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.statistic_count_plan_access
                ? scope.row.statistic_count_plan_access
                : 0
            }}
          </template>
        </el-table-column>
        <el-table-column prop="statistic_count_plan_uncheck "
                         label="待审核"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.statistic_count_plan_uncheck
                ? scope.row.statistic_count_plan_uncheck
                : 0
            }}
          </template>
        </el-table-column>
        <el-table-column prop="statistic_count_plan_finish "
                         label="已完成"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.statistic_count_plan_finish
                ? scope.row.statistic_count_plan_finish
                : 0
            }}
          </template>
        </el-table-column>
        <el-table-column prop="grade_obj.learn_manager_obj.nickname"
                         label="学管师"
                         width="160"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="创建时间"
                         align="center"
                         show-overflow-tooltip
                         width="180">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         align="center"
                         show-overflow-tooltip
                         width="180">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         width="100"
                         align="center"
                         fixed="right">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleAudit(scope.row)">审核</el-link>
            <el-link @click="delProjectTeam(scope.row.id)"
                     type="danger"
                     :underline="false">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(queryForm.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(queryForm.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog top="5vh"
               title="新建班级计划"
               :visible.sync="dialogFormVisible"
               @close="emitClose"
               @open="open"
               :close-on-click-modal="false"
               width="620px">
      <el-form :model="dataForm"
               label-position="right"
               ref="dataForm"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="所在班级："
                      prop="grade_id">
          <el-select v-model="dataForm.grade_id"
                     placeholder="请选择"
                     @change="gradeChange"
                     @focus="gradeList"
                     filterable
                     reserve-keyword
                     remote
                     :remote-method="remoteMethod"
                     style="width: 260px"
                     clearable>
            <el-option v-for="(item, index) in options"
                       :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="授课老师："
                      prop="teacher_id">
          <div class="user-wrap">
            <div class="top">
              <el-link type="primary"
                       :underline="false"
                       :disabled="!dataForm.grade_id"
                       @click="chooseDialog = true">选择老师</el-link>
            </div>
            <div class="bottom">
              <el-tag :disable-transitions="false"
                      v-if="teacherChoose">
                {{ teacherChoose.realname }}
                {{
                  teacherChoose.nickname
                    ? "(" + teacherChoose.nickname + ")"
                    : ""
                }}
                {{
                  teacherChoose.teacher_average_lesson_hour_amount || 0
                }}元/小时
              </el-tag>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose">取消</el-button>
        <el-button type="primary"
                   @click="submit('dataForm')">确定</el-button>
      </div>
    </el-dialog>

    <MatchingTeacher :isVisible="chooseDialog"
                     @close="chooseDialog = false"
                     @submit="chooseTeacher"
                     :grade_id="dataForm.grade_id" />

    <PlanDetail ref="planDetail"></PlanDetail>
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import MatchingTeacher from '@/components/DialogComponents/ClassService/MatchingTeacher.vue'
import PlanDetail from '@/components/PlanComponents/PlanDetail/index'
// import Clipboard from 'clipboard';
import {
  gradelist,
  coursePlanGroupList,
  coursePlanGroup,
  delCoursePlanGroup,
} from '@/request/api'
export default {
  components: {
    TableTittle,
    MatchingTeacher,
    PlanDetail,
  },
  data() {
    return {
      options: [],
      loading: false,
      classIndex: 1,
      classTotal: 0,
      selectLoading: false,
      auditTypeList: [
        {
          label: '是',
          value: 1,
        },
        {
          label: '否',
          value: 0,
        },
      ],
      clipboardText: '',
      TableName: '计划列表',
      tableData: [],
      auditNum: 0,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        is_exist_uncheck_plan: '',
        grade_id: '',
      },
      pageTotal: 0,
      dataForm: {
        grade_id: null,
      },
      grades: [], //班级
      dialogFormVisible: false,
      teacherChoose: null,
      chooseDialog: false,
      rules: {
        grade_id: [{ required: true, message: '请选择班级', trigger: 'blur' }],
        teacher_id: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.teacherChoose) {
                callback(new Error('请选择老师'))
              } else {
                callback()
              }
            },
          },
        ],
      },
    }
  },
  created() {
    if (this.$route.query.grade_id) {
      this.$set(this.queryForm, 'grade_id', Number(this.$route.query.grade_id))
      this.$set(this.queryForm, 'is_exist_uncheck_plan', '')
    }
    this.$store.dispatch('getStaffOptions')

    this.getCoursePlanGroupList()
    this.getCoursePlanGroupNum()
  },
  methods: {
    remoteMethod(query) {
      console.log('query', this.query)
      if (query !== null) {
        gradelist({ isPaging: 0, name_keyword: query }).then((response) => {
          let res = response.data
          this.options = res.data.list
        })
      } else {
        this.options = []
      }
    },
    emitClose() {
      this.dialogFormVisible = false
      this.grades = []
      this.classIndex = 1
    },
    // loadmore() {
    //   console.log('滚动到底部')
    //   if (this.grades.length < this.classTotal) {
    //     this.classIndex++
    //     this.gradeList()
    //   } else {
    //     console.log('取消')
    //   }
    // },
    // 预览
    preview(row) {
      this.$refs.planDetail.open({
        id: row.grade_obj.id,
        teacher_id: row.teacher_id,
      })
    },

    handleAudit(row) {
      window.open(
        `/planReview?grade_id=${row.grade_obj.id}&teacher_id=${row.teacher_id}`
      )
      // this.$router.push({ path: '/planReview', query: { grade_id: row.grade_obj.id,teacher_id:row.teacher_id, } })
    },
    // 选择班级
    gradeChange(e) {
      this.teacherChoose = null
    },
    gradeList() {
      this.options = []
      gradelist({ pageIndex: 1, pageSize: 100, isPaging: 1 }).then(
        (response) => {
          let res = response.data
          this.options = res.data.list
          // this.grades = this.grades.concat(res.data.list)
          // this.classTotal = res.data.count
          // console.log('this.grades', this.grades)
          // this.selectLoading = false
        }
      )
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.getCoursePlanGroupList()
    },
    handleCurrentChange(val) {
      this.queryForm.pageIndex = val
      this.getCoursePlanGroupList()
    },
    // 获取计划组列表
    getCoursePlanGroupList(flag) {
      if (flag) {
        this.queryForm.pageIndex = 1
      }
      coursePlanGroupList(this.queryForm).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.list
          this.pageTotal = res.data.data.count
          if (this.queryForm.is_exist_uncheck_plan == 1) {
            this.auditNum = this.pageTotal
          }
        }
        // console.log(res, '计划组列表')
      })
    },

    getCoursePlanGroupNum() {
      coursePlanGroupList({
        ...this.queryForm,
        is_exist_uncheck_plan: 1,
      }).then((res) => {
        if (res.data.code == 0) {
          this.auditNum = res.data.data.count
        }
        // console.log(res, '计划组列表')
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.teacherChoose = null
        this.$refs.dataForm.resetFields()
        this.$refs.dataForm.clearValidate()
      })
    },
    // 删除计划组
    delProjectTeam(id) {
      this.$confirm('是否删除计划组?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delCoursePlanGroup({
            id: id,
          }).then((res) => {
            if (res.data.code == 0) {
              this.getCoursePlanGroupList()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          coursePlanGroup({
            grade_id: this.dataForm.grade_id,
            teacher_id: this.teacherChoose.id,
          }).then((res) => {
            // console.log("创建计划组", res,)
            if (res.data.code == 0) {
              this.$message({
                message: '创建成功!',
                type: 'success',
              })
              this.getCoursePlanGroupList()
              this.dialogFormVisible = false
            }
          })
        }
      })
    },
    chooseTeacher({ data }) {
      this.teacherChoose = data
      // console.log("Data",data)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-badge__content.is-fixed {
  z-index: 99 !important;
}
</style>