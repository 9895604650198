<template>
  <el-dialog
    title="优惠券推广"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="404px"
    @close="emitClose"
  >
    <div class="bcg-container" ref="postHtml">
      <div class="main">
        <div class="top-div" ref="imageWrapper">
          <span id="span1">
            {{ content.name }}
          </span>

          <span id="span2">
            {{ content.coupon_type == 1 ? "发单优惠券" : "接单补贴券" }}
            <!-- 无使用门槛&nbsp;&nbsp;{{
              content.coupon_type == 1 ? "发单即立减" : "接单即补贴"
            }} -->
          </span>

          <span id="span3">
            <div class="high">
              <div>最</div>
              <div>高</div>
            </div>
            {{
              content.collect_type == 1
                ? (
                    content.collect_fix_amount *
                    content.collect_fix_count *
                    content.single_user_collect_count
                  ).toFixed(1)
                : content.collect_random_high_amount
            }}
            <div class="money">元</div>
          </span>
          <!-- <span id="span4">
            扫一扫&nbsp;&nbsp;领<span
              >最高{{
                content.collect_type == 1
                  ? (
                      content.collect_fix_amount *
                      content.collect_fix_count *
                      content.single_user_collect_count
                    ).toFixed(2)
                  : content.collect_random_high_amount
              }}元</span
            >{{ content.coupon_type == 1 ? "发单优惠券" : "接单补贴券" }}
          </span> -->
        </div>

        <div class="bottom-div">
          <img :src="QRCodeURL" alt="" style="width:110px;height:110px">
          <!-- <div class="qrcode-img" id="qrcode" ref="qrcode"></div> -->
        </div>
      </div>
    </div>
    <div class="down-div">
      <!-- draw -->
      <el-link type="primary" :underline="false" @click="createPoster"
        >下载图片</el-link
      >
      <el-link type="primary" :underline="false" @click="downloadQrcode"
        >下载二维码</el-link
      >
    </div>
    <div style="margin-top: 15px">
      <el-input
        placeholder="请输入内容"
        v-model="web_url"
        class="input-with-select"
        readonly
      >
        <el-button slot="append" type="primary" @click="copyUrl"
          >复制链接</el-button
        >
      </el-input>
    </div>

    <div class="content_container">
      <vue-canvas-poster
        :painting="painting"
        @success="success"
        @fail="fail"
      ></vue-canvas-poster>
    </div>
    <!-- <img src="" alt=""> -->
  </el-dialog>
</template>

<script>
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import { crmUrl } from "@/utils/helper.js";

export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      QRCodeURL:'',
      dialogFormVisible: false,
      web_url: "",
      qrcode_url: null,
      imgUrl: "", //下载图片
      w: 320,
      h: 185,
      info: {},
      posterImg: "", //生成的海报
      painting: {},
      admin_id: "",
    };
  },
  mounted() {},
  activated() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.$nextTick(() => {
        let url = "";
        if (location) {
          if (location.host.includes("manage.yanshizi.com")) {
            url = `https://share.yanshizi.com/#/pages/redPacket/index?share_coupon_id=${this.content.id}`;
          } else {
            url = `https://test01.kaoyanbox.net/#/pages/redPacket/index?share_coupon_id=${this.content.id}`;
            //  url = `http://localhost:8080/#/pages/redPacket/index?share_coupon_id=${this.content.id}`;
          }
        }
        this.web_url = url;
        this.qrcode();
        // this.drawPic(url);
      });
    },
  },
  methods: {
    emitClose() {
      document.querySelector("#qrcode").innerHTML = "";
      this.$emit("close");
    },
    qrcode() {
      let str = crmUrl();
      this.$axios({
        url: `${str}/api/v1/wechat/getQRCodeShareMini`,
        method: "post", //可以省略 不写默认是get
        data: {
          qr_path:`pages/redPacket/index?share_coupon_id=${this.content.id}`
        },
        headers: {
          // 设置请求头
          Authorization: localStorage.getItem("admin_token"),
        },
      }).then((res) => {
        this.QRCodeURL = res.data.data
      	// console.log('res:', res)
        
      });

      // let qrcode = new QRCode("qrcode", {
      //   width: 110, // 二维码宽度，单位像素
      //   height: 110, // 二维码高度，单位像素
      //   text: url,
      // });
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.downImage();
      //   });
      // });
    },
    downImage() {
      // const logo= document.createElement("img");
      let logo = new Image();
      logo.setAttribute("crossOrigin", "Anonymous");
      logo.src = require("/src/assets/images/qrcode-logo.png");
      logo.onload = () => {
        let qrImg = this.$refs.qrcode.querySelector("img");
        const canvas = this.$refs.qrcode.querySelector("canvas");
        let cxt = canvas.getContext("2d");
        const width = 110 / 2 - 30 / 2;
        const height = 110 / 2 - 30 / 2;
        cxt.drawImage(logo, width, height, 30, 30);
        qrImg.src = canvas.toDataURL();
      };
    },
    downloadQrcode() {
      let img = document.querySelectorAll("img")[11].currentSrc;
      // console.log("img",document.querySelectorAll('img'))
      var a = document.createElement("a");

      a.download = "qrcode";
      // 设置图片地址
      a.href = img;
      a.click();
    },
    copyUrl() {
      const input = document.createElement("textarea");
      input.setAttribute("id", "__mouse__position_input");
      input.value = this.web_url;
      this.$copyText(input.value).then((e) => {
        this.$notify.success({
          title: "提示",
          message: "复制成功",
          type: "success",
        });
      });
    },
    draw() {
      var a = document.createElement("a");
      a.download = "poster";
      // 设置图片地址
      a.href = this.posterImg;
      a.click();
    },
    success(src) {
      // console.log("src",src)
      this.posterImg = src;
    },
    fail(err) {
      alert(err);
    },
    querySearch(queryString, cb) {
      var restaurants = this.$store.state.adminList.map((v) => {
        return {
          value: v.nickname,
          id: v.id,
          code: v.invitecode,
        };
      });
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    createPoster() {
      const name = `计划预览`;
      const posterHtml = this.$refs.postHtml;

      // let w = posterHtml.offsetWidth;
      // let h = posterHtml.offsetHeight;
      let w = 364;
      let h = 513;
      // console.log("h", h);
      // 生成海报
      // console.log("生成海报", posterHtml, name);
      const domObj = posterHtml;
      // console.log(domObj);
      html2canvas(domObj, {
        allowTaint: true,
        useCORS: true,
        width: w,
        height: h,
        // scrollY: -18,
        // scrollX: -0,
        onclone(doc) {},
      }).then((canvas) => {
        // document.body.appendChild(canvas)
        // console.log("结果", domObj, canvas);
        //  canvas.toBlob((blob) => {
        // 将blob对象放入剪切板item中

        // const data = [new ClipboardItem({ [blob.type]: blob })];
        // 写入剪切板,成功会执行resolve,失败则走reject
          // navigator.clipboard.write(data).then(() => {
          //   this.$message({
          //     message: '已保存到粘贴板',
          //     type: 'success',
          //     duration: 2000,
          //   });
          // }, () => {
          //   this.$message({
          //     message: '保存截图失败',
          //     type: 'warning',
          //     duration: 2000,
          //   });
          // });
        // }, 'image/png');
        this.downloadQRImg(canvas, name);
      });
    },
    /** 根据URL下载图片 */
    downloadQRImg(canvas, name) {
      /** 新Image对象，可以理解为DOM */
      let img = new Image();
      /** 解决跨域 Canvas 污染问题 */
      img.setAttribute("crossOrigin", "anonymous");
      // img.onload = function() {
      /** canvas.toDataURL 返回的是一串Base64编码的URL,指定格式 PNG */
      let imgUrl = canvas.toDataURL("img/png");
      // console.log(imgUrl);
      img.src = imgUrl;
      /** 生成一个a元素,并创建一个单击事件 */
      let a = document.createElement("a");
      a.download = name || "photo"; // 设置图片名称
      a.href = imgUrl; // 将生成的URL设置为a.href属性
      a.setAttribute("id", "myLink");
      document.body.appendChild(a);
      // console.log("链接", a);
      this.exportCodeConfirm();
      // }
    },
    /** 导出/下载 二维码 */
    exportCodeConfirm() {
      setTimeout(() => {
        let event = new MouseEvent("click");
        /** 触发a的单击事件 */
        document.getElementById("myLink").dispatchEvent(event);
      }, 0);
    },
  },
};
</script>

<style lang="less" scoped>
div,
span {
  box-sizing: border-box;
}
.bcg-container {
  width: 364px;
  height: 513px;
  background-image: url("https://upload.kaoyanbox.net/3007ae62cb4a1df10bd4b386f0f917ca.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 67px;
}
// .main {
//   margin: 0 auto;
//   width: 344px;
//   height: 394px;
//   background-image: url("https://upload.kaoyanbox.net/a4c6e1eec83508fea7a0a50267c6c767.png");
//   background-image: url("https://upload.kaoyanbox.net/13874425ee525b54c910d204bef4a16b.png");
//   background-size: 100% 100%;
//   background-repeat: no-repeat;
//   padding-top: 32px;
// }
.top-div {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    display: block;
    font-family: PingFang SC;
    color: #ffffff;
    margin-bottom: 8px;
    &:nth-child(1) {
      font-size: 40px;
      font-family: AlimamaShuHeiTi, AlimamaShuHeiTi;
      font-weight: bold;
      color: #ffffff;
      line-height: 48px;
      text-shadow: 0px 2px 4px #1c6fe9;
    }
    &:nth-child(2) {
      width: 190px;
      height: 32px;
      line-height: 32px;
      background: #ffffff;
      border-radius: 16px;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #1c6fe9;
      text-align: center;
    }
    &:nth-child(3) {
      display: flex;
      font-size: 54px;
      font-family: Helvetica, Helvetica;
      font-weight: bold;
      color: #e82631;
      line-height: 57px;
      margin-top: 62px;
      .high {
        width: 20px;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #e82631;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 15px;
        & > div {
          line-height: 20px;
        }
        margin-right: 4px;
      }
      .money {
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #e82631;
        line-height: 69px;
        margin-left: 2px;
      }
    }
    &:nth-child(4) {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
      margin-top: 5px;
      & > span {
        display: inline;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        line-height: 20px;
        margin: 0 2px;
        color: #ffd364;
      }
    }
  }
}
.bottom-div {
  left: 50%;
  bottom: 198px;
  transform: translate(-50%, 0);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    display: block;
    margin-bottom: 27px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #8d8d8d;
    opacity: 1;
  }
  .qrcode-img {
    width: 110px;
    height: 110px;
    // margin-bottom: 12px;
  }
}
.down-div {
  margin-top: 16px;
  .el-link {
    font-size: 12px;
    margin: 0px 8px;
  }
}
</style>