<template>
    <el-dialog top="5vh" title="班级详情" :visible.sync="dialogFormVisible" @close="dialogFormVisible = false"
        :close-on-click-modal="false" width="70%"  >
        <el-table :data="tableData" :header-cell-style="{
            color: '#909399',
            fontFamily: 'PingFang SC',
            fontSize: '14px',
            fontWeight: '400',
            backgroundColor: '#F2F6FC',
        }" :cell-style="{ fontSize: '13px' }" @selection-change="handleSelectionChange" border>
            <el-table-column type="selection" width="50" align="center"> </el-table-column>

            <el-table-column fixed="left" label="学习记录" align="center" width="120">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false" @click="handleToRecord(scope.row)">课时</el-link>
                    <el-link type="primary" :underline="false" @click="handleToPlanCalendar(scope.row)">计划</el-link>
                </template>
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80" align="center" show-overflow-tooltip>

            </el-table-column>

            <el-table-column prop="name" label="班级名称" width="260" align="center" show-overflow-tooltip fixed="left">
                <template slot-scope="scope">
                    <div class="left">
                        {{ scope.row.name }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="班级类型" align="center" width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ formateType(scope.row.type) }}
                </template>
            </el-table-column>
            <el-table-column label="考研年份" width="80" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.apply_year ? scope.row.apply_year + '年' : '暂无' }}
                </template>
            </el-table-column>
            <el-table-column label="班级状态" width="80" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.grade_sync_status == 1" size="mini" type="warning">待匹配</el-tag>
                    <el-tag v-if="scope.row.grade_sync_status == 2" size="mini">匹配中</el-tag>
                    <el-tag v-if="scope.row.grade_sync_status == 3" size="mini" type="warning">待确认</el-tag>
                    <el-tag v-if="scope.row.grade_sync_status == 4" size="mini" type="warning">待上课</el-tag>
                    <el-tag v-if="scope.row.grade_sync_status == 5" size="mini">上课中</el-tag>
                    <el-tag v-if="scope.row.grade_sync_status == 6" size="mini" type="danger">已过半</el-tag>
                    <el-tag v-if="scope.row.grade_sync_status == 7" size="mini" type="info">已终止</el-tag>
                    <el-tag v-if="scope.row.grade_sync_status == 8" size="mini" type="danger">已结束</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="学员列表" width="120" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false" @click="ChooseStudent(scope.row)">{{
                        formateMember(scope.row.student_realnames) }}</el-link>
                </template>
            </el-table-column>
            <el-table-column label="授课老师" width="250" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div class="badge" v-if="scope.row.allocation_order_obj">
                        <div v-if="scope.row.allocation_order_obj.status == 4">
                            <el-link type="primary" :underline="false" @click="ChooseTeacher(scope.row)">{{
                                formateMember(scope.row.teacher_realnames) }}</el-link>
                        </div>
                        <div v-else>
                            <el-badge :value="scope.row.allocation_order_teacher_unconfirm_count" class="item"
                                :hidden="!scope.row.allocation_order_teacher_unconfirm_count">
                                <el-link type="primary" :underline="false"
                                    @click="openConfirmList(scope.row)">确认列表</el-link>
                            </el-badge>
                            <el-badge :value="scope.row.record_msg_unreaded_count" class="item"
                                :hidden="!scope.row.record_msg_unreaded_count">
                                <el-link type="warning" :underline="false"
                                    @click="handleToOrderRecord(scope.row)">沟通记录</el-link>
                            </el-badge>
                        </div>
                    </div>
                    <div class="badge" v-else>
                        <el-link type="success" :underline="false" @click="ChooseTeacher(scope.row)">老师列表</el-link>
                        <el-link type="primary" :underline="false" @click="ChooseTeacher(scope.row)"
                            v-if="scope.row.teacher_realnames">{{ formateMember(scope.row.teacher_realnames) }}</el-link>
                        <el-link type="warning" :underline="false" @click="handleToMatching(scope.row)"
                            v-else>提交匹配</el-link>
                    </div>
                </template>

            </el-table-column>

            <el-table-column width="120" show-overflow-tooltip align="center">
                <template slot="header">
                    <el-popover ref="popover">
                        <span class="el-dropdown-link" slot="reference">
                            计划时长<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <div class="popover-item" @click="handleCommand(1)">进度</div>
                        <div class="popover-item" @click="handleCommand(2)">小时</div>
                    </el-popover>
                </template>
                <template slot-scope="scope">
                    <div v-if="unitConversion == 1">
                        <el-progress :text-inside="true" :stroke-width="20"
                            :percentage="formateProgress(scope.row)"></el-progress>
                    </div>
                    <div v-if="unitConversion == 2">
                        {{ scope.row.grade_plan_data && scope.row.grade_plan_data.plan_exist_lesson_hour ?
                            scope.row.grade_plan_data.plan_exist_lesson_hour : 0 }}小时
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="机构名称" width="120" show-overflow-tooltip prop="student_real_company_names"
                align="center">
            </el-table-column>
            <el-table-column width="120" show-overflow-tooltip align="center">
                <template slot="header">
                    <el-popover ref="popover">
                        <span class="el-dropdown-link" slot="reference">
                            课程时长<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <div class="popover-item" @click="handleCommandOther(1)">进度</div>
                        <div class="popover-item" @click="handleCommandOther(2)">小时</div>
                    </el-popover>
                </template>
                <template slot-scope="scope">
                    <div v-if="unitConversion2 == 1">
                        <el-progress :text-inside="true" :stroke-width="20"
                            :percentage="scope.row.course_progress ? parseFloat((Number(scope.row.course_progress) * 100).toFixed(2)) : 0"></el-progress>
                    </div>
                    <div v-if="unitConversion2 == 2">
                        {{ scope.row.sum_grade_lesson_hour ? scope.row.sum_grade_lesson_hour : 0 }}小时
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="匹配周期" width="120" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.grade_plan_data.match_period_day }}天
                </template>
            </el-table-column>
            <el-table-column label="报考信息" width="650" show-overflow-tooltip align="left">
                <template slot-scope="scope">
                    {{ scope.row.apply_college_name }} | ({{ scope.row.apply_major_code }}){{ scope.row.apply_major_name }}
                    |
                    {{ scope.row.apply_academy_name }}
                    |
                    <span v-for="(item, index) in scope.row.apply_subjects || []" :key="index">
                        ({{ item.subject_code }}){{ item.subject_name }}
                    </span>
                    <span v-if="scope.row.apply_direction_code && scope.row.apply_direction_name">
                        | ({{ scope.row.apply_direction_code }}){{ scope.row.apply_direction_name }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="备注" width="200" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.remark ? scope.row.remark : '无' }}
                </template>
            </el-table-column>

            <el-table-column label="学管师" align="center" width="180">
                <template slot-scope="scope">
                    {{ scope.row.learn_manager ? scope.row.learn_manager.nickname : "" }}
                </template>
            </el-table-column>
            <el-table-column label="创建日期" align="center" width="150">
                <template slot-scope="scope">
                    {{ scope.row.create_time ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "" }}
                </template>
            </el-table-column>
            <el-table-column label="修改日期" align="center" width="150">
                <template slot-scope="scope">
                    {{ scope.row.update_time ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm") : "" }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" align="center" width="150">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false" @click="handleToEdit(scope.row)"
                        v-if="scope.row.status != 2">编辑</el-link>
                    <el-link type="success" :underline="false" @click="changeStatus(scope.row)" v-else>恢复</el-link>
                    <el-link type="primary" :underline="false" @click="handleToGradeRecord(scope.row)">记录</el-link>
                </template>
            </el-table-column>
        </el-table>
        <div class="operation">
            <div>
                <el-button size="mini" type="primary" @click="chooseAllEdit(1, '您确定要归档吗')"
                    v-show="query.archive == 0 && query.archive != ''">归档</el-button>
                <el-button size="mini" type="primary" @click="chooseAllEdit(0, '您确定要取消归档吗')"
                    v-show="query.archive == 1">取消归档</el-button>
                <el-button size="mini" type="danger" @click="chooseAllEditStatus()">终止</el-button>
                <el-button size="mini" type="danger" @click="handleToDeleteAll()">删除</el-button>
            </div>

            <div class="block" style="margin-bottom:0px;">
                <el-pagination background layout="total, prev, pager, next, sizes, jumper"
                    :current-page="Number(query.pageIndex)" :page-sizes="[10, 20, 50, 100, 500]"
                    :page-size="Number(query.pageSize)" :total="Number(pageTotal)" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <AddClass :isVisible="createDialog" @close="createDialog = false" @submit="fetchList(true)" :number="classNumber" />
        <EditClass :id="editId" :isVisible="editDialog" @close="editDialog = false" @submit="fetchList(true)" />
        <GradeStatusChange :id="editId" :isVisible="changeDialog" @close="changeDialog = false" @submit="fetchList(true)" />
        <ChooseStudent :id="ChooseStudentId" :isVisible="chooseDialog" @close="chooseDialog = false"
            @submit="fetchList(true)" />
        <ClassStudentList :id="ClassStudentListId" :isVisible="ClassStudentListDialog"
            @close="ClassStudentListDialog = false" @submit="fetchList()" />
        <ClassTeacherList :id="ClassTeacherListId" :isVisible="ClassTeacherListDialog"
            @close="ClassTeacherListDialog = false" @submit="fetchList()" :order_id="confirmOrder_id" />
        <Matching :id="matchId" :isVisible="matchDialog" @close="matchDialog = false" @submit="fetchList()" />
        <ConfirmList :ids="confirmIds" :isVisible="confirmDialog" :grade_id="grade_id" :order_teachers="order_teachers"
            :order_id="confirmOrder_id" @close="confirmDialog = false" @submit="fetchList()" />
        <Record :teacher_allocation_order_id="orderId" :isVisible="recordDialog" @close="recordDialog = false"
            @submit="fetchList" :grade_id="recordGradeId" :type="'grade_read'"></Record>


        <CreatePlanDialog ref="createPlanDialog"></CreatePlanDialog>
    </el-dialog>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddClass from "@/components/DialogComponents/ClassService/AddClass.vue";
import EditClass from "@/components/DialogComponents/ClassService/EditClass.vue";
import ChooseStudent from "@/components/DialogComponents/ClassService/ChooseStudent.vue";
import ClassStudentList from "@/components/DialogComponents/ClassService/ClassStudentList.vue";
import ClassTeacherList from "@/components/DialogComponents/ClassService/ClassTeacherList.vue";
import GradeStatusChange from "@/components/DialogComponents/ClassService/GradeStatusChange.vue";
import Matching from "@/components/DialogComponents/TeacherResources/Matching.vue";
import ConfirmList from "@/components/DialogComponents/TeacherResources/ConfirmList.vue";
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'

import CreatePlanDialog from '@/components/PlanComponents/CreatePlanDialog/index.vue'


import {
    gradelist,
    gradeistDelete,
    gradeDelete,
    gradelistEdit,
    gradeSync,
    gradeSyncEdit,
    gradeEdit,
    gradeExport
} from "@/request/api";
import { formateGradeInfo } from "@/utils/helper";
export default {
    components: {
    TableTittle,
    AddClass,
    EditClass,
    ChooseStudent,
    ClassStudentList,
    ClassTeacherList,
    GradeStatusChange,
    Matching,
    ConfirmList,
    Record,
    CreatePlanDialog
  },
    data() {
        return {
            dialogFormVisible: false,
            tableData: [],
            createDialog: false,
            query: {
                pageIndex: 1,
                pageSize: 10,
                isPaging: 1,
                archive: "0",
            },
            pageTotal: 0,
            chooseList: [],
            editDialog: false,
            editId: null,
            ChooseStudentId: null,
            chooseDialog: false,
            ClassStudentListId: null,
            ClassStudentListDialog: false,
            ClassTeacherListDialog: false,
            ClassTeacherListId: null,

            create_time: "",
            classNumber: 1,
            unitConversion: 1,
            unitConversion2: 1,
            changeDialog: false,
            matchId: null,
            matchDialog: false,
            confirmIds: '',
            confirmDialog: false,
            grade_id: null,
            order_teachers: [],
            orderId: null,
            recordDialog: false,
            recordGradeId: null,
            confirmOrder_id: null,
            uncheck: 0,
        }
    },
    created() {

    },
    methods: {
        open(name) {
            this.$set(this.query,'name_keyword',name)
            this.dialogFormVisible = true
            this.fetchList();
        },
        fetchCheckCount() {
            gradelist({
                is_has_allocation_confirm: 1,
                isPaging: 0
            }).then((response) => {
                let res = response.data
                this.uncheck = res.data.count
            });
        },
        handleSizeChange(val) {
            this.query.pageSize = val;
            this.fetchList();
        },
        handleCurrentChange(val) {
            this.query.pageIndex = val;
            this.fetchList();
        },
        fetchList(isReset = false) {
            if (isReset) {
                this.$set(this.query, "pageIndex", 1);
            }
            const params = Object.assign({}, this.query);
            if (params.types) {
                params.types = params.types.join(',')
            }
            if (params.statuses) {
                params.statuses = params.statuses.join(',')
            }
            if (params.learn_managers) {
                params.learn_managers = params.learn_managers.join(',')
            }
            if (params.grade_sync_statuses) {
                params.grade_sync_statuses = params.grade_sync_statuses.join(',')
            }
            gradelist(params).then((response) => {
                let res = response.data
                this.tableData = res.data.list || [];
                this.pageTotal = res.data.count;
                this.fetchCheckCount()

            });
            this.syncQuery();
        },
        // 解析URL请求参数
        parseQuery() {
            Object.keys(this.$route.query).forEach((key) => {
                this.query[key] = this.$route.query[key];
            });
            if (this.query.types && this.query.types.length) {
                this.query.types = this.query.types.split(",").map(v => { return Number(v) });
            }
            if (this.query.statuses && this.query.statuses.length) {
                this.query.statuses = this.query.statuses.split(",").map(v => { return Number(v) });
            }
            if (this.query.learn_managers && this.query.learn_managers.length) {
                this.query.learn_managers = this.query.learn_managers.split(",").map(v => { return Number(v) });
            }
            if (this.query.grade_sync_statuses && this.query.grade_sync_statuses.length) {
                this.query.grade_sync_statuses = this.query.grade_sync_statuses.split(",").map(v => { return Number(v) });
            }
        },
        // 同步URL请求参数
        syncQuery() {
            const params = Object.assign({}, this.query);
            let isSync = Object.keys(params).some(
                (key) => this.$route.query[key] != params[key]
            );
            if (params.types) {
                params.types = params.types.filter((v) => v).join(",");
            }
            if (params.statuses) {
                params.statuses = params.statuses.filter((v) => v).join(",");
            }
            if (params.learn_managers) {
                params.learn_managers = params.learn_managers.filter((v) => v).join(",");
            }
            if (params.grade_sync_statuses) {
                params.grade_sync_statuses = params.grade_sync_statuses.filter((v) => v).join(",");
            }
            isSync &&
                this.$router.replace({
                    query: Object.assign({}, this.$route.query, params),
                });
        },
        handleSelectionChange(val) {
            this.chooseList = val
        },
        formateType(type) {
            let types = {
                '0': '一对一',
                '1': '小班课',
                '2': '大班课',
                '3': '试听课',
            }
            return types[type]
        },
        formateMember(val) {
            if (val) {
                let val_arr = val.split(',')
                if (val_arr.length > 3) {
                    return val.split(',').slice(0, 1).join('，') + '等'
                } else {
                    return val.split(',').slice(0, 1).join('，')
                }
            } else {
                return '无'
            }
        },
        handleToGo(url, row) {
            this.$router.push(`${url}?class_id=${row.id}`)
        },
        handleToEdit(row) {
            this.editId = row.id
            this.editDialog = true
        },
        handleToDeleteAll() {
            if (this.chooseList.length == 0) {
                this.$message.error('还没有选择要删除的数据')
                return
            }
            if (this.chooseList.findIndex(v => {
                return v.status != 0
            }) != -1) {
                this.$message.error('仅可删除正在对接状态下的班级')
                return
            }
            this.$confirm("确定要删除这些信息吗", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let ids = this.chooseList.map(v => { return v.id })
                gradeistDelete({ ids }).then((res) => {
                    this.fetchList(this.query);
                });
            })
        },
        ChooseStudent(row) {
            if (row.student_ids) {
                this.ClassStudentListId = row.id
                this.ClassStudentListDialog = true
            } else {
                this.ChooseStudentId = row.id
                this.chooseDialog = true
            }
        },
        ChooseTeacher(row) {
            this.ClassTeacherListId = row.id
            this.confirmOrder_id = row.allocation_order_obj ? row.allocation_order_obj.id : null
            this.ClassTeacherListDialog = true
        },
        handleToRemove(id) {
            this.$confirm("确定要删除此班级吗", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                gradeDelete({ id }).then((res) => {
                    this.fetchList(this.query);
                });
            })
        },
        openCreateDialog() {
            let sort = 1;
            if (this.tableData.length) {
                sort = this.tableData[0].id + 1
            }
            this.classNumber = sort
            this.createDialog = true
        },
        handleToRecord(row) {
            window.open(`/classservice/record?grade_id=${row.id}`)
            /*    this.$router.push(`/classservice/record?grade_id=${row.id}`) */
        },

        handleToPlanCalendar(row) {
            this.$refs.createPlanDialog.open(row.id)
            // window.open(`/classservice/plan/calendar?grade_id=${row.id}`)

            /*   this.$router.push(`/classservice/plan/calendar?grade_id=${row.id}`) */
        },
        chooseAllEdit(archive_status, text) {
            if (this.chooseList.length == 0) {
                this.$message.error('还没有选择要修改的数据')
                return false;
            }
            this.$confirm(text, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let query = {
                        rows: this.chooseList.map(v => {
                            return {
                                id: v.id,
                                archive: archive_status,
                            }
                        }),
                    };
                    gradelistEdit(query).then((res) => {
                        this.fetchList();
                    });
                })
                .catch(() => {
                    this.$notify.error({
                        title: "提示",
                        message: "已取消操作",
                    });
                });
        },
        handleCommand(e) {
            this.unitConversion = e
            this.$refs['popover'].doClose()
        },
        handleCommandOther(e) {
            this.unitConversion2 = e
            this.$refs['popover'].doClose()
        },
        handleToGradeRecord(row) {
            this.$router.push(`/classservice/class/record/${row.id}`)
        },
        handleToSync(row) {
            gradeSync(formateGradeInfo(row, 'create')).then((res) => {
                if (res.data.data.message == "ok") {
                    gradeEdit({ id: row.id, old_id: res.data.data.data.id }).then((resp) => {
                        console.log(resp);
                        this.fetchList();
                    });
                } else {
                    this.$message.error(res.data.data.message)
                }
            });
        },
        openChangeDialog(row) {
            this.editId = row.id
            this.changeDialog = true
        },
        formateProgress(row) {
            if (row.grade_plan_data) {
                let total = row.grade_plan_data.plan_total_lesson_hour ? Number(row.grade_plan_data.plan_total_lesson_hour) : 0
                let now = row.grade_plan_data.plan_exist_lesson_hour ? Number(row.grade_plan_data.plan_exist_lesson_hour) : 0
                return Number(((now / total) * 100).toFixed(2)) || 0
            } else {
                return 0
            }
        },
        handleExport() {
            this.$confirm("是否导出数据", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                const params = Object.assign({}, this.query);
                if (params.types) {
                    params.types = params.types.join(',')
                }
                if (params.statuses) {
                    params.statuses = params.statuses.join(',')
                }
                if (params.learn_managers) {
                    params.learn_managers = params.learn_managers.join(',')
                }
                gradeExport(params).then((res) => {
                    if (res.data.code == 0) {
                        this.$message.success('导出成功')
                        //创建a标签用于跳转至下载链接
                        var str = res.data.data.url.replace(/http:/, window.location.protocol)
                        this.$store.commit("downLoad", str);
                    }
                });
            })
        },
        handleToMatching(row) {
            if (row.student_ids) {
                this.matchId = row.id
                this.matchDialog = true
            } else {
                this.$message.error('请选择上课学员')
            }
        },
        openConfirmList(row) {
            this.grade_id = row.id
            this.confirmOrder_id = row.allocation_order_obj.id
            this.confirmDialog = true
        },
        handleToOrderRecord(row) {
            this.orderId = row.allocation_order_obj.id
            this.recordGradeId = row.id
            this.recordDialog = true
        },
        chooseAllEditStatus() {
            if (this.chooseList.length == 0) {
                this.$message.error('还没有选择要修改的数据')
                return false;
            }
            if (this.chooseList.findIndex(v => {
                return [2, 3].includes(v.grade_sync_status)
            }) > -1) {
                this.$message.error('选中班级里有匹配中，待确认的状态，请先取消匹配后再终止班级！')
                return false;
            }
            this.$confirm('您确定要终止班级吗', "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let query = {
                        rows: this.chooseList.map(v => {
                            return {
                                id: v.id,
                                status: 2,
                                archive: 1
                            }
                        }),
                    };
                    gradelistEdit(query).then((res) => {
                        this.fetchList();
                    });
                })
                .catch(() => {
                    this.$notify.error({
                        title: "提示",
                        message: "已取消操作",
                    });
                });
        },
        changeStatus(row) {
            this.$confirm('您确定要恢复班级吗', "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let query = {
                        id: row.id,
                        status: 50,
                    };
                    gradeEdit(query).then((res) => {
                        this.fetchList();
                    });
                })
                .catch(() => {
                    this.$notify.error({
                        title: "提示",
                        message: "已取消操作",
                    });
                });
        }

    }
}
</script>

<style lang="less" scoped>

.operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }
  .left {
    text-align: left;
  }
  .badge {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>