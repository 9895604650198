<template>
  <div class="content">
    <el-dialog
      top="5vh"
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="dialogFormVisible = false"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="dataForm"
        :rules="rules"
        label-width="150px"
        size="small"
      >
        <el-form-item label="年份" prop="year">
          <el-select
            v-model="dataForm.year"
            placeholder="请选择年份"
            style="width: 360px"
          >
            <el-option
              v-for="(item, index) in yearsList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招生类型" prop="enrol_type">
          <el-select
            v-model="dataForm.enrol_type"
            placeholder="请选择招生类型"
            style="width: 360px"
          >
            <el-option
              v-for="(item, index) in enrolType"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考院校" prop="college_code">
          <el-select
            filterable
            v-model="dataForm.college_code"
            placeholder="请选择院校"
            style="width: 360px"
            clearable
            @change="collegeChoose"
          >
            <el-option
              v-for="item in $store.state.collegeList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业" prop="major_code">
          <el-select
            filterable
            style="width: 360px"
            v-model="dataForm.major_code"
            placeholder="请选择专业"
            @change="majorChange"
            clearable
          >
            <el-option
              v-for="(item, index) in majorOptions"
              :key="index"
              :label="item.allnames"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学院" prop="academy_code">
          <el-select
            filterable
            v-model="dataForm.academy_code"
            @change="academyChange"
            placeholder="请选择学院"
            clearable
            style="width: 360px"
          >
            <el-option
              v-for="(item, index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="item.academy_code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="方向" prop="directions">
          <el-select
            multiple
            v-model="dataForm.directions"
            placeholder="请选择方向"
            clearable
            style="width: 360px"
          >
            <el-option
              v-for="(item, index) in directionOptions"
              :key="index"
              :label="
                item.direction == '不限'
                  ? '不限'
                  : `(${item.direction_code})${item.direction}`
              "
              :value="
                item.direction == '不限'
                  ? ''
                  : `(${item.direction_code})${item.direction}`
              "
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="item.title"
          v-for="(item, index) in dataForm.subjects"
          :key="item.title"
          :prop="'subjects' + index"
        >
          <el-input
            style="width: 150px"
            v-model="item.code"
            placeholder="输入代码"
          ></el-input>
          <el-input
            style="width: 200px; margin-left: 10px"
            v-model.trim="item.name"
            placeholder="输入名称"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="'参考书目' + (index + 1)"
          v-for="(item, index) in dataForm.reference_books"
          :key="index"
        >
          <el-input
            style="width: 360px"
            v-model.trim="item.name"
            placeholder="请输入参考书目"
          ></el-input>
          <el-button
            type="primary"
            style="margin-left: 5px"
            @click="bookAdd"
            v-if="index == 0"
            >添加</el-button
          >
          <el-button
            type="danger"
            style="margin-left: 5px"
            @click="delBook(index)"
            v-if="index != 0"
            >删除</el-button
          >
        </el-form-item>

        <el-form-item label="一志愿招生录取人数" prop="user_count_first">
          <el-input
            style="width: 360px"
            v-model.trim="dataForm.user_count_first"
            placeholder="请输入一志愿招生录取人数"
          ></el-input>
        </el-form-item>
        <el-form-item label="一志愿招生最高分" prop="high_score">
          <el-input
            style="width: 360px"
            v-model.trim="dataForm.high_score"
            placeholder="请输入一志愿招生最高分"
          ></el-input>
        </el-form-item>
        <el-form-item label="一志愿招生最低分" prop="low_score">
          <el-input
            style="width: 360px"
            v-model.trim="dataForm.low_score"
            placeholder="请输入一志愿招生最低分"
          ></el-input>
        </el-form-item>

        <el-form-item label="调剂招生录取人数" prop="user_count_adjust">
          <el-input
            style="width: 360px"
            v-model.trim="dataForm.user_count_adjust"
            placeholder="请输入调剂招生录取人数"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="总拟招生人数" prop="plan_enrol_count">
          <el-input
            style="width: 360px"
            v-model.trim="dataForm.plan_enrol_count"
            placeholder="请输入总拟招生人数"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="复试科目名" prop="second_exam_subject_names">
          <el-input
            style="width: 360px"
            v-model.trim="dataForm.second_exam_subject_names"
            placeholder="请输入复试科目名"
          ></el-input>
        </el-form-item>
        <el-form-item label="复试参考书" prop="second_exam_reference_books">
          <el-input
            style="width: 360px"
            v-model.trim="dataForm.second_exam_reference_books"
            placeholder="请输入复试参考书"
          ></el-input>
        </el-form-item>

        <el-form-item label="考试大纲">
          <el-upload
            :limit="2"
            :file-list="fileList"
            class="upload-demo"
            drag
            accept=".docx ,.pdf"
            :action="action"
            multiple
            :on-success="handleAvatarSuccess"
            :on-remove="imgListRemove"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">只能上传docx/pdf文件</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="submit(true)" plain v-if="title=='新增'">
          确认并继续
        </el-button>
        <el-button type="primary" @click="submit()" plain> 确认 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { adminUrl } from "@/utils/helper.js";
import { imgUploadUrl } from "@/request/http";

import {
  majorlist,
  collegeMajorRecruitDetail,
  collegeMajorRecruitAdd,
  collegeMajorRecruitPut,
  subjectlist,
} from "@/request/api";
export default {
  data() {
    return {
      title: "新增",
      dialogFormVisible: false,
      action: imgUploadUrl(),
      fileList: [],
      dataForm: {
        academy_code: "",
        directions: [],
        subjects: [
          {
            title: "科目一",
            code: "",
            name: "",
          },
          { title: "科目二", code: "", name: "" },
          { title: "科目三", code: "", name: "" },
          { title: "科目四", code: "", name: "" },
        ],
        reference_books: [
          {
            name: "",
          },
        ],
      },
      enrolType: [
        {
          label: "全日制",
          value: 1,
        },
        {
          label: "非全日",
          value: 2,
        },
      ],
      yearsList: [
        {
          label: "2023年",
          value: 2023,
        },
        {
          label: "2022年",
          value: 2022,
        },
        {
          label: "2021年",
          value: 2021,
        },
        {
          label: "2020年",
          value: 2020,
        },
      ],
      majorOptions: [],
      academyOptions: [],
      directionOptions: [],
      rules: {
        year: [{ required: true, message: "年份不能为空", trigger: "blur" }],
        enrol_type: [
          {
            required: true,
            message: "招生类型不能为空",
            trigger: "blur",
          },
        ],
        college_code: [
          { required: true, message: "院校不能为空", trigger: "blur" },
        ],
        major_code: [
          { required: true, message: "专业不能为空", trigger: "blur" },
        ],
        academy_code: [
          { required: true, message: "学院不能为空", trigger: "blur" },
        ],
        subjects0: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.dataForm.subjects[0].name) {
                callback(new Error("名称不能为空"));
              } else if (!this.dataForm.subjects[0].code) {
                callback(new Error("代码不能为空"));
              } else if (
                !(
                  !isNaN(this.dataForm.subjects[0].code) &&
                  this.dataForm.subjects[0].code >= 0 &&
                  this.dataForm.subjects[0].code.length == 3
                )
              ) {
                callback(new Error("只能输入3位数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        subjects1: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.dataForm.subjects[1].name) {
                callback(new Error("名称不能为空"));
              } else if (!this.dataForm.subjects[1].code) {
                callback(new Error("代码不能为空"));
              } else if (
                !(
                  !isNaN(this.dataForm.subjects[1].code) &&
                  this.dataForm.subjects[1].code >= 0 &&
                  this.dataForm.subjects[1].code.length == 3
                )
              ) {
                callback(new Error("只能输入3位数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        subjects2: [
          {
            validator: (rule, value, callback) => {
              if (
                this.dataForm.subjects[2].code &&
                !this.dataForm.subjects[2].name
              ) {
                callback(new Error("请输入名称"));
              } else if (
                !this.dataForm.subjects[2].code &&
                this.dataForm.subjects[2].name
              ) {
                callback(new Error("请输入代码"));
              } else if (
                !(
                  !isNaN(this.dataForm.subjects[2].code) &&
                  this.dataForm.subjects[2].code >= 0 &&
                  this.dataForm.subjects[2].code.length == 3
                ) &&
                this.dataForm.subjects[2].code
              ) {
                callback(new Error("只能输入3位数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        subjects3: [
          {
            validator: (rule, value, callback) => {
              if (
                this.dataForm.subjects[3].code &&
                !this.dataForm.subjects[3].name
              ) {
                callback(new Error("请输入名称"));
              } else if (
                !this.dataForm.subjects[3].code &&
                this.dataForm.subjects[3].name
              ) {
                callback(new Error("请输入代码"));
              } else if (
                !(
                  !isNaN(this.dataForm.subjects[3].code) &&
                  this.dataForm.subjects[3].code >= 0 &&
                  this.dataForm.subjects[3].code.length == 3
                ) &&
                this.dataForm.subjects[3].code
              ) {
                callback(new Error("只能输入3位数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        user_count_first: [
          {
            required: true,
            message: "一志愿录取人数不能为空",
            trigger: "blur",
          },
          {
            pattern: /^([0]|[1-9][0-9]*)$/,
            message: "请输入正整数",
            trigger: "blur",
          },
        ],
        high_score: [
          {
            pattern: /^[1-9](\d*)$/,
            message: "请输入不为0的正整数",
            trigger: "blur",
          },
        ],
        low_score: [
          {
            validator: (rule, value, callback) => {
              let ex = /^[1-9](\d*)$/;
              if (
                this.dataForm.low_score &&
                !ex.test(this.dataForm.low_score)
              ) {
                callback(new Error("请输入不为0的正整数"));
              } else if (
                this.dataForm.high_score &&
                this.dataForm.low_score &&
                Number(this.dataForm.high_score) <
                  Number(this.dataForm.low_score)
              ) {
                callback(new Error("最低分不能高于最高分"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        // plan_enrol_count: [
        //   {
        //     pattern:  /^([0]|[1-9][0-9]*)$/,

        //     message: "请输入正整数",
        //     trigger: "blur",
        //   },
        // ],
        user_count_adjust: [
          {
            pattern: /^([0]|[1-9][0-9]*)$/,

            message: "请输入正整数",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    open(id) {
      this.fileList = [];
      this.dialogFormVisible = true;
      if (id) {
        this.title = "编辑";
        collegeMajorRecruitDetail({ id: id }).then((res) => {
          if (res.data.code == 0) {
            let data = res.data.data;
            // console.log(data);
            this.dataForm = {
              id: id,
              year: data.year,
              enrol_type: data.enrol_type,
              college_code: data.college_code,
              academy_code: data.academy_code,
              major_code: data.major_code,
              // plan_enrol_count: data.plan_enrol_count,
              directions: data.directions ? data.directions.split(",") : [""],
              subjects: [
                {
                  title: "科目一",
                  code: "",
                  name: "",
                },
                { title: "科目二", code: "", name: "" },
                { title: "科目三", code: "", name: "" },
                { title: "科目四", code: "", name: "" },
              ],
              reference_books: [{ name: "" }],
              user_count: data.user_count,
              user_count_first: data.user_count_first,
              user_count_adjust: data.user_count_adjust,
              low_score: data.low_score,
              high_score: data.high_score,
              second_exam_subject_names :data.second_exam_subject_names,
              second_exam_reference_books :data.second_exam_reference_books, 
              exam_outline_file_urls: data.exam_outline_file_urls,
            };
            // console.log('data',this.dataForm)
            let subjects = JSON.parse(data.subjects);
            if (subjects && subjects.length > 0) {
              subjects[0].forEach((item, index) => {
                this.dataForm.subjects[index].code = item.substr(1, 3);
                this.dataForm.subjects[index].name = item.substr(5);
              });
            }

            if (data.reference_books) {
              this.dataForm.reference_books = data.reference_books
                .split(",")
                .map((item) => {
                  return { name: item };
                });
            }
            if (data.exam_outline_file_urls) {
              this.fileList = data.exam_outline_file_urls
                .split(",")
                .map((item, index) => {
                  return {
                    name: item,
                    uid: index,
                    url: item,
                  };
                });
            }
            if (data.college_code) {
              this.getMajor(data.college_code);
            }
            if (data.college_code && data.major_code) {
              this.getMajorInfo(data.college_code, data.major_code);
              // this.academyChange()
              // this.directionInfo(data.college_code, data.major_code);
            }
          }
          //   console.log("res", res);
        });
      } else {
        this.title = "新增";
        this.dataForm = {
          academy_code: "",
          directions: [],
          subjects: [
            {
              title: "科目一",
              code: "",
              name: "",
            },
            { title: "科目二", code: "", name: "" },
            { title: "科目三", code: "", name: "" },
            { title: "科目四", code: "", name: "" },
          ],
          reference_books: [
            {
              name: "",
            },
          ],
        };
      }

      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate(); //清空表单
      });
    },
    submit(flag) {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          let data = {
            ...this.dataForm,
            user_count:
              Number(this.dataForm.user_count_first) +
              Number(this.dataForm.user_count_adjust || 0),
            subjects: [
              this.dataForm.subjects
                .map((item) => {
                  if (item.name && item.code) {
                    return `(${item.code})${item.name}`;
                  }
                })
                .filter((item) => item),
            ],
            directions: this.dataForm.directions
              .filter((item) => item)
              .join(","),
            reference_books: this.dataForm.reference_books
              .map((item) => item.name)
              .join(","),
            exam_outline_file_urls: this.fileList
              .map((item) => item.url)
              .join(","),
          };
          //   console.log(data)
          //     return
          if (this.title == "新增") {
            collegeMajorRecruitAdd(data).then((res) => {
              if (res.data.code == 0) {
                if (flag) {
                  // this.getMajor()
                  this.fileList = []
                  this.dataForm = {
                    year:this.dataForm.year,
                    enrol_type: this.dataForm.enrol_type,
                    college_code: this.dataForm.college_code,
                    academy_code: "",
                    directions: [],
                    subjects: [
                      {
                        title: "科目一",
                        code: "",
                        name: "",
                      },
                      { title: "科目二", code: "", name: "" },
                      { title: "科目三", code: "", name: "" },
                      { title: "科目四", code: "", name: "" },
                    ],
                    reference_books: [
                      {
                        name: "",
                      },
                    ],
                  };
                  this.$message.success("新增成功！");
                  this.$emit("submit");
                } else {
                  this.dialogFormVisible = false;
                  this.$message.success("新增成功！");
                  this.$emit("submit");
                }
              }
              //   console.log("res", res);
            });
          } else {
            collegeMajorRecruitPut(data).then((res) => {
              if (res.data.code == 0) {
                this.dialogFormVisible = false;
                this.$message.success("修改成功！");
                this.$emit("submit");
              }
              console.log("res", res);
            });
          }
        }
      });
    },
    handleAvatarSuccess(res, file, fileList) {
      this.fileList.push({
        name: res.data.name,
        url: res.data.url,
        uid: file.uid,
      });
      // console.log("fileList",this.fileList)
    },
    imgListRemove(file, fileList) {
      this.fileList = this.fileList.filter((item) => item.uid != file.uid);
      // console.log("fileList",this.fileList)
    },

    bookAdd() {
      if (this.dataForm.reference_books.length < 20) {
        this.dataForm.reference_books.push({
          name: "",
        });
      } else {
        this.$message.error("最多只能添加20本");
      }
    },
    delBook(index) {
      this.dataForm.reference_books.splice(index, 1);
    },
    // 选择院校
    collegeChoose(e) {
      this.dataForm.major_code = "";
      this.majorOptions = [];
      this.dataForm.academy_code = "";
      this.academyOptions = [];
      this.directionOptions = [];
      this.dataForm.directions = [];
      if (e) {
        this.getMajor(e);
      }
    },

    getMajor(college_code) {
      let dataForm = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(dataForm).then((response) => {
        let res = response.data;
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          };
        });
      });
    },
    // 选择专业
    majorChange(e) {
      // console.log("pp",e)
      this.dataForm.academy_code = "";
      this.academyOptions = [];
      this.directionOptions = [];
      this.dataForm.directions = [];
      if (e) {
        this.getMajorInfo(this.dataForm.college_code, e);
        // this.directionInfo(this.dataForm.college_code, e);
      }
    },

    getMajorInfo(college_code, major_code) {
      let str = adminUrl();
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: "get", //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data;
        this.academyOptions = res.data.academys;
        if (this.dataForm.academy_code) {
          this.academyChange(this.dataForm.academy_code);
        }
      });
    },
    //选择学院
    academyChange(e) {
      this.directionOptions = [];
      if (!e) {
        this.dataForm.directions = [];
        return;
      }
      // console.log(e,this.academyOptions)
      this.directionOptions = this.academyOptions.find(
        (item) => item.academy_code == e
      ).directions;
      if (!this.directionOptions.some((item) => item.direction == "不限")) {
        this.directionOptions.unshift({
          direction: "不限",
          direction_code: "",
        });
      }
      if (this.title == "新增") {
        this.dataForm.directions = [""];
        this.getSubjectOptions(
          this.dataForm.college_code,
          this.dataForm.academy_code,
          this.dataForm.major_code
        );
      }
    },
    // 科目
    getSubjectOptions(college_code, academy_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        academy_code: academy_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        let subjectsOptions = res.data.data.list || [];
        if (subjectsOptions && subjectsOptions.length > 0) {
          subjectsOptions.forEach((item, index) => {
            this.$set(this.dataForm.subjects[index], "name", item.subject_name);
            this.$set(this.dataForm.subjects[index], "code", item.subject_code);
          });
        }
        // subjects
        // console.log('lll',this.subjectsOptions)
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>