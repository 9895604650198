<template>
  <div class="content">
    <el-dialog
top="5vh"
      title="新建账单"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="501px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="100px"
        label-position="right"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item
          label="机构简称"
          prop="cid"
        >
          <el-select
            v-model="form.cid"
            placeholder="请选择"
            style="width: 350px"
            filterable
          >
            <el-option
              v-for="item in $store.state.CompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="账单明细"
          prop="detail_file_url"
          ref="fileUpload"
        >
          <el-upload
            class="upload-demo"
            action="https://manage-api.yanshizi.com/api/v1/upload/file"
            :limit="1"
            :on-success="handleSuccessFile"
            :on-exceed="handleExceed"
            ref="upload"
          >
            <el-button
              size="small"
              type="primary"
            >点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="账单类型"
          prop="type"
        >
          <el-select
            v-model="form.type"
            placeholder="请选择"
            style="width: 270px"
          >
            <el-option
              v-for="item in $store.state.BillsTypeOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="账单周期"
          prop="billTime"
        >
          <el-date-picker
            v-model="form.billTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="结算人数"
          prop="service_students"
        >
          <el-input
            v-model.number="form.service_students"
            placeholder="请输入结算人数"
            clearable
          ><template slot="append">人</template></el-input>
        </el-form-item>
        <el-form-item
          label="服务费用"
          prop="service_amount"
        >
          <el-input
            v-model="form.service_amount"
            placeholder="请输入服务费用"
            clearable
            @input="computedNumber"
          ><template slot="append">元</template></el-input>
        </el-form-item>
        <el-form-item
          label="结算课时"
          prop="service_hours"
        >
          <el-input
            v-model="form.service_hours"
            placeholder="请输入结算课时"
            clearable

          > <template slot="append">小时</template></el-input>
        </el-form-item>
        <el-form-item
          label="课时费用"
          prop="product_amount"
        >
          <el-input
            v-model="form.product_amount"
            placeholder="请输入课时费用"
            clearable
            @input="computedNumber"
          ><template slot="append">元</template></el-input>
        </el-form-item>
        <el-form-item
          label="其他费用"
          prop="other_amount"
        >
          <el-input
            v-model="form.other_amount"
            placeholder="请输入其他费用"
            clearable
            @input="computedNumber"
          ><template slot="append">元</template></el-input>
        </el-form-item>
        <el-form-item
          label="总费用"
          prop="total_amount"
        >
          <el-input
            v-model="form.total_amount"
            placeholder="请输入总费用"
            clearable
            disabled
          ><template slot="append">元</template></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="form.remark"
            placeholder="请输入备注"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      form: {
        service_amount: "",
        product_amount: "",
        other_amount: "",
        total_amount: 0,
        detail_file_url: "",
      },
      billTime: "",
      rules: {
        cid: [{ required: true, message: "请选择机构", trigger: "blur" }],
        detail_file_url: [
          { required: true, message: "请上传账单明细", trigger: "blur" },
        ],
        bill_no: [{ required: true, message: "请输入账单ID", trigger: "blur" }],
        service_amount: [
          { required: true, message: "请输入服务费用" },

        ],
        service_hours: [
          { required: true, message: "请输入结算课时" },
          { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' },
        ],
        total_amount: [
          { required: true, message: "请输入总费用" },

        ],
        product_amount: [
          { required: true, message: "请输入课时费用" },

        ],
        type: [{ required: true, message: "请选择账单类型", trigger: "blur" }],
        billTime: [
          { required: true, message: "请选择账单周期", trigger: "blur" },
        ],
        service_students: [
          { required: true, message: "请输入结算人数" },
          { type: "number", message: "数算人员必须为数字值" },
        ],
        other_amount: [
          { required: true, message: "请输入其他费用" },

        ],
      },
    };
  },
  mounted() { },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    emitClose() {
      this.form = {
        service_amount: "",
        product_amount: "",
        other_amount: "",
        total_amount: 0,
        detail_file_url: "",
      };
      this.$refs["upload"].clearFiles();
      this.$emit("close");
    },
    submit(rules) {
      if (this.form.detail_file_url.length) {
        console.log(_);
        _.unset(this.rules, ["detail_file_url"]);
      }
      this.$refs[rules].validate((valid) => {
        if (valid) {
          // 时间区间处理
          if (this.form.billTime) {
            this.form.start_time = this.form.billTime[0];
            this.form.end_time = this.form.billTime[1];
          } else {
            this.form.start_time = "";
            this.form.end_time = "";
          }
          this.$emit("submit", { form: this.form });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 文件上传成功处理
    handleSuccessFile(response, file, fileList) {
      this.form.detail_file_url = fileList[0].response.data.url;
      this.$refs.fileUpload.clearValidate(); // 关闭校验
    },
    // 文件超出一个
    handleExceed(files, fileList) {
      this.$notify({
        title: "提示",
        message: "只能上传一个文件",
        type: "warning",
      });
    },
    computedNumber(e) {
      this.form.total_amount =
        (Number(this.form.service_amount) +
          Number(this.form.product_amount) +
          Number(this.form.other_amount)).toFixed(2)
    },

  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
.el-input {
  width: 350px;
}
</style>