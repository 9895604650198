<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>

        <el-breadcrumb-item :to="{ path: '/classservice/teacher' }">老师管理</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="上课时间">
          <el-date-picker
            class="datetime"
            v-model="plan_start_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="fetchList(true)"
            clearable
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="课时主题">
          <el-input
            v-model.trim="query.plan_course_theme"
            placeholder="请输入计划课程主题"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <!--         <el-form-item label="课时状态">
          <el-select
            v-model="query.lesson_statuses"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.lesson_status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="班级">
          <el-input
            v-model.trim="query.grade_keyword"
            placeholder="请输入班级关键字"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="学生">
          <el-input
            v-model.trim="query.student_keyword"
            placeholder="请输入学生关键字"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="老师">
          <el-input
            v-model.trim="query.teacher_keyword"
            placeholder="请输入老师关键字"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="课酬状态">
          <el-select
            v-model="query.senior_course_order_statuses"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.senior_course_order_status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!--   <el-form-item label="账单名称">
          <el-input
            v-model.trim="query.plan_course_theme"
            placeholder="请输入计划课程主题"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item label="学管师">
          <el-select
            v-model="query.learn_manager"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
          >
            <el-option
              v-for="item in $store.state.staffOptions.filter(v=>{
                return v.depts.findIndex(t=>{
                  return t.id==4 && v.status == 1
                })!=-1
              })"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
          style="margin-bottom:0px;display:flex;"
          label-position="right"
        >
          <el-form-item label-width="32px">
            <el-radio-group
              v-model="lesson_statuses"
              @change="fetchList(true)"
            >
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button :label="1">待生成</el-radio-button>
              <el-radio-button :label="999">待生成（上月）</el-radio-button>
              <el-radio-button :label="2">已拒绝</el-radio-button>
            </el-radio-group>
          </el-form-item>

        </el-form>

      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{  fontSize: '13px' }"
        border
        @selection-change="handleSelectionChangeTab0"
      >
        <el-table-column
          type="selection"
          width="50"
          fixed="left"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="80"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          width="160"
          align="center"
          show-overflow-tooltip
        >
          <template slot="header">
            <el-popover ref="popover">

              <span
                class="el-dropdown-link"
                slot="reference"
              >
                {{unitConversion==1?'计划上课时间':'实际上课时间'}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <div
                class="popover-item"
                @click="handleCommand(1)"
              >计划上课时间</div>
              <div
                class="popover-item"
                @click="handleCommand(2)"
              >实际上课时间</div>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <div v-if="unitConversion==1">
              {{scope.row.plan_start_time?$moment(scope.row.plan_start_time).format("YYYY-MM-DD HH:mm"):""}}
            </div>
            <div v-if="unitConversion==2">
              <span v-if="scope.row.final_real_lesson_start_time">{{$moment(scope.row.final_real_lesson_start_time).format("YYYY-MM-DD HH:mm")}}</span>
              <el-tag
                type="info"
                size="mini"
                v-else
              >暂未上课</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="plan_course_theme"
          label="课时主题"
          width="200"
          align="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="plan_course_content"
          label="课程内容"
          width="300"
          align="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          width="120"
          show-overflow-tooltip
          align="center"
          label="课时时长"
        >

          <template slot-scope="scope">
            <div>{{scope.row.current_lesson_hour}}小时</div>
          </template>
        </el-table-column>
        <el-table-column
          label="课时状态"
          width="120"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.lesson_status==0"
              size="mini"
              type="warning"
            >待确认</el-tag>
            <el-tag
              v-if="scope.row.lesson_status==1"
              size="mini"
            >待回访</el-tag>
            <el-tag
              v-if="scope.row.lesson_status==2"
              size="mini"
              type="danger"
            >已拒绝</el-tag>
            <el-tag
              v-if="scope.row.lesson_status==3"
              size="mini"
              type="success"
            >已完成</el-tag>
            <el-tag
              v-if="scope.row.lesson_status==5"
              size="mini"
              type="success"
            >已完结</el-tag>
            <el-tag
              v-if="scope.row.lesson_status==6"
              size="mini"
            >已暂停</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="上课学员"
          width="220"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToStudentList(scope.row)"
            >
              {{formateMember(scope.row.student_realnames)}}
            </el-link>
          </template>
        </el-table-column>

        <el-table-column
          label="授课老师"
          width="120"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToTeacherInfo(scope.row)"
            >
              {{scope.row.teacher_realname}}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="grade_name"
          label="所在班级"
          align="center"
          width="260"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="学员评分"
          width="100"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToScoreDetail(scope.row)"
            >{{scope.row.student_apprise_teacher_average_score?`${scope.row.student_apprise_teacher_average_score}分`:'暂无'}}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="课时费"
          width="150"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.teacher_grade_lesson_hour_amount||0}}元/小时
          </template>
        </el-table-column>
        <el-table-column
          label="课酬状态"
          width="80"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="scope.row.senior_course_order_status">
              <el-tag
                size="small"
                v-if="scope.row.senior_course_order_status==0"
                type="warning"
              >待审核</el-tag>
              <el-tag
                size="small"
                v-else-if="scope.row.senior_course_order_status==1"
                type="success"
              >已通过</el-tag>
              <el-tag
                size="small"
                v-else-if="scope.row.senior_course_order_status==2"
                type="danger"
              >已拒绝</el-tag>
              <el-tag
                size="small"
                v-else-if="scope.row.senior_course_order_status==3"
                type="success"
              >已提现</el-tag>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="senior_course_order_name"
          label="账单名称"
          align="center"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.senior_course_order_name?scope.row.senior_course_order_name:'暂无'}}
          </template>
        </el-table-column>
        <el-table-column
          label="课程阶段"
          width="100"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{formateCourse_stage(scope.row.plan_course_stage)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="plan_course_target"
          label="课程目标"
          width="200"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="学管师"
          align="center"
          width="130"
        >
          <template slot-scope="scope">
            {{scope.row.learn_manager?scope.row.learn_manager.nickname:""}}
          </template>
        </el-table-column>
        <el-table-column
          label="创建日期"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="修改日期"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="操作"
          width="150"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link
                type="primary"
                :underline="false"
                @click="handleToConfirm(scope.row)"
              >确认详情</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button
          size="mini"
          @click="handleToBill"
        >生成课酬单</el-button>

        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <RecordStudentScore
      :isVisible="scoreDialog"
      @close="scoreDialog=false"
      @submit="fetchList()"
      :id="recordId"
    />
    <RecordStudentList
      :isVisible="studentDialog"
      @close="studentDialog=false"
      @submit="fetchList()"
      :id="recordId"
    />
    <RecordTeacherInfo
      :isVisible="infoDialog"
      @close="infoDialog=false"
      @submit="fetchList()"
      :id="teacherId"
    />
    <AddSeniorBill
      :isVisible="createbillDialog"
      @close="createbillDialog=false"
      @submit="fetchList()"
      :content="AddSeniorBillContent"
    />
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import RecordStudentScore from "@/components/DialogComponents/ClassService/RecordStudentScore.vue";
import RecordStudentList from "@/components/DialogComponents/ClassService/RecordStudentList.vue";
import RecordTeacherInfo from "@/components/DialogComponents/ClassService/RecordTeacherInfo.vue";
import AddSeniorBill from "@/components/DialogComponents/ClassService/AddSeniorBill.vue";



import {
  lessonLoglist,
} from "@/request/api";
export default {
  components: {
    TableTittle,
    RecordStudentScore,
    RecordStudentList,
    RecordTeacherInfo,
    AddSeniorBill
  },
  data() {
    return {
      TableName: '记录列表',
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      chooseList: [],
      recordId: null,
      scoreDialog: false,
      studentDialog: false,
      teacherId: null,
      infoDialog: false,
      plan_start_time: '',
      filters: {
        lesson_status: [
          { label: "待确认", value: 0 },
          { label: "待回访", value: 1 },
          { label: "已拒绝", value: 2 },
          { label: "已完成", value: 3 },
          { label: "已完结", value: 5 },
          { label: "已暂停", value: 6 },
        ],
        senior_course_order_status: [
          { label: "待审核", value: 0 },
          { label: "已通过", value: 1 },
          { label: "已拒绝", value: 2 },
          { label: "已提现", value: 3 },
        ]
      },
      createbillDialog: false,
      AddSeniorBillContent: [],
      unitConversion: 2,
      lesson_statuses: 1,
    };
  },
  created() {
    this.parseQuery();
    this.fetchList();

    this.$store.dispatch('getStaffOptions')


  },
  watch: {
    // 时间数据监听
    plan_start_time(value) {
      if (value) {
        this.query.start_plan_start_time = this.$moment(value[0]).valueOf();
        this.query.end_plan_start_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_plan_start_time = "";
        this.query.end_plan_start_time = "";
        this.fetchList(this.tableTab);
      }
    },
  },
  computed: {

  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.fetchList()
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      if (params.lesson_statuses) {
        params.lesson_statuses = params.lesson_statuses.join(',')
      }
      if (params.senior_course_order_statuses) {
        params.senior_course_order_statuses = params.senior_course_order_statuses.join(',')
      }
      if (this.lesson_statuses == 1) {
        params.lesson_statuses = "3"
      } else if (this.lesson_statuses == 999) {
        params.start_final_real_lesson_start_time = 0
        params.end_final_real_lesson_start_time = Number(this.$moment().month(this.$moment().month()).startOf('month').valueOf()) - 1
        params.lesson_statuses = "3"
      } else {
        params.start_final_real_lesson_start_time = ''
        params.end_final_real_lesson_start_time = ''
        params.lesson_statuses = this.lesson_statuses
      }
      lessonLoglist(params).then((response) => {
        let res = response.data
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },

    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
      if (this.query.lesson_statuses && this.query.lesson_statuses.length) {
        this.query.lesson_statuses = this.query.lesson_statuses.split(",").map(v => { return Number(v) });
      }
      if (this.query.senior_course_order_statuses && this.query.senior_course_order_statuses.length) {
        this.query.senior_course_order_statuses = this.query.senior_course_order_statuses.split(",").map(v => { return Number(v) });
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      if (params.lesson_statuses) {
        params.lesson_statuses = params.lesson_statuses.filter((v) => v).join(",");
      }
      if (params.senior_course_order_statuses) {
        params.senior_course_order_statuses = params.senior_course_order_statuses.filter((v) => v).join(",");
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    formateMember(val) {
      if (val) {
        let val_arr = val.split(',')
        if (val_arr.length > 3) {
          return val.split(',').slice(0, 3).join('，') + '等'
        } else {
          return val.split(',').slice(0, 3).join('，')
        }
      } else {
        return '无'
      }
    },
    formateCourse_stage(val) {
      let stages = {
        '0': '基础',
        '1': '强化',
        '2': '冲刺',
      }
      return stages[val]
    },

    handleToScoreDetail(row) {
      if (row.student_apprise_teacher_average_score) {
        this.recordId = row.id
        this.scoreDialog = true
      }

    },
    handleToStudentList(row) {
      this.recordId = row.id
      this.studentDialog = true
    },
    handleToTeacherInfo(row) {
      this.teacherId = row.teacher_id
      this.infoDialog = true
    },
    handleToConfirm(row) {
      window.open(`/classservice/record/confirm?lesson_log_id=${row.id}`)

      /*   this.$router.push(`/classservice/record/confirm?lesson_log_id=${row.id}`) */
    },
    handleSelectionChangeTab0(val) {
      this.chooseList = val
    },
    handleToBill() {
      if (!this.chooseList.length) {
        return this.$message.error('请选择数据')
      }
      let arr = this.chooseList.filter((v, i) => {
        return v.teacher_id != this.chooseList[0].teacher_id || v.grade_id != this.chooseList[0].grade_id
      })
      if (arr.length) {
        this.$message.error('请选择同个老师并且同个班级')
      } else {
        this.createbillDialog = true
        this.AddSeniorBillContent = this.chooseList
      }
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    }

  }
};
</script>

<style lang="less" scoped>
.content {
  .left {
    text-align: left;
  }
}
</style>
