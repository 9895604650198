<template>
    <div class="content">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>师资共享</el-breadcrumb-item>
                <el-breadcrumb-item>广告</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="button-wrap">
                <el-button type="primary" size="small" @click="handleAdd">新建</el-button>
            </div>
        </div>

        <el-card style="margin-top: 20px">
            <el-form class="search-wrap" ref="searchForm" :inline="true" :model="queryForm" size="small"
                label-position="left">
                <el-form-item label="广告名称">
                    <el-input v-model.trim="queryForm.title" placeholder="输入名称" clearable
                        @input="getSharePlatformList(true)"></el-input>
                </el-form-item>
                <el-form-item label="查看范围">
                    <el-select v-model="queryForm.share_user_typeses" placeholder="选择范围" @change="getSharePlatformList(true)">
                        <el-option v-for="(item, index) in rangeList" :key="index" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card style="margin-top: 20px">
            <TableTittle title="广告列表"></TableTittle>
            <el-table :data="tableData" :header-cell-style="{
                textAlign: 'center',
                color: '#909399',
                fontFamily: 'PingFang SC',
                fontSize: '14px',
                fontWeight: '400',
                backgroundColor: '#F2F6FC',
            }" :cell-style="{ fontSize: '13px' }" border>
                <el-table-column type="index" label="序号" width="100" align="center" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="grade_name" label="广告图" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image style="width: 70px; height: 95px" fit="cover" :src="scope.row.picture_url"
                            :preview-src-list="[scope.row.picture_url]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="广告名称" align="center" show-overflow-tooltip>
                </el-table-column>

                <!-- <el-table-column prop="link_url_web" label="广告链接" width="200" align="center" show-overflow-tooltip>
                </el-table-column> -->

                <el-table-column label="可见范围" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.range_type == 1 ? '全部可见' : getUserType(scope.row.range_only_user_types) }}
                        <!--  scope.row.range_only_user_types==1?'仅发单人':scope.row.range_only_user_types==2?'仅接单人':'仅游客'  -->
                    </template>
                </el-table-column>


                <el-table-column prop="teacher_realname" label="创建人" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ formateCreator(scope.row) }}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.create_time ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "" }}
                    </template>
                </el-table-column>
                <el-table-column label="更新时间" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.update_time ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm") : "" }}
                    </template>
                </el-table-column>

                <el-table-column prop="status" label="状态" width="100" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag type="danger" v-if="scope.row.status == 0">下线</el-tag>
                        <el-tag type="success" v-else>正常</el-tag>
                        <!-- {{ scope.row.status == 0 ? '无效' : '有效' }} -->
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="handleAudit(scope.row)">编辑</el-link>
                        <el-link @click="handleDel(scope.row.id)" type="danger" :underline="false">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top: 16px">
                <div class="block">
                    <el-pagination background layout="total, prev, pager, next, sizes, jumper"
                        :current-page="Number(queryForm.pageIndex)" :page-sizes="[10, 20, 50, 100, 500]"
                        :page-size="Number(queryForm.pageSize)" :total="Number(pageTotal)" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"></el-pagination>
                </div>
            </div>
        </el-card>

        <AdvertisingAdd ref="advertisingAdd" @submit="getSharePlatformList()"></AdvertisingAdd>
    </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AdvertisingAdd from "../../../components/AdvertisingComponents/AdvertisingAdd/index.vue"
import { crmUrl } from "@/utils/helper.js";
export default {
    components: {
        TableTittle,
        AdvertisingAdd
    },
    data() {
        return {
            queryForm: {
                pageIndex: 1,
                pageSize: 10,
                isPaging: 1,
                title: '',
                share_user_typeses: null
            },
            pageTotal: 0,
            tableData: [],
            rangeList: [
                {
                    label: '全部',
                    value: null
                },
                {
                    label: '发单人',
                    value: '1'
                },
                {
                    label: '接单人',
                    value: '2'
                },
                {
                    label: '游客',
                    value: '3'
                }

            ]
        }
    },
    created() {
        this.$store.dispatch("getStaffOptions");
        this.getSharePlatformList()
    },
    methods: {
        getSharePlatformList(flag) {
            if(flag){
                this.queryForm.pageIndex = 1
            }
            let str = crmUrl()
            this.$axios({
                url: `${str}/api/v1/sharePlatform/ad/list`,
                method: 'get',//可以省略 不写默认是get
                params: this.queryForm,
                headers: { // 设置请求头
                    Authorization: localStorage.getItem('admin_token')
                }
            }).then(res => {
                if (res.data.code == 0) {
                    this.pageTotal = res.data.data.count
                    this.tableData = res.data.data.list
                }
                // console.log("res", res)
            })

        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.getSharePlatformList();
        },
        handleCurrentChange(val) {
            this.queryForm.pageIndex = val;
            this.getSharePlatformList();
        },

        formateCreator(row) {
            if (row.manage_creator && this.$store.state.staffOptions.length) {
                let id = row.manage_creator
                return this.$store.state.staffOptions.find(v => {
                    return v.id == id
                }).nickname
            } else {
                return '无'
            }
        },
        handleAdd() {
            this.$refs.advertisingAdd.open()
        },
        handleAudit(row) {
            this.$refs.advertisingAdd.open(row)
        },
        handleDel(id) {
            this.$confirm('是否删除广告?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let str = crmUrl()
                this.$axios({
                    url: `${str}/api/v1/sharePlatform/ad`,
                    method: 'delete',//可以省略 不写默认是get
                    data: { id: id },
                    headers: { // 设置请求头
                        Authorization: localStorage.getItem('admin_token')
                    }
                }).then(res => {
                    if (res.data.code == 0) {
                        this.getSharePlatformList()
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        getUserType(type) {
            let typeName = ''
            type.split(',').forEach((item, index) => {
                if (item == 1) {
                    typeName += '发单人/'
                } else if (item == 2) {
                    typeName += '接单人/'
                } else if (item == 3) {
                    typeName += '游客/'
                }
            })

            return typeName.substring(0, typeName.length - 1)
        }
    }
}
</script>

<style lang="less" scoped></style>