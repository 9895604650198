import BasicSettings from "../views/Settings/BasicSettings/main.vue";
import RootDictionary from "../views/Settings/RootDictionary/main.vue";


export default [
    /*** 设  置 ***/
    {
        path: '/settings/basicsettings',
        component: BasicSettings,
        meta: { title: '基本设置' }
    }, {
        path: '/settings/rootdictionary',
        component: RootDictionary,
        meta: { title: '字典设置' }
    }
]