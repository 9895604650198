import { render, staticRenderFns } from "./RejectedTeacher.vue?vue&type=template&id=1f8f4ad5&scoped=true"
import script from "./RejectedTeacher.vue?vue&type=script&lang=js"
export * from "./RejectedTeacher.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f8f4ad5",
  null
  
)

export default component.exports