<template>
    <div class="content">
        <el-dialog top="5vh" title="选择院校" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="70%">
            <el-form class="search-wrap" ref="searchForm" :inline="true" :model="queryForm">
                <el-form-item label="院校名称" prop="keyword">
                    <el-input v-model="queryForm.keyword" placeholder="输入院校名称/代码" @input="getTableList(true)"
                        clearable></el-input>
                </el-form-item>
                <el-form-item label="地区" prop="province_code">
                    <el-select v-model="queryForm.province_code" placeholder="请选择" @change="getTableList(true)" clearable>
                        <el-option v-for="item in provinces" :key="item.code" :label="item.name" :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分类" prop="category_id">
                    <el-select v-model="queryForm.category_id" placeholder="请选择" @change="getTableList(true)" clearable>
                        <el-option v-for="item in categorys" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-select v-model="queryForm.status" placeholder="请选择" @change="getTableList(true)" clearable>
                        <el-option v-for="item in filter.status" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <!-- :row-key="selRowKey" -->
            <el-table class="table" :row-key="(row) => row.id" :data="tableList" border style="width: 100%"
                ref="multipleTable" v-loading="loading" @selection-change="handleSelectionChange">
                <!-- reserve-selection -->
                <el-table-column type="selection" width="50" align="center" :reserve-selection="true">
                </el-table-column>
                <el-table-column prop="id" label="ID" width="80" align="center">
                </el-table-column>
                <el-table-column prop="name" label="院校名称" min-width="160" align="center">
                </el-table-column>
                <el-table-column prop="code" label="院校代码" min-width="160" align="center">
                </el-table-column>
                <el-table-column prop="logo" label="标志" min-width="160" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.logo" style="max-height: 50px;display: block;margin: 0 auto;" />
                    </template>
                </el-table-column>
                <el-table-column prop="province_name" label="地区" min-width="160" align="center">
                </el-table-column>
                <el-table-column prop="category_ids" label="院校分类" min-width="160" align="center">
                    <template slot-scope="scope">
                        {{ renderCategoryName(scope.row.category_ids) }}
                    </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序值" width="120" align="center">
                </el-table-column>
                <el-table-column label="状态" width="160" align="center">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">{{ scope.row.status == 1 ? '正常' :
                            '下线' }}</el-tag>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="操作" width="180" align="center" fixed="right" v-if="isfixed">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text"
                            @click="handleToCollegeEdit(scope.row, scope.$index)">编辑</el-button>
                        <el-button size="mini" type="text"
                            @click="handleToAcademy(scope.row, scope.$index)">学院列表</el-button>
                        <el-button size="mini" type="text" @click="handleToMajor(scope.row, scope.$index)">专业列表</el-button>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column label="" width="40" align="center" fixed="right" v-else>
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                                ...
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="a" style="margin-bottom:6px;"><span
                                        @click="handleToCollegeEdit(scope.row, scope.$index)"
                                        style="font-size:16px;color: #409EFF;">
                                        编辑
                                    </span></el-dropdown-item>
                                <el-dropdown-item command="b" style="margin-bottom:6px;"><span
                                        @click="handleToAcademy(scope.row, scope.$index)"
                                        style="font-size:16px;color: #409EFF;">
                                        学院列表
                                    </span></el-dropdown-item>
                                <el-dropdown-item command="c" style="margin-bottom:6px;"><span
                                        @click="handleToMajor(scope.row, scope.$index)"
                                        style="font-size:16px;color: #409EFF;">
                                        专业列表
                                    </span></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column> -->
            </el-table>
            <div class="footer">
                <div>
                    <el-button size="small" @click="handleChose()">选择院校</el-button>
                </div>
                <el-pagination background layout="total, prev, pager, next, sizes, jumper"
                    :current-page="Number(queryForm.pageIndex)" :page-sizes="[10, 20, 50, 100, 500]"
                    :page-size="Number(queryForm.pageSize)" :total="Number(pageTotal)" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"></el-pagination>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import { adminUrl } from "@/utils/helper.js";
export default {
    data() {
        return {
            loading: false,
            queryForm: {
                pageIndex: 1,
                pageSize: 10,
                isPaging: 1
            },
            dialogFormVisible: false,
            categorys: [],
            tableList: [],
            pageTotal: 0,
            chooseList: [],
            filter: {
                status: [
                    { label: '全部', value: '' },
                    { label: '正常', value: '1' },
                    { label: '关闭', value: '0' }
                ],
            },
            provinces: [],
            flag:false,
            selfList:[]
        };
    },
    created() {
        this.getCategory()
        this.fetchProvinceList()
    },
    methods: {
        handleChose() {
            this.$emit('submit', this.chooseList)
            this.dialogFormVisible = false
        },
        open(list) {
            // console.log("list",list)
            this.flag = true
            this.selfList = list
            this.getTableList()
            this.dialogFormVisible = true


        },
        getTableList(flag) {
            this.loading = true
            if (flag) {
                this.queryForm.pageIndex = 1
            }
            let str = adminUrl()
            this.$axios({
                url: `${str}/api/v1/back/college/list`,
                method: 'get',//可以省略 不写默认是get
                params: this.queryForm
            }).then(res => {
                if (res.data.code == 0) {
                    this.loading = false
                    this.pageTotal = res.data.data.count
                    this.tableList = res.data.data.list
                    if(this.flag){
                        this.$refs.multipleTable.clearSelection()
                    }
                    this.flag = false
                    if (this.selfList.length > 0 && this.tableList.length > 0) {
                        this.selfList.forEach((its,ins) => {
                            this.tableList.forEach(item => {
                                if (its.id == item.id) {
                                    if(!its.flag){
                                        this.$refs.multipleTable.toggleRowSelection(its, true)
                                    }
                                    this.$set(this.selfList[ins],'flag',true)
                                }
                            })
                        });
                    }
                }

                // console.log("列表", res)

            })
        },
        getCategory() {
            let str = adminUrl()
            this.$axios({
                url: `${str}/api/v1/college/category/list`,
                method: 'get',//可以省略 不写默认是get
                params: { isPaging: 0 }
            }).then(res => {
                // console.log("Vv",res)
                let list = res.data.data.list
                list.unshift({ id: '', name: '全部' })
                this.categorys = list
            })
        },
        // 省份列表请求
        fetchProvinceList() {
            let str = adminUrl()
            this.$axios({
                url: `${str}/api/v1/back/province/list`,
                method: 'get',//可以省略 不写默认是get
                params: { isPaging: 0 }
            }).then(res => {

                let list = [{ code: '', name: '全部' }, ...res.data.data.list]
                this.provinces = list
            })
        },
        // 分类名称渲染
        renderCategoryName(ids) {
            if (!ids) {
                return ''
            }
            ids = ids.split(',')
            const list = this.categorys.filter(item => ids.includes(String(item.id)))
            if (list.length) {
                return list.map(v => v.name).join('、')
            }
            return ''
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;

            this.getTableList();
        },
        handleCurrentChange(val) {
            this.queryForm.pageIndex = val;
            this.getTableList();
        },



        handleSelectionChange(val) {
            this.chooseList = val
        },




    },
};
</script>
  
<style lang="less" scoped>
/deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
}

.footer {
    display: flex;
    justify-content: space-between;
}
</style>