<template>
  <div>
    <el-dialog
    append-to-body 
      top="5vh"
      title="班级管理"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="班级类型："
          prop="type"
        >
          <el-select
            v-model="dataForm.type"
            placeholder="请选择班级类型"
            clearable
          >
            <el-option
              label="一对一"
              :value="0"
            ></el-option>
            <el-option
              label="小班课"
              :value="1"
            ></el-option>
            <el-option
              label="大班课"
              :value="2"
            ></el-option>
            <el-option
              label="试听课"
              :value="3"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="考研年份："
          prop="apply_year"
        >
          <el-select
            v-model="dataForm.apply_year"
            placeholder="请选择考研年份"
            clearable
          >
            <el-option
              label="2022"
              value="2022"
            ></el-option>
            <el-option
              label="2023"
              value="2023"
            ></el-option>
            <el-option
              label="2024"
              value="2024"
            ></el-option>
            <el-option
              label="2025"
              value="2025"
            ></el-option>
            <el-option
              label="2026"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考信息："
          prop="apply_college"
        >
          <el-select
            v-model="dataForm.apply_college"
            placeholder="请选择院校"
            filterable
            clearable
            @change="collegeChange"
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="apply_major"
        >
          <el-select
            v-model="dataForm.apply_major"
            placeholder="请选择专业"
            @change="majorChange"
            clearable
            filterable
          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.allnames"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="apply_major"
        >
          <el-select
            v-model="dataForm.apply_academy"
            placeholder="请选择学院"
            clearable
            @change="academyChange"
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="{ value: item.academy_code, label: item.academy_name,directions:item.directions}"
            >
              <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="dataForm.apply_direction"
            placeholder="请选择考试方向"
            clearable
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in directionsOptions"
              :key="index"
              :label="item.direction"
              :value="{ value: item.direction_code, label: item.direction}"
            >
              <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="apply_subjects"
        >
          <el-select
            style="width:100%;"
            v-model="dataForm.apply_subjects"
            placeholder="请选择科目"
            clearable
            multiple
            @change="$forceUpdate()"
          >
            <el-option
              v-for="(item,index) in subjectOptions"
              :key="index"
              :label="item.subject_name"
              :value="{ value: item.subject_code, label: item.subject_name}"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>

            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="班级名称："
          prop="name"
        >
          <el-input
            v-model="dataForm.name"
            placeholder="请输入班级名称"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="辅导课时："
          prop="cocah_lesson_hour"
        >
          <el-input
            placeholder="请输入辅导课时"
            v-model="dataForm.cocah_lesson_hour"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          >
            <span slot="append">小时</span>

          </el-input>
        </el-form-item>

       <el-form-item
          label="复习基础："
          prop="coach_need"
        >
          <el-input
            type="textarea"
            v-model="dataForm.coach_need"
            placeholder="请输入复习基础"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="参考书目"
          prop="refer_book"
        >
          <el-input
            type="textarea"
            v-model="dataForm.refer_book"
            placeholder="请输入参考书目"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="课程备注："
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="dataForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="学管师："
          prop="learn_manager"
        >
          <el-select
            v-model="dataForm.learn_manager"
            placeholder="请选择学管师"
            clearable
          >
            <el-option
              v-for="(item,index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  academylist,
  majorlist,
  gradeEdit,
  gradeOne,
  subjectlist,
  commonHandleLogCreate,
  gradeSyncEdit
} from "@/request/api";
import { formateGradeInfo } from "@/utils/helper";
import { adminUrl } from "@/utils/helper";


export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
        cocah_lesson_hour: 1
      },
      rules: {
        name: [{ required: true, message: "请输入班级名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择班级类型", trigger: "blur" }],
        cocah_lesson_hour: [{ required: true, message: "请选择辅导课时", trigger: "blur" }, { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }],
        // learn_manager: [{ required: true, message: "请选择学管师", trigger: "blur" }],
        apply_year: [{ required: true, message: "请选择考研年份", trigger: "blur" }],
        apply_college: [{ required: true, message: "请选择院校", trigger: "blur" }],
        apply_major: [{ required: true, message: "请选择专业", trigger: "blur" }],
        apply_academy: [{ required: true, message: "请选择学院", trigger: "blur" }],
        apply_subjects: [{ required: true, message: "请选择科目", trigger: "blur" }],

      },
      majorOptions: [],// 专业数组
      academyOptions: [],// 学院数组
      subjectOptions: [],//科目数组
      origin_data: {},
      directionsOptions: [],

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      gradeOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data
        // this.$set(this.dataForm, 'learn_manager', res.data.learn_manager.id)
         delete this.dataForm.learn_manager
        if (this.dataForm.apply_college_name && this.dataForm.apply_college_code) {
          this.$set(this.dataForm, 'apply_college', {
            label: this.dataForm.apply_college_name,
            value: this.dataForm.apply_college_code,
          })
          this.getMajor(this.dataForm.apply_college_code)
        }
        if (this.dataForm.apply_major_name && this.dataForm.apply_major_code) {
          this.$set(this.dataForm, 'apply_major', {
            label: this.dataForm.apply_major_name,
            value: this.dataForm.apply_major_code,
          })
          this.getMajorInfo(this.dataForm.apply_college_code, this.dataForm.apply_major_code)

        }
        if (this.dataForm.apply_academy_name && this.dataForm.apply_academy_code) {
          this.$set(this.dataForm, 'apply_academy', {
            label: this.dataForm.apply_academy_name,
            value: this.dataForm.apply_academy_code,
          })
          this.getSubjects()
        }
        if (this.dataForm.apply_subjects) {
          this.$set(this.dataForm, 'apply_subjects', this.dataForm.apply_subjects.map(v => {
            return {
              value: v.subject_code,
              label: v.subject_name,
            }
          }))
        }
        if (res.data.apply_direction_code && res.data.apply_direction_name) {
          this.$set(this.dataForm, 'apply_direction', {
            label: res.data.apply_direction_name,
            value: res.data.apply_direction_code,
          })
        }
        this.origin_data = Object.assign({}, this.dataForm)

        this.$forceUpdate()

      });
    },
    emitClose() {
      this.$emit("close");
    },
    collegeChange(e) {
      this.dataForm.apply_major = {}
      this.majorOptions = []
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      if (e) {
        this.getMajor(e.value);
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map(v => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`
          }
        })
      });
    },
    majorChange(e) {
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      if (e) {
        this.getMajorInfo(this.dataForm.apply_college.value, e.value);

      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get',//可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then(response => {
        let res = response.data
        this.academyOptions = res.data.academys
        // if(this.academyOptions.length==1){
        //   this.dataForm.apply_academy = {
        //     value: this.academyOptions[0].academy_code,
        //     label:this.academyOptions[0].academy_name,
        //     directions:this.academyOptions[0].directions
        //   }
        //   this.academyChange( this.dataForm.apply_academy)
        // }


        this.directionsOptions = this.academyOptions.find(v => {
          return this.dataForm.apply_academy.value == v.academy_code
        }).directions

      })
    },
    getAcedemy(college_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        major_code: major_code,
      };
      academylist(query).then((response) => {
        let res = response.data
        this.academyOptions = res.data.list || [];
      });
    },
    academyChange(e) {
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      this.$forceUpdate()
      if (e) {
        this.directionsOptions = this.academyOptions.find(v => {
          return this.dataForm.apply_academy.value == v.academy_code
        }).directions
        this.getSubjects();
      }
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.dataForm.apply_college.value,
        academy_code: this.dataForm.apply_academy.value,
        major_code: this.dataForm.apply_major.value,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程"
        }
        this.subjectOptions = res.data.data.list || [];
        this.subjectOptions.push(obj)
      });
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            cid: localStorage.getItem("cid"),
            apply_college_code: this.dataForm.apply_college ? this.dataForm.apply_college.value : "",
            apply_college_name: this.dataForm.apply_college ? this.dataForm.apply_college.label : "",
            apply_major_code: this.dataForm.apply_major ? this.dataForm.apply_major.value : "",
            apply_major_name: this.dataForm.apply_major ? this.dataForm.apply_major.label : "",
            apply_academy_code: this.dataForm.apply_academy ? this.dataForm.apply_academy.value : "",
            apply_academy_name: this.dataForm.apply_academy ? this.dataForm.apply_academy.label : "",
            apply_direction_code: this.dataForm.apply_direction ? this.dataForm.apply_direction.value : "",
            apply_direction_name: this.dataForm.apply_direction ? this.dataForm.apply_direction.label : "",
            apply_year: this.dataForm.apply_year,
            name: this.dataForm.name,
            type: this.dataForm.type,
            cocah_lesson_hour: this.dataForm.cocah_lesson_hour,
            learn_manager: this.dataForm.learn_manager,
            apply_subjects: this.dataForm.apply_subjects.map(v => {
              return {
                subject_code: v.value,
                subject_name: v.label,
              }
            }),
            coach_need:this.dataForm.coach_need,
            refer_book:this.dataForm.refer_book,
            remark: this.dataForm.remark,

          }
          gradeEdit(query).then((res) => {
            if (res.data.code == 0) {
              this.createLog()
              /*      this.emitClose()
                   this.$emit('submit') */
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    formateType(type) {
      let types = {
        '0': '一对一',
        '1': '小班课',
        '2': '大班课',
        '3': '试听课',
      }
      return types[type]
    },
    createLog() {
      let arr = []
      for (let k in this.origin_data) {
        if (this.origin_data[k] != this.dataForm[k]) {
          arr.push({
            val: k,
            old: this.origin_data[k],
            new: this.dataForm[k],
          })
        }
      }
      let final = arr.map(v => {
        if (v.val == 'type') {
          return `将班级类型由“${this.formateType(v.old)}”变更为“${this.formateType(v.new)}”`
        } else if (v.val == 'apply_college') {
          return `将报考院校由“${v.old.label}”变更为“${v.new.label}”`
        } else if (v.val == 'apply_year') {
          return `将考研年份由“${v.old ? v.old + '年' : '无'}”变更为“${v.new}年”`
        } else if (v.val == 'apply_major') {
          return `将报考专业由“${v.old.label}”变更为“${v.new.label}”`
        } else if (v.val == 'apply_academy') {
          return `将报考学院由“${`（${v.old.value}）${v.old.label}`}”变更为“${`（${v.new.value}）${v.new.label}`}”`
        } else if (v.val == 'apply_subjects') {
          return `将报考科目由“${v.old && v.old.length ? v.old.map(t => { return `（${t.value}）${t.label}` }).join(',') : '‘’'}”变更为“${v.new.length ? v.new.map(t => {
            return `（${t.value}）${t.label}`
          }).join(',') : '‘’'}”`
        } else if (v.val == 'name') {
          return `将班级名称由“${v.old}”变更为“${v.new}”`
        } else if (v.val == 'remark') {
          return `将备注由“${v.old}”变更为“${v.new}”`
        } else if (v.val == 'cocah_lesson_hour') {
          return `将辅导课时由“${Number(v.old)}小时”变更为“${Number(v.new)}小时”`
        } else if (v.val == 'learn_manager') {
          return `将学管师由“${this.$store.state.staffOptions.find(t => {
            return t.id == v.old
          }).nickname}”变更为“${this.$store.state.staffOptions.find(t => {
            return t.id == v.new
          }).nickname}”`
        }
      })
      let query = {
        ref_id: this.dataForm.id,
        handle_type: 0,
        handle_child_type: 1,
        handle_content: final.join('<br/>'),
      }
      commonHandleLogCreate(query).then((res) => {
        if (res.data.code == 0) {
          this.sync()
        }
      });
    },
    sync() {
      gradeOne({ id: this.id }).then((response) => {
        let res = response.data
        if (res.data.old_id) {
          gradeSyncEdit(formateGradeInfo(res.data, 'edit')).then((res) => {
            if (res.data.data.message == "ok") {
              this.emitClose()
              this.$emit('submit')
            } else {
              this.$message.error(res.data.data.message)
            }
          });
        } else {
          this.emitClose()
          this.$emit('submit')
        }

      })

    },

  },
};
</script>

<style lang="less" scoped>
</style>