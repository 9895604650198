<template>
  <div>
    <el-dialog
top="5vh"
      title="老师信息"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="440px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="老师姓名："
          prop="name"
        >
          <span class="item-value">{{dataForm.realname}}  </span>
        </el-form-item>
        <el-form-item
          label="性别："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.gender==1?'男':'女'}}
          </span>
        </el-form-item>
        <el-form-item
          label="考研年份："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.recruit_year}}年
          </span>
        </el-form-item>
        <el-form-item
          label="录取信息："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.recruit_college_name}}/{{dataForm.recruit_major_name}}
          </span>
        </el-form-item>
        <el-form-item
          label="综合评分："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.teacher_composite_score}}分
          </span>
        </el-form-item>
        <el-form-item
          label="备注："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.manage_remark?dataForm.manage_remark:'无'}}
          </span>
        </el-form-item>
        <el-form-item
          label="创建人："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.manage_creator?dataForm.manage_creator.nickname:'无'}}
          </span>
        </el-form-item>
        <el-form-item
          label="创建时间："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.create_time?$moment(dataForm.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </span>
        </el-form-item>
        <el-form-item
          label="课时费："
          prop="lesson_hour_amount"
        >
          <span class="item-value">
            {{dataForm.teacher_composite_lesson_amount?dataForm.teacher_composite_lesson_amount:0}}元/小时
          </span>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose()">取消</el-button>
        <el-button
          type="primary"
          @click="emitClose()"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  teacherOne
} from "@/request/api";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    fetchDetail() {
      teacherOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data
      });
    },
  },
};
</script>

<style lang="less" scoped>
.item-value {
  font-size: 14px;
  color: #303133;
}
.el-form-item {
  margin-bottom: 8px !important;
}
</style>