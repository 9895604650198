import axios from 'axios'
import host from './config'
import { Message } from "element-ui";
import { months, monthsShort } from 'moment/moment';

// const product = `${window.location.protocol}//manage-api.yanshizi.com`
// const daily = `${window.location.protocol}//manage-api.kaoyanbox.net`
// var logDebug = true;
// let host = daily
// // 环境判断
// if (location) {
//     if (location.host.includes('manage.yanshizi.com')) {
//         host = product
//         logDebug = false
//     } else if (location.host.includes('manage.kaoyanbox.net')) {
//         logDebug = false
//         host = daily
//         if (location.host.includes('test')) {
//             host = daily
//         }
//     }
// }

// if (location) {
//     if (location.host.includes('manage.yanshizi.com')) {
//         host = product
//     } else if (location.host.includes('manage.kaoyanbox.net')) {
//         host = product
//         if (location.host.includes('test')) {
//             host = product
//         }
//     }
// }



export function imgUploadUrl() {
    return `${host}/api/v1/upload/image`
}

export function fileUploadUrl() {
    return `${host}/api/v1/upload/file`
}

axios.defaults.baseURL = host
axios.defaults.timeout = 20000
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'



// // 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = localStorage.getItem('token')
        token && (config.headers.Authorization = token)
        return config
    },
    error => {
        return Promise.error(error)
    })

//axios的拦截--response
axios.interceptors.response.use(response => {
    // TODO:具体的code对应的处理可继续添加修改
    if (response.data.code === 0) {
        console.log('请求成功')
    } else {
        Message({
            message: response.data.error || '服务器异常',
            type: 'error',
            duration: 2000
        })
    }
    if (response.data.code === 401) {
        location.href = '/login'
    }
    return response;
}, err => {
    Message({
        message: err.data.msg || '服务器异常',
        type: 'error',
        duration: 2000
    })
    return Promise.reject(err);
})
export default axios //导出封装后的axios