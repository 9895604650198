import AccountManagement from "../views/Mechanism/AccountManagement/main.vue";
import MechanismManagement from "../views/Mechanism/MechanismManagement/main.vue";
import Apply from "../views/Mechanism/Apply/main.vue";
import Group from "../views/Mechanism/Group/main.vue";
import OrderPrice from "../views/Mechanism/OrderPrice/index.vue";



import InstitutionalDepartment from "../views/Management/InstitutionalDepartment/main.vue";
import InstitutionalRole from "../views/Management/InstitutionalRole/main.vue";
import InstitutionalAuthority from "../views/Management/InstitutionalAuthority/main.vue";
import InstitutionalDictionary from "../views/Management/InstitutionalDictionary/main.vue";



export default [
    /*** 机 构 ***/
    {
        path: '/mechanism/mechanismmanagement',
        component: MechanismManagement,
        meta: { title: '机构管理' }
    },
    {
        path: '/mechanism/accountmanagement',
        component: AccountManagement,
        meta: { title: '账号管理' }
    },
    /*** 管  理(原先CRM管理模块移到机构模块，但是文件路径没有移动，注意！！！！) ***/
    {
        path: '/mechanism/institutionaldepartment',
        component: InstitutionalDepartment,
        meta: { title: '机构部门' }
    }, {
        path: '/mechanism/institutionalrole',
        component: InstitutionalRole,
        meta: { title: '机构角色' }
    }, {
        path: '/mechanism/institutionalauthority',
        component: InstitutionalAuthority,
        meta: { title: '机构权限' }
    }, {
        path: '/mechanism/institutionaldictionary',
        component: InstitutionalDictionary,
        meta: { title: '机构字典' }
    },
    {
        path: '/mechanism/apply',
        component: Apply,
        meta: { title: '机构申请' }
    },
    {
        path: '/mechanism/group',
        component: Group,
        meta: { title: '机构申请' }
    },
    {
        path: '/mechanism/orderprice',
        component: OrderPrice,
        meta: { title: '机构订单价格' }
    },
]