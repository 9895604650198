<template>
  <div>
    <el-dialog
    append-to-body 
      top="5vh"
      title="新建老师"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="657px"
    >
      <div class="title">
        {{ titleTxt }}
      </div>
      <el-alert
        title="如有不确定的信息项请勾选右侧方框"
        type="error"
        show-icon
        style="background-color:#ECF2FE;margin-bottom: 12px;"
      >
      </el-alert>
      <el-form
        :model="dataForm"
        label-position="left"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <div
          class="module"
          v-show="step==1"
        >
          <el-form-item
            label="姓名："
            prop="realname"
          >
            <el-input
              v-model="dataForm.realname"
              placeholder="请输入姓名"
              style="width: 90%;"
            ></el-input>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'realname')}"></el-checkbox>
          </el-form-item>
          <el-form-item
            label="考研年份："
            prop="recruit_year"
          >
            <el-select
              v-model="dataForm.recruit_year"
              placeholder="请选择考研年份"
              clearable
              style="width: 90%;"
            >
              <el-option
                label="2020年"
                :value="2020"
              >
              </el-option>
              <el-option
                label="2021年"
                :value="2021"
              >
              </el-option>
              <el-option
                label="2022年"
                :value="2022"
              >
              </el-option>
              <el-option
                label="2023年"
                :value="2023"
              >
              </el-option>
              <el-option
                label="2024年"
                :value="2024"
              >
              </el-option>
              <el-option
                label="2025年"
                :value="2025"
              >
              </el-option>
            </el-select>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'recruit_year')}"></el-checkbox>
          </el-form-item>
          <el-form-item
            label="录取院校："
            prop="recruit_college"
          >
            <el-select
              v-model="dataForm.recruit_college"
              placeholder="请选择录取院校"
              filterable
              clearable
              @change="collegeChange"
              style="width: 90%;"
            >
              <el-option
                v-for="(item,index) in $store.state.collegeList"
                :key="index"
                :label="item.name"
                :value="{ value: item.code, label: item.name}"
              >
              </el-option>
            </el-select>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'recruit_college_code','recruit_college_name')}"></el-checkbox>
          </el-form-item>
          <el-form-item
            label="录取专业："
            prop="recruit_major"
          >
            <el-select
              v-model="dataForm.recruit_major"
              placeholder="请选择专业"
              @change="majorChange"
              clearable
              filterable
              style="width: 90%;"
            >
              <el-option
                v-for="(item,index) in majorOptions"
                :key="index"
                :label="item.allnames"
                :value="{ value: item.code, label: item.name}"
              >
              </el-option>
            </el-select>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'recruit_major_code','recruit_major_name')}"></el-checkbox>

          </el-form-item>
          <el-form-item
            label="录取学院："
            prop="recruit_major"
          >
            <el-select
              v-model="dataForm.recruit_academy"
              placeholder="请选择录取学院"
              clearable
              @change="academyChange"
              style="width: 90%;"
            >
              <el-option
                v-for="(item,index) in academyOptions"
                :key="index"
                :label="item.academy_name"
                :value="{ value: item.academy_code, label: item.academy_name,directions:item.directions}"
              >
                <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
              </el-option>
            </el-select>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'recruit_academy_code','recruit_academy_name')}"></el-checkbox>

          </el-form-item>
          <el-form-item
            label="研究方向："
            prop="recruit_major"
          >
            <el-select
              v-model="dataForm.recruit_direction"
              placeholder="请选择研究方向"
              clearable
              style="width: 90%;"
            >
              <el-option
                v-for="(item,index) in directionsOptions"
                :key="index"
                :label="item.direction"
                :value="{ value: item.direction_code, label: item.direction}"
              >
                <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
              </el-option>
            </el-select>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'recruit_direction_code','recruit_direction_name')}"></el-checkbox>
          </el-form-item>
          <el-form-item
            label="初试科目："
            prop="test_subjects"
          >
            <el-select
              style="width:90%;"
              v-model="dataForm.test_subjects"
              placeholder="请选择初试科目"
              clearable
              multiple
              @change="$forceUpdate()"
            >
              <el-option
                v-for="(item,index) in subjectOptions"
                :key="index"
                :label="item.subject_name"
                :value="{ value: item.subject_code, label: item.subject_name,score:0}"
              >
                <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
              </el-option>
            </el-select>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'test_subject_codes','test_subject_names')}"></el-checkbox>
          </el-form-item>
          <el-form-item
            label="科目分数："
            prop="test_subjects"
          >
            <div style="display: flex;">
              <div v-if="dataForm.test_subjects&&dataForm.test_subjects.length">
                <el-input
                  v-for="(item,index) in dataForm.test_subjects"
                  :key="index"
                  v-model="item.score"
                  placeholder="请输入分数"
                  style="width:465px;margin-bottom: 12px;"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @input="computedScore"
                >
                  <template slot="prepend">
                    {{ item.label }}
                  </template>
                  <template slot="append">分</template>
                </el-input>
                <el-checkbox @change="(...event) =>{handleToParams(event, 'test_subject_scores')}"></el-checkbox>

              </div>
              <div v-else>请选择初试科目</div>
            </div>

          </el-form-item>
          <el-form-item
            label="初试总分："
            prop="initial_test_score"
          >
            <el-input
              v-model="dataForm.initial_test_score"
              placeholder="请输入初试总分"
              style="width: 465px"
              readonly
            >
              <template slot="append">分</template>
            </el-input>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'initial_test_score')}"></el-checkbox>

          </el-form-item>
          <el-form-item
            label="录取类型："
            prop="recruit_type"
          >
            <el-radio-group v-model="dataForm.recruit_type">
              <el-radio :label="1">一志愿</el-radio>
              <el-radio :label="2">调剂</el-radio>
            </el-radio-group>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'recruit_type')}"></el-checkbox>

          </el-form-item>
          <el-form-item
            label="复试科目："
            prop="second_test_subject_names"
          >
            <el-input
              v-model="dataForm.second_test_subject_names"
              placeholder="请输入复试科目"
              style="width: 90%;"
            ></el-input>

            <el-checkbox @change="(...event) =>{handleToParams(event,'second_test_subject_names')}"></el-checkbox>
          </el-form-item>
          <el-form-item
            label="参考书目："
            prop="reference_book"
          >
            <el-select
              style="width:90%;"
              v-model="dataForm.reference_book"
              placeholder="请选择参考书目"
              clearable
              multiple
              ref="select"
              @change="reference_bookChange"
              @visible-change="(v) => visibleChange(v, 'select', selectClick,'去添加')"
            >
              <el-option
                v-for="(item,index) in booksOptions"
                :key="index"
                :label="item.label"
                :value="{ value: item.value, label: item.label}"
              >
                <span>{{ `${item.label}${item.value}` }}</span>
              </el-option>

            </el-select>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'reference_book_names', 'reference_book_extra_infos')}"></el-checkbox>

          </el-form-item>
        </div>
        <div
          class="module"
          v-show="step==2"
        >
          <el-form-item
            label="性别："
            prop="gender"
          >
            <el-radio-group v-model="dataForm.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
              <el-radio :label="0">未知</el-radio>
            </el-radio-group>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'gender')}"></el-checkbox>
          </el-form-item>
          <el-form-item
            label="手机号："
            prop="mobile"
          >
            <el-input
              v-model="dataForm.mobile"
              placeholder="请输入手机号"
              style="width:90%;"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            >
            </el-input>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'mobile')}"></el-checkbox>
          </el-form-item>
          <el-form-item
            label="微信号："
            prop="name"
          >
            <el-input
              v-model="dataForm.wechat"
              placeholder="请输入微信号"
              style="width:90%;"
            >
            </el-input>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'wechat')}"></el-checkbox>
          </el-form-item>
          <!--    <el-form-item
              label="QQ号："
              prop="name"
            >
              <el-input
                v-model="dataForm.qq"
                placeholder="请输入QQ号"
                style="width:90%;"
              >
              </el-input>
            </el-form-item> -->
          <el-form-item
            label="个人优势："
            prop="additional"
          >
            <el-input
              type="textarea"
              v-model="dataForm.additional"
              placeholder="请输入个人优势"
              style="width: 90%"
            >
            </el-input>
            <el-checkbox @change="(...event) =>{handleToParams(event, 'additional')}"></el-checkbox>

          </el-form-item>
          <el-form-item
            label="录取证明："
            prop="recruit_certify_img"
          >
            <div style="display: flex;">
              <el-upload
                class="avatar-uploader"
                :action="imgUploadUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
              >
                <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  class="avatar"
                >
                <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                ></i>
              </el-upload>
              <el-checkbox @change="(...event) =>{handleToParams(event, 'recruit_certify_img')}"></el-checkbox>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div class="footer">
        <el-button
          v-if="step==2"
          @click="stepChange(-1)"
        >上一步</el-button>
        <el-button
          v-if="step==1"
          @click="handleToClose()"
        >取消</el-button>
        <el-button
          type="primary"
          v-if="step==1"
          @click="stepChange(1)"
        >下一步</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
          slot=""
          v-if="step==2"
        >确定</el-button>
      </div>
    </el-dialog>
    <AddBooks
      :isVisible="addBooksDialog"
      @close="addBooksDialog=false"
      @submit="AddBooksSubmit"
    ></AddBooks>
  </div>
</template>
    
   <script>



import {
  academylist,
  majorlist,
  subjectlist,
  teacherCreate
} from "@/request/api";
import { adminUrl } from "@/utils/helper";
import { imgUploadUrl } from "@/request/http";
import AddBooks from '@/components/DialogComponents/TeacherResources/AddBooks.vue'




export default {
  components: {
    AddBooks
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {

      },
      rules: {
        realname: [{ required: true, message: "请输入名称", trigger: "blur" }],
        recruit_year: [{ required: true, message: "请选择考研年份", trigger: "blur" }],

        recruit_college: [{ required: true, message: "请选择录取院校", trigger: "blur" }],
        recruit_major: [{ required: true, message: "请选择录取专业", trigger: "blur" }],
        recruit_academy: [{ required: true, message: "请选择录取学院", trigger: "blur" }],
        recruit_direction: [{ required: true, message: "请选择研究方向", trigger: "blur" }],
        test_subjects: [{ required: true, message: "请选择初试科目", trigger: "blur" }],
        initial_test_score: [{ required: true, message: "请输入初试科目分数", trigger: "blur" }],
        recruit_type: [{ required: true, message: "请选择录取类型", trigger: "blur" }],
        /*    reference_book: [{ required: true, message: "请选择参考书目", trigger: "blur" }], */
        /*   second_test_subject_names: [{ required: true, message: "请输入复试科目", trigger: "blur" }], */
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }, { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号' }],
        /*      additional: [{ required: true, message: "请输入个人优势", trigger: "blur" }], */
        /*    recruit_certify_img: [{
             required: true,
             validator: (rule, value, callback) => {
               if (!this.imageUrl) {
                 callback(new Error('请上传录取证明'))
               } else {
                 callback()
               }
             },
           }], */
      },
      majorOptions: [],// 专业数组
      academyOptions: [],// 学院数组
      subjectOptions: [],//科目数组
      directionsOptions: [],//方向数组
      titleTxt: '报考信息',
      step: 1,
      imageUrl: '',
      imgUploadUrl: imgUploadUrl(),
      booksOptions: [],
      addBooksDialog: false,
      unconfirm_fields: [],
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;

    },
  },
  methods: {
    handleToClose() {
      this.$emit("close");
    },
    collegeChange(e) {
      this.dataForm.recruit_major = {}
      this.majorOptions = []
      this.dataForm.recruit_academy = {}
      this.academyOptions = []
      this.dataForm.test_subjects = []
      this.subjectOptions = []
      if (e) {
        this.getMajor(e.value);
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map(v => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`
          }
        })
      });
    },
    majorChange(e) {
      this.dataForm.recruit_academy = {}
      this.academyOptions = []
      this.dataForm.test_subjects = []
      this.subjectOptions = []
      this.dataForm.directions = []
      if (e) {
        this.getMajorInfo(this.dataForm.recruit_college.value, e.value);
        this.getBooks(this.dataForm.recruit_college.value, e.value)
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get',//可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then(response => {
        let res = response.data
        this.academyOptions = res.data.academys

         if(this.academyOptions.length==1){
          this.dataForm.recruit_academy = {
            value: this.academyOptions[0].academy_code,
            label:this.academyOptions[0].academy_name,
            directions:this.academyOptions[0].directions
          }
          this.academyChange( this.dataForm.recruit_academy)
        }
      
        this.directionsOptions = this.academyOptions.find(v => {
          return this.dataForm.recruit_academy_code == v.academy_code
        }).directions

       

      })
    },
    getBooks(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/college/core/academyMajor/list`,
        method: 'get',//可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then(response => {
        let res = response.data
        if (res.data.list[0].reference_books && res.data.list[0].reference_books != 'null') {
          let books = res.data.list[0].reference_books.split('\n').filter(v => {
            return v && v.split('')[0] != '('
          })
          this.booksOptions = books.map(v => {
            return {
              label: v.split('》，')[0].substring(3, v.split('》，')[0].length),
              value: v.split('》，')[1],
            }
          })
          console.log(this.booksOptions);
        }
      })
    },
    academyChange(e) {
      this.dataForm.test_subjects = []
      this.subjectOptions = []
      this.$forceUpdate()
      if (e) {
        this.directionsOptions = e.directions
        this.getSubjects();
      }
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.dataForm.recruit_college.value,
        academy_code: this.dataForm.recruit_academy.value,
        major_code: this.dataForm.recruit_major.value,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程"
        }
        this.subjectOptions = res.data.data.list || [];
        this.subjectOptions.push(obj)
      });
    },
    submit(rules) {
      if (!this.dataForm.realname) {
        this.$message.error('请输入名称')
        return
      }
      if (!this.dataForm.recruit_year) {
        this.$message.error('请选择考研年份')
        return
      }
      if (!this.dataForm.recruit_college) {
        this.$message.error('请选择录取院校')
        return
      }
      if (!this.dataForm.recruit_major) {
        this.$message.error('请选择录取专业')
        return
      }
      if (!this.dataForm.recruit_academy) {
        this.$message.error('请选择录取学院')
        return
      }
      if (!this.dataForm.recruit_direction) {
        this.$message.error('请选择研究方向')
        return
      }
      if (!this.dataForm.test_subjects) {
        this.$message.error('请选择初试科目')
        return
      }
      if (!this.dataForm.initial_test_score) {
        this.$message.error('请输入初试科目分数')
        return
      }
      if (!this.dataForm.recruit_type) {
        this.$message.error('请选择录取类型')
        return
      }
      if (!this.dataForm.gender) {
        this.$message.error('请选择性别')
        return
      }
      if (!this.dataForm.mobile) {
        this.$message.error('请选择手机号')
        return
      }
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let data = {
            id: this.dataForm.id,
            realname: this.dataForm.realname,
            recruit_year: this.dataForm.recruit_year,
            confirm_status: 1,
            initial_test_score: this.dataForm.initial_test_score,
            gender: this.dataForm.gender,
            recruit_college_code: this.dataForm.recruit_college.value,
            recruit_college_name: this.dataForm.recruit_college.label,
            recruit_major_code: this.dataForm.recruit_major.value,
            recruit_major_name: this.dataForm.recruit_major.label,
            recruit_academy_code: this.dataForm.recruit_academy.value,
            recruit_academy_name: this.dataForm.recruit_academy.label,
            recruit_direction_code: this.dataForm.recruit_direction.value,
            recruit_direction_name: this.dataForm.recruit_direction.label,
            test_subject_names: this.dataForm.test_subjects && this.dataForm.test_subjects.length ? this.dataForm.test_subjects.map((v => {
              return v.label
            })).join(',') : '',
            test_subject_codes: this.dataForm.test_subjects && this.dataForm.test_subjects.length ? this.dataForm.test_subjects.map((v => {
              return v.value
            })).join(',') : '',
            test_subject_scores: this.dataForm.test_subjects && this.dataForm.test_subjects.length ? this.dataForm.test_subjects.map((v => {
              return v.score
            })).join(',') : '',
            recruit_type: this.dataForm.recruit_type,
            second_test_subject_names: this.dataForm.second_test_subject_names,
            mobile: this.dataForm.mobile,
            wechat: this.dataForm.wechat,
            additional: this.dataForm.additional,
            recruit_certify_img: this.imageUrl,
            reference_book_extra_infos: this.dataForm.reference_book && this.dataForm.reference_book.length ? this.dataForm.reference_book.map((v => {
              return v.value
            })).join(',') : '',
            reference_book_names: this.dataForm.reference_book && this.dataForm.reference_book.length ? this.dataForm.reference_book.map((v => {
              return v.label
            })).join(',') : '',
            unconfirm_fields: this.unconfirm_fields.filter(v => {
              return v
            }).join(','),
          }
          teacherCreate(data).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    stepChange(step) {
      this.step += step
      this.titleTxt = this.step == 1 ? '报考信息' : '个人信息'
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    computedScore() {
      if (this.dataForm.test_subjects && this.dataForm.test_subjects.length) {
        let score = this.dataForm.test_subjects.reduce((acc, cur) => {
          return acc + Number(cur.score)
        }, 0)
        this.$set(this.dataForm, 'initial_test_score', score)
      }
    },
    reference_bookChange(e) {
      console.log(e);
    },
    /**
     * 为element-ui的Select和Cascader添加弹层底部操作按钮
     * @param visible
     * @param refName  设定的ref名称
     * @param onClick  底部操作按钮点击监听
     * @param addname  底部按钮名称
     */
    visibleChange(visible, refName, onClick, addname) {
      if (visible) {
        const ref = this.$refs[refName]
        let popper = ref.$refs.popper
        if (popper.$el) popper = popper.$el
        if (!Array.from(popper.children).some(v => v.className === 'el-cascader-menu__ist')) {
          const el = document.createElement('ul')
          el.className = 'el-cascader-menu__list'
          el.style = 'border-top: solid 1px #E4E7ED; padding:0; color: #606266;'
          el.innerHTML = `<li class="el-cascader-node text-center" style="height:50px;line-height: 50px;">
                    <span class="el-cascader-node__label" style="color:#409EFF;"><i class="font-blue el-icon-plus"></i>` + addname + `</span>
                    </li>`
          popper.appendChild(el)
          el.onclick = () => {
            // 底部按钮的点击事件 点击后想触发的逻辑也可以直接写在这
            onClick && onClick()
            // 以下代码实现点击后弹层隐藏 不需要可以删掉
            if (ref.toggleDropDownVisible) {
              ref.toggleDropDownVisible(false)
            } else {
              ref.visible = false
            }
          }
        }
      }
    },
    /**
  * 点击添加品牌
  */
    selectClick() {
      this.addBooksDialog = true
    },
    AddBooksSubmit({ data }) {
      this.booksOptions.push(data)
    },
    handleToParams(e, name, othername = '') {
      if (e[0] == true) {
        if (!this.unconfirm_fields.includes(name)) {
          this.unconfirm_fields.push(name)
          this.unconfirm_fields.push(othername)
        } else {
          this.unconfirm_fields = this.unconfirm_fields.filter(v => {
            return v && v != name
          })
          this.unconfirm_fields = this.unconfirm_fields.filter(v => {
            return v && v != othername
          })
        }
      } else {
        this.unconfirm_fields = this.unconfirm_fields.filter(v => {
          return v && v != name
        })
        this.unconfirm_fields = this.unconfirm_fields.filter(v => {
          return v && v != othername
        })
      }
    },
    changeStatus() {
      this.$confirm("确定要改为未录取吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        teacherCreate({
          confirm_status: -1,
          id: this.dataForm.id,
        }).then((response) => {
          let res = response.data
          if (res.code == 0) {
            this.$message.success('操作成功')
            this.$emit('submit')
            this.handleToClose()
          }
        });
      })
    }
  },
};
    </script>
    
    <style lang="less" scoped>
.el-form-item {
}
.el-checkbox {
  margin-left: 16px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  color: #303133;
  margin-bottom: 12px;
}
.module {
}
/deep/.el-alert__icon {
  color: #266fe8;
}
/deep/.el-alert__title {
  color: #333;
}
/deep/.el-input-group__prepend {
  min-width: 110px;
}
.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  text-align: center;
}
.avatar {
  width: 140px;
  height: 140px;
}
</style>