<template>
    <div class="content">
        <el-dialog top="5vh" :title="title" :visible.sync="dialogFormVisible" @close="dialogFormVisible = false"
            :close-on-click-modal="false" width="620px">
            <div v-show="step == 1">
                <el-form :model="dataForm" label-position="right" ref="dataForm" :rules="rules" label-width="135px"
                    size="small">
                    <el-form-item label="此次创建多少节课" prop="knob">
                        <el-input min="0" v-model.number="dataForm.knob" placeholder="输入此次创建多少节课" clearable type="number"
                            >
                            <!-- class="inpBorder" -->
                            <template slot="append">
                                <div style="color: #606266;"> 节</div>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="课程阶段" prop="course_stage">
                        <el-select v-model="dataForm.course_stage" placeholder="选择课程阶段" clearable style="width: 100%;">
                            <el-option v-for="(item, index) in courseStageList" :key="index" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始上课时间" prop="plan_start_time">
                        <el-date-picker :picker-options="pickerOptions" default-time="19:00:00" value-format="timestamp" v-model="dataForm.plan_start_time" type="datetime"
                            format="yyyy-MM-dd HH:mm" style="width: 100%;" placeholder="选择开始上课时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="上课周期" prop="period">
                        <el-select v-model="dataForm.period" placeholder="选择上课周期" clearable style="width: 100%;">
                            <el-option v-for="(item, index) in periodColumns" :key="index" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="每次上课时长" prop="plan_lesson_hour">
                        <el-input min="0" 
                        v-model="dataForm.plan_lesson_hour" placeholder="输入每次上课时长" clearable type="number"
                            :class="this.dataForm.knob * this.dataForm.plan_lesson_hour > this.totalClassHours ? 'inpBorder' : ''">
                            <!-- class="inpBorder" -->
                            <template slot="append">
                                <div style="color: #606266;"> 小时</div>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <div class="hint"
                            :class="this.dataForm.knob * this.dataForm.plan_lesson_hour > this.totalClassHours ? 'color-E34D59' : ''">
                            *此班级课程剩余未定计划课时为{{ totalClassHours }}小时</div>
                    </el-form-item>

                </el-form>
                <div class="footer">
                    <el-button @click="dialogFormVisible = false">取消</el-button>
                    <!-- <el-button type="primary" @click="submit()" plain>确定</el-button> -->
                    <el-button type="primary" @click="nextSubmit">下一步</el-button>
                </div>
            </div>

            <div v-show="step == 2">
                <el-form :model="dynamicValidateForm" label-position="right" ref="dynamicValidateForm" label-width="135px"
                    size="small">
                    <el-form-item :label="'计划' + (Number(index) + 1)" v-for="(domain, index) in dynamicValidateForm.domains"
                        :key="index" :prop="'domains.' + index + '.course_theme'" :rules="{
                            required: true, message: '课程主题不能为空', trigger: 'blur'
                        }">
                        <el-input v-model="domain.course_theme" placeholder="请在这里输入课程主题" clearable>
                        </el-input>
                    </el-form-item>
                </el-form>
                <div class="footer">
                    <el-button @click="dialogFormVisible = false">取消</el-button>
                    <el-button @click="step = 1">上一步</el-button>
                    <el-button type="primary" @click="submit()" plain>确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import { byTeacher } from "@/request/api";
import { getNextMonth } from '@/utils/time.js'
export default {
    data() {
        var validateHour = (rule, value, callback) => {
            if (value <= 0) {
                callback(new Error('上课时长不能小于0'));
            } 
            else {
                callback();
            }
        }
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 86400000 ; 
                },
                
            },
            propData: {},
            step: 1,
            dialogFormVisible: false,
            title: '批量创建计划',

            dynamicValidateForm: {
                domains: [],
            },
            dataForm: {
                knob: '',
                course_stage: '',
                plan_start_time: '',
                period: '',
                plan_lesson_hour: null
            },
            totalClassHours: 0,
            courseStageList: [
                {
                    label: '基础阶段',
                    value: 0
                },
                {
                    label: '强化阶段',
                    value: 1
                },
                {
                    label: '冲刺阶段',
                    value: 2
                }

            ],
            periodColumns: [
                {
                    label: '每天一节',
                    value: '86400000'
                },
                {
                    label: '每两天一节',
                    value: '172800000'
                },
                {
                    label: '每三天一节',
                    value: '259200000'
                },
                {
                    label: '每四天一节',
                    value: '345600000'
                },
                {
                    label: '每五天一节',
                    value: '432000000'
                },
                {
                    label: '每周一节',
                    value: '604800000'
                },
                {
                    label: '每两周一节',
                    value: '1209600000'
                },
                {
                    label: '每三周一节',
                    value: '1814400000'
                },
                {
                    label: '每月一节',
                    value: 'month'
                }

            ],
            rules: {
                knob: [{ required: true, message: "此次创建多少节课不能为空", trigger: "blur" },
                { pattern: /^[1-9](\d*)$/, message: '请输入正整数', trigger: 'blur' }],
                course_stage: [{ required: true, message: "课程阶段不能为空", trigger: "blur" }],
                plan_start_time: [{ required: true, message: "上课时间不能为空", trigger: "blur" }],
                period: [{ required: true, message: "上课周期不能为空", trigger: "blur" }],
                plan_lesson_hour: [
                    { required: true, message: "上课时长不能为空"},
                    { validator: validateHour, trigger: 'blur' }
                ],
            }
        }
    },
    // console.log("时间",getNextMonth("2023-10-31"))
    created() {


    },
    methods: {
        updateHour(hour) {
            this.totalClassHours = hour
        },
        open(data) {
            this.totalClassHours = data.totalClassHours
            this.step = 1
            this.propData = data
            this.title = '批量创建计划'

            this.dataForm = {
                knob: '',
                course_stage: '',
                plan_start_time: '',
                period: '',
                plan_lesson_hour: ''
            }
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs.dataForm.clearValidate();//清空表单
            })

        },
        batchSubmit() {
            byTeacher({
                grade_id: this.propData.grade_id,
                teacher_id: this.propData.teacher_id,
                is_test_data: 1,
                arr_data: this.dynamicValidateForm.domains
            }).then(res => {
                if (res.data.code == 0) {
                    if (res.data.data.error && res.data.data.error.error_data_list.length > 0) {
                        this.$message({
                            type: 'error',
                            message: res.data.data.error.error_data_list[0].error_reason
                        });
                    } else {
                        this.$emit("submit",this.dynamicValidateForm.domains);
                        this.$emit("updateHour");
                        this.dialogFormVisible = false
                        // byTeacher({
                        //     grade_id: this.propData.grade_id,
                        //     teacher_id: this.propData.teacher_id,
                        //     arr_data: this.dynamicValidateForm.domains
                        // }).then(res => {
                        //     if (res.data.code == 0) {
                        //         this.dialogFormVisible = false
                        //         this.$message({
                        //             type: 'success',
                        //             message: '创建成功!'
                        //         });
                        //         this.$emit("submit");
                        //         this.$emit("updateHour");
                        //     }

                        // })


                    }
                }
                // console.log("Res", res)
            })
        },
        submit() {
            this.$refs.dynamicValidateForm.validate((valid) => {
                if (valid) {
                    this.batchSubmit()
                    // alert('submit!');
                }
            });
        },
        nextSubmit() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {

                    if (this.dataForm.knob * this.dataForm.plan_lesson_hour > this.totalClassHours) {
                        this.$alert('班级总计划课时已用完，请编辑或删除部分计划后再创建', '温馨提示', {
                            confirmButtonText: '确定',
                            callback: action => {

                            }
                        });
                        return
                    }


                    this.dynamicValidateForm.domains = []
                    for (let i = 0; i < Number(this.dataForm.knob); i++) {
                        let time = ''
                        if (this.dataForm.period == 'month') {
                            time = getNextMonth(this.$moment(this.dataForm.plan_start_time).format("YYYY-MM-DD HH:mm"), i)

                        } else {
                            time = (this.dataForm.plan_start_time + (i * Number(this.dataForm.period)))
                        }
                        this.dynamicValidateForm.domains.push({
                            "course_theme": "",
                            "plan_lesson_hour": this.dataForm.plan_lesson_hour,
                            "plan_start_time": time,
                            "course_stage": this.dataForm.course_stage
                        })

                    }
                    this.step = 2
                    // console.log("11", this.dynamicValidateForm.domains)
                }
            })

        }
    }
}
</script>

<style lang="less" scoped>
.hint {
    // margin-top: -10px;
    font-size: 12px;
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    font-weight: normal;
    color: #909399;
    line-height: 20px;
}

.inpBorder {
    /deep/.el-input__inner {
        border-color: #E34D59 !important;
    }
}

.color-E34D59 {
    color: #E34D59;
}
</style>