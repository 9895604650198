module.exports = {
    htmlContent: `<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
        <span style="font-size: 18px;" > <strong><span style="font-family: 宋体;">步骤</span></strong></span>
</p >
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-family: 宋体;font-size: 14px">1<span style="font-family:宋体">、首先下载</span><span style="font-family:Calibri">excel</span><span style="font-family:宋体">模板；</span></span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-family: 宋体;font-size: 14px">2<span style="font-family:宋体">、按模版中的格式编辑好数据（切勿增减调整列，以免引起数据混乱。）</span></span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-family: 宋体;font-size: 14px">3<span style="font-family:宋体">、选择编辑好的文件，点击上传文件；</span></span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-family: 宋体;font-size: 14px">4<span style="font-family:宋体">、等待一段时间，系统提示</span><span style="font-family:Calibri">X</span><span style="font-family:宋体">条记录上传成功</span></span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">。</span></span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-family: 宋体;font-size: 14px">&nbsp;</span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-size: 18px;"><strong><span style="font-family: 宋体;">注意事项和常见问题</span></strong></span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-family: 宋体;font-size: 14px">1<span style="font-family:宋体">、以当前页面新下载的模板为准，建议使用</span><span style="font-family:Calibri">office 2003</span><span style="font-family:宋体">或更高版本的办公软件打开文件，以避免导出数据错乱的问题</span></span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">；</span></span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-family: 宋体;font-size: 14px">2<span style="font-family:宋体">、</span></span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">姓名、考研年份（</span></span><span style="font-size: 14px">4</span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">位数字）、手机号（</span></span><span style="font-size: 14px">11</span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">位数字）为必填数据，缺少任何一条或数据格式错误将无法上传；</span></span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-size: 14px">3</span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">、</span></span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">微信号及</span>QQ<span style="font-family:宋体">号针对格式作了基本校验，格式错误将无法上传；</span></span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-size: 14px">4</span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">、</span></span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">学员标签及学员来源可以写入多个，以英文分号隔开；</span></span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-size: 14px">5</span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">、</span></span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">意向课程必须为系统产品</span></span><span style="font-size: 14px">-</span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">课程列表中登记的课程名称一致，简写或名称错误将无法上传；</span></span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-size: 14px">6</span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">、</span></span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">院校、专业、学院及考试科目必须对照研招网填入完整的中文全程，简写或名称错误将无法上传；</span></span>
</p>
<p style="text-align: justify; font-family: Calibri; font-size: 14px; white-space: normal; line-height: 2em;">
    <span style="font-size: 14px">7</span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">、</span></span><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">考试科目可以写入多个，以英文分号隔开。</span></span>
</p>
<p>
    <br/>
</p>`
}