<template>
  <div class="content">
    <el-dialog
      top="5vh"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      width="980px"
    >
      <div
        class="wrap"
        v-if="Object.keys(content).length"
      >
        <el-descriptions
          direction="horizontal"
          :column="1"
          border
          :labelStyle="mylabel"
          title="学长详情"
        >
          <el-descriptions-item label="姓名">{{
            content.realname
          }}
         
          </el-descriptions-item>

          <el-descriptions-item label="性别">
            <el-tag size="mini">{{
              renderGender(content.gender)
            }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="确认状态">
            <el-tag
              size="mini"
              v-if="content.confirm_status == 1"
              type="success"
            >已确认</el-tag>
            <el-tag
              size="mini"
              v-if="content.confirm_status == 0"
              type="warning"
            >未确认</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="手机号">{{
            content.mobile
          }}</el-descriptions-item>
          <el-descriptions-item label="微信号">{{
            content.wechat
          }}</el-descriptions-item>
          <el-descriptions-item label="课时费">{{
           content.teacher_average_lesson_hour_amount? (content.teacher_average_lesson_hour_amount).toFixed(2)+'元/小时':'暂无'
          }}</el-descriptions-item>
          <el-descriptions-item label="服务提供">
            <p
              v-for="(item, $i) in content.provide_services
                ? content.provide_services.split(',')
                : []"
              :key="$i"
            >
              {{ renderProvideService(item) }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item label="优势补充">{{
            content.additional || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="申请时间">{{
            $moment(content.create_time).format("YYYY.MM.DD")
          }}</el-descriptions-item>
          <el-descriptions-item label="考研年份">{{
            content.recruit_year
          }}</el-descriptions-item>
          <el-descriptions-item label="录取院校">{{
            content.recruit_college_name
          }}</el-descriptions-item>
          <el-descriptions-item label="录取专业">{{
            content.recruit_major_name
          }}</el-descriptions-item>
          <el-descriptions-item label="考试科目">{{
            content.test_subject_names
          }}</el-descriptions-item>
          <el-descriptions-item label="初试总分">{{
            content.initial_test_score
          }}</el-descriptions-item>
          <el-descriptions-item label="科目分数">{{
            content.test_subject_scores
          }}</el-descriptions-item>
          <el-descriptions-item label="录取类型">{{
            renderRecruitType(content.recruit_type)
          }}</el-descriptions-item>
          <el-descriptions-item label="录取类型">
            <el-image
              style="width: 100px; height: 100px"
              :src="content.recruit_certify_img"
              :preview-src-list="[content.recruit_certify_img]"
            >
            </el-image>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="emitClose"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { teacherOne } from "@/request/api";
export default {
  components: {

  },
  props: {

    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      content: {},
      mylabel: {
        width: "120px",
      },
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  created() {

  },
  computed: {

  },
  methods: {
    fetchDetail() {
      teacherOne({ id: this.id }).then((response) => {
        let res = response.data
        this.content = res.data
      });
    },
    renderProvideService(type) {
      const services = {
        1: "资料-整理备考资料",
        2: "经验-分享上岸经验",
        3: "小讲-录制语音课程",
        4: "辅导-辅导1v1学员",
        5: "研讯-汇总专业数据",
      };
      return services[type] || "";
    },
    renderRecruitType(type) {
      const recruitTypes = {
        1: "第一志愿上岸",
        2: "调剂上岸",
      };
      return recruitTypes[type] || "";
    },
    renderGender(type) {
      const genders = {
        1: "学长",
        2: "学姐",
      };
      return genders[type] || "";
    },
    emitClose() {
      this.$emit("close");
    },

  }
};
</script>

<style lang="less" scoped>
.content {
  .wrap {
  }
}
</style>
