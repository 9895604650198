<template>
    <div class="content">
        <el-dialog top="5vh" title="收款记录" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="70%">
            <el-form class="search-wrap" ref="searchForm" :inline="true" style="display: flex;flex-wrap: wrap;"
                :model="query" size="small" label-position="left">
                <template v-if="query.order_type == 1">
                    <el-form-item label="机构简称">
                        <el-select v-model="extend_student_order_payload.cid" placeholder="请选择" filterable
                            @change="getSelectList(true)" clearable>
                            <el-option v-for="item in $store.state.CompanyList" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学生姓名">
                        <el-input v-model="extend_student_order_payload.keyword" placeholder="请输入学生姓名"
                            @input="getSelectList(true)" clearable></el-input>
                    </el-form-item>
                </template>
                <template v-else>
                    <el-form-item label="机构简称">
                        <el-select v-model="extend_dispatch_order_payload.cid" placeholder="请选择" filterable
                            @change="getSelectList(true)" clearable>
                            <el-option v-for="item in $store.state.CompanyList" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学生姓名">
                        <el-input v-model="extend_dispatch_order_payload.keyword" placeholder="请输入学生姓名"
                            @input="getSelectList(true)" clearable></el-input>
                    </el-form-item>
                </template>

                <el-form-item label="收款状态">
                    <el-select v-model="query.receipt_status" placeholder="请选择" clearable @change="getSelectList(true)">
                        <el-option label="未收款" :value="0"></el-option>
                        <el-option label="已收款" :value="1"></el-option>
                        <el-option label="已退款" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收款时间">
                    <el-date-picker v-model="time" type="datetimerange" format="yyyy-MM-dd HH:mm"  :default-time="['00:00:00', '23:59:59']" align="right" unlink-panels range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期"  value-format="timestamp"
                        @change="getSelectList(true)">
                    </el-date-picker>
                </el-form-item>
                <el-form-item style="margin-left: auto;">
                    <el-button icon="el-icon-download" @click="handleExport" size="small">导出</el-button>
                </el-form-item>

            </el-form>
            <el-table  v-loading="loading" :data="tableData" ref="multipleTable" :header-cell-style="{
                textAlign: 'center',
                color: '#909399',
                fontFamily: 'PingFang SC',
                fontSize: '14px',
                fontWeight: '400',
                backgroundColor: '#F2F6FC',
            }" :cell-style="{ fontSize: '13px', }" border style="width: 100%; margin-top: 20px">
                <el-table-column type="index" label="序号" width="50" align="center">
                    <template slot-scope="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <template v-if="query.order_type == 1">
                    <el-table-column prop="apply_year" label="机构简称" show-overflow-tooltip width="150px" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.student_order_obj.company.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="apply_year" label="学生姓名" show-overflow-tooltip width="150px" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.student_order_obj.student_realname }}/{{
                                scope.row.student_order_obj.student_phone }}</span>
                        </template>
                    </el-table-column>
                </template>
                <template v-else>
                    <el-table-column prop="apply_year" label="订单编号" show-overflow-tooltip width="120px" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.dispatch_order_obj.order_no }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="apply_year" label="机构简称" show-overflow-tooltip width="150px" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.dispatch_order_obj.company.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="apply_year" label="学生姓名" show-overflow-tooltip width="100px" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.dispatch_order_obj.student_name }}</span>
                        </template>
                    </el-table-column>

                </template>


                <el-table-column prop="apply_year" label="订单类型" show-overflow-tooltip width="80px" align="center">
                    <template slot-scope="scope">
                        <span>{{ query.order_type == 1 ? '外包' : '代签' }}</span>
                    </template>
                </el-table-column>


                <el-table-column prop="receipt_amount" label="收款金额" width="100" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.receipt_amount }}元
                    </template>
                </el-table-column>


                <el-table-column label="收款方式" width="80" align="center">
                    <template slot-scope="scope">
                        {{
                            scope.row.receipt_type == 1 ? '支付宝' : scope.row.receipt_type == 2 ? '微信' : scope.row.receipt_type ==
                                3 ? '余额' : scope.row.receipt_type == 4 ? '转账' : '无'
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="收款状态" width="100" align="center">
                    <template slot-scope="scope">
                        <el-tag
                            :type="scope.row.receipt_status == 0 ? 'warning' : scope.row.receipt_status == 1 ? 'success' : 'danger'">
                            {{ scope.row.receipt_status == 0 ? '未收款' : scope.row.receipt_status == 1 ? '已收款' : '已退款' }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="收款截图" width="150" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.receipt_img">
                            <el-image style="width: 30px; height: 30px;margin-right: 5px;"
                                v-for="(item, index) in JSON.parse(scope.row.receipt_img)" :src="item.url"
                                :preview-src-list="JSON.parse(scope.row.receipt_img).map(item => item.url)">
                            </el-image>
                        </div>
                        <div v-else>-</div>
                    </template>
                </el-table-column>
                <el-table-column prop="receipt_trade_no" label="收款交易单号" width="150" align="center">
                    <template slot-scope="scope">
                        {{scope.row.receipt_trade_no||'-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="receipt_remark" label="收款备注" width="200" align="center">
                    <template slot-scope="scope">
                        {{scope.row.receipt_remark||'-' }}
                    </template>
                </el-table-column>

                <el-table-column label="收款时间" width="150" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.receipt_time ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "" }}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" width="150" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.create_time ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "" }}
                    </template>
                </el-table-column>
                <el-table-column label="完成时间" width="150 " align="center">
                    <template slot-scope="scope">
                        {{ scope.row.update_time ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm") : "" }}
                    </template>
                </el-table-column>
                <!--  :disabled="!$buttonPermission('dispatchorder:edit')" -->
                <el-table-column prop="name" label="操作" width="100" fixed="right">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="handleUp(scope.row)"
                            :disabled="!$buttonPermission('collectionRecord:handleUp')">编辑</el-link>
                        <el-link type="danger" :underline="false" @click="handleDel(scope.row)"
                            :disabled="!$buttonPermission('collectionRecord:handleUp')">删除</el-link>
                    </template>

                </el-table-column>
            </el-table>
            <div class="footer">
                <div>
                    <el-button size="small" @click="handleAdd()" v-if="id">新增记录</el-button>
                </div>
                <el-pagination background layout="total, prev, pager, next, sizes, jumper"
                    :current-page="Number(query.pageIndex)" :page-sizes="[10, 20, 50, 100, 500]"
                    :page-size="Number(query.pageSize)" :total="Number(pageTotal)" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"></el-pagination>
            </div>
        </el-dialog>
        <CollectionRecordAdd ref="collectionRecordAdd" @submit="getSelectList(true)"></CollectionRecordAdd>
    </div>
</template>

<script>
import { handleLogCreate, selectList, orderReceiptRecordDel, orderReceiptRecordExport } from "../../../request/api";
import CollectionRecordAdd from "@/components/DialogComponents/CollectionRecord/CollectionRecordAdd.vue"
export default {
    components: {
        CollectionRecordAdd
    },
    data() {
        return {
            loading:false,
            dialogFormVisible: false,
            query: {
                pageIndex: 1,
                pageSize: 10,
            },
            time: [],
            tableData: [],
            pageTotal: 0,
            extend_student_order_payload: {},
            extend_dispatch_order_payload: {},
            id:''
        }
    },
    created() {

    },
    methods: {
        // 导出
        handleExport() {
            this.$confirm("是否导出数据", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let data = {}
                if (this.query.order_type == 1) {
                    data = {
                        extend_student_order_payload: this.extend_student_order_payload
                    }
                } else {
                    data = {
                        extend_dispatch_order_payload: this.extend_dispatch_order_payload
                    }
                }
                orderReceiptRecordExport({
                    ...this.query,
                    ...data,
                    start_receipt_time: (this.time && this.time.length > 0) ? this.time[0] : '',
                    end_receipt_time: (this.time && this.time.length > 0) ? this.time[1] : ''
                }).then((res) => {
                    if (res.data.code == 0) {
                        this.$message.success('导出成功')
                        //创建a标签用于跳转至下载链接
                        // console.log("src",src)
                        var str = res.data.data.url.replace(/http:/, window.location.protocol)
                        this.$store.commit("downLoad", str);
                    }
                });
            })
        },
        handleAdd(row){
            this.$refs.collectionRecordAdd.open(this.query.order_type, this.id)
            // if (this.query.order_type == 1) {
               
            // } else {
            //     this.$refs.collectionRecordAdd.open('2', this.id)
            // }
        },
        handleUp(row) {
            if (this.query.order_type == 1) {
                this.$refs.collectionRecordAdd.open('1', row.student_order_obj.id, row)
            } else {
                this.$refs.collectionRecordAdd.open('2', row.dispatch_order_obj.id, row)
            }
        },
        open(type,id) {
            // console.log("id",id)
            if(id){
                this.id = id
                this.query.order_id = id
            }else{
                this.id = null
                this.query.order_id = null
            }
            // this.$store.dispatch("getCompanyList");
            this.dialogFormVisible = true
            this.query.order_type = type
            this.getSelectList()
        },
        getSelectList(flag) {
            this.loading = true
            if (flag) {
                this.query.pageIndex = 1
            }
            let data = {}
            if (this.query.order_type == 1) {
                data = {
                    extend_student_order_payload: this.extend_student_order_payload
                }
            } else {
                data = {
                    extend_dispatch_order_payload: this.extend_dispatch_order_payload
                }
            }
            // this.tableData = []
            selectList({
                ...this.query,
                ...data,
                start_receipt_time: (this.time && this.time.length > 0) ? this.time[0] : '',
                end_receipt_time: (this.time && this.time.length > 0) ? this.time[1] : ''

            }).then(res => {
                if (res.data.code == 0) {
                    this.loading = false
                    this.pageTotal = res.data.data.count
                    this.tableData = res.data.data.list
                }
                // console.log("re", res)
            })
        },

        handleDel(row) {
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log("删除")
                orderReceiptRecordDel({
                    id: row.id
                }).then(res => {
                    if (res.data.code == 0) {
                        this.getSelectList()
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleSizeChange(val) {
            this.query.pageSize = val;
            this.getSelectList();
        },
        handleCurrentChange(val) {
            this.query.pageIndex = val;
            this.getSelectList();
        },

    }
}
</script>

<style lang="less" scoped>
.header {
    display: flex;
    justify-content: space-between;
}

.footer {
    display: flex;
    justify-content: space-between;
}
</style>