<template>
  <div>
    <el-dialog
      top="5vh"
      title="新建并匹配"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="657px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="130px"
        size="small"
      >
        <el-form-item label="机构简称：" prop="cid">
          <el-select
            filterable
            v-model="dataForm.cid"
            placeholder="请选择"
            style="width: 100%"
            @change="cidChange"
          >
            <el-option
              v-for="item in $store.state.CompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考研年份：" prop="apply_year">
          <el-select
            v-model="dataForm.apply_year"
            placeholder="请选择考研年份"
            clearable
            style="width: 100%"
          >
            <el-option label="2024考研" value="2024"></el-option>
            <el-option label="2025考研" value="2025"></el-option>
            <el-option label="2026考研" value="2026"></el-option>
            <el-option label="2027考研" value="2027"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考院校：" prop="apply_college">
          <el-select
            v-model="dataForm.apply_college"
            placeholder="请选择报考院校"
            filterable
            clearable
            @change="collegeChange"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name }"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考专业：" prop="apply_major">
          <div class="sign">
            <el-select
              v-model="dataForm.apply_major"
              placeholder="请选择报考专业"
              @change="majorChange"
              clearable
              filterable
              class="select"
            >
              <el-option
                v-for="(item, index) in majorOptions"
                :key="index"
                :label="item.allnames"
                :value="{ value: item.code, label: item.name }"
              >
              </el-option>
            </el-select>
            <div class="sign-icon">
              {{
                majorType == 1 ? "学硕型" : majorType == 2 ? "专硕型" : "暂无"
              }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="报考学院：" prop="apply_major">
          <el-select
            v-model="dataForm.apply_academy"
            placeholder="请选择报考学院"
            clearable
            @change="academyChange"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="{
                value: item.academy_code,
                label: item.academy_name,
                directions: item.directions,
              }"
            >
              <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试方向：" prop="apply_major">
          <el-select
            v-model="dataForm.apply_direction"
            placeholder="请选择考试方向"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in directionsOptions"
              :key="index"
              :label="item.direction == '不限'
                  ? item.direction
                  : `（${item.direction_code}）${item.direction}`"
              :value="{ value: item.direction_code, label: item.direction }"
            >
              <span>{{
                item.direction == "不限"
                  ? item.direction
                  : `（${item.direction_code}）${item.direction}`
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="辅导科目：" prop="apply_subjects">
          <el-select
            style="width: 100%"
            v-model="dataForm.apply_subjects"
            placeholder="请选择辅导科目"
            clearable
            multiple
            @change="$forceUpdate()"
          >
            <el-option
              v-for="(item, index) in subjectOptions"
              :key="index"
              :label="item.subject_name"
              :value="{
                value: item.subject_code,
                label: item.subject_name,
                score: 0,
                id: item.id,
              }"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="辅导课时：" prop="lesson_hours">
          <el-input
            placeholder="请输入辅导课时"
            v-model="dataForm.lesson_hours"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            @input="classSwitching(1)"
          >
            <span slot="append">小时</span>
          </el-input>

           <div style="margin-top: 5px" v-if="dataForm.cid">
            1课时={{ rate }}小时 共
            <el-input
              v-model="rateHour"
              style="width: 80px; margin-top: 5px"
              @input="classSwitching(2)"
            ></el-input>
            课时
          </div>
        </el-form-item>
        <el-form-item label="订单价格：" prop="teacher_matching_price">
          <el-input
            v-model.number="dataForm.teacher_matching_price"
            placeholder="请输入订单价格"
            clearable
            style="width: 100%"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="匹配要求：" prop="allocation_demand">
          <el-input
            type="textarea"
            v-model="dataForm.allocation_demand"
            placeholder="请输入匹配要求"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="师资数量：" prop="count_teacher_need">
          <div class="count-wrap">
            <div
              class="count-item"
              @click="chooseCount(1)"
              :class="{ choose: count == 1 }"
            >
              1位
            </div>
            <div
              class="count-item"
              @click="chooseCount(2)"
              :class="{ choose: count == 2 }"
            >
              2位
            </div>
            <div
              class="count-item"
              @click="chooseCount(3)"
              :class="{ choose: count == 3 }"
            >
              3位
            </div>

            <div
              class="count-item"
              @click="chooseCount(0)"
              :class="{ choose: count == 0 }"
              v-if="count != 0"
            >
              更多
            </div>
            <div class="count-item" v-if="count == 0">
              <el-input
                v-model="countInput"
                placeholder="请输入"
                style="width: 60px"
              >
              </el-input>
              位
            </div>
          </div>
        </el-form-item>
        <el-form-item label="是否加急：" prop="urgency_status">
          <el-radio-group v-model="dataForm.urgency_status">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="匹配成功通知人：" prop="watcher">
          <el-select
            v-model="dataForm.watcher"
            placeholder="请选择"
            style="width: 100%"
            multiple
          >
            <el-option
              v-for="(item, index) in staffOptions"
              :key="index"
              :label="item.alias"
              :value="item.id"
            >
              <span> {{ `${item.alias}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button
          type="primary"
          @click="continueSubmitit('formRules')"
          :loading="btnLoading"
          >确定并继续</el-button
        >
        <el-button
          type="primary"
          @click="submit('formRules')"
          slot=""
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  companyuserlist,
  createteacherorder,
  academylist,
  majorlist,
  subjectlist,
  teacherorderOne,
  createTa,
  companyOne
} from "@/request/api";
import { adminUrl } from "@/utils/helper";

export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      majorType: 0,
      btnLoading: false,
      dialogFormVisible: this.isVisible,
      dataForm: {
        lesson_hours: 0,
        urgency_status: 0,
      },
      rules: {
        cid: [{ required: true, message: "请选择机构", trigger: "blur" }],
        apply_year: [
          { required: true, message: "请选择考研年份", trigger: "blur" },
        ],
        apply_college: [
          { required: true, message: "请选择报考院校", trigger: "blur" },
        ],
        apply_major: [
          { required: true, message: "请选择报考专业", trigger: "blur" },
        ],
        apply_academy: [
          { required: true, message: "请选择报考学院", trigger: "blur" },
        ],
        apply_subjects: [
          { required: true, message: "请选择辅导科目", trigger: "blur" },
        ],
        teacher_matching_price: [
          { required: true, message: "请选择机构", trigger: "blur" },
        ],
        apply_direction: [
          { required: true, message: "请选择研究方向", trigger: "blur" },
        ],
        lesson_hours: [
          { required: true, message: "请输入辅导课时", trigger: "blur" },
        ],
        urgency_status: [
          { required: true, message: "请选择是否加急", trigger: "blur" },
        ],
        count_teacher_need: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.count <= 0 && this.countInput <= 0) {
                callback(new Error("请选择师资数量"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      majorOptions: [], // 专业数组
      academyOptions: [], // 学院数组
      subjectOptions: [], //科目数组
      directionsOptions: [], //方向数组
      count: 1,
      countInput: 0,
      staffOptions: [],
      rate: 0.75,
      rateHour: 0,
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.rateHour = 0
      this.majorType = 0
      this.dialogFormVisible = val;
    },
  },
  methods: {
    handleToClose() {
      this.$emit("close");
      this.dataForm = {
        lesson_hours: 0,
        urgency_status: 0,
      };
    },
    collegeChange(e) {
      this.dataForm.apply_major = {};
      this.majorOptions = [];
      this.dataForm.apply_academy = {};
      this.academyOptions = [];
      this.dataForm.apply_subjects = [];
      this.subjectOptions = [];
      if (e) {
        this.getMajor(e.value);
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data;
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          };
        });
      });
    },
    majorChange(e) {
      this.dataForm.apply_academy = {};
      this.academyOptions = [];
      this.dataForm.apply_subjects = [];
      this.subjectOptions = [];
      this.dataForm.directions = [];

      if (e) {
        let obj = {};
        obj = this.majorOptions.find((item) => {

          return item.code === e.value; //筛选出 匹 配数据
        });
        console.log('obj:', obj)
        this.majorType = obj.type;
        this.getMajorInfo(this.dataForm.apply_college.value, e.value);
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl();
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: "get", //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data;
        this.academyOptions = res.data.academys;
        //  console.log("oooo",)
        if (this.academyOptions.length == 1) {
          this.dataForm.apply_academy = {
            value: this.academyOptions[0].academy_code,
            label: this.academyOptions[0].academy_name,
            directions: this.academyOptions[0].directions,
          };
          this.academyChange(this.dataForm.apply_academy);
        }
        //  console.log("999", this.dataForm)

        this.directionsOptions = this.academyOptions.find((v) => {
          return this.dataForm.apply_academy.value == v.academy_code;
        }).directions;
      });
    },
    academyChange(e) {
      console.log("E", e);
      this.dataForm.apply_subjects = [];
      this.subjectOptions = [];
      this.$forceUpdate();
      if (e) {
        this.directionsOptions = e.directions;
        if (this.directionsOptions[0].direction != "不限") {
          this.directionsOptions.unshift({
            direction: "不限",
            direction_code: "",
          });
        }
        this.$set(this.dataForm, "apply_direction", {
          label: this.directionsOptions[0].direction,
          value: this.directionsOptions[0].direction_code,
        });
        this.getSubjects();
      }
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.dataForm.apply_college.value,
        academy_code: this.dataForm.apply_academy.value,
        major_code: this.dataForm.apply_major.value,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程",
        };
        this.subjectOptions = res.data.data.list || [];
        this.subjectOptions.push(obj);
      });
    },

    continueSubmitit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          const data = {
            cid: this.dataForm.cid,
            apply_year: this.dataForm.apply_year,
            apply_college_code: this.dataForm.apply_college
              ? this.dataForm.apply_college.value
              : "",
            apply_college_name: this.dataForm.apply_college
              ? this.dataForm.apply_college.label
              : "",
            apply_major_code: this.dataForm.apply_major
              ? this.dataForm.apply_major.value
              : "",
            apply_major_name: this.dataForm.apply_major
              ? this.dataForm.apply_major.label
              : "",
            apply_academy_code: this.dataForm.apply_academy
              ? this.dataForm.apply_academy.value
              : "",
            apply_academy_name: this.dataForm.apply_academy
              ? this.dataForm.apply_academy.label
              : "",
            apply_direction_code: this.dataForm.apply_direction
              ? this.dataForm.apply_direction.value
              : "",
            apply_direction_name: this.dataForm.apply_direction
              ? this.dataForm.apply_direction.label
              : "",
            lesson_hours: this.dataForm.lesson_hours,
            teacher_matching_price: this.dataForm.teacher_matching_price,
            subjects:
              this.dataForm.apply_subjects &&
              this.dataForm.apply_subjects.length
                ? this.dataForm.apply_subjects.map((v) => {
                    return {
                      id: v.id,
                      subject_code: v.value,
                      subject_name: v.label,
                    };
                  })
                : "",
            watcher: this.dataForm.watcher
              ? this.dataForm.watcher.join(",")
              : "",
            remark: this.dataForm.allocation_demand
              ? this.dataForm.allocation_demand
              : "无",
          };
          createteacherorder(data).then((response) => {
            this.btnLoading = false;
            let res = response.data;
            if (res.code == 0) {
              let data1 = {
                type: 2,
                teacher_match_order_id: res.data.insertId,
                cid: this.dataForm.cid,
                apply_college_code: this.dataForm.apply_college
                  ? this.dataForm.apply_college.value
                  : "",
                apply_college_name: this.dataForm.apply_college
                  ? this.dataForm.apply_college.label
                  : "",
                apply_major_code: this.dataForm.apply_major
                  ? this.dataForm.apply_major.value
                  : "",
                apply_major_name: this.dataForm.apply_major
                  ? this.dataForm.apply_major.label
                  : "",
                apply_academy_code: this.dataForm.apply_academy
                  ? this.dataForm.apply_academy.value
                  : "",
                apply_academy_name: this.dataForm.apply_academy
                  ? this.dataForm.apply_academy.label
                  : "",
                apply_direction_code: this.dataForm.apply_direction
                  ? this.dataForm.apply_direction.value
                  : "",
                apply_direction_name: this.dataForm.apply_direction
                  ? this.dataForm.apply_direction.label
                  : "",
                apply_year: this.dataForm.apply_year,
                apply_subject_names:
                  this.dataForm.apply_subjects &&
                  this.dataForm.apply_subjects.length
                    ? this.dataForm.apply_subjects
                        .map((v) => {
                          return v.label;
                        })
                        .join(",")
                    : "",
                apply_subject_codes:
                  this.dataForm.apply_subjects &&
                  this.dataForm.apply_subjects.length
                    ? this.dataForm.apply_subjects
                        .map((v) => {
                          return v.value;
                        })
                        .join(",")
                    : "",
                lesson_hours: this.dataForm.lesson_hours,
                allocation_demand: this.dataForm.allocation_demand
                  ? this.dataForm.allocation_demand
                  : "无",
                urgency_status: this.dataForm.urgency_status,
                count_teacher_need: this.computedCount(),
              };
              createTa(data1).then((response1) => {
                let res1 = response1.data;
                if (res1.code == 0) {
                  this.$message.success("操作成功");

                  //  this.$emit('submit')
                  this.dataForm = {
                    lesson_hours: 0,
                    urgency_status: 0,
                    cid: this.dataForm.cid,
                    apply_year: this.dataForm.apply_year,
                    watcher: this.dataForm.watcher ? this.dataForm.watcher : [],
                  };
                  this.cidChange(this.dataForm.cid);
                }
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    


    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          const data = {
            cid: this.dataForm.cid,

            apply_year: this.dataForm.apply_year,
            apply_college_code: this.dataForm.apply_college
              ? this.dataForm.apply_college.value
              : "",
            apply_college_name: this.dataForm.apply_college
              ? this.dataForm.apply_college.label
              : "",
            apply_major_code: this.dataForm.apply_major
              ? this.dataForm.apply_major.value
              : "",
            apply_major_name: this.dataForm.apply_major
              ? this.dataForm.apply_major.label
              : "",
            apply_academy_code: this.dataForm.apply_academy
              ? this.dataForm.apply_academy.value
              : "",
            apply_academy_name: this.dataForm.apply_academy
              ? this.dataForm.apply_academy.label
              : "",
            apply_direction_code: this.dataForm.apply_direction
              ? this.dataForm.apply_direction.value
              : "",
            apply_direction_name: this.dataForm.apply_direction
              ? this.dataForm.apply_direction.label
              : "",
            lesson_hours: this.dataForm.lesson_hours,
            teacher_matching_price: this.dataForm.teacher_matching_price,
            subjects:
              this.dataForm.apply_subjects &&
              this.dataForm.apply_subjects.length
                ? this.dataForm.apply_subjects.map((v) => {
                    return {
                      id: v.id,
                      subject_code: v.value,
                      subject_name: v.label,
                    };
                  })
                : "",
            watcher: this.dataForm.watcher
              ? this.dataForm.watcher.join(",")
              : "",
            remark: this.dataForm.allocation_demand
              ? this.dataForm.allocation_demand
              : "无",
          };
          createteacherorder(data).then((response) => {
            this.btnLoading = false;
            let res = response.data;
            if (res.code == 0) {
              let data1 = {
                type: 2,
                teacher_match_order_id: res.data.insertId,
                cid: this.dataForm.cid,
                apply_college_code: this.dataForm.apply_college
                  ? this.dataForm.apply_college.value
                  : "",
                apply_college_name: this.dataForm.apply_college
                  ? this.dataForm.apply_college.label
                  : "",
                apply_major_code: this.dataForm.apply_major
                  ? this.dataForm.apply_major.value
                  : "",
                apply_major_name: this.dataForm.apply_major
                  ? this.dataForm.apply_major.label
                  : "",
                apply_academy_code: this.dataForm.apply_academy
                  ? this.dataForm.apply_academy.value
                  : "",
                apply_academy_name: this.dataForm.apply_academy
                  ? this.dataForm.apply_academy.label
                  : "",
                apply_direction_code: this.dataForm.apply_direction
                  ? this.dataForm.apply_direction.value
                  : "",
                apply_direction_name: this.dataForm.apply_direction
                  ? this.dataForm.apply_direction.label
                  : "",
                apply_year: this.dataForm.apply_year,
                apply_subject_names:
                  this.dataForm.apply_subjects &&
                  this.dataForm.apply_subjects.length
                    ? this.dataForm.apply_subjects
                        .map((v) => {
                          return v.label;
                        })
                        .join(",")
                    : "",
                apply_subject_codes:
                  this.dataForm.apply_subjects &&
                  this.dataForm.apply_subjects.length
                    ? this.dataForm.apply_subjects
                        .map((v) => {
                          return v.value;
                        })
                        .join(",")
                    : "",
                lesson_hours: this.dataForm.lesson_hours,
                allocation_demand: this.dataForm.allocation_demand
                  ? this.dataForm.allocation_demand
                  : "无",
                urgency_status: this.dataForm.urgency_status,
                count_teacher_need: this.computedCount(),
              };
              createTa(data1).then((response1) => {
                let res1 = response1.data;
                if (res1.code == 0) {
                  this.rateHour = 0
                  this.$message.success("操作成功");
                  this.$emit("submit");
                  this.handleToClose();
                }
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    chooseCount(num) {
      this.count = num;
    },
    computedCount() {
      if (this.count <= 3) {
        return this.count;
      } else if (this.count > 3) {
        return this.countInput;
      }
    },
    getStaffByCid(cid) {
      let query = {
        isPaging: 0,
        cid: cid,
      };
      companyuserlist(query).then((res) => {
        this.staffOptions = res.data.data.list || [];
      });
    },
    cidChange(e) {
      // console.log('11',e)
      this.dataForm.watcher = "";
      this.getStaffByCid(e);
      this.$set(
        this.dataForm,
        "teacher_matching_price",
        this.$store.state.CompanyList.find((v) => {
          return v.id == e;
        }).teacher_matching_price
      );
      this.getCompanyOne(e)
    },
     getCompanyOne(id) {
      companyOne({ id }).then((res) => {
        if (res.data.code == 0) {
          this.rate = res.data.data.lesson_hour_hour_rate || 0.75;
          if (this.dataForm.lesson_hours || this.rateHour) {
            this.rateHour = (
              Number(this.dataForm.lesson_hours) / this.rate
            ).toFixed(2);
          }
        }
      });
    },

    classSwitching(type) {
      if (type == 1) {
        this.rateHour = (
          Number(this.dataForm.lesson_hours) / this.rate
        ).toFixed(2);
      } else {
        this.$set(
          this.dataForm,
          "lesson_hours",
          (Number(this.rateHour) * this.rate).toFixed(2)
        );
        this.$forceUpdate()
        // this.dataForm.lesson_hours = (
        //   Number(this.rateHour) * this.rate
        // ).toFixed(2);
      }
    },


  },
};
</script>
        
<style lang="less" scoped>
.sign {
  display: flex;
  .select {
    flex: 1;
    /deep/.el-input__inner {
      border-radius: 4px 0 0 4px !important;
    }
  }
  .sign-icon {
    box-sizing: border-box;
    width: 70px;
    height: 32px;
    line-height: 32px;
    background-color: #f5f7fa;
    color: #909399;
    border: 1px solid #dcdfe6;
    border-left: none;
    text-align: center;
    border-radius: 0px 4px 4px 0px;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #303133;
  margin-bottom: 12px;
}

.module {
}

/deep/.el-alert__icon {
  color: #266fe8;
}

/deep/.el-alert__title {
  color: #333;
}

/deep/.el-input-group__prepend {
  min-width: 110px;
}

.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  text-align: center;
}

.avatar {
  width: 140px;
  height: 140px;
}

.count-wrap {
  display: flex;
  align-items: center;

  .count-item {
    padding: 0px 18px;
    font-size: 14px;
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    color: rgba(0, 0, 0, 0.6);
    border: 1px solid #dcdcdc;
    margin-right: 12px;
    cursor: pointer;

    /deep/.el-input__inner {
      border: none;
      background: transparent;
    }
  }

  .choose {
    background-image: url("https://upload.kaoyanbox.net/c2d67a5112245e425cc58a547522aaa8.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>