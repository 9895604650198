<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>机构</el-breadcrumb-item>
        <el-breadcrumb-item>机构管理</el-breadcrumb-item>
      </el-breadcrumb>

    </div>
    <el-card style="margin-top: 20px">
      <div class="table-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
        >
          <el-form-item style="">
            <el-radio-group
              v-model="query.is_read"
              @change="getList(query)"
            >
              <el-radio-button
                v-for="(item, index) in filter.read_status"
                :key="index"
                :label="item.value"
              >
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column
          prop="id"
          label="ID"
          show-overflow-tooltip
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          show-overflow-tooltip
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          show-overflow-tooltip
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="title"
          label="职务"
          show-overflow-tooltip
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="company_name"
          label="机构名称"
          show-overflow-tooltip
          width="260"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="地址"
          show-overflow-tooltip
          width="260"
        >
        </el-table-column>
        <el-table-column
          prop="need"
          label="需求"
          show-overflow-tooltip
          width="300"
        >
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建日期"
          show-overflow-tooltip
          width="260"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="150"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              v-if="scope.row.is_read==0"
              @click="handleToRead(scope.row.id)"
            >未读</el-link>
            <el-link
              type="info"
              :underline="false"
              v-else
            >已读</el-link>

          </template>

        </el-table-column>
      </el-table>

      <div class="block">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>

    </el-card>

  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";

import {
  companyapplylist,
  companyapplyread
} from "../../../request/api";
export default {
  components: {
    TableTittle,

  },
  data() {
    return {
      TableName: "申请列表",
      tableData: [],

      form: {},

      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        is_read: 0,
        cid: localStorage.getItem("cid"),
      },
      filter: {
        read_status: [
          { label: "全部", value: "" },
          { label: "已读", value: "1" },
          { label: "未读", value: "0" },
        ],

      },
    };
  },
  mounted() {
    this.getList(this.query);

  },
  methods: {

    getList(query) {
      companyapplylist(query).then((res) => {
        this.tableData = res.data.data.list || [];
        this.pageTotal = res.data.data.count;
      });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);

    },
    handleToRead(id) {
      let query = {
        id,
        is_read: 1,
      }
      companyapplyread(query).then((res) => {
        this.getList(this.query);
      });
    }

  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin: 16px 0px;
}
.star {
  width: 18px;
  height: 18px;
}
.demo-form-inline {
  height: 64px;
  border-bottom: 2px dashed #d2d5df;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
</style>