<template>
  <div class="content">
    <div class="demo-drawer__content">
      <el-form :inline="true" class="demo-form-inline" size="small">
        <el-form-item label="机构简称" prop="cid">
          <el-select
            v-model="form.cid"
            placeholder="请选择"
            filterable
            @change="submit"
            clearable
          >
            <el-option
              v-for="item in $store.state.CompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发票状态">
          <el-select
            v-model="form.invoice_status"
            placeholder="请选择发票状态"
            clearable
            @change="submit"
          >
            <el-option label="待开票" value="1"></el-option>
            <el-option label="开票成功" value="2"></el-option>
            <el-option label="开票失败" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票时间" prop="last_advance_time">
          <el-date-picker
            v-model="invoice_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="submit"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="创建时间" prop="last_advance_time">
          <el-date-picker
            v-model="create_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="submit"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      labelPosition: "left",
      create_time: "",
      invoice_time: "",
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit() {
      // 时间区间处理
      if (this.invoice_time) {
        this.form.start_invoice_time = this.invoice_time[0];
        this.form.end_invoice_time = this.invoice_time[1] + 86399000;
      } else {
        this.form.start_invoice_time = "";
        this.form.end_invoice_time = "";
      }
      if (this.create_time) {
        this.form.start_create_time = this.create_time[0];
        this.form.end_create_time = this.create_time[1] + 86399000;
      } else {
        this.form.start_create_time = "";
        this.form.end_create_time = "";
      }
      this.$emit("submit", { screenForm: this.form });
    },
  },
};
</script>

<style lang="less" scoped>
.demo-drawer__content {
  padding-top: 24px;
  height: 100%;
}
.el-form {
}
.demo-drawer__footer {
  margin-bottom: 24px;
  float: right;
}
</style>