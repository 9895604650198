<template>
  <div>
    <el-dialog
      top="5vh"
      title="确认列表"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="70%"
    >
      <div class="between">
        <TableTittle :title="TableName"></TableTittle>
        <div>
          <el-button
            type="danger"
            size="small"
            style="margin-bottom: 12px;"
            @click="cancelMatching"
            v-if="order&&order.status!=6&&!type"
          >取消匹配</el-button>
          <el-button
            type="danger"
            size="small"
            style="margin-bottom: 12px;"
            disabled
            v-else-if="!type"
          >匹配已取消</el-button>
          <el-button
            type="primary"
            size="small"
            v-if="!type"
            style="margin-bottom: 12px;"
            @click="openEditMatching"
          >修改匹配</el-button>
        </div>
      </div>
      <el-table
        :data="result"
        row-key="id"
        :header-cell-style="{
              backgroundColor: '#F2F6FC',
            }"
        border
        v-loading="loading"
        @expand-change="expandChange"
        :expand-row-keys="expands"
      >
        <!--  内嵌表格 -->
        <el-table-column
          type="expand"
          prop="沟通记录"
        >
          <template slot-scope="scope">
            <div class="expand-wrap">
              <el-table
                :data="scope.row.records?scope.row.records:[]"
                :border="false"
                :header-cell-style="{
                    backgroundColor: '#f9f9f9',
                 }"
              >
                <el-table-column
                  label="日期"
                  align="center"
                  width="150"
                >
                  <template slot-scope="scope">
                    {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):"无"}}
                  </template>
                </el-table-column>
                <el-table-column
                  label="记录人"
                  align="center"
                  width="100"
                >
                  <template slot-scope="scope">
                    {{ scope.row.manage_creator?scope.row.manage_creator.nickname:'无' }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="类别"
                  align="center"
                  width="100"
                >
                  <template slot-scope="scope">
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 1"
                    >分配</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 2"
                    >备注</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 3"
                    >修改</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 4"
                    >跟进</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 5"
                    >签单</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 6"
                    >申请</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 7"
                    >沟通</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 8"
                    >记录</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 9"
                    >提交</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 10"
                      type="warning"
                    >修改</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 11"
                    >加入</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 12"
                      type="success"
                    >确认</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 13"
                      type="danger"
                    >驳回</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 14"
                      type="danger"
                    >取消</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 15"
                      type="warning"
                    >稍签</el-tag>
                    <el-tag
                        size="mini"
                        v-if="scope.row.type == 16"
                        type="warning"
                      >完结</el-tag>
                      <el-tag size="mini" v-if="scope.row.type == 17" type="warning">推荐</el-tag>
            <el-tag size="mini" v-if="scope.row.type == 18" type="success">确认</el-tag>
            <el-tag size="mini" v-if="scope.row.type == 19" type="success">签约</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  label="沟通记录"
                  align="left"
                  show-overflow-tooltip
                  prop="record"
                  min-width="500"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.record }}
                      <span style="color:#409EFF;">{{ formateAltManager(scope.row.alt_manage_ids) }}</span>
                    </div>
                    <div
                      class="img-list"
                      v-if="scope.row.record_img_urls"
                    >
                      <img
                        v-for="(item,index) in JSON.parse(scope.row.record_img_urls)"
                        :key="index"
                        :src="item.url"
                        alt=""
                        srcset=""
                        @click="openViewer(JSON.parse(scope.row.record_img_urls),index)"
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <!--  内嵌表格 -->
        <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="realname"
          label="姓名"
          align="center"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.realname }} {{ scope.row.hide_name_status?`（${scope.row.nickname}）`:'' }}
          </template>
        </el-table-column>
        <el-table-column
          label="年级"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            {{scope.row.recruit_year}}年
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              v-if="formateStatus(scope.row,0)"
              type="warning"
            >待确认</el-tag>
            <el-tag
              size="small"
              v-if="formateStatus(scope.row,1)"
              type="success"
            >已确认</el-tag>
            <el-tag
              type="danger"
              size="small"
              v-if="formateStatus(scope.row,2)"
            >已驳回</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="本单课时费"
          align="center "
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ teachers.find(v=>{
              return v.teacher_id== scope.row.id
            }).sign_teacher_lesson_hour_amount ||0}}元/小时
          </template>
        </el-table-column>
        <el-table-column
          label="本单课时"
          align="center "
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ teachers.find(v=>{
              return v.teacher_id== scope.row.id
            }).sign_teacher_lesson_hour ||0}}小时
          </template>
        </el-table-column>
        <el-table-column
          label="录取信息"
          align="center "
          width="500"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{scope.row.recruit_college_name}} | {{scope.row.recruit_academy_name}} | ({{scope.row.recruit_major_code}}){{scope.row.recruit_major_name}} {{ scope.row.recruit_direction_code&&scope.row.recruit_direction_name?` | (${scope.row.recruit_direction_code})${scope.row.recruit_direction_name}`:'' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="手机号"
          align="center"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="wechat"
          label="微信号"
          align="center"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="签约课时费"
          align="center "
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.fee_amount?scope.row.fee_amount:0}}元/小时
          </template>
        </el-table-column>
        <el-table-column
          label="平均课时费"
          align="center "
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.teacher_average_lesson_hour_amount?scope.row.teacher_average_lesson_hour_amount:0}}元/小时
          </template>
        </el-table-column>
        <el-table-column
          label="匹配师"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{ formateAllocation_manage_id(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          label="签约有效期"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.contract_end_time?$moment(scope.row.contract_end_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToDetail(scope.row)"
            >查看详情</el-link>
            <el-link
              v-if="!type"
              type="primary"
              :underline="false"
              @click="handleToConfirm(scope.row)"
            >确认</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <SeniorDetail
      :isVisible="SeniorDetailDialog"
      @close="SeniorDetailDialog=false"
      :id="detailId"
    />
    <ConfirmGradeTeacher
      :isVisible="confirmDialog"
      @close="confirmDialog=false"
      :id="confirmId"
      @submit="fetchDetail"
    />
    <EditMatching
      :isVisible="editDialog"
      @close="editDialog=false"
      :id="editId"
      :teachers="teachers"
      @submit="fetchDetail"
    />
    <MatchingCancle
      :isVisible="cancelDialog"
      @close="cancelDialog=false"
      :id="order_id"
      @submit="fetchDetail"
    />
    <el-image-viewer
      v-if="showViewer"
      :url-list="imgs"
      :zIndex="999999999"
      :on-close="closeViewer"
      :initial-index="initialIndex"
    />
  </div>
</template>
  <script>


import TableTittle from "@/components/TableTittle/TableTittle.vue";
import SeniorDetail from "@/components/DialogComponents/TeacherResources/SeniorDetail.vue";
import ConfirmGradeTeacher from "@/components/DialogComponents/TeacherResources/ConfirmGradeTeacher.vue";
import EditMatching from "@/components/DialogComponents/TeacherResources/EditMatching.vue";
import MatchingCancle from "@/components/DialogComponents/TeacherResources/MatchingCancle.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";




import {
  teacherlist,
  gradelist,
  cancelOrdereTa,
  taOne,
  taRecordList
} from "@/request/api";
export default {
  components: {
    TableTittle,
    SeniorDetail,
    ConfirmGradeTeacher,
    EditMatching,
    MatchingCancle,
    ElImageViewer
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    type:{
      type: Boolean,
      default: false,
    },
    grade_id: {
      type: Number,
      default: null,
    },
    order_id: {
      type: Number,
      default: null,
    },
    teachers: {
      type: Array,
      default: () => [],
    },

    /*   ids: {
        type: String,
        default: '',
      },
      teachers: {
        type: Array,
        default: () => [],
      }, */
  },
  data() {
    return {
      initialIndex:0,
      dialogFormVisible: this.isVisible,
      result: [],
      TableName: '老师列表',
      SeniorDetailDialog: false,
      detailId: null,
      confirmId: null,
      confirmDialog: false,
      editId: null,
      editDialog: false,
      loading: false,
      order: null,
      cancelDialog: false,
      expands: [],
      showViewer: false,
      imgs: [],

    };

  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.expands = []

        this.fetchDetail()
      }
    },
  },
  methods: {
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url,index) {
      this.imgs = url.map(v => {
        return v.url
      })
      this.initialIndex = index
      this.showViewer = true;
    },
    expandChange(row) {
      let params = {
        teacher_allocation_order_id: this.$route.params.id,
        teacher_id: row.id,
        isPaging: 0,
      }
      taRecordList(params).then((response) => {
        let res = response.data
        this.$set(row, 'records', res.data.list)
      });
    },
    fetchOrderDetail() {
      taOne({
        id: this.order_id
      }).then((response) => {
        let res = response.data
        this.order = res.data || [];
      });
    },
    fetchDetail() {
      this.fetchOrderDetail()
      let ids = this.teachers.map(v => {
        return v.teacher_id
      }).join(',')
      if (ids) {
        this.fetchList(ids)
      } else {
        this.result = []
      }
    },
    fetchList(ids) {
      this.loading = true
      const params = {
        isPaging: 0,
        ids: ids,
      }
      teacherlist(params).then((response) => {
        let res = response.data
        this.result = res.data.list || [];
        this.loading = false

      });

    },
    handleToClose() {
      this.$emit("close");
      this.$emit("submit");
    },
    formateStatus(row, status) {
      if (this.teachers.length) {
        let number = this.teachers.find(v => {
          return v.teacher_id == row.id
        }).confirm_status
        if (number == status) {
          return true
        } else {
          return false
        }
      }
    },
    formateAllocation_manage_id(row) {
      var ids = this.teachers && this.teachers.length ? this.teachers.find(v => {
        return v.teacher_id == row.id
      }).allocation_manage_id : ''
      if (ids) {
        let arr = ids.toString().split(',').map(v => {
          return this.$store.state.staffOptions.find(t => {
            return t.id == v
          }).nickname
        })
        return arr.join(',')
      } else {
        return '无'
      }
    },
    Origin() {

    },
    handleToDetail(row) {
      this.detailId = row.id
      this.SeniorDetailDialog = true
    },
    handleToConfirm(row) {
      let id = this.teachers.find(v => {
        return v.teacher_id == row.id
      }).id
      this.confirmId = id
      this.confirmDialog = true
    },
    openEditMatching() {
      this.editId = this.order_id
      this.editDialog = true
    },
    formateAltManager(ids) {
      if (ids) {
        let str = ids.split(',').map(v => {
          return `@${this.$store.state.staffOptions.find(t => {
            return t.id == v
          }).nickname}`
        })

        return str.join(',')
      } else {
        return ''
      }
    },
    cancelMatching() {
      this.cancelDialog = true
      /*      this.$confirm("确定要取消该匹配吗", "提示", {
             confirmButtonText: "确定",
             cancelButtonText: "取消",
             type: "warning",
           }).then(() => {
             cancelOrdereTa({
               order_id: this.order_id,
             }).then((response) => {
               let res = response.data
               if (res.code == 0) {
                 this.$message.success('匹配订单已取消')
                 this.$emit('submit')
                 this.handleToClose()
               }
             });
           }) */
    }
  },
};
            </script>
        <style lang="less" >
.content {
  .between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.el-table__expanded-cell {
  padding: 0px !important;
}
.img-list {
  margin-top: 12px;
  display: flex;
  align-items: center;
  img {
    width: 140px;
    height: 140px;
    margin-right: 12px;
    border-radius: 4px;
  }
}
</style>