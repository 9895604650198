<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>员工</el-breadcrumb-item>
        <el-breadcrumb-item>用户权限</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button
        type="primary"
        @click="opendialogAdd"
        size="small"
      >新建</el-button>
    </div>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ fontSize: '13px' }"
        border
        row-key="id"
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          prop="name"
          label="名称"
          align="left"
          width="300"
          show-overflow-tooltip
        > </el-table-column>
        <el-table-column
          prop="code"
          label="编码"
          show-overflow-tooltip
          width="300"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="url"
          label="路由地址"
          show-overflow-tooltip
          align="center"
          width="300"
        >
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="200"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="类型"
          align="center"
          width="110"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.type == 1">目录</span>
            <span v-show="scope.row.type == 2">菜单</span>
            <span v-show="scope.row.type == 3">按钮</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="状态"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              :type="scope.row.status == 1 ? 'success' : 'danger'"
            >{{
              scope.row.status == 1 ? "启用" : "禁用"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          align="center"
          label="创建时间"
          width="234"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="操作"
          align="center"
          width="360"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="opendialogEdit(scope.row)"
            >编辑</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="primary"
              :underline="false"
              @click="openAddChildrenAuthDialog(scope.row)"
            >添加子级权限</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="danger"
              :underline="false"
              @click="deleteItem(scope.row)"
            >
              删除
            </el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="primary"
              :underline="false"
              @click="move(scope.row,'up',scope.$index)"
            >
              上移
            </el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="primary"
              :underline="false"
              @click="move(scope.row,'down',scope.$index)"
            >
              下移
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <!--       <div class="block">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div> -->
    </el-card>
    <el-dialog
top="5vh"
      title="权限管理"
      :visible.sync="dialogFormVisible"
      width="570px"
    >
      <el-form
        :model="form"
        ref="dialogform"
        :rules="rules"
        label-position="right"
        label-width="110px"
        size="small"
      >
        <el-form-item
          label="类型"
          prop="type"
        >
          <el-radio-group v-model="form.type">
            <el-radio-button :label="1">目录</el-radio-button>
            <el-radio-button :label="2">菜单</el-radio-button>
            <el-radio-button :label="3">按钮</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="名称"
          prop="name"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          label="路由地址"
          v-if="form.type != 1"
        >
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item
          label="权限标识码"
          v-if="form.type != 1"
        >
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item
          label="外链状态"
          prop="link_status"
        >
          <el-radio-group v-model="form.link_status">
            <el-radio-button :label="1">外链</el-radio-button>
            <el-radio-button :label="0">内部</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="隐藏状态"
          prop="hide_status"
        >
          <el-radio-group v-model="form.hide_status">
            <el-radio-button :label="1">隐藏</el-radio-button>
            <el-radio-button :label="0">展示</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item
          label="备注"
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="form.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit(submitmethods)"
        >确 定</el-button>
      </div>
    </el-dialog>
    <AddChildrenAuth
      :content="AddChildrenAuthContent"
      :isVisible="AddChildrenAuthDialog"
      @close="AddChildrenAuthDialog = false"
      @submit="AddChildrenAuthSubmit"
    >
    </AddChildrenAuth>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddChildrenAuth from "../../../components/DialogComponents/AddChildrenAuth/AddChildrenAuth.vue";
import {
  createuserrule,
  userrulelist,
  deleteuserrule,
  edituserrule,
  editrulelist
} from "../../../request/api";
export default {
  components: {
    TableTittle,
    AddChildrenAuth,
  },
  data() {
    return {
      TableName: "权限列表",
      dialogFormVisible: false,
      AddChildrenAuthDialog: false,
      AddChildrenAuthContent: {},
      tableData: [],
      query: {
        isPaging: 0,
      },
      pageTotal: 0,
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        url: [{ required: true, message: "请输入路由地址", trigger: "blur" }],
        code: [
          { required: true, message: "请输入权限标识码", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        link_status: [
          { required: true, message: "请选择外链状态", trigger: "blur" },
        ],
        hide_status: [
          { required: true, message: "请选择隐藏状态", trigger: "blur" },
        ],
        status: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      },
      form: {},
      submitmethods: "add",
      itemOptions: [],
    };
  },
  mounted() {
    this.getList(this.query);
  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    opendialogAdd() {
      this.submitmethods = "add";
      this.form = {};
      this.dialogFormVisible = true;
    },
    opendialogEdit(item) {
      this.submitmethods = "edit";
      this.dialogFormVisible = true;
      this.form = JSON.parse(JSON.stringify(item));
    },
    openAddChildrenAuthDialog(item) {
      this.AddChildrenAuthContent = JSON.parse(JSON.stringify(item));
      this.AddChildrenAuthDialog = !this.AddChildrenAuthDialog;
    },
    getList(query) {
      userrulelist(query).then((res) => {
        this.tableData = res.data.data.list || [];
        this.tableData.forEach(item => {
          this.$set(item, 'level', 1)
          if (item.children && item.children.length) {
            this.itemOptions.push(...item.children)
            item.children.forEach(pitem => {
              this.$set(pitem, 'level', 2)
              if (pitem.children && pitem.children.length) {
                pitem.children.forEach(ppitem => {
                  this.$set(pitem, 'children', pitem.children.sort((item_a, item_b) => item_a.sort - item_b.sort))
                  this.$set(ppitem, 'level', 3)
                })
              }
            })
          }
        });
        this.pageTotal = res.data.data.count;
      });
    },
    AddChildrenAuthSubmit({ form }) {
      createuserrule(form).then((res) => {
        this.$nextTick(() => {
          this.AddChildrenAuthDialog = false;
          this.getList(this.query);
        });
      });
    },
    submit(submitmethods) {
      this.$refs["dialogform"].validate((valid) => {
        if (valid) {
          let query = this.form;
          switch (submitmethods) {
            case "add":
              createuserrule(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false;
                  this.getList(this.query);
                });
              });
              break;
            case "edit":
              edituserrule(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false;
                  this.getList(this.query);
                });
              });
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteItem(item) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            id: item.id,
          };
          deleteuserrule(query).then((res) => {
            this.$notify.success({
              title: "提示",
              message: "删除成功",
            });
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    move(row, mode) {
      if (row.level == 3) {
        let arr = this.itemOptions.find(v => {
          return v.id == row.pid
        }).children || []
        let ind = arr.findIndex(v => {
          return v.id == row.id
        })
        let old = arr.splice(ind, 1)[0]
        if (mode == 'up') {
          arr.splice(ind - 1, 0, old)
        } else if (mode == 'down') {
          arr.splice(ind + 1, 0, old)
        }
        const rows = arr.map((item, sort) => {
          return { id: item.id, sort: sort }
        })
        editrulelist({ rows: rows }).then((res) => {
          this.dialogFormVisible = false;
          this.getList(this.query);
        });
      } else if (row.level == 2) {
        let arr = this.tableData.find(v => {
          return v.id == row.pid
        }).children || []
        let ind = arr.findIndex(v => {
          return v.id == row.id
        })
        let old = arr.splice(ind, 1)[0]
        if (mode == 'up') {
          arr.splice(ind - 1, 0, old)
        } else if (mode == 'down') {
          arr.splice(ind + 1, 0, old)
        }
        const rows = arr.map((item, sort) => {
          return { id: item.id, sort: sort }
        })
        editrulelist({ rows: rows }).then((res) => {
          this.dialogFormVisible = false;
          this.getList(this.query);
        });
      } else if (row.level == 1) {
        let ind = this.tableData.findIndex(v => {
          return v.id == row.id
        })
        let old = this.tableData.splice(ind, 1)[0]
        if (mode == 'up') {
          this.tableData.splice(ind - 1, 0, old)
        } else if (mode == 'down') {
          this.tableData.splice(ind + 1, 0, old)
        }
        const rows = this.tableData.map((item, sort) => {
          return { id: item.id, sort: sort }
        })
        editrulelist({ rows: rows }).then((res) => {
          this.dialogFormVisible = false;
          this.getList(this.query);
        });
      }
    }
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.card-header {
  display: flex;
  justify-content: space-between;
  .el-button {
    height: 30px;
    line-height: 5px;
  }
}
.block {
  margin-top: 20px;
  margin-bottom: 14px;
  float: right;
}
</style>