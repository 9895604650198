<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/tr/order' }">订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单处理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button v-if="detail.hard_dot_status == 1"
                   type="primary"
                   size="small"
                   @click="handleFinished">继续</el-button>
        <el-button type="primary"
                   size="small"
                   @click="checkRecord">沟通记录</el-button>
        <el-button type="primary"
                   size="small"
                   @click="openQuick">快速签单</el-button>
      </div>
    </div>
    <el-card class="card"
             style="margin-top: 28px; position: relative">
      <div class="urgent"
           v-if="detail.urgency_status == 1">
        <img src="@/assets/images/urgent.png"
             alt=""
             srcset="" />
      </div>
      <div class="header">
        <TableTittle :title="pageTitle"></TableTittle>
        <div class="teacher-count"
             v-if="
            detail.earn_amount_statistic_obj &&
            (detail.earn_amount_statistic_obj.hour_income_company_amount ||
              detail.fix_hour_income_company_amount)
          ">
          签约单价{{
            detail.fix_hour_income_company_amount
              ? detail.fix_hour_income_company_amount
              : detail.earn_amount_statistic_obj.hour_income_company_amount
              ? detail.earn_amount_statistic_obj.hour_income_company_amount
              : 0
          }}元
        </div>
        <div class="teacher-count"
             v-if="$route.query.peopleNum">
          学生{{ $route.query.peopleNum }}人
        </div>
        <div class="teacher-count">
          师资{{ detail.count_teacher_need || 0 }}位
        </div>
      </div>
      <el-descriptions class="margin-top"
                       :column="2"
                       border
                       :labelStyle="{ width: '120px' }">
        <el-descriptions-item label="报考信息"
                              labelClassName="my-label"
                              contentClassName="my-content">
          <el-tooltip class="item"
                      effect="dark"
                      :content="getText()"
                      placement="top-start">
            <div class="overflow-text">
              {{ detail.apply_college_name }} |
              {{ detail.apply_academy_name }} | ({{
                detail.apply_major_code
              }}){{ detail.apply_major_name }}
              {{
                detail.apply_direction_code && detail.apply_direction_name
                  ? ` |
            (${detail.apply_direction_code})${detail.apply_direction_name}`
                  : ""
              }}

            </div>
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="辅导科目"
                              contentClassName="my-content"
                              label-class-name="my-label">
          <el-tooltip class="item"
                      effect="dark"
                      :content="formateSubjects(detail)"
                      placement="top-start">
            <div class="overflow-text">
              <span>{{ formateSubjects(detail) }}</span>
            </div>
          </el-tooltip>
        </el-descriptions-item>
        <!-- <el-descriptions-item label="参考书目" contentClassName="my-content" label-class-name="my-label">
          <div class="overflow-text">
            {{ detail.apply_reference_book_names || '无' }}
          </div>
        </el-descriptions-item> -->
        <el-descriptions-item label="学生要求"
                              contentClassName="my-content"
                              label-class-name="my-label">
          <el-tooltip class="item"
                      effect="dark"
                      :content="detail.allocation_demand ? `${detail.allocation_demand}` : '无'"
                      placement="top-start">
            <div class="overflow-text">
              {{
                detail.allocation_demand ? `${detail.allocation_demand}` : "无"
              }}
            </div>
          </el-tooltip>
        </el-descriptions-item>
      </el-descriptions>
      <AdmixtureTable :admixtureData="admixtureData" />
    </el-card>

    <el-card style="margin-top: 28px">
      <div class="card-header">
        <TableTittle title="老师列表"></TableTittle>
        <el-form size="small"
                 :inline="true"
                 :model="query">
          <el-form-item label-width="32px">
            <el-radio-group v-model="query.handle_teacher_allocaton_type"
                            @change="change">
              <el-radio-button :label="1">同校同专业</el-radio-button>
              <el-radio-button :label="2">同校同科目</el-radio-button>
              <el-radio-button :label="3">统考科目</el-radio-button>
              <el-radio-button :label="4">同参考书</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-popover placement="bottom-end"
                      width="800"
                      trigger="click"
                      ref="popoverSH">
            <div>
              <div style="
                  border-bottom: #dcdfe6 1px solid;
                  padding: 16px;
                  margin-bottom: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                ">
                <div style="font-size: 16px; font-weight: 600; color: #303133">
                  筛选条件
                </div>
                <div class="action-wrap">
                  <el-button type="primary"
                             @click="handleSearch"
                             size="small">搜索</el-button>
                  <el-button @click="handleFormReset('searchForm')"
                             size="small">重置</el-button>
                </div>
              </div>
              <el-form class="search-wrap"
                       ref="searchForm"
                       :inline="true"
                       :model="query"
                       size="small"
                       label-position="right"
                       label-width="100px">
                <el-form-item label="确认状态："
                              prop="confirm_statuses">
                  <el-select v-model="query.confirm_statuses"
                             placeholder="请选择确认状态"
                             style="width: 250px"
                             clearable>
                    <el-option label="已确认"
                               :value="1"></el-option>
                    <el-option label="未确认"
                               :value="0"></el-option>
                    <el-option label="未录取"
                               :value="-1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="签约状态："
                              prop="contract_statuses">
                  <el-select v-model="query.contract_statuses"
                             placeholder="请选择签约状态"
                             style="width: 250px"
                             clearable>
                    <el-option label="已签约"
                               :value="1"></el-option>
                    <el-option label="未签约"
                               :value="0"></el-option>
                    <el-option label="已过期"
                               :value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="录取年份："
                              prop="recruit_years">
                  <el-select v-model="query.recruit_years"
                             placeholder="请选择录取年份"
                             style="width: 250px"
                             clearable>
                    <el-option label="2019年"
                               :value="2019"></el-option>
                    <el-option label="2020年"
                               :value="2020"></el-option>
                    <el-option label="2021年"
                               :value="2021"></el-option>
                    <el-option label="2022年"
                               :value="2022"></el-option>
                    <el-option label="2023年"
                               :value="2023"></el-option>
                    <el-option label="2024年"
                               :value="2024"></el-option>
                    <el-option label="2025年"
                               :value="2025"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="学长/学姐：">
                  <el-input v-model.trim="query.keyword"
                            placeholder="请输入姓名/手机号"
                            clearable
                            style="width: 250px"></el-input>
                </el-form-item>
                <el-form-item label="院校：">
                  <el-select style="width: 250px"
                             @clear="collegeClear"
                             @change="collegeChoose"
                             v-model.trim="query.college_code"
                             filterable
                             collapse-tags
                             placeholder="请选择"
                             clearable>
                    <el-option v-for="(item, index) in $store.state.collegeList"
                               :key="index"
                               :label="item.name"
                               :value="item.code">
                    </el-option>
                  </el-select>
                  <!-- <el-input v-model.trim="query.college" placeholder="请输入院校名/代码" style="width:250px;"
                    clearable></el-input> -->
                </el-form-item>
                <el-form-item label="学院：">
                  <el-select v-model="query.academy_code"
                             placeholder="请选择学院"
                             clearable
                             style="width: 100%">
                    <el-option v-for="(item, index) in academyOptions"
                               :key="index"
                               :label="item.academy_name"
                               :value="item.academy_code">
                      <span>{{
                        `（${item.academy_code}）${item.academy_name}`
                      }}</span>
                    </el-option>
                  </el-select>
                  <!-- <el-input v-model.trim="query.academy" placeholder="请输入学院名/代码" style="width:250px;"
                    clearable></el-input> -->
                </el-form-item>
                <el-form-item label="专业：">
                  <el-select @clear="academyClear"
                             @change="majorChange"
                             filterable
                             placeholder="请选择"
                             v-model.trim="query.major_code"
                             clearable
                             style="width: 250px">
                    <el-option v-for="(item, index) in majorOptions"
                               :key="index"
                               :label="item.allnames"
                               :value="item.code">
                    </el-option>
                  </el-select>
                  <!-- <el-input v-model.trim="query.major" placeholder="请输入专业名/代码" style="width:250px;" clearable></el-input> -->
                </el-form-item>
                <el-form-item label="科目：">
                  <el-input v-model.trim="query.test_subject_keyword"
                            placeholder="请输入初试科目"
                            style="width: 250px"
                            clearable></el-input>
                </el-form-item>
                <el-form-item label="复试：">
                  <el-input v-model.trim="query.second_test_subject_keyword"
                            placeholder="请输入复试科目"
                            style="width: 250px"
                            clearable></el-input>
                </el-form-item>
                <el-form-item label="辅导书籍：">
                  <el-input v-model.trim="query.reference_book_keyword"
                            placeholder="请输入辅导书籍"
                            style="width: 250px"
                            clearable></el-input>
                </el-form-item>
                <!-- <el-form-item label="初试科目：">
                  <el-input v-model.trim="query.test_subject_keyword" placeholder="请输入初试科目" style="width:250px;"
                    clearable></el-input>
                </el-form-item> -->
                <!--    <el-form-item
                  label="院校："
                  prop="college_composite_keyword"
                >
                  <el-select
                    v-model="query.college_composite_keyword"
                    placeholder="请选择院校名称"
                    filterable
                    clearable
                    style="width: 100%;"
                    @change="collegeChange"
                  >
                    <el-option
                      v-for="(item,index) in $store.state.collegeList"
                      :key="index"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="学长/学姐：">
                  <el-input
                    v-model.trim="query.keyword"
                    placeholder="请输入姓名/手机号"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="初试科目："
                  prop="test_subjects"
                >
                  <el-select
                    style="width:300px;"
                    v-model="query.test_subjects"
                    placeholder="请选择初试科目"
                    clearable
                    multiple
                    @change="$forceUpdate()"
                  >
                    <el-option
                      v-for="(item,index) in subjectOptions"
                      :key="index"
                      :label="item.subject_name"
                      :value="{ value: item.subject_code, label: item.subject_name}"
                    >
                      <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="辅导书籍："
                  prop="test_subjects"
                >
                  <el-select
                    style="width:100%;"
                    v-model="query.test_subjects"
                    placeholder="请选择初试科目"
                    clearable
                    multiple
                    @change="$forceUpdate()"
                  >
                    <el-option
                      v-for="(item,index) in subjectOptions"
                      :key="index"
                      :label="item.subject_name"
                      :value="{ value: item.subject_code, label: item.subject_name}"
                    >
                      <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
                    </el-option>
                  </el-select>
                </el-form-item> -->
              </el-form>
            </div>
            <el-button slot="reference"
                       size="small"
                       :class="query.handle_teacher_allocaton_type ? '' : 'bg-409EFF'">所有筛选</el-button>
          </el-popover>
        </el-form>
      </div>
      <el-table :data="result"
                row-key="id"
                :expand-row-keys="expands"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
        }"
                @expand-change="expandChange"
                v-loading="loading"
                border>
        <!--  内嵌表格 -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="expand-wrap">
              <el-table :data="scope.row.records ? scope.row.records : []"
                        :border="false"
                        :header-cell-style="{
                  backgroundColor: '#f9f9f9',
                }">
                <el-table-column label="日期"
                                 align="center"
                                 width="150">
                  <template slot-scope="scope">
                    {{
                      scope.row.create_time
                        ? $moment(scope.row.create_time).format(
                            "YYYY-MM-DD HH:mm"
                          )
                        : "无"
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="记录人"
                                 align="center"
                                 width="100">
                  <template slot-scope="scope">
                    {{
                      scope.row.manage_creator
                        ? scope.row.manage_creator.nickname
                        : "无"
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="类别"
                                 align="center"
                                 width="80">
                  <template slot-scope="scope">
                    <el-tag size="small"
                            v-if="scope.row.type == 1"
                            type="success">分配</el-tag>
                    <el-tag size="small"
                            v-if="scope.row.type == 2">备注</el-tag>
                    <el-tag size="small"
                            v-if="scope.row.type == 3">修改</el-tag>
                    <el-tag size="small"
                            v-if="scope.row.type == 4"
                            type="warning">跟进</el-tag>
                    <el-tag size="small"
                            v-if="scope.row.type == 5">签单</el-tag>
                    <el-tag size="small"
                            v-if="scope.row.type == 6"
                            type="warning">申请</el-tag>
                    <el-tag size="small"
                            v-if="scope.row.type == 7"
                            type="success">沟通</el-tag>
                    <el-tag size="small"
                            v-if="scope.row.type == 8">记录</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="沟通记录"
                                 align="left"
                                 show-overflow-tooltip
                                 prop="record">
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <!--  内嵌表格 -->

        <el-table-column label="沟通记录"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToRecord(scope.row)">所有记录</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="ID"
                         align="center"
                         width="80">
        </el-table-column>
        <el-table-column prop="realname"
                         label="姓名"
                         align="center"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.realname
            }}{{
              scope.row.hide_name_status ? `（${scope.row.nickname}）` : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="性别"
                         align="center"
                         width="50"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-show="scope.row.gender == 1">男</span>
            <span v-show="scope.row.gender == 2">女</span>
            <span v-show="scope.row.gender == 0">未知</span>
          </template>
        </el-table-column>
        <el-table-column label="年级"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            {{ scope.row.recruit_year }}年
          </template>
        </el-table-column>
        <!--      <el-table-column
          label="初试匹配度"
          align="center"
          width="120"
        >
          <template>
            <el-link
              type="primary"
              :underline="false"
            >部分匹配</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="复试匹配度"
          align="center"
          width="120"
        >
          <template>
            <el-link
              type="primary"
              :underline="false"
            >未知</el-link>
          </template>
        </el-table-column> -->
        <el-table-column label="确认状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.confirm_status == 1"
                    type="success">已确认</el-tag>
            <el-tag size="small"
                    v-if="scope.row.confirm_status == 0">未确认</el-tag>
            <el-tag type="danger"
                    size="small"
                    v-if="scope.row.confirm_status == -1">未录取</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="签约状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.contract_status == 1"
                    type="success">已签约</el-tag>
            <el-tag size="small"
                    v-if="scope.row.contract_status == 0"
                    type="warning">未签约</el-tag>
            <el-tag size="small"
                    v-if="scope.row.contract_status == 2"
                    type="danger">已过期</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="录取信息"
                         align="left"
                         width="350"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.recruit_college_name }} |
              {{ scope.row.recruit_academy_name }} | ({{
                scope.row.recruit_major_code
              }}){{ scope.row.recruit_major_name }}
              {{
                scope.row.recruit_direction_code &&
                scope.row.recruit_direction_name
                  ? ` |
              (${scope.row.recruit_direction_code})${scope.row.recruit_direction_name}`
                  : ""
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="初试科目"
                         align="left"
                         width="350"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ formateSubjectsOther(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="初试总分"
                         align="center "
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.initial_test_score }}分
          </template>
        </el-table-column>
        <el-table-column label="科目分数"
                         align="center"
                         width="260"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateScore(scope.row.test_subject_scores) }}
          </template>
        </el-table-column>
        <el-table-column label="参考书目"
                         align="center"
                         width="260"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateReference_book(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="复试科目"
                         align="center "
                         width="300"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.second_test_subject_names || "无" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="录取类型"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.recruit_type == 1">一志愿</span>
            <span v-if="scope.row.recruit_type == 2">调剂</span>
          </template>
        </el-table-column>

        <el-table-column label="平均课时费"
                         align="center "
                         width="110"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.teacher_average_lesson_hour_amount
                ? scope.row.sign_order_lesson_hour_amount
                : 0
            }}元/小时
          </template>
        </el-table-column>
        <el-table-column label="签单课时费"
                         align="center "
                         width="110"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.sign_order_lesson_hour_amount
                ? scope.row.sign_order_lesson_hour_amount
                : 0
            }}元/小时
          </template>
        </el-table-column>
        <el-table-column label="综合评分"
                         width="80"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              scope.row.teacher_average_student_apprise_score
                ? scope.row.teacher_average_student_apprise_score.toFixed(1) +
                  "分"
                : "暂无"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="微信添加"
                         width="200"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateWechatManager(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="首次签约人"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateFirstManager(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="签约有效期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.contract_end_time
                ? $moment(scope.row.contract_end_time).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         fixed="right"
                         align="center"
                         width="220">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToDetail(scope.row)">详情</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToLog(scope.row, '记录')">记录</el-link>
            <!-- <el-link
              type="primary"
              :underline="false"
              @click="handleToConfirm(scope.row.id)"
            >确认</el-link> -->
            <el-link type="primary"
                     :underline="false"
                     @click="handleToConfirm(scope.row.id)">确认</el-link>
            <el-link type="primary"
                     @click="handleToSign(scope.row.id)"
                     :underline="false">签约</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToLog(scope.row, '签单')">签单</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <div style="margin-right: 16px">
            共{{ otherCount }}条<span v-if="Number(otherCount) > 100">,涉及隐私仅显示前100条</span>
          </div>
          <el-pagination background
                         layout="prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>

    <Log :isVisible="logDialog"
         :price="detail.earn_amount_statistic_obj.hour_income_company_amount || 0"
         :id="logId"
         :written="true"
         :teacher_id="teacher_id"
         :order_id="Number(this.$route.params.id)"
         @close="logDialog = false"
         @submit="fetchList(false, true)"
         :isSign="isSign"></Log>
    <ConfirmSenior :isVisible="editDialog"
                   :id="editId"
                   @close="editDialog = false"
                   @submit="fetchList(false, true)">
    </ConfirmSenior>
    <!--   :teacher_allocation_order_id="Number(this.$route.params.id)" -->
    <Record :teacherNo="teacherNo"
            :flag="flag"
            :written="written"
            :teacher_id="teacher_id"
            :type="type"
            :isVisible="recordDialog"
            :teacher_allocation_order_id="Number(this.$route.params.id)"
            @close="recordDialog = false"
            @submit="fetchListData()"></Record>

    <!-- <Record :teacher_allocation_order_id="orderId" :isVisible="recordDialog" @close="recordDialog = false"
      @submit="fetchList" :type="'order_read'"></Record> -->

    <Sign :isVisible="signDialog"
          :id="signId"
          @close="signDialog = false"
          @submit="fetchList(false, true)"></Sign>
    <SignQuick :price="
        detail.fix_hour_income_company_amount
          ? detail.fix_hour_income_company_amount
          : detail.earn_amount_statistic_obj.hour_income_company_amount
          ? detail.earn_amount_statistic_obj.hour_income_company_amount
          : 0
      "
               :isVisible="quickDialog"
               @close="quickDialog = false"
               @submit="fetchList(false, true)"></SignQuick>
    <SeniorDetail :isVisible="SeniorDetailDialog"
                  @close="SeniorDetailDialog = false"
                  :id="detailId" />
  </div>
</template>
<script>
import TableTittle from '@/components/TableTittle/TableTittle.vue'
import Log from '@/components/DialogComponents/TeacherResources/Log.vue'
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'
import ConfirmSenior from '@/components/DialogComponents/TeacherResources/ConfirmSenior.vue'
import Sign from '@/components/DialogComponents/TeacherResources/Sign.vue'
import SeniorDetail from '@/components/DialogComponents/TeacherResources/SeniorDetail.vue'
import SignQuick from '@/components/DialogComponents/TeacherResources/SignQuick.vue'

import AdmixtureTable from '@/components/DialogComponents/AdmixtureTable/index.vue'
import { adminUrl } from '@/utils/helper'

import {
  taOne,
  subjectlist,
  teacherlist,
  taRecordList,
  teacherAllocationOrder,
  majorlist,
} from '@/request/api'
export default {
  components: {
    TableTittle,
    Log,
    ConfirmSenior,
    Record,
    Sign,
    SignQuick,
    SeniorDetail,
    AdmixtureTable,
  },
  data() {
    return {
      teacherNo: true,
      flag: true,
      written: true,
      type: 'teacher_read',
      majorOptions: [],
      isSign: false,
      result: [],
      detail: {},
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        handle_teacher_allocaton_type: 1,
        archive: '0',
        college_code: '',
        is_order_score: 1,
      },
      subjectOptions: [],
      academyOptions: [],
      pageTotal: 0,
      logDialog: false,
      logId: null,
      expands: [],
      editDialog: false,
      editId: null,
      teacher_id: null,
      recordDialog: false,
      signDialog: false,
      signId: null,
      quickDialog: false,
      labelStyle: {
        width: '170px',
        border: 'solid',
      },
      loading: false,
      otherCount: 0,
      SeniorDetailDialog: false,
      detailId: null,
      pageTitle: '',
      admixtureData: {},
    }
  },
  created() {
    this.fetchDetail()
    this.fetchList()
    this.$store.dispatch('getAllColleges')
    this.$store.dispatch('getStaffOptions')
  },
  computed: {},
  methods: {
    getText() {
      let direction = ''
      if (
        this.detail.apply_direction_code &&
        this.detail.apply_direction_name
      ) {
        direction = ` |(${this.detail.apply_direction_code})${this.detail.apply_direction_name}`
      }

      return `${this.detail.apply_college_name}|${this.detail.apply_academy_name}|(${this.detail.apply_major_code})|${this.detail.apply_major_name}
        ${direction}`
    },
    fetchListData() {},
    academyClear() {
      this.$set(this.query, 'academy_code', '')
      this.academyOptions = []
    },
    majorChange(e) {
      // console.log("e",e)
      this.$set(this.query, 'academy_code', '')
      this.academyOptions = []
      if (e) {
        this.getMajorInfo(this.query.college_code, e)
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get', //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        // console.log("libie", response);
        let res = response.data
        this.academyOptions = res.data.academys
      })
    },
    collegeClear() {
      this.$set(this.query, 'academy_code', '')
      this.academyOptions = []
      this.$set(this.query, 'major_code', '')
      this.majorOptions = []
    },
    collegeChoose(e) {
      this.collegeClear()
      if (!e) {
        return
      }

      let query = {
        isPaging: 0,
        college_code: e,
      }
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          }
        })
      })
    },

    async change(e) {
      // console.log("E", e);
      this.$set(this.query, 'college_code', '')
      this.$set(this.query, 'major_code', '')
      this.$set(this.query, 'academy_code', '')
      this.$set(this.query, 'test_subject_keyword', '')
      if (e == 1) {
        this.$set(this.query, 'college_code', this.detail.apply_college_code)
        await this.collegeChoose(this.detail.apply_college_code)
        this.$set(this.query, 'major_code', this.detail.apply_major_code)
        await this.majorChange(this.detail.apply_major_code)
        // this.$set(this.query, 'academy_code', this.detail.apply_academy_code)
      } else if (e == 2) {
        this.$set(this.query, 'college_code', this.detail.apply_college_code)
        this.$set(
          this.query,
          'test_subject_keyword',
          this.detail.apply_subject_names
        )
      }
      // console.log("uq", this.query);
      this.fetchList(true)
    },
    handleFinished(row) {
      this.$confirm('订单是否继续匹配？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          teacherAllocationOrder({
            id: this.$route.params.id,
            hard_dot_status: 0,
          }).then((res) => {
            // console.log(res,'res')
            if (res.data.code == 0) {
              this.fetchDetail()
              // this.fetchList()
              this.$message({
                type: 'success',
                message: '修改成功!',
              })
            }
            //
            //
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    checkRecord() {
      this.teacherNo = false
      this.flag = false
      this.written = false
      this.type = 'order_read'
      this.teacher_id = null
      this.recordDialog = true
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      if (this.query.handle_teacher_allocaton_type) {
        this.fetchList()
      } else {
        this.fetchList(false, true)
      }
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      if (this.query.handle_teacher_allocaton_type) {
        this.fetchList()
      } else {
        this.fetchList(false, true)
      }
      // this.fetchList(false,true);
    },
    fetchList(isReset = false, searchFlag) {
      this.loading = true
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      this.expands = []

      let params = {}
      if (searchFlag) {
        params = Object.assign({}, this.query, {
          test_subjects: null,
        })
      } else {
        params = {
          pageIndex: this.query.pageIndex,
          pageSize: this.query.pageSize,
          handle_teacher_allocaton_type:
            this.query.handle_teacher_allocaton_type,
          handle_teacher_allocaton_order_id: this.$route.params.id,
          archive: this.query.archive,
          isPaging: this.query.isPaging,
          test_subjects: null,
          is_order_score: 1,
        }
      }
      // let params = Object.assign({}, this.query, {
      //   test_subjects: null,
      //   handle_teacher_allocaton_order_id:this.$route.params.id
      // });

      teacherlist(params).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        if (res.data.count > 100) {
          this.otherCount = res.data.count
          this.pageTotal = 100
        } else {
          this.otherCount = res.data.count
          this.pageTotal = res.data.count || 0
        }
        this.loading = false
      })
    },
    fetchDetail() {
      taOne({ id: this.$route.params.id, is_statistic_company_earn: 1 }).then(
        (response) => {
          let res = response.data
          this.detail = res.data
          this.admixtureData = {
            enrol_type: this.detail.enrol_type,
            years: this.detail.apply_year,
            direction_code: this.detail.apply_direction_code,
            direction_name: this.detail.apply_direction_name,
            college_code: this.detail.apply_college_code,
            major_code: this.detail.apply_major_code,
            academy_code: this.detail.apply_academy_code,
          }
          this.pageTitle = `${
            this.$route.query.name ? this.$route.query.name + '-' : ''
          }${this.detail.apply_year}考研-${this.detail.lesson_hours}小时`
          // this.$set(this.query, 'college', this.detail.apply_college_name)
          // this.$set(this.query, 'major', this.detail.apply_major_code)
          // this.$set(this.query, 'academy', this.detail.apply_academy_name)
          this.change(1)
        }
      )
    },
    fetchSubjectList(college_code) {
      subjectlist({ college_code: college_code, isPaging: 0 }).then(
        (response) => {
          let res = response.data
          this.subjectOptions = res.data.list
        }
      )
    },
    handleToClose() {
      this.dialog = false
    },
    formateSubjects(row) {
      let arr = row.apply_subject_names
        ? row.apply_subject_names.split(',')
        : []
      let code_arr = row.apply_subject_codes
        ? row.apply_subject_codes.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `(${code_arr[i]})${v}`
            })
            .join('+')
        : '无'
    },
    formateSubjectsOther(row) {
      let arr = row.test_subject_names ? row.test_subject_names.split(',') : []
      let code_arr = row.test_subject_codes
        ? row.test_subject_codes.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `(${code_arr[i]})${v}`
            })
            .join('+')
        : '无'
    },
    formateSecondSubjects(row) {
      let arr = row.second_test_subject_names
        ? row.second_test_subject_names.split(',')
        : []
      let code_arr = row.second_test_subject_codes
        ? row.second_test_subject_codes.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `(${code_arr[i]})${v}`
            })
            .join('+')
        : '无'
    },
    collegeChange(e) {
      if (e) {
        this.fetchSubjectList(e)
      }
    },
    handleSearch() {
      if (this.query.test_subjects) {
        this.query.test_subject_codes_search = this.query.test_subjects
          .map((v) => {
            return v.value
          })
          .join(',')
        this.query.test_subject_names_search = this.query.test_subjects
          .map((v) => {
            return v.label
          })
          .join(',')
      }

      this.$set(this.query, 'handle_teacher_allocaton_type', '')
      this.fetchList(true, true)
      this.$refs.popoverSH.doClose()
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields()
      this.fetchList(true)
    },
    formateScore(score) {
      if (score) {
        let str = score.split(',')
        return str
          .map((v) => {
            return v + '分'
          })
          .join(' | ')
      } else {
        return '无'
      }
    },
    formateReference_book(row) {
      let arr = row.reference_book_names
        ? row.reference_book_names.split(',')
        : []
      let infos = row.reference_book_extra_infos
        ? row.reference_book_extra_infos.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `${v}，${infos[i]}`
            })
            .join(',')
        : '无'
    },
    formateWechatManager(row) {
      if (row.wechat_add_manage_ids) {
        let id = row.wechat_add_manage_ids
        let idList = id.split(',')
        let nickname = ''
        idList.forEach((item) => {
          this.$store.state.staffOptions.forEach((v) => {
            if (v.id == item) {
              nickname = nickname + ' | ' + v.nickname
            }
          })
        })
        return nickname.substring(2, nickname.length)
      } else {
        return '无'
      }
    },
    formateFirstManager(row) {
      if (row.first_contract_manage_id) {
        let id = row.first_contract_manage_id
        if (this.$store.state.staffOptions.length > 0) {
          return this.$store.state.staffOptions.find((v) => {
            return v.id == id
          }).nickname
        } else {
          return '无'
        }
      } else {
        return '无'
      }
    },
    handleToLog(row, title) {
      if (title == '签单') {
        this.isSign = true
      } else {
        this.isSign = false
      }
      this.teacher_id = row.id
      this.logId = row.id
      this.logDialog = true
    },
    openExpand(row) {
      let ind = this.expands.findIndex((v) => {
        return v == row.id
      })
      if (ind != -1) {
        this.expands.splice(ind, 1)
      } else {
        this.expands.push(row.id)
        let params = {
          teacher_allocation_order_id: this.$route.params.id,
          teacher_id: row.id,
          isPaging: 0,
        }
        taRecordList(params).then((response) => {
          let res = response.data
          this.$set(row, 'records', res.data.list)
        })
      }
    },

    handleToConfirm(id) {
      window.open(`/tr/order/confirm/${id}`)
    },
    expandChange(row) {
      let params = {
        teacher_allocation_order_id: this.$route.params.id,
        teacher_id: row.id,
        isPaging: 0,
      }
      taRecordList(params).then((response) => {
        let res = response.data
        this.$set(row, 'records', res.data.list)
      })
    },
    handleToRecord(row) {
      this.teacherNo = true
      this.flag = true
      this.written = true
      this.type = 'teacher_read'
      this.teacher_id = row.id
      this.recordDialog = true
    },
    handleToSign(id) {
      this.signId = id
      this.signDialog = true
    },
    openQuick() {
      this.quickDialog = true
    },
    handleToDetail(row) {
      this.detailId = row.id
      this.SeniorDetailDialog = true
    },
  },
}
</script>
    
<style lang="less" scoped>
.card {
  /deep/.el-descriptions .is-bordered {
    table-layout: fixed !important;
  }
}

.bg-409EFF {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409EF !important;
}

/deep/.el-button:focus,
.el-button:hover {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409EF !important;
}

// .reference{

// }
.content {
  .popover-header {
  }

  .my-label {
    width: 90px;
  }

  .my-content {
    width: 50%;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: start;
    .teacher-count {
      margin-left: 12px;
      margin-bottom: 16px;
      font-size: 12px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e34d59;
      border: #e34d59 2px solid;
      border-radius: 4px;
      padding: 2px 4px;
    }
  }

  /deep/.el-descriptions-item__label {
    white-space: nowrap;
  }

  .expand-wrap {
    .el-table__cell {
      background-color: #f9f9f9;
    }
  }

  .el-table__expanded-cell {
    padding: 0px !important;
  }

  .urgent {
    position: absolute;
    top: 16px;
    right: 16px;

    img {
      width: 86px;
      height: 68px;
    }
  }

  .divider {
    border-bottom: 1px dashed #dcdfe6;
    margin: 15px 0;
  }

  .action-wrap {
    display: flex;
    justify-content: flex-end;
  }

  .my-label {
    white-space: nowrap;
    width: 60px;
  }

  .overflow-text {
    width: 100%;
    background: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .timeline-wrap {
    height: 274px;
    overflow-y: auto;

    .timeline-item {
      height: 57px;
      background: rgba(225, 229, 237, 0.3);
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
    }
  }

  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}
</style>
    