<template>
  <el-dialog
top="5vh"
    title="开具发票"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="432px"
    @close="emitClose"
  >
    <el-form
      :model="form"
       :rules="rules"
      ref="formRules"
      label-position="right"
      label-width="130px"
      size="small"
      v-if="type == 1"
    >
    <!--  action="http://crm-api.kaoyanbox.net/api/v1/upload/file" -->
      <el-form-item label="" label-width="10px" prop="agreement">
        <el-upload
          class="upload-demo"
          action ="https://manage-api.yanshizi.com/api/v1/upload/file"
          :limit="1"
          :file-list="fileList"
          :on-success="handleSuccessFile"
          :on-exceed="handleExceed"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <el-form
      :model="form"
      label-position="right"
      label-width="130px"
      size="small"
      ref="formRules"
      :rules="rules"
      v-if="type == 2"
    >
      <el-form-item label="快递名称" label-width="80px" prop="express_code">
        <el-select
          v-model="form.express_code"
          placeholder="请选择"
          style="width: 322px"
        >
          <el-option
            v-for="item in $store.state.ExpressOptions"
            :key="item.id"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="快递单号" prop="express_no" label-width="80px">
        <el-input
          v-model="form.express_no"
          clearable
          style="width: 322px"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="emitClose">取 消</el-button>
      <el-button type="primary" @click="submit('formRules')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {} from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
    invoicetype: {
      type: Number,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      form: {},
      billInfo: this.content,
      type: this.invoicetype,
      fileList:[],
      rules: {
        agreement:[
           { required: true, message: "发票不能为空", trigger: "blur" },
        ],
        express_code: [
          { required: true, message: "请选择快递名称", trigger: "blur" },
        ],
        express_no: [
          { required: true, message: "请输入快递单号", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.fileList = []
      this.form = {}
      this.dialogFormVisible = val;
    },
    content(val) {
      this.billInfo = val;
    },
    invoicetype(val) {
      this.type = val;
      console.log(val);
    },
  },
  methods: {
    // 文件上传成功处理
    handleSuccessFile(response, file, fileList) {
      console.log(response, file, fileList)
      this.$set(this.form,'agreement',fileList[0].response.data.url)
    //  this.form.agreement = fileList[0].response.data.url; 
    },
    // 文件超出一个
    handleExceed(files, fileList) {
      this.$notify({
        title: "提示",
        message: "只能上传一个文件",
        type: "warning",
      });
    },
    submit(rules) {
      let query = {
        ...this.billInfo,
        ...this.form,
      };

      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.$emit("submit", { form: query });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    emitClose() {
      this.form = {};
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
</style>