<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item>报录查询</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button type="primary" size="small" @click="openCreateDialog"
          >新建</el-button
        >
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="年份">
          <el-select
            v-model="query.years"
            placeholder="请选择年份"
            style="width: 200px"
            clearable
            @change="getDataList(true)"
          >
            <el-option
              v-for="(item, index) in yearsList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报考院校：">
          <el-select
            filterable
            v-model="query.college_code"
            placeholder="请选择院校"
            style="width: 200px"
            clearable
            @change="collegeChoose"
          >
            <el-option
              v-for="item in $store.state.collegeList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业" prop="apply_major">
          <el-select
            filterable
            style="width: 200px"
            v-model="query.major_code"
            placeholder="请选择专业"
            @change="majorChange"
            clearable
          >
            <el-option
              v-for="(item, index) in majorOptions"
              :key="index"
              :label="item.allnames"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学院" prop="apply_major">
          <el-select
            filterable
            v-model="query.academy_code"
            placeholder="请选择学院"
            @change="getDataList(true)"
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="(item, index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="item.academy_code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="参考书目">
          <el-input
            style="width: 200px"
            v-model.trim="query.reference_book_keyword"
            placeholder="请输入参考书目"
            @input="getDataList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="创建人">
          <el-select
            filterable
            @change="getDataList(true)"
            v-model="query.manage_creators"
            multiple
            placeholder="请选择"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="time"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm"
            :default-time="['00:00:00', '23:59:59']"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="getDataList(true)"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table
        @selection-change="handleSelectionChange"
        :data="dataList"
        :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
        :cell-style="{ fontSize: '13px' }"
        border
      >
        <el-table-column type="selection" width="50" align="center">
        </el-table-column>
        <el-table-column prop="id" label="ID" align="center" width="80">
        </el-table-column>
        <el-table-column prop="year" label="年份" align="center" width="80">
          <template slot-scope="scope"> {{ scope.row.year }}年 </template>
        </el-table-column>
        <el-table-column
          prop="college_name"
          label="院校"
          align="left"
          :show-overflow-tooltip="true"
          width="120"
        >
          <template slot-scope="scope">
            <span class="pointer" @click="institutionOpen(scope.row)">
              {{ scope.row.college_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="major_name"
          label="专业"
          align="left"
          width="180"
        >
          <template slot-scope="scope">
            {{ `(${scope.row.major_code})${scope.row.major_name}` }}
          </template>
        </el-table-column>
        <el-table-column
          prop="academy_name"
          label="学院"
          :show-overflow-tooltip="true"
          align="left"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="academy_name"
          :show-overflow-tooltip="true"
          label="方向"
          align="left"
        >
          <template slot-scope="scope">
            {{ scope.row.directions ? scope.row.directions : "不限" }}
          </template>
        </el-table-column>

        <!-- <el-table-column
          prop="plan_enrol_count"
          label="拟招生"
          align="center"
          width="100"
        >
        </el-table-column> -->
        <el-table-column
          prop="user_count_first"
          label="一志愿招生人数"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            {{
              (scope.row.user_count_first||scope.row.user_count_first==0)
                ? `${scope.row.user_count_first}人`
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="high_score"
          label="一志愿最高分"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.high_score ? `${scope.row.high_score}分` : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="low_score"
          label="一志愿最低分"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.low_score ? `${scope.row.low_score}分` : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="user_count_adjust"
          label="调剂人数"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            {{
              (scope.row.user_count_adjust||scope.row.user_count_adjust==0)
                ? `${scope.row.user_count_adjust}人`
                : "-"
            }}
          </template>
        </el-table-column>
         <el-table-column
          prop="second_exam_subject_names"
          label="复试科目"
          show-overflow-tooltip
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="second_exam_reference_books"
          label="复试参考书"
          show-overflow-tooltip
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="academy_name"
          label="参考书目"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.reference_books_exist_status == 0 ? "-" : "有" }}
          </template>
        </el-table-column>

       

        <el-table-column
          prop="academy_name"
          label="考试大纲"
          
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.exam_outline_exist_status == 0 ? "-" : "有" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="manage_creator.nickname"
          label="创建人"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column label="创建时间" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="120">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleDetail(scope.row.id)"
              >详情</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              @click="handleToEdit(scope.row.id)"
              >编辑</el-link
            >
            <!-- <el-link type="primary" :underline="false">对比</el-link> -->
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 16px">
        <el-button
          size="mini"
          type="danger"
          :disabled="chooseList.length <= 0"
          @click="handleDel()"
          >批量删除</el-button
        >
        <div class="block">
          <el-pagination
            background
            layout="total,prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AdmixtureAdd ref="admixtureAdd" @submit="getDataList(true)"></AdmixtureAdd>
    <AdmixtureDetail ref="admixtureDetail"> </AdmixtureDetail>
    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>
   
  </div>
</template>

<script>
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import AdmixtureAdd from "@/components/DialogComponents/AdmixtureAdd/index.vue";
import InstitutionDetails from "@/components/DialogComponents/InstitutionDetails/index.vue";
import AdmixtureDetail from "@/components/DialogComponents/AdmixtureDetail/index.vue";


import { adminUrl } from "@/utils/helper.js";
import {
  collegeMajorRecruit,
  majorlist,
  collegeMajorRecruitDel,
  userlist,
} from "@/request/api";
export default {
  components: {
    TableTittle,
    AdmixtureAdd,
    AdmixtureDetail,
    InstitutionDetails,
    
  },
  data() {
    return {
      TableName: "报录列表",
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      time: [],
      yearsList: [
        {
          label: "2023年",
          value: "2023",
        },
        {
          label: "2022年",
          value: "2022",
        },
        {
          label: "2021年",
          value: "2021",
        },
        {
          label: "2020年",
          value: "2020",
        },
      ],
      pageTotal: 0,
      dataList: [],
      majorOptions: [],
      academyOptions: [],
      chooseList: [],
      userList: [],
    };
  },
  created() {
    this.$store.dispatch("getStaffOptions");
    this.$store.dispatch("getAllColleges");
    this.getDataList();
  },
  methods: {
    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.college_code);
    },
    getUserlist() {
      userlist({
        isPaging: 0,
      }).then((res) => {
        if (res.data.code == 0) {
          this.userList = res.data.data.list;
        }
        // console.log("用户", res)
      });
    },
    getDataList(flag) {
      if (this.flag) {
        this.query.pageIndex = 1;
      }
      if (this.time && this.time.length > 0) {
        this.query.start_create_time = this.time[0];
        this.query.end_create_time = this.time[1];
      } else {
        this.query.start_create_time = null;
        this.query.end_create_time = null;
      }
      collegeMajorRecruit({
        ...this.query,
        manage_creators: this.query.manage_creators
          ? this.query.manage_creators.join(",")
          : "",
      }).then((res) => {
        if (res.data.code == 0) {
          this.pageTotal = res.data.data.count;
          this.dataList = res.data.data.list;
        }
        // console.log(res);
      });
    },
    handleDetail(id) {
      this.$refs.admixtureDetail.open(id);
    },
    openCreateDialog() {
      this.$refs.admixtureAdd.open();
    },
    handleToEdit(id) {
      this.$refs.admixtureAdd.open(id);
    },
    handleDel() {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          collegeMajorRecruitDel({
            ids: this.chooseList.map((item) => item.id),
          }).then((res) => {
            if (res.data.code == 0) {
              this.dialogFormVisible = false;
              this.$message.success("删除成功！");
              this.getDataList(true);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      this.chooseList = val;
    },

    // 选择院校
    collegeChoose(e) {
      this.query.major_code = "";
      this.majorOptions = [];
      this.query.academy_code = "";
      this.academyOptions = [];
      this.getDataList(true);
      if (e) {
        this.getMajor(e);
      }
    },

    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data;
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          };
        });
      });
    },
    // 选择专业
    majorChange(e) {
      // console.log("pp",e)
      this.query.academy_code = "";
      this.academyOptions = [];
      this.getDataList(true);
      if (e) {
        this.getMajorInfo(this.query.college_code, e);
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl();
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: "get", //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data;
        this.academyOptions = res.data.academys;
      });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getDataList();
    },
  },
};
</script>