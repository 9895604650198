import Order from '../views/TeacherResources/order/index.vue'
import OrderHandle from '../views/TeacherResources/order/handle.vue'
import Confirm from '../views/TeacherResources/order/confirm.vue'
import Teachers from '../views/TeacherResources/teachers/index.vue'
import Database from '../views/TeacherResources/database/index.vue'
import Record from '../views/TeacherResources/record/index.vue'
import SignOrder from '../views/TeacherResources/signOrder/index.vue'
import Share from '../views/TeacherResources/share/index.vue'
import ShareHandle from '../views/TeacherResources/share/handle.vue'
import DataStatistics from '../views/TeacherResources/teachers/dataStatistics.vue'
import Admixture from '../views/TeacherResources/admixture/index.vue'
import Classregistration from '../views/TeacherResources/classRegistration/index.vue'
import Classhourrecord from '../views/TeacherResources/classHourRecord/index.vue'

export default [
    /* 师  资 */
    {
        path: '/tr/order',
        component: Order,
        meta: { title: '订单管理' }
    },
    {
        path: '/tr/order/handle/:id',
        component: OrderHandle,
        meta: { title: '订单处理/详情' }
    },
    {
        path: '/tr/order/confirm/:id',
        component: Confirm,
        meta: { title: '确认详情' }
    },
    {
        path: '/tr/teachers',
        component: Teachers,
        meta: { title: '老师管理' }
    },
    {
        path:'/data/statistics',
        component:DataStatistics,
        meta:{title:'数据统计'}
    },
    {
        path: '/tr/database',
        component: Database,
        meta: { title: '资料查询' }
    }, {
        path: '/tr/record',
        component: Record,
        meta: { title: '师资匹配记录' }
    }, {
        path: '/tr/signorder',
        component: SignOrder,
        meta: { title: '师资匹配我的签单' }
    }, {
        path: '/tr/share',
        component: Share,
        meta: { title: '师资推荐' }
    },
    {
        path: '/tr/share/handle/:id',
        component: ShareHandle,
        meta: { title: '师资推荐处理订单' }
    },
    {
        path:'/tr/admixture',
        component:Admixture,
        meta: { title: '报录查询' }
    },
    {
        path:'/tr/classregistration',
        component:Classregistration,
        meta: { title: '课时登记' }
    },
    {
        path:'/tr/classhourrecord',
        component:Classhourrecord,
        meta: { title: '课时记录' }
    }
]