<template>
  <div class="content">
    <el-dialog
      top="5vh"
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="dialogFormVisible = false"
      :close-on-click-modal="false"
      width="900px"
    >
      <el-descriptions
        title="报录信息"
        border
        :column="1"
        :labelStyle="mylabel"
      >
        <el-descriptions-item label="ID">{{ detail.id }}</el-descriptions-item>
        <el-descriptions-item label="年份"
          >{{ detail.year }}年</el-descriptions-item
        >
        <el-descriptions-item label="院校" v-if="detail.college_obj">{{
          detail.college_obj.name
        }}</el-descriptions-item>

        <el-descriptions-item label="专业" v-if="detail.major_obj">{{
          `(${detail.major_obj.major_code})${detail.major_obj.major_name}`
        }}</el-descriptions-item>
        <el-descriptions-item label="学院" v-if="detail.academy_obj">{{
          `(${detail.academy_obj.academy_code})${detail.academy_obj.academy_name}`
        }}</el-descriptions-item>
        <el-descriptions-item label="方向">{{
          detail.directions ? detail.directions : "不限"
        }}</el-descriptions-item>
        <el-descriptions-item label="科目" v-if="detail.subjects">{{
          JSON.parse(detail.subjects)[0].join(",")
        }}</el-descriptions-item>
        <el-descriptions-item label="一志愿招生人数">{{
          detail.user_count_first ? detail.user_count_first + "人" : ""
        }}</el-descriptions-item>
        <el-descriptions-item label="一志愿最高分">{{
          detail.high_score ? detail.high_score + "分" : ""
        }}</el-descriptions-item>
        <el-descriptions-item label="一志愿最低分">{{
          detail.low_score ? detail.low_score + "分" : ""
        }}</el-descriptions-item>
        <!-- <el-descriptions-item label="拟招生">{{
          detail.plan_enrol_count
        }}</el-descriptions-item> -->
        <el-descriptions-item label="调剂人数">{{
          detail.user_count_adjust ? detail.user_count_adjust + "人" : ""
        }}</el-descriptions-item>
        <el-descriptions-item v-if="detail.reference_books" label="参考书目">
          <div
            v-for="(item, index) in detail.reference_books.split(',')"
            :key="index"
          >
            {{ item }}
          </div>
        </el-descriptions-item>
        <el-descriptions-item
          label="考试大纲"
          v-if="detail.exam_outline_file_urls"
        >
          <div
            class="pointer"
            @click="openUrl(item)"
            v-for="(item, index) in detail.exam_outline_file_urls.split(',')"
            :key="index"
          >
            {{ item }}
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { collegeMajorRecruitDetail } from "@/request/api";
export default {
  data() {
    return {
      dialogFormVisible: false,
      title: "详情",
      detail: {},
      mylabel: {
        width: "120px",
      },
    };
  },
  created() {},
  methods: {
    open(id) {
      this.dialogFormVisible = true;
      this.detail = {};
      this.getDetail(id);
    },
    openUrl(url) {
      if (url.indexOf(".pdf") != -1) {
        window.open(url);
      } else {
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + url);
      }
    },
    getDetail(id) {
      collegeMajorRecruitDetail({ id: id }).then((res) => {
        if (res.data.code == 0) {
          this.detail = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
</style>