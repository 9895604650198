<template>
  <div class="content">
    <el-dialog
      top="5vh"
      append-to-body
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="dialogFormVisible = false"
      :close-on-click-modal="false"
      width="520px"
    >
      <!-- placeholder -->
      <el-form
        :model="dataForm"
        label-width="160px"
        label-position="right"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item label="机构简称">
          {{ dataForm.company.name }}
        </el-form-item>
        <el-form-item label="学生姓名">
          {{ dataForm.student_name }}
        </el-form-item>
        <el-form-item label="授课老师" prop="teacherName">
          <div v-if="dataForm.allocation_order_teacher_refs_confirm.length > 1">
            <el-link
              type="primary"
              :underline="false"
              :disabled="teacherChoose.id"
              @click="choseTeacher"
              >选择老师</el-link
            >
            <div class="bottom" v-if="teacherChoose.id">
              <el-tag :disable-transitions="false">
                {{ teacherChoose.teacher_realname
                }}{{
                  teacherChoose.sign_teacher_lesson_hour_amount || 0
                }}元/小时
              </el-tag>
            </div>
          </div>
          <div v-else>
            {{ dataForm.teacherName }}
          </div>
        </el-form-item>
        <el-form-item label="总课时"> {{ dataForm.hours }}小时 </el-form-item>
        <el-form-item label="已登记课时">
          {{dataForm.dispatch_log_total_lesson_hour?`${dataForm.dispatch_log_total_lesson_hour}小时`:'无'}}
        </el-form-item>

        <el-form-item label="本次登记课时" prop="final_real_lesson_hour">
          <el-input
            v-model.trim="dataForm.final_real_lesson_hour"
            placeholder="请输入本次登记课时"
            :step="0.001"
          >
            <template slot="append">小时</template>
          </el-input>
        </el-form-item>
        <el-form-item label="本次课时主题" prop="dispatch_log_course_theme">
          <el-input
            v-model.trim="dataForm.dispatch_log_course_theme"
            placeholder="请输入本次课时主题"
          ></el-input>
        </el-form-item>
        <el-form-item label="课时费确认" prop="sign_teacher_lesson_hour_amount">
          <el-input
            v-model="dataForm.sign_teacher_lesson_hour_amount"
            placeholder="请输入课时费"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>

    
        <el-form-item label="附加费" prop="final_real_company_service_amount">
          <el-input
            v-model="dataForm.final_real_company_service_amount"
            placeholder="请输入附加费"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>



        <el-form-item label="课时其他备注" prop="remark">
          <el-input
            type="textarea"
            v-model="dataForm.remark"
            placeholder="请输入课时其他备注"
          ></el-input>
        </el-form-item>

        <el-form-item label="机构签约费" prop="course_theme">
          {{ dataForm.ref_hour_income_company_amount }}元
        </el-form-item>

        <el-form-item label="本次登记对应的业绩为" prop="course_theme">
          <!-- 登记课时数 * （机构签约费 - 学长课时费））+ 机构附加费 -->
          <div
            v-if="
              dataForm.allocation_order_teacher_refs_confirm.length > 1 &&
              teacherChoose.id
            "
          >
            {{
              (
                dataForm.dispatch_log_total_lesson_hour *
                  (dataForm.ref_hour_income_company_amount -
                    (dataForm.sign_teacher_lesson_hour_amount || 0)) +
                (Number(dataForm.final_real_company_service_amount) || 0)
              ).toFixed(2)
            }}元
          </div>
          <div
            v-else-if="
              dataForm.allocation_order_teacher_refs_confirm.length == 1
            "
          >
            {{
              (
                (Number(dataForm.final_real_lesson_hour) || 0) *
                  (dataForm.ref_hour_income_company_amount -
                    (dataForm.sign_teacher_lesson_hour_amount || 0)) +
                (Number(dataForm.final_real_company_service_amount) || 0)
              ).toFixed(2)
            }}元
          </div>
          <div v-else>请选择老师</div>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="submit()" plain>确认</el-button>
      </div>
      <TeacherList ref="teacherList" @submit="teacherItem" />
    </el-dialog>
  </div>
</template>

<script>
import TeacherList from "../../../components/DialogComponents/Classregistration/TeacherList.vue";
import { dispatchLessonLogAdd } from "../../../request/api";
export default {
  components: {
    TeacherList,
  },

  data() {
    return {
      teacherChoose: {},
      dialogFormVisible: false,
      title: "登记",
      dataForm: {},
      rules: {
        teacherName: [
          { required: true, message: "老师不能为空", trigger: "blur" },
        ],
        final_real_lesson_hour: [
          { required: true, message: "本次登记课时不能为空", trigger: "blur" },
          {
            pattern: /^(\d+)?(\.\d{0,3})?$/,
            message: "输入最多三位小数的数字",
          },
        ],
        dispatch_log_course_theme: [
          { required: true, message: "本次课时主题不能为空", trigger: "blur" },
        ],

        sign_teacher_lesson_hour_amount: [
          { required: true, message: "课时费不能为空", trigger: "blur" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
        final_real_company_service_amount:[
          { required: true, message: "附加费不能为空", trigger: "blur" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
      },
      month:null
    };
  },
  created() {},
  methods: {
    teacherItem({ data }) {
      this.teacherChoose = data;

      this.$set(this.dataForm, "teacherName", data.teacher_realname);
      this.$set(
        this.dataForm,
        "sign_teacher_lesson_hour_amount",
        data.sign_teacher_lesson_hour_amount
      );
      this.$set(this.dataForm, "teacherId", data.teacher_id);
       this.$set(this.dataForm,'data.teacher_realname',`${row.allocation_order_teacher_refs_confirm[0].teacher_realname}老师的${this.month}月已上课时`)
    },
    choseTeacher() {
      this.$refs.teacherList.open(
        this.dataForm.allocation_order_teacher_refs_confirm
      );
    },
    open(row) {
      this.month = this.$moment()
          .month(this.$moment().month() - 1)
          .startOf("month")
          .format("MM");
          // console.log('ccc',this.month)
        this.dataForm = {}
      this.dataForm = JSON.parse(JSON.stringify(row));
      // console.log(row, "row");
      if (row.allocation_order_teacher_refs_confirm.length == 1) {
        this.$set(
          this.dataForm,
          "teacherName",
          row.allocation_order_teacher_refs_confirm[0].teacher_realname
        );
        this.$set(
          this.dataForm,
          "sign_teacher_lesson_hour_amount",
          row.allocation_order_teacher_refs_confirm[0]
            .sign_teacher_lesson_hour_amount
        );
        this.$set(
          this.dataForm,
          "teacherId",
          row.allocation_order_teacher_refs_confirm[0].teacher_id
        );
        this.$set(this.dataForm,'dispatch_log_course_theme',`${row.allocation_order_teacher_refs_confirm[0].teacher_realname}老师的${this.month}月已上课时`)
      }
      
      this.$set(this.dataForm, "final_real_company_service_amount", 0);

      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs.formRules.clearValidate(); //清空表单
      });
    },
    submit() {
      // console.log(this.dataForm)
      // return
      this.$refs.formRules.validate((valid) => {
        if (valid) {
          dispatchLessonLogAdd({
            company_id: this.dataForm.company.id,
            teacher_id: this.dataForm.teacherId,
            dispatch_log_student_name: this.dataForm.student_name,
            dispatch_log_course_theme: this.dataForm.dispatch_log_course_theme,
            ref_dispatch_order_id: this.dataForm.id,
            final_real_lesson_hour: Number(
              this.dataForm.final_real_lesson_hour
            ),
            final_real_lesson_hour_amount:
              this.dataForm.sign_teacher_lesson_hour_amount,
            final_real_company_hour_amount:
              this.dataForm.ref_hour_income_company_amount,
            final_real_company_service_amount:
              this.dataForm.final_real_company_service_amount,
            remark: this.remark,
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "提交成功!",
              });
              this.dialogFormVisible = false;
              this.$emit("submit");
            }
            // console.log("res", res);
            // teacherName
          });
        }
      });
    },

    getTeacher(row) {
      if (
        this.dataForm.allocation_order_teacher_refs &&
        this.dataForm.allocation_order_teacher_refs.length > 0
      ) {
        return this.dataForm.allocation_order_teacher_refs
          .map((item) => item.teacher_realname)
          .join(",");
      }
    },
  },
};
</script>

<style lang="less" scoped></style>