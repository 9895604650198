<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/classservice/teacher' }">老师管理</el-breadcrumb-item>
        <el-breadcrumb-item>课酬管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
      <!--   <el-button
          type="primary"
          size="small"
          @click="openCreateDialog"
        >新建</el-button> -->
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
      >
        <el-form-item
          label="账单名称"
          prop="order_no"
        >
          <el-input
            v-model.trim="query.order_name"
            placeholder="输入账单名称"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="姓名"
          prop="order_no"
        >
          <el-input
            v-model.trim="query.senior_realname_keyword"
            placeholder="输入姓名"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="order_no"
        >
          <el-input
            v-model.trim="query.senior_mobile_keyword"
            placeholder="输入手机号"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            class="datetime"
            v-model="createDatetime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            :default-time="['00:00:00', '23:59:59']"
            @change="timechange"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="table-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
        >
          <el-form-item style="">
            <el-radio-group
              v-model="query.status"
              @change="getList(query)"
            >
              <el-radio-button
                v-for="(item, index) in filter.status"
                :key="index"
                :label="item.value"
              >
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >

        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="order_name"
          label="账单名称"
          width="220"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="align-left">
              {{scope.row.order_name}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="80"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">

            <el-tag
              size="small"
              v-if="scope.row.status==0"
              type="warning"
            >待审核</el-tag>
            <el-tag
              size="small"
              v-else-if="scope.row.status==1"
              type="success"
            >已通过</el-tag>
            <el-tag
              size="small"
              v-else-if="scope.row.status==2"
              type="danger"
            >已拒绝</el-tag>
            <el-tag
              size="small"
              v-else-if="scope.row.status==3"
              type="success"
            >已提现</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="auditor_realname"
          label="拒绝理由"
          width="300"
          align="center"
          show-overflow-tooltip
          v-if="query.status==2"
        >
          <template slot-scope="scope">
            {{scope.row.refuse_reason?scope.row.refuse_reason:"/"}}
          </template>
        </el-table-column>
        <el-table-column
          label="金额"
          width="80"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.total_amount}}元
          </template>
        </el-table-column>
        <el-table-column
          prop="senior_realname"
          label="姓名"
          width="120"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="senior_mobile"
          label="手机号"
          width="120"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="300"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.remark?scope.row.remark:"无"}}
          </template>
        </el-table-column>

        <el-table-column
          prop="creator_realname"
          label="创建人"
          width="100"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.creator_nickname?scope.row.creator_nickname:""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="auditor_realname"
          label="审核人"
          width="100"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.auditor_nickname?scope.row.auditor_nickname:""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建日期"
          width="180"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{
                $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
              }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="审核日期"
          width="180"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{scope.row.audit_time?$moment(scope.row.audit_time).format("YYYY-MM-DD HH:mm"):""
              }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="操作"
          width="150"
          fixed="right"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="openEditDialog(scope.row)"
            >账单详情</el-link>
          </template>
        </el-table-column>

      </el-table>
      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddSeniorBill
      :isVisible="AddSeniorBillDialog"
      @close="AddSeniorBillDialog=false"
      @submit="getList(query)"
    />
    <EditSeniorBill
      :isVisible="EditSeniorBillDialog"
      @close="EditSeniorBillDialog=false"
      :content="EditSeniorBillContent"
      @submit="getList(query)"
    />
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddSeniorBill from "@/components/DialogComponents/SeniorBill/AddSeniorBill";
import EditSeniorBill from "@/components/DialogComponents/SeniorBill/EditSeniorBill";



import { adminUrl } from "@/utils/helper.js";
export default {
  components: {
    TableTittle,
    AddSeniorBill,
    EditSeniorBill
  },
  data() {
    return {
      TableName: "课酬列表",
      tableData: [],
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        status: "0",
        senior_id: this.$route.query.senior_id
      },
      AddSeniorBillDialog: false,
      EditSeniorBillDialog: false,
      EditSeniorBillContent: {},
      filter: {
        status: [
          { label: "全部", value: "" },
          { label: "待审核", value: "0" },
          { label: "已审核", value: "1" },
          { label: "已拒绝", value: "2" },
          { label: "已提现", value: "3" },
        ],
      },
      createDatetime: "",
      pageIndex_total: 1,
    };
  },
  mounted() {
    this.getList(this.query);
  },
  watch: {
    // 时间数据监听
    createDatetime(value) {
      if (value) {
        this.query.startTime = this.$moment(value[0]).valueOf();
        this.query.endTime = this.$moment(value[1]).valueOf();
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },
  },
  methods: {
    timechange(e) {
      if (e == null) {
        this.query.startTime = "";
        this.query.endTime = "";
      }
      this.getList(this.query, true);
    },
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val;
    },
    // 学长课酬订单（查询列表）
    getList(query, isReset = false) {
      if (isReset) {
        this.query.pageIndex = 1
      }
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/senior/course/order/list`,
        method: 'get',//可以省略 不写默认是get
        params: query,
      }).then(res => {
        this.tableData = res.data.data.list || [];
        this.pageTotal = res.data.data.count;
        this.pageIndex_total = Math.ceil(this.pageTotal / this.query.pageSize)
        if (this.query.pageIndex > this.pageIndex_total && this.pageIndex_total != 0) {
          this.$set(this.query, 'pageIndex', this.pageIndex_total)
          this.getList(this.query)
        }
      })

    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    openCreateDialog() {
      this.AddSeniorBillDialog = true
    },
    openEditDialog(row) {
      this.EditSeniorBillContent = Object.assign({}, row)
      this.EditSeniorBillDialog = true
    }
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.demo-form-inline {
  border-bottom: 2px dashed #d2d5df;
}
.popoverStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 92px;
}
.link-template {
  .el-link {
    margin: 0px 5px;
  }
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.align-left {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>