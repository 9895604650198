<template>
  <div class="content">
    <el-dialog
top="5vh"
      title="学长课酬"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="570px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="auto"
        label-position="left"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item label="学长：">
          <div class="senior-wrap">
            <el-button
              type="text"
              @click="openSeniorChoose"
              v-if="!Object.keys(seniorChoose).length"
            >选择用户</el-button>
            <el-button
              type="text"
              @click="openSeniorChoose"
              v-else
            >{{seniorChoose.realname}}/{{seniorChoose.mobile}}</el-button>
          </div>
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input
            v-model="seniorChoose.realname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input
            v-model="seniorChoose.mobile"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="order_name"
          label="账单名称："
        >
          <el-input
            v-model.trim="form.order_name"
            oninput="value=value.replace(/\ +/g, '')"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="金额："
          prop="total_amount"
        >
          <el-input
            v-model="form.total_amount"
            @input="amountInput"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="金额大写：">
          <el-input
            v-model="form.capitalize"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="form.remark"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <div class="left">
          <el-button
            type="primary"
            @click="submit('formRules')"
          >确定并新建</el-button>
        </div>
        <div class="right">
          <el-button @click="emitClose">取 消</el-button>
          <el-button
            type="primary"
            @click="submit('formRules',true)"
          >确 定</el-button>
        </div>

      </div>
    </el-dialog>
    <SeniorChoose
      :isVisible="SeniorChooseDialog"
      @submit="SeniorChooseSubmit"
      @close="SeniorChooseDialog=false"
    />
  </div>
</template>

<script>

import SeniorChoose from "@/components/DialogComponents/SeniorChoose.vue";
import { digitUppercase } from "@/utils/helper";
import { adminUrl } from "@/utils/helper.js";
export default {
  components: {
    SeniorChoose
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      labelWidth: "150px",
      SeniorChooseDialog: false,
      seniorChoose: {},
      form: {
        order_name: ""
      },

      rules: {
        order_name: [{ required: true, message: "请输入账单名称", trigger: "blur" }],
        total_amount: [{ required: true, message: "请输入金额", trigger: "blur" },
        { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }],
      },
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  mounted() {
    // 获取全部院校列表
  },
  methods: {

    emitClose() {
      this.form = {
        order_name: ""
      }
      this.seniorChoose = {}
      this.$emit("close");
    },
    submit(rules, isClose = false) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let param = {
            senior_id: this.seniorChoose.id,
            order_name: this.form.order_name,
            total_amount: this.form.total_amount,
            remark: this.form.remark
          }
          // 学长课酬订单（创建
          let str = adminUrl()
          this.$axios({
            url: `${str}/api/v1/back/senior/course/order`,
            method: 'post',//可以省略 不写默认是get
            data: param,
          }).then(res => {
            if (res.data.code == 0) {
              this.$message.success('创建成功')
              if (isClose) {
                this.$emit('submit')
                this.form = {
                  order_name: ""
                }
                this.seniorChoose = {}
                this.emitClose()
              } else {
                this.$emit('submit')
                this.form = {
                  order_name: ""
                }
                this.seniorChoose = {}
              }
            }

          })
        } else {
          return false;
        }
      });
    },
    openSeniorChoose() {
      this.SeniorChooseDialog = true
    },
    SeniorChooseSubmit(data) {
      let mouth = this.$moment().month(this.$moment().month() - 1).startOf('month').format('MM');
      this.seniorChoose = data

      this.form.order_name = `${this.seniorChoose.realname}的${mouth}月课酬`
    },
    amountInput(e) {
      this.form.capitalize = digitUppercase(e)
    }

  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
.footer {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
</style>