<template>
  <div class="content">
    <el-dialog
      top="5vh"
      title="新建订单"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="600px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="auto"
        label-position="left"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item
          label="机构简称："
          prop="cid"
        >
          <el-select
            filterable
            v-model="form.cid"
            placeholder="请选择"
            clearable
            style="width: 410px"
            @change="cidChange"
          >
            <el-option
              v-for="item in $store.state.CompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考院校："
          prop="apply_college_name"
        >
          <el-select
            v-model="form.apply_college_code"
            filterable
            placeholder="请选择"
            style="width: 410px"
            clearable
            @change="collegeChoose"
          >
            <el-option
              v-for="item in $store.state.collegeList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考专业："
          prop="apply_major_name"
        >
          <el-select
            filterable
            placeholder="请选择"
            v-model="form.apply_major_name"
            @change="majorChoose"
            style="width: 429px"
            clearable
          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.allnames"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考学院："
          prop="apply_academy_name"
        >
          <el-select
            filterable
            placeholder="请选择"
            v-model="form.apply_academy_name"
            clearable
            @change="academyChoose"
            style="width: 429px"
          >
            <el-option
              v-for="(item,index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="item.academy_code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="考试方向："
          prop="apply_direction"
        >
          <el-select
            v-model="form.apply_direction"
            placeholder="请选择考试方向"
            clearable
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in directionsOptions"
              :key="index"
              :label="item.direction"
              :value="{ value: item.direction_code, label: item.direction}"
            >
              <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="辅导科目："
          prop="subjectsarr"
        >
          <el-select
            :multiple-limit="4"
            @change="$forceUpdate()"
            v-model="form.subjectsarr"
            multiple
            placeholder="请选择"
            clearable
            style="width: 410px"
          >
            <el-option
              v-for="(item, index) in subjectsOptions"
              :key="index"
              :label="item.subject_name"
              :value="item.subject_code"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="订单价格："
          prop="teacher_matching_price"
        >
          <el-input
            v-model.number="form.teacher_matching_price"
            placeholder="请输入订单价格"
            clearable
            style="width: 304px"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          label="辅导课时："
          prop="lesson_hours"
        >
          <el-input
            placeholder="请输入辅导课时"
            v-model="form.lesson_hours"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          >
            <span slot="append">小时</span>
          </el-input>
        </el-form-item>
        <el-form-item
          label="备注："
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="form.remark"
            placeholder="请输入备注"
            clearable
          ></el-input>
        </el-form-item>
        <div class="line"></div>
        <el-form-item
          label="匹配成功通知人："
          prop="manage_watcher"
        >
          <el-select
            @change="$forceUpdate()"
            v-model="form.watcher"
            placeholder="请选择"
            style="width: 410px"
            multiple
          >
            <el-option
              v-for="(item,index) in staffOptions"
              :key="index"
              :label="item.alias"
              :value="item.id"
            >
              <span> {{ `${item.alias}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="continueSubmitit('formRules')"
        >确定并继续</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  majorlist,
  subjectlist,
  academylist,
  companyuserlist,
} from "../../../request/api";
import { adminUrl } from "@/utils/helper";

export default {
  props: {
    addForm:{
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      form: {
        lesson_hours: 0,
      },
      labelOptions: [],
      sourceOptions: [],
      staffOptions: [],
      // 院校选择项
      collegeOptions: [],
      academyOptions: [],
      majorOptions: [],
      subjectsOptions: [],
      productsOptions: [],
      rules: {
        cid: [{ required: true, message: "请选择机构", trigger: "blur" }],
        apply_college_name: [
          { required: true, message: "请选择意向院校", trigger: "blur" },
        ],
        apply_academy_name: [
          { required: true, message: "请选择意向学院", trigger: "blur" },
        ],
        apply_major_code: [
          { required: true, message: "请选择意向专业", trigger: "blur" },
        ],
        watcher: [{ required: true, message: "请选择负责人", trigger: "blur" }],
        subjectsarr: [
          { required: true, message: "请选择辅导科目", trigger: "blur" },
        ],
        order_amount: [
          { required: true, message: "请输入辅导课时" },
          { type: "number", message: "辅导课时必须为数字值" },
        ],
      },
      directionsOptions: [],
    };
  },
  mounted() { },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      this.form = {
        lesson_hours: 0,
      };
      if(val&&this.addForm.cid){
        this.form ={
          ...this.addForm
        }
      }
    },
  },
  methods: {
    emitClose() {
      this.form = {
        lesson_hours: 0,
      };
      this.$emit("close");
    },
    // 因为传科目时要上传对象所以在这里做一些处理
    formateSubjects() {
      let arr = [];
      this.form.subjectsarr.forEach((item) => {
        let obj = {};
        obj = this.subjectsOptions.find((v) => {
          return v.subject_code == item; //筛选出匹配数据
        });
        if (obj) {
          arr.push(obj);
        }
      });
      this.form.subjects = arr;
    },
    continueSubmitit(rules){
      
      this.formateSubjects();

      let data = JSON.parse(JSON.stringify(this.form))
      // console.log("xx", data.watcher)
      if (data.watcher) {
        data.watcher = data.watcher.join(",");
      }
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.$emit("continueSubmit", {
            form: Object.assign({}, data, {
              apply_direction_code: this.form.apply_direction ? this.form.apply_direction.value : "",
              apply_direction_name: this.form.apply_direction ? this.form.apply_direction.label : "",
            })
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    submit(rules) {
      this.formateSubjects();
      if (this.form.watcher) {
        this.form.watcher = this.form.watcher.join(",");
      }
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.$emit("submit", {
            form: Object.assign({}, this.form, {
              apply_direction_code: this.form.apply_direction ? this.form.apply_direction.value : "",
              apply_direction_name: this.form.apply_direction ? this.form.apply_direction.label : "",
            })
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 选择院校options搜索出name（后台数据需要name和code两个参数）
    collegeChoose(e) {
      let obj = {};
      obj = this.$store.state.collegeList.find((item) => {
        return item.code === e; //筛选出匹配数据
      });
      this.form.apply_college_code = obj.code;
      this.form.apply_college_name = obj.name;
      this.getMajorOptions();
    },
    getSubjectOptions(college_code, academy_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        academy_code: academy_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程"
        }
        this.subjectsOptions = res.data.data.list || [];
        this.subjectsOptions.push(obj)
      });
    },
    cidChange(e) {
      this.form.watcher = "";

      this.getStaffByCid(e);
      this.form.teacher_matching_price = this.$store.state.CompanyList.find(
        (v) => {
          return v.id == e;
        }
      ).teacher_matching_price;
    },
    getStaffByCid(cid) {
      let query = {
        isPaging: 0,
        cid: cid,
      };
      companyuserlist(query).then((res) => {
        this.staffOptions = res.data.data.list || [];
      });
    },
    // 选择学院，获取专业代码与专业名称
    majorChoose(e) {
      let obj = {};
      obj = this.majorOptions.find((item) => {
        return item.code === e; //筛选出匹配数据
      });
      this.form.apply_major_code = obj.code;
      this.form.apply_major_name = obj.name;
      this.getMajorInfo(this.form.apply_college_code, this.form.apply_major_code);
      this.getSubjectOptions(
        this.form.apply_college_code,
        this.form.apply_academy_code,
        this.form.apply_major_code
      );
    },
    // 获取学院代码与学院名称
    academyChoose(e) {
      // console.log("E",e)

      let obj = this.academyOptions.find((item) => {
        return item.academy_code === e; //筛选出匹配数据
      });
      this.form.apply_academy_code = obj.academy_code;
      this.form.apply_academy_name = obj.academy_name;
      this.directionsOptions = obj.directions
      this.$set(this.form, 'apply_direction', {
        label: this.directionsOptions[0].direction,
        value: this.directionsOptions[0].direction_code,
      })
    },
    getMajorOptions() {
      let query = {
        isPaging: 0,
        college_code: this.form.apply_college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map(v => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`
          }
        })
      });
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get',//可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then(response => {
        let res = response.data
        this.academyOptions = res.data.academys
        
        if(this.academyOptions.length==1){
          this.form.apply_academy_code = this.academyOptions[0].academy_code;
          this.form.apply_academy_name = this.academyOptions[0].academy_name;
          this.academyChoose(this.form.apply_academy_code)
        }

        this.directionsOptions = this.academyOptions.find(v => {
          return this.form.apply_academy_code == v.academy_code
        }).directions
      })
    },
  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
.line {
  border: dashed #dcdfe6 1px;
  margin: 36px auto;
}
</style>