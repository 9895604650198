<template>
  <div>
    <el-dialog
top="5vh"
      title="老师信息"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="440px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="老师姓名："
          prop="name"
        >
          <span class="item-value">{{dataForm.realname}} </span>
        </el-form-item>
        <el-form-item
          label="性别："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.gender==1?'男':'女'}}
          </span>
        </el-form-item>
        <el-form-item
          label="考研年份："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.recruit_year}}年
          </span>
        </el-form-item>
        <el-form-item
          label="录取信息："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.recruit_college_name}}/{{dataForm.recruit_major_name}}
          </span>
        </el-form-item>
        <el-form-item
          label="综合评分："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.teacher_composite_score}}分
          </span>
        </el-form-item>
        <el-form-item
          label="备注："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.manage_remark?dataForm.manage_remark:'无'}}
          </span>
        </el-form-item>
        <el-form-item
          label="创建人："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.manage_creator?dataForm.manage_creator.nickname:'无'}}
          </span>
        </el-form-item>
        <el-form-item
          label="创建时间："
          prop="name"
        >
          <span class="item-value">
            {{dataForm.create_time?$moment(dataForm.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </span>
        </el-form-item>
        <el-form-item
          label="课时费："
          prop="lesson_hour_amount"
        >
          <el-input
            placeholder="请输入课时费"
            v-model="dataForm.lesson_hour_amount"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          >
            <span slot="append">元/小时</span>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  teacherEdit,
  teacherOne,
  commonHandleLogCreate

} from "@/request/api";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
    class_id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        lesson_hour_amount: [{ required: true, message: "请输入课时费", trigger: "blur" },
        { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }],
      },
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },

  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    fetchDetail() {
      teacherOne({ id: this.content.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data
      });
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          if (this.dataForm.grades) {
            this.dataForm.grades.push({
              id: this.class_id,
              teacher_lesson_hour_amount: this.dataForm.lesson_hour_amount,
              teacher_grade_status: 1,
            })
          } else {
            this.dataForm.grades = [{
              id: this.class_id,
              teacher_lesson_hour_amount: this.dataForm.lesson_hour_amount,
              teacher_grade_status: 1,
            }]
          }

          let query = {
            id: this.dataForm.id,
            arr_grade: this.dataForm.grades.map(v => {
              return {
                grade_id: v.id,
                teacher_lesson_hour_amount: v.teacher_lesson_hour_amount,
                teacher_grade_status: v.teacher_grade_status,
              }
            })
          }
          teacherEdit(query).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.createLog()
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    createLog() {
      let query = {
        ref_id: this.class_id,
        handle_type: 0,
        handle_child_type: 4,
        handle_content: `邀请${this.dataForm.realname}老师加入班级，课时费${this.dataForm.lesson_hour_amount}元/小时`,
      }
      commonHandleLogCreate(query).then((res) => {
        if (res.data.code == 0) {
          this.$emit('submit')
          this.emitClose()
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.item-value {
  font-size: 14px;
  color: #303133;
}
.el-form-item {
  margin-bottom: 8px !important;
}
</style>