<template>
  <div class="content">
    <div class="form_card">
      <div class="change-div">
        <div
          class="change-div-item"
          v-for="(item, index) in methodsArr"
          :key="index"
          @click="methodsIndex = index"
        >
          <span
            class="change-div-item-span"
            :class="{ 'active-span': index == methodsIndex }"
            >{{ item.name }}</span
          >
          <div class="row" v-show="methodsIndex == index"></div>
        </div>
      </div>
      <PasswordLogin
        v-show="methodsArr[methodsIndex].name == '账号登录'"
        @forgetChange="forgetChange"
      ></PasswordLogin>
      <SmsCodeLogin
        v-show="methodsArr[methodsIndex].name == '验证码登录'"
      ></SmsCodeLogin>
      <ResetPassword
        v-show="methodsArr[methodsIndex].name == '重置密码'"
      ></ResetPassword>
    </div>
  </div>
</template>

<script>
import PasswordLogin from "../components/LoginComponents/passwordLogin/passwordLogin.vue";
import SmsCodeLogin from "../components/LoginComponents/SmsCodeLogin/SmsCodeLogin.vue";
import ResetPassword from "../components/LoginComponents/ResetPassword/ResetPassword.vue";
export default {
  components: {
    PasswordLogin,
    SmsCodeLogin,
    ResetPassword,
  },
  data() {
    return {
      methodsIndex: 0,
      methodsArr: [
        {
          name: "账号登录",
        },
        {
          name: "验证码登录",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    forgetChange() {
      this.methodsIndex = 0;
      this.methodsArr = [
        {
          name: "重置密码",
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/images/LoginBcg.png);
  background-size: 100%;
  .form_card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 504px;
    height: 500px;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(168, 197, 239, 0.2);
    opacity: 1;
    border-radius: 10px;
    .change-div {
      position: absolute;
      margin-top: 30px;
      left: 10%;
      height: 50px;
      display: flex;
      .change-div-item {
        margin: 0 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        .row {
          width: 48px;
          height: 6px;
          background: #409eff;
          opacity: 1;
          border-radius: 3px;
        }
        .change-div-item-span {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 28px;
          color: #909399;
          opacity: 1;
        }
        .active-span {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 800;
          line-height: 37px;
          color: #303133;
          opacity: 1;
        }
      }
    }
    .title {
      display: block;
      position: absolute;
      left: 21%;
      margin-top: 76px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #303133;
      opacity: 1;
    }
  }
}
</style>