<template>
  <div>
    <el-dialog
top="5vh"
      title="撤回理由"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="1080px"
    >
      <div class="wrap">
        <div class="item">
          <div class="label">姓名：</div>
          <div class="value">
            {{content.name}}
            <el-tag
              size="mini"
              effect="plain"
              type="info"
            >{{content.label}}</el-tag>
          </div>
        </div>
        <div
          class="item"
          style="margin-left:40px;"
        >
          <div class="label">状态：</div>
          <div class="value">
            <el-tag
              size="mini"
              v-if="content.status=='待确认'"
              type="warning"
            >{{content.status}}</el-tag>
            <el-tag
              size="mini"
              v-else-if="content.status=='已拒绝'"
              type="danger"
            >{{content.status}}</el-tag>
            <el-tag
              size="mini"
              type="success"
              v-else
            >{{content.status}}</el-tag>
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="item">
          <div class="label">内容：</div>
          <div class="value">{{content.content}}</div>
        </div>
      </div>
      <div class="wrap">
        <div class="item">
          <div class="label">确认时间：</div>
          <div class="value"> {{content.time?$moment(content.time).format("YYYY-MM-DD HH:mm"):""}}</div>
        </div>
      </div>
      <el-form
        :model="dataForm"
        label-position="top"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item label="撤回理由：">
          <div class="text-pic">
            <el-form-item prop="handle_content">
              <el-input
                type="textarea"
                v-model="dataForm.handle_content"
                placeholder="请输入撤回理由"
              ></el-input>
            </el-form-item>
            <div class="upload">
              <el-upload
                :action="imgUploadUrl"
                list-type="picture-card"
                :on-success="handleFileSuccess"
                :on-remove="handleRemove"
                :limit="3"
                :file-list="fileList"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import {
  confirmLesson,
  lessonLogOne
} from "@/request/api";
import { imgUploadUrl, fileUploadUrl } from "@/request/http";

import {
  getStorage
} from "@/utils/helper";



export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
    course_plan_id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      imgUploadUrl: imgUploadUrl(),
      fileList: [],
      dataForm: {},
      rules: {
        handle_content: [{ required: true, message: "请输入撤回理由", trigger: "change" }],
      },

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        console.log(this.content);
      }
    },

  },
  methods: {
    emitClose() {
      this.dataForm = {}
      this.fileList = []
      this.$emit("close");
    },
    handleFileSuccess(response, file, fileList) {
      this.fileList.push({
        name: file.raw.name,
        url: response.data.url,
      })
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          if (this.content.label == '学管师') {
            let params = {
              course_plan_id: this.course_plan_id,
              user_type: 'learn_manager',
              learn_manager: this.content.id,
              handle_content: this.dataForm.handle_content,
              handle_picture_urls: this.fileList.length ? JSON.stringify(this.fileList) : '',
              handle_area: 0,
              is_stop: 1,
            }
            confirmLesson(params).then((response) => {
              let res = response.data
              if (res.code == 0) {
                this.emitClose()
                this.$emit('submit')
              }
            });
          } else if (this.content.label == '学生') {
            let params = {
              student_confirm_status: 0,
              student_content_feedback_content: null,
              student_appraise_teacher_content: null,
              student_appraise_teacher_score: null,
              student_real_lesson_start_time: null,
              student_real_lesson_hour_area: null,
              student_confirm_time: null,
              course_plan_id: this.course_plan_id,
              user_type: 'student',
              learn_manager: getStorage('userInfo').id,
              student_id: this.content.origin_data.student_id,
              is_by_handle: 1,
              handle_area: 0,
              handle_type: 2,
              handle_learn_manager_id: getStorage('userInfo').id,
            }
            confirmLesson(params).then((response) => {
              let res = response.data
              if (res.code == 0) {
                this.emitClose()
                this.$emit('submit')
              }
            });
          } else if (this.content.label == '老师') {
            let params = {
              teacher_confirm_status: 0,
              teacher_real_lesson_start_time: null,
              teacher_real_lesson_hour: null,
              teacher_confirm_time: null,
              course_plan_id: this.course_plan_id,
              user_type: 'teacher',
              learn_manager: getStorage('userInfo').id,
              teacher_id: this.content.id,
              is_by_handle: 1,
              handle_area: 0,
              handle_type: 2,
              handle_learn_manager_id: getStorage('userInfo').id,
            }
            confirmLesson(params).then((response) => {
              let res = response.data
              if (res.code == 0) {
                this.emitClose()
                this.$emit('submit')
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .item {
    display: flex;
    align-items: center;
    .label {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
    }
  }
}
.text-pic {
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #dcdfe6;
  padding: 16px;

  /deep/.el-textarea__inner {
    border: 0;
    resize: none;
    padding: 0px;
  }
  .upload {
    /deep/.el-upload--picture-card {
      width: 56px;
      height: 56px;
      line-height: 65px;
    }
    /deep/.el-upload-list__item {
      width: 56px;
      height: 56px;
      line-height: 65px;
    }
  }
}
</style>