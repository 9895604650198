<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/classservice/class' }">学员管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button size="small"
                   type="primary"
                   @click="handleToLog()">新建记录</el-button>
      </div>
    </div>
    <!-- <el-card style="margin-top: 20px">
      <TableTittle :title="title"></TableTittle>
      <div class="count-wrap">
        <div
          class="item"
          v-for="(item) in options"
          :key="item.id"
        >
          <div class="label">{{ item.value }}</div>
          <div
            class="value"
            :class="{'red':formateValue(item)==0}"
          >{{ formateValue(item) }}</div>
        </div>
      </div>
    </el-card> -->
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="服务类型：">
          <el-select v-model="query.core_types"
                     @change="fetchList(true)"
                     placeholder="请选择"
                     clearable>
            <el-option v-for="item in options"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="记录内容">
          <el-input v-model.trim="query.content_keyword"
                    placeholder="请输入记录内容关键字"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="记录人">
          <el-input v-model.trim="query.real_record_user_keyword"
                    placeholder="请输入记录人"
                    @input="fetchList(true)"
                    clearable></el-input>
          <!-- <el-select
            v-model="query.real_record_user_keyword"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="报名时间">
          <el-date-picker class="datetime"
                          v-model="create_time"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="fetchList(true)"
                          clearable
                          :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <el-table :data="result"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ fontSize: '13px' }"
                @selection-change="handleSelectionChange"
                border>
        <el-table-column type="selection"
                         width="50"
                         align="center">
        </el-table-column>
        <el-table-column label="记录日期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="服务类型"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{ formateType(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="隐藏状态"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.user_hide_status == 1?'不隐藏':'对机构隐藏'}}
          </template>
        </el-table-column>
        <el-table-column prop="content_text"
                         label="记录内容"
                         align="left"
                         show-overflow-tooltip
                         min-width="300">

          <template slot-scope="scope">
            <div>
              {{scope.row.content_text}}
            </div>

            <div class="img-list"
                 v-if="scope.row.content_img">
              <img v-for="(item, index) in JSON.parse(scope.row.content_img)"
                   :key="index"
                   :src="item.url"
                   alt=""
                   srcset=""
                   @click="openViewer(JSON.parse(scope.row.content_img),index)">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="content_text"
                         label="关联对象"
                         align="center"
                         min-width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ getName(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="附件"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            <!-- <el-link
              type="primary"
              :underline="false"
              v-if="scope.row.content_img"
              @click="openViewer(JSON.parse(scope.row.content_img))"
              >预览</el-link
            > -->
            <el-link type="primary"
                     :underline="false"
                     v-if="scope.row.content_file"
                     @click="handleToPreview(scope.row)">预览</el-link>
            <el-link type="primary"
                     :underline="false"
                     v-else
                     disabled>预览</el-link>
          </template>
        </el-table-column>
        <el-table-column label="记录人"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.real_record_user_name }}
            <!-- {{ formateCreator(scope.row) }} -->
          </template>
        </el-table-column>
        <el-table-column label="记录人类型"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- {{scope.row.real_record_user_name}} -->
            {{ formateUserType(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="操作"
                         width="100"
                         align="center"
                         fixed="right">
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link type="primary"
                       :underline="false"
                       @click="handleToEdit(scope.row)">编辑</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button size="mini"
                   type="danger"
                   @click="handleToDeleteAll()">批量删除</el-button>

        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>

    <el-image-viewer v-if="showViewer"
                     :url-list="imgs"
                     :zIndex="999999999"
                     :on-close="closeViewer"
                     :initial-index="initialIndex" />

    <RecordPreview :isVisible="preDialog"
                   @close="preDialog = false"
                   :arr="url_arr"></RecordPreview>
    <BatchLog :isVisible="logDialog"
              @close="logDialog = false"
              @submit="fetchList"></BatchLog>
    <EditLog :isVisible="editDialog"
             @close="editDialog = false"
             @submit="fetchList"
             :id="editId"></EditLog>
  </div>
</template>
  
  <script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import RecordPreview from '@/components/DialogComponents/ClassService/RecordPreview.vue'
import BatchLog from '@/components/DialogComponents/ClassService/BatchLog.vue'
import EditLog from '@/components/DialogComponents/ClassService/EditLog.vue'

import {
  learnManageRecordList,
  editstudent,
  dictionaryroot,
  studentOne,
  learnManageRecordDeleteList,
} from '@/request/api'
import { log } from 'mathjs'
export default {
  components: {
    TableTittle,
    ElImageViewer,
    RecordPreview,
    EditLog,
    BatchLog,
  },
  data() {
    return {
      initialIndex: 0,
      TableName: '学员列表',
      result: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        docking_statuses: '',
      },
      pageTotal: 0,

      options: [
        {
          value: '课程回访',
          id: 20,
        },
        {
          value: '续课提醒',
          id: 21,
        },
        {
          value: '测试结果',
          id: 22,
        },
        {
          value: '问题记录',
          id: 23,
        },
        {
          value: '结课通知',
          id: 24,
        },
        {
          value: '终止通知',
          id: 25,
        },
        {
          value: '学员对接',
          id: 1,
        },
        {
          value: '师资匹配',
          id: 2,
        },
        {
          value: '师资确认',
          id: 3,
        },
        {
          value: '计划制定',
          id: 4,
        },
        {
          value: '上课提醒',
          id: 5,
        },
        {
          value: '课时确认',
          id: 6,
        },
        {
          value: '修改申请',
          id: 7,
        },
        {
          value: '终止申请',
          id: 8,
        },
        {
          value: '删除申请',
          id: 9,
        },
        {
          value: '学生修改',
          id: 10,
        },
        {
          value: '学生终止',
          id: 11,
        },
        {
          value: '学生删除',
          id: 12,
        },
        {
          value: '班级终止',
          id: 13,
        },
        {
          value: '班级删除',
          id: 14,
        },
      ],
      title: '服务记录',
      statistic_service_obj: [],
      showViewer: false,
      imgs: [],
      preDialog: false,
      url_arr: [],
      logDialog: false,
      create_time: '',
      editDialog: false,
      editId: null,
      chooseList: [],
    }
  },
  created() {
    this.parseQuery()
    this.fetchList()
    // this.fetchDictionary()

    this.$store.dispatch('getStaffOptions')
  },
  watch: {
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf()
        this.query.end_create_time = this.$moment(value[1]).valueOf()
      } else {
        this.query.start_create_time = ''
        this.query.end_create_time = ''
        this.fetchList(true)
      }
    },
  },
  computed: {},
  methods: {
    getName(row) {
      if (row.grade_id) {
        return row.ref_grade_name
      } else if (row.ref_lesson_log_grade_id) {
        return row.ref_lesson_log_grade_name
      } else if (row.ref_course_plan_grade_id) {
        return row.ref_course_plan_grade_name
      } else if (row.task_ref_course_plan_grade_id) {
        return row.task_ref_course_plan_grade_name
      } else if (row.task_ref_grade_id) {
        return row.task_ref_grade_name
      } else if (row.task_ref_allocation_order_grade_id) {
        return row.task_ref_allocation_order_grade_name
      } else if (row.task_ref_lesson_log_grade_id) {
        return row.task_ref_lesson_log_grade_name
      } else if (row.student_id) {
        return row.student_realname
      } else {
        return '-'
      }
      // if (row.student_id) {
      //   return row.student_realname;
      // } else if (row.grade_id) {
      //   return row.ref_grade_name
      // } else if (row.teacher_id) {
      //   return row.teacher_realname
      // } else if (row.course_plan_id) {
      //   return row.ref_course_plan_theme;
      // } else if (row.lesson_log_id) {
      //   return row.ref_lesson_log_theme;
      // }else if(row.task_id){
      //   return '-'
      // }else{
      //   return '-'
      // }
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
    openViewer(url, index) {
      this.imgs = url.map((v) => {
        return v.url
      })
      this.initialIndex = index
      this.showViewer = true
    },
    fetchDictionary() {
      dictionaryroot({ code: 'LearnManageServiceType' }).then((response) => {
        let res = response.data
        this.options = res.data.values
      })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)

      learnManageRecordList(params).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        this.pageTotal = res.data.count
        res.data.statistic_service_obj &&
          (this.statistic_service_obj = res.data.statistic_service_obj)
      })
      this.syncQuery()
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )

      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    formateValue(item) {
      let ind = this.statistic_service_obj.findIndex((v) => {
        return v.service_type_id == item.id
      })
      if (ind > -1) {
        return this.statistic_service_obj[ind].service_count
      } else {
        return 0
      }
    },
    formateType(row) {
      switch (row.core_type) {
        case 1:
          return '学员对接'
        case 2:
          return '师资匹配'
        case 3:
          return '师资确认'
        case 4:
          return '计划制定'
        case 5:
          return '上课提醒'
        case 6:
          return '课时确认'
        case 7:
          return '修改申请'
        case 8:
          return '终止申请'
        case 9:
          return '删除申请'
        case 10:
          return '学生修改'
        case 11:
          return '学生终止'
        case 12:
          return '学生删除'
        case 13:
          return '班级终止'
        case 14:
          return '班级删除'
        case 15:
          return '班级修改'
        case 20:
          return '课程回访'
        case 21:
          return '续课提醒'
        case 22:
          return '测试结果'
        case 23:
          return '问题记录'
        case 24:
          return '结课通知'
        case 25:
          return '终止通知'
      }

      // if (this.options.length&&row.service_type_id) {
      //   return this.options.find(v => {
      //     return v.id == row.service_type_id
      //   }).value
      // }
    },
    formateUserType(row) {
      switch (row.real_record_user_type) {
        case 1:
          return '学管师'
        case 2:
          return '匹配师'
        case 3:
          return '老师'
        case 4:
          return '学生'
        case 5:
          return '系统'
        case 6:
          return '班主任'
        case 7:
          return '对接人'
      }
    },
    formateCreator(row) {
      if (row.learn_manage_id && this.$store.state.staffOptions.length) {
        let id = row.learn_manage_id
        return this.$store.state.staffOptions.find((v) => {
          return v.id == id
        }).nickname
      } else {
        return '无'
      }
    },
    handleToPreview(row) {
      this.preDialog = true
      this.url_arr = JSON.parse(row.content_file)
    },
    handleToLog() {
      this.logDialog = true
    },
    handleToEdit(row) {
      this.editDialog = true
      this.editId = row.id
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm('确定要删除这些信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let ids = this.chooseList.map((v) => {
          return v.id
        })
        learnManageRecordDeleteList({ ids }).then((res) => {
          this.fetchList(this.query)
        })
      })
    },
  },
}
</script>
  
  <style lang="less" scoped>
.img-list {
  margin-top: 12px;
  display: flex;
  align-items: center;

  img {
    width: 140px;
    height: 140px;
    margin-right: 12px;
    border-radius: 4px;
  }
}
.dropdown-item {
  height: 100%;
  width: 100%;
}
/deep/.el-badge__content {
  z-index: 999999;
}
.count-wrap {
  padding: 30px 0px;
  background: #ecf2fe;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
      margin-bottom: 12px;
    }
    .value {
      font-size: 28px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .red {
      color: #f36d78;
    }
  }
}
</style>
  