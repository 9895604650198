<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>机构</el-breadcrumb-item>
        <el-breadcrumb-item>订单价格</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button type="primary" size="small" @click="createDialog = true">新建</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap" ref="searchForm" :inline="true" :model="query" size="small" label-position="left">
        <el-form-item label="机构" prop="cids">
          <el-select v-model="query.cids" placeholder="请选择机构" filterable @change="fetchList(true)" clearable multiple>
            <el-option v-for="item in $store.state.CompanyList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型" prop="order_types">
          <el-select v-model="query.order_types" placeholder="请选择订单类型" filterable @change="fetchList(true)" clearable
            multiple>
            <el-option label="外包" :value="1">
            </el-option>
            <el-option label="代找" :value="2">
            </el-option>
            <el-option label="代签" :value="3">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table :data="result" :header-cell-style="{
        backgroundColor: '#F2F6FC',
        color: '#909399',
        fontSize: '14px',
      }" @selection-change="handleSelectionChange" :cell-style="{ fontSize: '13px' }" border>
        <el-table-column type="selection" width="50" align="center"> </el-table-column>
        <el-table-column prop="id" label="ID" align="center" width="80">
        </el-table-column>
        <el-table-column label="机构简称" align="center" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateCompany(scope.row.cid) }}
          </template>
        </el-table-column>
        <el-table-column label="订单类型" align="center" show-overflow-tooltip width="80">
          <template slot-scope="scope">
            {{ formateType(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="初复试类型" align="center" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            {{ formateExam_time_type(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="价格类型" align="center" show-overflow-tooltip width="90">
          <template slot-scope="scope">
            {{ formatePrice_type(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="价格子类型" align="center" show-overflow-tooltip width="150">
          <template slot-scope="scope">
            {{ formateSub_price_type(scope.row) }}
            {{ formateExtendSub_price_type(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="target_range_names" label="目标范围" align="center" show-overflow-tooltip min-width="200">
          <template slot-scope="scope">
            {{ formateTarget_range(scope.row) }}
            {{ formateExtendTarget_range(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格" align="center" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.price ? scope.row.price : '0' }}元
          </template>
        </el-table-column>

        <el-table-column prop="sort" label="优先值" align="center" width="80" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="有效期" align="center" width="300" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.start_time && scope.row.end_time ? `${$moment(scope.row.start_time).format("YYYY-MM-DD HH:mm")} ~
                        ${$moment(scope.row.end_time).format("YYYY-MM-DD HH:mm")}` : '不限' }}

          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="100">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="handleToEdit(scope.row)">编辑</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="operation">
        <div>
          <el-button size="mini" type="danger" @click="handleToDeleteAll()">批量删除</el-button>
        </div>
        <div class="block" style="margin-bottom:0px;">
          <el-pagination background layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)" :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)" :total="Number(pageTotal)" @size-change="handleSizeChange"
            @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <AddOrderPrice :isVisible="createDialog" @close="createDialog = false" @submit="fetchList"></AddOrderPrice>
    <EditOrderPrice :isVisible="editDialog" @close="editDialog = false" :id="editId" @submit="fetchList"></EditOrderPrice>
  </div>
</template>
            
<script>


import TableTittle from "@/components/TableTittle/TableTittle.vue";
import AddOrderPrice from "@/components/DialogComponents/Mechanism/AddOrderPrice.vue";
import EditOrderPrice from "@/components/DialogComponents/Mechanism/EditOrderPrice.vue";





import {
  orderPriceList,
  orderPriceDeleteList
} from "@/request/api";
export default {

  components: {
    TableTittle,
    AddOrderPrice,
    EditOrderPrice
  },
  data() {
    return {
      TableName: '价格列表',
      result: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        is_oauth: 1
      },
      pageTotal: 0,
      create_time: '',
      detailId: null,
      createDialog: false,
      editDialog: false,
      editId: null,
      chooseList: [],
    };
  },
  created() {
    this.parseQuery();
    this.fetchList();
    this.$store.dispatch("getCompanyList");
    this.$store.dispatch('getAllColleges')
    this.$store.dispatch("getStaffOptions");
  },
  watch: {
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
        this.fetchList(true)
      }
    },
  },
  computed: {},
  methods: {

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.fetchList();
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query)
      if (params.cids) {
        params.cids = params.cids.join(',')
      }
      if (params.order_types) {
        params.order_types = params.order_types.join(',')
      }
      orderPriceList(params).then((response) => {
        let res = response.data
        this.result = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
      if (this.query.cids && this.query.cids.length) {
        this.query.cids = this.query.cids.split(",").map(v => { return Number(v) });
      }
      if (this.query.order_types && this.query.order_types.length) {
        this.query.order_types = this.query.order_types.split(",").map(v => { return Number(v) });
      }
    },

    handleSelectionChange(val) {
      this.chooseList = val
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      if (params.cids) {
        params.cids = params.cids.filter((v) => v).join(",");
      }
      if (params.order_types) {
        params.order_types = params.order_types.filter((v) => v).join(",");
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    formateCompany(id) {
      if (id && this.$store.state.CompanyList.length) {
        return this.$store.state.CompanyList.find(v => {
          return v.id == id
        }).name
      } else {
        return '无'
      }
    },
    formateType(row) {
      let types = {
        '1': '外包',
        '2': '代找',
        '3': '代签',
      }
      return types[row.order_type]
    },
    formateExam_time_type(row) {
      let types = {
        '0': '不限',
        '1': '初试',
        '2': '复试',
      }
      return types[row.exam_time_type]
    },
    formatePrice_type(row) {
      let types = {
        '0': '不限',
        '1': '院校',
        '2': '专业',
      }
      if (row.extend_second_price_type) {
        return types[row.price_type] + ' / ' + types[row.extend_second_price_type]
      }
      return types[row.price_type]
    },
    formateSub_price_type(row) {
      if (row.price_type == 1 && row.sub_price_type == 1) {
        return '院校分类'
      } else if (row.price_type == 1 && row.sub_price_type == 2) {
        return '院校地区'
      } else if (row.price_type == 1 && row.sub_price_type == 3) {
        return '指定院校'
      } else if (row.price_type == 2 && row.sub_price_type == 1) {
        return '一级门类'
      } else if (row.price_type == 2 && row.sub_price_type == 2) {
        return '二级学科'
      } else if (row.price_type == 2 && row.sub_price_type == 3) {
        return '指定专业'
      } else {
        return '不限'
      }
    },
    formateExtendSub_price_type(row) {
      if (!row.extend_second_sub_price_type) {
        return ''
      }
      if (row.extend_second_price_type == 1 && row.extend_second_sub_price_type == 1) {
        return ' / 院校分类'
      } else if (row.extend_second_price_type == 1 && row.extend_second_sub_price_type == 2) {
        return ' / 院校地区'
      } else if (row.extend_second_price_type == 1 && row.extend_second_sub_price_type == 3) {
        return ' / 指定院校'
      } else if (row.extend_second_price_type == 2 && row.extend_second_sub_price_type == 1) {
        return ' / 一级门类'
      } else if (row.extend_second_price_type == 2 && row.extend_second_sub_price_type == 2) {
        return ' / 二级学科'
      } else if (row.extend_second_price_type == 2 && row.extend_second_sub_price_type == 3) {
        return ' / 指定专业'
      } else {
        return ' / 不限'
      }
    },
    handleToEdit(row) {
      this.editDialog = true
      this.editId = row.id
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm("确定要删除这些信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map(v => { return v.id })
        orderPriceDeleteList({ ids }).then((response) => {
          let res = response.data
          if (res.code == 0) {
            this.fetchList()
          }
        });
      })
    },
    formateTarget_range(row) {
      if (row.target_ranges && row.target_range_names) {
        if (row.price_type == 2) {
          let arr = row.target_range_names ? row.target_range_names.split(',') : []
          let code_arr = row.target_ranges ? row.target_ranges.split(',') : []
          return arr.map((v, i) => {
            return `（${code_arr[i]}）${v}`
          }).join(',')
        } if (row.price_type == 1 && row.sub_price_type == 1) {
          return row.target_range_names + '院校'
        } else {
          return row.target_range_names
        }
      } else {
        return '不限'
      }
    },

    formateExtendTarget_range(row){
      if (!row.extend_second_sub_price_type) {
        return ''
      }
      if (row.extend_second_target_ranges && row.extend_second_target_range_names) {
        if (row.extend_second_price_type == 2) {
          let arr = row.extend_second_target_range_names ? row.extend_second_target_range_names.split(',') : []
          let code_arr = row.extend_second_target_ranges ? row.extend_second_target_ranges.split(',') : []
          return arr.map((v, i) => {
            return `（${code_arr[i]}）${v}`
          }).join(',')
        } if (row.extend_second_price_type == 1 && row.extend_second_sub_price_type == 1) {
          return row.extend_second_target_range_names + '院校'
        } else {
          return row.extend_second_target_range_names
        }
      } else {
        return '不限'
      }
    }

  }
};
</script>
            
<style lang="less" scoped>
.content {
  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }

  .el-link {
    font-size: 14px;
    margin: 0px 5px;
  }
}
</style>
            