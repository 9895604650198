<template>
  <div>
    <el-dialog top="5vh"
               title="分配订单"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               :close-on-click-modal="false"
               width="657px">
      <el-form :model="dataForm"
               label-position="left"
               ref="formRules"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="匹配师："
                      prop="allocation_manage_ids">
          <el-select v-model="dataForm.allocation_manage_ids"
                     placeholder="请选择"
                     clearable
                     style="width: 100%"
                     multiple
                     filterable
                     @change="$forceUpdate()">
            <el-option v-for="(item, index) in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 5 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="index"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button style="margin-right: auto"
                   type="primary"
                   @click="getConfigureList"
                   slot="">推荐匹配师</el-button>
        <el-button @click="handleToClose()">取消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')"
                   slot="">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
    
   <script>
import { editTa, configureList, getBestAllocationManage } from '@/request/api'

export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null
      },
    },
    row: {
      type: Object,
      default: () => {
        return {}
      },
    },
    manage_ids: {
      type: String,
      default: () => {
        return ''
      },
    },
    second_subject_status: {},
    type: {},
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
        allocation_manage_ids: [],
      },
      matcherId: '',

      rules: {
        allocation_manage_ids: [
          { required: true, message: '请选择匹配师', trigger: 'blur' },
        ],
      },
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        // this.getConfigureList()
        this.$set(
          this.dataForm,
          'allocation_manage_ids',
          this.manage_ids
            ? this.manage_ids.split(',').map((v) => {
                return Number(v)
              })
            : []
        )
      }
    },
  },
  methods: {
    getConfigureList() {
      configureList().then((response) => {
        if (response.data.code == 0) {
          let status = ''
          if (this.type == 1) {
            status = 5
          } else if (this.type == 2) {
            status = 6
          } else if (this.type == 3) {
            status = 7
          }
          this.matcherId = response.data.data.list.find(
            (item) => item.id == status
          ).value
          getBestAllocationManage({
            allocation_order_type: this.type,
            arr_allocation_manage_id: this.matcherId
              .split(',')
              .map((item) => Number(item)),
            second_subject_status: this.second_subject_status,
            same_order_obj: {
              apply_college_code: this.row.apply_college_code,
              apply_subject_codes: this.row.apply_subject_codes,
              apply_subject_names: this.row.apply_subject_names,
            },
          }).then((res) => {
            let list = []
            res.data.data.forEach((item) => {
              list.push(Number(item))
            })
            // console.log("c",list)
            if (res.data.code == 0) {
              this.$set(this.dataForm, 'allocation_manage_ids', list)
              // console.log("ccc",this.dataForm)
              // this.dataForm.allocation_manage_ids = res.data
            }
          })
          // console.log("ccssd",this.matcherId)
        }
        // console.log("Ccc",response)
      })
    },

    handleToClose() {
      this.$emit('close')
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let data = {
            id: this.id,
            allocation_manage_ids:
              this.dataForm.allocation_manage_ids.join(','),
          }
          editTa(data).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
    
    <style lang="less" scoped>
</style>