<template>
  <div class="content">
    <el-dialog
      top="5vh"
      title="学员管理"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="620px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="auto"
        label-position="left"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item label="报名时间：" prop="apply_time">
          <el-date-picker
            style="width: 100%"
            v-model="form.apply_time"
            type="date"
            placeholder="请选择报名时间"
            clearable
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="考研年份：" prop="apply_year">
          <el-select
            v-model="form.apply_year"
            placeholder="请选择"
            clearable
            style="width: 100%"
          >
            <el-option label="2022" value="2022"></el-option>
            <el-option label="2023" value="2023"></el-option>
            <el-option label="2024" value="2024"></el-option>
            <el-option label="2025" value="2025"></el-option>
            <el-option label="2026" value="2026"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生姓名：" prop="student_realname">
          <el-input
            v-model="form.student_realname"
            autocomplete="off"
            clearable
            placeholder="请输入姓名昵称"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input
            placeholder="请输入内容"
            v-model="form.student_phone"
            class="input-with-select"
          >
            <template slot="prepend">手机号</template>
          </el-input>
          <el-input
            placeholder="请输入内容"
            v-model="form.student_wechat"
            class="input-with-select"
          >
            <template slot="prepend">微信号</template>
          </el-input>
          <el-input
            placeholder="请输入内容"
            v-model="form.student_qq"
            class="input-with-select"
          >
            <template slot="prepend">&nbsp;QQ号</template>
          </el-input>
        </el-form-item>

        <el-form-item label="报考院校：" prop="apply_college_name">
          <el-select
            v-model="form.apply_college_code"
            filterable
            collapse-tags
            placeholder="请选择"
            style="width: 100%"
            clearable
            @change="collegeChange"
          >
            <el-option
              v-for="(item, index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报考专业：" prop="major_code">
          <div class="sign">
            <el-select
              filterable
              placeholder="请选择"
              v-model="form.major_code"
              @change="majorChoose"
              clearable
              class="select"
            >
              <el-option
                v-for="(item, index) in majorOptions"
                :key="index"
                :label="`（${item.code}）${item.allnames}`"
                :value="item.code"
              >
                <span>{{ `（${item.code}）${item.allnames}` }}</span>
              </el-option>
            </el-select>
            <div class="sign-icon">
              {{
                majorType == 1 ? "学硕型" : majorType == 2 ? "专硕型" : "暂无"
              }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="报考学院：" prop="apply_academy_name">
          <el-select
            filterable
            placeholder="请选择"
            v-model="form.apply_academy_name"
            @change="academyChoose"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="item.academy_code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试方向：" prop="apply_direction">
          <el-select
            v-model="form.apply_direction"
            placeholder="请选择考试方向"
            clearable
            @change="$forceUpdate()"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in directionsOptions"
              :key="index"
              :label="
                item.direction == '不限'
                  ? item.direction
                  : `（${item.direction_code}）${item.direction}`
              "
              :value="{ value: item.direction_code, label: item.direction }"
            >
              <span>{{
                item.direction == "不限"
                  ? item.direction
                  : `（${item.direction_code}）${item.direction}`
              }}</span>
              <!-- <span>{{ `（${item.direction_code}）${item.direction}` }}</span> -->
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="辅导科目：" prop="subjects">
          <el-select
            v-model="form.subjects"
            multiple
            placeholder="请选择"
            style="width: 100%"
            :disabled="!form.apply_college_code"
            clearable
            :multiple-limit="4"
          >
            <el-option
              v-for="(item, index) in subjectsOptions"
              :key="index"
              :label="item.subject_name"
              :value="item.subject_code"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="报名课程">
          <el-input
            v-model="formateProducts"
            clearable
            disabled
            style="width: 493px"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="上课课时：" prop="service_hours">
          <el-input v-model="form.service_hours" clearable style="width: 100%">
            <template slot="append">小时</template></el-input
          >
        </el-form-item>

        <el-form-item label="复习基础：" prop="coach_need">
          <el-input
            v-model="form.coach_need"
            placeholder="请输入复习基础"
          ></el-input>
        </el-form-item>

        <el-form-item label="参考书目：" prop="refer_book">
          <el-input
            v-model="form.refer_book"
            placeholder="请输入参考书目"
          ></el-input>
        </el-form-item>

        <el-form-item label="服务备注：">
          <el-input v-model="form.remark" type="textarea" clearable></el-input>
        </el-form-item>
        <el-form-item label="对接老师：" prop="manage_watcher">
          <el-select
            @change="$forceUpdate()"
            v-model="form.manage_watcher"
            placeholder="请选择"
            clearable
            filterable
            style="width: 100%"
            multiple
          >
            <el-option
              v-for="(item, index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            >
              <span> {{ `${item.nickname}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="教务老师：" prop="manage_watcher">
          <el-select
            v-model="form.watcher"
            placeholder="请选择"
            clearable
            filterable
            style="width: 100%"
            multiple
          >
            <el-option
              v-for="(item, index) in affairsTeacherList"
              :key="index"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary" @click="submit('formRules')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  majorlist,
  collegeList,
  companycourselist,
  subjectlist,
  academylist,
  companyuserlist,
} from "../../../request/api";
import { adminUrl } from "@/utils/helper";

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      majorType: 0,
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      form: {
        subjects: [],
      },
      majorOptions: [],
      academyOptions: [],
      subjectsOptions: [],
      rules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入你的手机号", trigger: "blur" },
        ],
        wechat: [
          { required: true, message: "请输入你的微信号", trigger: "blur" },
        ],
        qq: [{ required: true, message: "请输入你的QQ号", trigger: "blur" }],
        tags: [
          { required: true, message: "请选择你的学员标签", trigger: "blur" },
        ],
        service_hours: [
          { required: true, message: "请输入辅导课时" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
        apply_year: [
          { required: true, message: "请选择你的考研年份", trigger: "blur" },
        ],
        source: [{ required: true, message: "请选择来源", trigger: "blur" }],
        products: [
          { required: true, message: "请选择报名课程", trigger: "blur" },
        ],
        apply_college_name: [
          { required: true, message: "请选择意向院校", trigger: "blur" },
        ],
        apply_academy_name: [
          { required: true, message: "请选择意向学院", trigger: "blur" },
        ],
        major_code: [
          { required: true, message: "请选择意向专业", trigger: "blur" },
        ],
        watcher: [{ required: true, message: "请选择负责人", trigger: "blur" }],
        subjects: [
          { required: true, message: "请选择辅导科目", trigger: "blur" },
        ],
      },
      directionsOptions: [],
      affairsTeacherList: [],
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.form = {};

        this.fetchDetail();
      }
    },
  },
  mounted() {},
  computed: {
    formateProducts: function () {
      if (this.form.products == null) {
        return "无";
      }
      var str = "";
      this.form.products.forEach((item) => {
        str += item.product_name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
  },
  methods: {
    getAffairsTeacher(cid) {
      let query = {
        isPaging: 0,
        cid: cid,
      };
      companyuserlist(query).then((res) => {
        this.affairsTeacherList = res.data.data.list || [];
        // console.log('this.affairsTeacherList:', this.affairsTeacherList)
      });
    },
    fetchDetail() {
      
      this.form = JSON.parse(JSON.stringify(this.content));
      // console.log('this.form:', this.form)
      // return
      
      this.getAffairsTeacher(this.form.company.id);
      // console.log("this.form:", this.form);
      if (this.form.manage_watcher && this.form.manage_watcher.length) {
        this.form.manage_watcher = this.form.manage_watcher.map(
          (item) => item.id
        );
      }
      if (this.form.student_watcher && this.form.student_watcher.length) {
        this.form.watcher = this.form.student_watcher.map((item) => item.id);
      }
      if (this.form.apply_college_name && this.form.apply_college_code) {
        this.getMajorOptions();
      }
      // if (this.form.apply_major_name) {
      //   if (this.form.apply_major_name.includes("（")) {
      //     this.$set(
      //       this.form,
      //       "apply_major_name",
      //       this.form.apply_major_name.split("）")[1]
      //     );
      //   }
      // }
      if (this.form.apply_major_code) {
        this.$set(this.form, "major_code", this.form.apply_major_code);
        // console.log("major_code",this.form.major_code)
        let type = false;
        if (this.form.apply_direction_code) {
          type = true;
        }
        this.getMajorInfo(
          this.form.apply_college_code,
          this.form.apply_major_code,
          type
        );
      }
      if (this.form.apply_academy_name && this.form.apply_academy_code) {
        this.getSubjectOptions(
          this.form.apply_college_code,
          this.form.apply_academy_code,
          this.form.apply_major_code
        );
      }
      if (this.form.apply_direction_name || this.form.apply_direction_code) {
        this.$set(this.form, "apply_direction", {
          label: this.form.apply_direction_name,
          value: this.form.apply_direction_code,
        });
      }
      //  :label="item.subject_name"
      //           :value="item.subject_code"

      if (this.form.subjects) {
        this.$set(
          this.form,
          "subjects",
          this.form.subjects.map((v) => {
            return v;
          })
        );
      }
      console.log("ooo", this.form);
    },
    emitClose() {
      this.$emit("close");
    },
    // 因为传科目时要上传对象所以在这里做一些处理
    formateSubjects() {
      let arr = [];
      this.form.subjects.forEach((item) => {
        let obj = {};
        obj = this.subjectsOptions.find((v) => {
          return v.subject_code === item; //筛选出匹配数据
        });
        if (obj) {
          arr.push({
            subject_code: obj.subject_code,
            subject_name: obj.subject_name,
          });
        }
      });
      this.form.subjects = arr;
    },
    submit(rules) {
       console.log("submit",this.form)
      //  return
      if (
        this.form.student_phone &&
        !/^1[123456789]\d{9}$/.test(this.form.student_phone)
      ) {
        this.$message({
          message: "请填写正确的手机号",
          type: "error",
          duration: 1000,
        });
        return false;
      }
      if (this.form.subjects) {
        this.formateSubjects();
      }
      this.$refs[rules].validate((valid) => {
        if (valid) {
          delete this.form.creator;
          delete this.form.salesman;

          if (this.form.watcher) {
            this.form.watcher = this.form.watcher.join(",");
          }
          if (this.form.manage_watcher) {
            this.form.manage_watcher = this.form.manage_watcher.join(",");
          }
          // delete this.form.watcher;
          // delete this.form.manage_watcher;
          this.$emit("submit", {
            form: Object.assign({}, this.form, {
              apply_direction_code: this.form.apply_direction
                ? this.form.apply_direction.value
                : "",
              apply_direction_name: this.form.apply_direction
                ? this.form.apply_direction.label
                : "",
            }),
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getMajorOptions() {
      let query = {
        isPaging: 0,
        college_code: this.form.apply_college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data;
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `${v.name}`,
          };
        });
        let typeObj = this.majorOptions.find(
          (item) => item.code == this.form.apply_major_code
        );
        // console.log('typeObj:', typeObj,this.form.apply_major_code)
        this.majorType = typeObj ? typeObj.type : 0;
      });
    },

    collegeChange(e) {
      this.form.apply_major_name = "";
      this.form.apply_major_code = "";
      this.majorOptions = [];
      this.form.apply_academy_name = "";
      this.form.apply_academy_code = "";
      this.academyOptions = [];
      this.form.apply_direction = {};
      this.directionsOptions = [];
      this.form.subjects = [];
      this.subjectsOptions = [];
      if (!e) return;
      let obj = {};
      obj = this.$store.state.collegeList.find((item) => {
        return item.code === e; //筛选出匹配数据
      });
      this.form.apply_college_code = obj.code;
      this.form.apply_college_name = obj.name;
      this.getMajorOptions();
    },
    // 选择学院，获取专业代码与专业名称
    majorChoose(e) {
      this.form.apply_academy_name = "";
      this.form.apply_academy_code = "";
      this.academyOptions = [];
      this.form.apply_direction = {};
      this.directionsOptions = [];
      this.form.subjects = [];
      this.subjectsOptions = [];
      let obj = {};
      if (!e) return;
      obj = this.majorOptions.find((item) => {
        return item.code === e; //筛选出匹配数据
      });

      // console.log('专业obj:', obj)
      this.majorType = obj.type;
      this.form.apply_major_code = obj.code;
      this.form.apply_major_name = obj.name;
      this.getMajorInfo(
        this.form.apply_college_code,
        this.form.apply_major_code
      );
    },
    getMajorInfo(college_code, major_code, type) {
      let str = adminUrl();
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: "get", //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data;
        this.academyOptions = res.data.academys;

        if (this.academyOptions.length == 1) {
          this.form.apply_academy_code = this.academyOptions[0].academy_code;
          this.form.apply_academy_name = this.academyOptions[0].academy_name;
          this.academyList(this.form.apply_academy_code, type);
        } else {
          this.directionsOptions =
         
            this.academyOptions.find((v) => {
              return this.form.apply_academy_code == v.academy_code;
            })?.directions || [];
         console.log('this.directionsOptions:', this.directionsOptions)
          if (
            !this.directionsOptions.length ||
            this.directionsOptions[0].direction != "不限"
          ) {
            this.directionsOptions.unshift({
              direction: "不限",
              direction_code: "",
            });
          }
        }
      });
    },
    // 获取学院代码与学院名称
    academyChoose(e) {
      this.form.apply_direction = {};
      this.directionsOptions = [];
      this.form.subjects = [];
      this.subjectsOptions = [];
      this.academyList(e);

      // this.$set(this.form, "apply_direction", {
      //   label: this.directionsOptions[0].direction,
      //   value: this.directionsOptions[0].direction_code,
      // });
    },
    academyList(e, type) {
      if (!e) return;
      let obj = this.academyOptions.find((item) => {
        return item.academy_code === e; //筛选出匹配数据
      });

      this.form.apply_academy_code = obj.academy_code;
      this.form.apply_academy_name = obj.academy_name;

      this.directionsOptions = obj.directions || [];

      if (
        !this.directionsOptions.length ||
        this.directionsOptions[0].direction != "不限"
      ) {
        this.directionsOptions.unshift({
          direction: "不限",
          direction_code: "",
        });
      }

      if (!type) {
        this.$set(this.form, "apply_direction", {
          label: "不限",
          value: "",
        });
      }

      this.getSubjectOptions(
        this.form.apply_college_code,
        this.form.apply_academy_code,
        this.form.apply_major_code
      );
    },

    getSubjectOptions(college_code, academy_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        academy_code: academy_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        // console.log('辅导科目:', res)
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程",
        };
        this.subjectsOptions = res.data.data.list || [];
        this.subjectsOptions.push(obj);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.sign {
  display: flex;
  .select {
    flex: 1;
    /deep/.el-input__inner {
      border-radius: 4px 0 0 4px !important;
    }
  }
  .sign-icon {
    box-sizing: border-box;
    width: 70px;
    height: 32px;
    line-height: 32px;
    background-color: #f5f7fa;
    color: #909399;
    border: 1px solid #dcdfe6;
    border-left: none;
    text-align: center;
    border-radius: 0px 4px 4px 0px;
  }
}
.input-with-select {
  margin-bottom: 16px;
}
</style>