var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"top":"5vh","title":"关联记录","visible":_vm.dialogFormVisible,"close-on-click-modal":false,"width":"70%"},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":function($event){_vm.dialogFormVisible = false}}},[_c('el-table',{attrs:{"data":_vm.result,"header-cell-style":{
      textAlign: 'center',
      color: '#909399',
      fontFamily: 'PingFang SC',
      fontSize: '14px',
      fontWeight: '400',
      backgroundColor: '#F2F6FC',
    },"cell-style":{ fontSize: '13px' },"border":""}},[_c('el-table-column',{attrs:{"label":"记录日期","align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.create_time ? _vm.$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "")+" ")]}}])}),_c('el-table-column',{attrs:{"label":"服务类型","align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.formateType(scope.row))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"content_text","label":"记录内容","align":"left","show-overflow-tooltip":"","min-width":"300"}}),_c('el-table-column',{attrs:{"prop":"content_text","label":"关联对象","align":"center","min-width":"100","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getName(scope.row))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"附件","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.content_img)?_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":function($event){_vm.openViewer(JSON.parse(scope.row.content_img))}}},[_vm._v("预览")]):(scope.row.content_file)?_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.handleToPreview(scope.row)}}},[_vm._v("预览")]):_c('el-link',{attrs:{"type":"primary","underline":false,"disabled":""}},[_vm._v("预览")])]}}])}),_c('el-table-column',{attrs:{"label":"记录人","width":"120","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.real_record_user_name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"记录人类型","width":"100","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.formateUserType(scope.row))+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }