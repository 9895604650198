<template>
  <div>
    <el-dialog
top="5vh"
      title="课时费"
      :visible.sync="dialogVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="980"
    >
      <el-table
        class="results"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column
          label="辅导日期"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="班级名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="课时费用"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.teacher_lesson_hour_amount?scope.row.teacher_lesson_hour_amount:0}}元/小时
          </template>
        </el-table-column>
        <el-table-column
          label="班级状态"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status==0"
              size="mini"
              type="warning"
            >正在对接</el-tag>
            <el-tag
              v-if="scope.row.status==1"
              size="mini"
              type="success"
            >正在上课</el-tag>
            <el-tag
              v-if="scope.row.status==2"
              size="mini"
              type="info"
            >课程终止</el-tag>
            <el-tag
              v-if="scope.row.status==3"
              size="mini"
              type="danger"
            >课程结束</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          small
          layout="total, prev, pager, next,sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <div class="footer">
        <el-button @click="emitClose()">取消</el-button>
        <el-button
          type="primary"
          @click="emitClose()"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  gradelist
} from "@/request/api";

export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogVisible: this.isVisible,
      results: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 10,

    };
  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogVisible = val;
      if (val) {
        this.query = {
          pageIndex: 1,
          pageSize: 10,
          isPaging: 1,
        }
        this.fetchList();
      }
    },
  },
  methods: {
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      let params = { ...this.query, teacher_id: this.id };
      gradelist(params).then((response) => {
        let res = response.data
        this.results = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    emitClose() {
      this.$emit("close");
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.fetchList();
    },
  },
};
</script>

<style lang="less" scoped>
.item-value {
  font-size: 14px;
  color: #303133;
}
.el-form-item {
  margin-bottom: 8px !important;
}
</style>