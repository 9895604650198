<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>师资共享</el-breadcrumb-item>
        <el-breadcrumb-item>优惠券包</el-breadcrumb-item>
        <el-breadcrumb-item>创建优惠券</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px; padding-top: 20px">
      <TableTittle title="基础设置"></TableTittle>
      <el-form :model="dataForm"
               label-position="right"
               ref="dataForm"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="优惠券名称"
                      prop="name">
          <el-input style="width: 605px"
                    v-model.trim="dataForm.name"
                    placeholder="请输入优惠券名称"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="优惠券分类"
                      prop="coupon_type">
          <el-radio-group v-model="dataForm.coupon_type">
            <el-radio :label="2">接单补贴券</el-radio>
            <el-radio :label="1">发单支付券</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="优惠券组合"
                      prop="arr_single_coupon">
          <el-table :data="dataForm.arr_single_coupon"
                    ref="multipleTable"
                    :header-cell-style="headerCellStyle"
                    :cell-style="{ fontSize: '13px', textAlign: 'center' }"
                    style="width: 350px">
            <el-table-column prop="date"
                             label="面额（元）">
              <template slot-scope="scope">
                <el-select v-model="scope.row.single_circulation_amount"
                           placeholder="请选择">
                  <el-option v-for="item in options1"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column prop="name"
                             label="张数（张）">
              <template slot-scope="scope">
                <el-input v-model="scope.row.single_circulation_count"
                          placeholder="请输入张数"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="80"
                             align="center"
                             fixed="right">
              <template slot-scope="scope">
                <el-link v-if="scope.$index != 0"
                         type="primary"
                         :underline="false"
                         @click="delCoupon(scope.$index)">删除</el-link>
              </template>
            </el-table-column>
          </el-table>
          <div class="addition"
               @click="addCoupon()">添加一行</div>
        </el-form-item>
        <el-form-item label="优惠总价">
          <el-input style="width: 200px"
                    :readonly="true"
                    v-model.trim="totalPrice"
                    clearable>
            <template slot="append">元</template>
          </el-input>
        </el-form-item>

        <div class="row-line"></div>
        <TableTittle title="领取设置"></TableTittle>
        <el-form-item label="领券类型"
                      prop="collect_type">
          <el-radio-group v-model="dataForm.collect_type">
            <el-radio :label="1">等值</el-radio>
            <el-radio :label="2">随机</el-radio>
          </el-radio-group>

          <div class="section"
               v-if="dataForm.collect_type == 2">
            <div class="section-box">
              <div class="symbol">￥</div>
              <el-input placeholder=""
                        v-model="dataForm.collect_random_low_amount"
                        style="flex: auto">
              </el-input>
            </div>
            <div class="line"></div>
            <div class="section-box">
              <div class="symbol">￥</div>
              <el-input placeholder=""
                        v-model="dataForm.collect_random_high_amount"
                        style="flex: auto">
              </el-input>
            </div>
            <div style="margin-left: 11px">之间</div>
          </div>

          <div v-else>
            <el-table :data="[{}]"
                      ref="multipleTable"
                      :header-cell-style="headerCellStyle"
                      :cell-style="{ fontSize: '13px', textAlign: 'center' }"
                      style="width: 350px">
              <el-table-column prop="collect_fix_amount"
                               label="面额（元）">
                <el-select v-model="dataForm.collect_fix_amount"
                           placeholder="请选择">
                  <el-option v-for="item in options1"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-table-column>
              <el-table-column prop="collect_fix_count"
                               label="张数（张）">
                <el-input v-model="dataForm.collect_fix_count"
                          placeholder="请输入张数"></el-input>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item label="每人领取"
                      prop="single_user_collect_count">
          <div class="frequency">
            <div v-for="(item, index) in frequencyList"
                 :key="index"
                 :class="count == index ? 'choose' : ''"
                 @click="claims(index)">
              {{ item }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="有效期"
                      prop="time">
          <el-date-picker v-model="dataForm.time"
                          :picker-options="pickerOptions"
                          type="datetimerange"
                          format="yyyy-MM-dd HH:mm"
                          :default-time="['00:00:00', '23:59:59']"
                          align="right"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="timestamp"
                          @change="dayChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="在线状态"
                      prop="status">
          <!-- <el-switch v-model="dataForm.switch"></el-switch> -->
          <el-switch @change="switchChange"
                     :active-value="1"
                     :inactive-value="0"
                     v-model="dataForm.status"
                     active-color="#13ce66"
                     inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
      </el-form>
      <div class="row-line"></div>
      <el-button type="primary"
                 @click="submit">提交</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </el-card>
  </div>
</template>

<script>
import { crmUrl } from '@/utils/helper.js'
import TableTittle from '@/components/TableTittle/TableTittle.vue'
export default {
  components: {
    TableTittle,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        },
      },
      title: '新增',
      headerCellStyle: {
        textAlign: 'center',
        color: '#909399',
        fontFamily: 'PingFang SC',
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: '#F2F6FC',
      },
      status: 0,
      dataForm: {
        coupon_type: 1,
        collect_type: 1,
        single_user_collect_count: 1,
        time: [],
        collect_random_low_amount: null,
        collect_random_high_amount: null,
        arr_single_coupon: [
          {
            single_circulation_amount: null,
            single_circulation_count: null,
          },
        ],
      },

      options1: [
        // {
        //   label: "0.1",
        //   value: 0.1,
        // },
        {
          label: '5元',
          value: 5,
        },
        {
          label: '10元',
          value: 10,
        },
        {
          label: '20元',
          value: 20,
        },
        {
          label: '30元',
          value: 30,
        },
        {
          label: '50元',
          value: 50,
        },
      ],
      frequencyList: ['1次', '2次', '3次'],
      count: 0,
      rules: {
        name: [
          { required: true, message: '优惠券名称不能为空', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '长度在 2 到 10 个字符',
            trigger: 'blur',
          },
        ],
        arr_single_coupon: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              let testE = /^[1-9](\d*)$/
              for (let item of this.dataForm.arr_single_coupon) {
                if (!item.single_circulation_amount) {
                  callback(new Error(`面额不能为空`))
                } else if (!item.single_circulation_count) {
                  callback(new Error(`张数不能为空`))
                } else if (!testE.test(item.single_circulation_count)) {
                  callback(new Error(`请输入正整数`))
                } else {
                  callback()
                }
              }
            },
          },
        ],
        collect_type: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              let testE = /^[1-9](\d*)$/
              let testR = /^(\d+|\d+\.\d{1,2})$/
              if (this.dataForm.collect_type == 1) {
                if (!this.dataForm.collect_fix_amount) {
                  callback(new Error(`面额不能为空`))
                } else if (!this.dataForm.collect_fix_count) {
                  callback(new Error(`张数不能为空`))
                } else if (!testE.test(this.dataForm.collect_fix_count)) {
                  callback(new Error(`请输入正整数`))
                } else {
                  callback()
                }
              } else {
                if (!this.dataForm.collect_random_low_amount) {
                  callback(new Error(`最低值不能为空`))
                } else if (!this.dataForm.collect_random_high_amount) {
                  callback(new Error(`最高值不能为空`))
                } else if (
                  !testR.test(this.dataForm.collect_random_low_amount)
                ) {
                  callback(new Error(`最低值请输入最多两位小数的正数`))
                } else if (
                  !testR.test(this.dataForm.collect_random_high_amount)
                ) {
                  callback(new Error(`最高值请输入最多两位小数的正数`))
                } else {
                  callback()
                }
              }
            },
          },
        ],
        time: [{ required: true, message: '有效期不能为空', trigger: 'blur' }],
      },
    }
  },
  computed: {
    totalPrice(news, olds) {
      return this.dataForm.arr_single_coupon
        .map(
          (item) =>
            Number(item.single_circulation_amount) *
            Number(item.single_circulation_count)
        )
        .reduce((a, b) => a + b)
    },
  },
  created() {
    // console.log('iddd',this.$route)
    if (this.$route.query.id) {
      this.title = '编辑'
      this.getDetail(this.$route.query.id)
    } else {
      this.title = '新增'
    }
  },
  methods: {
    getDetail(id) {
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/coupon`,
        method: 'get', //可以省略 不写默认是get
        params: { id: id },
        headers: {
          // 设置请求头
          Authorization: localStorage.getItem('admin_token'),
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.dataForm = res.data.data
          this.dataForm.arr_single_coupon = this.dataForm.single_coupon_list
          this.dataForm.time = [
            this.dataForm.start_time,
            this.dataForm.end_time,
          ]
          this.count = Number(this.dataForm.single_user_collect_count) - 1
          if (this.dataForm.status == 2) {
            this.dataForm.status = 0
          }
          // console.log("ccccc",res)
        }
      })
    },
    dayChange() {
      this.dataForm.start_time = this.dataForm.time[0]
      this.dataForm.end_time = this.dataForm.time[1]
      // console.log(this.time)
    },
    switchChange(e) {
      if (e == 1) {
        this.dataForm.status = 1
      } else {
        this.dataForm.status = 0
      }
    },
    claims(index) {
      this.count = index
      this.$set(this.dataForm, 'single_user_collect_count', index + 1)
      // this.dataForm.single_user_collect_count = this.frequencyList[index]
    },
    submit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          let data = {
            ...this.dataForm,
          }
          if (data.collect_type == 1) {
            data.collect_random_low_amount = null
            data.collect_random_high_amount = null
          } else {
            data.collect_fix_amount = null
            data.collect_fix_count = null
            data.collect_random_low_amount = Number(
              data.collect_random_low_amount
            )
            data.collect_random_high_amount = Number(
              data.collect_random_high_amount
            )
          }

          if (this.title == '新增') {
            let str = crmUrl()
            this.$axios({
              url: `${str}/api/v1/sharePlatform/coupon`,
              method: 'post', //可以省略 不写默认是get
              data: data,
              headers: {
                // 设置请求头
                Authorization: localStorage.getItem('admin_token'),
              },
            }).then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: 'success',
                  message: '创建成功!',
                })
                this.$router.go(-1)
              }
            })
          } else {
            let str = crmUrl()
            this.$axios({
              url: `${str}/api/v1/sharePlatform/coupon`,
              method: 'put', //可以省略 不写默认是get
              data: data,
              headers: {
                // 设置请求头
                Authorization: localStorage.getItem('admin_token'),
              },
            }).then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: 'success',
                  message: '创建成功!',
                })
                this.$router.go(-1)
              }
            })
          }
        }
      })
    },
    // 添加优惠券组合
    addCoupon() {
      this.dataForm.arr_single_coupon.push({
        single_circulation_amount: null,
        single_circulation_count: null,
      })
    },
    delCoupon(index) {
      this.dataForm.arr_single_coupon.splice(index, 1)
    },

    // handleInput(row) {
    //   row.input = row.input.replace(/[^\d]/g, "");
    //   // 如果第一个字符是0，则去掉
    //   if (row.input.charAt(0) === "0") {
    //     this.row.input = this.row.input.slice(1);
    //   }
    // },
  },
}
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
.row-line {
  width: 710px;
  height: 1px;
  background: #eeeeee;
  margin-bottom: 25px;
  border-radius: 2px 2px 2px 2px;
}
.addition {
  width: 350px;
  height: 36px;
  border-radius: 4px 4px 4px 4px;
  border: 1px dashed rgba(0, 0, 0, 0.26);
  font-size: 14px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  color: rgba(0, 0, 0, 0.26);
  line-height: 36px;
  text-align: center;
}

.section {
  display: flex;
  align-items: center;

  .line {
    width: 8px;
    height: 1px;
    background: rgba(0, 0, 0, 0.26);
    margin: 0 3px;
  }

  .section-box {
    width: 52px;
    height: 28px;
    background: #feffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dcdcdc;
    display: flex;
    align-items: center;
    padding: 4px;

    .symbol {
      margin-right: 4px;
    }

    /deep/.el-input__inner {
      padding: 0;
      border: none;
      height: 20px !important;
      line-height: 20px !important;
    }
  }
}

.frequency {
  display: flex;
  margin-top: 3px;

  & > div {
    width: 52px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #feffff;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #dcdcdc;
    margin-right: 12px;
    font-size: 14px;
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }

  .choose {
    background-image: url('https://upload.kaoyanbox.net/c2d67a5112245e425cc58a547522aaa8.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #0052d9 !important;
  }
}
</style>