<template>
  <div>
    <el-dialog
top="5vh"
      title="录取信息"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="440px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item label="录取院校：">
          <span class="item-value">{{dataForm.recruit_college_name}}</span>
        </el-form-item>
        <el-form-item label="录取学院：">
          <span class="item-value">{{dataForm.recruit_academy_name}}</span>
        </el-form-item>
        <el-form-item label="录取专业：">
          <span class="item-value">({{dataForm.recruit_major_code}}){{dataForm.recruit_major_name}}</span>
        </el-form-item>
        <el-form-item label="考试科目：">
          <span class="item-value">{{dataForm.test_subject_names}}</span>
        </el-form-item>
        <el-form-item label="各科成绩：">
          <div
            class="item-value"
            v-for="(item,index) in subjects"
            :key="index"
          >({{subjects_code[index]}}) {{item}}：{{subjects_score[index]||0}}分</div>
        </el-form-item>
        <el-form-item label="录取类型：">
          <span class="item-value">{{formateRecruit_type(dataForm.recruit_type)}}</span>
        </el-form-item>
        <el-form-item label="录取院校：">
          <el-image
            style="width:120px;height:120px;"
            :src="dataForm.recruit_certify_img"
            @click="openViewer(dataForm.recruit_certify_img)"
          ></el-image>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose()">取消</el-button>
        <el-button
          type="primary"
          @click="emitClose()"
        >确定</el-button>
      </div>
    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      :url-list="tableImg"
      :on-close="closeViewer"
      :zIndex="999999999"
    />
  </div>
</template>

<script>



import {
  majorlist,
  teacherOne,
  teacherEdit
} from "@/request/api";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {
    ElImageViewer
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
        recruit_college: [{ required: true, message: "请选择录取院校", trigger: "blur" }],
        recruit_major: [{ required: true, message: "请选择录取专业", trigger: "blur" }],
      },
      subjects: [],
      subjects_code: [],
      subjects_score: [],
      tableImg: [],
      showViewer: false,
    };
  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      teacherOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data
        if (res.data.test_subject_names) {
          this.subjects = res.data.test_subject_names.split(',')
        }
        if (res.data.test_subject_codes) {
          this.subjects_code = res.data.test_subject_codes.split(',')
        }
        if (res.data.test_subject_scores) {
          this.subjects_score = res.data.test_subject_scores.split(',')
        }
      });
    },
    emitClose() {
      this.$emit("close");
    },
    formateRecruit_type(type) {
      const types = {
        1: "第一志愿上岸",
        2: "调剂上岸",
      };
      return types[type] || "";
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.tableImg = [url];
      this.showViewer = true;
    },
    /*     submit(rules) {
          this.$refs[rules].validate((valid) => {
            if (valid) {
              let query = {
                id: this.dataForm.id,
                cid: localStorage.getItem("cid"),
                recruit_college_code: this.dataForm.recruit_college ? this.dataForm.recruit_college.value : "",
                recruit_college_name: this.dataForm.recruit_college ? this.dataForm.recruit_college.label : "",
                recruit_major_code: this.dataForm.recruit_major ? this.dataForm.recruit_major.value : "",
                recruit_major_name: this.dataForm.recruit_major ? `(${this.dataForm.recruit_major.value})${this.dataForm.recruit_major.label}` : "",
                realname: this.dataForm.realname,
                gender: this.dataForm.gender,
                recruit_year: this.dataForm.recruit_year,
                manage_remark: this.dataForm.manage_remark,
              }
              teacherEdit(query).then((response) => {
                let res = response.data
                if (res.code == 0) {
                  this.$message.success('修改成功')
                  this.emitClose()
                  this.$emit('submit')
                }
              });
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        }, */
  },
};
</script>

<style lang="less" scoped>
.item-value {
  font-size: 14px;
  color: #303133;
}
.el-form-item {
  margin-bottom: 8px !important;
}
</style>