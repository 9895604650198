<template>
  <div class="dialog-wrap">
    <el-dialog
      top="5vh"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      :show-close="false"
      width="1280px"
    >
      <div class="header">
        <div class="title">老师列表</div>
        <div class="dialog-footer">
          <!-- <el-button type="primary" @click="handleToChoose" size="small">新 增</el-button> -->
          <el-button @click="handleClose" size="small">关 闭</el-button>
        </div>
      </div>

      <el-table
        :data="confirm_result"
        row-key="id"
        :header-cell-style="{
          backgroundColor: '#F2F6FC',
        }"
        border
        v-loading="loading"
      >
        <el-table-column prop="id" label="ID" width="100" align="center">
        </el-table-column>
        <el-table-column
          prop="realname"
          label="姓名"
          align="center"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.realname }}
            {{
              scope.row.teacher_hide_name_status
                ? `（${scope.row.nickname}）`
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="性别" align="center" width="50">
          <template slot-scope="scope">
            {{ scope.row.gender == 1 ? "男" : "女" }}
          </template>
        </el-table-column>

        <!-- <el-table-column
          label="课时费"
          align="center"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              >{{
                scope.row.teacher_lesson_hour_amount
                  ? scope.row.teacher_lesson_hour_amount.toFixed(2)
                  : 0
              }}元/小时</span
            >
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="班级授课状态" align="center" width="120">
          <template slot-scope="scope">
            <el-tag size="mini" v-show="scope.row.teacher_grade_status == 1"
              >正常</el-tag
            >
            <el-tag
              size="mini"
              type="info"
              v-show="scope.row.teacher_grade_status == 0"
              >终止</el-tag
            >
          </template>
        </el-table-column> -->
        <el-table-column
          prop="manage_remark"
          label="备注"
          width="300"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- <el-table-column
          label="创建人"
          align="center"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <el-table-column label="创建日期" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="修改日期" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="260" align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToDetail(scope.row)"
              >查看详情</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <SeniorDetail
      :isVisible="SeniorDetailDialog"
      @close="SeniorDetailDialog = false"
      :id="detailId"
    />
  </div>
</template>

<script>
import { teacherlist } from "@/request/api";
// import { manageUrl } from "@/utils/helper";
import SeniorDetail from "./SeniorDetail.vue";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      default: () => null,
    },
  },
  components: {
    SeniorDetail,
  },
  data() {
    return {
      loading: false,
      dialogVisible: this.isVisible,
      confirm_result: [],
      detailId: "",
      SeniorDetailDialog: false,

      // editId:'',
      // editDialog:false
    };
  },
  watch: {
    isVisible(val) {
      this.dialogVisible = val;
      if (val) {
        this.getDataList();
      }
    },
  },
  created() {},
  methods: {
    getDataList() {
      this.loading = true;
      teacherlist({
        isPaging: 0,
        ids: this.id
      }).then(res => {
        // console.log("r", res)
        this.loading = false
        if (res.data.code == 0) {
          this.confirm_result = res.data.data.list
        }

      })
      // let str = manageUrl();
      // this.$axios({
      //   url: `${str}/api/v1/teacher/teacher/list`,
      //   method: "get", //可以省略 不写默认是get
      //   params: {
      //     isPaging: 0,
      //     ids: this.id,
      //   },
      // }).then((res) => {
      //   this.loading = false;
      //   if (res.code == 0) {
      //     this.confirm_result = res.data.list;
      //   }
      // });
    },

    handleClose() {
      this.$emit("close");
    },
    handleToDetail(row) {
      this.detailId = row.id;
      this.SeniorDetailDialog = true;
    },
  },
};
</script>


<style lang="less" scoped>
/deep/.el-dialog__body {
  padding-top: 0px;
}
 .header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #333;
    }
  }
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }

  .result {
    margin-top: 10px;
  }

  .footer {
    margin-top: 10px;
  }

  .now {
    margin-left: 16px;
    color: red;
  }

 

  .img-list {
    margin-top: 12px;
    display: flex;
    align-items: center;

    img {
      width: 140px;
      height: 140px;
      margin-right: 12px;
      border-radius: 4px;
    }
  }
}
</style>