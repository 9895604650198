<template>
  <div>
    <el-dialog
      top="5vh"
      title="新建匹配需求"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="657px"
    >
      <el-form
        :model="dataForm"
        label-position="left"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <!--   <el-form-item
              label="对象："
              prop="realname"
            >
              <el-input
                v-model="dataForm.realname"
                placeholder="请输入对象名称"
                style="width: 100%;"
              ></el-input>
            </el-form-item> -->
        <el-form-item
          label="考研年份："
          prop="apply_year"
        >
          <el-select
            v-model="dataForm.apply_year"
            placeholder="请选择考研年份"
            clearable
            style="width: 100%;"
          >
            <el-option
              label="2022"
              value="2022"
            ></el-option>
            <el-option
              label="2023"
              value="2023"
            ></el-option>
            <el-option
              label="2024"
              value="2024"
            ></el-option>
            <el-option
              label="2025"
              value="2025"
            ></el-option>
            <el-option
              label="2026"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考院校："
          prop="apply_college"
        >
          <el-select
            v-model="dataForm.apply_college"
            placeholder="请选择报考院校"
            filterable
            clearable
            @change="collegeChange"
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考专业："
          prop="apply_major"
        >
          <el-select
            v-model="dataForm.apply_major"
            placeholder="请选择报考专业"
            @change="majorChange"
            clearable
            filterable
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.allnames"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考学院："
          prop="apply_major"
        >
          <el-select
            v-model="dataForm.apply_academy"
            placeholder="请选择报考学院"
            clearable
            @change="academyChange"
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="{ value: item.academy_code, label: item.academy_name,directions:item.directions}"
            >
              <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="考试方向："
          prop="apply_major"
        >
          <el-select
            v-model="dataForm.apply_direction"
            placeholder="请选择考试方向"
            clearable
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in directionsOptions"
              :key="index"
              :label="item.direction"
              :value="{ value: item.direction_code, label: item.direction}"
            >
              <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="辅导科目："
          prop="apply_subjects"
        >
          <el-select
            style="width:100%;"
            v-model="dataForm.apply_subjects"
            placeholder="请选择辅导科目"
            clearable
            multiple
            @change="$forceUpdate()"
          >
            <el-option
              v-for="(item,index) in subjectOptions"
              :key="index"
              :label="item.subject_name"
              :value="{ value: item.subject_code, label: item.subject_name,score:0}"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="辅导课时："
          prop="lesson_hours"
        >
          <el-input
            placeholder="请输入辅导课时"
            v-model="dataForm.lesson_hours"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          >
            <span slot="append">小时</span>
          </el-input>
        </el-form-item>

         <el-form-item label="复习基础：" prop="coach_need">
          <el-input
            type="textarea"
            v-model="dataForm.coach_need"
            placeholder="请输入复习基础"
          ></el-input>
        </el-form-item>
        <el-form-item label="参考书目" prop="refer_book">
          <el-input
            type="textarea"
            v-model="dataForm.refer_book"
            placeholder="请输入参考书目"
          ></el-input>
        </el-form-item>


        <el-form-item
          label="匹配要求："
          prop="allocation_demand"
        >
          <el-input
            type="textarea"
            v-model="dataForm.allocation_demand"
            placeholder="请输入匹配要求"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="师资数量："
          prop="count_teacher_need"
        >
          <div class="count-wrap">
            <div
              class="count-item"
              @click="chooseCount(1)"
              :class="{'choose':count==1}"
            >1位</div>
            <div
              class="count-item"
              @click="chooseCount(2)"
              :class="{'choose':count==2}"
            >2位</div>
            <div
              class="count-item"
              @click="chooseCount(3)"
              :class="{'choose':count==3}"
            >3位</div>

            <div
              class="count-item"
              @click="chooseCount(0)"
              :class="{'choose':count==0}"
              v-if="count!=0"
            >更多</div>
            <div
              class="count-item"
              v-if="count==0"
            >
              <el-input
                v-model="countInput"
                placeholder="请输入"
                style="width: 60px"
              >
              </el-input>
              位
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="是否加急："
          prop="urgency_status"
        >
          <el-radio-group v-model="dataForm.urgency_status">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
          slot=""
        >确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
        
       <script>



import {
  academylist,
  majorlist,
  subjectlist,
  dispatchOrderOne,
  createTa
} from "@/request/api";
import { adminUrl } from "@/utils/helper";



export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        apply_year: [{ required: true, message: "请选择考研年份", trigger: "blur" }],
        apply_college: [{ required: true, message: "请选择报考院校", trigger: "blur" }],
        apply_major: [{ required: true, message: "请选择报考专业", trigger: "blur" }],
        apply_academy: [{ required: true, message: "请选择报考学院", trigger: "blur" }],
        apply_subjects: [{ required: true, message: "请选择辅导科目", trigger: "blur" }],
        apply_direction: [{ required: true, message: "请选择研究方向", trigger: "blur" }],
        lesson_hours: [{ required: true, message: "请输入辅导课时", trigger: "blur" }],
        urgency_status: [{ required: true, message: "请选择是否加急", trigger: "blur" }],
        allocation_demand: [{ required: true, message: "请输入匹配要求", trigger: "blur" }],
        count_teacher_need: [{
          required: true,
          validator: (rule, value, callback) => {
            if (this.count <= 0 && this.countInput <= 0) {
              callback(new Error('请选择师资数量'))
            } else {
              callback()
            }
          },
        }],
      },
      majorOptions: [],// 专业数组
      academyOptions: [],// 学院数组
      subjectOptions: [],//科目数组
      directionsOptions: [],//方向数组
      count: 1,
      countInput: 0,

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      dispatchOrderOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data
        if (this.dataForm.apply_college_name && this.dataForm.apply_college_code) {
          this.$set(this.dataForm, 'apply_college', {
            label: this.dataForm.apply_college_name,
            value: this.dataForm.apply_college_code,
          })
          this.getMajor(this.dataForm.apply_college_code)
        }
        if (this.dataForm.apply_major_name && this.dataForm.apply_major_code) {
          this.$set(this.dataForm, 'apply_major', {
            label: this.dataForm.apply_major_name,
            value: this.dataForm.apply_major_code,
          })
          this.getMajorInfo(this.dataForm.apply_college_code, this.dataForm.apply_major_code)
        }
        if (this.dataForm.apply_academy_name && this.dataForm.apply_academy_code) {
          this.$set(this.dataForm, 'apply_academy', {
            label: this.dataForm.apply_academy_name,
            value: this.dataForm.apply_academy_code,
          })
          this.getSubjects()
        }
        if (this.dataForm.subjects && this.dataForm.subjects.length) {
          this.$set(this.dataForm, 'apply_subjects', this.dataForm.subjects.map(v => {
            return {
              value: v.subject_code,
              label: v.subject_name,
            }
          }))
        }
        this.$set(this.dataForm, 'urgency_status', 0)
        this.$set(this.dataForm, 'allocation_demand', res.data.remark ? res.data.remark : '无')

        this.$set(this.dataForm, 'apply_year', 2024)
        this.$set(this.dataForm, 'lesson_hours', res.data.hours ? res.data.hours : 0)
      });
    },
    handleToClose() {
      this.$emit("close");
    },
    collegeChange(e) {
      this.dataForm.apply_major = {}
      this.majorOptions = []
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      if (e) {
        this.getMajor(e.value);
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map(v => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`
          }
        })
      });
    },
    majorChange(e) {
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.directions = []

      if (e) {
        this.getMajorInfo(this.dataForm.apply_college.value, e.value);
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get',//可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then(response => {
        let res = response.data
        this.academyOptions = res.data.academys
        this.directionsOptions = this.academyOptions.find(v => {
          return this.dataForm.apply_academy_code == v.academy_code
        }).directions
        this.$set(this.dataForm, 'apply_direction', {
          label: this.directionsOptions[0].direction,
          value: this.directionsOptions[0].direction_code,
        })
      })

    },
    academyChange(e) {
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.$forceUpdate()
      if (e) {
        this.directionsOptions = e.directions
        this.getSubjects();
      }
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.dataForm.apply_college.value,
        academy_code: this.dataForm.apply_academy.value,
        major_code: this.dataForm.apply_major.value,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程"
        }
        this.subjectOptions = res.data.data.list || [];
        this.subjectOptions.push(obj)
      });
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let data = {
            type: 3,
            teacher_dispatch_order_id: this.dataForm.id,
            cid: this.dataForm.cid,
            apply_college_code: this.dataForm.apply_college ? this.dataForm.apply_college.value : "",
            apply_college_name: this.dataForm.apply_college ? this.dataForm.apply_college.label : "",
            apply_major_code: this.dataForm.apply_major ? this.dataForm.apply_major.value : "",
            apply_major_name: this.dataForm.apply_major ? this.dataForm.apply_major.label : "",
            apply_academy_code: this.dataForm.apply_academy ? this.dataForm.apply_academy.value : "",
            apply_academy_name: this.dataForm.apply_academy ? this.dataForm.apply_academy.label : "",
            apply_direction_code: this.dataForm.apply_direction ? this.dataForm.apply_direction.value : "",
            apply_direction_name: this.dataForm.apply_direction ? this.dataForm.apply_direction.label : "",
            apply_year: this.dataForm.apply_year,
            apply_subject_names: this.dataForm.apply_subjects && this.dataForm.apply_subjects.length ? this.dataForm.apply_subjects.map((v => {
              return v.label
            })).join(',') : '',
            apply_subject_codes: this.dataForm.apply_subjects && this.dataForm.apply_subjects.length ? this.dataForm.apply_subjects.map((v => {
              return v.value
            })).join(',') : '',
            lesson_hours: this.dataForm.lesson_hours,
            allocation_demand: this.dataForm.allocation_demand,
            urgency_status: this.dataForm.urgency_status,
            count_teacher_need: this.computedCount(),
            coach_need: this.dataForm.coach_need,
            refer_book: this.dataForm.refer_book,
          }
          createTa(data).then((response) => {
            let res = response.data
            console.log(res);
            if (res.code == 0) {
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    chooseCount(num) {
      this.count = num
    },
    computedCount() {
      if (this.count <= 3) {
        return this.count
      } else if (this.count > 3) {
        return this.countInput
      }
    }
  },
};
  </script>
        
  <style lang="less" scoped>
.title {
  font-size: 18px;
  font-weight: 600;
  color: #303133;
  margin-bottom: 12px;
}
.module {
}
/deep/.el-alert__icon {
  color: #266fe8;
}
/deep/.el-alert__title {
  color: #333;
}
/deep/.el-input-group__prepend {
  min-width: 110px;
}
.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  text-align: center;
}
.avatar {
  width: 140px;
  height: 140px;
}
.count-wrap {
  display: flex;
  align-items: center;
  .count-item {
    padding: 0px 18px;
    font-size: 14px;
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    color: rgba(0, 0, 0, 0.6);
    border: 1px solid #dcdcdc;
    margin-right: 12px;
    cursor: pointer;
    /deep/.el-input__inner {
      border: none;
      background: transparent;
    }
  }
  .choose {
    background-image: url("https://upload.kaoyanbox.net/c2d67a5112245e425cc58a547522aaa8.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>