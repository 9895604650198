<template>
  <el-dialog
top="5vh"
    title="添加子级权限"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="520px"
    @close="emitClose"
  >
    <el-form
      :model="newform"
      ref="dialogform"
      :rules="rules"
      label-position="right"
      label-width="110px"
      size="small"
    >
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="newform.type">
          <el-radio-button :label="1">目录</el-radio-button>
          <el-radio-button :label="2">菜单</el-radio-button>
          <el-radio-button :label="3">按钮</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model="newform.name"></el-input>
      </el-form-item>
      <el-form-item label="路由地址" v-if="newform.type != 1">
        <el-input v-model="newform.url"></el-input>
      </el-form-item>
      <el-form-item label="权限标识码" v-if="newform.type != 1">
        <el-input v-model="newform.code"></el-input>
      </el-form-item>
      <el-form-item label="外链状态" prop="link_status">
        <el-radio-group v-model="newform.link_status">
          <el-radio-button :label="1">外链</el-radio-button>
          <el-radio-button :label="0">内部</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="隐藏状态" prop="hide_status">
        <el-radio-group v-model="newform.hide_status">
          <el-radio-button :label="1">隐藏</el-radio-button>
          <el-radio-button :label="0">展示</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态">
        <el-switch
          v-model="newform.status"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" v-model="newform.remark"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit('dialogform')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      newform: {
        id: "",
        link_status: 0,
        hide_status: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        url: [{ required: true, message: "请输入路由地址", trigger: "blur" }],
        code: [
          { required: true, message: "请输入权限标识码", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        link_status: [
          { required: true, message: "请选择外链状态", trigger: "blur" },
        ],
        hide_status: [
          { required: true, message: "请选择隐藏状态", trigger: "blur" },
        ],
        status: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      },
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.newform.pid = val.id;
    },
  },
  methods: {
    emitClose() {
      this.newform = {};
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.$emit("submit", { form: this.newform });
          this.newform = {};
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
.el-input {
  width: 318px;
}
</style>