<template>
  <div>
    <div class="form_main">
      <div class="input-item">
        <img
          src="../../../assets/images/phone.png"
          alt=""
        />
        <input
          type="text"
          placeholder="请输入用户名"
          v-model="phone"
        />
      </div>
      <div class="input-item">
        <img
          src="../../../assets/images/password.png"
          alt=""
        />
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="yzcode"
        />
        <el-button
          size="small"
          :disabled="codedisabled"
          @click="sendsms"
        >{{
          yzcodetext
        }}</el-button>
      </div>
    </div>
    <div class="submit-div">
      <el-button
        :loading="loading"
        @click="login"
      >{{
        loading ? "登陆中..." : "点击登录"
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { smscode, userlogin, userpermissions } from "../../../request/api";
export default {
  data() {
    return {
      phone: "",
      yzcodetext: "获取验证码",
      codeTime: 0,
      codedisabled: false,
      yzcode: "",
      loading: false,
    };
  },
  methods: {
    sendsms() {
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone)) {
        this.$notify.error({
          title: "请输入正确的手机号",
          showClose: false,
          duration: 2000,
        });
        return false;
      } else {
        this.codeTime = 60;
        this.codedisabled = true;
        let query = {
          phone: this.phone,
        };
        smscode(query).then((res) => {
          this.timer();
        });
      }
    },
    timer() {
      if (this.codeTime > 0) {
        this.codeTime--;
        this.yzcodetext = this.codeTime + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.codeTime = 0;
        this.yzcodetext = "获取验证码";
        this.codedisabled = false;
      }
    },
    login() {
      if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.phone)) {
        this.$notify.error({
          title: "请输入正确的手机号",
          showClose: false,
          duration: 2000,
        });
        return false;
      }
      if (!this.yzcode) {
        this.$notify.error({
          title: "请输入验证码",
          showClose: false,
          duration: 2000,
        });
        return false;
      }
      this.loading = true;
      let query = {
        phone: this.phone,
        code: this.yzcode,
      };
      userlogin(query).then((res) => {
        this.loading = false;
        localStorage.setItem("userInfo", JSON.stringify(res.data.data));
        localStorage.setItem("token", res.data.data.token);
        this.companys = res.data.data.companys;
        userpermissions().then((res) => {
          localStorage.setItem(
            "permissions",
            JSON.stringify(res.data.data.list)
          );
          if (res.data.code == 0) {
            this.$router.replace({ path: "/index" });
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form_main {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .input-item {
    margin-bottom: 72px;
    height: 45px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3e6ef;
    img {
      width: 24px;
      height: 24px;
    }
    input {
      margin-left: 32px;
      padding: 0 8px;
      height: 100%;
      border: none;
      width: 80%;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 28px;
      color: #252631;
      opacity: 1;
      outline: 0;
    }
  }
}
.submit-div {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
  .el-button {
    width: 100%;
    height: 49px;
    background: #409eff;
    opacity: 1;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 22px;
    color: #ffffff;
    opacity: 1;
  }
}
.agreement-div {
  position: absolute;
  width: 87%;
  top: 89%;
  left: 10%;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #c0c4cc;
    opacity: 1;
  }
}
</style>