import { render, staticRenderFns } from "./OrderConfirmList.vue?vue&type=template&id=3df3b9af"
import script from "./OrderConfirmList.vue?vue&type=script&lang=js"
export * from "./OrderConfirmList.vue?vue&type=script&lang=js"
import style0 from "./OrderConfirmList.vue?vue&type=style&index=0&id=3df3b9af&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports