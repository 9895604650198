<template>
  <div>
    <el-dialog
      append-to-body 
      top="5vh"
      title="班级管理"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="班级类型："
          prop="type"
        >
          <el-select
            v-model="dataForm.type"
            placeholder="请选择班级类型"
            clearable
            @change="typeChange"
          >
            <el-option
              label="一对一"
              :value="0"
            ></el-option>
            <el-option
              label="小班课"
              :value="1"
            ></el-option>
            <el-option
              label="大班课"
              :value="2"
            ></el-option>
            <el-option
              label="试听课"
              :value="3"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="考研年份："
          prop="apply_year"
        >
          <el-select
            v-model="dataForm.apply_year"
            placeholder="请选择考研年份"
            clearable
          >
            <el-option
              label="2022"
              value="2022"
            ></el-option>
            <el-option
              label="2023"
              value="2023"
            ></el-option>
            <el-option
              label="2024"
              value="2024"
            ></el-option>
            <el-option
              label="2025"
              value="2025"
            ></el-option>
            <el-option
              label="2026"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考信息："
          prop="apply_college"
        >
          <el-select
            v-model="dataForm.apply_college"
            placeholder="请选择院校"
            filterable
            clearable
            @change="collegeChange"
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="apply_major"
        >
          <el-select
            v-model="dataForm.apply_major"
            placeholder="请选择专业"
            @change="majorChange"
            clearable
            filterable
          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.allnames"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="apply_major"
        >
          <el-select
            v-model="dataForm.apply_academy"
            placeholder="请选择学院"
            clearable
            @change="academyChange"
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="{ value: item.academy_code, label: item.academy_name,directions:item.directions}"
            >
              <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="dataForm.apply_direction"
            placeholder="请选择考试方向"
            @change="$forceUpdate()"
            clearable
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in directionsOptions"
              :key="index"
              :label="item.direction"
              :value="{ value: item.direction_code, label: item.direction}"
            >
              <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="apply_subjects"
        >
          <el-select
            style="width:100%;"
            v-model="dataForm.apply_subjects"
            placeholder="请选择科目"
            clearable
            multiple
            @change="$forceUpdate()"
          >
            <el-option
              v-for="(item,index) in subjectOptions"
              :key="index"
              :label="item.subject_name"
              :value="{ value: item.subject_code, label: item.subject_name}"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>

            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="班级名称："
          prop="name"
        >
          <el-input
            v-model="dataForm.name"
            placeholder="请输入班级名称"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="辅导课时："
          prop="cocah_lesson_hour"
        >
          <el-input
            placeholder="请输入辅导课时"
            v-model="dataForm.cocah_lesson_hour"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            @blur="hoursBlur"
          >
            <span slot="append">小时</span>
          </el-input>
        </el-form-item>
        <!--      <el-form-item
          label=""
          v-if="tipsText"
        >
          <div class="tips">
            {{tipsText}}
          </div>
        </el-form-item> -->
         <el-form-item
          label="复习基础："
          prop="coach_need"
        >
          <el-input
            type="textarea"
            v-model="dataForm.coach_need"
            placeholder="请输入复习基础"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="参考书目"
          prop="refer_book"
        >
          <el-input
            type="textarea"
            v-model="dataForm.refer_book"
            placeholder="请输入参考书目"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="备注："
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="dataForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="学管师："
          prop="learn_manager"
        >
          <el-select
            v-model="dataForm.learn_manager"
            placeholder="请选择学管师"
            clearable
          >
            <el-option
              v-for="(item,index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>

      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  academylist,
  majorlist,
  gradeCreate,
  gradeOne,
  subjectlist,
  commonHandleLogCreate
} from "@/request/api";
import {
  getStorage
} from "@/utils/helper";
import { adminUrl } from "@/utils/helper";

export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Number,
      default: 1,
    }
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
        cocah_lesson_hour: 1
      },
      rules: {
        name: [{ required: true, message: "请输入班级名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择班级类型", trigger: "blur" }],
        cocah_lesson_hour: [{ required: true, message: "请选择辅导课时", trigger: "blur" },
        { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }],
        learn_manager: [{ required: true, message: "请选择学管师", trigger: "blur" }],
        apply_year: [{ required: true, message: "请选择考研年份", trigger: "blur" }],
        apply_college: [{ required: true, message: "请选择院校", trigger: "blur" }],
        apply_major: [{ required: true, message: "请选择专业", trigger: "blur" }],
        apply_academy: [{ required: true, message: "请选择学院", trigger: "blur" }],
        apply_subjects: [{ required: true, message: "请选择科目", trigger: "blur" }],

      },
      majorOptions: [],// 专业数组
      academyOptions: [],// 学院数组
      subjectOptions: [],//科目数组
      companySelect: 1,
      tipsText: "",
      directionsOptions: [],


    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.$set(this.dataForm, 'learn_manager', getStorage('userInfo').id)
        this.companySelect = 2
        this.hoursBlur()
      }
    },
  },
  methods: {
    emitClose() {
      this.dataForm = {
      }
      this.$emit("close");
    },
    collegeChange(e) {
      this.dataForm.apply_major = {}
      this.majorOptions = []
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      if (e) {
        this.getMajor(e.value);
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map(v => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`
          }
        })
      });
    },
    majorChange(e) {
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      if (e) {
        this.getMajorInfo(this.dataForm.apply_college.value, e.value);
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get',//可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then(response => {
        let res = response.data
        this.academyOptions = res.data.academys

        if(this.academyOptions.length==1){
          this.dataForm.apply_academy = {
            value: this.academyOptions[0].academy_code,
            label:this.academyOptions[0].academy_name,
            directions:this.academyOptions[0].directions
          }
          this.academyChange( this.dataForm.apply_academy)
        }


      })
    },
    academyChange(e) {
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      this.$forceUpdate()
      let num = Math.floor(Math.random() * 100)
      if (e) {
        this.directionsOptions = e.directions
        let str = this.dataForm.name ? this.dataForm.name : ''
        this.$set(this.dataForm, 'name', `${this.dataForm.apply_year ? this.dataForm.apply_year.substring(2) : ''}${this.dataForm.apply_college.label}${this.dataForm.apply_major.label}${num}班${str}`)
        this.getSubjects();
      }
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.dataForm.apply_college.value,
        academy_code: this.dataForm.apply_academy.value,
        major_code: this.dataForm.apply_major.value,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程"
        }
        this.subjectOptions = res.data.data.list || [];
        this.subjectOptions.push(obj)
      });
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            cid: localStorage.getItem("cid"),
            apply_college_code: this.dataForm.apply_college ? this.dataForm.apply_college.value : "",
            apply_college_name: this.dataForm.apply_college ? this.dataForm.apply_college.label : "",
            apply_major_code: this.dataForm.apply_major ? this.dataForm.apply_major.value : "",
            apply_major_name: this.dataForm.apply_major ? this.dataForm.apply_major.label : "",
            apply_academy_code: this.dataForm.apply_academy ? this.dataForm.apply_academy.value : "",
            apply_academy_name: this.dataForm.apply_academy ? this.dataForm.apply_academy.label : "",
            apply_direction_code: this.dataForm.apply_direction ? this.dataForm.apply_direction.value : "",
            apply_direction_name: this.dataForm.apply_direction ? this.dataForm.apply_direction.label : "",
            apply_year: this.dataForm.apply_year,
            apply_subjects: this.dataForm.apply_subjects.map(v => {
              return {
                subject_code: v.value,
                subject_name: v.label,
              }
            }),
            name: this.dataForm.name,
            type: this.dataForm.type,
            cocah_lesson_hour: this.companySelect == 2 ? this.dataForm.cocah_lesson_hour : (Number(this.dataForm.cocah_lesson_hour) * 0.75).toFixed(2),
            learn_manager: this.dataForm.learn_manager,
             coach_need:this.dataForm.coach_need,
            refer_book:this.dataForm.refer_book,
            remark: this.dataForm.remark,
          }
          gradeCreate(query).then((res) => {
            if (res.data.code == 0) {
              this.createLog(res.data.data.insertId)
              /*               this.emitClose()
                            this.$emit('submit') */
            }
          });
        } else {
          console.log("error sxubmit!!");
          return false;
        }
      });
    },
    createLog(id) {
      let query = {
        ref_id: id,
        handle_type: 0,
        handle_child_type: 0,
        handle_content: '新建了班级',
      }
      commonHandleLogCreate(query).then((res) => {
        if (res.data.code == 0) {
          this.emitClose()
          this.$emit('submit')
        }
      });
    },
    hoursBlur() {
      if (!/^(\d+|\d+\.\d{1,2})$/.test(this.dataForm.cocah_lesson_hour)) {
        this.tipsText = "输入最多两位小数的数字"
      } else {
        if (this.companySelect == 2) {
          this.tipsText = `${this.dataForm.cocah_lesson_hour}小时等于${(this.dataForm.cocah_lesson_hour * 1.33).toFixed(2)}课时`
        } else if (this.companySelect == 1) {
          this.tipsText = `${this.dataForm.cocah_lesson_hour}课时等于${(this.dataForm.cocah_lesson_hour * 0.75).toFixed(2)}小时`
        }
      }
    },
    typeChange(e) {
      if (e == 3) {
        if (this.dataForm.name && this.dataForm.name.indexOf('一对一试听课')) {
          return
        }
        let str = this.dataForm.name ? this.dataForm.name : ''
        this.$set(this.dataForm, 'name', str + '一对一试听课')
      }
    }
  },
};
</script>

<style lang="less" scoped>
.content {
}
</style>