var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"top":"5vh","title":"","visible":_vm.dialogFormVisible,"close-on-click-modal":false,"width":"470px"},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-form',[_c('el-form-item',{attrs:{"label":"学管师"}},[_c('el-select',{staticStyle:{"width":"400px"},attrs:{"filterable":"","placeholder":"请选择"},on:{"change":function($event){return _vm.$forceUpdate()}},model:{value:(_vm.form.watcher),callback:function ($$v) {_vm.$set(_vm.form, "watcher", $$v)},expression:"form.watcher"}},_vm._l((_vm.$store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 4 && v.status == 1;
                  }) != -1
                );
              })),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.nickname,"value":item.id}},[_c('span',[_vm._v(" "+_vm._s(`${item.nickname}-${item.phone}`))])])}),1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.emitClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }