import Order from "../views/ShareOrder/order/index.vue";
import Record from "../views/ShareOrder/record/index.vue";
import HelpCenter from "../views/ShareOrder/helpCenter/index.vue"
import Advertising from "../views/ShareOrder/advertising/index.vue"
import Topupgift from "../views/ShareOrder/topUpGift/index.vue"
import Discountroll from "../views/ShareOrder/discountRoll/index.vue"
import Datamanagement from "../views/ShareOrder/dataManagement/index.vue"
import Discountrolladd  from "../views/ShareOrder/discountRoll/discountRollAdd.vue";
import Reserveteachers from "../views/ShareOrder/reserveTeachers/index.vue";
import LeaveWord from "../views/ShareOrder/leaveWord/index.vue";
import EventMangement from "../views/ShareOrder/eventMangement/index.vue";

export default [
    /*** 师资共享订单 ***/
    {
        path: '/shareorder/datamanagement',
        component: Datamanagement,
        meta: { title: '资料管理' }
    },
    {
        path: '/shareorder/order',
        component: Order,
        meta: { title: '订单管理' }
    },
    {
        path: '/shareorder/reserveteachers',
        component: Reserveteachers,
        meta: { title: '订单管理' }
    },
    {
        path: '/shareorder/record',
        component: Record,
        meta: { title: '操作记录' }
    },
    {
        path: '/shareorder/topUpGift',
        component: Topupgift,
        meta: { title: '充值赠送' }
    },
    {
        path: '/shareorder/discountroll',
        component: Discountroll,
        meta: { title: '优惠券包' }
    },
    {
        path: '/shareorder/discountrolladd',
        component: Discountrolladd,
        meta: { title: '优惠券包新增' }
    },
    {
        path: '/shareorder/advertising',
        component: Advertising,
        meta: { title: '广告管理' }
    },
    {
        path: '/shareorder/helpCenter',
        component: HelpCenter,
        meta: { title: '帮助中心' }
    },
    {
        path: '/shareorder/leaveWord',
        component: LeaveWord,
        meta: { title: '留言' }
  },
     {
        path: '/shareorder/eventMangement',
        component: EventMangement,
        meta: { title: '活动管理' }
  }
    
    // 
]