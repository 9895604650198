<template>
  <div>
    <el-dialog
      append-to-body
      top="5vh"
      title="取消理由"
      :visible.sync="dialogFormVisible"
      width="700px"
      :close-on-click-modal="false"
      @close="handleToClose"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="取消理由："
          prop="text"
        >
          <el-input
            type="textarea"
            v-model.trim="dataForm.text"
            placeholder="请输入取消理由"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleToClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>



import {
  editTa,
  taRecordCreate,
  cancelOrdereTa
} from "@/request/api";

export default {
  components: {

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      rules: {
        text: [{ required: true, message: "请输入取消理由", trigger: "blur" }]
      }
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    handleToClose() {
      this.dataForm = {}
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          cancelOrdereTa({
            order_id: this.id,
          }).then((response1) => {
            let res1 = response1.data
            if (res1.code == 0) {
              taRecordCreate({
                type: 2,
                teacher_allocation_order_id: this.id,
                record:`取消理由：${this.dataForm.text}`,
              }).then((response) => {
                let res = response.data
                if (res.code == 0) {
                  this.$emit('submit')
                  this.handleToClose()
                }
              })
            }
          });


        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
  </script>
  
  <style lang="less" scoped>
</style>