<template>
  <el-dialog
    top="5vh"
    :visible.sync="dialogFormVisible"
    @close="handleToClose"
    :close-on-click-modal="false"
    width="88%"
    append-to-body
  >
    <div class="content">
      <div
        class="modular"
        v-for="(item,index) in list"
        :key="index"
      >
        <div class="title">{{ item.name }}</div>
        <div class="preview">
          <template v-if="item.content_type==1">

          </template>
          <template v-if="item.content_type==2">
            <iframe
              :frameborder="0"
              height="800"
              :src="JSON.parse(item.content)[0].url"
              class="iframe"
              v-if="JSON.parse(item.content)[0].url.indexOf('.pdf') != -1"
              width="100%"
            ></iframe>
            <iframe
              :frameborder="0"
              height="800"
              :src="baseurl+JSON.parse(item.content)[0].url"
              width="100%"
              class="iframe"
              v-else
            >
            </iframe>
          </template>
          <template v-if="item.content_type==3">
            <div class="img-list">
              <img
                v-for="(item,index) in JSON.parse(item.content)"
                :key="index"
                :src="item.url"
                alt=""
                srcset=""
                class="image"
              >
            </div>

          </template>
        </div>
      </div>
      <!--  <div class="modular"></div> -->
    </div>
  </el-dialog>
</template>
  <script>

let baseurl = `${window.location.protocol}//view.officeapps.live.com/op/view.aspx?src=`

import {

} from "@/request/api";

import {

} from "@/utils/helper";




export default {
  components: {

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      baseurl: baseurl,

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    handleToClose() {
      this.list = []
      this.$emit("close");
    },

  },
};
      </script>
  <style lang="less" scoped>
.content {
  min-height: 900px;
  display: flex;
  .modular {
    flex: 1;
    margin: 0px 9px;
    .title {
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 600;
      color: #303133;
      text-align: center;
    }
    .preview {
      margin-top: 18px;
    }
    .img-list {
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
      }
    }
  }
}
</style>