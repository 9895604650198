import ServiceOrder from "../views/Service/Service1st/main.vue";
import OrderMatching from '../views/Service/OrderMatching/main.vue'
import CommunicationRecord from "../views/Service/OrderMatching/CommunicationRecord/main.vue";
import DispatchOrder from "../views/Service/DispatchOrder/main.vue";
import DispatchOrderRecord from "../views/Service/DispatchOrder/CommunicationRecord/main.vue";

import Record from "../views/Service/Service1st/Record/Record.vue";


export default [
    /*** 服  务 ***/
    {
        path: '/service/serviceorder',
        component: ServiceOrder,
        meta: { title: '服务订单' }
    }, {
        path: '/service/serviceorder/record',
        component: Record,
        name: 'servicerecord',
        meta: { title: '跟进记录' }
    }, {
        path: '/service/ordermatching',
        component: OrderMatching,
        meta: { title: '师资匹配' }
    }, {
        path: '/service/communicationrecord',
        component: CommunicationRecord,
        name: 'communicationrecord',
        meta: { title: '沟通记录' }
    }, {
        path: '/service/dispatchorder',
        component: DispatchOrder,
        meta: { title: '师资派遣' }
    }, {
        path: '/service/dispatchorderrecord',
        component: DispatchOrderRecord,
        name: 'dispatchorderrecord',
        meta: { title: '师资派遣沟通记录' }
    },
]