<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="600px">
    <el-form
      :model="dataForm"
      ref="dataForm"
      :rules="rules"
      label-width="80px"
      size="small"
    >
      <el-form-item label="驳回理由" prop="refuse_reason">
        <el-input
          v-model.trim="dataForm.refuse_reason"
          type="textarea"
          :rows="2"
          placeholder="请输入驳回理由"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="驳回照片" prop="picture_url">
        <el-upload
          :on-remove="imgListRemove"
          :file-list="imgList"
          :limit="3"
          accept=".jpg,.png"
          list-type="picture-card"
          class="avatar-uploader"
          multiple
          :action="action"
          :on-success="handleAvatarSuccess"
        >
          <i class="el-icon-plus"></i>
          <div class="el-upload__tip" slot="tip">
            请上传JPG/PNG格式，大小在2M以内
          </div>
        </el-upload>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { imgUploadUrl } from "@/request/http";
import { crmUrl } from "@/utils/helper.js";
export default {
  data() {
    return {
      action: imgUploadUrl(),
      dialogVisible: false,
      orderId: "",
      dataForm: {},
      imgList: [],
      rules: {
        refuse_reason: [
          { required: true, message: "驳回理由不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    open(id) {
      this.orderId = id;
      this.dialogVisible = true;
    },

    submit() {
      let data = {
        find_order_id: this.orderId,
        check_status: 0,
        refuse_img_urls: this.imgList
          .map((item) => item.response.data.url)
          .join(),
        refuse_reason: this.dataForm.refuse_reason,
      };
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          let str = crmUrl();
          this.$axios({
            url: `${str}/api/v1/sharePlatform/findOrderHandle/orderTeacher/check`,
            method: "post", //可以省略 不写默认是get
            data: data,
            headers: {
              // 设置请求头
              Authorization: localStorage.getItem("admin_token"),
            },
          }).then((res) => {
            // console.log("ress", res);
            if (res.data.code == 0) {
              this.$emit('submit')
              this.dialogVisible = false
              this.$message.success("驳回成功!");
            }
          });
        }
      });
    },

    handleAvatarSuccess(res, file, fileList) {
      this.imgList = fileList;
    },
    imgListRemove(file, fileList) {
      this.dataForm.picture_url = "";
      this.imgList = fileList;
    },
  },
};
</script>

<style lang="less" scoped>
</style>