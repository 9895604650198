<template>
  <div class="dialog-wrap">
    <el-dialog
    append-to-body 
      top="5vh"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      :show-close="false"
      width="1280px"
    >
      <div class="header">
        <div class="title">老师列表</div>
        <div class="dialog-footer">
          <el-button
            type="primary"
            @click="handleToChoose"
            size="small"
          >新 增</el-button>
          <el-button
            @click="handleToSubmit"
            size="small"
          >关 闭</el-button>
        </div>
      </div>
      <el-table
        class="result"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ fontSize: '13px' }"
        border
      >
        <el-table-column
          prop="id"
          label="ID"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="realname"
          label="姓名"
          align="center"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.hide_name_status==1? `${scope.row.realname}(${scope.row.nickname})`:scope.row.realname }}
          </template>
        </el-table-column>
        <el-table-column
          label="性别"
          align="center"
          width="50"
        >
          <template slot-scope="scope">
            {{scope.row.gender==1?'男':'女'}}
          </template>
        </el-table-column>

        <el-table-column
          label="课时费"
          align="center"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.teacher_lesson_hour_amount?(scope.row.teacher_lesson_hour_amount).toFixed(2):0 }}元/小时</span>
          </template>
        </el-table-column>
        <el-table-column
          label="班级授课状态"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <el-tag
              size="mini"
              v-show="scope.row.teacher_grade_status == 1"
            >正常</el-tag>
            <el-tag
              size="mini"
              type="info"
              v-show="scope.row.teacher_grade_status == 0"
            >终止</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="manage_remark"
          label="备注"
          width="300"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="创建人"
          align="center"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="创建日期"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="修改日期"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          width="260"
          align="center"
        >
          <template slot-scope="scope">
            <el-link
                type="primary"
                :underline="false"
                @click="handleToDetail(scope.row)"
              >查看详情</el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="handleToEditLessonHourAmount(scope.row)"
            >修改课时费</el-link>
            <el-link
              type="danger"
              :underline="false"
              @click="handleToChangeStatus(scope.row,'确定要将老师移除班级吗',0)"
              v-if="scope.row.teacher_grade_status==1"
            >移除班级</el-link>
            <el-link
              type="primary"
              :underline="
              false"
              @click="handleToChangeStatus(scope.row,'确定要将老师重新加入班级吗',1)"
              v-if="scope.row.teacher_grade_status==0"
            >加入班级</el-link>
          </template>
        </el-table-column>
      </el-table>


      <div v-if="order_id">
        <div
          class="header"
          style="margin-top: 32px;"
        >
          <div class="title">确认列表</div>
          <div class="dialog-footer">
            <el-button
              type="danger"
              size="small"
              style="margin-bottom: 12px;"
              @click="cancelMatching"
              v-if="order&&order.status!=6"
            >取消匹配</el-button>
            <el-button
              type="danger"
              size="small"
              style="margin-bottom: 12px;"
              disabled
              v-else
            >匹配已取消</el-button>
            <el-button
              type="primary"
              size="small"
              style="margin-bottom: 12px;"
              @click="openEditMatching"
            >修改匹配</el-button>
          </div>
        </div>

        <el-table
          :data="confirm_result"
          row-key="id"
          :header-cell-style="{
            backgroundColor: '#F2F6FC',
          }"
          border
          @expand-change="expandChange"
          :expand-row-keys="expands"
          v-loading="loading"
        >
          <!--  内嵌表格 -->
          <el-table-column
            type="expand"
            prop="沟通记录"
          >
            <template slot-scope="scope">
              <div class="expand-wrap">
                <el-table
                  :data="scope.row.records?scope.row.records:[]"
                  :border="false"
                  :header-cell-style="{
                  backgroundColor: '#f9f9f9',
               }"
                >
                  <el-table-column
                    label="日期"
                    align="center"
                    width="150"
                  >
                    <template slot-scope="scope">
                      {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):"无"}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="记录人"
                    align="center"
                    width="100"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.manage_creator?scope.row.manage_creator.nickname:'无' }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="类别"
                    align="center"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 1"
                      >分配</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 2"
                      >备注</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 3"
                      >修改</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 4"
                      >跟进</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 5"
                      >签单</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 6"
                      >申请</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 7"
                      >沟通</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 8"
                      >记录</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 9"
                      >提交</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 10"
                        type="warning"
                      >修改</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 11"
                      >加入</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 12"
                        type="success"
                      >确认</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 13"
                        type="danger"
                      >驳回</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 14"
                        type="danger"
                      >取消</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 15"
                        type="warning"
                      >稍签</el-tag>
                      <el-tag
                        size="mini"
                        v-if="scope.row.type == 16"
                        type="warning"
                      >完结</el-tag>
                      
                      <el-tag size="mini" v-if="scope.row.type == 17" type="warning">推荐</el-tag>
            <el-tag size="mini" v-if="scope.row.type == 18" type="success">确认</el-tag>
            <el-tag size="mini" v-if="scope.row.type == 19" type="success">签约</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="沟通记录"
                    align="left"
                    show-overflow-tooltip
                    prop="record"
                    min-width="500"
                  >
                    <template slot-scope="scope">
                      <div>{{ scope.row.record }}
                        <span style="color:#409EFF;">{{ formateAltManager(scope.row.alt_manage_ids) }}</span>
                      </div>
                      <div
                        class="img-list"
                        v-if="scope.row.record_img_urls"
                      >
                        <img
                          v-for="(item,index) in JSON.parse(scope.row.record_img_urls)"
                          :key="index"
                          :src="item.url"
                          alt=""
                          srcset=""
                          @click="openViewer(JSON.parse(scope.row.record_img_urls),index)"
                        >
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </el-table-column>
          <!--  内嵌表格 -->
          <el-table-column
            prop="id"
            label="ID"
            align="center"
            width="80"
          >
          </el-table-column>
          <el-table-column
            prop="realname"
            label="姓名"
            align="center"
            width="150"
            show-overflow-tooltip
          >
             <template slot-scope="scope">
              {{scope.row.hide_name_status==1?`${scope.row.realname}(${scope.row.nickname})`:scope.row.realname}}
            </template>
          </el-table-column>
          <el-table-column
            label="年级"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              {{scope.row.recruit_year}}年
            </template>
          </el-table-column>
          <el-table-column
            label="状态"
            align="center"
            width="80"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag
                size="small"
                v-if="formateStatus(scope.row,0)"
                type="warning"
              >待确认</el-tag>
              <el-tag
                size="small"
                v-if="formateStatus(scope.row,1)"
                type="success"
              >已确认</el-tag>
              <el-tag
                type="danger"
                size="small"
                v-if="formateStatus(scope.row,2)"
              >已驳回</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="本单课时费"
            align="center "
            width="110"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{scope.row.sign_order_lesson_hour_amount?scope.row.sign_order_lesson_hour_amount:0}}元/小时
            </template>
          </el-table-column>
          <el-table-column
            label="本单课时"
            align="center "
            width="110"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{scope.row.sign_order_lesson_hour?scope.row.sign_order_lesson_hour:0}}小时
            </template>
          </el-table-column>
          <el-table-column
            label="录取信息"
            align="center "
            width="500"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{scope.row.recruit_college_name}} | {{scope.row.recruit_academy_name}} | ({{scope.row.recruit_major_code}}){{scope.row.recruit_major_name}} {{ scope.row.recruit_direction_code&&scope.row.recruit_direction_name?` | (${scope.row.recruit_direction_code})${scope.row.recruit_direction_name}`:'' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="mobile"
            label="手机号"
            align="center"
            width="120"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="wechat"
            label="微信号"
            align="center"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="匹配师"
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{ formateAllocation_manage_id(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column
            label="签约有效期"
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{scope.row.contract_end_time?$moment(scope.row.contract_end_time).format("YYYY-MM-DD HH:mm"):""}}
            </template>
          </el-table-column>
          <el-table-column
            label="签约课时费"
            align="center "
            width="110"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{scope.row.fee_amount?scope.row.fee_amount:0}}元/小时
            </template>
          </el-table-column>
          <el-table-column
            label="平均课时费"
            align="center "
            width="110"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{scope.row.teacher_average_lesson_hour_amount?scope.row.teacher_average_lesson_hour_amount:0}}元/小时
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                :underline="false"
                @click="handleToDetail(scope.row)"
              >查看详情</el-link>
              <el-link
                type="primary"
                :underline="false"
                @click="handleToConfirm(scope.row)"
              >确认</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <ChooseTeacher
      :id="ChooseTeacherId"
      :isVisible="chooseDialog"
      @close="chooseDialog=false"
      @submit="fetchDetail()"
    />
    <EditLessonHourAmount
      :id="editId"
      :class_id="this.id"
      :isVisible="editDialog"
      @close="editDialog=false"
      @submit="fetchDetail()"
    />
    <SeniorDetail
      :isVisible="SeniorDetailDialog"
      @close="SeniorDetailDialog=false"
      :id="detailId"
    />
    <ConfirmGradeTeacher
      :isVisible="confirmDialog"
      @close="confirmDialog=false"
      :id="confirmId"
      @submit="fetchDetail"
    />
    <EditMatching
      :isVisible="editMatchingDialog"
      @close="editMatchingDialog=false"
      :id="editMatchingId"
      @submit="fetchDetail"
      :teachers="teachers"
    />

    <MatchingCancle
      :isVisible="cancelDialog"
      @close="cancelDialog=false"
      :id="order_id"
      @submit="fetchDetail"
    />
    <el-image-viewer
      v-if="showViewer"
      :url-list="imgs"
      :zIndex="999999999"
      :on-close="closeViewer"
      :initial-index="initialIndex"
    />
  </div>
</template>

<script>
import {
  gradeOne,
  gradeEdit,
  commonHandleLogCreate,
  teacherlist,
  gradelist,
  taOne,
  taRecordList


} from "@/request/api";
import ChooseTeacher from "@/components/DialogComponents/ClassService/ChooseTeacher.vue";
import EditLessonHourAmount from "@/components/DialogComponents/ClassService/EditLessonHourAmount.vue";
import SeniorDetail from "@/components/DialogComponents/TeacherResources/SeniorDetail.vue";
import ConfirmGradeTeacher from "@/components/DialogComponents/TeacherResources/ConfirmGradeTeacher.vue";
import EditMatching from "@/components/DialogComponents/TeacherResources/EditMatching.vue";
import MatchingCancle from "@/components/DialogComponents/TeacherResources/MatchingCancle.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";



export default {
  components: {
    ChooseTeacher,
    EditLessonHourAmount,
    SeniorDetail,
    ConfirmGradeTeacher,
    EditMatching,
    MatchingCancle,
    ElImageViewer
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => null,
    },
    order_id: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      initialIndex:0,
      dialogVisible: this.isVisible,
      pageTotal: 10,
      results: [],
      ChooseTeacherId: null,
      chooseDialog: false,
      editId: null,
      editDialog: false,
      confirm_result: [],
      editMatchingId: null,
      editMatchingDialog: false,
      SeniorDetailDialog: false,
      detailId: null,
      confirmDialog: false,
      confirmId: null,
      order: null,
      loading: false,
      cancelDialog: false,
      cancelId: null,
      teachers: [],
      expands: [],
      showViewer: false,
      imgs: [],

    };
  },

  watch: {
    isVisible(val) {
      this.dialogVisible = val;
      if (val) {
      
        this.expands = []
        this.fetchDetail()
       
      }
    },
  },
  methods: {
    // getTeacherName(list){

    // },

    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.imgs = url.map(v => {
        return v.url
      })
      this.initialIndex = index
      this.showViewer = true;
    },
    expandChange(row) {
      let params = {
        teacher_allocation_order_id: this.$route.params.id,
        teacher_id: row.id,
        isPaging: 0,
      }
      taRecordList(params).then((response) => {
        let res = response.data
        this.$set(row, 'records', res.data.list)
      });
    },
    fetchOrderDetail() {
      taOne({
        id: this.order_id
      }).then((response) => {
        let res = response.data
        this.order = res.data || [];
      });
    },
    emitClose() {
      this.$emit("close");
    },
    fetchDetail() {
      gradeOne({ id: this.id }).then((response) => {
        this.fetchOrderDetail()
        let res = response.data
        this.results = res.data.teachers
        // console.log("pp",this.results)
        this.fetchConfirmDetail()
       

      });
    },
    fetchConfirmDetail() {
      gradelist({ ids: this.id }).then((response) => {
        let res = response.data
        console.log("ll",res)
        this.teachers = res.data.list[0].allocation_order_teacher_refs

        this.order_teachers = res.data.list[0].allocation_order_teacher_refs
         console.log("ids",res.data.list[0].allocation_order_teacher_refs);
        if(res.data.list[0].allocation_order_teacher_refs){
           let ids = res.data.list[0].allocation_order_teacher_refs.map(v => {
          return v.teacher_id
        }).join(',')
       
        this.fetchConfirmList(ids, res.data.list[0].allocation_order_obj ? res.data.list[0].allocation_order_obj.id : '')
        }
       
      })
    },
    fetchConfirmList(ids, order_id = '') {
      this.loading = true
      const params = {
        isPaging: 0,
        ids: ids,
        handle_teacher_allocaton_order_id: order_id
      }
      teacherlist(params).then((response) => {
        let res = response.data
        this.confirm_result = res.data.list || [];
        // console.log("ll",this.confirm_result)
        this.loading = false
      });
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val;
      this.diffSelection();
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        keyword: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      };
      this.$emit("close");
    },

    collegeFormate(row) {
      if (row.apply_college_name && row.apply_major_code && row.apply_major_name) {
        return `${row.apply_college_name},（${row.apply_major_code}）${row.apply_major_name}`
      } else {
        return "暂无定校"
      }
    },
    formateManage_Watcher(val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.nickname + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    handleToChoose() {
      this.ChooseTeacherId = this.id
      this.chooseDialog = true
    },
    handleToSubmit() {
      this.$emit('submit')
      this.handleClose()
    },
    handleToEditLessonHourAmount(row) {
      this.editId = row.id
      this.editDialog = true
    },
    handleToChangeStatus(row, text, status) {
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        row.teacher_grade_status = status
        let query = {
          id: this.id,
          arr_teacher: this.results.map((v => {
            return {
              teacher_id: v.id,
              teacher_grade_status: v.teacher_grade_status
            }
          }))
        }
        gradeEdit(query).then((res) => {
          if (res.data.code == 0) {
            this.createLog(row, status)
          }
        });
      })
    },
    createLog(row, status) {
      let query = {
        ref_id: this.id,
        handle_type: 0,
        handle_child_type: status == 1 ? 4 : 5,
        handle_content: status == 1 ? `邀请${row.realname}老师加入班级，课时费${row.teacher_lesson_hour_amount || 0}元/小时` : `将${row.realname}老师移出了班级`,
      }
      commonHandleLogCreate(query).then((res) => {
        if (res.data.code == 0) {
          this.fetchDetail()
        }
      });
    },
    formateAllocation_manage_id(row) {
      var ids = this.order_teachers.find(v => {
        return v.teacher_id == row.id
      }).allocation_manage_id
      if (ids) {
        let arr = ids.toString().split(',').map(v => {
          return this.$store.state.staffOptions.find(t => {
            return t.id == v
          }).nickname
        })
        return arr.join(',')
      } else {
        return '无'
      }
    },
    openEditMatching() {
      this.editMatchingId = this.order_id
      this.editMatchingDialog = true
    },
    formateStatus(row, status) {
      if (this.order_teachers.length) {
        let number = this.order_teachers.find(v => {
          return v.teacher_id == row.id
        }).confirm_status
        if (number == status) {
          return true
        } else {
          return false
        }
      }
    },
    handleToDetail(row) {
      this.detailId = row.id
      this.SeniorDetailDialog = true
    },
    handleToConfirm(row) {
      let id = this.order_teachers.find(v => {
        return v.teacher_id == row.id
      }).id
      this.confirmId = id
      this.confirmDialog = true
    },
    formateAltManager(ids) {
      if (ids) {
        let str = ids.split(',').map(v => {
          return `@${this.$store.state.staffOptions.find(t => {
            return t.id == v
          }).nickname}`
        })

        return str.join(',')
      } else {
        return ''
      }
    },
    cancelMatching() {
      this.cancelDialog = true
    
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__body {
  padding-top: 0px;
}
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    margin-top: 10px;
  }
  .now {
    margin-left: 16px;
    color: red;
  }
  .header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 20px;
      font-weight: 600;
      color: #333;
    }
  }
  .img-list {
    margin-top: 12px;
    display: flex;
    align-items: center;
    img {
      width: 140px;
      height: 140px;
      margin-right: 12px;
      border-radius: 4px;
    }
  }
}
</style>
