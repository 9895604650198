<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>账单管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button type="primary"
                   size="small"
                   @click="openAddBillsDialog"
                   :disabled="!$buttonPermission('billmanagement:create')">新建</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form :inline="true"
               class="demo-form-inline"
               size="small">
        <el-form-item label="搜索">
          <el-input v-model="query.order_no"
                    placeholder="账单ID"
                    @input="getList(query)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="账单名称">
          <el-input v-model="query.order_name"
                    placeholder="账单名称"
                    @input="getList(query)"
                    clearable></el-input>
        </el-form-item>
      </el-form>
      <ScreenBill @submit="screenTable"></ScreenBill>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form :inline="true"
                 label-width="70px"
                 size="small">
          <el-form-item label="只看关注">
            <el-switch v-model="query.follow"
                       :active-value="1"
                       inactive-value=" "
                       @change="getList(query)">
            </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                border
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="50"
                         fixed="left">
        </el-table-column>
        <el-table-column prop="date"
                         label="关注"
                         fixed="left"
                         width="50">
          <template slot-scope="scope">
            <img alt=""
                 srcset=""
                 class="star"
                 src="../../../assets/images/star-fill.png"
                 @click="followDelete(scope.row)"
                 v-show="scope.row.follow" />
            <img src="../../../assets/images/star.png"
                 alt=""
                 srcset=""
                 class="star"
                 v-show="!scope.row.follow"
                 @click="followCreate(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column prop="detail_file_url"
                         label="账单明细"
                         width="100"
                         fixed="left">
          <template slot-scope="scope">
            <el-link v-if="scope.row.detail_file_url"
                     type="primary"
                     :underline="false"
                     @click="downLoad(scope.row)">下载账单</el-link>
            <el-link v-else
                     type="primary"
                     :underline="false"
                     @click="look(scope.row)">查看</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="order_no"
                         label="账单ID"
                         width="120"
                         show-overflow-tooltips></el-table-column>
        <el-table-column prop="type"
                         label="账单名称"
                         width="220"
                         show-overflow-tooltips>
          <template slot-scope="scope">
            <span>{{ scope.row.order_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="type"
                         label="机构简称"
                         width="150"
                         show-overflow-tooltips>
          <template slot-scope="scope">
            <span>{{ scope.row.company.name }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="type"
                         label="审核状态"
                         width="150"
                         show-overflow-tooltips>
          <template slot-scope="scope">
            <el-tag :type="scope.row.manage_audit_status==0?'':scope.row.manage_audit_status==1?'success':'danger'">
              {{ scope.row.manage_audit_status==0?'待审核':scope.row.manage_audit_status==1?'已通过':"已驳回"}}
            </el-tag>

          </template>
        </el-table-column>

        <el-table-column prop="status"
                         label="账单状态"
                         width="100">
          <template slot-scope="scope">
            <div @click="openChangeStatus(scope.row)">
              <el-tag size="small"
                      v-show="scope.row.status == 0"
                      type="warning">待确定</el-tag>
              <el-tag size="small"
                      v-show="scope.row.status == 1">待支付</el-tag>
              <el-tag size="small"
                      v-show="scope.row.status == 2"
                      type="success">已支付</el-tag>
              <el-tag size="small"
                      v-show="scope.row.status == 3"
                      type="success">已完成</el-tag>
              <el-tag size="small"
                      v-show="scope.row.status == 4"
                      type="info">已取消</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="invoice_status"
                         label="开票状态"
                         width="100">
          <template slot-scope="scope">
            <el-tag size="small"
                    type="success"
                    v-show="scope.row.invoice_status == 0">待开票</el-tag>
            <el-tag size="small"
                    type="success"
                    v-show="scope.row.invoice_status == 1">开票中</el-tag>
            <el-tag size="small"
                    type="success"
                    v-show="scope.row.invoice_status == 2">已开票</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="type"
                         label="账单类型"
                         width="100"></el-table-column>
        <el-table-column prop="date"
                         label="账单周期"
                         width="200">
          <template slot-scope="scope">
            <span>
              {{ scope.row.start_time | dateFormat("YYYY.MM.DD") }}--{{
                scope.row.end_time | dateFormat("YYYY.MM.DD")
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="total_amount"
                         label="总费用"
                         width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.total_amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="service_students"
                         label="结算人数"
                         width="100">
        </el-table-column>
        <el-table-column prop="service_amount"
                         label="服务费用"
                         width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.service_amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="service_hours"
                         label="结算课时"
                         width="100">
        </el-table-column>
        <el-table-column prop="product_amount"
                         label="课程费用"
                         width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.product_amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="other_amount"
                         label="其他费用"
                         width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.other_amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark"
                         label="备注"
                         width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column prop="creator"
                         label="支付方式"
                         width="90">
          <template slot-scope="scope">
            <span>{{
                scope.row.payment_type
                  ? formatePayType(scope.row.payment_type)
                  : "/"
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="date"
                         label="操作"
                         width="150"
                         fixed="right">
          <template slot-scope="scope">
            <!--             <el-link type="primary" :underline="false">推送</el-link>
            <el-divider direction="vertical"></el-divider> -->
            <el-link type="info"
                     :underline="false"
                     v-if="scope.row.status == 3">编辑</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="openEditBillsDialog(scope.row)"
                     :disabled="!$buttonPermission('billmanagement:edit')"
                     v-else>查看</el-link>
            <el-link type="primary"
                     :underline="false"
                     v-if="scope.row.manage_audit_status == 0"
                     @click="handleExamine(scope.row)">审核</el-link>
            <!-- <el-link
              type="primary"
              :underline="false"
              v-if="scope.row.manage_audit_status == 0"
              @click="handleCheckRefuse(scope.row.id)"
              >驳回</el-link
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button size="small"
                   type="danger"
                   @click="chooseAllDelete"
                   :disabled="!$buttonPermission('billmanagement:delete')">批量删除</el-button>
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <AddBills :isVisible="AddBillsDialog"
              @submit="AddBillsSubmit"
              @close="AddBillsDialog = false"></AddBills>
    <EditBills :content="EditBillsContent"
               :isVisible="EditBillsDialog"
               @submit="EditBillsSubmit"
               @submitList="getList(query)"
               @close="EditBillsDialog = false"></EditBills>
    <ChangeBillStatus :content="ChangeBillStatusContent"
                      :isVisible="ChangeBillStatusDialog"
                      @submit="getList(query)"
                      @close="ChangeBillStatusDialog = false"></ChangeBillStatus>

    <Examine :content="examineContent"
             :isVisible="examineIsVisible"
             :examineFlag="examineFlag"
             :type="type"
             :intertype="intertype"
             @close="examineClose"
             @submit="getList()"></Examine>

  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import AddBills from '../../../components/DialogComponents/AddBills/AddBills.vue'
import EditBills from '../../../components/DialogComponents/EditBills/EditBills.vue'
import ScreenBill from '../../../components/ScreenComponents/ScreenBill/ScreenBill.vue'
import ChangeBillStatus from '@/components/DialogComponents/ChangeBillStatus/ChangeBillStatus.vue'
import Examine from '@/components/DialogComponents/SeniorBill/Examine.vue'
import {
  createserviceorder,
  orderlist,
  editorderlist,
  deleteorderlist,
  createuserfollow,
  deleteuserfollow,
  checkAccess,
  checkRefuse,
} from '../../../request/api'
export default {
  components: {
    TableTittle,
    AddBills,
    EditBills,
    ScreenBill,
    ChangeBillStatus,
    Examine,
  },
  data() {
    return {
      intertype: 0,
      dialogVisible: false,
      refuse_reason: '',
      order_id: '',
      TableName: '帐单列表',
      tableData: [],
      screenForm: {},
      deleteList: [],
      AddBillsDialog: false,
      EditBillsDialog: false,
      lock: true,
      EditBillsContent: {},
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      ChangeBillStatusDialog: false,
      ChangeBillStatusContent: {},
      examineContent: {},
      examineIsVisible: false,
      examineFlag: false,
      type: false,
    }
  },
  mounted() {
    /* this.parseQuery(); */

    this.getList(this.query)
    this.getOptions()
  },
  methods: {
    examineClose() {
      this.examineIsVisible = false
      this.examineFlag = false
    },
    look(row) {
      if (row.type == '代签订单') {
        this.type = true
      } else {
        this.type = false
      }
      if (row.type == '代找订单') {
        this.intertype = 1
      } else {
        this.intertype = 0
      }
      this.examineFlag = true
      this.examineContent = row
      this.examineIsVisible = true
    },
    // 审核
    handleExamine(row) {
      this.examineContent = row
      this.examineIsVisible = true
    },
    handleCheckAccess(id) {
      this.$confirm('是否通过审核?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          checkAccess({
            order_id: id,
          }).then((res) => {
            if (res.data.code == 0) {
              this.getList()
              this.$message({
                type: 'success',
                message: '审核成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    checkRefuseSubmit() {
      checkRefuse({
        order_id: this.order_id,
        refuse_reason: this.refuse_reason,
      }).then((res) => {
        if (res.data.code == 0) {
          this.getList()
          this.$message({
            type: 'success',
            message: '驳回成功!',
          })
          this.dialogVisible = false
        }
      })
    },
    handleCheckRefuse(id) {
      this.order_id = id
      this.dialogVisible = true
    },
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val
    },
    getList(query) {
      orderlist(query).then((res) => {
        this.tableData = res.data.data.list || []
        this.pageTotal = res.data.data.count
      })
      /*   this.syncQuery(); */
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    getOptions() {
      this.$store.dispatch('getCompanyList', { data_range_tags: '2,3,4' })
      this.$store.dispatch('getBillsType')
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getList(this.query)
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getList(this.query)
    },
    openAddBillsDialog() {
      this.AddBillsDialog = !this.AddBillsDialog
    },
    openEditBillsDialog(item) {
      this.EditBillsContent = JSON.parse(JSON.stringify(item))
      this.EditBillsContent.billTime = [
        this.EditBillsContent.start_time,
        this.EditBillsContent.end_time,
      ]
      this.EditBillsDialog = !this.EditBillsDialog
    },
    // 添加服务账单提交
    AddBillsSubmit({ form }) {
      createserviceorder(form).then((res) => {
        this.$notify.success({
          title: '提示',
          message: '提交成功',
        })
        this.getList(this.query)
        this.AddBillsDialog = false
      })
    },
    // 修改服务账单提交
    EditBillsSubmit({ form }) {
      editorderlist(form).then((res) => {
        this.$notify.success({
          title: '提示',
          message: '提交成功',
        })
        this.getList(this.query)
        this.EditBillsDialog = false
      })
    },
    // 下载文件
    downLoad(item) {
      this.$store.commit('downLoad', item.detail_file_url)
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 2,
          follow_id: item.id,
        }
        createuserfollow(query).then((res) => {
          this.getList(this.query)
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 500)
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 2,
          follow_id: item.id,
        }
        deleteuserfollow(query).then((res) => {
          this.getList(this.query)
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 300)
      }
    },
    // 筛选
    screenTable({ screenForm }) {
      this.$set(this.query, 'pageIndex', 1)
      this.query = {
        ...this.query,
        ...screenForm,
      }
      this.getList(this.query)
    },
    // 选中数据后做处理删除
    chooseAllDelete() {
      this.$confirm('确定要删除选中的数据吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            ids: [],
          }
          this.deleteList.forEach((item) => {
            query.ids.push(item.id)
          })
          deleteorderlist(query).then((res) => {
            this.$notify.success({
              title: '提示',
              message: '删除成功',
            })
            this.$nextTick(() => {
              this.getList(this.query)
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消删除',
          })
        })
    },
    formatePayType(type) {
      const obj = {
        0: '无',
        1: '支付宝',
        2: '微信',
        3: '余额',
        4: '转账',
      }
      return obj[type]
    },
    openChangeStatus(row) {
      if (row.manage_audit_status == 1) {
        if (row.status == 3 || row.status == 4) {
          this.$message.error('当前状态不可修改')
          return
        } else {
          this.ChangeBillStatusContent = Object.assign({}, row)
          this.ChangeBillStatusDialog = true
        }
      } else {
        this.$message.error('当前状态不可修改')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
}
.star {
  width: 18px;
  height: 18px;
}
.card-header {
  display: flex;
  justify-content: space-between;
}
.demo-form-inline {
  height: 64px;
  border-bottom: 2px dashed #d2d5df;
}
.el-tag {
  cursor: pointer;
}
</style>