<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item>课时登记</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <!-- <el-button
          type="primary"
          size="small"
          @click="handleCollectionRecord()"
        >收款记录</el-button>
        <el-button
          type="primary"
          size="small"
          @click="openAutoDialog()"
          :disabled="!$buttonPermission('dispatchorder:create')"
        >新建并匹配</el-button> -->
        <!-- <el-button
          type="primary"
          size="small"
          @click="openAddDispatchOrderDialog()"
          :disabled="!$buttonPermission('dispatchorder:create')"
        >新建</el-button> -->
      </div>
    </div>
    <el-card class="operation-card">
      <el-form :inline="true"
               size="small">
        <el-form-item label="学生信息">
          <el-input v-model="query.keyword"
                    placeholder="姓名/院校/学院/专业/备注"
                    @input="inputSearch"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="老师信息">
          <el-input v-model="query.ref_teacher_confirm_keyword"
                    placeholder="姓名/手机号"
                    @input="inputSearch"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="匹配师："
                      prop="allocation_manage_ids">
          <!-- multiple
            filterable -->
          <el-select v-model="query.allocation_manage_id"
                     placeholder="请选择匹配师"
                     clearable
                     style="width: 100%"
                     @change="fetchList(true)">
            <el-option v-for="(item, index) in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 5 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="index"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="订单状态">
          <el-select
            v-model="query.dispatch_order_sync_statuses"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in filters.dispatch_order_sync_statuses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item style="float: right">
          <el-radio-group v-model="query.archive" @change="fetchList(true)">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="1">已归档</el-radio-button>
            <el-radio-button label="0">未归档</el-radio-button>
          </el-radio-group>
        </el-form-item> -->
        <deviceLine style="margin-bottom: 16px"></deviceLine>

        <!-- <el-form-item label="订单状态">
          <el-select
            v-model="query.dispatch_order_sync_statuses"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in filters.dispatch_order_sync_statuses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="机构简称"
                      prop="cid">
          <el-select v-model="query.cid"
                     placeholder="请选择"
                     filterable
                     @change="fetchList(true)"
                     clearable>
            <el-option v-for="item in $store.state.CompanyList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间"
                      prop="last_advance_time">
          <el-date-picker v-model="create_time"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="timestamp"
                          @change="fetchList(true)">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="最近登记时间">
          <el-select v-model="dispatchTime"
                     placeholder="请选择"
                     @change="dispatchChange"
                     clearable
                     collapse-tags>
            <el-option v-for="item in filters.periodColumns"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="table-card"
             style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form :inline="true"
                 label-width="70px"
                 size="small">
          <el-form-item label="只看关注">
            <el-switch v-model="query.follow"
                       :active-value="1"
                       inactive-value=" "
                       @change="fetchList(true)">
            </el-switch>
          </el-form-item>
          <el-form-item label-width="32px">
            <el-radio-group v-model="statuses"
                            @change="fetchList(true)">
              <el-radio-button :label="0">全部</el-radio-button>
              <el-radio-button :label="4">上课中</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item>
            <el-badge :value="uncheck" :hidden="uncheck == 0">
              <el-checkbox
                v-model="query.is_has_allocation_confirm"
                label="待确认师资"
                border
                :true-label="1"
                :false-label="0"
                @change="fetchList(true)"
              ></el-checkbox>
            </el-badge>
          </el-form-item> -->
        </el-form>
      </div>
      <el-table :data="result"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                @selection-change="handleSelectionChange"
                border>
        <el-table-column type="selection"
                         width="50"> </el-table-column>
        <el-table-column prop="date"
                         label="关注"
                         width="50"
                         fixed="left">
          <template slot-scope="scope">
            <img alt=""
                 srcset=""
                 class="star"
                 src="../../../assets/images/star-fill.png"
                 @click="followDelete(scope.row)"
                 v-show="scope.row.follow" />
            <img src="../../../assets/images/star.png"
                 alt=""
                 srcset=""
                 class="star"
                 v-show="!scope.row.follow"
                 @click="followCreate(scope.row)" />
          </template>
        </el-table-column>
        <!-- <el-table-column prop="nickname" label="师资信息" width="130">
          <template slot-scope="scope">
            <div class="link-template">
              <div
                class="red-bradge"
                v-if="
                  scope.row.manage_unread_status == 1 && scope.row.status != 1
                "
              >
                <span>new</span>
              </div>
              <el-link
                type="primary"
                :underline="false"
                @click="openCheckResultDialog(scope.row)"
                :disabled="
                  scope.row.dispatch_order_sync_status == 5 ||
                  !$buttonPermission('dispatchorder:fill')
                "
                v-if="scope.row.result == null"
                >填写</el-link
              >
              <el-link
                type="success"
                :underline="false"
                v-else
                @click="openCheckResultDialog(scope.row)"
                :disabled="
                  scope.row.dispatch_order_sync_status == 5 ||
                  !$buttonPermission('dispatchorder:fill')
                "
                >填写</el-link
              >
              <el-link
                type="primary"
                :underline="false"
                @click="goRecord(scope.row)"
                :disabled="
                  scope.row.dispatch_order_sync_status == 5 ||
                  !$buttonPermission('dispatchorder:record:view')
                "
                >跟进</el-link
              >
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="order_no"
                         label="订单号"
                         width="150">
        </el-table-column>
        <el-table-column prop="create_time"
                         label="最近登记时间"
                         width="120">
          <template slot-scope="scope">
            {{
              scope.row.dispatch_log_last_create_time
                ? $moment(scope.row.dispatch_log_last_create_time).format(
                    "YYYY-MM-DD"
                  )
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         label="课程进度"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="getNum(scope.row)"
            ></el-progress> -->
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         label="订单状态"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    type="warning"
                    v-show="scope.row.dispatch_order_sync_status == 1">待服务</el-tag>
            <el-tag size="small"
                    type="warning"
                    v-show="scope.row.dispatch_order_sync_status == 2">待对接</el-tag>
            <el-tag size="small"
                    v-show="scope.row.dispatch_order_sync_status == 3">匹配中</el-tag>
            <el-tag size="small"
                    v-show="scope.row.dispatch_order_sync_status == 4">上课中</el-tag>
            <el-tag size="small"
                    type="danger"
                    v-show="scope.row.dispatch_order_sync_status == 5">已取消</el-tag>
            <el-tag size="small"
                    type="success"
                    v-show="scope.row.dispatch_order_sync_status == 6">已完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="授课老师"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="openConfirmList(scope.row)"
                  style="color: rgb(64, 158, 255)">
              {{ getTeacher(scope.row.allocation_order_teacher_refs_confirm) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="apply_year"
                         label="机构简称"
                         show-overflow-tooltip
                         width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.company.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="student_name"
                         label="学生姓名"
                         width="200"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="name"
                         label="已登记课时"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.dispatch_log_total_lesson_hour==null ?scope.row.dispatch_log_total_lesson_hour=0 :scope.row.dispatch_log_total_lesson_hour + "小时" }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="name"
                         label="辅导课时"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.hours + "小时" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="报考信息"
                         width="300"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="pointer"
                  @click="institutionOpen(scope.row)"
                  v-if="
                scope.row.apply_college_name && scope.row.apply_college_code
              ">
              {{ `${scope.row.apply_college_name}` }}/</span>

            <span v-if="
                scope.row.apply_academy_name && scope.row.apply_academy_code
              ">
              {{
                `(${scope.row.apply_academy_code})${scope.row.apply_academy_name}/`
              }}</span>

            <span v-if="scope.row.apply_major_name && scope.row.apply_major_code">
              {{
                `(${scope.row.apply_major_code})${scope.row.apply_major_name}/`
              }}</span>
            <span v-if="
                scope.row.apply_direction_code && scope.row.apply_direction_name
              ">
              {{
                `(${scope.row.apply_direction_code})${scope.row.apply_direction_name}/`
              }}</span>
            <span>{{ scope.row.subjects | formateSub }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="remark"
                         label="订单备注"
                         width="280"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>

        <el-table-column label="匹配师"
                         width="130"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              getManageList(scope.row.bind_allocation_order_manage_list)
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="对接人"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.manage_watcher | formateManage_Watcher }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="create_time"
                         label="创建时间"
                         width="120">
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>

        <el-table-column prop="name"
                         label="操作"
                         width="100"
                         fixed="right">
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link type="primary"
                       :disabled="scope.row.dispatch_order_sync_status != 4"
                       :underline="false"
                       @click="register(scope.row)">登记</el-link>
              <!-- <el-link
                type="primary"
                :underline="false"
                @click="orderReceiptRecord(scope.row.id)"
                >收款记录</el-link
              >
              <el-link
                type="primary"
                :underline="false"
                @click="openEditDispatchOrderDialog(scope.row)"
                :disabled="!$buttonPermission('dispatchorder:edit')"
                v-if="scope.row.dispatch_order_sync_status != 5"
                >编辑</el-link
              >
              <el-link
                type="success"
                :underline="false"
                @click="changeStatus(scope.row)"
                v-else
                >恢复</el-link
              > -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <!-- <el-button
          size="mini"
          type="primary"
          @click="chooseAllEdit(1, '您确定要归档吗')"
          v-show="query.archive == 0 && query.archive != ''"
          :disabled="!$buttonPermission('dispatchorder:batch')"
        >归档</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="chooseAllEdit(0, '您确定要取消归档吗')"
          v-show="query.archive == 1"
          :disabled="!$buttonPermission('dispatchorder:batch')"
        >取消归档</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="chooseAllDelete"
          :disabled="!$buttonPermission('dispatchorder:delete')"
        >删除</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="filterArr"
          :disabled="!$buttonPermission('dispatchorder:batch')"
        >终止</el-button>
        <el-button
          size="small"
          type="primary"
          @click="openBatchEditWatcherDialog"
          v-show="$buttonPermission('dispatchorder:batchWatcher')"
        >分配</el-button> -->
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <AddDispatchOrder :isVisible="AddDispatchOrderDialog"
                      @close="AddDispatchOrderDialog = false"
                      @submit="AddDispatchOrderSubmit"></AddDispatchOrder>
    <EditDispatchOrder :isVisible="EditDispatchOrderDialog"
                       @close="EditDispatchOrderDialog = false"
                       @submit="EditDispatchOrderSubmit"
                       :content="EditDispatchOrderContent"></EditDispatchOrder>
    <CheckResult :content="CheckResultContent"
                 :isVisible="CheckResultDialog"
                 @close="CheckResultDialog = false"
                 @submit="CheckResultSubmit">
    </CheckResult>
    <BatchEditWatcher :isVisible="BatchEditWatcherDialog"
                      @close="BatchEditWatcherDialog = false"
                      @submit="BatchEditWatcherSubmit"></BatchEditWatcher>
    <DispatchingOrderMatching :id="matchId"
                              :isVisible="matchDialog"
                              @close="matchDialog = false"
                              @submit="fetchList()" />
    <!-- grade_id有可能是师资匹配id也有可能是师资派遣id -->
    <OrderConfirmList :teachers="teachers"
                      :isVisible="confirmDialog"
                      :grade_id="confirmId"
                      :order_id="confirmOrder_id"
                      :type="true"
                      @close="confirmDialog = false"
                      @submit="fetchList()" />
    <Record :teacher_allocation_order_id="orderId"
            :isVisible="recordDialog"
            @close="recordDialog = false"
            @submit="fetchList()"
            :type="'order_read'"></Record>
    <AddAutoDispatchingOrder :isVisible="autoDialog"
                             @close="autoDialog = false"
                             @submit="fetchList()"></AddAutoDispatchingOrder>

    <CollectionRecord ref="collectionRecord"></CollectionRecord>
    <CollectionRecordAdd ref="collectionRecordAdd"></CollectionRecordAdd>
    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>
    <Register ref="register"
              @submit="fetchList()" />
  </div>
</template>

<script>
import { getNextMonth } from '@/utils/time.js'
import deviceLine from '../../../components/Line/Line.vue'

import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import AddDispatchOrder from '../../../components/DialogComponents/AddDispatchOrder/AddDispatchOrder.vue'
import EditDispatchOrder from '../../../components/DialogComponents/EditDispatchOrder/EditDispatchOrder.vue'
import CheckResult from '../../../components/DialogComponents/CheckResult/CheckResult.vue'
import BatchEditWatcher from '../../../components/DialogComponents/BatchEditWatcher/BatchEditWatcher.vue'
import DispatchingOrderMatching from '@/components/DialogComponents/TeacherResources/DispatchingOrderMatching.vue'
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'
import OrderConfirmList from '@/components/DialogComponents/TeacherResources/OrderConfirmList.vue'
import AddAutoDispatchingOrder from '@/components/DialogComponents/TeacherResources/AddAutoDispatchingOrder.vue'

import CollectionRecord from '@/components/DialogComponents/CollectionRecord/CollectionRecord.vue'
import CollectionRecordAdd from '@/components/DialogComponents/CollectionRecord/CollectionRecordAdd.vue'
import InstitutionDetails from '@/components/DialogComponents/InstitutionDetails/index.vue'

import Register from '../../../components/DialogComponents/Classregistration/Register.vue'
import {
  createteacherdispatchorder,
  teacherdispatchorderlist,
  editeacherdispatchorder,
  createuserfollow,
  deleteuserfollow,
  editteacherdispatchorderlist,
  deleteteacherdispatchorderlist,
  matchdispatchorderresult,
  signedBehalf,
  signedBehalfedit,
  crmOutrecorddetail,
} from '../../../request/api'
import { formateObjDaiqian } from '../../../utils/helper'
export default {
  components: {
    TableTittle,
    AddDispatchOrder,
    EditDispatchOrder,
    CheckResult,
    BatchEditWatcher,
    DispatchingOrderMatching,
    OrderConfirmList,
    Record,
    deviceLine,
    AddAutoDispatchingOrder,
    CollectionRecord,
    CollectionRecordAdd,
    InstitutionDetails,
    Register,
  },
  data() {
    return {
      result: [],
      TableName: '订单列表',
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        archive: '0',
        dispatch_order_sync_statuses: 4,
      },
      statuses: '4',
      pageTotal: 0,
      EditDispatchOrderDialog: false, // 编辑订单弹窗
      EditDispatchOrderContent: {},
      AddDispatchOrderDialog: false, // 添加订单弹窗
      CheckResultDialog: false, // 师资信息弹窗
      chooseList: [],
      lock: true,
      CheckResultContent: {},
      hoursObj: {},
      // 批量修改watcher弹窗
      BatchEditWatcherDialog: false,
      matchId: null,
      matchDialog: false,
      confirmId: null,
      confirmOrder_id: null,
      confirmDialog: false,
      teachers: [],
      orderId: null,
      recordDialog: false,
      uncheck: 0,
      create_time: '',
      autoDialog: false,
      filters: {
        dispatch_order_sync_statuses: [
          { value: 1, label: '待服务' },
          { value: 2, label: '待对接' },
          { value: 3, label: '匹配中' },
          { value: 4, label: '上课中' },
          { value: 5, label: '已取消' },
          { value: 6, label: '已完成' },
        ],
        periodColumns: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '一天前',
            value: '86400000',
          },
          {
            label: '三天前',
            value: '259200000',
          },

          {
            label: '七天前',
            value: '604800000',
          },
          {
            label: '十五天前',
            value: '1296000000',
          },
          {
            label: '一个月前',
            value: 'month',
          },
        ],
      },
      dispatchTime: [],
    }
  },
  filters: {
    formateSub: function (val) {
      if (val == null || val == 1) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += `(${item.subject_code})${item.subject_name}` + '，'
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
    formateWatcher: function (val) {
      if (!val) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += item.alias + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
    formateManage_Watcher(val) {
      if (!val) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += item.nickname + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
  },
  watch: {
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf()
        this.query.end_create_time = this.$moment(value[1]).valueOf()
      } else {
        this.query.start_create_time = ''
        this.query.end_create_time = ''
        this.fetchList(true)
      }
    },
  },
  mounted() {
    // console.log("day",this.$moment().startOf('day').format('x'))
    // console.log("shi",this.$moment().valueOf())
    this.parseQuery()
    // this.$store.dispatch("getStaffOptions");
    // 获取列表
    this.fetchList()
    this.$store.dispatch('getCompanyList', { data_range_tags: '3' })
    this.$store.dispatch('getAllColleges')
    this.$store.dispatch('getStaffOptions')
  },
  methods: {
    getTeacher(list) {
      if (list && list.length > 0) {
        return list.map((item) => item.teacher_realname).join(',') || '-'
      }
    },
    register(row) {
      this.$refs.register.open(row)
    },
    dispatchChange(e) {
      //   console.log(e, "e");
      if (e == 'month') {
        this.query.start_dispatch_last_create_time = 1
        this.query.end_dispatch_last_create_time = getNextMonth(
          this.$moment(
            Number(this.$moment().startOf('day').format('x'))
          ).format('YYYY-MM-DD HH:mm'),
          -1
        )
      } else if (e == '') {
        this.query.start_dispatch_last_create_time = ''
        this.query.end_dispatch_last_create_time = ''
      } else {
        this.query.start_dispatch_last_create_time = 1

        this.query.end_dispatch_last_create_time =
          this.$moment().startOf('day').format('x') - e
      }
      this.fetchList(true)
      //   console.log(this.query,'query')
    },

    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.apply_college_code)
    },
    getManageList(list) {
      if (list && list.length > 0) {
        return list.map((item) => item.nickname).join(' / ')
      } else {
        return '-'
      }
    },
    orderReceiptRecord(id) {
      this.$refs.collectionRecord.open('2', id)
    },
    handleCollectionRecord() {
      this.$refs.collectionRecord.open('2')
    },
    fetchCheckCount() {
      teacherdispatchorderlist({
        is_has_allocation_confirm: 1,
        isPaging: 0,
      }).then((response) => {
        let res = response.data
        if (res.data.list.length > 0) {
          let uncheckList = res.data.list.filter((item) => item.archive != 1)
          this.uncheck = uncheckList.length || 0
        } else {
          this.uncheck = 0
        }

        // archive
      })
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      // console.log('fiss',this.query)

      const params = Object.assign({}, this.query)
      if (this.statuses == 4) {
        params.dispatch_order_sync_statuses = 4
      } else {
        params.dispatch_order_sync_statuses = ''
      }
      // if (params.dispatch_order_sync_statuses&&params.dispatch_order_sync_statuses.length) {
      //   params.dispatch_order_sync_statuses =
      //     params.dispatch_order_sync_statuses.join(",");
      //     this.statuses = -1
      // } else {

      // }
      // if (
      //   params.allocation_manage_ids &&
      //   params.allocation_manage_ids.length > 0
      // ) {
      //   params.allocation_manage_ids = params.allocation_manage_ids.join(",");
      // }
      params.is_statistic_dispatch_order_company = 1
      teacherdispatchorderlist(params)
        .then((response) => {
          let res = response.data
          this.result = res.data.list || []
          this.pageTotal = res.data.count
          this.fetchCheckCount()

          /*    this.result.forEach((item, index) => {
             this.$set(item, 'learnhours', 0) // 设置属性
             if (item.old_id == null) {
               return false
             }
             let forQuery = {
               id: item.old_id,
               newId: item.id,
             }
             // 请求每一条数据的详情
             crmOutrecorddetail(forQuery).then((response_other) => {
               if (response_other.data.data.message == "ok") {
                 let arr = response_other.data.data.data.records
                 // 判断，为空则不计算
                 if (arr.length > 0) {
                   // 求和
                   let a = arr.reduce((acc, cur) => {
                     return acc + cur.hours
                   }, 0)
                   // 赋值
                   item.learnhours = a
                 }
               }
               this.$set(this.hoursObj, index, item.learnhours)
             })
           }) */
        })
        .then(() => {
          /*      setTimeout(() => {
               this.getListTwice(params)
             }, 100); */
        })
      // this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.follow) {
        this.query.follow = Number(this.query.follow)
      }
      if (this.query.is_has_allocation_confirm) {
        this.query.is_has_allocation_confirm = Number(
          this.query.is_has_allocation_confirm
        )
      }
      if (this.query.cid) {
        this.query.cid = Number(this.query.cid)
      }
      if (
        this.query.dispatch_order_sync_statuses &&
        this.query.dispatch_order_sync_statuses.length
      ) {
        this.query.dispatch_order_sync_statuses =
          this.query.dispatch_order_sync_statuses.split(',').map((v) => {
            return Number(v)
          })
      }
      if (this.query.start_create_time && this.query.end_create_time) {
        this.create_time = [
          this.$moment(Number(this.query.start_create_time)),
          this.$moment(Number(this.query.end_create_time)),
        ]
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      if (params.dispatch_order_sync_statuses) {
        params.dispatch_order_sync_statuses =
          params.dispatch_order_sync_statuses.filter((v) => v).join(',')
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    getListTwice(query) {
      teacherdispatchorderlist(query).then((res) => {
        this.result = res.data.data.list || []
        this.pageTotal = res.data.data.count
        if (JSON.stringify(this.hoursObj) != '{}') {
          this.result.forEach((item, index) => {
            this.$set(item, 'learnhours', this.hoursObj[index])
          })
        }
      })
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseList = val
    },
    // 筛选组件传值
    screenTable({ screenForm }) {
      this.$set(this.query, 'pageIndex', 1)
      this.query = {
        ...this.query,
        ...screenForm,
      }
      this.fetchList()
    },
    // 搜索框搜索
    inputSearch(e) {
      this.fetchList()
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    // 跳转沟通记录
    goRecord(item) {
      this.$router.push({
        name: `dispatchorderrecord`,
        query: item,
      })
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 3,
          follow_id: item.id,
        }
        createuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 500)
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 3,
          follow_id: item.id,
        }
        deleteuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 300)
      }
    },
    formateOrder() {
      // 把subjects参数转换为处理并转换字符串，必须要number格式
      if (this.EditDispatchOrderContent.subjects) {
        let arr = []
        this.EditDispatchOrderContent.subjects.forEach((item) => {
          arr.push(item.subject_code)
        })
        this.EditDispatchOrderContent.subjects = arr
      }
    },
    // 打开添加订单弹窗
    openAddDispatchOrderDialog() {
      this.AddDispatchOrderDialog = !this.AddDispatchOrderDialog
    },
    // 打开编辑订单弹窗
    openEditDispatchOrderDialog(item) {
      this.EditDispatchOrderContent = JSON.parse(JSON.stringify(item))
      this.formateOrder()
      this.EditDispatchOrderDialog = !this.EditDispatchOrderDialog
    },
    openCheckResultDialog(item) {
      this.CheckResultContent = JSON.parse(JSON.stringify(item))
      this.CheckResultDialog = !this.CheckResultDialog
    },
    // 添加订单组件提交
    AddDispatchOrderSubmit({ form }) {
      createteacherdispatchorder(form).then((res) => {
        if (res.data.code == 0) {
          this.fetchList()
          this.AddDispatchOrderDialog = false
        }
      })
    },
    // 修改订单组件提交
    EditDispatchOrderSubmit({ form }) {
      editeacherdispatchorder(form).then((res) => {
        if (res.data.code == 0) {
          this.$notify.success({
            title: '提示',
            message: '修改成功',
          })
          this.EditDispatchOrderDialog = false
          this.fetchList()
          /* 
          if (form.old_id == null) {
            this.fetchList();
            return false
          }
          // 总端修改成功之后还要再调一次老系统的接口同步数据
          signedBehalfedit(formateObjDaiqian(form, 'edit')).then(response => {
            if (response.data.data.message == "ok") {
              this.fetchList();
            } else {
              this.$notify.error({
                title: "提示",
                message: response.data.data.message,
              });
            }
          }) */
        }
      })
    },
    chooseAllEdit(archive_status, text) {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: '提示',
          message: '还没有选择要修改的数据',
        })
        return false
      }
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            rows: [],
          }
          this.chooseList.forEach((item) => {
            let obj = {
              id: item.id,
              archive: archive_status,
            }
            query.rows.push(obj)
          })
          editteacherdispatchorderlist(query).then((res) => {
            this.$nextTick(() => {
              this.fetchList()
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消操作',
          })
        })
    },
    // 选中数据后做处理删除
    chooseAllDelete() {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: '提示',
          message: '还没有选择要删除的数据',
        })
        return false
      }
      this.$confirm('确定要删除选中的数据吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            ids: [],
          }
          this.chooseList.forEach((item) => {
            query.ids.push(item.id)
          })
          deleteteacherdispatchorderlist(query).then((res) => {
            this.$nextTick(() => {
              this.fetchList()
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消删除',
          })
        })
    },
    getNum(row) {
      if (row.dispatch_log_total_lesson_hour) {
        // console.log("cc",(Number(row.dispatch_log_total_lesson_hour) /Number(row.hours)* 100).toFixed(2)+'')
        return (
          (Number(row.dispatch_log_total_lesson_hour) / Number(row.hours)) *
          100
        ).toFixed(2)
      }
      return 0
    },
    // 修改订单状态
    editTeacherOrder(item, text, status, canceltext) {
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            id: item.id,
            status: status,
          }
          editeacherdispatchorder(query).then((res) => {
            this.$nextTick(() => {
              this.dialogVisible = false
              this.fetchList()
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: canceltext,
          })
        })
    },
    CheckResultSubmit({ form }) {
      console.log(form)
      let query = {
        id: this.CheckResultContent.id,
        result: form,
      }
      matchdispatchorderresult(query).then((res) => {
        this.$nextTick(() => {
          this.CheckResultDialog = false
          this.fetchList()
          /*      if (this.CheckResultContent.old_id) {
                 let obj = formateObjDaiqian(this.CheckResultContent, 'edit')
                 obj.status = "匹配成功"
                 signedBehalfedit(obj).then((res) => {
     
                 });
               } */
        })
      })
    },
    // 对多选数组做判断处理
    filterArr() {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: '提示',
          message: '还没有选择要修改的数据',
        })
        return false
      }
      if (
        this.chooseList.findIndex((v) => {
          return [3].includes(v.dispatch_order_sync_status)
        }) > -1
      ) {
        this.$message.error(
          '选中班级里有匹配中的状态，请先取消匹配后再终止订单！'
        )
        return false
      }
      this.$confirm('您确定要取消吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            rows: [],
          }
          query.rows = this.chooseList.map((v) => {
            let obj = {
              id: v.id,
              status: 6,
              archive: 1,
            }
            return obj
          })
          editteacherdispatchorderlist(query).then((res) => {
            if (res.data.code == 0) {
              this.$notify.success({
                title: '提示',
                message: '操作成功',
              })
              this.fetchList()
            }
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消操作',
          })
        })
    },
    exportData(item) {
      signedBehalf(formateObjDaiqian(item, 'create')).then((res) => {
        if (res.data.data.message == 'ok') {
          editeacherdispatchorder({
            id: res.data.data.data.newId,
            old_id: res.data.data.data.id,
          }).then((resp) => {
            this.fetchList()
          })
        } else {
          this.$notify.error({
            title: '提示',
            message: res.data.data.message,
          })
        }
      })
    },
    openBatchEditWatcherDialog() {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: '提示',
          message: '还没有选择要修改的数据',
        })
        return false
      }
      this.BatchEditWatcherDialog = true
    },
    BatchEditWatcherSubmit(obj) {
      let query = {
        rows: this.chooseList.map((v) => {
          return {
            id: v.id,
            manage_watcher: obj.watcher,
          }
        }),
      }
      editteacherdispatchorderlist(query).then((res) => {
        this.$nextTick(() => {
          this.fetchList()
          this.$notify.success({
            title: '提示',
            message: '修改成功',
          })
          this.BatchEditWatcherDialog = false
        })
      })
    },
    handleToMatching(row) {
      this.matchId = row.id
      this.matchDialog = true
    },
    openConfirmList(row) {
      this.teachers =
        row.allocation_order_teacher_refs &&
        row.allocation_order_teacher_refs.length
          ? row.allocation_order_teacher_refs
          : []
      this.confirmId = row.id
      this.confirmOrder_id = row.allocation_order_obj.id
      this.confirmDialog = true
    },
    handleToOrderRecord(row) {
      this.orderId = row.allocation_order_obj.id
      this.recordGradeId = row.id
      this.recordDialog = true
    },
    openAutoDialog() {
      this.autoDialog = true
    },
    changeStatus(row) {
      this.$confirm('您确定要恢复订单吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            id: row.id,
            status: 50,
          }
          editeacherdispatchorder(query).then((res) => {
            this.fetchList()
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消操作',
          })
        })
    },
  },
}
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.operation-card {
  width: 100%;
  margin-top: 20px;
  .demo-form-inline {
    height: 64px;
    border-bottom: 2px dashed #d2d5df;
  }
}
.operation-menu {
  display: flex;
  justify-content: space-between;
  .menu_left {
    margin-top: 25px;
  }
  .menu_right {
    margin-top: 25px;
  }
}
.block {
  float: right;
  display: flex;
  justify-content: center;
}
.star {
  width: 18px;
  height: 18px;
}
.templateLink,
.link-template {
  .el-link {
    margin: 0px 5px;
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
}
.item {
  margin-top: 10px;
  margin-right: 40px;
}
.link-template {
  position: relative;
  .red-bradge {
    position: absolute;
    width: 25px;
    height: 13px;
    background: #f56c6c;
    border-radius: 6px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -4px;
    top: 0;
    span {
      font-size: 10px;
      color: #ffffff;
    }
  }
}
.badge {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>