<template>
  <div>
    <el-dialog
top="5vh"
      title="学员评价"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="980"
    >
      <el-table
        class="results"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column
          label="上课时间"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.student_real_lesson_start_time?$moment(scope.row.student_real_lesson_start_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="课程主题"
          width="300"
          show-overflow-tooltip
        >
          <template>
            {{course_plan.course_theme}}
          </template>
        </el-table-column>
        <el-table-column
          label="姓名"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.student.realname}}  
          </template>
        </el-table-column>
        <el-table-column
          label="学员评分"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.student_appraise_teacher_score?scope.row.student_appraise_teacher_score:0}}分
          </template>
        </el-table-column>
        <el-table-column
          prop="student_appraise_teacher_content"
          label="学员评价"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="评价时间"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-button @click="emitClose()">取消</el-button>
        <el-button
          type="primary"
          @click="emitClose()"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  lessonLogOne,
} from "@/request/api";

export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      results: [],
      course_plan: {},
    };
  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      lessonLogOne({ id: this.id }).then((response) => {
        let res = response.data
        this.results = res.data.lesson_students ? res.data.lesson_students : []
        this.course_plan = res.data.course_plan
      });
    },
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.item-value {
  font-size: 14px;
  color: #303133;
}
.el-form-item {
  margin-bottom: 8px !important;
}
</style>