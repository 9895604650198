<template>
  <div class="content">
    <el-dialog
      top="5vh"
      append-to-body
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="dialogFormVisible = false"
      :close-on-click-modal="false"
      width="410px"
    >
      <div class="institution-header">
        <img style="width: 80px; height: 80px" :src="detail.logo" alt="" />

        <div class="header-right">
          <div class="title">{{ `(${detail.code})${detail.name}` }}</div>
          <div class="sign">
            <div>
              <img src="@/assets/images/dingwei.png" alt="" />
              <div>{{ detail.province_name }}</div>
            </div>
            <div>
              <img src="@/assets/images/paiming.png" alt="" />
              <div>{{ `综合第${detail.college_rank}名` }}</div>
            </div>
          </div>
          <div class="category-box" v-if="detail.category_ids">
            <div
              class="category"
              v-for="(item, index) in detail.category_ids.split(',')"
              :key="index"
            >
              {{ college_category_ids.find((its) => its.value == item).label }}
            </div>
          </div>
        </div>
      </div>

      <div class="line" style="margin-top: 16px">
        <div>
          <img src="@/assets/images/dianhua.png" alt="" />
          <div>联系电话：</div>
        </div>
        <div>{{ detail.contact_phone }}</div>
      </div>
      <div class="line">
        <div>
          <img src="@/assets/images/dizhi.png" alt="" />
          <div>联系地址：</div>
        </div>
        <div>{{ detail.contact_address }}</div>
      </div>
      <div class="line">
        <div>
          <img src="@/assets/images/guanwang.png" alt="" />
          <div>学校官网：</div>
        </div>
        <div class="color-409EFF" @click="openUlr(detail.website)">
          {{ detail.website }}
        </div>
      </div>
      <div class="line">
        <div>
          <img src="@/assets/images/yuan.png" alt="" />
          <div>研究生院：</div>
        </div>
        <div class="color-409EFF" @click="openUlr(detail.graduate_website)">
          {{ detail.graduate_website }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { collegeInfo } from "@/request/api";

export default {
  data() {
    return {
      title: "院校详情",
      dialogFormVisible: false,
      detail: {},
      college_category_ids: [
        { value: 1, label: "985" },
        { value: 2, label: "211" },
        { value: 3, label: "一流大学" },
        { value: 4, label: "一流学科" },
        { value: 5, label: "自主划线" },
      ],
    };
  },
  created() {},
  methods: {
    open(code) {
      this.dialogFormVisible = true;
      this.getDetail(code);
    },
    openUlr(url) {
      window.open("https://" + url);
    },
    getDetail(code) {
      this.detail = {};
      collegeInfo({
        code: code,
      }).then((res) => {
        if (res.data.code == 0) {
          this.detail = res.data.data;
        }
        // console.log("ppp", res);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.institution-header {
  display: flex;

  .header-right {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    margin-left: 18px;
    font-size: 14px;
    font-family: HelveticaNeue, HelveticaNeue;
    color: #333333;
    .title {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      color: #303133;
      line-height: 16px;
    }
    .sign {
      margin-top: 10px;
      display: flex;
      font-size: 14px;
      font-family: HelveticaNeue, HelveticaNeue;
      color: #333333;
      & > div {
        display: flex;
        align-items: center;
      }
      & > div:nth-of-type(2) {
        margin-left: 24px;
      }
      img {
        width: 16px;
        height: 16px;
        margin-right: 2px;
      }
    }
    .category-box {
      margin-top: 10px;
      display: flex;
      .category {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFangSC-Regular;
        font-weight: normal;
        color: #266fe8;
        width: fit-content;
        padding: 4px 6px;
        background: #ecf2fe;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid #a4c9f7;
        margin-right: 10px;
      }
    }
  }
}

.line {
  margin-top: 12px;
  display: flex;
  font-size: 14px;
  font-family: HelveticaNeue, HelveticaNeue;
  color: #333333;
  & > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    height: 18px;
    flex-shrink: 0;
    img {
      width: 16px;
      height: 16px;
      margin-right: 2px;
    }
  }

  .color-409EFF {
    color: #409eff;
    cursor: pointer;
  }
}
</style>