<template>
  <div class="content">
    <el-dialog
top="5vh"
      title="退款"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="620px"
      @close="emitClose"
    >
      <el-descriptions title="" direction="vertical" :column="3" border>
        <el-descriptions-item label="订单号">{{
          content.order_no
        }}</el-descriptions-item>
        <el-descriptions-item label="订单金额">{{
          content.order_amount + "元"
        }}</el-descriptions-item>
        <el-descriptions-item label="退款金额">
          <el-input
            placeholder="请输入内容"
            v-model.number="refund_amount"
            style="width: 200px"
          >
            <span slot="append" class="inputspan" @click="allnum">全部</span>
          </el-input>
        </el-descriptions-item>
      </el-descriptions>
      <div class="line">
        <span class="line-span"
          >退款给付款机构：<span class="refund_amount"
            >{{ refund_amount }} </span
          >元</span
        >
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary" @click="refundOrder">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { paymentrefund } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      refund_amount: 0,
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {},
  },
  methods: {
    allnum() {
      this.refund_amount = this.content.order_amount;
    },
    emitClose() {
      this.$emit("close");
    },
    refundOrder() {
      let query = {
        ref_id: this.content.id,
        ref_type: 3,
        amount: this.refund_amount,
      };
      paymentrefund(query).then((res) => {
        if (res.data.code == 0) {
          this.$notify.success({
            title: "提示",
            message: "成功退款",
          });
          this.dialogFormVisible = false;
          this.$emit("getlist");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.my-label {
  background: #f2f6fc;
}

.my-content {
  background: #f2f6fc;
}
.inputspan {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 24px;
  color: #409eff;
  opacity: 1;
  cursor: pointer;
}
.line {
  margin: 36px 0px;
  border-top: dashed #dcdfe6 1px;
  .line-span {
    display: block;
    margin: 36px 16px;
  }
  .refund_amount {
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 24px;
    color: #f56c6c;
    opacity: 1;
  }
}
</style>