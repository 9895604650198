<template>
  <div class="content">
    <!--   <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/classservice/plan' }">计划管理</el-breadcrumb-item>
        <el-breadcrumb-item>导入</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
      </div>
    </div> -->
    <el-card>
      <div class="main">
        <div class="main-item">
          <div class="main-item-titleimg">
            <img
              src="@/assets/images/importstep1.png"
              alt=""
            />
          </div>
          <div class="html-content-div">
            <span v-html="textContent"></span>
          </div>
          <el-checkbox
            v-model="checked"
            style="margin-top: 24px"
          >我已阅读完上述关于模板的各个问题描述
          </el-checkbox>
          <div class="content-sub-div">
            <el-button
              type="primary"
              @click="downloadTemplate"
            >点击下载模板</el-button>
            <span>如已熟知或已下载 可跳过此步骤</span>
          </div>
        </div>
        <div class="main-item">
          <div class="main-item-titleimg">
            <img
              src="@/assets/images/importstep2.png"
              alt=""
            />
          </div>
          <div class="upload-view">
            <el-form
              :model="uploadData"
              label-position="left"
              ref="formRules"
              :rules="rules"
              label-width="100px"
              size="small"
            >
              <el-form-item label="选择老师：">
                <el-select
                  v-model="uploadData.file_teacher_id"
                  placeholder="请选择老师"
                >
                  <el-option
                    v-for="item in teachers"
                    :key="item.value"
                    :label="item.realname"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-upload
                drag
                class="upload-demo"
                :action="actionsUrl+'/api/v1/lesson/coursePlan/bulk/byFileImport'"
                :headers="myAuthorization"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
                :file-list="fileList"
                accept=".xlsx,.xls"
                :limit="1"
                :data="uploadData"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
                <div
                  class="el-upload__tip"
                  slot="tip"
                >
                  只能上传xlsx/xls文件，且不超过10M
                </div>
              </el-upload>
            </el-form>

          </div>
        </div>
        <div class="main-item">
          <div class="main-item-titleimg">
            <img src="@/assets/images/importstep3.png">
          </div>
          <div
            class="result"
            v-if="is_success==null"
            style="height:396px;background-color:#fff;"
          >
            <img
              src="@/assets/images/import_empty.png"
              alt=""
              srcset=""
              class="empty"
            >
            <div class="text">暂无导入文件</div>
          </div>
          <div
            class="result"
            v-else-if="is_success==true"
          >
            <img
              src="@/assets/images/import_success.png"
              alt=""
              srcset=""
              class="import-icon"
            >
            <div class="title">表格导入完成</div>
            <div class="text">此次成功导入{{count_insert}}条数据</div>
            <el-button
              type="primary"
              size="small"
              @click="handleToBack()"
            >返回列表</el-button>
          </div>
          <div
            class="result"
            v-else-if="is_success==false"
          >
            <img
              src="@/assets/images/import_error.png"
              alt=""
              srcset=""
              class="import-icon"
            >
            <div class="title">表格导入失败</div>
            <div class="text">请核对并修改以下信息后，再重新上传</div>
            <el-button
              type="primary"
              size="small"
              @click="openListDialog"
            >点击查看</el-button>
          </div>
        </div>
      </div>
    </el-card>
    <ImportErrorList
      :isVisible="listDialog"
      @close="listDialog=false"
      :list="errorList"
    />
  </div>
</template>

<script>
import { hostUrl } from "@/utils/helper"; // 环境判断
import AdministrationText from "./AdministrationText";
import {
  gradeOne
} from "@/request/api";
let mytoken = {
  Authorization: localStorage.getItem("token"),
}; // 要保证取到token
// 模板说明，根据不同接口获取不同说明
import ImportErrorList from "../../../components/DialogComponents/ClassService/ImportErrorList.vue";
export default {
  components: {
    ImportErrorList
  },
  data() {
    return {
      fileList: [],
      actionsUrl: hostUrl(), // 接口地址，判断是线上还是测试环境
      postUrl: "", // 请求地址
      myAuthorization: mytoken,
      tableData: [],
      tableRadio: "1",
      uploadValue: "", // 上传之后文件名显示在输入框里
      textContent: "",
      checked: false,
      listDialog: false,
      errorList: [],
      uploadData: {
      },
      is_success: null,
      teachers: [],
      count_insert: 0,
      rules: [],
    };
  },
  created() {
    this.textContent = AdministrationText.htmlContent
    this.uploadData.file_grade_id = this.$route.query.grade_id
    this.fetchTeacher()
    /*  this.uploadData.file_grade_id = 61
     this.uploadData.file_teacher_id = 442 */
  },
  computed: {},
  methods: {
    fetchTeacher() {
      gradeOne({ id: this.$route.query.grade_id }).then((response) => {
        let res = response.data
        if (res.data.teachers && res.data.teachers.length) {
          this.teachers = res.data.teachers.filter(v => {
            return v.teacher_grade_status == 1
          })
          this.$set(this.uploadData, 'file_teacher_id', this.teachers[0].id)
        }
      });
    },
    handleRemove(file) {
      this.uploadValue = "";
      this.is_success = null
    },
    handleSuccess(file, fileList) {
      this.is_success = file.data.is_success
      if (file.data.data) {
        this.count_insert = file.data.data.count_insert
      }
      if (file.data.error && file.data.error.error_data_list) {
        this.errorList = file.data.error.error_data_list
      }
    },
    beforeUpload(file) {
      if (this.uploadData.file_teacher_id) {
        return true
      } else {
        this.$message.error('请选择老师')
        return false
      }
      /*  console.log(file);
       return false */
    },
    getBlob(imgurl, filename = '文件') {
      var ajax = new XMLHttpRequest();
      ajax.open("GET", imgurl, true);
      ajax.responseType = 'blob';
      ajax.onload = e => {
        let res = e.target.response
        let blob = new Blob([res], { type: res.type }) // 这里的res为后端返回的流数据
        if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
          navigator.msSaveBlob(blob, filename)
        } else {
          const href = URL.createObjectURL(blob) //创建新的URL表⽰指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = filename //指定下载⽂件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        }
      }
      ajax.send()
    },
    downloadTemplate() {
      if (!this.checked) {
        this.$notify.error({
          title: "提示",
          message: "请勾选",
        });
        return false;
      }
      this.getBlob("https://upload.kaoyanbox.net/0a64fb1a36b8b240faad18e4634f6ae8.xlsx", '班级老师课程计划批量创建模板')
    },
    openListDialog() {
      this.listDialog = true
    },
    handleToBack() {
      this.$router.replace(`/classservice/plan`)
    }
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
}
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.el-card {
  margin-top: 20px;
}
.main {
  flex: 1;
  display: flex;
  justify-content: space-around;
  height: 770px;

  .main-item {
    padding: 0px 15px;
    flex: 1;
    & + .main-item {
      border-left: dashed 1px;
      /*     border-right: dashed 1px;
      border-left: dashed 1px; */
    }
    .main-item-titleimg {
      height: 6%;
      img {
        width: 128px;
        height: 40px;
      }
    }
    .el-select {
      width: 265px;
    }
    .result {
      margin-top: 36px;
      height: 277px;
      background-color: #e8f0fa;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .import-icon {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-bottom: 16px;
      }
      .empty {
        width: 251px;
        height: 223px;
      }
      .title {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
      .text {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        margin-top: 8px;
        color: #909399;
        margin-bottom: 16px;
      }
    }
  }
}
.el-radio-group {
  width: 100%;
  .el-radio-button {
  }
}
.upload-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .el-select {
    width: 360px;
  }
  .upload-demo {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.upload-dragger {
  border: solid;
}
.html-content-div {
  margin-top: 24px;
  height: 70%;
  overflow: auto;
}
.content-sub-div {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .el-button {
    width: 45%;
  }
  span {
    margin-top: 12px;
    display: block;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #c0c4cc;
    opacity: 1;
  }
}
</style>