<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/classservice/record' }">课时记录</el-breadcrumb-item>
        <el-breadcrumb-item>确认详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="'基本信息'"></TableTittle>
      </div>
      <div class="descriptions">
        <el-descriptions
          class="margin-top"
          :column="4"
          :colon="false"
          :labelStyle="labelStyle"
          :contentStyle="contentStyle"
        >
          <template slot="extra">
          </template>
          <el-descriptions-item label="老师姓名：">
            <div class="text-content">
              {{confirmInfo.lesson_teacher?confirmInfo.lesson_teacher.teacher.realname:''}}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="学生姓名：">
            <el-tooltip
              class="item"
              effect="dark"
              :content="confirmInfo.lesson_students&&confirmInfo.lesson_students.length?formateStudentName():''"
              placement="left-start"
            >
              <div class="text-content">
                {{formateStudentName()}}
              </div>
            </el-tooltip>
          </el-descriptions-item>
          <el-descriptions-item label="计划时长：">
            <div class="text-content">
              {{confirmInfo.course_plan?confirmInfo.course_plan.plan_lesson_hour_origin:0}}小时
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="上课时间：">
            <div class="text-content">
              {{confirmInfo.course_plan?$moment(confirmInfo.course_plan.plan_start_time).format("YYYY-MM-DD HH:mm"):''}}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="课时主题：">
            <div class="text-content">
              {{confirmInfo.course_plan?confirmInfo.course_plan.course_theme:''}}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="">
            <template slot="label"> </template>
          </el-descriptions-item>
          <el-descriptions-item
            label="课时内容："
            span="1"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="confirmInfo.course_plan?confirmInfo.course_plan.course_content:''"
              placement="left-start"
            >
              <div class="text-content">
                {{confirmInfo.course_plan?confirmInfo.course_plan.course_content:''}}
              </div>
            </el-tooltip>

          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="'确认记录'"></TableTittle>
        <div>
          <el-button
            type="primary"
            size="mini"
            @click="openAddConfirmRecordDialog"
            :disabled="confirmInfo.learn_manager_confirm_status==1"
          >确认</el-button>
          <el-button
            type="danger"
            size="mini"
            @click="refuseDialog=true"
            :disabled="confirmInfo.learn_manager_confirm_status==1||confirmInfo.lesson_status==2"
          >拒绝</el-button>
        </div>
      </div>
      <el-table
        :data="confirmList"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{  fontSize: '13px' }"
        border
      >
        <el-table-column
          prop="name"
          label="姓名"
          width="150"
          show-overflow-tooltip
          align="center"
        >
        
          <template slot-scope="scope">
            {{scope.row.name}}
            <el-tag
              size="mini"
              effect="plain"
              type="info"
            >{{scope.row.label}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          width="100"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              size="mini"
              v-if="scope.row.status=='待确认'"
              type="warning"
            >{{scope.row.status}}</el-tag>
            <el-tag
              size="mini"
              v-else-if="scope.row.status=='已拒绝'"
              type="danger"
            >{{scope.row.status}}</el-tag>
            <el-tag
              size="mini"
              type="success"
              v-else
            >{{scope.row.status}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="确认内容"
          align="left"
          show-overflow-tooltip
        >
          <template
            slot-scope="scope"
            v-if="scope.row.content"
          >
            <template v-if="scope.row.label=='学生'">
              <div>学生对于老师提交的实际上课时间及课时<span :class="scope.row.real_lesson_hour_area=='有异议'?'red-weight':'blue-weight'">{{scope.row.real_lesson_hour_area}}</span><span v-if="scope.row.real_lesson_hour_area=='有异议'">，请尽快联系沟通具体情况。</span></div>
            </template>
            <template v-if="scope.row.label=='老师'">
              <div>老师确认了实际上课时间：<span :class="{'red-weight':$moment(confirmInfo.course_plan.plan_start_time).format('YYYY-MM-DD HH:mm')!=scope.row.real_lesson_start_time}">{{scope.row.real_lesson_start_time}}</span>；实际上课时长：<span :class="{'red-weight':confirmInfo.course_plan.plan_lesson_hour_origin!=scope.row.real_lesson_hour}">{{scope.row.real_lesson_hour}}小时</span>；</div>
            </template>
            <template v-if="scope.row.label=='学管师'">
              <div>学管师确认了实际上课时间：<span :class="{'red-weight':$moment(confirmInfo.course_plan.plan_start_time).format('YYYY-MM-DD HH:mm')!=scope.row.real_lesson_start_time}">{{scope.row.real_lesson_start_time}}</span>；实际上课时长：<span :class="{'red-weight':confirmInfo.course_plan.plan_lesson_hour_origin!=scope.row.real_lesson_hour}">{{scope.row.real_lesson_hour}}小时</span>；</div>
            </template>
            <!-- student_real_lesson_hour_area -->
          </template>
        </el-table-column>
        <el-table-column
          label="提交时间"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.time?$moment(scope.row.time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="操作"
          width="150"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToConfirmDetail(scope.row)"
              :disabled="scope.row.status=='待确认'"
            >查看详情</el-link>
            <template v-if="scope.row.label=='学生'||scope.row.label=='老师'">
              <el-link
                type="primary"
                :underline="false"
                :disabled="confirmInfo.learn_manager_confirm_status==1||scope.row.status=='待确认'"
                @click="confirmWithdrawal(scope.row)"
              >撤回</el-link>
            </template>
            <template v-else>
              <el-link
                type="primary"
                :underline="false"
                :disabled="confirmInfo.learn_manager_confirm_status!=1"
                @click="confirmWithdrawal(scope.row)"
              >撤回</el-link>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="'记录详情'"></TableTittle>
        <div>
          <el-button
            type="primary"
            size="mini"
            @click="openAddConfirmFeedbackDialog"
          >新增</el-button>
        </div>
      </div>
      <el-table
        :data="feedbackList"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{  fontSize: '13px' }"
        border
      >

        <el-table-column
          prop="name_1st"
          label="姓名"
          width="150"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.name_1st}}
            <el-tag
              size="mini"
              type="info"
              effect="plain"
            >{{scope.row.label}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="类型"
          width="120"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="content"
          label="内容"
          show-overflow-tooltip
          align="left"
          min-width="600"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.type=='老师评价'"
              class="content-text"
            >
              对<span>{{scope.row.name_2nd}}</span>的评价：{{scope.row.content}}
            </div>
            <div
              v-else-if="scope.row.type=='课程评价'"
              class="content-text"
            >
              对<span>{{scope.row.name_2nd}}</span>老师的评价：{{scope.row.content}}
            </div>
            <div
              v-else-if="scope.row.type=='老师评分'"
              class="content-text"
            >
              对<span>{{scope.row.name_2nd}}</span>老师的评分：<span :class="{'red-weight':Number(scope.row.score)<6}">{{scope.row.score}}分</span>
            </div>
            <div
              v-else-if="scope.row.type=='确认记录'"
              class="img-content"
            >
              <img
                :src="item.url"
                alt=""
                srcset=""
                v-for="(item,index) in scope.row.picture_urls"
                :key="index"
              >
              <template v-if="scope.row.handle.handle_type==3">
                {{`我撤回了自己于“${$moment(parseTime(scope.row,'update_time')).format("YYYY-MM-DD HH:mm")}”提交的关于“确认的实际上课时间为“${$moment(parseTime(scope.row,'confirm_time')).format("YYYY-MM-DD HH:mm")}”的上课记录，稍后将重新确认。`}}
              </template>
              <template v-else-if="scope.row.handle.handle_type==0">
                <div v-if="scope.row.handle.target_student_id">
                  To<span class="weight">{{scope.row.handle.student_realname}}</span>学员：我代你进行了课程确认，{{scope.row.handle.handle_content}}
                </div>
                <div v-else-if="scope.row.handle.target_teacher_id">
                  To<span class="weight">{{scope.row.handle.teacher_realname}}</span>老师：我代你进行了课程确认，{{scope.row.handle.handle_content}}
                </div>
              </template>
              <template v-else-if="scope.row.handle.handle_type==1">
                <div v-if="scope.row.handle.target_student_id">
                  To<span class="weight">{{scope.row.handle.student_realname}}</span>学员：我对你的确认进行了修改，{{scope.row.handle.handle_content}}
                </div>
                <div v-else-if="scope.row.handle.target_teacher_id">
                  To<span class="weight">{{scope.row.handle.teacher_realname}}</span>老师：我对你的确认进行了修改，{{scope.row.handle.handle_content}}
                </div>
              </template>
              <template v-else-if="scope.row.handle.handle_type==2">
                <div v-if="scope.row.handle.target_student_id">
                  To<span class="weight">{{scope.row.handle.student_realname}}</span>学员：我撤回了你于{{$moment(parseTime(scope.row,'update_time')).format("YYYY-MM-DD HH:mm")}}提交的关于“确认实际上课时间{{$moment(parseTime(scope.row,'confirm_time')).format("YYYY-MM-DD HH:mm")}}”的上课确认记录，请根据实际课程情况重新进行确认！
                </div>
                <div v-else-if="scope.row.handle.target_teacher_id">
                  To<span class="weight">{{scope.row.handle.teacher_realname}}</span>老师：我撤回了你于{{$moment(parseTime(scope.row,'update_time')).format("YYYY-MM-DD HH:mm")}}提交的关于“确认实际上课时间{{$moment(parseTime(scope.row,'confirm_time')).format("YYYY-MM-DD HH:mm")}}”的上课确认记录，请根据实际课程情况重新进行确认！
                </div>
              </template>
              <template v-else-if="scope.row.handle.handle_type==4">
                课时拒绝理由： {{scope.row.handle.handle_content}}
              </template>
            </div>
            <div
              v-else
              class="content-text"
            >
              {{scope.row.content}}
            </div>
          </template>
        </el-table-column>
        <!--     <el-table-column
          prop="type"
          label="类型"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <!--      <el-table-column
          prop="name_2nd"
          label="对象"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <!--    <el-table-column
          prop="score"
          label="分数"
          width="80"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <el-table-column
          prop="area"
          label="查看范围"
          width="100"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-dropdown
              trigger="click"
              @command="(...event) =>{handleToCommand(event, scope.row)}"
              v-if="scope.row.type!='确认课时'"
            >
              <span class="el-dropdown-link">
                {{scope.row.area}}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0">全部</el-dropdown-item>
                <el-dropdown-item command="2">仅学生</el-dropdown-item>
                <el-dropdown-item command="3">仅内部</el-dropdown-item>
                <el-dropdown-item command="4">仅老师</el-dropdown-item>
                <el-dropdown-item command="5">仅对方</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span v-else>{{scope.row.area}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="提交时间"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            {{scope.row.time?$moment(scope.row.time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="100"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToFeedbackDetail(scope.row)"
            >查看详情</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <AddConfirmRecord
      :isVisible="AddConfirmRecordDialog"
      @close="AddConfirmRecordDialog=false"
      @submit="timeOutSubmit"
      :course_plan_id="confirmInfo.course_plan?confirmInfo.course_plan.id:null"
      :lesson_log_id="$route.query.lesson_log_id"
    />
    <AddConfirmFeedback
      :isVisible="AddConfirmFeedbackDialog"
      @close="AddConfirmFeedbackDialog=false"
      @submit="fetchDetail"
      :course_plan_id="confirmInfo.course_plan?confirmInfo.course_plan.id:null"
      :lesson_log_id="$route.query.lesson_log_id"
    />
    <Withdrawal
      :isVisible="WithdrawalDialog"
      @close="WithdrawalDialog=false"
      @submit="timeOutSubmit"
      :content="WithdrawalContent"
      :course_plan_id="confirmInfo.course_plan?confirmInfo.course_plan.id:null"
    />
    <ConfirmDetail
      :isVisible="ConfirmDetailDialog"
      @close="ConfirmDetailDialog=false"
      :content="ConfirmDetailContent"
      :lesson_log="confirmInfo"
    />
    <RecordFeedbackDetail
      :isVisible="RecordFeedbackDetailDialog"
      @close="RecordFeedbackDetailDialog=false"
      :content="RecordFeedbackDetailContent"
    />
    <ConfirmRecordRefuse
      :isVisible="refuseDialog"
      @close="refuseDialog=false"
      @submit="timeOutSubmit"
      :course_plan_id="confirmInfo.course_plan?confirmInfo.course_plan.id:null"
      :lesson_log_id="$route.query.lesson_log_id"
    />
  </div>
</template>

<script>
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import AddConfirmRecord from "@/components/DialogComponents/ClassService/AddConfirmRecord.vue";
import AddConfirmFeedback from "@/components/DialogComponents/ClassService/AddConfirmFeedback.vue";
import Withdrawal from "@/components/DialogComponents/ClassService/Withdrawal.vue";
import ConfirmDetail from "@/components/DialogComponents/ClassService/ConfirmDetail.vue";
import RecordFeedbackDetail from "@/components/DialogComponents/ClassService/RecordFeedbackDetail.vue";
import ConfirmRecordRefuse from "@/components/DialogComponents/ClassService/ConfirmRecordRefuse.vue";

import {
  confirmLesson,
  lessonLogOne,
  lessonLogEdit,
  handleLogEdit,

} from "@/request/api";
import {
  getStorage
} from "@/utils/helper";

export default {
  components: {
    TableTittle,
    AddConfirmRecord,
    AddConfirmFeedback,
    Withdrawal,
    ConfirmDetail,
    ConfirmRecordRefuse,
    RecordFeedbackDetail
  },
  data() {
    return {
      AddConfirmRecordDialog: false,
      AddConfirmFeedbackDialog: false,
      tableData: [],
      confirmInfo: {},
      feedbackList: [],
      confirmList: [],
      WithdrawalContent: {},
      WithdrawalDialog: false,
      ConfirmDetailDialog: false,
      ConfirmDetailContent: {},
      refuseDialog: false,
      RecordFeedbackDetailContent: {},
      RecordFeedbackDetailDialog: false,
      labelStyle: {
        width: '70px'
      },
      contentStyle: {
      }
    };
  },
  created() {
    setTimeout(() => {
      this.fetchDetail()
    }, 800);
    this.$store.dispatch('getStaffOptions')
  },
  computed: {

  },
  methods: {
    fetchDetail() {
      lessonLogOne({ id: this.$route.query.lesson_log_id }).then((response) => {
        let res = response.data
        this.confirmInfo = res.data
        this.formateFeedbackList()
        this.formateConfirmList()
      });
    },
    openAddConfirmRecordDialog() {
      this.AddConfirmRecordDialog = true
    },
    openAddConfirmFeedbackDialog() {
      this.AddConfirmFeedbackDialog = true
    },
    formateFeedbackList() {
      let arr = []
      this.confirmInfo.lesson_students.forEach((v, i) => {
        let studentArr = [
          //老师对学生的评价
          {
            name_2nd: v.student.realname,
            name_1st: this.confirmInfo.lesson_teacher.teacher.realname,
            label: '老师',
            type: '老师评价',
            area: this.formateArea(v.teacher_appraise_student_area),
            content: `${v.teacher_appraise_student_content || ""}`,
            time: this.confirmInfo.teacher_confirm_time,
            teacher_id: this.confirmInfo.lesson_teacher.teacher.id,
            student_id: v.id,
            type_other: 1,
          },
          //学生对老师的评价内容
          {
            name_1st: v.student.realname,
            name_2nd: this.confirmInfo.lesson_teacher.teacher.realname,
            label: '学生',
            type: '课程评价',
            area: this.formateArea(v.student_appraise_teacher_area),
            content: `${v.student_appraise_teacher_content || ""}`,
            time: v.student_confirm_time,
            score: v.student_appraise_teacher_score ? v.student_appraise_teacher_score + '分' : '0分',
            teacher_id: this.confirmInfo.lesson_teacher.teacher.id,
            student_id: v.id,
            type_other: 2,
          },
          //学生对老师的评价分数
          {
            name_1st: v.student.realname,
            name_2nd: this.confirmInfo.lesson_teacher.teacher.realname,
            label: '学生',
            type: '老师评分',
            area: this.formateArea(v.student_appraise_teacher_area),
            content: `${v.student_appraise_teacher_content || ""}`,
            time: v.student_confirm_time,
            score: v.student_appraise_teacher_score ? v.student_appraise_teacher_score : 0,
            teacher_id: this.confirmInfo.lesson_teacher.teacher.id,
            student_id: v.id,
            type_other: 3,

          },
          //学生对内容的评价
          {
            name_1st: v.student.realname,
            name_2nd: "",
            label: '学生',
            type: '内容反馈',
            area: this.formateArea(v.student_content_feedback_area),
            content: v.student_content_feedback_content || "",
            time: v.student_confirm_time,
            teacher_id: this.confirmInfo.lesson_teacher.teacher.id,
            student_id: v.id,
            type_other: 4,

          },
          //学生对课程的评价
          {
            name_1st: v.student.realname,
            name_2nd: "",
            label: '学生',
            type: '课程反馈',
            area: this.formateArea(v.student_course_feedback_area),
            content: v.student_course_feedback_content || "",
            time: v.student_confirm_time,
            teacher_id: this.confirmInfo.lesson_teacher.teacher.id,
            student_id: v.id,
            type_other: 5,

          },
        ]
        arr = arr.concat(studentArr)
      })
      // 老师对课程的评价
      let teacherObj = {
        name_1st: this.confirmInfo.lesson_teacher.teacher.realname,
        name_2nd: "",
        label: '老师',
        type: '课程反馈',
        area: this.formateArea(this.confirmInfo.teacher_course_feedback_area),
        content: this.confirmInfo.teacher_course_feedback_content || "",
        time: this.confirmInfo.teacher_course_feedback_time,
        teacher_id: this.confirmInfo.lesson_teacher.teacher.id,
        type_other: 6,

      }
      arr.push(teacherObj)

      // 学管师评价
      let learnFeedbackObj = {
        name_1st: this.confirmInfo.learn_manager.nickname,
        name_2nd: "",
        label: '学管师',
        type: '课程反馈',
        area: this.formateArea(this.confirmInfo.learn_manager_course_feedback_area),
        content: this.confirmInfo.learn_manager_course_feedback_content || "",
        time: this.confirmInfo.learn_manager_course_feedback_time,
        learn_manager_id: this.confirmInfo.learn_manager.id,
        type_other: 7,
      }
      arr.push(learnFeedbackObj)


      if (this.confirmInfo.learn_manager_confirm_status) {
        // 学管师确认内容
        let learnConfirmObj = {
          name_1st: this.confirmInfo.learn_manager.nickname,
          name_2nd: "",
          label: '学管师',
          type: '确认课时',
          area: '全部',
          content: this.confirmInfo.learn_manager_visit_content || "",
          time: this.confirmInfo.learn_manager_confirm_time,
          learn_manager_id: this.confirmInfo.learn_manager.id,
          type_other: 7,
        }
        arr.push(learnConfirmObj)
      }


      this.confirmInfo.lesson_handle_logs.forEach(item => {
        let final = {
          name_1st: this.$store.state.staffOptions.find((v => {
            return v.id == item.manage_creator
          })).nickname,
          name_2nd: "",
          label: '学管师',
          type: this.formateLogsType(item),
          area: this.formateArea(item.handle_area),
          content: this.formateLogsContent(item),
          time: item.create_time,
          picture_urls: item.handle_picture_urls ? JSON.parse(item.handle_picture_urls) : [],
          handle: item,
          type_other: 8,

        }
        arr.push(final)
      })
      this.feedbackList = arr.filter(v => { return v.content && v.time })
      this.feedbackList.sort(this.compare('time'));
    },
    compare(attr) {
      return function (a, b) {
        var val1 = a[attr];
        var val2 = b[attr];
        return val2 - val1;
      }
    },
    formateLogsType(item) {
      if (item.handle_type == 6) {
        return '课程反馈'
      } else {
        return '确认记录'
      }
    },
    formateLogsContent(item) {
      if (item.handle_type == 6) {
        return item.handle_content
      } else {
        return '1'
      }
    },
    formateConfirmList() {
      let arr = []
      this.confirmInfo.lesson_students.forEach((v, i) => {
        let studentArr = [
          // 学生的确认
          {
            name: v.student.realname,
            label: '学生',
            status: this.formateStudentConfirmStatus(v),
            content: v.student_confirm_status == 1 ? `学生确认了实际上课时间：${this.$moment(v.student_real_lesson_start_time).format("YYYY-MM-DD HH:mm")}；实际上课时长：${this.formateStudentRealLessonHour(v.student_real_lesson_hour_area)}；` : "",
            real_lesson_start_time: v.student_confirm_status == 1 ? this.$moment(v.student_real_lesson_start_time).format("YYYY-MM-DD HH:mm") : '',
            real_lesson_hour_area: v.student_confirm_status == 1 ? this.formateStudentRealLessonHour(v.student_real_lesson_hour_area) : '',
            time: v.student_confirm_time,
            is_handle: v.student_by_handle_status,
            origin_data: v,
          }
        ]
        arr = arr.concat(studentArr)
      })
      // 老师的确认
      let teacherObj = {
        name: this.confirmInfo.lesson_teacher.teacher.realname,
        label: '老师',
        /* status: this.confirmInfo.teacher_confirm_status == 1 ? '已确认' : '待确认', */
        status: this.formateTeacherConfirmStatus(),
        content: this.confirmInfo.teacher_confirm_status == 1 ? `老师确认了实际上课时间：${this.$moment(this.confirmInfo.teacher_real_lesson_start_time).format("YYYY-MM-DD HH:mm")}；实际上课时长：${this.confirmInfo.teacher_real_lesson_hour ? this.confirmInfo.teacher_real_lesson_hour : 0}小时；` : "",
        time: this.confirmInfo.teacher_confirm_time,
        id: this.confirmInfo.lesson_teacher.teacher.id,
        real_lesson_start_time: this.confirmInfo.teacher_confirm_status == 1 ? this.$moment(this.confirmInfo.teacher_real_lesson_start_time).format("YYYY-MM-DD HH:mm") : '',
        real_lesson_hour: this.confirmInfo.teacher_confirm_status == 1 ? `${this.confirmInfo.teacher_real_lesson_hour ? this.confirmInfo.teacher_real_lesson_hour : 0}` : '',
      }
      arr.push(teacherObj)
      if (this.confirmInfo.learn_manager_confirm_status == 1) {
        let learn_managerObj = {
          name: this.confirmInfo.learn_manager.nickname,
          label: '学管师',
          status: this.confirmInfo.learn_manager_confirm_status == 1 ? '已确认' : '待确认',
          content: this.confirmInfo.learn_manager_confirm_status == 1 ? `学管师确认了实际上课时间：${this.$moment(this.confirmInfo.learn_manager_confirm_time).format("YYYY-MM-DD HH:mm")}；实际上课时长：${this.confirmInfo.final_real_lesson_hour ? this.confirmInfo.final_real_lesson_hour : 0}小时；` : "",
          time: this.confirmInfo.learn_manager_confirm_time,
          id: this.confirmInfo.learn_manager.id,
          real_lesson_start_time: this.confirmInfo.learn_manager_confirm_status == 1 ? this.$moment(this.confirmInfo.final_real_lesson_start_time).format("YYYY-MM-DD HH:mm") : '',
          real_lesson_hour: this.confirmInfo.learn_manager_confirm_status == 1 ? `${this.confirmInfo.final_real_lesson_hour ? this.confirmInfo.final_real_lesson_hour : 0}` : '',
        }

        arr.push(learn_managerObj)
      }
      this.confirmList = arr
    },
    formateArea(area) {
      const areas = {
        '0': '全部',
        '1': '仅学管师（废弃）',
        '2': '仅学生',
        '3': '仅内部',
        '4': '仅老师',
        '5': '仅对方',
      }
      return areas[area] || ""
    },
    formateLearn_managerHandle_type(item) {
      /*  0代确认1代修改2代撤回3暂停（学管师撤回）4拒绝 */
      /*     if (item.handle_type == '3') {
            let obj = JSON.parse(item.before)
            return `我撤回了自己于“${this.$moment(obj.update_time).format("YYYY-MM-DD HH:mm")}”提交的关于“确认的实际上课时间为“${this.$moment(obj.learn_manager_confirm_time).format("YYYY-MM-DD HH:mm")}”的上课记录，稍后将重新确认。`
          } else if (item.handle_type == '0') {
            if (item.target_student_id) {
              return `To${item.student_realname}学员：我代你进行了课程确认，${item.handle_content}`
            } else if (item.target_teacher_id) {
              return `To${item.teacher_realname}老师：我代你进行了课程确认，${item.handle_content}`
            }
          } else if (item.handle_type == '1') {
            if (item.target_student_id) {
              return `To${item.student_realname}学员：我对你的确认进行了修改，${item.handle_content}`
            } else if (item.target_teacher_id) {
              return `To${item.teacher_realname}老师：我对你的确认进行了修改，${item.handle_content}`
            }
          } */
    },
    formateTeacherConfirmStatus() {
      if (this.confirmInfo.teacher_confirm_status == 1) {
        if (this.confirmInfo.teacher_by_handle_status == 1) {
          return '已确认（代）'
        } else if (this.confirmInfo.teacher_by_handle_status == 2) {
          return '已修改（代）'
        } else {
          return '已确认'
        }
      } else {
        return '待确认'
      }
    },
    formateStudentConfirmStatus(row) {
      if (row.student_confirm_status == 1) {
        if (row.student_by_handle_status == 1) {
          return '已确认（代）'
        } else if (row.student_by_handle_status == 2) {
          return '已修改（代）'
        } else {
          return '已确认'
        }
      } else if (row.student_confirm_status == 0) {
        return '待确认'
      } else if (row.student_confirm_status == 2) {
        return '已拒绝'
      }
      /*    const statuss = {
           '0': '待确认',
           '1': '已确认',
           '2': '已拒绝',
         }
         return statuss[status] || "" */
    },
    formateStudentRealLessonHour(area) {
      const areas = {
        '0': '确认无误。',
        '1': '有异议',
        '2': '大于计划课时',
      }
      // const areas = {
      //   '0': '等于计划课时',
      //   '1': '小于计划课时',
      //   '2': '大于计划课时',
      // }
      return areas[area] || ""
    },
    timeOutSubmit() {
      setTimeout(() => {
        this.fetchDetail()
      }, 500);
    },
    openWithdrawal(row) {
      this.WithdrawalContent = Object.assign({}, row)
      this.WithdrawalDialog = true
    },
    handleToConfirmDetail(row) {
      this.ConfirmDetailDialog = true
      this.ConfirmDetailContent = Object.assign({}, row)
    },
    parseTime(row, type) {
      let obj = JSON.parse(row.handle.before)
      if (type == 'update_time') {
        return obj.update_time
      } else if (type == 'confirm_time') {
        if (obj.target_student_id) {
          return obj.student_real_lesson_start_time
        } else if (obj.target_teacher_id) {
          return obj.teacher_real_lesson_start_time
        } else {
          return obj.final_real_lesson_start_time
        }
      }
    },
    confirmWithdrawal(row) {
      if (row.label == '学生') {
        this.$confirm(`是否确定撤回${row.name}学员的确认`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let params = {
            student_confirm_status: 0,
            student_content_feedback_content: null,
            student_appraise_teacher_content: null,
            student_appraise_teacher_score: null,
            student_real_lesson_start_time: null,
            student_real_lesson_hour_area: null,
            student_confirm_time: null,
            course_plan_id: this.confirmInfo.course_plan_id,
            user_type: 'student',
            learn_manager: getStorage('userInfo').id,
            student_id: row.origin_data.student_id,
            is_by_handle: 1,
            handle_area: 5,
            handle_type: 2,
            handle_learn_manager_id: getStorage('userInfo').id,
          }
          confirmLesson(params).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.fetchDetail()
            }
          });
        })
      } else if (row.label == '老师') {
        this.$confirm(`是否确定撤回${row.name}老师的确认`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let params = {
            teacher_confirm_status: 0,
            teacher_real_lesson_start_time: null,
            teacher_real_lesson_hour: null,
            teacher_confirm_time: null,
            course_plan_id: this.confirmInfo.course_plan_id,
            user_type: 'teacher',
            learn_manager: getStorage('userInfo').id,
            teacher_id: row.id,
            is_by_handle: 1,
            handle_area: 5,
            handle_type: 2,
            handle_learn_manager_id: getStorage('userInfo').id,
          }
          confirmLesson(params).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.fetchDetail()
            }
          });
        })
      } else if (row.label == '学管师') {
        this.$confirm(`是否确定撤回自己的确认`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let params = {
            course_plan_id: this.confirmInfo.course_plan_id,
            user_type: 'learn_manager',
            learn_manager: getStorage('userInfo').id,
            handle_area: 0,
            is_stop: 1,
          }
          confirmLesson(params).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.fetchDetail()
            }
          });
        })
      }
    },
    handleToFeedbackDetail(row) {
      this.RecordFeedbackDetailContent = Object.assign({}, row)
      this.RecordFeedbackDetailDialog = true
    },
    handleToCommand(param, row) {
      if (row.type_other == 8) {
        const data = {
          id: row.handle.id,
          handle_area: param[0],
        }
        handleLogEdit(data).then((response) => {
          let res = response.data
          if (res.code == 0) {
            this.fetchDetail()
          }
        });
      } else {
        const data = {
          id: this.confirmInfo.id
        }
        if (row.type_other == 1) {
          data.arr_lesson_student_ref = [
            {
              id: row.student_id,
              teacher_appraise_student_area: param[0],
            }
          ]
        } else if (row.type_other == 2 || row.type_other == 3) {
          data.arr_lesson_student_ref = [
            {
              id: row.student_id,
              student_appraise_teacher_area: param[0],
            }
          ]
        } else if (row.type_other == 4) {
          data.arr_lesson_student_ref = [
            {
              id: row.student_id,
              student_content_feedback_area: param[0],
            }
          ]
        } else if (row.type_other == 5) {
          data.arr_lesson_student_ref = [
            {
              id: row.student_id,
              student_course_feedback_area: param[0],
            }
          ]
        } else if (row.type_other == 6) {
          data.teacher_course_feedback_area = param[0]
        } else if (row.type_other == 7) {
          data.learn_manager_course_feedback_area = param[0]
        }
        lessonLogEdit(data).then((response) => {
          let res = response.data
          if (res.code == 0) {
            this.fetchDetail()
          }
        });
      }
    },
    formateStudentName() {
      if (this.confirmInfo.lesson_students && this.confirmInfo.lesson_students.length) {
        return this.confirmInfo.lesson_students.map(v => {
          return v.student.realname
        }).join(',')
      } else {
        return '暂无'
      }
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  .content-text {
    span {
      font-weight: 800;
    }
  }
  .img-content {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  .weight {
    font-weight: 800;
  }
  .red-weight {
    font-weight: 800;
    color: red;
  }
  .blue-weight {
    font-weight: 800;
    color: blue;
  }
  .text-content {
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  /deep/.el-descriptions-item__label {
    white-space: nowrap;
  }
  .descriptions {
    padding-left: 8px;
    width: 60%;
    /*  min-width: 60%; */
  }
}
</style>
