<template>
  <div class="dialog-wrap">
    <el-dialog top="5vh"
               title="提现详情"
               :close-on-click-modal="false"
               :visible.sync="dialogVisible"
               @close="handleClose"
               width="1180px">
      <div class="header-wrap">
        <div class="header-item">
          <p>本次申请提现：{{withdrawInfo.amount || 0}}元</p>
        </div>
        <div class="header-item">
          <p>收款方式：{{handleRender(withdrawInfo.fee_account_type, 'payType')}}</p>
          <p>收款人姓名：{{withdrawInfo.fee_account_name}}</p>
          <p>收款人账号：{{withdrawInfo.fee_account_card}}</p>
        </div>
        <div class="header-item">
          <p>提现状态：
            <el-tag v-if="withdrawInfo.status === 0">提现中</el-tag>
            <el-tag type="success"
                    v-if="withdrawInfo.status === 1">提现成功</el-tag>
            <el-tag type="danger"
                    v-if="withdrawInfo.status === 2">提现已撤销</el-tag>
            <el-tag type="danger"
                    v-if="withdrawInfo.status === 3">提现被驳回</el-tag>
          </p>
          <div v-if="withdrawInfo.status === 0">
            <el-button size="mini"
                       type="primary"
                       @click="openDialogFormVisible">审核通过，确认去打款</el-button>
            <el-button size="mini"
                       plain
                       @click="handleWithdrawCancel">审核不通过，驳回提现</el-button>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="table-wrap"
           v-if="withdraw_user_type==1">
        <div class="title">
          <h3>提现订单核对</h3>
          <!-- <el-button class="export-button" type="text">下载excel</el-button> -->
        </div>
        <el-table :data="results"
                  border
                  style="width: 100%"
                  size="mini">
          <el-table-column prop="ref_id"
                           label="订单ID"
                           width="80"
                           align="center">
          </el-table-column>
          <el-table-column label="商品类型"
                           width="100"
                           align="center">
            <template slot-scope="scope">
              <span>{{ handleRender(scope.row.ref_type, 'productType') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="商品名称"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="text-align:left;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{ scope.row.goods_name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="user_nickname"
                           label="购买用户"
                           width="100"
                           align="center">
          </el-table-column>
          <el-table-column label="订单金额(元)"
                           width="100"
                           align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.amount }}元</span>
            </template>
          </el-table-column>
          <el-table-column label="订单状态"
                           width="80"
                           align="center">
            <template slot-scope="scope">
              <span>{{ statusRender(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="支付时间"
                           width="140"
                           align="center">
            <template slot-scope="scope">
              <span>{{  $moment(scope.row.create_time).format('YYYY-MM-DD HH:mm')  }}</span>
            </template>
          </el-table-column>
          <el-table-column label="订单收益"
                           width="100"
                           align="center">
            <template slot-scope="scope">
              <span>{{scope.row.type==2?'-':''}}{{ scope.row.amount }}元</span>
            </template>
          </el-table-column>
          <el-table-column label="收益类型"
                           width="100"
                           align="center">
            <template slot-scope="scope">
              <!--     {{scope.row.parent_level==0?'推广佣金':'邀请奖励'}} -->
              <span>{{ handleRender(scope.row.parent_level, 'parentLevel') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="结算状态"
                           width="100"
                           align="center">
            <template slot-scope="scope">
              <el-tag type="danger"
                      size="mini"
                      v-if="scope.row.status==0">待结算</el-tag>
              <el-tag size="mini"
                      v-if="scope.row.status==1">已结算</el-tag>
              <el-tag type="info"
                      size="mini"
                      v-if="scope.row.status==2">已撤销</el-tag>
              <el-tag type="success"
                      size="mini"
                      v-if="scope.row.status==3">已提现</el-tag>
              <el-tag type="warning"
                      size="mini"
                      v-if="scope.row.status==4">提现中</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table-wrap"
           v-if="withdraw_user_type==2">
        <div class="title">
          <h3>提现订单核对</h3>
        </div>
        <el-table :data="results"
                  border
                  style="width: 100%"
                  size="mini">
          <el-table-column prop="find_order_order_no"
                           label="订单编号"
                           width="150"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.payment_order_obj">{{scope.row.payment_order_obj.out_trade_no}}</span>
              <span v-else>{{scope.row.find_order_order_no}}</span>
            </template>

          </el-table-column>
          <el-table-column prop="remark"
                           label="订单详情"
                           min-width="200"
                           align="center">
          </el-table-column>
          <el-table-column label="订单信息"
                           align="left"
                           min-width="400"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.payment_order_obj">{{scope.row.payment_order_obj.product_name}}</span>
              <span v-else>{{scope.row.find_order_apply_year}}考研 | {{scope.row.find_order_apply_college_name}} |
                ({{scope.row.find_order_apply_major_code}}){{scope.row.find_order_apply_major_name }}</span>

            </template>
          </el-table-column>
          <el-table-column label="订单收益"
                           width="100"
                           align="center">
            <template slot-scope="scope">
              <span>{{scope.row.type==2?'-':''}}{{ scope.row.amount }}元</span>
            </template>
          </el-table-column>
          <!--    <el-table-column
            label="订单金额"
            align="center"
            show-overflow-tooltip
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.find_order_total_in_amount }}元
            </template>
          </el-table-column> -->
          <el-table-column label="订单状态"
                           align="center"
                           width="80"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.payment_order_obj">
                <span v-if="scope.row.payment_order_obj.pay_status == 0">未支付</span>
                <span v-if="scope.row.payment_order_obj.pay_status == 1">已支付</span>
              </div>
              <div v-else>
                <span v-if="scope.row.find_order_status == 1">待接单</span>
                <span v-if="scope.row.find_order_status == 2">接单中</span>
                <span v-if="scope.row.find_order_status == 3">待支付</span>
                <span v-if="scope.row.find_order_status == 4">待确认</span>
                <span v-if="scope.row.find_order_status == 5">已完成</span>
                <span v-if="scope.row.find_order_status == 6">已取消</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="结算状态"
                           width="100"
                           align="center">
            <template slot-scope="scope">
              <el-tag type="danger"
                      size="mini"
                      v-if="scope.row.status==0">待结算</el-tag>
              <el-tag size="mini"
                      v-if="scope.row.status==1">已结算</el-tag>
              <el-tag type="info"
                      size="mini"
                      v-if="scope.row.status==2">已撤销</el-tag>
              <el-tag type="success"
                      size="mini"
                      v-if="scope.row.status==3">已提现</el-tag>
              <el-tag type="warning"
                      size="mini"
                      v-if="scope.row.status==4">提现中</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="find_order_sender_teacher_name"
                           label="发单人"
                           align="center"
                           width="100"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{   scope.row.find_order_sender_company_area}}-{{ scope.row.find_order_sender_teacher_name }}
            </template>
          </el-table-column>
          <el-table-column label="支付时间"
                           width="140"
                           align="center">
            <template slot-scope="scope">
              <span>{{  $moment(scope.row.create_time).format('YYYY-MM-DD HH:mm')  }}</span>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <div class="footer-wrap">
        <el-pagination background
                       layout="total, prev, pager, next, sizes, jumper"
                       :current-page="Number(query.pageIndex)"
                       :page-sizes="[10, 20, 50, 100, 500]"
                       :page-size="Number(query.pageSize)"
                       :total="Number(pageTotal)"
                       @size-change="handleSizeChange"
                       @current-change="handlePageChange"></el-pagination>
      </div>
    </el-dialog>
    <el-dialog top="5vh"
               title="请输入验证码"
               :visible.sync="dialogFormVisible"
               width="420px"
               @close="dialogFormVisible=false">
      <el-form :model="form"
               style="display:flex;">
        <el-form-item :label="phone">
          <el-input v-model="form.password"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="align-self:flex-end;margin-left:16px;">
          <el-button type="primary"
                     size="small"
                     :disabled="codedisabled"
                     @click="sendsms">{{
          yzcodetext
        }}</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="handleAccept">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog top="5vh"
               title="拒绝理由"
               :visible="refuseVisible"
               width="500px"
               @close="refuseVisible=false">
      <el-form :model="refuseForm"
               ref="dialogForm"
               :rules="rules">
        <el-form-item prop="remark">
          <el-input type="textarea"
                    v-model="refuseForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="refuseVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submit('dialogForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { adminUrl, crmUrl } from '@/utils/helper.js'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {}
      },
    },
    withdraw_user_type: {
      type: Number,
      default: 1,
    },
    cid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      query: {
        ids: '',
        pageIndex: 1,
        pageSize: localStorage.getItem('pageSize')
          ? Number(localStorage.getItem('pageSize'))
          : 10,
        isPaging: 1,
      },
      pageTotal: 10,
      withdrawInfo: {},
      profitInfo: {},
      results: [],
      dialogFormVisible: false,
      form: {},
      refuseForm: {},
      rules: {
        remark: [
          { required: true, message: '请输入拒绝理由', trigger: 'blur' },
        ],
      },
      refuseVisible: false,
      phone: '',
      codedisabled: false,
      yzcodetext: '获取验证码',
      codeTime: 0,
    }
  },
  watch: {
    visible(val) {
      this.dialogVisible = val

      if (val) {
        this.fetchWithdrawInfo()
      }
    },
  },
  methods: {
    // 提现信息查询
    fetchWithdrawInfo() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/member/withdraw/order`,
        method: 'get', //可以省略 不写默认是get
        params: { id: this.content.id },
      }).then((res) => {
        this.withdrawInfo = res.data.data
        if (res.data.data.ref_ids) {
          this.query.ids = res.data.data.ref_ids
          if (this.withdraw_user_type == 1) {
            this.fetchProfitLogs()
          } else if (this.withdraw_user_type == 2) {
            this.fetchCompanyLogs()
          }
        }
      })
    },
    // 驳回提现
    handleWithdrawCancel() {
      this.refuseVisible = true
    },
    // 学长营收记录请求
    fetchProfitLogs() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/member/profit/logs`,
        method: 'get', //可以省略 不写默认是get
        params: this.query,
      }).then((res) => {
        this.results = res.data.data.list || []
        this.pageTotal = res.data.data.count
      })
    },
    fetchCompanyLogs() {
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/company/account/log`,
        method: 'get', //可以省略 不写默认是get
        params: {
          ...this.query,
          cid: this.cid,
        },
      }).then((res) => {
        this.results = res.data.data.list || []
        this.pageTotal = res.data.data.count
      })
    },
    // 分页条数变化
    handleSizeChange(val) {
      this.$set(this.query, 'pageSize', val)
      localStorage.setItem('pageSize', val)
      this.fetchWithdrawInfo()
      // this.fetchProfitLogs()
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, 'pageIndex', val)
      this.fetchWithdrawInfo()
      // this.fetchProfitLogs()
    },
    handleClose() {
      this.$emit('close')
    },
    handleRender(value, type) {
      let productTypes = {
        1: '课程',
        2: '题库',
        3: '专栏',
        4: '资料',
        5: '文章',
        6: '学长',
        7: '联报',
        8: '课酬',
      }
      let status = {
        0: '待结算',
        1: '已结算',
        2: '已退款',
        3: '已提现',
        4: '提现中',
      }
      let payTypes = {
        1: '支付宝',
        2: '微信',
      }
      let parentLevel = {
        0: '推广佣金',
        1: '邀请奖励',
        200: '创作收益',
        201: '课时课酬',
      }
      if (type == 'productType') {
        return productTypes[value] || ''
      } else if (type == 'status') {
        return status[value] || ''
      } else if (type == 'payType') {
        return payTypes[value] || ''
      } else if (type == 'parentLevel') {
        return parentLevel[value] || ''
      }
      return ''
    },
    openDialogFormVisible() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/member/withdraw/smscode/mobile`,
        method: 'get', //可以省略 不写默认是get
      }).then((res) => {
        this.phone = `输入${res.data.data}收到的手机验证码`
        this.dialogFormVisible = true
      })
    },
    handleAccept() {
      if (!this.form.password) {
        this.$message.error(`请输入拒绝理由`)
        return
      }
      let query = {
        password: this.form.password,
        withdraw_id: this.content.id,
      }
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/member/withdraw/access`,
        method: 'post', //可以省略 不写默认是get
        data: query,
      }).then((res) => {
        console.log(res)
        if (res.data.code == 0) {
          this.$message.success(`操作成功`)
          this.dialogFormVisible = false
          this.$emit('submit')
          this.handleClose()
        } else if (res.data.code == 404) {
          if (res.data.error == 'NO_AUTH') {
            this.$message.error(`没有该接口权限`)
          } else if (res.data.error == 'AMOUNT_LIMIT') {
            this.$message.error(`金额超限`)
          } else if (res.data.error == 'OPENID_ERROR') {
            /*       else if (res.data.error == 'PARAM_ERROR') {
                  this.$message.error(`参数错误`);
                } */
            this.$message.error(`Openid错误`)
          } else if (res.data.error == 'SEND_FAILED') {
            this.$message.error(`付款错误`)
          } else if (res.data.error == 'NOTENOUGH') {
            this.$message.error(`余额不足`)
          } else if (res.data.error == 'SYSTEMERROR') {
            this.$message.error(`系统繁忙，请稍后再试`)
          } else if (res.data.error == 'NAME_MISMATCH') {
            this.$message.error(`姓名校验出错`)
          } else if (res.data.error == 'SIGN_ERROR') {
            this.$message.error(`签名错误`)
          } else if (res.data.error == 'XML_ERROR') {
            this.$message.error(`Post内容出错`)
          } else if (res.data.error == 'FATAL_ERROR') {
            this.$message.error(`两次请求参数不一致`)
          } else if (res.data.error == 'FREQ_LIMIT') {
            this.$message.error(`超过频率限制，请稍后再试`)
          } else if (res.data.error == 'MONEY_LIMIT') {
            this.$message.error(
              `已经达到今日付款总额上限/已达到付款给此用户额度上限`
            )
          } else if (res.data.error == 'CA_ERROR') {
            this.$message.error(`商户API证书校验出错`)
          } else if (res.data.error == 'V2_ACCOUNT_SIMPLE_BAN') {
            this.$message.error(`无法给未实名用户付款`)
          } else if (res.data.error == 'PARAM_IS_NOT_UTF8') {
            this.$message.error(`请求参数中包含非utf8编码字符`)
          } else if (res.data.error == 'SENDNUM_LIMIT') {
            this.$message.error(`该用户今日付款次数超过限制`)
          } else if (res.data.error == 'RECV_ACCOUNT_NOT_ALLOWED') {
            this.$message.error(`收款账户不在收款账户列表`)
          } else if (res.data.error == 'PAY_CHANNEL_NOT_ALLOWED') {
            this.$message.error(`本商户号未配置API发起能力`)
          } else if (res.data.error == 'SEND_MONEY_LIMIT') {
            this.$message.error(`已达到今日商户付款额度上限`)
          } else if (res.data.error == 'RECEIVED_MONEY_LIMIT') {
            this.$message.error(`已达到今日付款给此用户额度上限`)
          } else if (res.data.error == 'PARAM_ERROR') {
            this.$message.error(`付款商户号无法使用该品牌信息`)
          } else if (res.data.error == 'NO_AUTH') {
            this.$message.error(`付款商户号无品牌红包产品权限`)
          } else if (res.data.error == 'PARAM_ERROR') {
            this.$message.error(`品牌信息查询失败`)
          } else if (res.data.error == 'EXCEED_PAYEE_ACCOUNT_LIMIT') {
            this.$message.error(`用户账户收款受限`)
          } else if (res.data.error == 'PAYER_ACCOUNT_ABNORMAL') {
            this.$message.error(`商户账户付款受限`)
          } else if (res.data.error == 'PAYEE_ACCOUNT_ABNORMAL') {
            this.$message.error(`用户账户收款异常`)
          }
        }
      })
    },
    // 订单状态渲染
    statusRender(row) {
      const order_status = {
        0: '待支付',
        1: '已支付',
        2: '待发货',
        3: '待收货',
        4: '已完成',
        5: '已取消',
      }
      return order_status[row.order_status] || ''
    },
    submit(rules) {
      if (!this.refuseForm.remark) {
        this.$message.error(`请输入拒绝理由`)
        return
      }
      let query = {
        remark: this.refuseForm.remark,
        withdraw_id: this.withdrawInfo.id,
      }
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/member/withdraw/revoke`,
        method: 'post', //可以省略 不写默认是get
        data: query,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message.success(`驳回成功`)
          this.refuseVisible = false
          this.$emit('submit')
          this.handleClose()
        }
      })
    },
    timer() {
      if (this.codeTime > 0) {
        this.codeTime--
        this.yzcodetext = this.codeTime + 's后重新获取'
        setTimeout(this.timer, 1000)
      } else {
        this.codeTime = 0
        this.yzcodetext = '获取验证码'
        this.codedisabled = false
      }
    },
    sendsms() {
      this.codeTime = 60
      this.codedisabled = true
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/member/withdraw/send/smscode`,
        method: 'post', //可以省略 不写默认是get
      }).then((res) => {
        this.timer()
      })
      /*    withdrawsendsmscode().then((res) => {
           this.timer();
         }); */
    },
  },
}
</script>

<style lang="less" scoped>
.dialog-wrap {
  .header-item {
    display: flex;
    align-items: center;

    & + .header-item {
      margin-top: 10px;
    }
    & > p {
      font-weight: bold;
      margin-right: 20px;
    }
  }
  .table-wrap {
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .export-button {
      padding: 0;
      margin-left: 10px;
    }
  }
  .footer-wrap {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
</style>
