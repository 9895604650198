<template>
  <el-dialog :visible.sync="dialogVisible"
             width="750px">
    <div class="titles">{{title}}</div>
    <div v-if="detail">
      <div class="title">
        <div class="tag"></div>
        <div class="text">
          总发单数量：<span>{{
            detail.total_send_count
          }}</span>
        </div>
      </div>
      <div class="main">
        <div class="card">
          <div class="card-tit">总发单完成数量</div>
          <div class="card-num">
            {{ detail.total_send_finish_count }}
          </div>
        </div>
        <div class="card">
          <div class="card-tit">发单进行中数量</div>
          <div class="card-num">
            {{
              detail.total_send_running_count
            }}
          </div>
        </div>
        <div class="card">
          <div class="card-tit">总发单取消数量</div>
          <div class="card-num">
            {{ detail.total_send_cancel_count }}
          </div>
        </div>
        <div class="card">
          <div class="card-tit">发单未提交未支付取消数量</div>
          <div class="card-num">
            {{
              detail
                .total_send_cancel_unteacher_unpay_count
            }}
          </div>
        </div>
        <div class="card">
          <div class="card-tit">发单已提交未支付取消数量</div>
          <div class="card-num">
            {{
              detail
                .total_send_cancel_teachered_unpay_count
            }}
          </div>
        </div>
        <div class="card">
          <div class="card-tit">发单已提交已支付取消数量</div>
          <div class="card-num">
            {{
              detail
                .total_send_cancel_teachered_payed_count
            }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="detail"
         style="margin-top:20px">
      <div class="title">
        <div class="tag"></div>
        <div class="text">
          总接单数量：<span>{{
            detail.total_receive_count
          }}</span>
        </div>
      </div>
      <div class="main">
        <div class="card">
          <div class="card-tit">总接单完成数量</div>
          <div class="card-num">
            {{
              detail
                .total_receive_finish_count
            }}
          </div>
        </div>
        <div class="card">
          <div class="card-tit">接单进行中数量</div>
          <div class="card-num">
            {{
              detail
                .total_receive_running_count
            }}
          </div>
        </div>
        <div class="card">
          <div class="card-tit">总接单驳回数量</div>
          <div class="card-num">
            {{
              detail
                .total_receive_refuse_count
            }}
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      detail: {},
      dialogVisible: false,
    }
  },
  created() {},
  methods: {
    open(row, item) {
      let levelContent = ''
      if (row.total_confirm_teacher_level == 1) {
        levelContent = '扬帆'
      } else if (row.total_confirm_teacher_level == 2) {
        levelContent = '远航'
      } else if (row.total_confirm_teacher_level == 3) {
        levelContent = '破浪'
      } else if (row.total_confirm_teacher_level == 4) {
        levelContent = '行舟'
      } else if (row.total_confirm_teacher_level == 5) {
        levelContent = '慎行'
      } else if (row.total_confirm_teacher_level == 6) {
        levelContent = '止步'
      }
      this.title =
        item.company_ref_name +
        '/' +
        item.alias +
        '/' +
        item.phone +
        '\xa0\xa0\xa0\xa0' +
        '确认值' +
        row.total_confirm_teacher_rate +
        '%' +
        '\xa0\xa0\xa0\xa0' +
        '等级：' +
        levelContent
      this.detail = row
      this.dialogVisible = true
    },
  },
}
</script>

<style lang="less" scoped>
.titles {
  position: relative;
  top: -30px;
  font-size: 19px;
  color: #303133;
}
.title {
  display: flex;
  align-items: center;
  .tag {
    width: 4px;
    height: 16px;
    background: #409eff;
    border-radius: 4px 4px 4px 4px;
    margin-right: 4px;
  }
  .text {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #303133;
    line-height: 16px;
    span {
      font-family: DINPro, DINPro;
      font-weight: bold;
      font-size: 18px;
      color: #303133;
      line-height: 16px;
    }
  }
}

.main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .card {
    width: 194px;
    height: 70px;
    background: #f4f5fc;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 14px;
    margin-top: 12px;
    .card-tit {
      ont-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #303133;
      line-height: 16px;
    }
    .card-num {
      font-family: DINPro, DINPro;
      font-weight: bold;
      font-size: 18px;
      color: #303133;
      line-height: 16px;
      margin-top: 6px;
    }
  }
}
</style>