<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>管理</el-breadcrumb-item>
        <el-breadcrumb-item>机构字典</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button
        type="primary"
        @click="opendialogAdd"
        size="small"
        :disabled="!$buttonPermission('institutionaldictionary:create')"
      >新建</el-button>
    </div>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column
          prop="id"
          label="ID"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="字典名称"
        ></el-table-column>
        <el-table-column
          prop="code"
          label="字典编码"
        > </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="操作"
          width="210"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="openDictionaryValueTableDialog(scope.row)"
              :disabled="!$buttonPermission('institutionaldictionary:edit')"
            >
              查看
            </el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="danger"
              :underline="false"
              @click="deleteItem(scope.row)"
              :disabled="!$buttonPermission('institutionaldictionary:delete')"
            >
              删除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
top="5vh"
      title="字典管理"
      :visible.sync="dialogFormVisible"
      width="570px"
    >
      <el-form
        :model="form"
        label-position="right"
        label-width="80px"
        size="small"
        ref="dialogform"
        :rules="rules"
      >
        <el-form-item
          label="字典名称"
          prop="name"
        >
          <el-input
            v-model="form.name"
            autocomplete="off"
            style="width: 455px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="字典编码"
          prop="code"
        >
          <el-input
            v-model="form.code"
            autocomplete="off"
            style="width: 455px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit(submitmethods)"
        >确 定</el-button>
      </div>
    </el-dialog>
    <DictionaryValueTable
      :content="DictionaryValueTableContent"
      :isVisible="DictionaryValueTableDialog"
      @close="DictionaryValueTableDialog = false"
    >
    </DictionaryValueTable>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import DictionaryValueTable from "../../../components/DialogComponents/DictionaryValueTable/DictionaryValueTable.vue";
import {
  createdictionary,
  deletedictionary,
  dictionarylist,
} from "../../../request/api";
export default {
  components: {
    TableTittle,
    DictionaryValueTable,
  },
  data() {
    return {
      TableName: "字典列表",
      form: {},
      dialogFormVisible: false,
      DictionaryValueTableContent: {},
      DictionaryValueTableDialog: false,
      submitmethods: "add",
      tableData: [],
      rules: {
        name: [{ required: true, message: "请输入字典名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入字典编码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    opendialogAdd() {
      this.submitmethods = "add";
      this.form = {};
      this.dialogFormVisible = true;
    },
    opendialogEdit(item) {
      this.submitmethods = "edit";
      this.form = JSON.parse(JSON.stringify(item));
      this.$nextTick(() => {
        this.dialogFormVisible = true;
      });
    },
    getList() {
      dictionarylist().then((res) => {
        this.tableData = res.data.data.list || [];
      });
    },
    submit(method) {
      this.$refs["dialogform"].validate((valid) => {
        if (valid) {
          let query = this.form;
          switch (method) {
            case "add":
              createdictionary(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false;
                  this.getList(this.query);
                });
              });
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteItem(item) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            code: item.code,
          };
          deletedictionary(query).then((res) => {
            this.$notify.success({
              title: "提示",
              message: "删除成功",
            });
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    openDictionaryValueTableDialog(item) {
      this.DictionaryValueTableContent = JSON.parse(JSON.stringify(item));
      this.DictionaryValueTableDialog = !this.DictionaryValueTableDialog;
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.card-header {
  display: flex;
  justify-content: space-between;
  .el-button {
    height: 35px;
    line-height: 10px;
  }
}
.block {
  margin-top: 20px;
  margin-bottom: 14px;
  float: right;
}
</style>