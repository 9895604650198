<template>
  <div>
    <el-dialog
      top="5vh"
      title="快速签单"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="70%"
    >
      <el-tabs
        v-model="active"
        @tab-click="tabChange"
      >
        <el-tab-pane
          label="选择老师"
          name="teachers"
        >
          <div
            class="search-wrap"
            v-if="step==1"
          >
            <img
              src="@/assets/images/signquick.png"
              alt=""
              srcset=""
              class="searchimg"
            >
            <div>
              <el-input
                placeholder="输入老师姓名或手机号"
                v-model="query.keyword"
              >
                <el-button
                  type="primary"
                  @click="handleToSearch(true)"
                  slot="append"
                >搜索老师</el-button>
              </el-input>
            </div>
          </div>
          <div
            class="table-wrap"
            v-else-if="step==2"
          >
            <el-input
              placeholder="输入老师姓名或手机号"
              v-model="query.keyword"
              style="width: 320px;margin-bottom: 16px;"
            >
              <el-button
                slot="append"
                type="primary"
                @click="handleToSearch(false)"
              >搜索老师</el-button>
            </el-input>
            <el-table
              :data="result"
              row-key="id"
              :header-cell-style="{
            backgroundColor: '#F2F6FC',
          }"
              @expand-change="expandChange"
              v-loading="loading"
              border
            >
              <!--  内嵌表格 -->
              <el-table-column type="expand">
                <template slot-scope="scope">
                  <div class="expand-wrap">
                    <el-table
                      :data="scope.row.records?scope.row.records:[]"
                      :border="false"
                      :header-cell-style="{
                  backgroundColor: '#f9f9f9',
               }"
                    >
                      <el-table-column
                        label="日期"
                        align="center"
                        width="150"
                      >
                        <template slot-scope="scope">
                          {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):"无"}}
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="记录人"
                        align="center"
                        width="100"
                      >
                        <template slot-scope="scope">
                          {{ scope.row.manage_creator?scope.row.manage_creator.nickname:'无' }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="类别"
                        align="center"
                        width="80"
                      >
                        <template slot-scope="scope">
                          <el-tag
                            size="small"
                            v-if="scope.row.type == 1"
                            type="success"
                          >分配</el-tag>
                          <el-tag
                            size="small"
                            v-if="scope.row.type == 2"
                          >备注</el-tag>
                          <el-tag
                            size="small"
                            v-if="scope.row.type == 3"
                          >修改</el-tag>
                          <el-tag
                            size="small"
                            v-if="scope.row.type == 4"
                            type="warning"
                          >跟进</el-tag>
                          <el-tag
                            size="small"
                            v-if="scope.row.type == 5"
                          >签单</el-tag>
                          <el-tag
                            size="small"
                            v-if="scope.row.type == 6"
                            type="warning"
                          >申请</el-tag>
                          <el-tag
                            size="small"
                            v-if="scope.row.type == 7"
                            type="success"
                          >沟通</el-tag>
                          <el-tag
                            size="small"
                            v-if="scope.row.type == 8"
                          >记录</el-tag>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="沟通记录"
                        align="left"
                        show-overflow-tooltip
                        prop="record"
                      >
                      </el-table-column>
                    </el-table>
                  </div>
                </template>

              </el-table-column>
              <!--  内嵌表格 -->

              <el-table-column
                label="沟通记录"
                align="center"
                width="100"
              >
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="handleToRecord(scope.row)"
                  >所有记录</el-link>
                </template>
              </el-table-column>
              <el-table-column
                prop="id"
                label="ID"
                align="center"
                width="80"
              >
              </el-table-column>
              <el-table-column
                prop="realname"
                label="姓名"
                align="center"
                width="100"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="realname"
                label="性别"
                align="center"
                width="50"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-show="scope.row.gender==1">男</span>
                  <span v-show="scope.row.gender==2">女</span>
                  <span v-show="scope.row.gender==0">未知</span>
                </template>
              </el-table-column>
              <el-table-column
                label="年级"
                align="center"
                width="100"
              >
                <template slot-scope="scope">
                  {{scope.row.recruit_year}}年
                </template>
              </el-table-column>
              <!--      <el-table-column
          label="初试匹配度"
          align="center"
          width="120"
        >
          <template>
            <el-link
              type="primary"
              :underline="false"
            >部分匹配</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="复试匹配度"
          align="center"
          width="120"
        >
          <template>
            <el-link
              type="primary"
              :underline="false"
            >未知</el-link>
          </template>
        </el-table-column> -->
              <el-table-column
                label="确认状态"
                align="center"
                width="80"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    size="small"
                    v-if="scope.row.confirm_status == 1"
                    type="success"
                  >已确认</el-tag>
                  <el-tag
                    size="small"
                    v-if="scope.row.confirm_status == 0"
                  >未确认</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="签约状态"
                align="center"
                width="80"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    size="small"
                    v-if="scope.row.contract_status == 1"
                    type="success"
                  >已签约</el-tag>
                  <el-tag
                    size="small"
                    v-if="scope.row.contract_status == 0"
                    type="warning"
                  >未签约</el-tag>
                  <el-tag
                    size="small"
                    v-if="scope.row.contract_status == 2"
                    type="danger"
                  >已过期</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="本单课时费"
                align="center "
                width="110"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{scope.row.sign_order_lesson_hour_amount?scope.row.sign_order_lesson_hour_amount:0}}元/小时
                </template>
              </el-table-column>
              <el-table-column
                label="录取信息"
                align="left"
                width="350"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{scope.row.recruit_college_name}} | {{scope.row.recruit_academy_name}} | ({{scope.row.recruit_major_code}}){{scope.row.recruit_major_name}} {{ scope.row.recruit_direction_code&&scope.row.recruit_direction_name?` | (${scope.row.recruit_direction_code})${scope.row.recruit_direction_name}`:'' }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="初试科目"
                align="left"
                width="350"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ formateSubjectsOther(scope.row) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="初试总分"
                align="center "
                width="80"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{scope.row.initial_test_score}}分
                </template>
              </el-table-column>
              <el-table-column
                label="科目分数"
                align="center"
                width="260"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ formateScore(scope.row.test_subject_scores) }}
                </template>
              </el-table-column>
              <el-table-column
                label="参考书目"
                align="center"
                width="260"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ formateReference_book(scope.row) }}
                </template>
              </el-table-column>
              <el-table-column
                label="复试科目"
                align="center"
                width="300"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.second_test_subject_names ||'无'}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="录取类型"
                align="center"
                width="80"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.recruit_type==1">一志愿</span>
                  <span v-if="scope.row.recruit_type==2">调剂</span>

                </template>
              </el-table-column>

              <el-table-column
                label="签单状态"
                align="center"
                width="80"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    size="small"
                    v-if="scope.row.sign_order_confirm_status  == -1"
                    type="warning"
                  >未签单</el-tag>
                  <el-tag
                    size="small"
                    v-if="scope.row.sign_order_confirm_status  == 0"
                    type="warning"
                  >待确认</el-tag>
                  <el-tag
                    size="small"
                    v-if="scope.row.sign_order_confirm_status  == 1"
                    type="success"
                  >已确认</el-tag>
                  <el-tag
                    size="small"
                    v-if="scope.row.sign_order_confirm_status  == 2"
                    type="danger"
                  >已驳回</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="签约课时费"
                align="center "
                width="110"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{scope.row.fee_amount?scope.row.fee_amount:0}}元/小时
                </template>
              </el-table-column>
              <el-table-column
                label="平均课时费"
                align="center "
                width="110"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{scope.row.teacher_average_lesson_hour_amount?scope.row.teacher_average_lesson_hour_amount:0}}元/小时
                </template>
              </el-table-column>
              <el-table-column
                label="综合评分"
                width="80"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.teacher_average_student_apprise_score?(scope.row.teacher_average_student_apprise_score).toFixed(1)+'分':'暂无' }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="微信添加"
                width="200"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ formateWechatManager(scope.row) }}
                </template>
              </el-table-column>
              <el-table-column
                label="首次签约人"
                width="100"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ formateFirstManager(scope.row) }}
                </template>
              </el-table-column>
              <el-table-column
                label="签约有效期"
                align="center"
                width="150"
              >
                <template slot-scope="scope">
                  {{scope.row.contract_end_time?$moment(scope.row.contract_end_time).format("YYYY-MM-DD HH:mm"):"无"}}
                </template>
              </el-table-column>
              <el-table-column
                label="更新时间"
                align="center"
                width="150"
              >
                <template slot-scope="scope">
                  {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="150"
              >
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="handleToLog(scope.row)"
                  >签单</el-link>
                  <el-link
                    type="primary"
                    :underline="false"
                    v-if="scope.row.confirm_status==0"
                    @click="handleToEdit(scope.row.id)"
                  >确认</el-link>
                  <el-link
                    type="primary"
                    v-if="scope.row.confirm_status==1"
                    @click="handleToSign(scope.row.id)"
                    :underline="false"
                  >签约</el-link>
                </template>
              </el-table-column>
            </el-table>
            <div style="margin-top: 16px">
              <div class="block">
                <div style="margin-right:16px;">共{{otherCount}}条<span v-if="Number(otherCount)>100">,涉及隐私仅显示前100条</span></div>

                <el-pagination
                  background
                  layout="total, prev, pager, next, sizes, jumper"
                  :current-page="Number(query.pageIndex)"
                  :page-sizes="[10, 20, 50, 100, 500]"
                  :page-size="Number(query.pageSize)"
                  :total="Number(pageTotal)"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                ></el-pagination>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="历史签单"
          name="history"
        >
          <el-table
            :data="history_result"
            row-key="id"
            :header-cell-style="{
            backgroundColor: '#F2F6FC',
          }"
            @expand-change="expandChange"
            v-loading="loading"
            v-if="history_result.length"
            border
          >
            <!--  内嵌表格 -->
            <el-table-column type="expand">
              <template slot-scope="scope">
                <div class="expand-wrap">
                  <el-table
                    :data="scope.row.records?scope.row.records:[]"
                    :border="false"
                    :header-cell-style="{
                  backgroundColor: '#f9f9f9',
               }"
                  >
                    <el-table-column
                      label="日期"
                      align="center"
                      width="150"
                    >
                      <template slot-scope="scope">
                        {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):"无"}}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="记录人"
                      align="center"
                      width="100"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.manage_creator?scope.row.manage_creator.nickname:'无' }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="类别"
                      align="center"
                      width="80"
                    >
                      <template slot-scope="scope">
                        <el-tag
                          size="small"
                          v-if="scope.row.type == 1"
                          type="success"
                        >分配</el-tag>
                        <el-tag
                          size="small"
                          v-if="scope.row.type == 2"
                        >备注</el-tag>
                        <el-tag
                          size="small"
                          v-if="scope.row.type == 3"
                        >修改</el-tag>
                        <el-tag
                          size="small"
                          v-if="scope.row.type == 4"
                          type="warning"
                        >跟进</el-tag>
                        <el-tag
                          size="small"
                          v-if="scope.row.type == 5"
                        >签单</el-tag>
                        <el-tag
                          size="small"
                          v-if="scope.row.type == 6"
                          type="warning"
                        >申请</el-tag>
                        <el-tag
                          size="small"
                          v-if="scope.row.type == 7"
                          type="success"
                        >沟通</el-tag>
                        <el-tag
                          size="small"
                          v-if="scope.row.type == 8"
                        >记录</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="沟通记录"
                      align="left"
                      show-overflow-tooltip
                      prop="record"
                    >
                    </el-table-column>
                  </el-table>
                </div>
              </template>

            </el-table-column>
            <!--  内嵌表格 -->

            <el-table-column
              label="沟通记录"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="handleToRecord(scope.row)"
                >所有记录</el-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="id"
              label="ID"
              align="center"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="realname"
              label="姓名"
              align="center"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="realname"
              label="性别"
              align="center"
              width="50"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-show="scope.row.gender==1">男</span>
                <span v-show="scope.row.gender==2">女</span>
                <span v-show="scope.row.gender==0">未知</span>
              </template>
            </el-table-column>
            <el-table-column
              label="年级"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                {{scope.row.recruit_year}}年
              </template>
            </el-table-column>
            <!--      <el-table-column
          label="初试匹配度"
          align="center"
          width="120"
        >
          <template>
            <el-link
              type="primary"
              :underline="false"
            >部分匹配</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="复试匹配度"
          align="center"
          width="120"
        >
          <template>
            <el-link
              type="primary"
              :underline="false"
            >未知</el-link>
          </template>
        </el-table-column> -->
            <el-table-column
              label="确认状态"
              align="center"
              width="80"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag
                  size="small"
                  v-if="scope.row.confirm_status == 1"
                  type="success"
                >已确认</el-tag>
                <el-tag
                  size="small"
                  v-if="scope.row.confirm_status == 0"
                >未确认</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              label="签约状态"
              align="center"
              width="80"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag
                  size="small"
                  v-if="scope.row.contract_status == 1"
                  type="success"
                >已签约</el-tag>
                <el-tag
                  size="small"
                  v-if="scope.row.contract_status == 0"
                  type="warning"
                >未签约</el-tag>
                <el-tag
                  size="small"
                  v-if="scope.row.contract_status == 2"
                  type="danger"
                >已过期</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              label="本单课时费"
              align="center "
              width="110"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{scope.row.sign_order_lesson_hour_amount?scope.row.sign_order_lesson_hour_amount:0}}元/小时
              </template>
            </el-table-column>
            <el-table-column
              label="录取信息"
              align="left"
              width="350"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{scope.row.recruit_college_name}} | {{scope.row.recruit_academy_name}} | ({{scope.row.recruit_major_code}}){{scope.row.recruit_major_name}} {{ scope.row.recruit_direction_code&&scope.row.recruit_direction_name?` | (${scope.row.recruit_direction_code})${scope.row.recruit_direction_name}`:'' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="初试科目"
              align="left"
              width="350"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{ formateSubjectsOther(scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="初试总分"
              align="center "
              width="80"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{scope.row.initial_test_score}}分
              </template>
            </el-table-column>
            <el-table-column
              label="科目分数"
              align="center"
              width="260"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ formateScore(scope.row.test_subject_scores) }}
              </template>
            </el-table-column>
            <el-table-column
              label="参考书目"
              align="center"
              width="260"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ formateReference_book(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column
              label="复试科目"
              align="center"
              width="300"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{ scope.row.second_test_subject_names ||'无'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="录取类型"
              align="center"
              width="80"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.recruit_type==1">一志愿</span>
                <span v-if="scope.row.recruit_type==2">调剂</span>

              </template>
            </el-table-column>

            <el-table-column
              label="签单状态"
              align="center"
              width="80"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag
                  size="small"
                  v-if="scope.row.sign_order_confirm_status  == -1"
                  type="warning"
                >未签单</el-tag>
                <el-tag
                  size="small"
                  v-if="scope.row.sign_order_confirm_status  == 0"
                  type="warning"
                >待确认</el-tag>
                <el-tag
                  size="small"
                  v-if="scope.row.sign_order_confirm_status  == 1"
                  type="success"
                >已确认</el-tag>
                <el-tag
                  size="small"
                  v-if="scope.row.sign_order_confirm_status  == 2"
                  type="danger"
                >已驳回</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              label="签约课时费"
              align="center "
              width="110"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{scope.row.fee_amount?scope.row.fee_amount:0}}元/小时
              </template>
            </el-table-column>
            <el-table-column
              label="平均课时费"
              align="center "
              width="110"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{scope.row.teacher_average_lesson_hour_amount?scope.row.teacher_average_lesson_hour_amount:0}}元/小时
              </template>
            </el-table-column>
            <el-table-column
              label="综合评分"
              width="80"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{ scope.row.teacher_average_student_apprise_score?(scope.row.teacher_average_student_apprise_score).toFixed(1)+'分':'暂无' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="微信添加"
              width="200"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ formateWechatManager(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column
              label="首次签约人"
              width="100"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ formateFirstManager(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column
              label="签约有效期"
              align="center"
              width="150"
            >
              <template slot-scope="scope">
                {{scope.row.contract_end_time?$moment(scope.row.contract_end_time).format("YYYY-MM-DD HH:mm"):"无"}}
              </template>
            </el-table-column>
            <el-table-column
              label="更新时间"
              align="center"
              width="150"
            >
              <template slot-scope="scope">
                {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              fixed="right"
              align="center"
              width="150"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="handleToLog(scope.row)"
                >签单</el-link>
                <el-link
                  type="primary"
                  :underline="false"
                  v-if="scope.row.confirm_status==0"
                  @click="handleToEdit(scope.row.id)"
                >确认</el-link>
                <el-link
                  type="primary"
                  v-if="scope.row.confirm_status==1"
                  @click="handleToSign(scope.row.id)"
                  :underline="false"
                >签约</el-link>
              </template>
            </el-table-column>
          </el-table>
          <div
            class="empty"
            v-else
          >
            <img
              src="@/assets/images/history-empty.png"
              alt=""
              srcset=""
            >
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <Log
      :price="price"
      :isVisible="logDialog"
      :id="logId"
      :order_id="Number(this.$route.params.id)"
      @close="logDialog=false"
      @submit="fetchList();fetchHistoryList()"
      :isSign="true"
    ></Log>
    <ConfirmSenior
      :isVisible="editDialog"
      :id="editId"
      @close="editDialog=false"
      @submit="fetchList();fetchHistoryList()"
    ></ConfirmSenior>
    <Record
      :teacher_id="teacher_id"
      :isVisible="recordDialog"
      @submit="fetchList();fetchHistoryList()"
      @close="recordDialog=false"
    ></Record>
    <Sign
      :isVisible="signDialog"
      :id="signId"
      @close="signDialog=false"
      @submit="fetchList();fetchHistoryList()"
    ></Sign>
  </div>
</template>
<script>
import {
  teacherlist,
  taRecordList
} from "@/request/api";
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'
import ConfirmSenior from '@/components/DialogComponents/TeacherResources/ConfirmSenior.vue';
import Sign from '@/components/DialogComponents/TeacherResources/Sign.vue'
import Log from '@/components/DialogComponents/TeacherResources/Log.vue';


export default {
  components: {
    Record,
    ConfirmSenior,
    Sign,
    Log
  },
  props: {
    price:{
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      active: 'teachers',
      search: '',
      step: 1,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      result: [],
      teacher_id: null,
      recordDialog: false,
      loading: false,
      logDialog: false,
      logId: null,
      editDialog: false,
      editId: null,
      signDialog: false,
      signId: null,
      history_result: [],
      otherCount: 0,
    };

  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.fetchList();
    },
    fetchList(isReset = false) {
      this.loading = true
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query, {
        handle_teacher_allocaton_order_id: this.$route.params.id
      });
      teacherlist(params).then((response) => {
        let res = response.data
        this.result = res.data.list || [];
        if (res.data.count > 100) {
          this.otherCount = res.data.count
          this.pageTotal = 100
        } else {
          this.otherCount = res.data.count
          this.pageTotal = res.data.count || 0
        }
        this.loading = false
      });
    },
    fetchHistoryList() {
      this.loading = true
      const query = {
        handle_teacher_allocaton_order_id: this.$route.params.id,
        isPaging: 0,
        sign_order_confirm_statuses: '0,1,2'
      }
      teacherlist(query).then((response) => {
        let res = response.data
        this.history_result = res.data.list || [];
        this.loading = false
      });
    },

    handleToClose() {
      this.$emit("close");
      this.step = 1
    },
    handleToSearch(isNext) {
      if (isNext) {
        this.step += 1
      }
      this.fetchList(true)
    },
    handleToRecord(row) {
      this.teacher_id = row.id
      this.recordDialog = true
    },
    expandChange(row) {
      let params = {
        teacher_allocation_order_id: this.$route.params.id,
        teacher_id: row.id,
        isPaging: 0,
      }
      taRecordList(params).then((response) => {
        let res = response.data
        this.$set(row, 'records', res.data.list)
      });
    },
    formateSubjectsOther(row) {
      let arr = row.test_subject_names ? row.test_subject_names.split(',') : []
      let code_arr = row.test_subject_codes ? row.test_subject_codes.split(',') : []
      return arr.length ? arr.map((v, i) => {
        return `(${code_arr[i]})${v}`
      }).join('+') : '无'
    },
    formateScore(score) {
      if (score) {
        let str = score.split(',')
        return str.map(v => {
          return v + '分'
        }).join(' | ')
      } else {
        return '无'
      }
    },
    formateWechatManager(row) {
      if (row.wechat_add_manage_ids) {
        let id = row.wechat_add_manage_ids
        let idList =  id.split(',')
        let nickname = ''
        idList.forEach(item=>{
          this.$store.state.staffOptions.forEach(v => {
             if(v.id == item){
              nickname=nickname+' | '+ v.nickname
             }
          })
        })
       return nickname.substring(2,nickname.length)
       
      } else {
        return '无'
      }
      // if (row.wechat_add_manage_ids) {
      //   let id = row.wechat_add_manage_ids
      //   return this.$store.state.staffOptions.find(v => {
      //     return v.id == id
      //   }).nickname
      // } else {
      //   return '无'
      // }

    },
    formateFirstManager(row) {
      if (row.first_contract_manage_id) {
        let id = row.first_contract_manage_id
        return this.$store.state.staffOptions.find(v => {
          return v.id == id
        }).nickname
      } else {
        return '无'
      }
    },
    formateSecondSubjects(row) {
      let arr = row.second_test_subject_names ? row.second_test_subject_names.split(',') : []
      let code_arr = row.second_test_subject_codes ? row.second_test_subject_codes.split(',') : []
      return arr.length ? arr.map((v, i) => {
        return `(${code_arr[i]})${v}`
      }).join('+') : '无'
    },
    formateReference_book(row) {
      let arr = row.reference_book_names ? row.reference_book_names.split(',') : []
      let infos = row.reference_book_extra_infos ? row.reference_book_extra_infos.split(',') : []
      return arr.length ? arr.map((v, i) => {
        return `${v}，${infos[i]}`
      }).join(',') : '无'
    },
    handleToLog(row) {
      this.logId = row.id
      this.logDialog = true
    },
    handleToEdit(id) {
      this.editId = id
      this.editDialog = true
    },
    handleToSign(id) {
      this.signId = id
      this.signDialog = true
    },
    tabChange() {
      if (this.active == 'history') {
        this.fetchHistoryList()
      }
    }
  },
};
    </script>
    <style lang="less" scoped>
/deep/.el-dialog__body {
  padding-top: 0px;
}
.search-wrap {
  height: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .searchimg {
    width: 203px;
    height: 159px;
    margin: 16px 0px;
  }
}
.empty {
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 150px;
    height: 158px;
  }
}
/deep/.el-input-group__append {
  background: #409eff;
  span {
    color: #fff;
  }
}
</style>