<template>
  <div>
    <el-dialog  width="750px" title="留言新增" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="emitClose">
      <el-form :model="dataForm" label-position="right" ref="formRules" :rules="rules" label-width="100px">
        <el-form-item label="留言：" prop="title">
          <el-input type="textarea" placeholder="请输入"  v-model="dataForm.title"></el-input>
        </el-form-item>

       <el-form-item label="类别" prop="type">
             <el-select  v-model="dataForm.type" placeholder="请选择类别" 
                  style="width: 100%;">
                  <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.value">
                  </el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="发布状态：">
          <el-switch v-model="dataForm.status" :active-value="1" :inactive-value="0">
          </el-switch>
        </el-form-item>
         <el-form-item label="排序值：" prop="sort">
          <el-input v-model="dataForm.sort" type="Number"></el-input>
      </el-form-item>
      </el-form>
      
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary" @click="submit('formRules')">确 定</el-button>
      </div>

    </el-dialog>

  </div>
</template>

<script>

import { crmUrl } from "@/utils/helper.js";
export default {

  props: {
    isVisible: {
      type: Boolean,
      default: false,
      status:0
    },
    form:{}

  },
  data() {
    return {
      dataForm: {
        sort:0,
        status:0,
        content:"",
        type:3
      },
      typeList:[
        {
          label:'发单人留言',
          value:3
        },
        {
          label:'接单人留言',
          value:4
        }
      ],
      dialogFormVisible: this.isVisible,
      rules: {
        title: [{ required: true, message: "请输入留言", trigger: "blur" }],

      },
    };
  },
  mounted() {

  },
  onload(){
  
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;

    },

  },
  methods: {
    initDtaForm(e) {
      // console.log("ee",e)
      this.dataForm.content = e
    },
    emitClose() {
      this.dataForm = {
        sort:0,
        status:0,
        type:3,
        content:"",
      }
      this.$emit("close");
    },

    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.dataForm)
          let str = crmUrl()
   
          this.$axios({
            url: `${str}/api/v1/sharePlatform/helpCenterArticle`,
            method: 'post',//可以省略 不写默认是get
            data: params,
            headers: { // 设置请求头
              Authorization: localStorage.getItem('admin_token')
            }
          }).then(response => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('提交成功')
              this.$emit('submit')
              this.emitClose()
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.input-new-tag {
  width: 90px;
}

.el-tag {
  line-height: 32px;
  height: 32px;
  margin: 0px 5px;
}

.tips {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 16px;
}
</style>