<template>
  <div class="content">
    <el-dialog append-to-body top="5vh" :visible.sync="dialogFormVisible" @close="handleToClose" width="980px">
      <div class="wrap" v-if="Object.keys(content).length">
        <TableTittle :title="'个人信息'"></TableTittle>
        <el-descriptions direction="horizontal" :column="1" border :labelStyle="mylabel" style="margin-bottom: 16px;">
          <el-descriptions-item label="姓名">{{
            content.realname
          }}</el-descriptions-item>
          <el-descriptions-item label="性别">
            <el-tag size="mini">{{
              renderGender(content.gender)
            }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="手机号">
            <div class="flex">
              <div> {{ content.mobile }}</div>
              <el-button v-if="content.mobile" size="small" :data-clipboard-text="clipboardText" class="mobile"
                @click="copy(content.mobile, 'mobile')">复制</el-button>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="微信号">
            <div class="flex">
              <div> {{ content.wechat}}</div>
              <el-button v-if="content.wechat" size="small" :data-clipboard-text="clipboardText" class="wechat"
                @click="copy(content.wechat, 'wechat')">复制</el-button>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="QQ号">
            <div class="flex">
              <div> {{  content.qq}}</div>
              <el-button v-if="content.qq" size="small" :data-clipboard-text="clipboardText" class="qq"
                @click="copy(content.qq, 'qq')">复制</el-button>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="课时费">{{
            content.teacher_average_lesson_hour_amount ?
            (content.teacher_average_lesson_hour_amount).toFixed(2) + '元/小时' : '暂无'
          }}</el-descriptions-item>
          <el-descriptions-item
            label="综合评分">{{ content.teacher_average_student_apprise_score ? (content.teacher_average_student_apprise_score).toFixed(2) + '分' : '暂无'
            }}</el-descriptions-item>
          <el-descriptions-item label="个人优势">{{
            content.additional || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="内部备注">{{
            content.contract_remark || ""
          }}</el-descriptions-item>

        </el-descriptions>
        <TableTittle :title="'录取信息'"></TableTittle>
        <el-descriptions direction="horizontal" :column="1" border :labelStyle="mylabel">
          <el-descriptions-item label="年级">{{
            content.recruit_year
          }}年</el-descriptions-item>
          <el-descriptions-item label="录取院校">{{
            content.recruit_college_name
          }}</el-descriptions-item>
          <el-descriptions-item label="学院名称">{{
            content.recruit_academy_name
          }}</el-descriptions-item>
          <el-descriptions-item label="专业名称">({{
            content.recruit_major_code
          }}){{
  content.recruit_major_name
}}</el-descriptions-item>
          <el-descriptions-item label="研究方向">({{
            content.recruit_direction_code
          }}){{
  content.recruit_direction_name
}}</el-descriptions-item>
          <el-descriptions-item label="初试科目">{{ formateSubjects(content) }}</el-descriptions-item>
          <el-descriptions-item label="初试总分">{{
            content.initial_test_score
          }}分</el-descriptions-item>
          <el-descriptions-item label="科目分数">{{
            formateScore(content.test_subject_scores)
          }}</el-descriptions-item>
          <el-descriptions-item label="录取类型">{{
            renderRecruitType(content.recruit_type)
          }}</el-descriptions-item>
          <el-descriptions-item label="复试科目">{{ content.second_test_subject_names }}</el-descriptions-item>
          <el-descriptions-item label="辅导书籍">{{ formateBooks(content) }}</el-descriptions-item>
          <el-descriptions-item label="录取参考书">
            <div v-if="content.ref_recruit_reference_bookses">
                {{content.ref_recruit_reference_bookses}}
            </div>
            <div v-else>无</div>
          </el-descriptions-item>
          <el-descriptions-item label="录取证明" v-if="content.recruit_certify_img">
            <el-image :src="content.recruit_certify_img" class="img" :preview-src-list="[content.recruit_certify_img]">
            </el-image>

          </el-descriptions-item>
          <el-descriptions-item label="成绩证明" v-if="content.recruit_score_img">
            <el-image :src="content.recruit_score_img" class="img" :preview-src-list="[content.recruit_score_img]">
            </el-image>

          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleToClose">取 消</el-button>
        <el-button type="primary" @click="handleToClose">确 定
        </el-button>
      </div>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :url-list="tableImg" :on-close="closeViewer" />
  </div>
</template>
  
<script>
import { teacherOne } from "@/request/api";
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import Clipboard from 'clipboard';

export default {
  components: {
    TableTittle,
    ElImageViewer
  },
  props: {

    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
    
      default: null,
    },
  },
  data() {
    return {
      clipboardText: '',
      dialogFormVisible: this.isVisible,
      content: {},
      mylabel: {
        width: "120px",
      },
      showViewer: false,
      tableImg: [],

    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  created() {
    
  },
  computed: {

  },
  methods: {
    copy(text, type) {
      this.clipboardText = text

      var clipboard = new Clipboard(`.${type}`)

      clipboard.on('success', e => {
        this.$message({
          message: '复制成功！',
          type: 'success'
        });
        // 释放内存  
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制  
        this.$message({
          message: '该浏览器不支持自动复制',
          type: 'error'
        });
        // 释放内存  
        clipboard.destroy()
      })
    },

    fetchDetail() {
      teacherOne({ id: this.id }).then((response) => {
        let res = response.data
        this.content = res.data
      });
    },
    renderProvideService(type) {
      const services = {
        1: "资料-整理备考资料",
        2: "经验-分享上岸经验",
        3: "小讲-录制语音课程",
        4: "辅导-辅导1v1学员",
        5: "研讯-汇总专业数据",
      };
      return services[type] || "";
    },
    renderRecruitType(type) {
      const recruitTypes = {
        1: "第一志愿上岸",
        2: "调剂上岸",
      };
      return recruitTypes[type] || "";
    },
    renderGender(type) {
      const genders = {
        0: "未知",
        1: "学长",
        2: "学姐",
      };
      return genders[type] || "";
    },
    handleToClose() {
      this.$emit("close");
    },
    formateSubjects(row) {
      let arr = row.test_subject_names ? row.test_subject_names.split(',') : []
      let code_arr = row.test_subject_codes ? row.test_subject_codes.split(',') : []
      return arr.length ? arr.map((v, i) => {
        return `(${code_arr[i]})${v}`
      }).join('+') : '无'
    },
    formateSecondSubjects(row) {
      let arr = row.second_test_subject_codes ? row.second_test_subject_codes.split(',') : []
      let code_arr = row.second_test_subject_names ? row.second_test_subject_names.split(',') : []
      return arr.length ? arr.map((v, i) => {
        return `(${v})${code_arr[i]}`
      }).join('+') : '无'
    },
    formateScore(score) {
      if (score) {
        let str = score.split(',')
        return str.map(v => {
          return v + '分'
        }).join(' | ')
      } else {
        return '无'
      }
    },
    formateBooks(row) {
      let arr = row.reference_book_extra_infos ? row.reference_book_extra_infos.split(',') : []
      let code_arr = row.reference_book_names ? row.reference_book_names.split(',') : []
      return arr.length ? arr.map((v, i) => {
        return `${code_arr[i]},${v}`
      }).join('，') : '无'
    },
    openViewer(url) {
      this.tableImg = [url];
      this.showViewer = true;
    },

  }
};
</script>
  
<style lang="less" scoped>
.content {
  .img {
    width: 140px;
    height: 140px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}
</style>
  