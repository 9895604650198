var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('el-dialog',{attrs:{"top":"5vh","title":_vm.title,"visible":_vm.dialogFormVisible,"close-on-click-modal":false,"width":"500px"},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":function($event){_vm.dialogFormVisible = false}}},[_c('el-table',{attrs:{"data":_vm.result,"width":"150rpx","header-cell-style":{
        backgroundColor: '#F2F6FC',
        color: '#909399',
       
              fontSize: '14px'
              ,
              },"cell-style":{ fontSize: '13px' },"border":""}},[_c('el-table-column',{attrs:{"fixed":"left","label":"用户名","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.company_user_obj .alias)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"order_no","label":"手机号","align":"center","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.company_user_obj .phone)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"order_no","label":"查看时间","align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.company_user_obj ? _vm.$moment(scope.row.read_time).format("YYYY-MM-DD HH:mm") : "无")+" ")]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }