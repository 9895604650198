<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>CRM管理</el-breadcrumb-item>
        <el-breadcrumb-item>机构字典</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 24px">
      <TittleText :title="titleArr[0]"></TittleText>
      <AccountInfo></AccountInfo>
    </el-card>
    <el-card style="margin-top: 24px">
      <TittleText title="开关"></TittleText>
      <div class="switch">
        <div>共享平台审核隐藏开关：</div>
        <div @click="switchUp">
          <el-switch :value="value"
                     active-color="#13ce66"
                     inactive-color="#ff4949">
          </el-switch>
        </div>
      </div>

      <div class="switch"
           style="margin-top: 10px">
        <div>余额支付减免功能开关(当前减免金额为{{ sharePrice }}元)：</div>
        <div @click="switchUpShare">
          <el-switch :value="shareValue"
                     active-color="#13ce66"
                     inactive-color="#ff4949">
          </el-switch>
        </div>
      </div>
      <div class="switchTime">
        <div>共享平台一口价间隔时间设置：</div>
        <div style="color:#409EFF;cursor: pointer;"
             @click="edit(item=1)">编辑</div>
        <el-tag type="success"
                style="marginLeft:10px;marginTop:-5px">{{priceTime}}</el-tag>
      </div>
      <div class="switchTime">
        <div>共享开票成功选择钉钉通知管理员：</div>
        <div style="color:#409EFF;cursor: pointer;"
             @click="edit(item=2)">编辑</div>
        <el-tag type="success"
                style="marginLeft:10px;marginTop:-5px">{{mangeName}}</el-tag>

      </div>

    </el-card>
    <el-dialog :title="title"
               :visible.sync="delayedVisible"
               :close-on-click-modal="false"
               width="404px"
               @close="emitClose">
      <div v-if="title=='一口价系统间隔时间'">
        <div class="timef">
          <el-select v-model="dayTime"
                     filterable
                     size="small"
                     placeholder="请选择">
            <el-option v-for="item in optionsDays"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
          <span>天</span>
          <el-select v-model="hourTime"
                     filterable
                     size="small"
                     placeholder="请选择">
            <el-option v-for="item in optionsHour"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
          <span>小时</span>
          <el-select v-model="mintueTime"
                     filterable
                     size="small"
                     placeholder="请选择">
            <el-option v-for="item in optionsMinuted"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
          <span>分钟</span>
        </div>
      </div>
      <div v-else>
        <el-select v-model="learn_manager"
                   placeholder="请选择通知人"
                   clearable>
          <el-option v-for="item in $store.state.staffOptions"
                     :key="item.id"
                     :label="item.nickname"
                     :value="item.id">
          </el-option>
        </el-select>
      </div>

      <div class="footer">
        <el-button @click="canclePrice()">取消</el-button>
        <el-button type="primary"
                   @click="confirmPrice">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { configureList, configureEdit } from '@/request/api'
import TittleText from '../../../components/TableTittle/TableTittle.vue'
import AccountInfo from '../../../components/SettingComponents/AccountInfo/AccountInfo.vue'
export default {
  components: {
    TittleText,
    AccountInfo,
  },
  data() {
    return {
      title: '一口价系统间隔时间',
      learn_manager: null,
      delayedVisible: false,
      value: false,
      shareValue: false,
      priceValue: false,
      sharePrice: '',
      id: null,
      titleArr: ['账户信息'],
      form: {
        taxRate: 0,
      },
      optionsDays: Array.from({ length: 366 }, (v, k) => ({
        value: k,
        label: k,
      })),
      dayTime: '0',
      optionsHour: Array.from({ length: 25 }, (v, k) => ({
        value: k,
        label: k,
      })),
      hourTime: '0',
      optionsMinuted: Array.from({ length: 61 }, (v, k) => ({
        value: k,
        label: k,
      })),
      mintueTime: '5',
      timestamp: 0,
      priceTime: 0,
      mangeName: '',
    }
  },
  created() {
    this.$store.dispatch('getStaffOptions')
    setTimeout(() => {
      this.getConfigureList()
    }, 500)
  },
  methods: {
    edit(item) {
      if (item == 1) {
        this.title = '一口价系统间隔时间'
      } else {
        this.title = '选择通知管理员'
      }
      this.delayedVisible = true
    },
    emitClose() {
      this.delayedVisible = false
    },
    canclePrice() {
      this.delayedVisible = false
    },
    confirmPrice() {
      if (this.title == '一口价系统间隔时间') {
        this.timestamp =
          this.dayTime * 24 * 60 * 60 * 1000 +
          this.hourTime * 60 * 60 * 1000 +
          this.mintueTime * 60 * 1000
        configureEdit({
          id: 22,
          value: this.timestamp,
        }).then((res) => {
          if (res.data.code == 0) {
            this.getConfigureList()
            this.$message({
              type: 'success',
              message: '设置成功!',
            })
            this.delayedVisible = false
          }
        })
      } else {
        configureEdit({
          id: 30,
          value: this.learn_manager,
        }).then((res) => {
          if (res.data.code == 0) {
            this.getConfigureList()
            this.$message({
              type: 'success',
              message: '设置成功!',
            })

            this.delayedVisible = false
          }
        })
      }
    },
    getConfigureList() {
      configureList({}).then((res) => {
        let interPrice = null
        res.data.data.list.map((v) => {
          if (v.name == '共享平台开票通知管理员列表') {
            console.log('111', v.value, this.$store.state.staffOptions)
            this.$store.state.staffOptions.map((item) => {
              if (item.id == v.value) {
                console.log('opop')
                this.mangeName = item.nickname
              }
            })
            // this.mangeName = this.$store.state.staffOptions.filter((item) => {
            //   return Number(v.value) == item.id
            // })
            console.log('this.mangeName', this.mangeName)
          }
          if (v.name == '共享平台一口价设置间隔时间') {
            interPrice = v.value
            if (interPrice < 3600000) {
              this.priceTime = `${Math.floor(
                Math.floor(Math.round(interPrice / 1000) / 60)
              )}分钟`
            } else if (interPrice < 86400000) {
              this.priceTime = `${Math.floor(
                Math.floor(Math.round(interPrice / 1000) / 60) / 60
              )}小时`
            } else if (interPrice < 2592000000) {
              this.priceTime = `${Math.floor(
                Math.floor(
                  Math.floor(Math.round(interPrice / 1000) / 60) / 60
                ) / 24
              )}天`
            } else if (interPrice < 31104000000) {
              this.priceTime = `${
                Math.floor(
                  Math.floor(
                    Math.floor(Math.round(interPrice / 1000) / 60) / 60
                  ) / 24
                ) / 30
              }月`
            }
            console.log('priceTime', this.priceTime)
          }
        })

        // let interPrice = res.data.data.list.filter((v) => {
        //   v.name == '共享平台一口价设置间隔时间'
        // })
        console.log('interPrice', interPrice)

        let obj = res.data.data.list.filter(
          (item) => item.name == '共享平台审核隐藏开关'
        )
        if (obj[0].value == 1) {
          this.value = true
        } else {
          this.value = false
        }
        this.id = obj[0].id

        this.shareValue = !!Number(
          res.data.data.list.find(
            (item) => item.code == 'SharePlatformBalancePaySubStatus'
          ).value
        )
        this.sharePrice = res.data.data.list.find(
          (item) => item.code == 'SharePlatformBalancePaySubAmount'
        ).value
      })
    },
    switchprice() {
      this.$confirm(`是否${this.shareValue ? '关闭' : '开启'}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          configureEdit({
            id: 16,
            value: this.shareValue ? false : true,
          }).then((res) => {
            if (res.data.code == 0) {
              this.getConfigureList()
              this.$message({
                type: 'success',
                message: '设置成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    switchUpShare() {
      this.$confirm(`是否${this.shareValue ? '关闭' : '开启'}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          configureEdit({
            id: 16,
            value: this.shareValue ? false : true,
          }).then((res) => {
            // console.log("设置", res)
            if (res.data.code == 0) {
              this.getConfigureList()
              this.$message({
                type: 'success',
                message: '设置成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    switchUp() {
      this.$confirm(`是否${this.value ? '关闭' : '开启'}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          configureEdit({
            id: this.id,
            value: this.value ? false : true,
          }).then((res) => {
            // console.log("设置", res)
            if (res.data.code == 0) {
              this.getConfigureList()
              this.$message({
                type: 'success',
                message: '设置成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
  },
}
</script>

<style lang="less" scoped>
.switch {
  display: flex;
  align-items: center;
}
.switchTime {
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 600px;
}
::v-deep .el-input--small .el-input__inner {
  width: 80px !important;
}

.breadcrumb {
  display: flex;
  align-items: center;
  height: 35px;
}

.tips-class {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 16px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #909399;
  opacity: 1;
}

.button-new-tag {
  margin-right: 24px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  float: right;
  margin-bottom: 20px;
}
</style>