<template>
  <div>
    <el-dialog
top="5vh"
      title="指定学管师"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="left"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="对接人："
          prop="manage_watcher"
        >
          <el-select
            v-model="dataForm.manage_watcher"
            placeholder="请选择对接人"
            clearable
            multiple
          >
            <el-option
              v-for="(item,index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>

      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  editstudentlist,
} from "@/request/api";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    ids: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },

      rules: {
        manage_watcher: [{ required: true, message: "请选择对接人", trigger: "blur" }],
      },
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.$store.dispatch('getStaffOptions')
      }
    },
  },
  methods: {

    emitClose() {
      this.$emit("close");
    },


    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            rows: this.ids.map(v => {
              return {
                id: v.id,
                docking_man: this.dataForm.manage_watcher.map(v => { return Number(v) }).join(','),
                
              }
            })
          }
          editstudentlist(query).then((res) => {
            if (res.data.code == 0) {
              this.$message.success('修改成功')
              this.emitClose()
              this.$emit('submit')
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>