<template>
  <div>
    <el-dialog
      top="5vh"
      title="新建资料"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="700px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="年份："
          prop="year"
        >
          <el-select
            v-model="dataForm.year"
            placeholder="请选择年份"
            clearable
            @change="nameChange"
            style="width: 100%;"
          >
            <el-option
              label="2020"
              :value="2020"
            >
            </el-option>
            <el-option
              label="2021"
              :value="2021"
            >
            </el-option>
            <el-option
              label="2022"
              :value="2022"
            >
            </el-option>
            <el-option
              label="2023"
              :value="2023"
            >
            </el-option>
            <el-option
              label="2024"
              :value="2024"
            >
            </el-option>
            <el-option
              label="2025"
              :value="2025"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="院校名称："
          prop="college"
        >
          <el-select
            v-model="dataForm.college"
            placeholder="请选择院校名称"
            filterable
            clearable
            @change="collegeChange"
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="学院名称："
          prop="academy"
        >
          <el-select
            v-model="dataForm.academy"
            placeholder="请选择学院"
            clearable
            style="width: 100%;"
            @change="nameChange()"
            filterable
          >
            <el-option
              v-for="(item,index) in academyOptions"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
              <span>{{ `${item.code?`(${item.code})`:''}${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="文件类型："
          prop="type"
        >
          <el-select
            v-model="dataForm.type"
            placeholder="请选择文件类型"
            filterable
            clearable
            style="width: 100%;"
            @change="nameChange"
          >
            <el-option
              v-for="(item,index) in types"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="资料名称："
          prop="name"
        >
          <el-input
            v-model="dataForm.name"
            placeholder="请输入资料名称"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="文件格式："
          prop="content_type"
        >
          <el-radio-group v-model="dataForm.content_type">
            <el-radio :label="2">文件</el-radio>
            <el-radio :label="3">多图</el-radio>
            <el-radio :label="1">网址</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label=""
          prop="content_type"
          label-width="0"
        >
          <div
            class="wrap"
            v-if="dataForm.content_type==1"
          >
            <el-input
              placeholder="请输入内容"
              v-model="httpUrl"
            >
            </el-input>
          </div>
          <div
            class="wrap"
            v-if="dataForm.content_type==2"
          > 
          <!-- <input name="file" type="file" id="input" multiple  style="width: 100%;height: 20px;background: red;" ref="upINput" @change="handleFiles($event)"> -->
          
            <el-upload
              drag
              :action="imgUploadUrl"
              :file-list="fileList"
              multiple
              :on-remove="fileListRemove"
              :on-success="fileListSucccess"
              :limit="1"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </div>
          <div
            class="wrap-other"
            v-if="dataForm.content_type==3"
          >
            <el-upload
              :action="imgUploadUrl"
              list-type="picture-card"
              :file-list="imgList"
              :on-remove="imgListRemove"
              :on-preview="handlePicturePreview"
              :on-success="imgListSucccess"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>

      </el-form>

      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      top="5vh"
      :visible.sync="imgDialogVisible"
    >
      <img
        width="100%"
        :src="dialogImageUrl"
        alt=""
      >
    </el-dialog>
  </div>
</template>
  <script>
import {
  materialCreate,
  academylist,
  upload
} from "@/request/api";

import {

} from "@/utils/helper";

import { imgUploadUrl } from "@/request/http";
import axios from 'axios'

export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      radio: 0,
      dataForm: {
        content_type: 2,
      },
      rules: {
        college: [{ required: true, message: "请选择院校", trigger: "blur" }],
        year: [{ required: true, message: "请选择年份", trigger: "blur" }],
        content_type: [{ required: true, message: "请选择文件格式", trigger: "blur" }],
        type: [{ required: true, message: "请选择文件类型", trigger: "blur" }],
        name: [{ required: true, message: "请输入资料名称", trigger: "blur" }],
        /*      academy: [{ required: true, message: "请选择学院", trigger: "blur" }], */
      },
      imgUploadUrl: imgUploadUrl(),
      fileList: [],
      imgList: [],
      dialogImageUrl: '',
      imgDialogVisible: false,
      httpUrl: '',
      types: [
        {
          label: '录取名单',
          value: 1,
        }, {
          label: '参考书目',
          value: 2,
        },
        {
          label: '专业目录',
          value: 3,
        },
        {
          label: '招生简章',
          value: 4,
        },
        {
          label: '考试大纲',
          value: 5,
        }, {
          label: '分数线',
          value: 6,
        }, {
          label: '复试方案',
          value: 7,
        }, {
          label: '调剂信息',
          value: 8,
        }, {
          label: '复试名单',
          value: 9,
        },
      ],
      academyOptions: [{
        name: '不限',
        code: '',
      }]
    };

  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (Object.keys(this.content).length) {
        this.dataForm = this.content
        if (this.content.college_name && this.content.college_code) {
          this.$set(this.dataForm, 'college', {
            label: this.dataForm.college_name,
            value: this.dataForm.college_code,
          })
          this.nameChange()
        }
        this.$set(this.dataForm, 'content_type', 2)
        this.$set(this.dataForm, 'academy', {
          label: '不限',
          value: ''
        })
      }
    },
  },
  methods: {
    handleFiles(e){
      console.log("E",e)
      const data = new FormData()
      data.append('file',e.target.files[0])
      axios({
        method: 'post',
        data,
        url:'https://manage-api.kaoyanbox.net/api/v1/upload/image',
        headers: {'Content-Type': 'multipart/form-data'},
      }).then(res=>{
        console.log(res,'res')
      })
    },
    handleToClose() {
      this.$emit("close");
      this.dataForm = {
        content_type: 2,
      }
      this.httpUrl = ''
      this.fileList = []
      this.imgList = []
    },
    fileListRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = fileList
    },
    fileListSucccess(response, file, fileList) {
      // console.log(fileList, 'fileList');
      this.fileList = fileList
    },
    imgListRemove(file, fileList) {
      console.log(file, fileList);
      this.imgList = fileList
    },
    imgListSucccess(response, file, fileList) {
      console.log(fileList, 'fileList');
      this.imgList = fileList
    },
    handlePicturePreview(file) {
      this.dialogImageUrl = file.url;
      this.imgDialogVisible = true;
    },
    collegeChange(e) {
      if (e) {
        this.getAcedemy(e.value)
      }
      this.nameChange()
    },
    getAcedemy(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      academylist(query).then((response) => {
        let res = response.data
        this.academyOptions = this.academyOptions.concat(res.data.list)
        this.$set(this.dataForm, 'academy', {
          label: '不限',
          value: ''
        })
      });
    },
    nameChange(e) {
      this.$set(this.dataForm, 'name', `${this.dataForm.year ? `${this.dataForm.year}年` : ''}${this.dataForm.college ? this.dataForm.college.label : ''}${this.dataForm.academy && this.dataForm.academy.label != '不限' ? this.dataForm.academy.label : ''}${this.dataForm.type ? this.types.find(v => {
        return v.value == this.dataForm.type
      }).label : ''}`)
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let data = {
            year: this.dataForm.year,
            college_code: this.dataForm.college.value,
            college_name: this.dataForm.college.label,
            academy_code: this.dataForm.academy && this.dataForm.academy.label != '不限' ? this.dataForm.academy.value : '',
            academy_name: this.dataForm.academy && this.dataForm.academy.label != '不限' ? this.dataForm.academy.label : '',
            type: this.dataForm.type,
            name: this.dataForm.name,
            content_type: this.dataForm.content_type,
            content: this.formateContent()
          }
          materialCreate(data).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    formateContent() {
      if (this.dataForm.content_type == 1) {
        return this.httpUrl
      } else if (this.dataForm.content_type == 2) {
        let arr = this.fileList ? this.fileList.map(v => {
          return {
            name: v.name,
            url: v.response.data.url
          }
        }) : ''
        return JSON.stringify(arr)
      } else if (this.dataForm.content_type == 3) {
        let arr = this.imgList ? this.imgList.map(v => {
          return {
            name: v.name,
            url: v.response.data.url
          }
        }) : ''
        return JSON.stringify(arr)
      }
    }
  },
};
      </script>
  <style lang="less" scoped>
.content {
  .wrap {
    /deep/.el-upload {
      width: 100%;
    }
    /deep/.el-upload-dragger {
      width: 100%;
    }
  }
}
</style>