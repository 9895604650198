<template>
  <div>
    <el-dialog
top="5vh"
      title="计划详情"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="left"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
        v-if="Object.keys(content).length"
      >
        <el-form-item label="上课时间">
          <div class="value">
            {{content.plan_start_time?$moment(content.plan_start_time).format("YYYY-MM-DD HH:mm"):""}}
          </div>
        </el-form-item>
        <el-form-item label="课时主题">
          <div class="value">
            {{content.course_theme}}
          </div>
        </el-form-item>
        <el-form-item label="内容简介">
          <div class="value">
            {{content.course_content}}
          </div>
        </el-form-item>
        <el-form-item label="计划时长">
          <div class="value">
            {{content.plan_lesson_hour}}小时
          </div>
        </el-form-item>
        <el-form-item label="所在班级">
          <div class="value">
            {{content.grade?content.grade.name:'无'}}
          </div>
        </el-form-item>
        <el-form-item label="相关学员">
          <div class="value">
            {{formateMember(content.student_realnames)}}
          </div>
        </el-form-item>
        <el-form-item label="授课老师">
          <div class="value">
            {{content.teacher?content.teacher.realname:'无'}} 
          </div>
        </el-form-item>
        <el-form-item label="课程阶段">
          <div class="value">
            {{formateCourse_stage(content.course_stage)}} </div>
        </el-form-item>
        <el-form-item label="课程目标">
          <div class="value">
            {{content.course_target}}
          </div>
        </el-form-item>
        <el-form-item label="学管师">
          <div class="value">
            {{content.course_target}}
            {{content.learn_manager?content.learn_manager.nickname:""}}
          </div>
        </el-form-item>
        <el-form-item label="创建时间">
          <div class="value">
            {{$moment(content.create_time).format("YYYY-MM-DD HH:mm")}}
          </div>
        </el-form-item>
        <el-form-item label="备注">
          <div class="value">
            {{ content.remark }}
          </div>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button
          size="small"
          @click="emitClose(false)"
        >取消</el-button>
        <el-button
          type="danger"
          size="small"
          @click="cancel"
        >使作弃</el-button>

      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  coursePlanOne
  ,
  coursePlanEdit
} from "@/request/api";
import {

} from "@/utils/helper";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {

      },
      content: {},

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      coursePlanOne({ id: this.id }).then((response) => {
        let res = response.data
        this.content = res.data

      });
    },
    emitClose() {
      this.dataForm = {
      }
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          console.log('asdads');
        } else {
          console.log("error submit!!"); 
          return false;
        }
      });
    },
    formateMember(val) {
      if (val) {
        let val_arr = val.split(',')
        if (val_arr.length > 3) {
          return val.split(',').slice(0, 3).join('，') + '等'
        } else {
          return val.split(',').slice(0, 3).join('，')
        }
      } else {
        return '无'
      }
    },
    formateCourse_stage(val) {
      let stages = {
        '0': '基础',
        '1': '强化',
        '2': '冲刺',
      }
      return stages[val]
    },
    cancel() {
      this.$confirm("是否确认作弃本条课程计划，确认后对应课时将释放，需重新新建计划", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        coursePlanEdit({ id: this.id, plan_status: 6 }).then((res) => {
          if (res.data.code == 0) {
            this.emitClose()
            this.$emit('submit')
          }
        });
      })
    }
  },
};
</script>

<style lang="less" scoped>
.content {
  .value {
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
  }
}
</style>