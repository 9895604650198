<template>
  <div>
    <el-dialog top="5vh" title="提交师资信息" :visible.sync="dialogFormVisible" @close="handleToClose"
      :close-on-click-modal="false" width="560px">

      <el-form :model="dataForm" label-position="right" ref="formRules" :rules="rules" label-width="110px" size="small">
        <el-form-item label="录取年份：" prop="teacher_apply_year">
          <el-select v-model="dataForm.teacher_apply_year" placeholder="请选择">
            <el-option v-for="item in yearOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="姓名：" prop="teacher_name">
          <el-input v-model="dataForm.teacher_name" placeholder="请输入姓名" style="width: 100%;"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="teacher_phone">
          <el-input v-model="dataForm.teacher_phone" placeholder="请输入手机号" style="width: 100%;"
            oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="初试总分：" prop="teacher_first_exam_score">
          <el-input v-model="dataForm.teacher_first_exam_score" placeholder="请输入初试总分" style="width: 100%;"
            oninput="value=value.replace(/[^0-9.]/g,'')">
            <template slot="append">分</template>
          </el-input>
        </el-form-item>
        <el-form-item label="微信号：" prop="name">
          <el-input v-model="dataForm.teacher_wehcat" placeholder="请输入微信号" style="width:100%;">
          </el-input>
        </el-form-item>
        <el-form-item label="QQ号：">
          <el-input v-model="dataForm.teacher_qq" placeholder="请输入QQ号" style="width:100%;">
          </el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="dataForm.remark" placeholder="请输入备注" style="width: 100%">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button type="primary" @click="submit('formRules')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  teacherOne,
  taRecordCreate
} from "@/request/api";
import { crmUrl, getStorage } from "@/utils/helper.js";



export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    realname: {
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
    order_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      yearOptions: [
        {
          label: '2025级',
          value: '2025'
        },
        {
          label: '2024级',
          value: '2024'
        },
        {
          label: '2023级',
          value: '2023'
        },
        {
          label: '2022级',
          value: '2022'
        },
        {
          label: '2021级',
          value: '2021'
        },
        {
          label: '2020级',
          value: '2020'
        }
      ],
      dataForm: {
        teacher_apply_year:''
      },
      rules: {
        teacher_apply_year:[{ required: true, message: "录取年份不能为空", trigger: "blur" }],
        teacher_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        teacher_phone: [{ required: true, message: "请输入手机号", trigger: "blur" }, { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号' }],
        teacher_first_exam_score: [{ required: true, message: "请输入初试总分", trigger: "blur" }],

      },


    };

  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      // this.dialogFormVisible = val;

      if (val) {
        if (this.id) {
          this.fetchDetail()
        }
        this.fetchTeacherList(val)

      }
    },
  },
  methods: {
    fetchDetail() {
      teacherOne({ id: this.id }).then((response) => {
        // console.log("xxx",response.data)
        let res = response.data
        this.dataForm = res.data
        this.$set(this.dataForm, 'teacher_apply_year', res.data.recruit_year?res.data.recruit_year:'')
        this.$set(this.dataForm, 'teacher_name', res.data.realname)
        this.$set(this.dataForm, 'teacher_phone', res.data.mobile)
        this.$set(this.dataForm, 'teacher_first_exam_score', res.data.initial_test_score ? res.data.initial_test_score : '')
        this.$set(this.dataForm, 'teacher_wehcat', res.data.wechat ? res.data.wechat : '')
        this.$set(this.dataForm, 'teacher_qq', res.data.qq ? res.data.qq : '')
      })
    },
    fetchTeacherList(val) {
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrderHandle/orderTeacher/find`,
        method: 'get',//可以省略 不写默认是get
        params: {
          find_order_id: this.order_id,
          handle_receiver_id: 1000
        },
      }).then(response => {
        let res = response.data
        if (res.code == 0) {
          this.dialogFormVisible = val
          this.teacherList = res.data.arr_receiver_teacher
        } else {
          this.$emit("close");
        }
      })
    },
    handleToClose() {

      this.$emit("close");
      this.dialogFormVisible = false
      this.dataForm = {}
    },


    recordCreate() {
      let data = {
        type: "17",
        teacher_id: this.id,
        teacher_allocation_order_id: this.order_id || null,
        inner_order_type: 2,
        record: `已将${this.realname}老师推荐给师资平台`
      }
      taRecordCreate(data).then((response) => {
        if (res.code == 0) {
          // this.$message.success('操作成功')
          // this.$emit('submit')
          // this.handleToClose()
        }
      })
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          // if (this.teacherList.findIndex(v => {
          //   return v.teacher_phone == this.dataForm.teacher_phone
          // }) > -1) {
          //   this.$message.error('老师联系方式已存在')
          //   return
          // }
          // console.log("cc",this.dataForm)
          this.teacherList.push({
            teacher_apply_year:this.dataForm.teacher_apply_year,
            create_time: this.dataForm.create_time,
            help_find_order_id: this.order_id,
            receiver_company_user_id: 1000,
            remark: this.dataForm.remark,
            teacher_first_exam_score: this.dataForm.teacher_first_exam_score,
            teacher_name: this.dataForm.teacher_name,
            teacher_phone: this.dataForm.teacher_phone,
            teacher_qq: this.dataForm.teacher_qq,
            teacher_wechat: this.dataForm.teacher_wehcat,
            update_time: this.dataForm.update_time
          })
          //
          let data = {
            find_order_id: this.order_id,
            arr_receiver_teacher: this.teacherList,
            last_receiver_teacher_phone: this.dataForm.teacher_phone,
          }

          // console.log("d",data)
          let str = crmUrl()
          this.$axios({
            url: `${str}/api/v1/sharePlatform/findOrderHandle/orderTeacher/create`,
            method: 'post',//可以省略 不写默认是get
            data: data,
          }).then(response => {
            let res = response.data
            if (res.code == 0) {
              this.recordCreate()
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
</script>
<style lang="less"></style>