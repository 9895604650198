<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>转账审批</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px">
      <el-form
        label-position="right"
        :model="screenForm"
        :inline="true"
        size="small"
      >
        <el-form-item label="机构简称">
          <el-select
            v-model="query.cid"
            placeholder="请选择"
            filterable
            @change="getList(query)"
            clearable
          >
            <el-option
              v-for="item in $store.state.CompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="float: right">
          <el-radio-group
            v-model="query.audit_status"
            @change="getList(query)"
          >
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button :label="1">已通过</el-radio-button>
            <el-radio-button :label="0">待审核</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-card> <el-card style="margin-top: 20px">
      <TableTittle :title="TableName"></TableTittle>
   
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="50"
          fixed="left"
        >
        </el-table-column>
        <el-table-column
          prop="order_no"
          label="充值ID"
          width="274px"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="type"
          label="机构简称"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.company.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="total_amount"
          label="充值金额"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.amount">{{ scope.row.amount + "元" }}</span>
            <span v-else>0元</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="付款截图"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              @click="openViewer(scope.row.payOrder.pay_voucher)"
              :disabled="!$buttonPermission('transferapproval:check')"
              :underline="false"
            >查看</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="提交日期"
          width="210px"
        >
          <template slot-scope="scope">
            {{ scope.row.update_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="操作"
          width="100"
          fixed="right"
        >
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link
                type="primary"
                :underline="false"
                @click="audit(1, scope.row.id)"
                v-show="scope.row.audit_status == 0"
                :disabled="!$buttonPermission('transferapproval:examine')"
              >同意</el-link>
              <el-link
                type="danger"
                :underline="false"
                @click="opendialogFormVisible(scope.row)"
                :disabled="!$buttonPermission('transferapproval:examine')"
                v-show="scope.row.audit_status == 0"
              >拒绝</el-link>
              <el-link
                type="success"
                :underline="false"
                v-show="scope.row.audit_status == 1"
              >已同意</el-link>
              <el-link
                type="danger"
                :underline="false"
                v-show="scope.row.audit_status == 2"
              >已拒绝</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog
top="5vh"
      title="拒绝理由"
      :visible.sync="dialogFormVisible"
      width="570px"
    >
      <el-form
        :model="form"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item
          label=""
          prop="audit_feedback"
        >
          <el-input
            v-model="form.audit_feedback"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogFormVisibleSubmit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      :url-list="[tableImg]"
      :on-close="closeViewer"
    />
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import {
  rechargeorderlist,
  rechargeorderauditdone, // 审核通过
  rechargeorderauditfail, // 审核拒绝
} from "../../../request/api";
// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: {
    TableTittle,
    ElImageViewer,
  },
  data() {
    return {
      TableName: "转账审批",
      tableData: [],
      screenForm: {},
      deleteList: [],
      dialogFormVisible: false,
      form: {},
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        audit_status: 0,
      },
      // 预览图片数组
      showViewer: false,
      tableImg: [],
      srcUrl: require("../../../assets/images/128@2x.png"),
      rules: {
        audit_feedback: [
          { required: true, message: "请输入拒绝理由", trigger: "blur" },
        ],
      },
      auditContent: {},
    };
  },
  mounted() {
    this.getList(this.query);
    this.$store.dispatch("getCompanyList");
  },
  methods: {
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val;
    },
    getList(query) {
      rechargeorderlist(query).then((res) => {
        this.tableData = res.data.data.list || [];
        this.pageTotal = res.data.data.count;
      });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    // 下载文件
    downLoad(item) {
      this.$store.commit("downLoad", item.detail_file_url);
    },
    openViewer(url) {
      this.tableImg = url;
      this.showViewer = true;
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    audit(type, id) {
      switch (type) {
        case 1:
          this.$confirm("确定要通过吗", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let query = {
                id: id,
              };
              rechargeorderauditdone(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogVisible = false;
                  this.getList(this.query);
                });
              });
            })
            .catch(() => {
              this.$notify.error({
                title: "提示",
                message: "已取消修改",
              });
            });
          break;
        case 2:
          break;
        default:
          break;
      }
    },
    opendialogFormVisible(item) {
      this.auditContent = item;
      this.dialogFormVisible = true;
    },
    dialogFormVisibleSubmit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            audit_feedback: this.form.audit_feedback,
            id: this.auditContent.id,
          };
          rechargeorderauditfail(query).then((res) => {
            if (res.data.code == 0) {
              this.dialogFormVisible = false;
              this.getList(this.query);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.demo-form-inline {
  border-bottom: 2px dashed #d2d5df;
}
.screenshotimg {
  width: 22px;
  height: 22px;
}
.templateLink {
  width: 100%;
  .el-link {
    margin: 0px 5px;
  }
}
</style>