import Clipboard from 'clipboard';
import { Message } from 'element-ui';
export function copyWord(type){
    var clipboard = new Clipboard(`.${type}`)
    console.log("cl",`.${type}`,clipboard)
    clipboard.on('success', e => {
        Message({
            message: '复制成功！',
            type: 'success'
        });
        // 释放内存  
        clipboard.destroy()
    })
    clipboard.on('error', e => {
        // 不支持复制  
        Message({
            message: '该浏览器不支持自动复制',
            type: 'error'
        });
        // 释放内存  
        clipboard.destroy()
    })
}