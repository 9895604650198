import Vue from 'vue'
import VueRouter from 'vue-router'



import Layout from '../views/layout'
import Login from '../views/login'
import OverView from '../views/overview'
import Index from '../views/index'

import Service from "./ServiceRouter";
import Finance from "./FinanceRouter";
import Mechanism from "./MechanismRouter";
import Settings from "./SettingsRouter";
import Staff from "./StaffRouter";
import ClassService from "./ClassServiceRouter";
import TeacherResources from "./TeacherResourcesRouter";
import ShareOrder from "./ShareOrderRouter";

import planReview from "../views/ClassService/Plan/planReview.vue";
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/',
      name: 'Layout',
      component: Layout,
      children: [
        {
          path: '/overview',
          component: OverView,
          meta: { title: '总览' }
        },
        {
          path: '/index',
          component: Index,
          meta: { title: '总览' }
        },
        {
          path: '/planReview',
          component: planReview,
          meta: { title: '计划审核' }
      },
        ...Service,
        ...Finance,
        ...Mechanism,
        ...Settings,
        ...Staff,
        ...ClassService,
        ...TeacherResources,
        ...ShareOrder
      ],
    },
    {
      path: '/login',
      component: Login,
      name: 'Login',
    },
  ],
})


