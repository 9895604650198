import { render, staticRenderFns } from "./AddSeniorBill.vue?vue&type=template&id=32ea589d&scoped=true"
import script from "./AddSeniorBill.vue?vue&type=script&lang=js"
export * from "./AddSeniorBill.vue?vue&type=script&lang=js"
import style0 from "./AddSeniorBill.vue?vue&type=style&index=0&id=32ea589d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ea589d",
  null
  
)

export default component.exports