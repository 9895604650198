<template>
    <div class="content">
        <el-dialog top="5vh" :title="title" :visible.sync="dialogFormVisible" @close="dialogFormVisible = false"
            :close-on-click-modal="false" width="620px">
            <el-form :model="dataForm" label-position="left" ref="dataForm" :rules="rules" label-width="80px" size="small">
                <el-form-item label="广告名称" prop="title">
                    <el-input v-model.trim="dataForm.title" placeholder="请输入广告名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="广告封面" prop="picture_url">
                    <el-upload :on-remove="imgListRemove" :file-list="imgList"
                        :class="dataForm.picture_url ? 'hide_box' : ''" :limit="1" list-type="picture-card"
                        class="avatar-uploader" multiple :action="action" :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__tip" slot="tip">请上传JPG/PNG格式，大小在2M以内</div>
                    </el-upload>

                </el-form-item>
                <el-form-item label="可见范围" prop="range_only_user_types">
                    <el-select style="width: 100%;" v-model="dataForm.range_only_user_types" multiple placeholder="选择范围">
                        <el-option v-for="item in rangeList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="广告定位" prop="position_type">
                    <el-select style="width: 100%;" v-model="dataForm.position_type"  placeholder="选择广告定位">
                        <el-option v-for="item in targeting" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="网页链接" prop="link_url_web">
                    <el-input v-model.trim="dataForm.link_url_web" placeholder="请输入网页链接" clearable></el-input>
                </el-form-item>
                <el-form-item label="微信链接" prop="link_url_mini">
                    <el-input v-model.trim="dataForm.link_url_mini" placeholder="请输入微信链接" clearable></el-input>
                </el-form-item>
                <el-form-item label="在线状态" prop="status">
                    <el-switch v-model="dataForm.status" :active-value="1" :inactive-value="0">
                    </el-switch>
                </el-form-item>
                <div class="footer">
                    <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
                    <el-button type="primary" @click="submit" plain size="small">确定</el-button>
                </div>
            </el-form>

        </el-dialog>
    </div>
</template>

<script>
import { imgUploadUrl } from "@/request/http";
import { crmUrl } from "@/utils/helper.js";
export default {
    data() {
        return {
            imgList: [],
            action: imgUploadUrl(),
            dialogFormVisible: false,
            title: '新建广告',
            dataForm: {
                title: '',
                link_url_web: '',
                picture_url: '',
                range_only_user_types: undefined,
                status: 0,
                // position_type: 1,
                position_type:''
            },
            targeting:[
                {
                    label:'弹窗',
                    value:1
                },
                {
                    label:'浮动',
                    value:2
                }
            ],
            rangeList: [
                {
                    label: '全部',
                    value: '全部'
                },
                {
                    label: '发单人',
                    value: '1'
                },
                {
                    label: '接单人',
                    value: '2'
                },
                {
                    label: '游客',
                    value: '3'
                }

            ],
            rules: {
                title: [{ required: true, message: "广告名称不能为空", trigger: "blur" }],
                picture_url: [{ required: true, message: "广告封面不能为空", trigger: "blur" }],
                range_only_user_types: [{ required: true, message: "可见范围不能为空", trigger: "blur" }],
                link_url_web: [{ required: true, message: "网页链接不能为空", trigger: "blur" }],
                link_url_mini:[{required: true, message: "微信链接不能为空", trigger: "blur"}],
                position_type:[{required: true, message: "广告定位不能为空", trigger: "blur"}]
            }
        }
    },
    created() {

    },
    methods: {
        open(row) {
            this.formClear()
            if (row) {
                // console.log("Row",row)
                this.title = "编辑广告"
                this.dataForm = JSON.parse(JSON.stringify(row))
                if(this.dataForm.range_type == 1){
                    this.dataForm.range_only_user_types = ['全部']
                }else{
                    this.dataForm.range_only_user_types = this.dataForm.range_only_user_types.split(',')
                }
                this.imgList = [
                    {
                        url: row.picture_url
                    }
                ]
            } else {
                this.title = "新建广告"
            }
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs.dataForm.clearValidate();
            })
        },
        formClear() {
            this.dataForm = {
                title: '',
                link_url_web: '',
                picture_url: '',
                range_only_user_types: undefined,
                status: 0,
                position_type: ''
            }
            this.imgList = []
        },
        submit() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    if (this.dataForm.range_only_user_types.toString().includes('全部')) {
                        this.dataForm.range_type = 1
                        delete this.dataForm.range_only_user_types
                    } else {
                        this.dataForm.range_type = 2
                        this.dataForm.range_only_user_types = this.dataForm.range_only_user_types.toString()
                    }
                    let str = crmUrl()
                    if (this.title == '新建广告') {
                        this.$axios({
                            url: `${str}/api/v1/sharePlatform/ad`,
                            method: 'post',//可以省略 不写默认是get
                            data: this.dataForm,
                            headers: { // 设置请求头
                                Authorization: localStorage.getItem('admin_token')
                            }
                        }).then(res => {
                            if (res.data.code == 0) {
                                this.dialogFormVisible = false
                                this.$emit("submit")
                                this.$message({
                                    type: 'success',
                                    message: '创建成功!'
                                });
                            }
                        })
                    } else {
                        this.$axios({
                            url: `${str}/api/v1/sharePlatform/ad`,
                            method: 'put',//可以省略 不写默认是get
                            data: this.dataForm,
                            headers: { // 设置请求头
                                Authorization: localStorage.getItem('admin_token')
                            }
                        }).then(res => {
                            if (res.data.code == 0) {
                                this.dialogFormVisible = false
                                this.$emit("submit")
                                this.$message({
                                    type: 'success',
                                    message: '修改成功!'
                                });
                            }
                        })
                    }
                }
            })
        },
        handleAvatarSuccess(res, file, fileList) {
            this.dataForm.picture_url = res.data.url;
            this.imgList = fileList
            // console.log("cc", this.imgList)
        },
        imgListRemove(file, fileList) {
            this.dataForm.picture_url = ''
            this.imgList = fileList

        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传广告封面图片只能是PNG或者JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传广告封面图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        }
    },

}
</script>

<style lang="less" scoped>
.hide_box {
    ::v-deep.el-upload--picture-card {
        display: none;
    }
}

.el-upload__tip{
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    line-height: 20px;
}
</style>