<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>机构</el-breadcrumb-item>
        <el-breadcrumb-item>机构管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button type="primary" size="small" @click="handleToGroup"
          >分组</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="openAddMechanismDialog"
          :disabled="!$buttonPermission('mechanismmanagement:create')"
          >新建</el-button
        >
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form
        label-position="right"
        :model="screenForm"
        :inline="true"
        size="small"
        class="demo-form-inline"
      >
        <el-form-item label="搜索">
          <el-input
            v-model="query.keyword"
            placeholder="机构简称/品牌名称/公司名称/负责人姓名/手机号"
            @input="getList(query)"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <ScreenMechanism @submit="screenTable"></ScreenMechanism>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
          style="margin-bottom: 0px; display: flex"
          label-position="right"
          inline
        >
          <el-form-item label-width="32px">
            <el-radio-group
              v-model="query.data_range_tagses"
              @change="getList(query)"
            > 
              <el-radio-button :label="'0'">全部</el-radio-button>
              <el-radio-button :label="'1'">仅全部</el-radio-button>
              <el-radio-button :label="'2'">外包</el-radio-button>
              <el-radio-button :label="'3'">代签</el-radio-button>
              <el-radio-button :label="'4'">代找</el-radio-button>
              <el-radio-button :label="'5'">共享</el-radio-button>
              <el-radio-button :label="'6'">其他</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" fixed="left">
        </el-table-column>
        <el-table-column
          prop="id"
          label="机构ID"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="机构简称"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="brand_name"
          label="品牌名称"
          width="140"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="company_name"
          label="公司名称"
          width="270"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="company_area"
          label="所在地"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="detail_file_url" label="联系人" width="150">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="openContectDialog(scope.row)"
              :disabled="!$buttonPermission('mechanismmanagement:create')"
              >{{ scope.row.contact_name }}</el-link
            >
          </template>
        </el-table-column>

        <el-table-column label="类型" width="220">
          <template slot-scope="scope">
            <!-- tagList -->
            <el-select
              v-model="scope.row.tagList"
              multiple
              placeholder="请选择"
             :multiple-limit="3"
              @change="
                (...event) => {
                  tagsChange(event, scope.row);
                }
              "
              size="small"
            >
              <el-option
                v-for="item in tagOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- {{ getType(scope.row.data_range_tags) }} -->
          </template>
        </el-table-column>
        <el-table-column
          prop="deposit"
          label="是否开启担保服务"
          width="170"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.platform_guarantee_status == 1 ? "担保" : "非担保"
            }}</span>
          </template>
        </el-table-column>
        <!--       <el-table-column
          prop="company_groups"
          label="机构组"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{formateCompany_groups(scope.row.company_groups)}}
          </template>

        </el-table-column> -->
        <el-table-column
          prop="deposit"
          label="是否缴纳保证金"
          width="170"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.deposit_status == 1">{{
              scope.row.deposit + "元"
            }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="deposit"
          label="是否开启师资匹配"
          width="170"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.teacher_matching_status == 1 ? "开启" : "关闭"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="teacher_matching_price"
          label="师资匹配单价"
          width="170"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.teacher_matching_status == 1">{{
              scope.row.teacher_matching_price + "元"
            }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="deposit"
          label="是否开始师资派遣"
          width="170"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.teacher_dispatch_status == 1 ? "开启" : "关闭"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="deposit"
          label="是否共享手机号"
          width="170"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.is_share_mobile == 1 ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="机构课时小时转换比率"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            1课时 = {{ scope.row.lesson_hour_hour_rate }}小时
          </template>
        </el-table-column>
        <el-table-column prop="detail_file_url" label="合作协议" width="140">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="downLoad(scope.row)"
              :disabled="!$buttonPermission('mechanismmanagement:download')"
              >点击下载</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="company_address"
          label="地址"
          width="320"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="220"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="合作时间"
          width="200"
          show-overflow-tooltip
        >
          <template
            slot-scope="scope"
            v-if="scope.row.coop_start_time && scope.row.coop_end_time"
          >
            {{ scope.row.coop_start_time | dateFormat("YYYY.MM.DD") }} -
            {{ scope.row.coop_end_time | dateFormat("YYYY.MM.DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="creator"
          label="添加人"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.creator">{{
              scope.row.creator.nickname
            }}</span>
            <span v-if="scope.row.manage_creator">{{
              scope.row.manage_creator.nickname
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="默认对接人" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateManage_docking_man(scope.row.manage_docking_man) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="最后修改日期"
          width="170"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.update_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建日期"
          width="170"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column prop="date" label="状态" width="120">
          <template slot-scope="scope">
            <el-tag
              size="small"
              :type="scope.row.status == 1 ? 'success' : 'danger'"
              >{{ scope.row.status == 1 ? "启用" : "禁用" }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="date" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="changeStatus(scope.row)"
              :disabled="!$buttonPermission('mechanismmanagement:edit')"
              >{{ scope.row.status == 1 ? "停用" : "启用" }}</el-link
            >
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="primary"
              :underline="false"
              :disabled="!$buttonPermission('mechanismmanagement:edit')"
              @click="openEditMechanismDialog(scope.row)"
              >编辑</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button
          size="small"
          type="danger"
          @click="chooseAllDelete"
          :disabled="!$buttonPermission('mechanismmanagement:delete')"
          >批量删除</el-button
        >
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddMechanism
      :isVisible="AddMechanismDialog"
      @close="AddMechanismDialog = false"
      @submit="AddMechanismSubmit"
    ></AddMechanism>
    <EditMechanism
      :isVisible="EditMechanismDialog"
      @close="EditMechanismDialog = false"
      :id="editId"
      @submit="EditMechanismSubmit"
    >
    </EditMechanism>
    <el-dialog
      top="5vh"
      title="联系人"
      :visible.sync="contectDialog"
      width="445px"
    >
      <el-form :model="form" label-width="80px" label-position="right">
        <el-form-item label="姓名">
          <el-input v-model="form.contact_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="form.contact_phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="职务">
          <el-select
            v-model="form.contact_title"
            placeholder="请选择职务"
            style="width: 325px"
          >
            <el-option
              v-for="item in $store.state.MechanismRoleList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="contectDialog = false">取 消</el-button>
        <el-button type="primary" @click="EditMechanism(form)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddMechanism from "../../../components/DialogComponents/AddMechanism/AddMechanism.vue";
import EditMechanism from "../../../components/DialogComponents/EditMechanism/EditMechanism.vue";
import ScreenMechanism from "../../../components/ScreenComponents/ScreenMechanism/ScreenMechanism.vue";
import {
  createcompany,
  companylist,
  deletecompanylist,
  editcompany,
} from "../../../request/api";
export default {
  components: {
    TableTittle,
    AddMechanism,
    EditMechanism,
    ScreenMechanism,
  },
  data() {
    return {
      tagOptions: [
        {
          label: "全部",
          value: "1",
        },
        {
          label: "外包",
          value: "2",
        },
        {
          label: "代签",
          value: "3",
        },
        {
          label: "代找",
          value: "4",
        },
        {
          label: "共享",
          value: "5",
        },
        {
          label: "其他",
          value: "6",
        },
      ],
      TableName: "机构列表",
      tableData: [],
      screenForm: {},
      form: {},
      AddMechanismDialog: false,
      EditMechanismDialog: false,
      contectDialog: false,
      EditMechanismContent: {},
      deleteList: [],
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        data_range_tagses: "0",
      },
      editId: null,
    };
  },
  mounted() {
    this.getList(this.query);
    this.$store.dispatch("getMechanismList");
    this.$store.dispatch("getStaffOptions");
    this.$store.dispatch("getCompanyGroup");
  },
  methods: {
    tagsChange(e, item) {
      this.EditMechanismSubmit({
        form: {
          id: item.id,
          data_range_tags: e[0].join(','),
        },
      });

      // console.log(e, item);
      // this.tableData.forEach((item,index)=>{
      //   if(item.id == item.id){
      //     this.$set(this.tableData[index],'tagList',e)
      //     // item.tagList = e
      //   }
      // })
      // item.tagList = e
      // let data = {
      //   manage_tags: e[0] && e[0].length ? e[0].join(',') : '',
      //   id: item.id,
      // }
      // editstudent(data).then((res) => {
      //   if (res.data.code == 0) {
      //     this.$message.success('修改成功')
      //     this.fetchList()
      //   }
      // });
    },
    getType(tag) {
      let nameList = ["全部", "外包", "代签", "代找", "共享", "其他"];
      if (tag) {
        // console.log('tag:', tag)
        return tag
          .split(",")
          .map((item) => nameList[Number(item) - 1])
          .join(",");
      } else {
        return "-";
      }
    },
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val;
    },
    getList(query) {
      let data = JSON.parse(JSON.stringify(query))
      if(this.query.data_range_tagses==0){
        data.data_range_tagses = null
      }
      companylist(data).then((res) => {
        this.tableData = res.data.data.list || [];
        this.tableData.forEach((item) => {
          item.tagList = item.data_range_tags.split(",");
        });
        //
        this.pageTotal = res.data.data.count;
      });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    openAddMechanismDialog() {
      this.AddMechanismDialog = !this.AddMechanismDialog;
    },
    openEditMechanismDialog(item) {
     console.log("item",item)
      this.editId = item.id;
      this.EditMechanismDialog = true;
    },
    openContectDialog(item) {
      this.contectDialog = !this.contectDialog;
      this.form = JSON.parse(JSON.stringify(item));
    },
    // 机构添加提交
    AddMechanismSubmit({ form }) {
      createcompany(form).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("提交成功");
          this.getList(this.query);
          this.AddMechanismDialog = false;
        }
      });
    },
    // 机构修改提交
    EditMechanismSubmit({ form }) {
      editcompany(form).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("提交成功");
          this.getList(this.query);
          this.EditMechanismDialog = false;
        }
      });
    },
    // 机构修改提交
    EditMechanism(form) {
      delete form.creator;
      editcompany(form).then((res) => {
        this.$nextTick(() => {
          this.getList(this.query);
          this.contectDialog = false;
        });
      });
    },
    // 切换数据状态
    changeStatus(item) {
      let query = {
        id: item.id,
        status: 0,
      };
      if (item.status == 0) {
        query.status = 1;
      }
      editcompany(query).then((res) => {
        this.$nextTick(() => {
          this.getList(this.query);
        });
      });
    },
    // 筛选
    screenTable({ screenForm }) {
      this.$set(this.query, "pageIndex", 1);
      this.query = {
        ...this.query,
        ...screenForm,
      };
      this.getList(this.query);
    },
    // 下载文件
    downLoad(item) {
      this.$store.commit("downLoad", item.agreement);
    },
    // 选中数据后做处理删除
    chooseAllDelete() {
      this.$confirm("确定要删除选中的数据吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            ids: [],
          };
          this.deleteList.forEach((item) => {
            query.ids.push(item.id);
          });
          deletecompanylist(query).then((res) => {
            this.$notify.success({
              title: "提示",
              message: "删除成功",
            });
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    formateCompany_groups(ids) {
      let val = ids ? ids.split(",") : [];
      let str = [];

      if (val.length && this.$store.state.groupList.length) {
        val.forEach((item) => {
          str.push(
            this.$store.state.groupList.find((v) => {
              return v.id == item;
            }).name
          );
        });
        return str.join(",");
      } else {
        return "无";
      }
    },
    handleToGroup() {
      this.$router.push(`/mechanism/group`);
    },
    formateManage_docking_man(val) {
      if (val) {
        return val
          .map((v) => {
            return v.nickname;
          })
          .join(",");
      } else {
        return "暂无";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
}
.star {
  width: 18px;
  height: 18px;
}
.demo-form-inline {
  height: 64px;
  border-bottom: 2px dashed #d2d5df;
}
</style>