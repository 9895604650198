// 学生计划颜色
let getStudentPlanColor = (e) => {
	if (e == 20) {
		return 'primary'
	} else if (e == 21) {
		return 'warning'
	} else if (e == 22) {
		return 'error'
	} else if (e == 40 || e == 41 || e == 42 || e == 43 || e == 45 || e == 46) {
		return 'success'
	}

}
// 学生计划状态
let getStudentPlanStatus = (e) => {
	if (e == 20) {
		return '待上课'
	} else if (e == 21) {
		return '待提交'
	} else if (e == 22) {
		return '已超期'
	} else if (e == 40 || e == 41 || e == 42 || e == 43 || e == 45 || e == 46) {
		return '已上课'
	}
}

// 老师计划颜色
let getTeacherPlanColor = (e) => {
	if (e == 0) {
		return ''
	} else if (e == 5) {
		return 'info'
	}else if (e == 6) {
		return 'info'
	}  
	else if (e == 20) {
		return 'success'
	} else if (e == 21) {
		return 'warning'
	} else if (e == 22) {
		return 'danger'
	} else if (e == 40) {
		return ''
	} else if (e == 41) {
		return ''
	} else if (e == 42) {
		return 'danger'
	} else if (e == 43 || e == 45 || e == 46) {
		return 'success'
	}

}
// 老师计划状态
let getTeacherPlanStatus = (e) => {
	// console.log("e",e)
	if (e == 0) {
		return '待审核'
	} else if (e == 5) {
		return '已拒绝'
	} else if (e == 6) {
		return '已取消'
	} 
	else if (e == 20) {
		return '待上课'
	} else if (e == 21) {
		return '待提交'
	} else if (e == 22) {
		return '已超期'
	} else if (e == 40) {
		return '待确认'
	} else if (e == 41) {
		return '待回访'
	} else if (e == 42) {
		return '已拒绝'
	} else if (e == 43 || e == 45 || e == 46) {
		return '已完成'
	}
}

// 学生课时颜色
let getStudentClassColor = (e) => {
	if (e == 0) {
		return 'primary'
	} else if (e == 1) {
		return 'primary'
	} else if (e == 2) {
		return 'error'
	} else if (e == 3 || e == 5 || e == 6) {
		return 'success'
	}
}
// 学生课时状态
let getStudentClassStatus = (e) => {
	if (e == 0) {
		return '待确认'
	} else if (e == 1) {
		return '待回访'
	} else if (e == 2) {
		return '已拒绝'
	} else if (e == 3 || e == 5 || e == 6) {
		return '已完成'
	}
}

// 老师课时颜色
let getTeacherClassColor = (e) => {
	if (e == 0) {
		return 'primary'
	} else if (e == 1) {
		return 'primary'
	} else if (e == 2) {
		return 'error'
	} else if (e == 3) {
		return 'success'
	} else if (e == 5) {
		return 'success'
	} else if (e == 6) {
		return 'warning'
	}

}
// 老师课时状态
let getTeacherClassStatus = (e) => {
	if (e == 0) {
		return '待确认 '
	} else if (e == 1) {
		return '待回访'
	} else if (e == 2) {
		return '已拒绝'
	} else if (e == 3) {
		return '已完成'
	} else if (e == 5) {
		return '已完结'
	} else if (e == 6) {
		return '已暂停 '
	}
}
export {
	getStudentPlanColor,
	getStudentPlanStatus,
	getTeacherPlanColor,
	getTeacherPlanStatus,
	getStudentClassColor,
	getStudentClassStatus,
	getTeacherClassColor,
	getTeacherClassStatus
}