<template>
  <div>
    <el-dialog
      top="5vh"
      :title="dataForm.name || '预览'"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="1080px"
    >
      <template v-if="this.fileObject">
        <iframe
          :frameborder="0"
          height="800"
          :src="this.fileObject.url"
          class="iframe"
          v-if="this.fileObject.url.indexOf('.pdf') != -1"
        ></iframe>
        <iframe
          :frameborder="0"
          height="800"
          :src="baseurl+this.fileObject.url"
          class="iframe"
          v-else
        >
        </iframe>
      </template>

    </el-dialog>
  </div>
</template>
      <script>

let baseurl = `${window.location.protocol}//view.officeapps.live.com/op/view.aspx?src=`


import {
  materialOne
} from "@/request/api";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    arr: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {},
      fileObject: null,
      imgList: null,
      baseurl: baseurl,
      httpUrl: null,
    };

  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      this.fileObject = this.arr[0]
      console.log(this.fileObject);
    },
    handleToClose() {
      this.$emit("close");
    },

  },
};
          </script>
      <style lang="less" scoped>
.content {
  .iframe {
    width: 100%;
  }
  .img-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
    }
  }
}
</style>