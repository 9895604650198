<template>
  <el-dialog
    title="审核"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1094px"
    class="dialog"
    append-to-body
  >
    <div class="header">
      <TableTittle title="基础信息" style="margin-bottom: 0"></TableTittle>
      <div>
        <el-button size="small" type="danger" @click="offLineFlag = true"
          >下线</el-button
        >
        <el-button size="small" type="primary" @click="handlePass"
          >通过</el-button
        >
      </div>
    </div>
    <el-table
      :data="dataList"
      :header-cell-style="{
        backgroundColor: '#F2F6FC',
        color: '#909399',
        fontSize: '14px',
      }"
      :cell-style="{ fontSize: '13px' }"
      border
    >
      <el-table-column prop="id" label="ID" align="center"> </el-table-column>
      <el-table-column
        prop="teacher_name"
        label="姓名"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <el-tag :type="getStatus(scope.row.comp_teacher_status, 1)">{{
            getStatus(scope.row.comp_teacher_status, 2)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="pre_check_access_count"
        label="采纳数"
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="pre_check_refuse_count"
        label="驳回数"
        align="center"
      >
      </el-table-column>
      <el-table-column label="提交人" align="center" width="240">
        <template>
          {{ receiver_company_user_company_name }} /
          {{ receiver_company_user_name }} /
          {{ receiver_company_user_phone }}
        </template>
      </el-table-column>
      <el-table-column label="提交日期" align="center" width="150">
        <template slot-scope="scope">
          {{
            scope.row.create_time
              ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="更新时间" align="center" width="150">
        <template slot-scope="scope">
          {{
            scope.row.update_time
              ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
              : ""
          }}
        </template>
      </el-table-column>
    </el-table>

    <TableTittle title="跟进记录" style="margin-top: 24px"></TableTittle>
    <FollowUpRecord :teacherId="id"/>
    <el-dialog
      append-to-body
      title="下线理由"
      :visible.sync="offLineFlag"
      width="628px"
    >
      <el-input
        type="textarea"
        :autosize="{ minRows: 2 }"
        placeholder="请输入内容"
        v-model="pre_outline_reason"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offLineFlag = false">取 消</el-button>
        <el-button type="primary" @click="handleOffLine">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import FollowUpRecord from "../../components/followUpRecord/index.vue"
import { crmUrl } from "@/utils/helper.js";
export default {
  components: {
    TableTittle,
    FollowUpRecord
  },
  data() {
    return {
      dialogVisible: false,
      offLineFlag: false,
      dataList: [],
      pre_outline_reason: "",
      id: "",
      receiver_company_user_company_name: "",
      receiver_company_user_name: "",
      receiver_company_user_phone: "",
    };
  },
  methods: {
    open(id, company_name, user_name, user_phone) {
      this.pre_outline_reason = "";
      this.receiver_company_user_company_name = company_name;
      this.receiver_company_user_name = user_name;
      this.receiver_company_user_phone = user_phone;
      this.id = id;
      this.getDetail(id);
      this.dialogVisible = true;
    },
    getDetail(id) {
      let str = crmUrl();
      this.$axios({
        url: `${str}/api/v1/sharePlatform/preTeacher`,
        method: "get",
        params: {
          id,
        },
        headers: {
          Authorization: localStorage.getItem("admin_token"),
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.dataList = [res.data.data];
        }
      });
    },
    handleOffLine() {
        let data = {
          id: this.id,
          pre_outline_reason: this.pre_outline_reason,
          comp_teacher_status: 4,
        }
        this.updateStatus(data)
    },
    handlePass() {
        let data = {
          id: this.id,
          comp_teacher_status: 2,
        }
        this.updateStatus(data)
    },
    updateStatus(data) {
      let str = crmUrl();
      this.$axios({
        url: `${str}/api/v1/sharePlatform/preTeacher/compTeacherStatus`,
        method: "put",
        data: data,
        headers: {
          Authorization: localStorage.getItem("admin_token"),
        },
      }).then((res) => {
        // console.log("res:", res);
        if (res.data.code == 0) {
          this.$message({
            message: "审核成功！",
            type: "success",
          });
          this.offLineFlag = false;
          this.dialogVisible = false;
          this.$emit("offLineSubmit");
        }
      });
    },

    getStatus(status, type) {
      switch (status) {
        case 1:
          if (type == 1) return "";
          return "已提交";
        case 2:
          if (type == 1) return "success";
          return "正常";
        case 3:
          if (type == 1) return "warning";
          return "待审核";
        case 4:
          if (type == 1) return "danger";
          return "已下线";
        case 5:
          if (type == 1) return "info";
          return "已删除";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
