<template>
  <div>
    <el-dialog
top="5vh"
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="680px"
    >
      <div>
        <el-descriptions
          :column="1"
          :labelStyle="labelStyle"
        >
          <el-descriptions-item label="姓名">
            {{content.name}}
          </el-descriptions-item>
          <el-descriptions-item label="提交时间">{{$moment(content.time).format("YYYY-MM-DD HH:mm")}}</el-descriptions-item>
          <el-descriptions-item label="确认状态">
            <el-tag
              size="mini"
              v-if="content.status=='待确认'"
              type="warning"
            >{{content.status}}</el-tag>
            <el-tag
              size="mini"
              v-else-if="content.status=='已拒绝'"
              type="danger"
            >{{content.status}}</el-tag>
            <el-tag
              size="mini"
              type="success"
              v-else
            >{{content.status}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="确认内容">{{content.content}}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="footer">
        <el-button @click="emitClose()">取消</el-button>
        <el-button
          type="primary"
          @click="emitClose()"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import {
  confirmLesson,
  lessonLogOne
} from "@/request/api";
import { } from "@/utils/helper.js";
import { } from "@/utils/helper";
import { imgUploadUrl, fileUploadUrl } from "@/request/http";



export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },

  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      imgUploadUrl: imgUploadUrl(),
      labelStyle: {
      },
      title: '确认详情'
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.title = this.content.label + '的确认详情'
      }
    },

  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  margin-bottom: 16px;
}
.weight {
  margin-bottom: 8px;
  font-weight: 800;
  color: #333;
}
</style>