<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/classservice/class' }"
          >班级管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>班级变更记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <!-- <el-table-column type="selection" width="50" align="center">
        </el-table-column> -->
        <el-table-column label="记录日期" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="服务类型" align="center" width="150">
          <template slot-scope="scope">
            {{ formateType(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="content_text"
          label="记录内容"
          align="left"
          show-overflow-tooltip
          min-width="300"
        >
        </el-table-column>
        <el-table-column
          prop="content_text"
          label="关联对象"
          align="center"
          min-width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ getName(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="附件" align="center" width="100">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              v-if="scope.row.content_img"
              @click="openViewer(JSON.parse(scope.row.content_img))"
              >预览</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              v-else-if="scope.row.content_file"
              @click="handleToPreview(scope.row)"
              >预览</el-link
            >
            <el-link type="primary" :underline="false" v-else disabled
              >预览</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          label="记录人"
          width="120"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.real_record_user_name }}
            <!-- {{ formateCreator(scope.row) }} -->
          </template>
        </el-table-column>
        <el-table-column
          label="记录人类型"
          width="100"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <!-- {{scope.row.real_record_user_name}} -->
            {{ formateUserType(scope.row) }}
          </template>
        </el-table-column>
      </el-table>
      <div class="operation">
        <div></div>
        <div class="block" style="margin-bottom: 0px">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <el-image-viewer
      v-if="showViewer"
      :url-list="imgs"
      :zIndex="999999999"
      :on-close="closeViewer"
    />
    <RecordPreview
      :isVisible="preDialog"
      @close="preDialog = false"
      :arr="url_arr"
    ></RecordPreview>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import RecordPreview from "@/components/DialogComponents/ClassService/RecordPreview.vue";

import { commonHandleLogList, learnManageRecordList } from "@/request/api";
export default {
  components: {
    TableTittle,
    ElImageViewer,
    RecordPreview,
  },
  data() {
    return {
      TableName: "记录列表",
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,

        // handle_type: 0,
      },
      pageTotal: 0,
      showViewer: false,
      imgs: [],
      preDialog: false,
      url_arr: [],
    };
  },
  created() {
    this.parseQuery();
    this.fetchList();
  },

  computed: {},
  methods: {
    handleToPreview(row) {
      this.preDialog = true;
      this.url_arr = JSON.parse(row.content_file);
    },
    openViewer(url) {
      this.imgs = url.map((v) => {
        return v.url;
      });
      this.showViewer = true;
    },

    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.imgs = url.map((v) => {
        return v.url;
      });
      this.showViewer = true;
    },

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.fetchList();
    },
    fetchList() {
      const params = Object.assign({}, this.query, {
        grade_id: this.$route.params.id,
      });
      learnManageRecordList(params).then((response) => {
        let res = response.data;

        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );

      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    getName(row) {
      if (row.grade_id) {
        return row.ref_grade_name;
      } else if (row.ref_lesson_log_grade_id) {
        return row.ref_lesson_log_grade_name;
      } else if (row.ref_course_plan_grade_id) {
        return row.ref_course_plan_grade_name;
      } else if (row.task_ref_course_plan_grade_id) {
        return row.task_ref_course_plan_grade_name;
      } else if (row.task_ref_grade_id) {
        return row.task_ref_grade_name;
      } else if (row.task_ref_allocation_order_grade_id) {
        return row.task_ref_allocation_order_grade_name;
      } else if (row.task_ref_lesson_log_grade_id) {
        return row.task_ref_lesson_log_grade_name;
      } else if (row.student_id) {
        return row.student_realname;
      } else {
        return "-";
      }
    },
    formateUserType(row) {
      switch (row.real_record_user_type) {
        case 1:
          return "学管师";
        case 2:
          return "匹配师";
        case 3:
          return "老师";
        case 4:
          return "学生";
        case 5:
          return "系统";
      }
    },
    formateType(row) {
      switch (row.core_type) {
        case 1:
          return "学员对接";
        case 2:
          return "师资匹配";
        case 3:
          return "师资确认";
        case 4:
          return "计划制定";
        case 5:
          return "上课提醒";
        case 6:
          return "课时确认";
      }
      // let types = {
      //   '0': '新建班级',
      //   '1': '编辑班级',
      //   '2': '学员加入',
      //   '3': '学员移除',
      //   '4': '老师加入',
      //   '5': '老师移除',
      //   '6': '课酬变更',
      //   '7': '终止班级'
      // }
      // return types[type]
    },
  },
};
</script>

<style lang="less" scoped>
.operation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.left {
  width: 90%;
  /*  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  text-align: left;
  line-height: 30px;
}
</style>
