<template>
  <div>
    <el-dialog
      top="5vh"
      title="修改备注"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="60px"
        size="small"
      >
        <el-form-item
          label="备注："
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model.trim="dataForm.remark"
            placeholder="请输入备注"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="handleToClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
  
  <script>



import {
  coursePlanOne,
  coursePlanEdit
} from "@/request/api";

import {
} from "@/utils/helper";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        reamark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }

    },
  },
  methods: {
    fetchDetail() {
      coursePlanOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data
      });
    },
    handleToClose() {
      this.$refs['formRules'].clearValidate();
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          const params = {
            id: this.dataForm.id,
            remark: this.dataForm.remark,
          }
          coursePlanEdit(params).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.handleToClose()
              this.$emit('submit')
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
  </script>
  
  <style lang="less" scoped>
</style>