<template>
    <div class="content">
        <el-dialog top="5vh" :title="title" :visible.sync="dialogFormVisible" @close="dialogFormVisible = false"
            :close-on-click-modal="false" width="620px">
            <el-form :model="dataForm" label-position="right" ref="dataForm" :rules="rules" label-width="100px"
                size="small">
                <el-form-item label="课程主题" prop="course_theme">
                    <el-input v-model.trim="dataForm.course_theme" placeholder="请在这里输入课程主题" clearable></el-input>
                </el-form-item>
                <el-form-item label="课程阶段" prop="course_stage">
                    <el-select v-model="dataForm.course_stage" placeholder="选择上课时间" clearable style="width: 100%;">
                        <el-option v-for="(item, index) in courseStageList" :key="index" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上课时间" prop="plan_start_time">
                    <el-date-picker :picker-options="pickerOptions" default-time="19:00:00" value-format="timestamp"
                        v-model="dataForm.plan_start_time" type="datetime" format="yyyy-MM-dd HH:mm" style="width: 100%;"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上课时长" prop="plan_lesson_hour">
                    <el-input v-model="dataForm.plan_lesson_hour" placeholder="输入上课时长" clearable>
                        <!-- class="inpBorder" -->
                        <template slot="append">
                            <div style="color: #606266;"> 小时</div>
                        </template>
                    </el-input>
                </el-form-item>

                <el-form-item label="备注" prop="remark" v-if="title == '编辑计划'">
                    <el-input type="textarea" placeholder="请输入备注" v-model="dataForm.remark">
                    </el-input>
                </el-form-item>
                <el-form-item label="">
                    <div class="hint">*此班级课程剩余未定计划课时为{{ totalClassHours }}小时</div>
                    <div class="hint red-hint" v-if="btnName == '取消并新建'">
                        *因涉及上课时间或时长修改，将取消本次计划并新建一个新的计划。
                    </div>
                </el-form-item>

            </el-form>
            <div class="footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="submit()" plain>
                    {{ btnName }}
                </el-button>
                <el-button type="primary" @click="nextSubmit()" v-if="title == '新建计划'">确定并创建下一条</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import { byTeacher, coursePlanEdit } from "@/request/api";

export default {
    data() {
        var validateHour = (rule, value, callback) => {
            if (value <= 0) {
                callback(new Error('上课时长不能小于0'));
            } else {
                callback();
            }
        }
        return {
            proIndex: '',
            propData: {},   //接受值
            btnName: '确定',
            title: '',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 86400000;
                }
            },
            dataForm: {
                "course_theme": "",
                "course_stage": "",
                "plan_lesson_hour": '',
                "plan_start_time": '',
                "remark": ''
            },
            courseStageList: [
                {
                    label: '基础阶段',
                    value: 0
                },
                {
                    label: '强化阶段',
                    value: 1
                },
                {
                    label: '冲刺阶段',
                    value: 2
                }

            ],
            dialogFormVisible: false,
            totalClassHours: 0,
            rules: {
                course_theme: [{ required: true, message: "课程主题不能为空", trigger: "blur" }],
                course_stage: [{ required: true, message: "课程阶段不能为空", trigger: "blur" }],
                plan_start_time: [{ required: true, message: "上课时间不能为空", trigger: "blur" }],
                plan_lesson_hour: [
                    { required: true, message: "上课时长不能为空", trigger: "blur" },
                    { validator: validateHour, trigger: 'blur' }
                ],
            }
        }
    },
    watch: {
        dataForm: {
            handler(newValue, oldValue) {
                if (this.title == '编辑计划' && this.propData.type && (newValue.plan_lesson_hour != this.propData.dataForm.plan_lesson_hour ||
                    newValue.plan_start_time != this.propData.dataForm.plan_start_time)) {
                    this.btnName = '取消并新建'
                    // console.log("12")
                } else {
                    this.btnName = '确定'
                }
            },
            deep: true
        }
    },
    created() {

    },
    methods: {

        updateHour(hour) {
            // console.log("hour", hour)
            this.totalClassHours = hour
        },
        open(data) {
            // console.log("Data", data)
            this.totalClassHours = data.totalClassHours

            this.propData = JSON.parse(JSON.stringify(data))

            if (this.propData.proIndex !== null && this.propData.proIndex !== undefined && this.propData.proIndex !== '') {
                // console.log("c1")
                this.proIndex = this.propData.proIndex
                this.totalClassHours = (Number(this.totalClassHours) + Number(this.propData.dataForm.plan_lesson_hour)).toFixed(2)
            } else {
                // console.log("c2")
                this.proIndex = ''
            }

            if (data.dataForm) {
                this.dataForm = {
                    "course_theme": data.dataForm.course_theme,
                    "course_stage": data.dataForm.course_stage,
                    "plan_lesson_hour": data.dataForm.plan_lesson_hour,
                    "plan_start_time": data.dataForm.plan_start_time,
                    id: data.dataForm.id || '',
                    "remark": data.dataForm.remark || ''
                }
                this.title = '编辑计划'
            } else {
                this.dataForm = {
                    "course_theme": "",
                    "course_stage": "",
                    "plan_lesson_hour": '',
                    "plan_start_time": '',
                }
                this.title = '新建计划'
            }
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs.dataForm.clearValidate();//清空表单
            })
            // console.log("数量",this.proIndex)
        },
        // 单独添加
        aloneSubmit(type) {
            byTeacher({
                grade_id: this.propData.grade_id,
                teacher_id: this.propData.teacher_id,
                is_test_data: 1,
                arr_data: [this.dataForm]
            }).then(res => {
                if (res.data.code == 0) {
                    if (res.data.data.error && res.data.data.error.error_data_list.length > 0) {
                        // console.log("Ccc", res.data.data.error.error_data_list[0].error_reason)
                        this.$message({
                            type: 'error',
                            message: res.data.data.error.error_data_list[0].error_reason
                        });
                        if (type == 'cancel-new') {
                            this.btnName = '确定'
                            this.title = "新建计划"
                            this.$emit("submit");
                        }
                    } else {
                        // 编辑
                        if (this.propData.type && (this.dataForm.plan_lesson_hour != this.propData.dataForm.plan_lesson_hour ||
                            this.dataForm.plan_start_time != this.propData.dataForm.plan_start_time)) {
                            byTeacher({
                                grade_id: this.propData.grade_id,
                                teacher_id: this.propData.teacher_id,
                                arr_data: [this.dataForm]
                            }).then(res => {
                                if (res.data.code == 0) {
                                    // if (type == 'next') {
                                    //     this.dataForm = {
                                    //         "course_theme": "",
                                    //         "course_stage": "",
                                    //         "plan_lesson_hour": '',
                                    //         "plan_start_time": '',
                                    //     }
                                    // } else {
                                    //     this.dialogFormVisible = false
                                    // }
                                    this.dialogFormVisible = false
                                    this.$message({
                                        type: 'success',
                                        message: '创建成功!'
                                    });
                                    this.$emit("refresh");
                                    this.$emit("updateHour");
                                }

                            })
                        } else {
                            this.$emit("submit", [this.dataForm]);
                            this.$emit("updateHour");
                            if (type == 'next') {
                                this.dataForm = {
                                    "course_theme": "",
                                    "course_stage": "",
                                    "plan_lesson_hour": '',
                                    "plan_start_time": '',
                                }
                            } else {
                                this.dialogFormVisible = false
                            }

                            // this.$message({
                            //     type: 'success',
                            //     message: '创建成功!'
                            // });


                        }



                    }
                }
                // console.log("Res", res)
            })
        },
        cancelAndNew() {
            coursePlanEdit({
                id: this.propData.dataForm.id,
                plan_status: 6
            }).then(res => {
                if (res.data.code == 0) {
                    this.aloneSubmit('cancel-new')
                }
            })
        },
        submit() {
            if (Number(this.dataForm.plan_lesson_hour) > Number(this.totalClassHours)) {
                this.$message({
                    type: 'error',
                    message: '剩余课时不足'
                });
                return
            }
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    if (this.title == '新建计划') {
                        this.aloneSubmit()
                    } else {
                        // console.log("1")
                        if (this.propData.type && (this.dataForm.plan_lesson_hour != this.propData.dataForm.plan_lesson_hour ||
                            this.dataForm.plan_start_time != this.propData.dataForm.plan_start_time)) {
                            // console.log("2")
                            this.cancelAndNew()
                        } else {
                            // console.log("3",this.proIndex)
                            if (this.proIndex !== null && this.proIndex !== undefined && this.proIndex !== '') {
                                this.$emit("submit", {
                                    obj: this.dataForm,
                                    proIndex: this.proIndex
                                });
                                this.$emit("updateHour");
                                this.dialogFormVisible = false
                            } else {
                                // console.log("5",this.dataForm,this.propData)
                                // return
                                if(this.propData.dataForm.plan_status == 5){
                                    this.dataForm.plan_status = 0
                                }
                               
                                coursePlanEdit(this.dataForm).then(res => {
                                    if (res.data.code == 0) {
                                        this.dialogFormVisible = false
                                        this.$message({
                                            type: 'success',
                                            message: '修改成功!'
                                        });
                                        this.$emit("refresh");
                                        this.$emit("updateHour");
                                    }
                                })
                            }

                        }


                    }

                }
            })

        },
        nextSubmit() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    this.aloneSubmit('next')
                }
            })

        }
    }
}
</script>

<style lang="less" scoped>
.hint {
    // margin-top: -10px;
    font-size: 12px;
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    font-weight: normal;
    color: #909399;
    line-height: 20px;
}

.red-hint {
    color: #E34D59;
}

.inpBorder {
    /deep/.el-input__inner {
        border-color: #E34D59 !important;
    }
}

.color-E34D59 {
    color: #E34D59;
}
</style>