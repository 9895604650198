<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item>资料查询</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button type="primary" size="small" @click="openCreateDialog"
          >新建</el-button
        >
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="年份：">
          <el-select
            v-model="query.years"
            placeholder="请选择年份"
            clearable
            @change="fetchList(true)"
            style="width: 100%"
          >
            <el-option label="2021" :value="2021"> </el-option>
            <el-option label="2022" :value="2022"> </el-option>
            <el-option label="2023" :value="2023"> </el-option>
            <el-option label="2024" :value="2024"> </el-option>
            <el-option label="2025" :value="2025"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="院校：" prop="name">
          <el-input
            v-model="query.college_keyword"
            placeholder="请输入院校名称/代码"
            style="width: 100%"
            clearable
            @input="fetchList(true)"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="学院：" prop="name">
          <el-input
            v-model="query.academy_keyword"
            placeholder="请输入学院名称/代码"
            style="width: 100%"
            @input="fetchList(true)"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="文件类型：">
          <el-select
            v-model="query.types"
            placeholder="请选择文件类型"
            clearable
            @change="fetchList(true)"
            style="width: 100%"
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item, index) in filters.types"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建人：">
          <el-select
            @change="fetchList(true)"
            v-model="manage_creators"
            multiple
            placeholder="请选择"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in userList"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="创建时间：">
          <el-date-picker
            @change="fetchList(true)"
            class="datetime"
            v-model="time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            clearable
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table
        :data="result"
        :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
        :cell-style="{ fontSize: '13px' }"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
          fixed="left"
        >
        </el-table-column>
        <el-table-column prop="id" label="ID" align="center" width="80">
        </el-table-column>

        <el-table-column label="年份" align="center" width="100">
          <template slot-scope="scope"> {{ scope.row.year }}年 </template>
        </el-table-column>
        <el-table-column
          prop="college_name"
          label="院校名称"
          align="center"
          show-overflow-tooltip
          width="150"
        >
          <template slot-scope="scope">
            <div class="pointer" @click="institutionOpen(scope.row)">
              {{ scope.row.college_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="academy_name"
          label="学院名称"
          align="left"
          show-overflow-tooltip
          width="200"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.academy_code && scope.row.academy_name"
              >({{ scope.row.academy_code }}){{ scope.row.academy_name }}</span
            >
            <span v-else>不限</span>
          </template>
        </el-table-column>
        <el-table-column label="文件类型" align="center" width="150">
          <template slot-scope="scope">
            {{ formateType(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="资料名称"
          align="left"
          show-overflow-tooltip
          width="400"
        >
        </el-table-column>
        <el-table-column label="创建人" align="center" width="120">
          <template slot-scope="scope">
            {{
              scope.row.manage_creator ? scope.row.manage_creator.nickname : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="修改时间" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="180">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToPreview(scope.row)"
              >预览</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              @click="download(scope.row)"
              >下载</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              @click="handleToEdit(scope.row.id)"
              >编辑</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              @click="handleToContrast(scope.row)"
              >对比</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="operation">
        <div>
          <el-button size="mini" type="danger" @click="handleToDeleteAll()"
            >批量删除</el-button
          >
        </div>

        <div class="block" style="margin-bottom: 0px">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <EditData
      :id="editId"
      :isVisible="editDialog"
      @submit="fetchList"
      @close="editDialog = false"
    ></EditData>
    <AddData
      :isVisible="createDialog"
      @submit="fetchList"
      @close="createDialog = false"
    ></AddData>
    <el-image-viewer
      v-if="showViewer"
      :url-list="tableImg"
      :on-close="closeViewer"
    />
    <PreviewWeb
      :id="previewId"
      :isVisible="previewDialog"
      @close="previewDialog = false"
    ></PreviewWeb>
    <Contrast
      :data="ContrastData"
      :isVisible="contrastDialog"
      @close="contrastDialog = false"
    ></Contrast>
    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>
  </div>
</template>
      
<script>
let baseurl = `${window.location.protocol}//view.officeapps.live.com/op/view.aspx?src=`;

import TableTittle from "@/components/TableTittle/TableTittle.vue";
import AddData from "@/components/DialogComponents/TeacherResources/AddData.vue";
import EditData from "@/components/DialogComponents/TeacherResources/EditData.vue";
import PreviewWeb from "@/components/DialogComponents/TeacherResources/PreviewWeb.vue";
import Contrast from "@/components/DialogComponents/TeacherResources/Contrast.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import InstitutionDetails from "@/components/DialogComponents/InstitutionDetails/index.vue";

import { materiallist, materiallistDelete, userlist } from "@/request/api";
export default {
  components: {
    TableTittle,
    AddData,
    EditData,
    ElImageViewer,
    PreviewWeb,
    Contrast,
    InstitutionDetails,
  },
  data() {
    return {
      TableName: "资料列表",
      result: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      time: [],
      manage_creators: [],
      pageTotal: 0,
      chooseList: [],
      editDialog: false,
      editId: null,
      recordDialog: false,
      createDialog: false,
      tableImg: [],
      showViewer: false,
      previewId: null,
      previewDialog: false,
      userList: [],
      filters: {
        types: [
          {
            label: "录取名单",
            value: 1,
          },
          {
            label: "参考书目",
            value: 2,
          },
          {
            label: "专业目录",
            value: 3,
          },
          {
            label: "招生简章",
            value: 4,
          },
          {
            label: "考试大纲",
            value: 5,
          },
          {
            label: "分数线",
            value: 6,
          },
          {
            label: "复试方案",
            value: 7,
          },
          {
            label: "调剂信息",
            value: 8,
          },
          {
            label: "复试名单",
            value: 9,
          },
        ],
      },
      ContrastData: {},
      contrastDialog: false,
      baseurl: baseurl,
      createDalog: false,
    };
  },
  created() {
    this.getUserlist();
    this.parseQuery();
    this.fetchList();
    this.$store.dispatch("getAllColleges");
  },
  computed: {},
  methods: {
    download(row) {
      if (row.content) {
        console.log("row.content:");
        let done = JSON.parse(row.content)[0];
        let url = done.url; //这里是后台返回的url
        let a_link = document.createElement("a");
        // 这里是将url转成blob地址，
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            // 将链接地址字符内容转变成blob地址
            a_link.href = URL.createObjectURL(blob);
            console.log(a_link.href);
            a_link.download = done.name  //下载的文件的名字
            document.body.appendChild(a_link);
            a_link.click();
          });
      }
    },
    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.college_code);
    },
    getUserlist() {
      userlist({
        isPaging: 0,
      }).then((res) => {
        if (res.data.code == 0) {
          this.userList = res.data.data.list;
        }
        // console.log("用户", res)
      });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.fetchList();
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      if (params.types) {
        params.types = params.types.join(",");
      }
      if (this.time && this.time.length > 0) {
        params.start_create_time = this.time[0];
        params.end_create_time = this.time[1];
      }
      params.manage_creators = this.manage_creators.toString();
      materiallist(params).then((response) => {
        let res = response.data;
        this.result = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
      if (this.query.types && this.query.types.length) {
        this.query.types = this.query.types.split(",").map((v) => {
          return Number(v);
        });
      }
    },

    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      if (params.types) {
        params.types = params.types.filter((v) => v).join(",");
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    handleSelectionChange(val) {
      this.chooseList = val;
    },
    handleToEdit(id) {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      this.showOverflowTooltip = true;
      if (!user) {
        this.$confirm("请先登录后再继续操作", "提示", {
          confirmButtonText: "去登陆",
          cancelButtonText: "稍后",
          type: "warning",
        }).then(() => {
          this.$router.replace("/login");
        });
        return;
      }
      this.editId = id;
      this.editDialog = true;
    },
    formateType(row) {
      let types = {
        1: "录取名单",
        2: "参考书目",
        3: "专业目录",
        4: "招生简章",
        5: "考试大纲",
        6: "分数线",
        7: "复试方案",
        8: "调剂信息",
        9: "复试名单",
      };
      return types[row.type];
    },
    handleToDeleteAll() {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      if (!user) {
        this.$confirm("请先登录后再继续操作", "提示", {
          confirmButtonText: "去登陆",
          cancelButtonText: "稍后",
          type: "warning",
        }).then(() => {
          this.$router.replace("/login");
        });
        return;
      }
      if (this.chooseList.length == 0) {
        this.$message.error("还没有选择要删除的数据");
        return;
      }
      this.$confirm("确定要删除这些信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map((v) => {
          return v.id;
        });
        materiallistDelete({ ids }).then((response) => {
          let res = response.data;
          if (res.code == 0) {
            this.fetchList();
          }
        });
      });
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.tableImg = [url];
      this.showViewer = true;
    },
    handleToPreview(row) {
      if (row.content_type == 1) {
        if (row.content.includes("http")) {
          // console.log("11")
          window.open(row.content);
        } else {
          // console.log("2")
          window.open(window.location.protocol + "//" + row.content);
        }
      } else {
        this.previewId = row.id;
        this.previewDialog = true;
      }
      /*    else if (row.content_type == 2) {
           let url = JSON.parse(row.content)[0].url
           window.open(url)
         } else if (row.content_type == 3) {
           this.tableImg = JSON.parse(row.content).map(v => {
             return v.url
           });
           this.showViewer = true;
         } */
    },
    openCreateDialog() {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      if (!user) {
        this.$confirm("请先登录后再继续操作", "提示", {
          confirmButtonText: "去登陆",
          cancelButtonText: "稍后",
          type: "warning",
        }).then(() => {
          this.$router.replace("/login");
        });
      } else {
        this.createDialog = true;
      }
    },
    handleToContrast(row) {
      this.ContrastData = Object.assign({}, row);
      this.contrastDialog = true;
    },
  },
};
</script>
      
<style lang="less" scoped>
.content {
  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }
}
</style>
      