<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item>计划管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button icon="el-icon-download"
                   @click="handleExport"
                   size="small">导出</el-button>
        <el-button type="primary"
                   size="small"
                   @click="createDialog=true">新建</el-button>

      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="上课时间">
          <el-date-picker class="datetime"
                          v-model="plan_start_time"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="fetchList(true)"
                          clearable
                          :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="完成时间">
          <el-date-picker class="datetime"
                          v-model="finish_time"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="fetchList(true)"
                          clearable
                          :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="课时主题">
          <el-input v-model.trim="query.course_theme"
                    placeholder="请输入计划课时主题"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="计划状态">
          <el-select v-model="plan_statuses"
                     placeholder="请选择"
                     @change="plan_statusesChange"
                     clearable>
            <el-option v-for="(item,index) in filters.plan_status"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级">
          <el-input v-model.trim="query.grade_keyword"
                    placeholder="请输入班级关键字"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="老师">
          <el-input v-model.trim="query.teacher_keyword"
                    placeholder="请输入老师关键字"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="学管师">
          <el-select v-model="query.learn_manager"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option v-for="item in $store.state.staffOptions.filter(v=>{
                return v.depts.findIndex(t=>{
                  return t.id==4 && v.status == 1
                })!=-1
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model.trim="query.remark_keyword"
                    placeholder="请输入备注"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form size="small"
                 :inline="true">
          <el-form-item label="只看关注"
                        label-width="120">
            <el-switch v-model="query.follow"
                       :active-value="1"
                       inactive-value=" "
                       @change="fetchList(true)">
            </el-switch>
          </el-form-item>
          <el-form-item label-width="32px">
            <el-radio-group v-model="plan_statuses"
                            @change="fetchList(true)">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button :label="1">上课中</el-radio-button>
              <el-badge :value="count_plan_uncheck"
                        class="item"
                        :hidden="count_plan_uncheck==0">
                <el-radio-button :label="0">待审核</el-radio-button>
              </el-badge>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{  fontSize: '13px' }"
                border>
        <el-table-column prop="id"
                         label="ID"
                         width="80"
                         fixed="left"
                         align="center">
        </el-table-column>
        <el-table-column prop="date"
                         label="关注"
                         width="50"
                         fixed="left"
                         align="center">
          <template slot-scope="scope">
            <img alt=""
                 srcset=""
                 class="star"
                 src="@/assets/images/star-fill.png"
                 @click="followDelete(scope.row)"
                 v-show="scope.row.follow" />
            <img src="@/assets/images/star.png"
                 alt=""
                 srcset=""
                 class="star"
                 v-show="!scope.row.follow"
                 @click="followCreate(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column prop="course_theme"
                         label="计划上课时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.plan_start_time?$moment(scope.row.plan_start_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column prop="course_theme"
                         label="课时主题"
                         width="200"
                         align="left"
                         show-overflow-tooltip>
        </el-table-column>
        <!--     <el-table-column
          prop="course_content"
          label="内容简介"
          width="300"
          align="left"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <el-table-column width="120"
                         show-overflow-tooltip
                         align="center"
                         label="计划时长">
          <template slot-scope="scope">
            <div>{{scope.row.plan_lesson_hour}}小时</div>
          </template>
        </el-table-column>
        <el-table-column label="计划状态"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.plan_status==0"
                    size="mini"
                    type="warning">待审核</el-tag>
            <el-tag v-if="scope.row.plan_status==1"
                    size="mini">待上课</el-tag>

            <el-tag v-if="scope.row.plan_status==20"
                    size="mini"
                    type="warning">待上课</el-tag>
            <el-tag v-if="scope.row.plan_status==21"
                    size="mini"
                    type="warning">待提交</el-tag>
            <el-tag v-if="scope.row.plan_status==22"
                    size="mini"
                    type="danger">已超期</el-tag>
            <el-tag v-if="scope.row.plan_status==40"
                    size="mini"
                    type="error">待确认</el-tag>
            <el-tag v-if="scope.row.plan_status==41"
                    size="mini"
                    type="warning">待回访</el-tag>
            <el-tag v-if="scope.row.plan_status==42"
                    size="mini"
                    type="danger">已拒绝</el-tag>
            <el-tag v-if="scope.row.plan_status==43"
                    size="mini"
                    type="success">已完成</el-tag>
            <el-tag v-if="scope.row.plan_status==45"
                    size="mini"
                    type="success">已完结</el-tag>
            <el-tag v-if="scope.row.plan_status==46"
                    size="mini"
                    type="info">已暂停</el-tag>
            <el-tag v-if="scope.row.plan_status==5"
                    size="mini"
                    type="danger">已拒绝</el-tag>
            <el-tag v-if="scope.row.plan_status==6"
                    size="mini"
                    type="info">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="remark"
                         label="备注"
                         align="center"
                         width="300"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="handleToEditRemark(scope.row)"
                 v-if="scope.row.remark"
                 style="cursor: pointer;">{{scope.row.remark}}</div>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToEditRemark(scope.row)"
                     v-else>添加备注</el-link>
          </template>
        </el-table-column>
        <el-table-column label="上课学员"
                         align="center"
                         width="220"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{formateMember(scope.row.student_realnames)}}
          </template>
        </el-table-column>
        <el-table-column label="授课老师"
                         align="center"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.teacher_realname}}
          </template>
        </el-table-column>
        <el-table-column prop="grade_name"
                         label="所在班级"
                         width="260"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="课程阶段"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{formateCourse_stage(scope.row.course_stage)}}
          </template>
        </el-table-column>
        <el-table-column prop="course_target"
                         label="课程目标"
                         width="200"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="references"
                         label="参考资料"
                         width="200"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="学管师"
                         align="center"
                         width="130">
          <template slot-scope="scope">
            {{scope.row.learn_manager?scope.row.learn_manager.nickname:""}}
          </template>
        </el-table-column>
        <el-table-column label="完成日期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.log_learn_manager_confirm_time?$moment(scope.row.log_learn_manager_confirm_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column label="创建日期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column label="修改日期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="操作"
                         width="200"
                         align="center"
                         fixed="right">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToCalendar(scope.row)"
                     v-if="scope.row.plan_status==0">查看详情</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToDetail(scope.row)"
                     v-if="[20,21,22,40,41,43,45,46].includes(scope.row.plan_status)">查看详情</el-link>
            <el-link type="danger"
                     :underline="false"
                     @click="handleToRemove(scope.row)"
                     v-if="[5,6,42].includes(scope.row.plan_status)">删除</el-link>
            <!-- <el-link
              type="success"
              :underline="false"
              @click="handleToInvite(scope.row)"
              v-if="[21,22,40].includes(scope.row.plan_status)"
            >邀请确认</el-link> -->
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <AddCoursePlan :isVisible="createDialog"
                   @close="createDialog=false"
                   @submit="fetchList()" />
    <RefuseDialog :isVisible="refuseDialog"
                  @close="refuseDialog=false"
                  @submit="refuseSubmit" />
    <PlanDetail :isVisible="detailDialog"
                @close="detailDialog=false"
                :id="detailId"
                @submit="fetchList()" />
    <InviteToConfirm :isVisible="InviteToConfirmDialog"
                     @close="InviteToConfirmDialog=false"
                     :id="confirmId" />
    <EditPlanRemark :isVisible="remarkDialog"
                    @close="remarkDialog=false"
                    :id="remarkId"
                    @submit="fetchList()"></EditPlanRemark>
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import AddCoursePlan from '@/components/DialogComponents/ClassService/AddCoursePlan.vue'
import RefuseDialog from '@/components/DialogComponents/RefuseDialog.vue'
import PlanDetail from '@/components/DialogComponents/ClassService/PlanDetail.vue'
import InviteToConfirm from '@/components/DialogComponents/ClassService/InviteToConfirm.vue'
import EditPlanRemark from '@/components/DialogComponents/ClassService/EditPlanRemark.vue'

import {
  coursePlanlist,
  coursePlanCheck,
  coursePlanDelete,
  createuserfollow,
  deleteuserfollow,
  coursePlanExport,
} from '@/request/api'
export default {
  components: {
    TableTittle,
    AddCoursePlan,
    RefuseDialog,
    PlanDetail,
    InviteToConfirm,
    EditPlanRemark,
  },
  data() {
    return {
      finish_time: '',
      TableName: '计划列表',
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      infoDialog: false,
      createDialog: false,
      refuseId: null,
      refuseDialog: false,
      plan_start_time: '',
      filters: {
        plan_status: [
          /*     { label: "待审核", value: 0 },
              { label: "待上课", value: 1 },
              { label: "待确认", value: 2 },
              { label: "已确认", value: 3 },
              { label: "已完成", value: 4 },
              { label: "已拒绝", value: 5 }, */
          { label: '待审核', value: 0 },
          { label: '已通过', value: 99 },
          { label: '已拒绝（计划）', value: 5 },
          { label: '已取消', value: 6 },
          { label: '空状态', value: 7 },
          { label: '待上课', value: 20 },
          { label: '待提交', value: 21 },
          { label: '已超期', value: 22 },
          { label: '待确认', value: 40 },
          { label: '待回访', value: 41 },
          { label: '已拒绝（课时）', value: 42 },
          { label: '已完成', value: 43 },
          { label: '已完结', value: 45 },
          { label: '已暂停', value: 46 },
          { label: '上课中', value: 1 },
        ],
      },
      plan_statuses: 1,
      unitConversion: 1,
      popoverVisble: false,
      statusCount: 0,
      detailDialog: false,
      detailId: null,
      count_plan_uncheck: 0,
      InviteToConfirmDialog: false,
      confirmId: null,
      lock: true,
      remarkDialog: false,
      remarkId: null,
    }
  },
  created() {
    this.parseQuery()
    this.fetchList(true)
    this.fetchCount()

    this.$store.dispatch('getStaffOptions')
  },
  watch: {
    // 时间数据监听
    plan_start_time(value) {
      if (value) {
        this.query.start_plan_start_time = this.$moment(value[0]).valueOf()
        this.query.end_plan_start_time = this.$moment(value[1]).valueOf()
      } else {
        this.query.start_plan_start_time = ''
        this.query.end_plan_start_time = ''
        this.fetchList(true)
      }
    },
    finish_time(value) {
      if (value) {
        this.query.start_log_learn_manager_confirm_time = this.$moment(
          value[0]
        ).valueOf()
        this.query.end_log_learn_manager_confirm_time = this.$moment(
          value[1]
        ).valueOf()
      } else {
        this.query.start_log_learn_manager_confirm_time = ''
        this.query.end_log_learn_manager_confirm_time = ''
        this.fetchList(true)
      }
    },
  },
  computed: {},
  methods: {
    fetchCount() {},
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    fetchCheckCount() {
      coursePlanlist({
        plan_statuses: 0,
        isPaging: 1,
      }).then((response) => {
        let res = response.data
        this.count_plan_uncheck = res.data.count_plan_uncheck
      })
    },
    fetchList(isReset = false) {
      this.fetchCheckCount()
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)
      if (this.plan_statuses == 1) {
        params.plan_statuses = '21,22,40'
      } else if (this.lesson_statuses == 99) {
        params.lesson_statuses = '1'
      } else {
        params.plan_statuses = this.plan_statuses
      }

      coursePlanlist(params).then((response) => {
        let res = response.data
        this.tableData = res.data.list || []
        this.pageTotal = res.data.count
      })
      this.syncQuery()
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.plan_statuses && this.query.plan_statuses.length) {
        this.query.plan_statuses = this.query.plan_statuses
          .split(',')
          .map((v) => {
            return Number(v)
          })
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      if (params.plan_statuses) {
        params.plan_statuses = params.plan_statuses.filter((v) => v).join(',')
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    formateMember(val) {
      if (val) {
        let val_arr = val.split(',')
        if (val_arr.length > 3) {
          return val.split(',').slice(0, 3).join('，') + '等'
        } else {
          return val.split(',').slice(0, 3).join('，')
        }
      } else {
        return '无'
      }
    },
    formateCourse_stage(val) {
      let stages = {
        0: '基础',
        1: '强化',
        2: '冲刺',
      }
      return stages[val]
    },

    handleToAccept(row) {
      this.$confirm('确定要通过此条信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let query = {
          id: row.id,
          is_access: 1,
        }
        coursePlanCheck(query).then((res) => {
          this.$nextTick(() => {
            this.fetchList(true)
          })
        })
      })
    },
    handleToRefuse(row) {
      this.refuseId = row.id
      this.refuseDialog = true
    },
    refuseSubmit({ text }) {
      let query = {
        id: this.refuseId,
        is_access: 0,
        refuse_reason: text,
      }
      coursePlanCheck(query).then((res) => {
        this.$nextTick(() => {
          this.fetchList(true)
        })
      })
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    },
    handleToRemove(row) {
      this.$confirm('确定要删除此班级吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        coursePlanDelete({ id: row.id }).then((res) => {
          this.fetchList(true)
        })
      })
    },
    handleToDetail(row) {
      this.detailDialog = true
      this.detailId = row.id
    },
    handleToCalendar(row) {
      window.open(
        `/classservice/plan/calendar?grade_id=${row.grade_id}&isOpen=true&time=${row.plan_start_time}&plan_id=${row.id}`
      )
      /*       this.$router.push(`/classservice/plan/calendar?grade_id=${row.grade_id}&isOpen=true&time=${row.plan_start_time}&plan_id=${row.id}`) */
    },
    handleToImport() {
      this.$router.push('/classservice/plan/import')
    },
    plan_statusesChange(e) {
      this.fetchList(true)
    },
    handleToInvite(row) {
      this.InviteToConfirmDialog = true
      this.confirmId = row.id
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 6,
          follow_id: item.id,
        }
        createuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 500)
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 6,
          follow_id: item.id,
        }
        deleteuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 300)
      }
    },
    handleToEditRemark(row) {
      console.log(row)
      this.remarkDialog = true
      this.remarkId = row.id
    },
    handleExport() {
      this.$confirm('是否导出数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = Object.assign({}, this.query)
        console.log('1111', params)
        if (this.plan_statuses == 1) {
          params.plan_statuses = '21,22,40'
        } else if (this.lesson_statuses == 99) {
          params.lesson_statuses = '1'
        } else {
          params.plan_statuses = this.plan_statuses
        }

        coursePlanExport(params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success('导出成功')
            //创建a标签用于跳转至下载链接
            var str = res.data.data.url.replace(
              /http:/,
              window.location.protocol
            )
            this.$store.commit('downLoad', str)
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  .star {
    width: 18px;
    height: 18px;
  }
}
</style>
