<template>
  <div>
    <el-dialog
top="5vh"
      title="评价与反馈"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="110px"
        size="small"
      >
        <el-form-item
          label="评价与反馈："
          prop="learn_manager_course_feedback_content"
        >
          <el-input
            type="textarea"
            v-model="dataForm.learn_manager_course_feedback_content"
            placeholder="请输入评价与反馈"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="查看范围："
          prop="learn_manager_course_feedback_area"
        >
          <el-radio-group v-model="dataForm.learn_manager_course_feedback_area">
            <el-radio :label="3">仅内部</el-radio>
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="2">仅学生</el-radio>
            <el-radio :label="4">仅老师</el-radio>
            <el-radio :label="5">仅对方</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="对象："
          prop="target"
          v-if="dataForm.learn_manager_course_feedback_area==5"
        >
          <el-select
            v-model="dataForm.target"
            placeholder="请选择院校"
          >
            <el-option
              v-for="(item,index) in members"
              :key="index"
              :label="item.name"
              :value="{ value: item.target_user_id, label: item.name,type:item.target_user_type}"
            >
              {{item.name}} （{{item.target_user_type==0?'学生':'老师'}}）
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>

      </div>
    </el-dialog>
  </div>
</template>

<script>



import {
  courseFeedback,
  lessonLogOne,
  handleLogCreate
} from "@/request/api";
import {
  getStorage
} from "@/utils/helper";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    course_plan_id: {
      type: Number,
      default: () => null,
    },
    lesson_log_id: {
      type: String,
      default: () => null,
    }
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
        learn_manager_course_feedback_area: 3
      },
      rules: {
        learn_manager_course_feedback_content: [{ required: true, message: "请输入评价与反馈", trigger: "blur" }],
        learn_manager_course_feedback_area: [
          { required: true, message: "请选择查看范围", trigger: "change" },
        ],
        target: [
          { required: true, message: "请选择对象", trigger: "blur" },
        ],
      },
      members: [],
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {

    emitClose() {
      this.$refs['formRules'].clearValidate();
      this.$emit("close");
    },
    fetchDetail() {
      lessonLogOne({ id: this.lesson_log_id }).then((response) => {
        let res = response.data
        this.members = this.members.concat(res.data.lesson_students.map((v) => {
          return {
            target_user_type: 0,
            target_user_id: v.student_id,
            name: v.student.realname

          }
        }))
        this.members.push({
          target_user_type: 1,
          target_user_id: res.data.teacher_id,
          name: res.data.lesson_teacher.teacher.realname

        })
      });
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            lesson_log_id: this.lesson_log_id,
            handle_area: this.dataForm.learn_manager_course_feedback_area,
            handle_content: this.dataForm.learn_manager_course_feedback_content,
            handle_type: 6,
          }
          if (this.dataForm.target && this.dataForm.target.type == 0) {
            query.target_user_type = this.dataForm.target.type
            query.target_student_id = this.dataForm.target.value
          } else if (this.dataForm.target && this.dataForm.target.type == 1) {
            query.target_user_type = this.dataForm.target.type
            query.target_teacher_id = this.dataForm.target.value
          }
          handleLogCreate(query).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.emitClose()
              this.$emit('submit')
            }
          });
          /*   let query = {
              course_plan_id: this.course_plan_id,
              user_type: 'learn_manager',
              learn_manager: getStorage('userInfo').id,
              learn_manager_course_feedback_content: this.dataForm.learn_manager_course_feedback_content,
              learn_manager_course_feedback_area: this.dataForm.learn_manager_course_feedback_area,
            }
            courseFeedback(query).then((response) => {
              let res = response.data
              if (res.code == 0) {
                this.emitClose()
                this.$emit('submit')
              }
            }); */
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
</script>

<style lang="less" scoped>
.tips {
  font-size: 14px;
  color: #909399;
  line-height: 16px;
}
</style>