var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"top":"5vh","title":"分配订单","visible":_vm.dialogFormVisible,"close-on-click-modal":false,"width":"657px"},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.handleToClose}},[_c('el-form',{ref:"formRules",attrs:{"model":_vm.dataForm,"label-position":"left","rules":_vm.rules,"label-width":"100px","size":"small"}},[_c('el-form-item',{attrs:{"label":"匹配师：","prop":"allocation_manage_ids"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","clearable":"","multiple":"","filterable":""},on:{"change":function($event){return _vm.$forceUpdate()}},model:{value:(_vm.dataForm.allocation_manage_ids),callback:function ($$v) {_vm.$set(_vm.dataForm, "allocation_manage_ids", $$v)},expression:"dataForm.allocation_manage_ids"}},_vm._l((_vm.$store.state.staffOptions.filter((v) => {
              return (
                v.depts.findIndex((t) => {
                  return t.id == 5 && v.status == 1;
                }) != -1
              );
            })),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.nickname,"value":item.id}})}),1)],1)],1),_c('div',{staticClass:"footer"},[_c('el-button',{staticStyle:{"margin-right":"auto"},attrs:{"slot":"","type":"primary"},on:{"click":_vm.getConfigureList},slot:"default"},[_vm._v("推荐匹配师")]),_c('el-button',{on:{"click":function($event){return _vm.handleToClose()}}},[_vm._v("取消")]),_c('el-button',{attrs:{"slot":"","type":"primary"},on:{"click":function($event){return _vm.submit('formRules')}},slot:"default"},[_vm._v("确定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }