<template>
  <div class="headers">
    <!-- 折叠按钮 -->
    <div class="logo_img" @click="collapseChage">
      <i v-if="!collapse" class="el-icon-s-fold"></i>
      <i v-else class="el-icon-s-unfold"></i>
    </div>
    <div class="logo">研盒机构服务系统</div>
    <!--     <div class="collapse-btn" @click="collapseChage">
      <i v-if="!collapse" class="el-icon-s-fold"></i>
      <i v-else class="el-icon-s-unfold"></i>
    </div> -->
    <div class="header-right">
      <div class="header-user-con">
        <!-- 全屏显示 -->
        <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip
            effect="dark"
            :content="fullscreen ? `取消全屏` : `全屏`"
            placement="bottom"
          >
            <i class="el-icon-rank"></i>
          </el-tooltip>
        </div>
        <!-- 用户头像 -->
        <div class="user-avator" v-if="userInfo">
          <img src="../../assets/images/avatar.png" alt="" srcset="" />
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link user-dropdown">
              {{ userInfo.nickname
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="editpwd">修改密码</el-dropdown-item>
              <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <EditPassword
      :isVisible="EditPasswordDialog"
      @close="EditPasswordDialog = false"
    ></EditPassword>
  </div>
</template>
<script>
import EditPassword from "../../components/LoginComponents/EditPassword/EditPassword.vue";
export default {
  components: {
    EditPassword,
  },
  data() {
    return {
      collapse: true,
      fullscreen: false,
      name: "lofreer",
      message: 2,
      EditPasswordDialog: false,
    };
  },
  computed: {
    userInfo: function () {
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    // 侧边栏折叠
    collapseChage() {
      this.collapse = !this.collapse;
      this.$emit("collapse", this.collapse);
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    handleCommand(command) {
      switch (command) {
        case "loginout":
          /*      adminLogout().then((res) => {
            removeStorage("AccountInfo");
            removeStorage("cid");
            removeStorage("token");
            removeStorage("userInfo");
            this.$router.push("/login");
          }); */
          localStorage.removeItem("AccountInfo");
          localStorage.removeItem("cid");
          localStorage.removeItem("token");
          localStorage.removeItem("userInfo");
          this.$router.push("/login");
          break;
        case "editpwd":
          this.EditPasswordDialog = !this.EditPasswordDialog;
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  },
};
</script>
<style  scoped lang="less">
.headers {
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  font-size: 22px;
  color: #fff;
  z-index: 999;
  background-color: #525c8f;
  box-shadow: 0px 8px 16px rgba(57, 65, 103, 0.1);
}
.collapse-btn {
  float: left;
  padding: 0 16px;
  cursor: pointer;
}
.headers .logo {
  float: left;
  width: 180px;
}
.header-right {
  float: right;
  padding-right: 50px;
}
.header-user-con {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 40px;
}
.btn-fullscreen {
  transform: rotate(45deg);
  font-size: 24px;
}

.logo {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 45px;
  color: #ffffff;
  opacity: 1;
}
.logo_img {
  float: left;
  width: 55px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo_img > img {
  width: 24px;
  height: 24px;
}
.user-avator {
  width: 120px;
  height: 45px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .user-dropdown {
    font-size: 14px;
    color: #ffffff;
  }
}
</style>
