<template>
    <div class="content">
        <el-dialog top="5vh" :title="title" :visible.sync="dialogFormVisible" @close="dialogFormVisible = false"
            :close-on-click-modal="false" width="620px">
            <el-form :model="dataForm" label-position="right" ref="dataForm" :rules="rules" label-width="100px"
                size="small">
                <el-form-item label="年份" prop="year">
                    <el-select v-model="dataForm.year" placeholder="请选择年份" style="width: 150px;" clearable>
                        <el-option v-for="(item, index) in yearList" :key="index" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="报考院校：" prop="college_code">
                    <el-select v-model="dataForm.college_code" filterable collapse-tags placeholder="请选择"
                        @change="collegeChoose" style="width: 429px">
                        <el-option v-for="(item, index) in $store.state.collegeList" :key="index" :label="item.name"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报考专业：" prop="major_code">
                    <el-select filterable placeholder="请选择" v-model="dataForm.major_code" @change="majorChoose"
                        style="width: 429px">
                        <el-option v-for="(item, index) in majorOptions" :key="index" :label="item.allnames"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报考学院：" prop="academy_code">
                    <el-select filterable placeholder="请选择" v-model="dataForm.academy_code" @change="academyChoose"
                        style="width: 429px">
                        <el-option v-for="(item, index) in academyOptions" :key="index" :label="item.academy_name"
                            :value="item.academy_code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="科目：" prop="subject_codes">
                    <el-select filterable placeholder="请选择" multiple v-model="dataForm.subject_codes"
                        @change="subject_codes" style="width: 429px">
                        <el-option v-for="(item, index) in subjectsOptions" :key="index" :label="item.subject_name"
                            :value="item.subject_code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-select v-model="dataForm.type" placeholder="选择文件类型" style="width: 150px;">
                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model.trim="dataForm.name" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="简介" prop="description">
                    <el-input type="textarea" placeholder="请输入简介" v-model="dataForm.description">
                    </el-input>
                </el-form-item>
                <el-form-item label="链接" prop="link_url">
                    <el-input v-model.trim="dataForm.link_url" placeholder="请输入链接"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="link_password">
                    <el-input v-model.trim="dataForm.link_password" placeholder="请输入密码"></el-input>
                </el-form-item>
              <div v-show =false>
                {{ name }}
              </div>
            </el-form>

            <div class="footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="submit()" plain>新增</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { majorlist, subjectlist, commonCollegeMaterialAdd, commonCollegeMaterialUp } from "@/request/api";
import { adminUrl } from "@/utils/helper";
import { map } from "mathjs";
export default {
    components: {

    },
    data() {
        return {
            title: '新增资料',
            dialogFormVisible: false,

            dataForm: {
                year:'',
            },
            yearList: [
                {
                    label: '2025',
                    value: '2025'
                },
                {
                    label: '2024',
                    value: '2024'
                },
                {
                    label: '2023',
                    value: '2023'
                },
                {
                    label: '2022',
                    value: '2022'
                },
                {
                    label: '2021',
                    value: '2021'
                },
                {
                    label: '2020',
                    value: '2020'
                },
                {
                    label: '2019',
                    value: '2019'
                }
            ],
            typeList: [
                {
                    label: '资料包',
                    value: 1
                },
                {
                    label: '真题',
                    value: 2
                },
                {
                    label: '笔记',
                    value: 3
                },
                {
                    label: '讲义',
                    value: 4
                },
                {
                    label: '小讲',
                    value: 5
                },
                {
                    label: '课程',
                    value: 6
                },
            ],
            majorOptions: [],
            academyOptions: [],
            subjectsOptions: [],
            rules: {
                year: [
                    { required: true, message: "请选择年份", trigger: "blur" },
                ],
                college_code: [
                    { required: true, message: "请选择院校", trigger: "blur" },
                ],
                major_code: [
                    { required: true, message: "请选择专业", trigger: "blur" },
                ],
                academy_code: [
                    { required: true, message: "请选择学院", trigger: "blur" },
                ],
                subject_codes: [
                    { required: true, message: "请选择科目", trigger: "blur" },
                ],
                type: [
                    { required: true, message: "请选择类型", trigger: "blur" },
                ],
                name: [
                    { required: true, message: "请输入名称", trigger: "blur" },
                ],
                description: [
                    { required: true, message: "请输入简介", trigger: "blur" },
                ],
                link_url: [
                    { required: true, message: "请输入链接", trigger: "blur" },
                ],
                link_password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                ],
            },
        }
    },
    created() {
        this.$store.dispatch("getAllColleges");
    },
    computed :{
        name:{
            set:function(newValue){
                // this.$set(this.dataForm, 'year', '')
                // this.$set(dataForm,'name',newValue)
                // console.log("计算",newValue)
            },
            get:function(newValue){
            //    console.log(this.dataForm)
               let codeName = ''
                if( this.dataForm.subject_codes&&this.dataForm.subject_codes.length>0){
                    this.dataForm.subject_codes.forEach((item,index)=>{
                    codeName += `(${item})${this.dataForm.subject_names.split(',')[index] }`  
                })
                }
               

                this.$set(this.dataForm,'name',(this.dataForm.year?(this.dataForm.year+'年'):'')+ 
                (this.dataForm.college_name||'') + 
                (codeName|| '') +
                ( this.dataForm.type? this.typeList.filter(item=>item.value==this.dataForm.type)[0].label:'')
                )
                return this.dataForm.year
            },
           
        }
      
    },
    methods: {
        open(row) {
            console.log('row',row)
            if (row&&row.id) {
                this.title = "修改资料"
                this.dataForm = JSON.parse(JSON.stringify(row))
                this.dataForm.subject_codes = this.dataForm.subject_codes.split(',')
                this.getMajorOptions();
                this.getMajorInfo(this.dataForm.college_code, this.dataForm.major_code);
                this.getSubjectOptions(
                    this.dataForm.college_code,
                    this.dataForm.academy_code,
                    this.dataForm.major_code
                );

            } else {
                this.title = "新增资料"
                this.dataForm = {}
            }
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.$refs.dataForm.clearValidate();//清空表单
            })
        },

        submit() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    if (this.title == '新增资料') {
                        commonCollegeMaterialAdd({ ...this.dataForm, subject_codes: this.dataForm.subject_codes.toString() }).then(res => {
                            if (res.data.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: '新增成功!'
                                });
                                this.dialogFormVisible = false
                                this.$emit("submit")
                            }
                            // console.log("xinz",res)
                        })
                    } else {
                        commonCollegeMaterialUp({ ...this.dataForm, subject_codes: this.dataForm.subject_codes.toString() }).then(res => {
                            if (res.data.code == 0) {
                                this.$message({
                                    type: 'success',
                                    message: '修改成功!'
                                });
                                this.dialogFormVisible = false
                                this.$emit("submit")
                            }
                            // console.log("xinz",res)
                        })
                    }


                    // alert('submit!');
                }
            });
        },
        // 选择学院
        collegeChoose(e) {
            this.$set(this.dataForm, 'major_name', '')
            this.$set(this.dataForm, 'major_code', '')
            this.academyOptions = []
            this.$set(this.dataForm, 'academy_name', '')
            this.$set(this.dataForm, 'academy_code', '')
            this.subjectsOptions = []
            this.$set(this.dataForm, 'subject_names', '')
            this.$set(this.dataForm, 'subject_codes', '')
            this.subjectsOptions = []
            let obj = {};
            obj = this.$store.state.collegeList.find((item) => {
                return item.code === e; //筛选出匹配数据
            });
            // this.dataForm.college_code = obj.code;
            this.dataForm.college_name = obj.name;
            this.getMajorOptions();
        },
        // 获取专业列表
        getMajorOptions() {

            let query = {
                isPaging: 0,
                college_code: this.dataForm.college_code,
            };
            majorlist(query).then((response) => {
                let res = response.data
                this.majorOptions = res.data.list.map(v => {
                    return {
                        ...v,
                        allnames: `（${v.code}）${v.name}`
                    }
                })
            });
        },
        // 选择专业
        majorChoose(e) {
            this.$set(this.dataForm, 'academy_name', '')
            this.$set(this.dataForm, 'academy_code', '')
            this.subjectsOptions = []
            this.$set(this.dataForm, 'subject_names', '')
            this.$set(this.dataForm, 'subject_codes', '')
            this.subjectsOptions = []
            let obj = {};
            obj = this.majorOptions.find((item) => {
                return item.code === e; //筛选出匹配数据
            });
            // this.dataForm.major_code = obj.code;
            this.dataForm.major_name = obj.name;
            this.getMajorInfo(this.dataForm.college_code, this.dataForm.major_code);

        },
        // 获取学院列表
        getMajorInfo(college_code, major_code) {
            let str = adminUrl()
            this.$axios({
                url: `${str}/api/v1/major/info`,
                method: 'get',//可以省略 不写默认是get
                params: {
                    college_code: college_code,
                    major_code: major_code,
                },
            }).then(response => {
                let res = response.data
                this.academyOptions = res.data.academys
               
                this.academyOptions.unshift({
                    academy_name: '不限',
                    academy_code: '全部'
                })
                 if(this.academyOptions.length == 1){
                    this.dataForm.academy_code = this.academyOptions[0].academy_code
                    this.dataForm.academy_name = this.academyOptions[0].academy_name
                }

            })
        },
        // 获取科目
        getSubjectOptions(college_code, academy_code, major_code) {
            let query = {
                isPaging: 0,
                college_code: college_code,
                academy_code: academy_code=='全部'?'':academy_code,
                major_code: major_code,
            };
            subjectlist(query).then((res) => {
                this.subjectsOptions = res.data.data.list || [];
                // this.subjectsOptions.push(obj)
                // console.log("res", res)
            });
        },
        // 选择学院
        academyChoose(e) {
            this.$set(this.dataForm, 'subject_names', '')
            this.$set(this.dataForm, 'subject_codes', '')
            this.subjectsOptions = []
            let obj = this.academyOptions.find((item) => {
                return item.academy_code === e; //筛选出匹配数据
            });
            this.dataForm.academy_code = obj.academy_code;
            this.dataForm.academy_name = obj.academy_name;
            this.getSubjectOptions(
                this.dataForm.college_code,
                this.dataForm.academy_code,
                this.dataForm.major_code
            );
        },
        // 选择科目
        subject_codes(e) {
            let obj = this.subjectsOptions.filter(item => e.some(i => i == item.subject_code))
            this.dataForm.subject_names = obj.map(item => item.subject_name).join(',')
           
            // console.log("e",this.dataForm.subject_names)
        }
    }
}
</script>

<style lang="less" scoped></style>