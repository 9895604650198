<template>
  <div>
    <el-dialog
top="5vh"
      title="邀请确认课时"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="450px"
    >
      <div class="bcg-container">
        <div class="value startplan">{{ $moment(detail.plan_start_time).format("YYYY-MM-DD") }}</div>
        <div class="value planhour">{{detail.plan_lesson_hour}}小时</div>
        <div class="value theme">{{detail.course_theme}}</div>
        <div class="value content">{{detail.course_content}}</div>
        <div class="value img">
          <div
            class="qrcode-img"
            id="qrcode"
            ref="qrcode"
          >
          </div>
          <!--     <img
            src="https://upload.kaoyanbox.net/bde1ad5a3ce1ce8dffa3662786a67260.png"
            alt=""
          > -->
        </div>

      </div>
      <div class="down">
        <el-link
          type="primary"
          :underline="false"
          @click="draw"
        >下载图片</el-link>
      </div>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
    <div class="content_container">
      <vue-canvas-poster
        :painting="painting"
        @success="success"
        @fail="fail"
      ></vue-canvas-poster>
    </div>
  </div>
</template>
  
  <script>



import {
  coursePlanOne
} from "@/request/api";
import QRCode from "qrcodejs2"



export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      detail: {},
      painting: {},
      posterImg: '',


    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      coursePlanOne({ id: this.id }).then((response) => {
        let res = response.data
        if (res.code == 0) {
          this.detail = res.data
          this.qrcode()
          this.drawPic()
        }
      });
    },
    qrcode(url) {
      let qrcode = new QRCode("qrcode", {
        width: 90, // 二维码宽度，单位像素
        height: 90, // 二维码高度，单位像素
        text: `https://h5.kaoyanbox.com/qrcode?plan_id=${this.detail.id}`, // 生成二维码的链接
      });
      console.log(document.querySelector('#qrcode').querySelectorAll('img'));
    },
    emitClose() {
      document.querySelector("#qrcode").innerHTML = "";
      this.$emit("close");
    },
    drawPic() {
      setTimeout(() => {
        this.painting = {
          width: '412px',
          height: '498px',
          background: 'https://upload.kaoyanbox.net/19fedc0999639e6627f0d02d96242029.png',
          views: [{
            type: 'text',
            text: this.$moment(this.detail.plan_start_time).format("YYYY-MM-DD"),
            css: {
              width: '80px',
              textAlign: 'left',
              fontSize: "12px",
              color: "#3C485C",
              fontWeight: '600',
              top: '169px',
              left: '61px',
            },
          }, {
            type: 'text',
            text: `${this.detail.plan_lesson_hour || 0}小时`,
            css: {
              width: '80px',
              textAlign: 'left',
              fontSize: "12px",
              color: "#3C485C",
              fontWeight: '600',
              top: '169px',
              left: '206px',
            },
          }, {
            type: 'text',
            text: this.detail.course_theme,
            css: {
              width: '310px',
              textAlign: 'left',
              fontSize: "12px",
              color: "#3C485C",
              fontWeight: '600',
              top: '227px',
              left: '61px',
            },
          }, {
            type: 'text',
            text: this.detail.course_content,
            css: {
              width: '310px',
              fontSize: "12px",
              color: "#3C485C",
              fontWeight: '600',
              top: '285px',
              left: '61px',
            },
          }, {
            type: 'image',
            url: document.querySelector('#qrcode').querySelectorAll('img')[0].currentSrc,
            css: {
              width: '102px',
              height: '102px',
              top: '362px',
              left: '248px',
            }
          }],
        }
      }, 500)
    },
    success(src) {
      this.posterImg = src
    },
    fail(err) {
      alert(err)
    },
    draw() {
      var a = document.createElement('a')
      a.download = 'poster'
      // 设置图片地址
      a.href = this.posterImg;
      a.click();
    },
  },
};
  </script>
  
  <style lang="less" scoped>
.bcg-container {
  width: 412px;
  height: 498px;
  background-image: url("https://upload.kaoyanbox.net/19fedc0999639e6627f0d02d96242029.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  
  .value {
    position: absolute;
 
    font-size: 12px;
    font-weight: 600;
    color: #3c485c;
  }
  .startplan {
    left: 61px;
    top: 169px;
  }
  .planhour {
    top: 169px;
    left: 206px;
  }
  .theme {
    left: 61px;
    top: 227px;
  }
  .content {
    left: 61px;
    top: 285px;
  }
  .img {
    left: 268px;
    bottom: 40px;

    .qrcode-img {
    }
    img {
    }
  }
}
.down {
  margin-top: 18px;
}
</style>