<template>
  <div>
    <el-dialog
top="5vh"
      :title="dataForm.name || '预览'"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="1080px"
    >
      <template v-if="this.dataForm.content_type==1">
        <iframe
          :frameborder="0"
          height="800"
          :src="this.httpUrl"
          class="iframe"
        >
        </iframe>
      </template>
      <template v-if="this.dataForm.content_type==2">
     
        <iframe
          :frameborder="0"
          height="800"
          :src="this.fileObject.url"
          class="iframe"
          v-if="this.fileObject.url.indexOf('.pdf') != -1"
        ></iframe>
        <iframe
          :frameborder="0"
          height="800"
          :src="baseurl+this.fileObject.url"
          class="iframe"
          v-else
        >
        </iframe>
      </template>
      <template v-if="this.dataForm.content_type==3">
        <div class="img-wrap">
          <img
            v-for="(item,index) in imgList"
            :key="index"
            :src="item.url"
            alt=""
            srcset=""
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>
    <script>

let baseurl = `${window.location.protocol}//view.officeapps.live.com/op/view.aspx?src=`


import {
  materialOne
} from "@/request/api";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {},
      fileObject: null,
      imgList: null,
      baseurl: baseurl,
      httpUrl: null,
    };

  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      materialOne({ id: this.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data
        if (this.dataForm.content_type == 1) {
          this.httpUrl = this.dataForm.content
          console.log(this.httpUrl);
        } else if (this.dataForm.content_type == 2) {
          this.fileObject = JSON.parse(this.dataForm.content)[0]
          console.log(this.fileObject);
        } else if (this.dataForm.content_type == 3) {
          this.imgList = JSON.parse(this.dataForm.content)
          console.log(this.imgList);


        }
        console.log("xxx",this.fileObject.url)
      })
    },
    handleToClose() {
      this.$emit("close");
    },

  },
};
        </script>
    <style lang="less" scoped>
.content {
  .iframe {
    width: 100%;
  }
  .img-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
    }
  }
}
</style>