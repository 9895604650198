import BillManagement from "../views/Finance/BillManagement/main.vue";
import BalanceManagement from "../views/Finance/BalanceManagement/main.vue";
import TransferApproval from "../views/Finance/TransferApproval/main.vue";
import InvoicingRequisition from "../views/Finance/InvoicingRequisition/main.vue";
import SeniorBill from "../views/Finance/SeniorBill/main.vue";
import WithDraw from "../views/Finance/WithDraw/main.vue";
import Transactionrecord from "../views/Finance/TransactionRecord/main.vue"

export default [
    /*** 财  务 ***/
    {
        path: '/finance/billmanagement',
        component: BillManagement,
        meta: { title: '账单管理' }
    }, {
        path: '/finance/balancemanagement',
        component: BalanceManagement,
        meta: { title: '余额管理' }
    }, {
        path: '/finance/transferapproval',
        component: TransferApproval,
        meta: { title: '转账审批' }
    }, {
        path: '/finance/invoicingrequisition',
        component: InvoicingRequisition,
        meta: { title: '开票审批' }
    },
    {
        path: '/finance/seniorbill',
        component: SeniorBill,
        meta: { title: '学长课酬' }
    },
    {
        path: '/finance/withdraw',
        component: WithDraw,
        meta: { title: '提现审核' }
    },
    {
        path: '/finance/transactionrecord',
        component: Transactionrecord,
        meta: { title: '交易记录' }
    },
]