 
import Vue from 'vue'
Vue.directive('loadmore', {
    bind(el, binding) {
          const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
          SELECTWRAP_DOM.addEventListener('scroll', function () {
          console.log(this.scrollHeight ,this.scrollTop,this.clientHeight)
          const CONDITION =Math.ceil(this.scrollHeight ) - Math.ceil(this.scrollTop)-1 <= this.clientHeight
          // console.log("减去",this.scrollHeight - this.scrollTop)
            if (CONDITION) {
                binding.value()
            }
        })
    }
}) 
