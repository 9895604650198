<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item>课程表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button
          size="small"
          @click="handleToImport"
          v-if="$route.query.grade_id"
        >导入</el-button>
        <el-button
          type="primary"
          size="small"
          @click="createDialog=true"
        >新建</el-button>
      </div>
    </div>

    <el-card class="card">
      <el-calendar v-model="calendarValue">
        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
        <template
          slot="dateCell"
          slot-scope="{date, data}"
        >
          <div class="date-cell">
            <div
              class="cell-title"
              :class="{'isSelected': data.isSelected,'notMonth':notMonth(data)}"
            >
              {{ data.day.split('-').slice(2).join('-') }}
            </div>
            <div class="topic-wrap">
              <div
                class="topic-item"
                v-for="(item,index) in formateDateCell(date,data)"
                :key="index"
                v-show="index<splice"
                @click.stop="opendetailDialog(item)"
              >
                <!--   橙色  -->
                <div
                  class="point"
                  style="background-color:#E6A23C;"
                  v-if="[0,20,21,41].includes(item.plan_status)"
                ></div>
                <!--   蓝色  -->
                <div
                  class="point"
                  style="background-color:#409EFF;"
                  v-if="[1,40].includes(item.plan_status)"
                ></div>
                <!--   红色  -->
                <div
                  class="point"
                  style="background-color:#F56C6C;"
                  v-if="[22,42,5,46].includes(item.plan_status)"
                ></div>
                <!--   绿色  -->
                <div
                  class="point"
                  style="background-color:#67C23A;"
                  v-if="[43,45].includes(item.plan_status)"
                ></div>
                <div class="topic-name">
                  <span class="time">{{$moment(item.plan_start_time).format("HH:mm")}}</span>
                  {{item.course_theme}}
                </div>
              </div>
              <PublishPopover
                :list="formateDateCell(date,data)"
                :date="date"
                v-if="formateDateCell(date,data)&&formateDateCell(date,data).length>splice"
                :splice="splice"
                @submit="opendetailDialog"
              />
            </div>
          </div>
        </template>
      </el-calendar>

    </el-card>
<!--     <div class="fix-wrap">
      <i class="el-icon-upload"></i>
      &nbsp;导入
    </div> -->
    <CalendarDetail
      :isVisible="detailDialog"
      :plan_id="plan_id"
      :grade_id="Number($route.query.grade_id)||null"
      :student_id="Number($route.query.student_id)||null"
      @close="detailDialog=false"
    />
    <AddCoursePlan
      :isVisible="createDialog"
      :plan_grade_id="Number($route.query.grade_id)||null"
      @close="createDialog=false"
      @submit="submitInit()"
    />
  </div>
</template>

<script>
import PublishPopover from "@/components/DialogComponents/ClassService/PublishPopover.vue";
import CalendarDetail from "@/components/DialogComponents/ClassService/CalendarDetail.vue";
import AddCoursePlan from "@/components/DialogComponents/ClassService/AddCoursePlan.vue";


import {
  coursePlanlist,

} from "@/request/api";
export default {
  components: {
    PublishPopover,
    CalendarDetail,
    AddCoursePlan
  },
  data() {
    return {
      calendarValue: new Date().getTime(),
      results: [],
      detailDialog: false,
      plan_id: null,
      createDialog: false,
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      splice: 1,
    };
  },
  mounted() {
    this.heightComputed(document.documentElement.clientHeight)
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        this.windowHeight = window.fullHeight;  // 高
        this.heightComputed(this.windowHeight)
      })()
    }
  },
  created() {
    if (this.$route.query.isOpen) {
      console.log("")
      this.calendarValue = Number(this.$route.query.time)
      this.plan_id = Number(this.$route.query.plan_id)
      setTimeout(() => {
        this.detailDialog = true
      }, 500);
    }
    this.fetchList();
  },
  watch: {
    calendarValue(value) {
      this.fetchList()
    },
    /*     windowHeight(val) {
          if (val <= 866) {
            this.splice = 0
          } else if (val >= 1013 && val < 1200) {
            this.splice = 1
          } else if (val >= 1200) {
            this.splice = 2
          }
        }, */
  },
  computed: {

  },
  methods: {
    heightComputed(val) {
      if (val < 950) {
        this.splice = 1
      } else if (val > 950 && val < 1200) {
        this.splice = 2
      } else if (val > 1200) {
        this.splice = 3
      }

    },
    fetchList() {
      const startOfMonth = this.$moment(this.calendarValue).startOf('month').format('x');
      const endOfMonth = this.$moment(this.calendarValue).endOf('month').format('x');
      coursePlanlist({
        isPaging: 0,
        grade_id: this.$route.query.grade_id,
        student_id: this.$route.query.student_id,
        start_plan_start_time: startOfMonth,
        end_plan_start_time: endOfMonth,
        is_all_type: 1,
      }).then((response) => {
        let res = response.data
        this.results = res.data.list
        this.results.sort(this.compare('plan_start_time'));
      });
    },
    compare(attr) {
      return function (a, b) {
        var val1 = a[attr];
        var val2 = b[attr];
        return val1 - val2;
      }
    },
    formateDateCell(date, data) {
      if (this.results.length) {
        let item_time = this.$moment(date.getTime()).startOf('day').format('x')
        let arr = []
        this.results.forEach((v) => {
          if (this.$moment(v.plan_start_time).startOf('day').format('x') == item_time) {
            arr.push(v)
          }
        })
        return arr
      }
    },
    submitInit() {
      this.fetchList();
    },
    opendetailDialog(item) {
      this.plan_id = item.id
      this.detailDialog = true
    },
    notMonth(data) {
      if (data.type == 'current-month') {
        return false
      } else {
        return true
      }
    },
    handleToImport() {
      this.$router.push(`/classservice/plan/import?grade_id=${Number(this.$route.query.grade_id)}`)
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px;

  .breadcrumb {
    margin-bottom: 16px;
  }
  /deep/.el-card__body {
    padding: 0px;
  }
  .card {
    flex: 1;
    overflow-y: scroll;
  }
  .date-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    .cell-title {
      color: #333;
      font-weight: 600;
      padding: 4px;
      font-size: 12px;
    }
    .isSelected {
      border-radius: 50%;
      color: #fff;
      background: #409eff;
    }
    .notMonth {
      color: #ccc;
    }
    .topic-wrap {
      width: 100%;
      padding: 0px 4px;

      .topic-item {
        background-color: #ebf5ff;
        & + .topic-item {
          margin-top: 5px;
        }
        display: flex;
        align-items: center;
        padding: 4px;
        border-radius: 4px;

        .point {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #67c23a;
          margin-right: 10px;
        }
        .point-other {
          background: #f56c6c;
        }
        .topic-name {
          width: 100%;
          font-size: 12px;
          color: #606266;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          .time {
            color: #909399;
          }
        }
      }
      /*     .topic-item:hover {
      background: #333;
    } */
      .more-content {
        cursor: pointer;
        margin-top: 16px;
        text-align: center;
        color: #999;
      }
    }
  }
  /deep/.el-card__body {
    height: 95%;
  }
  /deep/.el-calendar {
    height: 95%;
  }
  /deep/.el-calendar__body {
    height: 95%;
  }
  /deep/.el-calendar-day:hover {
    background-color: transparent;
  }
  /deep/.el-calendar__title {
    font-size: 18px;
    font-weight: 600;
  }
  /deep/ .current:hover {
    background-color: #f2f8fe;
  }
  /deep/.el-calendar-table {
    height: 100%;
    th {
      height: 20px;
      text-align: center;
      background: #f2f6fc;
    }
    th:before {
      content: "周";
    }
    tr {
    }
    td {
    }
  }
  .fix-wrap {
    position: fixed;
    right: 0px;
    bottom: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 35px;
    border-radius: 4px;
    background-color: #409eff;
    color: #fff;
    cursor: pointer;
  }
}
</style>
