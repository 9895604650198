import EmployeeManagement from "../views/Staff/EmployeeManagement/main.vue";
import DepartmentManagement from "../views/Staff/DepartmentManagement/main.vue";
import RoleManagement from "../views/Staff/RoleManagement/main.vue";
import UserRights from "../views/Staff/UserRights/main.vue";


export default [
    /* 员  工 */
    {
        path: '/staff/employeemanagement',
        component: EmployeeManagement,
        meta: { title: '员工管理' }
    },
    {
        path: '/staff/departmentmanagement',
        component: DepartmentManagement,
        meta: { title: '部门管理' }
    },
    {
        path: '/staff/rolemanagement',
        component: RoleManagement,
        meta: { title: '角色管理' }
    },
    {
        path: '/staff/userrights',
        component: UserRights,
        meta: { title: '用户权限' }
    }
]