<template>
  <div class="dialog-wrap">
    <el-dialog
    append-to-body 
      top="5vh"
      title="添加学员"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="1400px"
    >
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="mini"
      >
        <el-form-item label="学员信息">
          <el-input
            v-model.trim="query.keyword"
            placeholder="请输入姓名/手机号"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="机构简称">
          <el-input
            v-model.trim="query.company_name"
            placeholder="请输入机构简称"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="院校">
          <el-input
            v-model.trim="query.college_keyword"
            placeholder="请输入院校"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="专业">
          <el-input
            v-model.trim="query.major_keyword"
            placeholder="请输入专业"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="班级">
          <el-input
            v-model.trim="query.grade_keyword"
            placeholder="请输入班级关键字"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="课程状态">
          <el-select
            v-model="query.course_statuses"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.course_status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table
        class="result"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ fontSize: '13px' }"
        border
      >
        <el-table-column
          type="selection"
          width="50"
          fixed="left"
          :selectable='selectEnable'
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="60"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="报名日期"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="招生机构"
          width="200"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.company?`${scope.row.company.company_name}/${scope.row.company.contact_name}`:""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="realname"
          label="学员姓名"
          width="100"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="课程状态"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              v-show="scope.row.course_status == 1"
              type="warning"
            >待上课</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.course_status == 2"
            >上课中</el-tag>
            <el-tag
              size="small"
              type="info"
              v-show="scope.row.course_status == 3"
            >上课结束</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="apply_year"
          label="考研年份"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            {{scope.row.apply_year?`${scope.row.apply_year}年`:""}}
          </template>
        </el-table-column>
        <el-table-column
          label="报考信息"
          width="500"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.apply_college_name}} | ({{scope.row.apply_major_code}}){{scope.row.apply_major_name}} | {{scope.row.apply_academy_name}}
            |
            <span
              v-for="(item,index) in scope.row.subjects||[]"
              :key="index
            "
            >
              ({{item.subject_code}}){{item.subject_name}}
            </span>
          </template>
        </el-table-column>
        <!--      <el-table-column
          label="辅导时长"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.service_hours}}小时
          </template>
        </el-table-column> -->
        <el-table-column
          width="120"
          align="center"
          show-overflow-tooltip
        >
          <template slot="header">
            <el-popover ref="popover">
              <span
                class="el-dropdown-link"
                slot="reference"
              >
                辅导时长<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <div
                class="popover-item"
                @click="handleCommand(1)"
              >小时</div>
              <div
                class="popover-item"
                @click="handleCommand(2)"
              >课时</div>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <div v-if="unitConversion==1">{{scope.row.service_hours}}小时</div>
            <div v-if="unitConversion==2">{{(scope.row.service_hours/0.75).toFixed(2)}}课时</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="课程备注"
          width="300"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="学管师"
          align="center"
          width="130"
        >
          <template slot-scope="scope">
            {{scope.row.learn_manager?scope.row.learn_manager.nickname:""}}
          </template>
        </el-table-column>
        <el-table-column
          label="对接人"
          width="130"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ formateManage_Watcher(scope.row.manage_watcher)}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          small
          layout="total, prev, pager, next,sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submitBefore"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  studentlist,
  gradeOne,
  gradeEdit,
  commonHandleLogCreate

} from "@/request/api";

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isHandle: {
      type: Boolean,
      default: true,
    },
    id: {
      type: Number,
      default: () => null,
    },
    choose_students: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      dialogVisible: this.isVisible,
      query: {
        keyword: "",
        college: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 10,
      results: [],
      selections: [],
      countSelections: [],
      content: {},
      filters: {
        course_status: [
          { label: "待上课", value: 1 },
          { label: "上课中", value: 2 },
          { label: "上课结束", value: 3 },
        ],
      },
      unitConversion: 1,
      grade: {},
      setNum: 0,
      final_students: [],
    };
  },

  watch: {
    isVisible(val) {
      this.dialogVisible = val;
      if (val) {
        this.query = {
          pageIndex: 1,
          pageSize: 10,
          isPaging: 1,
        }
        this.fetchList();
      }
    },
  },
  methods: {
    selectEnable(row, rowIndex) {
      if (this.content.length) {
        let ind = this.content.findIndex((v) => {
          return v.id == row.id
        })
        if (ind == -1) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }


    },
    emitClose() {
      this.$emit("close");
    },
    // 院校列表请求
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      let params = { ...this.query };
      if (params.course_statuses) {
        params.course_statuses = params.course_statuses.join(',')
      }
      studentlist(params).then((response) => {
        let res = response.data
        this.results = res.data.list || [];
        this.pageTotal = res.data.count;
        if (this.isHandle) {
          this.fetchDetail()
        }
        this.$nextTick(this.setRowSelection)
      });
    },
    fetchDetail() {
      gradeOne({ id: this.id }).then((response) => {
        let res = response.data
        this.grade = res.data
        this.content = res.data.students ? res.data.students : []

        /*  ; */
      });
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    // 设置选中行
    setRowSelection() {
      if (this.results.length) {
        this.results.forEach((row) => {
          if (this.choose_students.find((item) => item.id === row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageIndex", 1);
      this.fetchList();
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields();
      this.fetchList();
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.fetchList();
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val;
      this.diffSelection();
    },
    submitBefore() {
      if (!this.isHandle) {
        this.$emit('submit', { data: this.countSelections })
        this.emitClose()
        return
      }
      if (this.setNum + 1 > this.countSelections.length) {
        this.setNum = 0
        this.handleSubmit(this.final_students)
        this.final_students = []
        return
      } else {
        if (this.countSelections[this.setNum].service_hours > this.grade.cocah_lesson_hour) {
          this.$confirm(`当前加入的班级课时为${this.grade.cocah_lesson_hour}小时，与${this.countSelections[this.setNum].realname}的总课时${this.countSelections[this.setNum].service_hours}小时不匹配，是否确认加入？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.final_students.push(this.countSelections[this.setNum])
            this.setNum += 1
            this.submitBefore()
          }).catch(() => {
            this.setNum += 1
            this.submitBefore()
          })
        } else if (this.countSelections[this.setNum].service_hours < this.grade.cocah_lesson_hour) {
          this.$confirm(`当前加入的班级课时为${this.grade.cocah_lesson_hour}小时，大于${this.countSelections[this.setNum].realname}的总课时${this.countSelections[this.setNum].service_hours}小时，是否确认加入？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.final_students.push(this.countSelections[this.setNum])
            this.setNum += 1
            this.submitBefore()
          }).catch(() => {
            this.setNum += 1
            this.submitBefore()
          })
        } else {
          this.final_students.push(this.countSelections[this.setNum])
          this.setNum += 1
          this.submitBefore()
        }
      }
    },
    handleSubmit(arr) {
      var origin = []
      if (this.grade.students && this.grade.students.length) {
        origin = this.grade.students
      }
      let final = origin.concat(arr).map(v => {
        return {
          student_id: v.id,
          student_grade_status: v.student_grade_status != null ? v.student_grade_status : 1,
        }
      })
      let query = {
        id: this.id,
        arr_student: final
      }
      gradeEdit(query).then((res) => {
        if (res.data.code == 0) {
          this.createLog(arr)
          this.final_students = []
        }
      });
    },
    createLog(arr) {
      let query = {
        ref_id: this.id,
        handle_type: 0,
        handle_child_type: 2,
        handle_content: `将${arr.map(v => {
          return `${v.realname}学员`
        })}加入班级`,
      }
      commonHandleLogCreate(query).then((res) => {
        if (res.data.code == 0) {
          this.emitClose()
          this.$emit('submit')
        }
      });
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        keyword: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      };
      this.$emit("close");
    },
    // 选中项差异化计算
    diffSelection() {
      let diffs = [];
      // 减
      this.countSelections.forEach((item, index) => {
        if (!this.selections.find((v) => v.id === item.id)) {
          if (this.results.find((v2) => v2.id === item.id)) {
            diffs.push(item);
          }
        }
      });
      if (diffs.length) {
        this.countSelections = this.countSelections.filter(
          (item) => !diffs.find((v) => v.id === item.id)
        );
      }
      // 加
      this.selections.forEach((item) => {
        if (!this.countSelections.find((v) => v.id === item.id)) {
          this.countSelections.push(item);
        }
      });
    },

    handleToChoose(row) {
      let data = [row]
      this.$emit("submit", { data: data });
      this.handleClose();
    },
    collegeFormate(row) {
      if (row.apply_college_name && row.apply_major_code && row.apply_major_name) {
        return `${row.apply_college_name},（${row.apply_major_code}）${row.apply_major_name}`
      } else {
        return "暂无定校"
      }
    },
    formateManage_Watcher(val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.nickname + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    }
  },
};
</script>

<style lang="less" scoped>
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    margin-top: 10px;
  }
  .now {
    margin-left: 16px;
    color: red;
  }
}
.el-input {
  width: 150px;
}
</style>
