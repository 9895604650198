<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>师资共享</el-breadcrumb-item>
        <el-breadcrumb-item>优惠券包</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button type="primary"
                   size="small"
                   @click="handleAdd()">新建</el-button>
      </div>
    </div>

    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="queryForm"
               size="small"
               label-position="left">
        <el-form-item label="优惠券包名称">
          <el-input @input="getDataList(true)"
                    v-model.trim="queryForm.create_time"
                    placeholder="输入名称"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-select multiple
                     @change="getDataList(true)"
                     v-model="queryForm.coupon_types"
                     placeholder="选择范围"
                     style="width: 150px">
            <el-option v-for="(item, index) in couponTypesList"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <el-select multiple
                     @change="getDataList(true)"
                     v-model="queryForm.collect_types"
                     placeholder="选择范围"
                     style="width: 115px">
            <el-option v-for="(item, index) in collectTypesList"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form size="small"
                 :inline="true">
          <el-form-item label-width="32px">
            <el-radio-group v-model="queryForm.statuses"
                            @change="getDataList(true)">
              <el-radio-button :label="'1,0'">全部</el-radio-button>
              <el-radio-button :label="'1'">进行中</el-radio-button>
              <el-radio-button :label="'0'">已下线</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData"
                ref="multipleTable"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ fontSize: '13px', textAlign: 'center' }"
                border
                style="width: 100%; margin-top: 20px">
        <el-table-column label="ID"
                         prop="id"
                         width="50"> </el-table-column>
        <el-table-column prop="name"
                         label="优惠券名称"
                         width="180">
        </el-table-column>

        <el-table-column prop="name"
                         label="分类"
                         width="120">
          <template slot-scope="scope">
            <el-tag :type="scope.row.coupon_type == 1 ? '' : 'warning'">
              {{ scope.row.coupon_type == 1 ? "发单优惠券" : "接单优惠券" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="address"
                         label="类型"
                         width="80">
          <template slot-scope="scope">
            {{ scope.row.collect_type == 1 ? "等值" : "随机" }}
          </template>
        </el-table-column>

        <el-table-column prop="address"
                         label="金额"
                         width="100">
          <template slot-scope="scope">
            {{
              scope.row.collect_type == 1
                ? scope.row.collect_fix_amount
                : `${scope.row.collect_random_low_amount}-${scope.row.collect_random_high_amount}`
            }}元
          </template>
        </el-table-column>
        <el-table-column prop="address"
                         label="券包明细"
                         width="180">
          <template slot-scope="scope">
            {{ getSingleCoupon(scope.row.single_coupon_list) }}
          </template>
        </el-table-column>
        <el-table-column prop="total_circulation_amount"
                         label="总金额"
                         width="80">
          <template slot-scope="scope">
            {{ scope.row.total_circulation_amount }}元
          </template>
        </el-table-column>

        <el-table-column prop="date"
                         label="状态"
                         width="80">
          <template slot-scope="scope">
            <el-tag :type="
                scope.row.status == 0
                  ? 'danger'
                  : scope.row.status == 1
                  ? 'success'
                  : 'info'
              ">
              {{
                scope.row.status == 0
                  ? "下线"
                  : scope.row.status == 1
                  ? "正常"
                  : "未开始"
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="address"
                         label="已领取"
                         width="80">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="goCheckOrder(scope.row)"> {{
              scope.row.single_coupon_list
                .map((item) => item.single_collect_exist_count)
                .reduce((a, b) => Number(a) + Number(b))
            }}</el-link>

          </template>
        </el-table-column>
        <el-table-column prop="address"
                         label="已使用"
                         width="80">
          <template slot-scope="scope">
            {{
              scope.row.single_coupon_list
                .map((item) => item.single_collect_exist_used_count)
                .reduce((a, b) => Number(a) + Number(b))
            }}
          </template>
        </el-table-column>
        <el-table-column prop="address"
                         label="有效期"
                         width="260">
          <template slot-scope="scope">
            {{ $moment(scope.row.start_time).format("YYYY-MM-DD HH:mm") }} ~
            {{ $moment(scope.row.end_time).format("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column prop="address"
                         label="创建人"
                         width="100">
          <template slot-scope="scope">
            {{ formateCreator(scope.row) }}
          </template>
        </el-table-column>

        <el-table-column label="创建时间"
                         width="150"
                         align="center">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         width="150 "
                         align="center">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="180"
                         align="center"
                         fixed="right">
          <!-- :disabled="scope.row.id" -->
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleAdd(scope.row.id)">编辑</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToCouponShare(scope.row)">推广</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleDel(scope.row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(queryForm.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(queryForm.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <Record :isVisible="recordDialog"
            @close="recordDialog = false"
            @submit=" getDataList"
            :type="'order_read'"></Record>
    <CouponExtension :isVisible="CouponExtensionDialog"
                     :content="CouponExtensionContent"
                     @close="CouponExtensionDialog=false" />

    <!-- 点击领取弹出弹窗 -->
    <el-dialog top="5vh"
               title="已领取列表"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               :close-on-click-modal="false"
               width="80%"
               append-to-body
               @open="openDialog">
      <div class="search-wrap">
        <div class="searchTitle"></div>
        <el-form class="search-wrap"
                 ref="searchForm"
                 :inline="true"
                 :model="CouponlistForm"
                 size="small"
                 label-position="left">
          <!-- <el-form-item>
            <el-form-item label="领取人">
              <el-input v-model="CouponlistForm.name_keyword"
                        placeholder="请输入姓名或手机号"
                        @input="Couponlist()"
                        clearable></el-input>
            </el-form-item>
          </el-form-item> -->
          <el-form-item label="状态">
            <el-select v-model="CouponlistForm.status"
                       placeholder="请选择优惠卷使用状态"
                       @change="Couponlist()"
                       clearable>
              <el-option v-for="(item,index) in collectTypestues"
                         :key="index"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary"
                       size="mini"
                       @click="handleSearch">搜索</el-button>
            <el-button class="submit"
                       size="mini"
                       @click="handleFormReset('CouponlistForm')">重置</el-button>
          </el-form-item> -->
        </el-form>
      </div>
      <el-table :data="result"
                row-key="id"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column label="序号"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            {{
              scope.row.id
            }}
          </template>
        </el-table-column>
        <el-table-column label="领取人"
                         align="center"
                         width="250">
          <template slot-scope="scope"
                    v-if="scope.row.company_user_obj !=null">
            {{
              scope.row.company_user_obj.company_ref_name
            }}{{scope.row.company_user_obj.alias}}
          </template>
          <template v-else>
            -
          </template>
        </el-table-column>
        <el-table-column label="领取来源"
                         align="center"
                         width="250">
          <template slot-scope="scope">
            {{ scope.row.source_remark}}
          </template>
        </el-table-column>
        <el-table-column label="类型"
                         align="center"
                         width="250">
          <template slot-scope="scope">
            <el-tag :type="
                scope.row.share_coupon_type   == 1
                  ? 'warning'
                  : scope.row.share_coupon_type  == 2
                  ? ''
                  : ''
              ">
              {{
                scope.row.share_coupon_type  == 1
                  ? "发单人优惠"
                  : scope.row.share_coupon_type  == 2
                  ? "接单人补贴"
                  : ""
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="金额"
                         align="center"
                         width="250">
          <template slot-scope="scope">
            {{
              scope.row.share_single_coupon_obj.single_circulation_amount 
            }}元
          </template>
        </el-table-column>
        <el-table-column label="状态"
                         align="center"
                         width="250">
          <template slot-scope="scope">
            <el-tag :type="
                scope.row.status  == 0
                  ? 'danger'
                  : scope.row.status == 1
                  ? 'success'
                  : 'info'
              ">
              {{
                scope.row.status == 0
                  ? "未使用"
                  : scope.row.status == 1
                  ? "已使用"
                  : "已过期"
              }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="领取时间"
                         align="center"
                         width="200">
          <template slot-scope="scope">
            <span>{{  $moment(scope.row.create_time).format('YYYY-MM-DD HH:mm')  }}</span>
          </template>
        </el-table-column>

        <el-table-column label="使用时间"
                         align="center"
                         width="200">
          <template slot-scope="scope">
            <span>{{  $moment(scope.row.used_time).format('YYYY-MM-DD HH:mm')  }}</span>
          </template>
        </el-table-column>

        <el-table-column label="过期时间"
                         align="center"
                         width="200">
          <template slot-scope="scope">
            <span>{{  $moment(scope.row.endTime).format('YYYY-MM-DD HH:mm')  }}</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="footer">
        <el-pagination background
                       layout="total, prev, pager, next, sizes, jumper"
                       :current-page="Number(CouponlistForm.pageIndex)"
                       :page-sizes="[10, 20, 50, 100, 500]"
                       :page-size="Number(CouponlistForm.pageSize)"
                       :total="Number(pageTotals)"
                       @size-change="handleSizeChangelist"
                       @current-change="handlePageChangelist"></el-pagination>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { shareCouponlist } from '@/request/api.js'
import { crmUrl } from '@/utils/helper.js'
import TableTittle from '@/components/TableTittle/TableTittle.vue'
import CouponExtension from '../../../components/CouponExtension.vue'
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'
export default {
  components: {
    Record,
    TableTittle,
    CouponExtension,
  },
  data() {
    return {
      result: [],
      dialogFormVisible: false,
      recordDialog: false,
      TableName: '优惠券列表',
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        coupon_types: [],
        collect_types: [],
        statuses: 1,
      },
      CouponlistForm: {
        status: '',
        pageIndex: 1,
        pageSize: 10,
      },
      couponTypesList: [
        {
          label: '发单优惠券',
          value: 1,
        },
        {
          label: '接单补贴券',
          value: 2,
        },
      ],
      collectTypesList: [
        {
          label: '等值',
          value: 1,
        },
        {
          label: '随机',
          value: 2,
        },
      ],
      collectTypestues: [
        {
          label: '未使用',
          value: 0,
        },
        {
          label: '已使用',
          value: 1,
        },
        {
          label: '已过期',
          value: 2,
        },
      ],
      tableData: [],
      pageTotal: 0,
      pageTotals: 0,
      CouponExtensionContent: {},
      CouponExtensionDialog: false,
    }
  },

  created() {
    this.$store.dispatch('getStaffOptions')
    this.getDataList()
  },
  methods: {
    handleSearch() {
      this.Couponlist()
    },
    handleFormReset(formName) {
      this.$refs[formName].resetFields()
      this.Couponlist()
    },
    handleSizeChangelist(val) {
      this.CouponlistForm.pageSize = val
      this.Couponlist()
    },
    handlePageChangelist(val) {
      this.CouponlistForm.pageIndex = val
      this.Couponlist()
    },
    Couponlist() {
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/company/user/shareCoupon/list`,
        method: 'get', //可以省略 不写默认是get
        params: {
          ...this.CouponlistForm,
        },
        headers: {
          // 设置请求头
          Authorization: localStorage.getItem('admin_token'),
        },
      }).then((res) => {
        res.data.data.list.map((item) => {
          item.endTime = item.create_time + 30 * 24 * 60 * 60 * 1000
        })
        this.result = res.data.data.list
        this.pageTotals = res.data.data.count
      })
    },
    goCheckOrder(row) {
      this.CouponlistForm.share_coupon_id = row.id
      this.dialogFormVisible = true
      this.Couponlist()
    },
    handleToClose() {
      this.CouponlistForm = {
        status: '',
        pageIndex: 1,
        pageSize: 10,
      }
    },
    openDialog() {},
    handleToCouponShare(row) {
      this.CouponExtensionContent = JSON.parse(JSON.stringify(row))
      this.CouponExtensionDialog = !this.CouponExtensionDialog
    },
    formateCreator(row) {
      if (row.manage_creator && this.$store.state.staffOptions.length) {
        let id = row.manage_creator
        return this.$store.state.staffOptions.find((v) => {
          return v.id == id
        }).nickname
      } else {
        return '无'
      }
    },
    getSingleCoupon(list) {
      return list
        .map(
          (item) =>
            `${item.single_circulation_amount}元*${item.single_circulation_count}`
        )
        .join(' | ')
    },
    getDataList(flag) {
      if (flag) {
        this.queryForm.pageIndex = 1
      }

      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/coupon/list`,
        method: 'get', //可以省略 不写默认是get
        params: {
          ...this.queryForm,
          coupon_types: this.queryForm.coupon_types.toString(),
          collect_types: this.queryForm.collect_types.toString(),
        },
        headers: {
          // 设置请求头
          Authorization: localStorage.getItem('admin_token'),
        },
      }).then((response) => {
        if (response.data.code == 0) {
          this.pageTotal = response.data.data.count
          this.tableData = response.data.data.list
        }
      })
    },
    handleAdd(id = null) {
      // console.log("id",id)
      if (id) {
        this.$router.push({
          path: '/shareorder/discountrolladd',
          query: { id: id },
        })
      } else {
        this.$router.push({ path: '/shareorder/discountrolladd' })
      }
    },

    // 删除
    handleDel(id) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let str = crmUrl()
          this.$axios({
            url: `${str}/api/v1/sharePlatform/coupon`,
            method: 'delete', //可以省略 不写默认是get
            data: { id: id },
            headers: {
              // 设置请求头
              Authorization: localStorage.getItem('admin_token'),
            },
          }).then((res) => {
            // console.log("cc",res)
            if (res.data.code == 0) {
              this.getDataList()
              this.$message({
                type: 'error',
                message: '删除成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.getDataList()
    },
    handleCurrentChange(val) {
      this.queryForm.pageIndex = val
      this.getDataList()
    },
  },
}
</script>
<style lang="less" scoped>
::v-deep .el-dialog__header {
  // border: 1px solid red !important;
  background: #ebeef5;
  border-radius: 4px 4px 0px 0px;
}
.search-wrap {
  position: relative;
  // margin-bottom: 24px;
  .searchTitle {
    position: absolute;
    top: 0;
    width: 100%;
    height: 57px;
    // border: 1px solid red;
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .confirm {
    color: #ffff;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    line-height: 32px;
    background: #409eff;
    border-radius: 4px 4px 4px 4px;
    width: 56px;
    height: 32px;
  }
}
</style>