<template>
  <el-dialog
    top="5vh"
    title="关联记录"
    :visible.sync="dialogFormVisible"
    @close="dialogFormVisible = false"
    :close-on-click-modal="false"
    width="70%"
  >
    <el-table
      :data="result"
      :header-cell-style="{
        textAlign: 'center',
        color: '#909399',
        fontFamily: 'PingFang SC',
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: '#F2F6FC',
      }"
      :cell-style="{ fontSize: '13px' }"
      border
    >
      <el-table-column label="记录日期" align="center" width="150">
        <template slot-scope="scope">
          {{
            scope.row.create_time
              ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="服务类型" align="center" width="150">
        <template slot-scope="scope">
          {{ formateType(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="content_text"
        label="记录内容"
        align="left"
        show-overflow-tooltip
        min-width="300"
      >
      </el-table-column>
      <el-table-column
        prop="content_text"
        label="关联对象"
        align="center"
        min-width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ getName(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="附件" align="center" width="100">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              v-if="scope.row.content_img"
              @click="openViewer(JSON.parse(scope.row.content_img))"
              >预览</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              v-else-if="scope.row.content_file"
              @click="handleToPreview(scope.row)"
              >预览</el-link
            >
            <el-link type="primary" :underline="false" v-else disabled
              >预览</el-link
            >
          </template>
        </el-table-column>
      <el-table-column
        label="记录人"
        width="120"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.real_record_user_name }}
          <!-- {{ formateCreator(scope.row) }} -->
        </template>
      </el-table-column>
      <el-table-column
        label="记录人类型"
        width="100"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <!-- {{scope.row.real_record_user_name}} -->
          {{ formateUserType(scope.row) }}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import {
  learnManageRecordList,
} from "@/request/api";
export default {
  data() {
    return {
      dialogFormVisible: false,
      result: [],
    };
  },
  methods: {
    open(row) {
        console.log("ppp",row)
        learnManageRecordList({
            force_show_statuses:'1,2',
            ids:row.task_record_id,
            isPaging: 0,
            
        }).then(res=>{
            if(res.data.code == 0){
                this.result = res.data.data.list
                this.dialogFormVisible = true;
            }
           
            // console.log(res)
        })
    //   
    },
    getName(row) {
      if (row.grade_id) {
        return row.ref_grade_name;
      } else if (row.ref_lesson_log_grade_id) {
        return row.ref_lesson_log_grade_name;
      } else if (row.ref_course_plan_grade_id) {
        return row.ref_course_plan_grade_name;
      } else if (row.task_ref_course_plan_grade_id) {
        return row.task_ref_course_plan_grade_name;
      } else if (row.task_ref_grade_id) {
        return row.task_ref_grade_name;
      } else if (row.task_ref_allocation_order_grade_id) {
        return row.task_ref_allocation_order_grade_name;
      } else if (row.task_ref_lesson_log_grade_id) {
        return row.task_ref_lesson_log_grade_name;
      } else if (row.student_id) {
        return row.student_realname;
      } else {
        return "-";
      }
    },
    formateType(row) {
      switch (row.core_type) {
        case 1:
          return "学员对接";
        case 2:
          return "师资匹配";
        case 3:
          return "师资确认";
        case 4:
          return "计划制定";
        case 5:
          return "上课提醒";
        case 6:
          return "课时确认";
      }
    },
    formateUserType(row) {
      switch (row.real_record_user_type) {
        case 1:
          return "学管师";
        case 2:
          return "匹配师";
        case 3:
          return "老师";
        case 4:
          return "学生";
        case 5:
          return "系统";
      }
    },
  },
};
</script>
