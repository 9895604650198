<template>
  <div class="line-content"></div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.line-content {
  border: 1px dashed #D2D5DF;
}
</style>