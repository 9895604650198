<template>
  <div class="content">
    <el-dialog
      top="5vh"
      append-to-body
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="dialogFormVisible = false"
      :close-on-click-modal="false"
      width="520px"
    >
      <el-form
        :model="dataForm"
        label-width="80px"
        label-position="right"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item label="课时主题" prop="dispatch_log_course_theme">
          <el-input
            v-model.trim="dataForm.dispatch_log_course_theme"
            placeholder="请输入课时主题"
          ></el-input>
        </el-form-item>
        <el-form-item label="课时时长" prop="final_real_lesson_hour">
          <el-input
            v-model.trim="dataForm.final_real_lesson_hour"
            placeholder="请输入课时时长"
          >
            <template slot="append">小时</template>
          </el-input>
        </el-form-item>

        <el-form-item label="课时费" prop="final_real_lesson_hour_amount">
          <el-input
            v-model.trim="dataForm.final_real_lesson_hour_amount"
            placeholder="请输入课时费"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>

        <el-form-item label="课时备注" prop="remark">
          <el-input
            type="textarea"
            v-model="dataForm.remark"
            placeholder="请输入课时备注"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="submit()" plain>确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dispatchLessonLogUp } from "../../../request/api";
export default {
  data() {
    return {
      title: "编辑",
      dialogFormVisible: false,
      dataForm: {},
      rules: {
        dispatch_log_course_theme: [
          { required: true, message: "课时主题不能为空", trigger: "blur" },
        ],
        final_real_lesson_hour: [
          { required: true, message: "课时时长不能为空", trigger: "blur" },
          {
            pattern:/^(\d+)?(\.\d{0,3})?$/,
            message: "输入最多三位小数的数字",
          },
        ],
        final_real_lesson_hour_amount: [
          { required: true, message: "课时费不能为空", trigger: "blur" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
        dispatch_log_course_theme: [
          { required: true, message: "课时主题不能为空", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    open(row) {
    //   console.log("row", row);
      this.dataForm = {
        id: row.id,
        dispatch_log_course_theme: row.dispatch_log_course_theme,
        final_real_lesson_hour: row.final_real_lesson_hour,
        final_real_lesson_hour_amount: row.final_real_lesson_hour_amount,
        final_real_company_hour_amount: row.final_real_company_hour_amount,
        final_real_company_service_amount:row.final_real_company_service_amount,
        remark: row.remark,
      };
      this.dialogFormVisible = true;
    //   this.$nextTick(() => {
    //     this.$refs.formRules.clearValidate(); //清空表单
    //   });
    },
    submit() {
      this.$refs.formRules.validate((valid) => {
        if (valid) {
          dispatchLessonLogUp(this.dataForm).then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.dialogFormVisible = false;
              this.$emit("submit");
            }
          });
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
</style>