<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>服务</el-breadcrumb-item>
        <el-breadcrumb-item>师资订单</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button type="primary" size="small" @click="handleCollectionRecord"
          >收款记录</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="handleADD"
          :disabled="!$buttonPermission('serviceorder:create')"
          >新建</el-button
        >
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form
        :inline="true"
        :model="query"
        class="demo-form-inline"
        size="mini"
      >
        <el-form-item label="机构简称" prop="cid">
          <el-select
            v-model="query.cid"
            placeholder="请选择"
            filterable
            @change="fetchList(true)"
            clearable
          >
            <el-option
              v-for="item in $store.state.CompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="搜索">
          <el-input
            v-model="query.keyword"
            placeholder="姓名/院校名称/专业名称"
            @input="inputSearch"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item style="float: right">
          <el-radio-group v-model="query.archive" @input="fetchList(true)">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="1">已归档</el-radio-button>
            <el-radio-button label="0">未归档</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <deviceLine style="margin-bottom: 16px"></deviceLine>

        <el-form-item label="考研年份">
          <el-select
            v-model="query.apply_year"
            placeholder="请选择考研年份"
            @change="fetchList(true)"
            clearable
          >
            <el-option label="2023考研" value="2023"></el-option>
            <el-option label="2024考研" value="2024"></el-option>
            <el-option label="2025考研" value="2025"></el-option>
            <el-option label="2026考研" value="2026"></el-option>
            <el-option label="2027考研" value="2027"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学员信息">
          <el-input
            v-model="query.student_keyword"
            placeholder="学员信息"
            @input="inputSearch"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="订单状态">
          <el-select
            v-model="query.student_order_sync_statuses"
            placeholder="请选择订单状态"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in filters.student_order_sync_statuses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报考信息">
          <el-input
            v-model="query.college_info_keyword"
            placeholder="输入报考院校/学院/专业/方向"
            @input="inputSearch"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="辅导科目">
          <el-input
            v-model="query.subject_keyword"
            placeholder="辅导科目"
            @input="inputSearch"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="对接老师" prop="manage_watcher">
          <el-select
            @change="inputSearch"
            v-model="query.manage_watcher_id"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in $store.state.staffOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            >
              <span> {{ `${item.nickname}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="学管师">
          <el-select v-model="query.learn_managers" placeholder="请选择" @change="fetchList(true)" clearable multiple
            collapse-tags>
            <el-option v-for="item in $store.state.staffOptions.filter(v => {
              return v.depts.findIndex(t => {
                return t.id == 4 && v.status == 1
              }) != -1
            })" :key="item.id" :label="item.nickname" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="学管记录">
          <el-select
            v-model="query.last_manage"
            placeholder="请选择订单状态"
            @change="fetchList(true)"
            clearable
            collapse-tags
          >
            <el-option
              v-for="item in timestampList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学管老师" prop="manage_watcher">
          <el-select
            @change="inputSearch"
            v-model="query.student_learn_manager_id"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 4 && v.status == 1;
                  }) != -1
                );
              })"
              :key="index"
              :label="item.nickname"
              :value="item.id"
            >
              <span> {{ `${item.nickname}-${item.phone}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="教务老师" prop="manage_watcher">
          <!--   -->
          <el-select
            @change="inputSearch"
            v-model="query.student_watcher_id"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in affairsTeacherList"
              :key="index"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="未跟进时间" prop="region" label-width="90px">
          <el-select
            v-model="end_advance_time"
            placeholder="请选择未跟进时间"
            @change="fetchList(true)"
            clearable
          >
            <el-option
              v-for="item in timeArr"
              :key="item.timestamp"
              :label="item.name"
              :value="item.timestamp"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="create_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="fetchList(true)"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="收款时间">
          <el-date-picker
            v-model="company_order_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="fetchList(true)"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="跟进日期">
          <el-date-picker
            v-model="advance_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"   
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="fetchList(true)"
          >
          </el-date-picker>
        </el-form-item> -->
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="tabulation">
        <div class="radio-group">
          <el-radio-group
            v-model="filtrate"
            @change="filtrateChange"
            size="small"
          >
            <el-radio-button label="1">全部</el-radio-button>
            <el-radio-button label="2">仅关注</el-radio-button>
            <!-- distributionNum -->
            <el-badge
              :value="distributionNum"
              :max="99"
              :hidden="distributionNum == 0"
            >
              <el-radio-button label="3">待分配</el-radio-button>
            </el-badge>
          </el-radio-group>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="订单列表" name="first">
            <el-table
              :data="result"
              :header-cell-style="{
                textAlign: 'center',
                color: '#909399',
                fontFamily: 'PingFang SC',
                fontSize: '14px',
                fontWeight: '400',
                backgroundColor: '#F2F6FC',
              }"
              @selection-change="handleSelectionChange"
              border
            >
              <el-table-column
                type="selection"
                width="55"
                fixed="left"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="date"
                label="关注"
                width="69"
                fixed="left"
                align="center"
              >
                <template slot-scope="scope">
                  <img
                    alt=""
                    srcset=""
                    class="star"
                    src="../../../assets/images/star-fill.png"
                    @click="followDelete(scope.row)"
                    v-show="scope.row.follow"
                  />
                  <img
                    src="../../../assets/images/star.png"
                    alt=""
                    srcset=""
                    class="star"
                    v-show="!scope.row.follow"
                    @click="followCreate(scope.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="服务记录"
                width="100"
                fixed="left"
                align="center"
              >
                <template slot-scope="scope">
                  <div class="badge">
                    <el-badge
                      v-if="
                        student_unread_msg && student_unread_msg[scope.$index]
                      "
                      :value="
                        student_unread_msg[scope.$index].unread_ait_msg_count >
                        0
                          ? '@'
                          : student_unread_msg[scope.$index]
                              .unread_common_msg_count
                      "
                      class="item"
                      :hidden="
                        !student_unread_msg[scope.$index]
                          .unread_ait_msg_count &&
                        !student_unread_msg[scope.$index]
                          .unread_common_msg_count
                      "
                    >
                      <el-link
                        type="primary"
                        :underline="false"
                        @click="goRecord(scope.row)"
                        :disabled="
                          !$buttonPermission('serviceorder:record:view')
                        "
                        >查看</el-link
                      >
                    </el-badge>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="apply_time"
                label="报名时间"
                width="120"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.apply_time | dateFormat("YYYY-MM-DD") }}
                </template>
              </el-table-column>
              <!-- <el-table-column
          label="负责人"
          width="130"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.watcher | formateWatcher }}</span>
          </template>
        </el-table-column> -->
              <el-table-column
                align="center"
                prop="apply_year"
                label="机构简称"
                show-overflow-tooltip
                width="120px"
              >
                <template slot-scope="scope">
                  <span
                    >{{ scope.row.company.name }}
                    <!-- {{
                      scope.row.watcher && scope.row.watcher.length > 0
                        ? " / " + scope.row.watcher[0].alias
                        : ""
                    }} -->
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                prop="nickname"
                label="学生姓名"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="openStudentDialog(scope.row)"
                    :disabled="!$buttonPermission('serviceorder:edit')"
                    >{{ scope.row.student_realname }}</el-link
                  >
                </template>
              </el-table-column>

              <el-table-column
                prop="apply_year"
                label="订单状态"
                width="80"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tag
                    size="small"
                    v-show="scope.row.student_order_sync_status == 1"
                    >待定校</el-tag
                  >
                  <el-tag
                    size="small"
                    type="warning"
                    v-show="scope.row.student_order_sync_status == 2"
                    >待服务</el-tag
                  >
                  <el-tag
                    size="small"
                    type="warning"
                    v-show="scope.row.student_order_sync_status == 3"
                    >待对接</el-tag
                  >
                  <el-tag
                    size="small"
                    v-show="scope.row.student_order_sync_status == 4"
                    >匹配中</el-tag
                  >
                  <el-tag
                    size="small"
                    v-show="scope.row.student_order_sync_status == 5"
                    >上课中</el-tag
                  >
                  <el-tag
                    type="info"
                    size="small"
                    v-show="scope.row.student_order_sync_status == 6"
                    >已终止</el-tag
                  >
                  <el-tag
                    type="danger"
                    size="small"
                    v-show="scope.row.student_order_sync_status == 7"
                    >已结束</el-tag
                  >
                </template>
              </el-table-column>

              <el-table-column
                label="所在班级"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div
                    style="cursor: pointer; color: #409eff"
                    @click="
                      (scope.row.grade_names || scope.row.grade_names_end) &&
                        opengradeDialog(scope.row)
                    "
                  >
                    {{
                      scope.row.grade_names || scope.row.grade_names_end || "-"
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="learnhours"
                label="课程进度"
                width="150"
                align="center"
              >
                <template slot-scope="scope">
                  <div class="progress-div">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="20"
                      :percentage="formatePrecent(scope.row)"
                      :class="{ 'red-text': formatePrecent(scope.row) > 100 }"
                    ></el-progress>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="name"
                label="报考院校"
                width="160"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span class="pointer" @click="institutionOpen(scope.row)">
                    {{ `${scope.row.apply_college_name}` }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="报考专业"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>
                    {{
                      `(${scope.row.apply_major_code})${scope.row.apply_major_name}`
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="报考学院"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>
                    {{
                      `(${scope.row.apply_academy_code})${scope.row.apply_academy_name}`
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="考试方向"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.apply_direction_code &&
                      scope.row.apply_direction_name
                    "
                  >
                    {{
                      `(${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
                    }}</span
                  >
                  <span v-else>无</span>
                </template>
              </el-table-column> -->
              <el-table-column
                label="报考信息"
                width="450"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{
                    `${scope.row.apply_year}年/${
                      scope.row.apply_college_name
                    }/(${scope.row.apply_academy_code})${
                      scope.row.apply_academy_name
                    }/(${scope.row.apply_major_code})${
                      scope.row.apply_major_name.includes(
                        scope.row.apply_major_code
                      )
                        ? scope.row.apply_major_name.split(
                            `（${scope.row.apply_major_code}）`
                          )[1]
                        : scope.row.apply_major_name
                    }/`
                  }}
                  {{
                    scope.row.apply_direction_code &&
                    scope.row.apply_direction_name
                      ? `(${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
                      : "不限"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="辅导科目"
                width="160"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.subjects | formateSub }}</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="name"
                label="报名课时"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.service_hours + "小时" }}</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="coach_need"
                label="复习基础"
                width="300"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.coach_need || "无" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="refer_book"
                label="参考书目"
                width="300"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.refer_book || "无" }}
                </template>
              </el-table-column>

              <el-table-column
                prop="remark"
                label="服务备注"
                width="300"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
                  <span v-else>无</span>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="对接老师"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.manage_watcher | formateManage_Watcher
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                prop="student_learn_manager"
                label="学管老师"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{
                    scope.row.student_learn_manager
                      ? scope.row.student_learn_manager.nickname
                      : "无"
                  }}
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                prop="student_watcher"
                label="教务老师"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ getStudentWatcher(scope.row.student_watcher) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="creator"
                label="创建老师"
                width="100"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.creator">
                    {{ scope.row.creator.alias }}
                  </span>
                  <span v-else-if="scope.row.manage_creator">
                    {{ scope.row.manage_creator.realname }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="最近一条学管记录创建时间"
                width="200"
                align="center"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.last_manage_record_create_time
                      ? $moment(
                          scope.row.last_manage_record_create_time
                        ).format("YYYY-MM-DD HH:mm")
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间" width="150" align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.create_time
                      ? $moment(scope.row.create_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column label="更新时间" width="150" align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.update_time
                      ? $moment(scope.row.update_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : ""
                  }}
                </template>
              </el-table-column>

              <!-- <el-table-column label="收款总金额" width="120">
                <template slot-scope="scope">
                  {{
                    scope.row.order_receipt_amount_total
                      ? scope.row.order_receipt_amount_total + "元"
                      : "-"
                  }}
                </template>
              </el-table-column> -->
              <!-- <el-table-column prop="apply_year" label="考试年份" width="120">
              </el-table-column> -->

              <!--  <el-table-column
          prop="learnhours"
          label="课程进度"
          width="150"
        >
          <template slot-scope="scope">
            <div class="progress-div">
              <el-progress
                :text-inside="true"
                :stroke-width="26"
                :percentage="formatePrecent(scope.row)"
                @change="$forceUpdate()"
              ></el-progress>

            </div>
          </template>
        </el-table-column> -->
              <!--       <el-table-column
          prop="name"
          label="报考课程"
          width="320"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.products | formateProducts }}</span>
          </template>
        </el-table-column> -->

              <!--      <el-table-column prop="name" label="咨询老师">
          <template slot-scope="scope">
            <span>{{ scope.row.watcher | formate }}</span>
          </template>
        </el-table-column> -->

              <!-- <el-table-column
                prop="update_time"
                label="最新跟进日期"
                width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.update_time | dateFormat("YYYY-MM-DD") }}
                </template>
              </el-table-column>

              <el-table-column label="匹配师" width="130" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{
                    getManageList(scope.row.bind_allocation_order_manage_list)
                  }}</span>
                </template>
              </el-table-column> -->

              <el-table-column
                align="center"
                prop="name"
                label="操作"
                width="120"
                fixed="right"
              >
                <template slot-scope="scope">
                  <div class="templateLink">
                    <!--   <el-link
                type="info"
                :underline="false"
                v-if="scope.row.old_id"
              >已同步</el-link>
              <el-link
                type="primary"
                :underline="false"
                @click="exportData(scope.row)"
                :disabled="!$buttonPermission('serviceorder:sync')"
                v-else
              >同步</el-link> -->
                    <!-- <el-link
                      type="primary"
                      :underline="false"
                      @click="orderReceiptRecord(scope.row.id)"
                      >收款记录</el-link
                    >
                    <el-link
                      type="info"
                      :underline="false"
                      v-if="scope.row.service_status == 10"
                      >课程终止</el-link
                    > -->
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="openBatchEditWatcherDialog(scope.row)"
                      >分配</el-link
                    >

                    <el-link
                      type="primary"
                      :underline="false"
                      @click="openConfirmCollegesShow(scope.row)"
                      v-if="scope.row.service_status != 10"
                      :disabled="!$buttonPermission('serviceorder:edit')"
                      >变更</el-link
                    >

                    <el-link
                      type="success"
                      :underline="false"
                      @click="changeStatus(scope.row)"
                      v-if="scope.row.student_order_sync_status == 6"
                      >恢复</el-link
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="数据列表" name="second">
            <el-table
              :data="result"
              :header-cell-style="{
                textAlign: 'center',
                color: '#909399',
                fontFamily: 'PingFang SC',
                fontSize: '14px',
                fontWeight: '400',
                backgroundColor: '#F2F6FC',
              }"
              @selection-change="handleSelectionChange"
              border
            >
              <el-table-column
                type="selection"
                width="55"
                fixed="left"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="date"
                label="关注"
                width="69"
                fixed="left"
                align="center"
              >
                <template slot-scope="scope">
                  <img
                    alt=""
                    srcset=""
                    class="star"
                    src="../../../assets/images/star-fill.png"
                    @click="followDelete(scope.row)"
                    v-show="scope.row.follow"
                  />
                  <img
                    src="../../../assets/images/star.png"
                    alt=""
                    srcset=""
                    class="star"
                    v-show="!scope.row.follow"
                    @click="followCreate(scope.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column
                prop="create_time"
                label="报名时间"
                width="120"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="apply_year"
                label="机构简称"
                show-overflow-tooltip
                width="150px"
              >
                <template slot-scope="scope">
                  <span
                    >{{ scope.row.company.name
                    }}{{
                      scope.row.watcher && scope.row.watcher.length > 0
                        ? " / " + scope.row.watcher[0].alias
                        : ""
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="nickname"
                label="学生姓名"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="openStudentDialog(scope.row)"
                    :disabled="!$buttonPermission('serviceorder:edit')"
                    >{{ scope.row.student_realname }}</el-link
                  >
                </template>
              </el-table-column>

              <el-table-column
                prop="apply_year"
                label="订单状态"
                width="80"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tag
                    size="small"
                    v-show="scope.row.student_order_sync_status == 1"
                    >待定校</el-tag
                  >
                  <el-tag
                    size="small"
                    type="warning"
                    v-show="scope.row.student_order_sync_status == 2"
                    >待服务</el-tag
                  >
                  <el-tag
                    size="small"
                    type="warning"
                    v-show="scope.row.student_order_sync_status == 3"
                    >待对接</el-tag
                  >
                  <el-tag
                    size="small"
                    v-show="scope.row.student_order_sync_status == 4"
                    >匹配中</el-tag
                  >
                  <el-tag
                    size="small"
                    v-show="scope.row.student_order_sync_status == 5"
                    >上课中</el-tag
                  >
                  <el-tag
                    type="info"
                    size="small"
                    v-show="scope.row.student_order_sync_status == 6"
                    >已终止</el-tag
                  >
                  <el-tag
                    type="danger"
                    size="small"
                    v-show="scope.row.student_order_sync_status == 7"
                    >已结束</el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="name"
                label="报名课时"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.service_hours + "小时" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="已上课时"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.sum_student_lesson_log_lesson_hour + "小时"
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="签约单价"
                width="160"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.upFlag">
                    <el-input
                      style="width: 100px"
                      min="0"
                      v-model="scope.row.ref_hour_income_company_amount"
                      placeholder="请输入"
                      type="number"
                    ></el-input>
                    <i
                      style="margin-left: 5px"
                      class="el-icon-finished"
                      @click="upSignContract(scope.row)"
                    ></i>
                  </div>
                  <div v-else>
                    {{ scope.row.ref_hour_income_company_amount + "元/小时" }}
                    <i
                      class="el-icon-edit-outline"
                      @click="upSign(scope.row)"
                    ></i>
                  </div>
                  <!-- <span>{{
                    scope.row.ref_hour_income_company_amount + "元/小时"
                  }}</span> -->
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="总实收已收费"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.ref_exist_company_order_amount }}元</span>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="总待收费"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    >{{
                      scope.row.ref_prepare_company_order_amount || 0
                    }}元</span
                  >
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="已收课时费"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    >{{
                      scope.row.ref_exist_company_lesson_amount || 0
                    }}元</span
                  >
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="待收课时费"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    >{{
                      scope.row.ref_prepare_company_lesson_amount || 0
                    }}元</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="已收学生其他费"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    >{{
                      scope.row.ref_exist_student_level_other_amount || 0
                    }}元</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="待收学生其他费"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    >{{
                      scope.row.ref_prepare_student_level_other_amount || 0
                    }}元</span
                  >
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="总已收费"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    >{{
                      scope.row.ref_company_total_receive_amount || 0
                    }}元</span
                  >
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="基础已收费"
                width="140"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.upRefFlag">
                    <el-input
                      style="width: 100px"
                      min="0"
                      v-model="scope.row.ref_company_basic_receive_amount"
                      placeholder="请输入"
                      type="number"
                    ></el-input>
                    <i
                      style="margin-left: 5px"
                      class="el-icon-finished"
                      @click="upRefCompany(scope.row)"
                    ></i>
                  </div>
                  <div v-else>
                    {{ scope.row.ref_company_basic_receive_amount + "元" }}
                    <i
                      class="el-icon-edit-outline"
                      @click="upRef(scope.row)"
                    ></i>
                  </div>
                  <!-- <span>{{
                    scope.row.ref_company_basic_receive_amount || 0 
                  }}元</span> -->
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="老师时薪"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ getTeacherMoney(scope.row.ref_arr_teacher_hour_amount) }}
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="已发课酬"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.ref_exist_senior_order_amount + "元"
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="待发课酬"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.ref_prepare_senior_order_amount + "元"
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="其他费用"
                width="160"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.restFlag">
                    <el-input
                      style="width: 100px"
                      min="0"
                      v-model="scope.row.ref_student_other_company_amount"
                      placeholder="请输入"
                      type="number"
                    ></el-input>
                    <i
                      style="margin-left: 5px"
                      class="el-icon-finished"
                      @click="upRestMoney(scope.row)"
                    ></i>
                  </div>
                  <div v-else>
                    {{ scope.row.ref_student_other_company_amount || 0 }}元
                    <i
                      class="el-icon-edit-outline"
                      @click="upRest(scope.row)"
                    ></i>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="当前利润"
                width="120"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.ref_pure_earn_amount || 0 }}元</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="对接老师"
                width="130"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.manage_watcher | formateManage_Watcher
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="最近上课时间" width="150" align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.ref_last_lesson_create_time
                      ? $moment(scope.row.ref_last_lesson_create_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "-"
                  }}
                </template>
              </el-table-column>

              <el-table-column label="最近收款时间" width="150" align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.ref_last_company_order_time
                      ? $moment(scope.row.ref_last_company_order_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="name"
                label="操作"
                width="120"
                fixed="right"
              >
                <template slot-scope="scope">
                  <div class="templateLink">
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="orderReceiptRecord(scope.row.id)"
                      >收款记录</el-link
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- <el-form :inline="true" label-width="70px" size="small">
        <el-form-item label="只看关注">
          <el-switch
            v-model="query.follow"
            :active-value="1"
            inactive-value=" "
            @change="fetchList(true)"
          >
          </el-switch>
        </el-form-item>
      </el-form> -->

      <div style="margin-top: 20px">
        <el-button
          size="small"
          type="primary"
          @click="chooseAllEdit(1, '您确定要归档吗')"
          v-show="query.archive == 0"
          :disabled="!$buttonPermission('serviceorder:batch')"
          >归档</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="chooseAllEdit(0, '您确定要取消归档吗')"
          v-show="query.archive == 1"
          :disabled="!$buttonPermission('serviceorder:batch')"
          >取消归档</el-button
        >
        <el-button
          type="danger"
          size="mini"
          @click="filterArr"
          :disabled="!$buttonPermission('serviceorder:batch')"
          >终止</el-button
        >
        <el-button
          type="danger"
          size="mini"
          @click="deleteItem"
          :disabled="!$buttonPermission('serviceorder:batch')"
          >删除</el-button
        >

        <!-- <el-button
          size="small"
          type="primary"
          @click="openBatchEditWatcherDialog"
          v-if="$buttonPermission('serviceorder:batchWatcher')"
          >分配</el-button
        > -->
        <!-- <el-button
          size="small"
          type="primary"
          @click="editLearn_manager"
          >指定学管师</el-button
        > -->
        <!-- <el-button size="mini" @click="editLearn_manager">指定学管师</el-button> -->
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddServiceStudent
      :btnFlag="btnFlag"
      :addForm="addForm"
      :isVisible="AddServiceStudentShow"
      @close="AddServiceStudentShow = false"
      @submit="addStudent"
      @continueSubmit="continueAddStudent"
    >
    </AddServiceStudent>
    <el-dialog
      top="5vh"
      title="学员信息"
      :visible.sync="studentDialog"
      width="570px"
    >
      <el-form
        :model="studentForm"
        label-width="auto"
        label-position="left"
        size="small"
      >
        <el-form-item label="姓名/昵称：" prop="student_realname">
          <el-input
            v-model="studentForm.student_realname"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input
            placeholder=""
            v-model="studentForm.student_phone"
            class="input-with-select"
          >
            <template slot="prepend">手机号</template>
          </el-input>
          <el-input
            placeholder=""
            v-model="studentForm.student_wechat"
            class="input-with-select"
          >
            <template slot="prepend">微信号</template>
          </el-input>
          <el-input
            placeholder=""
            v-model="studentForm.student_qq"
            class="input-with-select"
          >
            <template slot="prepend">&nbsp;QQ号</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="studentDialog = false">取 消</el-button>
        <el-button type="primary" @click="editInfo">确 定</el-button>
      </div>
    </el-dialog>
    <ConfirmColleges
      :content="ConfirmCollegesContent"
      :isVisible="ConfirmCollegesShow"
      @close="ConfirmCollegesShow = false"
      @submit="ConfirmCollegesSubmit"
    ></ConfirmColleges>
    <BatchEditWatcher
      :isVisible="BatchEditWatcherDialog"
      :learn_manager_id="learn_manager_id"
      @close="BatchEditWatcherDialog = false"
      @submit="BatchEditWatcherSubmit"
    ></BatchEditWatcher>

    <CollectionRecord ref="collectionRecord"></CollectionRecord>
    <CollectionRecordAdd ref="collectionRecordAdd"></CollectionRecordAdd>
    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>
    <GradeList
      :isVisible="gradeDialog"
      @close="gradeDialog = false"
      :student_realname="student_realname"
      :student_id="nowStudent_id"
    />

    <AppointLearnManager
      :isVisible="AppointLearnManagerDialog"
      @close="AppointLearnManagerDialog = false"
      @submit="fetchList"
      :ids="editIds"
    />
  </div>
</template>

<script>
import deviceLine from "../../../components/Line/Line.vue";
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddServiceStudent from "../../../components/DialogComponents/AddServiceStudent/AddServiceStudent.vue";
import ConfirmColleges from "../../../components/DialogComponents/ConfirmColleges/ConfirmColleges.vue";
import BatchEditWatcher from "../../../components/DialogComponents/BatchEditWatcher/BatchEditWatcher.vue";
import GradeList from "../../../components/TheClass/GradeList.vue";
import AppointLearnManager from "@/components/DialogComponents/ClassService/AppointLearnManager.vue";
import {
  studentorder,
  studentorderlist,
  deletestudentorderlist,
  editstudentorder,
  createuserfollow,
  deleteuserfollow,
  editstudentorderlist,
  outsourcing,
  crmOutrecorddetail,
  outsourcingedit,
  getUserStudentUnreadMsg,
  companyuserlist,
} from "../../../request/api";
import { formateObj } from "../../../utils/helper";
import moment from "moment";
import TeacherRecruitInfoVue from "../../../components/DialogComponents/ClassService/TeacherRecruitInfo.vue";
import Handle from "../../TeacherResources/share/handle.vue";
import CollectionRecord from "@/components/DialogComponents/CollectionRecord/CollectionRecord.vue";
import CollectionRecordAdd from "@/components/DialogComponents/CollectionRecord/CollectionRecordAdd.vue";
import InstitutionDetails from "@/components/DialogComponents/InstitutionDetails/index.vue";
import { log } from "mathjs";
export default {
  components: {
    deviceLine,
    TableTittle,
    AddServiceStudent,
    ConfirmColleges,
    BatchEditWatcher,
    CollectionRecord,
    CollectionRecordAdd,
    InstitutionDetails,
    GradeList,
    AppointLearnManager,
  },
  data() {
    return {
      AppointLearnManagerDialog: false,
      editIds: [],
      btnFlag: false,
      addForm: {},
      formInline: {},
      studentForm: {},
      activeName: "first",
      filtrate: "1",
      result: [],
      deleteList: [],
      AddServiceStudentShow: false,
      ConfirmCollegesShow: false,
      ConfirmCollegesContent: {},
      studentDialog: false,
      addRecordDialog: false,
      rowInfo: {},
      lock: true,
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        keyword: "",
        archive: 0,
      },
      hoursObj: {},
      // 批量修改watcher弹窗
      BatchEditWatcherDialog: false,
      end_advance_time: "",
      create_time: "",
      advance_time: "",
      //
      timestampList: [
        {
          label: "1天内",
          value: 86400000,
          id: 1,
          type: 1,
        },
        {
          label: "7天内",
          value: 86400000 * 7,
          id: 2,
          type: 1,
        },
        {
          label: "超7天",
          value: 86400000 * 7,
          id: 3,
          type: 0,
        },
        {
          label: "超15天",
          value: 86400000 * 15,
          id: 4,
          type: 0,
        },
        {
          label: "超30天",
          value: 86400000 * 30,
          id: 5,
          type: 0,
        },
      ],
      timeArr: [
        {
          name: "一天",
          timestamp: Date.now() - 1 * 24 * 60 * 60 * 1000,
        },
        {
          name: "三天",
          timestamp: Date.now() - 3 * 24 * 60 * 60 * 1000,
        },
        {
          name: "一周",
          timestamp: Date.now() - 7 * 24 * 60 * 60 * 1000,
        },
        {
          name: "两周",
          timestamp: Date.now() - 14 * 24 * 60 * 60 * 1000,
        },
        {
          name: "一个月",
          timestamp: Date.now() - 30 * 24 * 60 * 60 * 1000,
        },
        {
          name: "两个月",
          timestamp: Date.now() - 60 * 24 * 60 * 60 * 1000,
        },
        {
          name: "三个月",
          timestamp: Date.now() - 90 * 24 * 60 * 60 * 1000,
        },
      ],
      filters: {
        student_order_sync_statuses: [
          { value: 1, label: "待定校" },
          { value: 2, label: "待服务" },
          { value: 3, label: "待对接" },
          { value: 4, label: "匹配中" },
          { value: 5, label: "上课中" },
          { value: 6, label: "已终止" },
          { value: 7, label: "已结束" },
        ],
      },
      student_unread_msg: [],
      nowStudent_id: null,
      student_realname: null,
      gradeDialog: false,
      affairsTeacherList: [],
      distributionNum: 0,
      batchEditWatcheId: null,
      learn_manager_id: "",
      company_order_time: "",
    };
  },
  mounted() {
    console.log("router", this.$router);
    this.parseQuery();
    this.fetchList();
    this.getDistribution();
    this.$store.dispatch("getCompanyList", { data_range_tags: "2" });
    this.$store.dispatch("getAllColleges");
    this.getAffairsTeacher();
  },
  filters: {
    formate: function (val) {
      var str = "";
      val.forEach((item) => {
        str += item.alias + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formateSub: function (val) {
      if (val == null || val == 1) {
        return "";
      }
      var str = "";
      val.forEach((item) => {
        str += `(${item.subject_code})${item.subject_name}` + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formateProducts: function (val) {
      if (val == null || val == 1) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.product_name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formateManage_Watcher(val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.nickname + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formateWatcher: function (val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.alias + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
  },
  watch: {
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_apply_time = this.$moment(value[0]).valueOf();
        this.query.end_apply_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_apply_time = "";
        this.query.end_apply_time = "";
        this.fetchList(true);
      }
    },
    advance_time(value) {
      if (value) {
        this.query.start_advance_time = this.$moment(value[0]).valueOf();
        this.query.end_advance_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_advance_time = "";
        this.query.end_advance_time = "";
        this.fetchList(true);
      }
    },
  },
  methods: {
    changeStatus(row) {
      this.$confirm("您确定要恢复订单吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            id: row.id,
            service_status: 50,
          };
          editstudentorder(query).then((res) => {
            this.fetchList();
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消操作",
          });
        });
    },

    upRestMoney(row) {
      editstudentorder({
        id: row.id,
        ref_student_other_company_amount: Number(
          row.ref_student_other_company_amount
        ),
      }).then((res) => {
        this.fetchList();
        // console.log('res:', res)
      });
    },
    upRest(row) {
      this.result.find((item, index) => {
        if (row.id == item.id) {
          this.$set(this.result[index], "restFlag", true);
        }
      });
    },

    upSignContract(row) {
      editstudentorder({
        id: row.id,
        student_order_fix_company_amount: Number(
          row.ref_hour_income_company_amount
        ),
      }).then((res) => {
        this.fetchList();
        // console.log('res:', res)
      });
    },
    upSign(row) {
      this.result.find((item, index) => {
        if (row.id == item.id) {
          this.$set(this.result[index], "upFlag", true);
          // item.upFlag = true;
        }
      });
      // console.log('item.upFlag:',this.result)
    },
    upRefCompany(row) {
      editstudentorder({
        id: row.id,
        ref_company_basic_receive_amount: Number(
          row.ref_company_basic_receive_amount
        ),
      }).then((res) => {
        this.fetchList();
        // console.log('res:', res)
      });
    },
    upRef(row) {
      this.result.find((item, index) => {
        if (row.id == item.id) {
          this.$set(this.result[index], "upRefFlag", true);
          // item.upFlag = true;
        }
      });
    },

    getTeacherMoney(list) {
      if (list && list.length) {
        return list.join("/") + "元";
      } else {
        return "-";
      }
    },
    getAffairsTeacher() {
      let query = {
        isPaging: 0,
        cid: localStorage.getItem("cid"),
      };
      companyuserlist(query).then((res) => {
        this.affairsTeacherList = res.data.data.list || [];
        // console.log('this.affairsTeacherList:', this.affairsTeacherList)
      });
    },
    formatePrecent(row) {
      if (row.student_course_progress) {
        return Number((Number(row.student_course_progress) * 100).toFixed(1));
      } else {
        return 0;
      }
    },
    getStudentWatcher(list) {
      if (list && list.length) {
        return list.map((item) => item.alias).join(",");
      } else {
        return "无";
      }
    },
    opengradeDialog(row) {
      this.nowStudent_id = row.student_id;
      this.student_realname = row.student_realname;
      this.gradeDialog = true;
    },
    handleClick(tab, event) {
      if (tab.name == "first") {
        this.query.is_statistic_data_table = "";
        this.fetchList(true);
      } else {
        this.query.is_statistic_data_table = 1;
        this.fetchList(true);
      }
    },
    filtrateChange(e) {
      this.query.follow = 0;
      this.query.is_has_student_learn_manager = "";
      if (e == 2) {
        this.query.follow = 1;
      } else if (e == 3) {
        this.query.is_has_student_learn_manager = 0;
      }
      this.fetchList(true);
    },

    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.apply_college_code);
    },
    getManageList(list) {
      if (list && list.length > 0) {
        return list.map((item) => item.nickname).join(" / ");
      } else {
        return "-";
      }
    },
    orderReceiptRecord(id) {
      this.$refs.collectionRecord.open("1", id);
    },
    handleCollectionRecord() {
      this.$refs.collectionRecord.open("1");
    },
    handleADD() {
      this.addForm = {};
      this.AddServiceStudentShow = !this.AddServiceStudentShow;
    },
    formateServiceStatus(val) {
      let obj = {
        0: "无",
        1: "待定校",
        2: "待服务",
        3: "正在对接",
        4: "正在匹配",
        5: "匹配成功",
        6: "匹配失败",
        7: "开始上课",
        8: "正在上课",
        9: "课程结束",
        10: "课程终止",
      };
      return obj[val] || "";
    },
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val;
    },
    handleSizeChange(val) {
      this.hoursObj = {};
      this.query.pageSize = val;
      this.fetchList();
    },
    handleCurrentChange(val) {
      this.hoursObj = {};
      this.query.pageIndex = val;
      this.fetchList();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
      if (this.query.cid) {
        this.query.cid = Number(this.query.cid);
      }
      if (
        this.query.student_order_sync_statuses &&
        this.query.student_order_sync_statuses.length
      ) {
        this.query.student_order_sync_statuses =
          this.query.student_order_sync_statuses.split(",").map((v) => {
            return Number(v);
          });
      }
      if (this.query.start_create_time && this.query.end_create_time) {
        this.create_time = [
          this.$moment(Number(this.query.start_create_time)),
          this.$moment(Number(this.query.end_create_time)),
        ];
      }
      if (this.query.start_advance_time && this.query.end_advance_time) {
        this.advance_time = [
          this.$moment(Number(this.query.start_advance_time)),
          this.$moment(Number(this.query.end_advance_time)),
        ];
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      if (params.student_order_sync_statuses) {
        params.student_order_sync_statuses = params.student_order_sync_statuses
          .filter((v) => v)
          .join(",");
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },

    getDistribution() {
      let query = {
        isPaging: 0,
        cid: localStorage.getItem("cid"),
        is_has_student_learn_manager: 0,
        archive: 0,
      };
      studentorderlist(query).then((response) => {
        let res = response.data;
        this.distributionNum = res.data.count || 0;
        // console.log('this.distributionNum:', this.distributionNum)
      });
    },
    fetchList(isReset = false) {
      // console.log('ppp',this.query.manage_watcher_id);
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      let params = Object.assign({}, this.query);
      // if(this.query.manage_watcher_id&&this.query.manage_watcher_id.length>0){
      //   params.manage_watcher_id = this.query.manage_watcher_id
      // }

      // 创建时间区间处理
      if (this.end_advance_time) {
        this.query.end_advance_time = this.end_advance_time;
      }
      if (params.student_order_sync_statuses) {
        params.student_order_sync_statuses =
          params.student_order_sync_statuses.join(",");
      }

      if (this.company_order_time && this.company_order_time.length) {
        params.start_last_company_order_time = this.company_order_time[0];
        params.end_last_company_order_time = this.company_order_time[1];
      }
      if(this.query.last_manage){
        let obj = this.timestampList.find(item=>item.id == this.query.last_manage)
        params.is_exist_manage_record  = obj.type
        params.start_last_manage_record_time =this.$moment().unix() * 1000 - obj.value
        params.end_last_manage_record_time =this.$moment().unix() * 1000
      }
      delete params.last_manage
      studentorderlist(params)
        .then((response) => {
          // console.log('response:', response)
          let res = response.data;
          this.result = res.data.list || [];
          this.pageTotal = res.data.count;
          if (this.result && this.result.length) {
            let student_ids = this.result.map((item) => item.student_id);
            this.userStudentUnreadMsg(student_ids);
          }

          /*      this.result.forEach((item, index) => {
               this.$set(item, 'learnhours', 0) // 设置属性
               if (item.old_id == null) {
                 return false
               }
               let forQuery = {
                 id: item.old_id,
                 newId: item.id,
               }
               // 请求每一条数据的详情
               crmOutrecorddetail(forQuery).then((response_other) => {
                 if (response_other.data.data.message == "ok") {
                   let arr = response_other.data.data.data.records
                   // 判断，为空则不计算
                   if (arr.length > 0) {
                     // 求和
                     let a = arr.reduce((acc, cur) => {
                       return acc + cur.hours
                     }, 0)
                     // 赋值
                     item.learnhours = a
                   }
                 }
                 this.$set(this.hoursObj, index, item.learnhours)
               })
             }) */
        })
        .then(() => {
          /*   setTimeout(() => {
            this.getListTwice(params)
          }, 100); */
        });
      // this.syncQuery();
    },
    userStudentUnreadMsg(ids) {
      // console.log('ids:', ids)

      getUserStudentUnreadMsg({
        arr_student_id: ids,
      }).then((res) => {
        if (res.data.code == 0) {
          this.student_unread_msg =
            res.data.data.arr_map_student_unread_msg.map((item) => item[1]);
          // console.log('this.student_unread_msg:', this.student_unread_msg)
        }
        // console.log("res",res);
      });
    },
    // 获取表格列表

    getListTwice(query) {
      studentorderlist(query).then((res) => {
        this.result = res.data.data.list || [];
        this.pageTotal = res.data.data.count;
        if (JSON.stringify(this.hoursObj) != "{}") {
          this.result.forEach((item, index) => {
            this.$set(item, "learnhours", this.hoursObj[index]);
          });
        }
      });
    },
    //
    continueAddStudent({ form }) {
      let query = form;
      this.addForm = {
        apply_year: query.apply_year,
        cid: query.cid,
        manage_watcher: [],
        watcher: [],
        learn_manager: query.learn_manager,
      };
      this.addForm.manage_watcher = [];
      this.addForm.watcher = [];
      if (query.manage_watcher) {
        query.manage_watcher.split(",").map((item) => {
          this.addForm.manage_watcher.push(Number(item));
        });
      }
      if (query.watcher) {
        query.watcher.split(",").map((item) => {
          this.addForm.watcher.push(Number(item));
        });
      }

      //   console.log("query",this.addForm)
      studentorder(query).then((res) => {
        this.AddServiceStudentShow = false;
        this.btnFlag = true;
        this.fetchList();
        setTimeout(() => {
          this.AddServiceStudentShow = true;
        }, 1000);
      });
    },

    addStudent({ form }) {
      let query = form;
      studentorder(query).then((res) => {
        this.AddServiceStudentShow = false;
        this.btnFlag = true;
        this.fetchList();
      });
    },
    openStudentDialog(item) {
      this.studentForm = JSON.parse(JSON.stringify(item));
      this.studentDialog = true;
    },
    deleteItem() {
      this.$confirm("确定要删除选中的数据吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            ids: [],
          };
          this.deleteList.forEach((item) => {
            query.ids.push(item.id);
          });
          deletestudentorderlist(query).then((res) => {
            this.$nextTick(() => {
              this.fetchList();
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    editStatus(status, id, text, cid) {
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            service_status: status,
            id: id,
            cid: cid,
          };
          editstudentorder(query).then((res) => {
            this.$nextTick(() => {
              this.fetchList();
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消修改",
          });
        });
    },
    screenTable({ screenForm }) {
      this.$set(this.query, "pageIndex", 1);
      this.query = {
        ...this.query,
        ...screenForm,
      };
      this.fetchList();
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 1,
          follow_id: item.id,
        };
        createuserfollow(query).then((res) => {
          this.fetchList();
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 500);
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 1,
          follow_id: item.id,
        };
        deleteuserfollow(query).then((res) => {
          this.fetchList();
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 300);
      }
    },
    // 搜索框搜索
    inputSearch(e) {
      this.fetchList();
    },
    editInfo() {
      if (!/^1[123456789]\d{9}$/.test(this.studentForm.student_phone)) {
        this.$message({
          message: "请填写正确的手机号",
          type: "error",
          duration: 1000,
        });
        return false;
      }
      let query = {
        id: this.studentForm.id,
        student_realname: this.studentForm.student_realname,
        student_qq: this.studentForm.student_qq,
        student_wechat: this.studentForm.student_wechat,
        student_phone: this.studentForm.student_phone,
      };
      editstudentorder(query).then((res) => {
        this.$nextTick(() => {
          this.studentDialog = false;
          this.fetchList();

          /*    if (this.studentForm.old_id == null) {
               this.fetchList();
               return false
             }
             // 总端修改成功之后还要再调一次老系统的接口同步数据
             outsourcingedit(formateObj(this.studentForm, 'edit')).then(response => {
               if (response.data.data.message == "ok") {
                 this.fetchList();
               } else {
                 this.$notify.error({
                   title: "提示",
                   message: response.data.data.message,
                 });
               }
             }) */
        });
      });
    },
    openConfirmCollegesShow(item) {
      // console.log(item);
      this.ConfirmCollegesContent = JSON.parse(JSON.stringify(item));
      this.formateStudent();
      this.ConfirmCollegesShow = true;
    },
    // 确定院校提交
    ConfirmCollegesSubmit({ form }) {
      let query = form;
      editstudentorder(query).then((res) => {
        this.ConfirmCollegesShow = false;
        this.fetchList();
        /* 
                if (form.old_id == null) {
                  this.fetchList();
                  return false
                }
                this.$nextTick(() => {
                  // 总端修改成功之后还要再调一次老系统的接口同步数据
                  outsourcingedit(formateObj(form, 'edit')).then(response => {
                    if (response.data.data.message == "ok") {
                      this.fetchList();
                    } else {
                      this.$notify.error({
                        title: "提示",
                        message: response.data.data.message,
                      });
                      this.fetchList();
                    }
                  })
                }); */
      });
    },
    formateStudent() {
      // 回显做处理
      // 把watcher参数转换为处理并转换字符串，必须要number格式
      if (this.ConfirmCollegesContent.watcher) {
        let arr = [];
        this.ConfirmCollegesContent.watcher.forEach((item) => {
          arr.push(item.uid);
        });
        arr = arr.map((v) => Number(v));
        this.ConfirmCollegesContent.watcher = arr;
      }
      // 把subjects参数转换为处理并转换字符串，必须要number格式
      if (this.ConfirmCollegesContent.subjects) {
        let arr3 = [];
        this.ConfirmCollegesContent.subjects.forEach((item) => {
          arr3.push(item.subject_code);
        });
        this.ConfirmCollegesContent.subjects = arr3;
      }
    },
    goRecord(row) {
      window.open(
        `/classservice/student/record?student_id=${row.student_id}&name=${row.student_realname}`
      );
      // this.$router.push(
      //   `/classservice/student/record?student_id=${row.student_id}&name=${row.student_realname}`
      // );
      // this.$router.push({
      //   name: `servicerecord`,
      //   query: item,
      // });
    },
    // 对多选数组做判断处理
    filterArr() {
      if (this.deleteList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      let flag = this.deleteList.findIndex((item) => item.service_status == 10);
      if (flag == -1) {
        this.$confirm("您确定要终止吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let query = {
              rows: [],
            };
            query.rows = this.deleteList.map((v) => {
              let obj = {
                id: v.id,
                service_status: 10,
              };
              return obj;
            });

            editstudentorderlist(query).then((res) => {
              if (res.data.code == 0) {
                this.$notify.success({
                  title: "提示",
                  message: "操作成功",
                });
                this.fetchList();
              }
            });
          })
          .catch(() => {
            this.$notify.error({
              title: "提示",
              message: "已取消操作",
            });
          });
      } else {
        this.$notify.error({
          title: "提示",
          message: "选中订单中部分订单已处于终止状态",
        });
      }
    },

    exportData(item) {
      outsourcing(formateObj(item, "create")).then((res) => {
        if (res.data.data.message == "ok") {
          editstudentorder({
            id: res.data.data.data.newId,
            old_id: res.data.data.data.id,
          }).then((resp) => {
            this.fetchList();
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: res.data.data.message,
          });
        }
      });
    },
    // formatePrecent(row) {
    //   let num = (
    //     (Number(row.learnhours) / Number(row.service_hours)) *
    //     100
    //   ).toFixed(0);
    //   if (isNaN(num)) {
    //     return 0;
    //   } else {
    //     return Number(num);
    //   }
    // },
    editLearn_manager() {
      if (this.deleteList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.editIds = this.deleteList;
      this.AppointLearnManagerDialog = true;
    },

    openBatchEditWatcherDialog(row) {
      // if (this.deleteList.length == 0) {
      //   this.$notify.error({
      //     title: "提示",
      //     message: "还没有选择要修改的数据",
      //   });
      //   return false;
      // }
      this.batchEditWatcheId = row.id;
      this.learn_manager_id = row.student_learn_manager
        ? row.student_learn_manager.id
        : null;
      this.BatchEditWatcherDialog = true;
    },
    BatchEditWatcherSubmit(obj) {
      // let query = {
      //   rows: this.deleteList.map((v) => {
      //     return {
      //       id: v.id,
      //       manage_watcher: obj.watcher,
      //       update_time:new Date().getTime()
      //     };
      //   }),
      // };
      let query = {
        rows: [
          {
            id: this.batchEditWatcheId,
            learn_manager: obj.watcher,
            update_time: new Date().getTime(),
          },
        ],
      };
      editstudentorderlist(query).then((res) => {
        this.$nextTick(() => {
          this.fetchList();
          this.$notify.success({
            title: "提示",
            message: "修改成功",
          });
          this.BatchEditWatcherDialog = false;
        });
      });
    },
    // 批量修改
    chooseAllEdit(archive_status, text) {
      if (this.deleteList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            rows: [],
          };
          this.deleteList.forEach((item) => {
            let obj = {
              id: item.id,
              archive: archive_status,
            };
            query.rows.push(obj);
          });
          editstudentorderlist(query).then((res) => {
            this.$nextTick(() => {
              this.fetchList();
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消修改",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.tabulation {
  /deep/ .el-tabs__nav-wrap::after {
    height: 0px !important;
  }
  /deep/.el-badge__content.is-fixed {
    right: 15px !important;
  }
}
/deep/.el-badge__content.is-fixed {
  z-index: 99 !important;
}
.badge {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  height: 35px;
  justify-content: space-between;
}

.operation-menu {
  display: flex;
  justify-content: space-between;

  .menu_left {
    margin-top: 25px;
  }

  .menu_right {
    margin-top: 25px;
  }
}

.star {
  width: 18px;
  height: 18px;
}

.block {
  float: right;
  display: flex;
  justify-content: center;
}

.input-with-select {
  margin-bottom: 16px;
}

.templateLink {
  width: 100%;

  .el-link {
    margin: 0px 5px;
  }
}
</style>