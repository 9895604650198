<template>
    <div class="content">
        <el-dialog top="5vh" :title="title" :visible.sync="dialogFormVisible" @close="dialogFormVisible = false"
            :close-on-click-modal="false" width="620px">
            <el-form :model="dataForm" label-position="top" ref="dataForm" label-width="100px" size="small">
                <div v-for="(item, index) in subject_reference_list" :key="index">
                    <el-form-item  prop="course_theme">
                        <div slot="label" class="formLable">
                            <div>专业课名称</div>
                            <el-link v-if="index!=0" @click="delProfessionalCourses(index)">删除</el-link>
                        </div>
                        <el-input v-model.trim="item.subject_name" placeholder="请输入专业课名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="参考书" prop="course_theme" v-for="(its, ins) in item.reference_list" :key="ins">
                        <div class="area">
                            <el-input type="textarea" placeholder="例：孙发友：《新闻报道写作通论》，人民出版社，2006年版"
                                v-model="its.refence_name">
                            </el-input>
                            <img :src="require('/src/assets/images/close.png')" alt="" v-if="ins != 0"
                                @click="delReferenceBook(index, ins)">
                        </div>
                    </el-form-item>
                    <div class="footer" v-if="index != subject_reference_list.length - 1">
                        <el-button type="primary" icon="el-icon-plus" size="small" class="ele-btn"
                            @click="addReferenceBook(index)" plain>新增参考书</el-button>
                    </div>
                    <div v-else>
                        <div>
                            <el-button type="primary" icon="el-icon-plus" size="small" style="width: 100%;"
                                @click="addProfessionalCourses()" plain>新增专业课</el-button>
                        </div>
                        <div style="margin-top: 5px;">
                            <el-button type="primary" icon="el-icon-plus" size="small" style="width: 100%;"
                                @click="addReferenceBook(index)" plain>新增参考书</el-button>
                        </div>
                    </div>

                </div>
                <div class="footer">
                    <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
                    <el-button type="primary" @click="submit" plain size="small">确定</el-button>
                </div>
            </el-form>

        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            subject_reference_list: [],
            dataForm: {},
            dialogFormVisible: false,
            title: '推荐参考书'
        }
    },
    created() {

    },
    methods: {
        open(data) {
            // console.log(data)
            if (data && data.length > 0) {
                this.subject_reference_list = JSON.parse(JSON.stringify(data))
            } else {
                this.subject_reference_list = [
                    {
                        reference_list: [{
                            refence_name: ''
                        }],
                        subject_name: ""
                    }
                ]
            }
            this.dialogFormVisible = true
        },

        delProfessionalCourses(index) {
				this.subject_reference_list.splice(index, 1)
			},

        // 提交
        submit() {
            this.subject_reference_list = this.subject_reference_list.filter(item => item.subject_name)
            this.subject_reference_list.forEach((item, index) => {
                item.reference_list = item.reference_list.filter(its => its.refence_name)
            })
            this.$emit('submit',this.subject_reference_list)
            this.dialogFormVisible = false
        },
        delReferenceBook(index, ins) {
            this.subject_reference_list[index].reference_list.splice(ins, 1)
        },
        // 新增专业课
        addProfessionalCourses() {
            this.subject_reference_list.push({
                reference_list: [{
                    refence_name: ''
                }],
                subject_name: ""
            })
        },
        addReferenceBook(index) {
            this.subject_reference_list[index].reference_list.push({
                refence_name: ''
            })
        },
    }
}
</script>

<style lang="less" scoped>
.ele-btn {
    width: 100%;
    margin-top: -20px;
    margin-bottom: 15px;
}

.formLable{
    width: 580px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.area {
    position: relative;

    img {
        width: 16px;
        position: absolute;
        top: -5px;
        right: -5px;
        cursor: pointer;
    }


}
</style>