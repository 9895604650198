<template>
  <div>
    <el-dialog append-to-body top="5vh" title="记录" :visible.sync="dialogFormVisible" @close="handleToClose" :close-on-click-modal="false"
      width="700px">
      <el-form :model="dataForm" label-position="right" ref="formRules" :rules="rules" label-width="100px" size="small">
        <el-form-item label="类别：" prop="type">
          <el-select v-model="dataForm.type" placeholder="请选择" style="width: 275px;" @change="typeChange">
            <el-option-group v-for="group in options" :key="group.label" :label="group.label">
              <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="辅导课时：" prop="sign_teacher_lesson_hour" v-if="dataForm.type == 5">
          <el-select v-model="countSelect" placeholder="请选择" style="width: 100px;" @change="countSelectChange">
            <el-option label="全部" :value="1">
            </el-option>
            <el-option label="1/2" :value="2">
            </el-option>
            <el-option label="1/3" :value="3">
            </el-option>
            <el-option label="1/4" :value="4">
            </el-option>
            <el-option label="自定义" :value="5">
            </el-option>
          </el-select>
          <el-input v-model="dataForm.sign_teacher_lesson_hour" placeholder="请输入" style="width: 160px;margin-left: 12px;"
            oninput="value=value.replace(/[^0-9.]/g,'')" :readonly="countSelect != 5">
            <template slot="append"> 小时</template>
          </el-input>
        </el-form-item>
        <el-form-item label="课时费：" prop="sign_teacher_lesson_hour_amount" v-if="dataForm.type == 5">
          <el-input v-model="dataForm.sign_teacher_lesson_hour_amount" placeholder="请输入" style="width: 275px;"
            oninput="value=value.replace(/[^0-9.]/g,'')">
            <template slot="append">元/小时</template>
          </el-input>
        </el-form-item>
        <div :class="(dataForm.type == 6 || dataForm.type == 8) && teacher_id ? 'record' : ''">
          <el-form-item label="内容：" prop="record">
            <el-input v-model="dataForm.record" type="textarea" placeholder="请输入内容或直接粘贴图片" style="width: 100%;"
              @paste.native="handlePaste"></el-input>
          </el-form-item>
        </div>



        <el-form-item label="" v-if="dataForm.type == 6 && teacher_id">
          <el-link :underline="false" type="primary" @click="dataForm.record = '已经申请添加老师 QQ，等待通过。'">
            已经申请添加老师 QQ，等待通过。
          </el-link>
          |
          <el-link :underline="false" type="primary" @click="dataForm.record = '已发送短信，等待回复。'">
            已发送短信，等待回复。
          </el-link>
        </el-form-item>

        <el-form-item label="" v-if="dataForm.type == 8 && teacher_id">
          <el-link :underline="false" type="primary" @click="dataForm.record = '已拨打电话，未接听。'">
            已拨打电话，未接听。
          </el-link>
          |
          <el-link :underline="false" type="primary" @click="dataForm.record = '已拨打电话，是空号。'">
            已拨打电话，是空号。
          </el-link>
          |
          <el-link :underline="false" type="primary" @click="dataForm.record = '电话沟通反馈说：'">
            电话沟通反馈说：
          </el-link>
        </el-form-item>
        <!-- <el-form-item
          label="提醒："
          prop="alt_manage_ids"
        >
          <el-select
            v-model="dataForm.alt_manage_ids"
            placeholder="请选择"
            clearable
            multiple
            style="width: 100%x;"
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="图片：" prop="">
          <!--    <el-input
            type="textarea"
            :rows="2"
            placeholder="粘贴图片即可上传"
            v-model="uploadImg"
            style="margin-bottom: 16px;"
            readonly
          >
          </el-input> -->
          <el-upload :action="imgUploadUrl" list-type="picture-card" :file-list="fileList" :limit="3"
            :on-success="handleSuccess">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button type="primary" @click="submit('formRules')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  taRecordCreate,
  teacherOne,
  taOne,
  teacherEdit
} from "@/request/api";
import {
  getStorage
} from "@/utils/helper";
import { imgUploadUrl } from "@/request/http";

export default {
  components: {
  },
  props: {
    teacher_id:{
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
    order_id: {
      type: Number,
      default: null,
    },
    isSign: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
        type: ''
      },
      rules: {
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        record: [{ required: true, message: "请输入内容", trigger: "blur" }],
        sign_teacher_lesson_hour_amount: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.dataForm.sign_teacher_lesson_hour_amount || Number(this.dataForm.sign_teacher_lesson_hour_amount <= 50)) {
              callback(new Error('请选择或输入正确辅导课时费'))
            } else {
              callback()
            }
          },
        }],
        sign_teacher_lesson_hour: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.dataForm.sign_teacher_lesson_hour && this.dataForm.sign_teacher_lesson_hour != 0) {
              callback(new Error('请选择或输入辅导课时'))
            } else if (this.dataForm.sign_teacher_lesson_hour > this.orderInfo.lesson_hours) {
              callback(new Error('辅导课时不可大于总课时'))
            } else {
              callback()
            }
          },
        }],
        /*   alt_manage_ids: [{ required: true, message: "请选择类型", trigger: "blur" }], */
      },
      options: [{
        label: '跟进',
        options: [

          {
            value: 6,
            label: '申请'
          },
          {
            value: 7,
            label: '沟通'
          }, {
            value: 8,
            label: '记录'
          },
          {
            value: 16,
            label: '完结'
          },
        ]
      }],
      teacherInfo: {},
      countSelect: 1,
      orderInfo: {},
      uploadImg: '',
      imgUploadUrl: imgUploadUrl(),
      fileList: [],
    };
  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        if (this.id) {
          // this.dataForm.type = ''
          // this.options[0].options = this.options[0].options.filter(v => {
          //   return v.value != 8
          // })
          this.options[0].options = this.options[0].options.filter(v => {
            return v.value != 16
          })
          this.fetchDetail()
        } else {
          this.dataForm.type = 8
          this.options[0].options = this.options[0].options.filter(v => {
            return v.value != 7
          })
          this.options[0].options = this.options[0].options.filter(v => {
            return v.value != 6
          })
        }
        if (this.order_id) {
          this.fetchOrderDetail()
        }
      }
    },
  },
  methods: {
    // 文件上传成功处理
    handleSuccess(response, file, fileList) {
      this.fileList.push({
        url: response.data.url,
      })
    },
    handlePaste(value) {
      if (this.fileList.length >= 3) {
        this.$message.error('最多可以传三张')
        return
      }
      let file = value.clipboardData.items[0]
      if (file.type.includes('image')) {
        let imgFile = file.getAsFile()
        const formData = new FormData()
        formData.append('file', imgFile)
        const axiosInstance = this.$axios.create({ withCredentials: false })
        axiosInstance({
          method: 'post',
          url: this.imgUploadUrl, // 上传地址，视情况更换
          data: formData,
        })
          .then(res => {
            // 调用成功回调
            this.fileList.push({
              url: res.data.data.url,
            })
          })
          .catch(function (err) {
            console.log(err)
            // 上传失败
          })
      }
    },
    typeChange(e) {
      if (e == 6) {
        this.$set(this.dataForm, 'record', "已申请添加该老师微信，等待验证通过。")
        // this.dataForm.record ="已申请添加该老师微信，等待验证通过。"

      } else {
        this.$set(this.dataForm, 'record', "")
      }
      if (e == 5) {
        if (this.teacherInfo.confirm_status == 0 || this.teacherInfo.contract_status == 0) {
          this.dataForm.type = null
          this.$message.error('请先与老师确认签约后再签单！')
        } else {
          this.dataForm.type = 5
        }
      }
    },
    fetchDetail() {
      teacherOne({ id: this.id }).then((response) => {
        let res = response.data
        this.teacherInfo = res.data
        this.$set(this.dataForm, 'sign_teacher_lesson_hour_amount', res.data.fee_amount ? res.data.fee_amount : null)
        if (this.isSign) {
          this.typeChange(5)
        }
      })
    },
    fetchOrderDetail() {
      taOne({ id: this.order_id }).then((response) => {
        let res = response.data
        this.orderInfo = res.data
        this.countSelect = 1
        this.$set(this.dataForm, 'sign_teacher_lesson_hour', res.data.lesson_hours ? Number(res.data.lesson_hours) : 0)
      })
    },
    handleToClose() {
      this.$emit("close");
      this.dataForm = {}
      this.fileList = []
    },
    submit(rules) {
      console.log(this.fileList);
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let data = {
            type: this.dataForm.type,
            teacher_id: this.id,
            teacher_allocation_order_id: this.order_id || null,
            record: this.dataForm.record,
            inner_order_type: 2,
            // alt_manage_ids: this.dataForm.alt_manage_ids ? this.dataForm.alt_manage_ids.join(',') : '',
            sign_teacher_lesson_hour_amount: this.dataForm.type == 5 ? this.dataForm.sign_teacher_lesson_hour_amount : null,
            sign_teacher_lesson_hour: this.dataForm.type == 5 ? this.dataForm.sign_teacher_lesson_hour : null,
            record_img_urls: this.fileList.length ? JSON.stringify(this.fileList) : ''
          }
          taRecordCreate(data).then((response) => {
            let res = response.data
            if (this.dataForm.type == 7) {
              this.editTeacher()
            }
            if (res.code == 0) {
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editTeacher() {
      let ids_array = this.teacherInfo.wechat_add_manage_ids ? this.teacherInfo.wechat_add_manage_ids.split(',') : []
      if (ids_array.findIndex(v => {
        return v == getStorage('userInfo').id
      }) == -1) {
        ids_array.push(getStorage('userInfo').id)
        teacherEdit({
          id: this.teacherInfo.id,
          wechat_add_manage_ids: ids_array.join(','),
        }).then((response) => {

        })
      }
    },
    countSelectChange(e) {
      if (e == 1) {
        this.$set(this.dataForm, 'sign_teacher_lesson_hour', this.orderInfo.lesson_hours)
      } else if (e == 2) {
        this.$set(this.dataForm, 'sign_teacher_lesson_hour', (this.orderInfo.lesson_hours / 2).toFixed(2))
      } else if (e == 3) {
        this.$set(this.dataForm, 'sign_teacher_lesson_hour', (this.orderInfo.lesson_hours / 3).toFixed(2))
      } else if (e == 4) {
        this.$set(this.dataForm, 'sign_teacher_lesson_hour', (this.orderInfo.lesson_hours / 4).toFixed(2))
      }
    },

  },
};
</script>
<style lang="less" scoped>
.record {

  /deep/.el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 0 !important;
  }
}

/deep/.el-input-group__append {
  background: #eeeeee !important;
}
</style>