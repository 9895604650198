<template>
  <div>
    <el-dialog
      top="5vh"
      title="确认"
      :visible.sync="dialogFormVisible"
      @close="handleToClose"
      :close-on-click-modal="false"
      width="650px"
    >
      <el-form
        :model="dataForm"
        label-position="left"
        ref="formRules"
        :rules="rules"
        label-width="110px"
        size="small"
      >
        <el-form-item
          label="类别："
          prop="confirm_status"
        >
          <el-radio-group v-model="dataForm.confirm_status">
            <el-radio :label="1">确认</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="内容："
          prop="confirm_reason"
        >
          <el-input
            v-model="dataForm.confirm_reason"
            type="textarea"
            placeholder="请输入内容"
            style="width: 100%;"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import {
  taOrderConfirm
} from "@/request/api";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
        confirm_status: 1,
      },
      rules: {
        confirm_status: [{ required: true, message: "请选择类型", trigger: "blur" }],
        confirm_reason: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },

    };

  },
  mounted() {
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    handleToClose() {
      this.$emit("close");
      this.dataForm = {
        confirm_status: 1,
      }
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let data = {
            teacher_allocation_teacher_ref_id: this.id,
            confirm_status: this.dataForm.confirm_status,
            confirm_reason: this.dataForm.confirm_reason,
          }
          taOrderConfirm(data).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
  </script>
  <style lang="less" scoped>
</style>