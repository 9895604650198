<template>
  <el-dialog
top="5vh"
    title="发票详情"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="570px"
  >
    <span class="tips">开票金额 </span>
    <div class="count-content">
      <span>￥{{ billInfo.invoice_amount || 0 }}</span>
    </div>
    <el-descriptions
      direction="vertical"
      :column="2"
      border
      :contentStyle="contentStyle"
    >
      <el-descriptions-item label="发票抬头">{{
        billInfo.company_name
      }}</el-descriptions-item>
      <el-descriptions-item label="发票税号">{{
        billInfo.company_tax_no
      }}</el-descriptions-item>
      <el-descriptions-item label="收件人">{{
        billInfo.receiver_name
      }}</el-descriptions-item>
      <el-descriptions-item label="联系方式">{{
        billInfo.receiver_phone
      }}</el-descriptions-item>
      <el-descriptions-item label="邮寄地址">{{
        billInfo.receiver_address
      }}</el-descriptions-item>
    </el-descriptions>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="emitClose">取 消</el-button>
      <el-button
        type="primary"
        @click="emitClose"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      billInfo: this.content,
      contentStyle: {
        width: "200px",
      },
    };
  },
  mounted() { },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.billInfo = val;
      console.log(val);
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  width: 128px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #303133;
  opacity: 1;
}
.count-content {
  height: 55px;
  text-align: center;
  margin-bottom: 36px;
  span {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 55px;
    color: #e6a23c;
    opacity: 1;
  }
}
</style>