<template>
  <div>
    <el-dialog
      append-to-body
      top="5vh"
      title="修改课时费"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="440px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item label="当前课时费：">
          <span class="item-value">
            {{ final_real_lesson_hour_amount }}元/小时
          </span>
        </el-form-item>
        <el-form-item label="课时费：" prop="final_real_lesson_hour_amount">
          <el-input
            placeholder="请输入课时费"
            v-model="dataForm.final_real_lesson_hour_amount"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          >
            <span slot="append">元/小时</span>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="tips">
        此课时费修改只对本次有效，想要修改其他课时费请前往班
        级中点击“授课老师”进行修改
      </div>
      <div class="footer">
        <el-button @click="emitClose()">取消</el-button>
        <el-button type="primary" @click="submit('formRules')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { lessonLogEdit, dispatchLessonLogUp } from "@/request/api";
export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {},
      rules: {
        teacher_grade_lesson_hour_amount: [
          { required: true, message: "请输入课时费", trigger: "blur" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
      },
      final_real_lesson_hour_amount: 0,
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.dataForm = val;
      this.final_real_lesson_hour_amount =
        this.dataForm.final_real_lesson_hour_amount;
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },

    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          dispatchLessonLogUp({
            id: this.content.id,
            dispatch_log_course_theme: this.content.dispatch_log_course_theme,
            final_real_lesson_hour: this.content.final_real_lesson_hour,
            final_real_lesson_hour_amount: this.content.final_real_lesson_hour_amount,
            final_real_company_hour_amount: this.content.final_real_company_hour_amount,
            final_real_company_service_amount:
              this.content.final_real_company_service_amount,
            // remark: this.content.remark,
          }).then((response) => {
            let res = response.data;
            if (res.code == 0) {
              this.$message.success("修改成功");
              this.$emit("submit", {
                id: this.content.id,
                final_real_lesson_hour_amount:
                  this.dataForm.final_real_lesson_hour_amount,
              });
              this.emitClose();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  font-size: 14px;
  font-weight: 400;
  color: #c0c4cc;
  line-height: 22px;
}
</style>