<template>
  <div>
    <el-dialog
      top="5vh"
      title="确认课时"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="980px"
    >
      <div class="table">
        <el-table
          :data="confirmList"
          :header-cell-style="{
            textAlign: 'center',
            color: '#909399',
            fontFamily: 'PingFang SC',
            fontSize: '14px',
            fontWeight: '400',
            backgroundColor: '#F2F6FC',
          }"
          :cell-style="{ textAlign: 'center', fontSize: '13px' }"
          border
        >
          <el-table-column
            prop="name"
            label="姓名"
            width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.name }}
              <el-tag size="mini" effect="plain" type="info">{{
                scope.row.label
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag
                size="mini"
                v-if="scope.row.status == '待确认'"
                type="warning"
                >{{ scope.row.status }}</el-tag
              >
              <el-tag
                size="mini"
                v-else-if="scope.row.status == '已拒绝'"
                type="danger"
                >{{ scope.row.status }}</el-tag
              >
              <el-tag size="mini" type="success" v-else>{{
                scope.row.status
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="确认内容"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="提交时间" width="150">
            <template slot-scope="scope">
              {{
                scope.row.time
                  ? $moment(scope.row.time).format("YYYY-MM-DD HH:mm")
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <el-link
                type="primary"
                :underline="false"
                :disabled="
                  dataForm.editList.findIndex((v) => {
                    return v.id == scope.row.id;
                  }) != -1
                "
                @click="handleToEdit(scope.row)"
                >修改</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="form-modular">
        <el-form
          :model="dataForm"
          label-position="left"
          ref="formRules"
          :rules="rules"
          label-width="100px"
          size="small"
        >
          <div v-for="(item, index) in dataForm.editList" :key="index">
            <div class="header">
              <div
                class="title"
                v-if="item.label == '学生' && item.handle_type == 0"
              >
                代<span class="name">{{ item.name }}</span
                ><span>{{ item.label }}</span
                >课时内容的确认及说明
              </div>
              <div
                class="title"
                v-if="item.label == '学生' && item.handle_type == 1"
              >
                对<span class="name">{{ item.name }}</span
                ><span>{{ item.label }}</span
                >课时确认的修改及说明
              </div>
              <div
                class="title"
                v-if="item.label == '老师' && item.handle_type == 0"
              >
                代<span class="name">{{ item.name }}</span
                ><span>{{ item.label }}</span
                >课时内容的确认及说明
              </div>
              <div
                class="title"
                v-if="item.label == '老师' && item.handle_type == 1"
              >
                对<span class="name">{{ item.name }}</span
                ><span>{{ item.label }}</span
                >课时确认的修改及说明
              </div>
              <div class="title" v-if="item.label == '学管师'">
                我的课时确认
              </div>
              <div>
                <el-link
                  type="primary"
                  :underline="false"
                  v-if="item.handle_type == 1"
                  @click="cancel(index)"
                  >取消修改</el-link
                >
              </div>
            </div>

            <el-form-item
              label="上课时间："
              :prop="'editList.' + index + '.final_real_lesson_start_time'"
              :rules="rules.final_real_lesson_start_time"
            >
              <div class="">
                <el-date-picker
                  :picker-options="pickerOptions"
                  v-model="item.final_real_lesson_start_time"
                  type="datetime"
                  placeholder="请选择日期时间"
                  clearable
                  value-format="timestamp"
                  @change="
                    (...event) => {
                      datePickerChange(event, item);
                    }
                  "
                >
                </el-date-picker>
              </div>
            </el-form-item>

            <el-form-item
              label="辅导时长："
              :prop="'editList.' + index + '.student_real_lesson_hour_area'"
              :rules="rules.student_real_lesson_hour_area"
              v-if="item.label == '学生'"
            >
              <el-select
                v-model="item.student_real_lesson_hour_area"
                placeholder="请选择"
                clearable
                @input="
                  (...event) => {
                    studentHourChange(event, item);
                  }
                "
              >
                <el-option label="等于计划课时" :value="0"> </el-option>
                <el-option label="小于计划课时" :value="1"> </el-option>
                <el-option label="大于计划课时" :value="2"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="辅导时长："
              :prop="'editList.' + index + '.final_real_lesson_hour'"
              :rules="rules.final_real_lesson_hour"
              v-else
            >
              <el-input-number
                v-model="item.final_real_lesson_hour"
                :precision="2"
                :step="0.1"
                :min="0.01"
                :max="result.course_plan.plan_lesson_hour || 0"
                @change="
                  (...event) => {
                    hourInput(event, item);
                  }
                "
              ></el-input-number>
              <span style="margin-left: 8px">小时</span>
            </el-form-item>
            <el-form-item
              label="课时费："
              :prop="'editList.' + index + '.final_real_lesson_hour_amount'"
              :rules="rules.final_real_lesson_hour_amount"
              v-if="item.label == '学管师'"
            >
              <el-input
                v-model="item.final_real_lesson_hour_amount"
                oninput="value=value.replace(/^0| [^0-9]/g, '')"
                style="width: 80px"
              ></el-input>
              <span style="margin-left: 8px">元/小时</span>
            </el-form-item>
            <el-form-item
              label="回访记录："
              :prop="'editList.' + index + '.handle_content'"
              :rules="item.label != '学管师' ? rules.handle_content : {}"
            >
              <div class="text-pic">
                <el-form-item>
                  <el-input
                    type="textarea"
                    v-model="item.handle_content"
                    placeholder="请输入回访记录"
                    @input="
                      (...event) => {
                        contentInput(event, item);
                      }
                    "
                    @paste.native="
                      (...event) => {
                        handlePaste(event, item);
                      }
                    "
                  ></el-input>
                </el-form-item>
                <div class="upload" v-if="item.label != '学管师'">
                  <el-upload
                    :action="imgUploadUrl"
                    list-type="picture-card"
                    :on-success="
                      (...event) => {
                        handleFileSuccess(event, index);
                      }
                    "
                    :on-remove="
                      (...event) => {
                        handleRemove(event, index);
                      }
                    "
                    :limit="9"
                    :file-list="item.handle_picture_urls"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <div class="footer">
        <el-button size="small" @click="emitClose(false)">取消</el-button>
        <!--   <el-button
          size="small"
          type="danger"
          @click="refuse()"
        >拒绝</el-button> -->
        <el-button type="primary" @click="validate('formRules')" size="small"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { confirmLesson, lessonLogOne } from "@/request/api";
import { imgUploadUrl, fileUploadUrl } from "@/request/http";

import { getStorage } from "@/utils/helper";
export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    course_plan_id: {
      type: Number,
      default: () => null,
    },
    lesson_log_id: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,

      dataForm: {
        editList: [],
      },
      rules: {
        final_real_lesson_start_time: [
          { required: true, message: "请选择上课时间", trigger: "change" },
        ],
        final_real_lesson_hour_amount: [
          { required: true, message: "请输入课时费", trigger: "change" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
        student_real_lesson_hour_area: [
          { required: true, message: "请选择辅导课时", trigger: "change" },
        ],
        final_real_lesson_hour: [
          { required: true, message: "请输入辅导课时", trigger: "change" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
        handle_content: [
          { required: true, message: "请输入回访内容", trigger: "change" },
        ],
      },
      tipsText: "",
      result: {},
      confirmList: [],
      imgUploadUrl: imgUploadUrl(),
      lesson_handle_logs: [],
    
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date().getTime();
        },
      },
    };
  },
  mounted() {},
  computed: {
    // pickerOptions(){
    //   return {
    //     disabledDate(time) {
    //       return time.getTime() > Date.now() - 86400000;
    //     },
    //   }
    // }
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail();
      }
    },
  },
  methods: {
    // pickerOptions() {
    //   return {
    //     disabledDate(time) {
    //       return time.getTime() > Date.now() - 86400000;
    //     },
    //   };
    // },
    emitClose() {
      this.$refs["formRules"].clearValidate();
      this.dataForm.editList = [];
      this.$emit("close");
    },
    fetchDetail() {
      lessonLogOne({ id: this.lesson_log_id }).then((response) => {
        let res = response.data;
        this.result = res.data;
        this.lesson_handle_logs = res.data.lesson_handle_logs
          ? res.data.lesson_handle_logs
          : [];
        this.formateConfirmList();
      });
    },
    getPickerOptions(item) {
      // 每次调用都返回一个新的对象实例
      return item.pickerOptions;
    },
    formateConfirmList() {
      let arr = [];
      this.result.lesson_students.forEach((v, i) => {
        let studentArr = [
          // 学生的确认
          {
            id: v.student_id,
            name: v.student.realname,
            label: "学生",
            status: this.formateStudentConfirmStatus(v),
            content:
              v.student_confirm_status == 1
                ? `学生对于老师提交的实际上课时间及课时${this.formateStudentRealLessonHour(
                    v.student_real_lesson_hour_area
                  )}；`
                : "",
            time: v.student_confirm_time,
            is_handle: v.student_by_handle_status,
            handle_type: 0,
            origin_data: v,
            handle_content: "",
            handle_picture_urls: [],
            final_real_lesson_start_time:
              this.result.course_plan.plan_start_time,
            student_real_lesson_hour_area: 0,
          },
        ];
        arr = arr.concat(studentArr);
      });
      // 老师的确认
      let teacherObj = {
        id: this.result.teacher_id,
        name: this.result.lesson_teacher.teacher.realname,
        label: "老师",
        status: this.result.teacher_confirm_status == 1 ? "已确认" : "待确认",
        content:
          this.result.teacher_confirm_status == 1
            ? `老师确认了实际上课时间：${this.$moment(
                this.result.teacher_real_lesson_start_time
              ).format("YYYY-MM-DD HH:mm")}；实际上课时长：${
                this.result.teacher_real_lesson_hour
                  ? this.result.teacher_real_lesson_hour
                  : 0
              }小时；`
            : "",
        time: this.result.teacher_confirm_time,
        handle_type: 0,
        handle_content: "",
        handle_picture_urls: [],
        final_real_lesson_start_time: this.result.course_plan.plan_start_time,
        final_real_lesson_hour: this.result.current_lesson_hour,
      };
      arr.push(teacherObj);
      this.confirmList = arr;
      this.dataForm.editList = arr.filter((v) => {
        return v.status == "待确认";
      });
      // 学管师的表单对象，push到editlist里面
      let learn_managerObj = {
        id: this.result.learn_manager.id,
        label: "学管师",
        handle_area: 0,
        name: this.result.learn_manager.realname,
        final_real_lesson_start_time: this.result.course_plan.plan_start_time,
        final_real_lesson_hour: this.result.current_lesson_hour,
        handle_content: this.result.learn_manager_visit_content
          ? this.result.learn_manager_visit_content
          : "",
        final_real_lesson_hour_amount: this.result
          .teacher_grade_lesson_hour_amount
          ? this.result.teacher_grade_lesson_hour_amount
          : 0,
      };
      //学生，老师，学管师，三方正常的（本人操作）确认，修改，撤回，都不需要操作记录，只有代操作或拒绝或暂停时，会同步生成记录
      /*       if (this.result.learn_manager_handle_log_ids) {
              let log_id = this.result.learn_manager_handle_log_ids.split(',')
              let obj = this.lesson_handle_logs.find(v => {
                return v.id == log_id[log_id.length - 1]
              })
              learn_managerObj.handle_content = obj.handle_content
              learn_managerObj.handle_picture_urls = obj.handle_picture_urls ? JSON.parse(obj.handle_picture_urls) : []
            } else {
              learn_managerObj.handle_content = ''
              learn_managerObj.handle_picture_urls = []
            } */
      this.dataForm.editList.push(learn_managerObj);
      // console.log("shuss",this.dataForm.editList)
    },
    handleFileSuccess(param, index) {
      // (...event) =>{handleFileSuccess(event, index)}
      // response, file, fileList
      this.dataForm.editList[index].handle_picture_urls.push({
        name: param[1].name,
        url: param[0].data.url,
      });
    },
    handleRemove(param, index) {
      this.dataForm.editList[index].handle_picture_urls = param[1];
    },
    validate(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.submitBefore();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async submitBefore() {
      let final = JSON.parse(JSON.stringify(this.dataForm.editList));
      for (let i = 0; i < final.length; i++) {
        let pro = await this.submit(final[i], i);
        if (i + 1 == this.dataForm.editList.length) {
          this.emitClose();
          this.$emit("submit");
        }
      }
    },
    submit(item, index) {
      if (item.label == "学管师") {
        let params = {
          course_plan_id: this.result.course_plan_id,
          user_type: "learn_manager",
          learn_manager: item.id,
          learn_manager_course_feedback_area: item.handle_area,
          final_real_lesson_hour: item.final_real_lesson_hour,
          learn_manager_confirm_status: 1,
          learn_manager_visit_content: item.handle_content,
          final_real_lesson_start_time: item.final_real_lesson_start_time,
          final_real_lesson_hour_amount: item.final_real_lesson_hour_amount,
        };
        return new Promise((resolve, reject) => {
          confirmLesson(params).then((response) => {
            if (response.data.code == 0) {
              resolve(0);
            } else {
              reject();
            }
          });
        });
      } else if (item.label == "学生") {
        let params = {
          course_plan_id: this.result.course_plan_id,
          is_by_handle: 1,
          user_type: "student",
          student_id: item.id,
          handle_content: item.handle_content,
          handle_picture_urls:
            item.handle_picture_urls && item.handle_picture_urls.length
              ? JSON.stringify(item.handle_picture_urls)
              : "",
          handle_area: 5,
          student_confirm_status: 1,
          student_real_lesson_start_time: item.final_real_lesson_start_time,
          student_real_lesson_hour_area: item.student_real_lesson_hour_area,
          handle_type: item.handle_type,
          handle_learn_manager_id: getStorage("userInfo").id,
        };
        return new Promise((resolve, reject) => {
          confirmLesson(params).then((response) => {
            if (response.data.code == 0) {
              resolve(0);
            } else {
              reject();
            }
          });
        });
      } else if (item.label == "老师") {
        let params = {
          course_plan_id: this.result.course_plan_id,
          is_by_handle: 1,
          user_type: "teacher",
          teacher_id: item.id,
          handle_content: item.handle_content,
          handle_picture_urls:
            item.handle_picture_urls && item.handle_picture_urls.length
              ? JSON.stringify(item.handle_picture_urls)
              : "",
          handle_area: 5,
          teacher_confirm_status: 1,
          teacher_real_lesson_start_time: item.final_real_lesson_start_time,
          teacher_real_lesson_hour: item.final_real_lesson_hour,
          handle_type: item.handle_type,
          handle_learn_manager_id: getStorage("userInfo").id,
        };
        return new Promise((resolve, reject) => {
          confirmLesson(params).then((response) => {
            if (response.data.code == 0) {
              resolve(0);
            } else {
              reject();
            }
          });
        });
      }
    },
    formateStudentConfirmStatus(row) {
      if (row.student_confirm_status == 1) {
        if (row.student_by_handle_status == 1) {
          return "已确认（代）";
        } else {
          return "已确认";
        }
      } else if (row.student_confirm_status == 0) {
        return "待确认";
      } else if (row.student_confirm_status == 2) {
        return "已拒绝";
      }
    },
    formateStudentRealLessonHour(area) {
      const areas = {
        0: "确认无误",
        1: "有异议",
        2: "大于计划课时",
      };
      return areas[area] || "";
    },
    handleToEdit(row) {
      if (row.label == "学生") {
        let data = {
          is_by_handle: 1,
          handle_type: 1,
          handle_area: 5,
          id: row.id,
          label: "学生",
          final_real_lesson_start_time:
            row.origin_data.student_real_lesson_start_time,
          student_real_lesson_hour_area:
            row.origin_data.student_real_lesson_hour_area,
          name: row.name,
          handle_content: "",
          handle_picture_urls: [],
        };
        if (row.origin_data.student_by_handle_log_ids) {
          let log_id = row.origin_data.student_by_handle_log_ids
            .split(",")
            .filter((t) => {
              return t != "0" || t;
            });
          let obj =
            this.lesson_handle_logs.find((v) => {
              return v.id == log_id[log_id.length - 1];
            }) || {};
          data.handle_content = obj.handle_content;
          data.handle_picture_urls = obj.handle_picture_urls
            ? JSON.parse(obj.handle_picture_urls)
            : [];
        } else {
          data.handle_content = "";
          data.handle_picture_urls = [];
        }
        this.dataForm.editList.unshift(data);
      } else if (row.label == "老师") {
        let data = {
          is_by_handle: 1,
          handle_type: 1,
          handle_area: 5,
          id: row.id,
          label: "老师",
          final_real_lesson_start_time: this.result
            .teacher_real_lesson_start_time
            ? this.result.teacher_real_lesson_start_time
            : "",
          final_real_lesson_hour: this.result.teacher_real_lesson_hour
            ? this.result.teacher_real_lesson_hour
            : "",
          name: row.name,
          handle_content: "",
          handle_picture_urls: [],
        };
        if (this.result.teacher_by_handle_log_ids) {
          let log_id = this.result.teacher_by_handle_log_ids
            .split(",")
            .filter((t) => {
              return t != 0 || t;
            });
          let obj =
            this.lesson_handle_logs.find((v) => {
              return v.id == log_id[log_id.length - 1];
            }) || {};
          data.handle_content = obj.handle_content;
          data.handle_picture_urls = obj.handle_picture_urls
            ? JSON.parse(obj.handle_picture_urls)
            : [];
        } else {
          data.handle_content = "";
          data.handle_picture_urls = [];
        }
        this.dataForm.editList.unshift(data);
      }
    },
    refuse() {
      let final = JSON.parse(JSON.stringify(this.dataForm.editList));
      final.forEach((item, index) => {
        if (item.label == "学管师") {
          let params = {
            is_refuse: 1,
            course_plan_id: this.result.course_plan_id,
            user_type: "learn_manager",
            learn_manager: item.id,
            learn_manager_course_feedback_area: 0,
            final_real_lesson_hour: item.final_real_lesson_hour,
            learn_manager_confirm_status: 1,
            learn_manager_visit_content: item.handle_content,
            final_real_lesson_start_time: item.final_real_lesson_start_time,
          };
          confirmLesson(params).then((response) => {
            this.emitClose();
            this.$emit("submit");
          });
        }
      });
    },
    datePickerChange(params, item) {
      this.dataForm.editList.forEach((item, index) => {
        this.$set(item, "final_real_lesson_start_time", params[0]);
      });
    },
    hourInput(params, item) {
      let hour =
        this.result.course_plan && this.result.course_plan.plan_lesson_hour
          ? Number(this.result.course_plan.plan_lesson_hour)
          : 0;
      let input_hour = Number(params[0]);
      this.dataForm.editList.forEach((item, index) => {
        if (item.label == "学生") {
          if (input_hour == hour) {
            this.$set(item, "student_real_lesson_hour_area", 0);
          } else if (input_hour < hour) {
            this.$set(item, "student_real_lesson_hour_area", 1);
          } else if (input_hour > hour) {
            this.$set(item, "student_real_lesson_hour_area", 2);
          }
        } else if (item.label == "老师" || item.label == "学管师") {
          this.$set(item, "final_real_lesson_hour", input_hour);
        }
      });
    },
    contentInput(params, item) {
      if (item.label == "学管师") {
        /*   this.dataForm.editList.forEach((item, index) => {
            this.$set(item, 'handle_content', params[0])
          }) */
      }
    },
    cancel(ind) {
      this.dataForm.editList.splice(ind, 1);
    },
    studentHourChange(params, item) {
      this.dataForm.editList.forEach((item, index) => {
        if (item.label == "学生") {
          this.$set(item, "student_real_lesson_hour_area", params[0]);
        }
      });
    },
    handlePaste(value, item) {
      if (item.handle_picture_urls.length >= 9) {
        this.$message.error("最多可以传九张");
        return;
      }
      let file = value[0].clipboardData.items[0];
      if (file.type.includes("image")) {
        let imgFile = file.getAsFile();
        const formData = new FormData();
        formData.append("file", imgFile);
        const axiosInstance = this.$axios.create({ withCredentials: false });
        axiosInstance({
          method: "post",
          url: this.imgUploadUrl, // 上传地址，视情况更换
          data: formData,
        })
          .then((res) => {
            // 调用成功回调
            item.handle_picture_urls.push({
              url: res.data.data.url,
            });
          })
          .catch(function (err) {
            console.log(err);
            // 上传失败
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-bottom: 32px;
}
.form-modular {
  padding: 16px 0px;
  .text-pic {
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #dcdfe6;
    padding: 16px;

    /deep/.el-textarea__inner {
      border: 0;
      resize: none;
      padding: 0px;
    }
    .upload {
      /deep/.el-upload--picture-card {
        width: 56px;
        height: 56px;
        line-height: 65px;
      }
      /deep/.el-upload-list__item {
        width: 56px;
        height: 56px;
        line-height: 65px;
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      margin-bottom: 16px;
      .name {
        font-weight: 800;
      }
    }
  }
}
</style>